import { RecurrenceOptions } from "types";
import { Frequency, Options, RRule, Weekday } from "rrule";

export const recurrenceOptionsToRRule = (recurrence?: RecurrenceOptions): RRule | undefined => {
  if (!recurrence || !recurrence.type) {
    return undefined;
  }

  const { type, start, end, interval } = recurrence;

  const options: Partial<Options> = { dtstart: start, until: end, interval: interval, wkst: RRule.SU };

  if (type === "daily") {
    options.freq = Frequency.DAILY;
  }

  if (type === "weekly") {
    const { weekdays } = recurrence;

    options.freq = Frequency.WEEKLY;
    options.byweekday = weekdays?.map((weekday) => new Weekday(weekday));
  }

  if (type === "monthly") {
    options.freq = Frequency.MONTHLY;

    if ("dayOfMonth" in recurrence && typeof recurrence.dayOfMonth !== "undefined") {
      const { dayOfMonth } = recurrence;

      options.bymonthday = [dayOfMonth];
    } else if ("dayOfWeek" in recurrence) {
      const { dayOfWeek, index } = recurrence;

      options.byweekday = [dayOfWeek];
      options.bysetpos = [index];
    }
  }

  return new RRule(options);
};
