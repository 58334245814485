import { Box, Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import { ReservationServiceRequestSettingType, ReservationServiceType } from "store";
import { ReservationCateringDetailsProps } from "./types";
import { resolveReservationServiceOptionTypeLabel } from "utils";
import { TogetherDateAndTime } from "../icons";
import { t } from "@lingui/macro";

export const ReservationCateringDetails: React.FC<ReservationCateringDetailsProps> = (props) => {
  const { type, requests, settings } = props;
  const { palette } = useTheme();
  const servingTime = settings?.find((setting) => {
    return setting.type === ReservationServiceRequestSettingType.SERVING_TIME 
      && setting.serviceType === ReservationServiceType.CATERING
      && setting.serviceOptionType === type;
  });
  const costCentre = settings?.find((setting) => {
    return setting.type === ReservationServiceRequestSettingType.COST_CENTRE 
      && setting.serviceType === ReservationServiceType.CATERING
      && setting.serviceOptionType === type;
  });
  const options = requests?.filter(({ option }) => {
    return option && option.type === type && option.serviceType === ReservationServiceType.CATERING;
  });
  const other = requests?.filter((request) => {
    return request.type === type && !request.option && request.serviceType === ReservationServiceType.CATERING && request.description;
  });

  if (!servingTime && !costCentre && !options?.length) {
    return <></>;
  }

  return (
    <>
      <Box alignItems="center" display="flex" justifyContent="space-between" mb={1}>
        <Typography fontSize={14} fontWeight={600} textTransform="capitalize">{resolveReservationServiceOptionTypeLabel(type)}</Typography>
        {servingTime?.value ? (
          <Box alignItems="center" display="flex" gap={1}>
            <TogetherDateAndTime fill={palette.grey[700]} sx={{ width: 16, height: 16 }} />
            <Typography color={palette.grey[700]} fontSize={14}>{servingTime.value}</Typography>
          </Box>
        ) : undefined}
      </Box>
      <Grid container mb={2} spacing={1}>
        {costCentre?.value ? (
          <>
            <Grid item sm={5}><Typography fontSize={14}>{t`Cost centre`}</Typography></Grid>
            <Grid item sm={7}><Typography fontSize={14} fontWeight={600}>{costCentre.value}</Typography></Grid>
          </>
        ) : undefined}
        {options?.map(({ option, quantity }) => (
          <>
            <Grid item sm={5}><Typography fontSize={14}>{option?.name}</Typography></Grid>
            <Grid item sm={7}><Typography fontSize={14}>{quantity || "-"}</Typography></Grid>
          </>
        ))}
        {other?.length ? (
          <>
            <Grid item sm={5}><Typography fontSize={14}>{t`Other`}</Typography></Grid>
            <Grid item sm={7}>
              <Typography fontSize={14}>{other.map(({ description }) => description).join(", ")}</Typography>
            </Grid>
          </>
        ) : undefined}
      </Grid>
    </>
  );
};
