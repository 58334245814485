import {Link, useParams} from "react-router-dom";
import {useTypedSelector} from "store/_legacy/Redux/store";
import Box from "components/_legacy/Box";
import styles from "../LocationDetailsHeader/styles.module.scss";
import Heading from "components/_legacy/Heading";
import Text from "components/_legacy/Text";
import {LocationOutlinedIcon} from "../../Icons/LocationOutlinedIcon";

export default function ReservationMapViewHeader() {
  const { locationId } = useParams<{ locationId: string }>();
  const { config, locations } = useTypedSelector(state => state);
  const selectedLocation = locations.locations.find(location => location.id === locationId);
  
  return (
    <>
      <Box className={styles.header} dataTestId="reservation-details-header">
        <Box alignItems="center" display="flex" justifyContent="between" marginBottom={15}>
          <Box>
            <Box alignItems="center" display="flex">
              <Heading className={styles.heading} size="sm">
                <Link className={styles.headingBack} to='/admin/reservations'>
                  <svg height="18px" version="1.1" viewBox="0 0 18 18" width="18px">
                    <g fill="none" fillRule="evenodd" id="A-locations" stroke="none" strokeWidth="1">
                      <g id="A-Location-notifications-createnew" transform="translate(-207.000000, -28.000000)">
                        <rect fill="transparent" height="900" width="1440" x="0" y="0" />
                        <path d="M220,0 C208.954305,2.02906125e-15 200,8.954305 200,20 L200,20 L200,1024 L0,1024 L0,0 Z" fill={config.theme.primaryLight} id="Combined-Shape" />
                        <g id="icons/arrow-back" transform="translate(207.000000, 28.000000)">
                          <g id="icons/arrow-left" />
                          <rect fill={config.theme.primary} height="2" id="Rectangle" rx="1" width="14" x="2" y="8" />
                          <path d="M10,5 C10.5522847,5 11,5.44771525 11,6 C11,6.55228475 10.5522847,7 10,7 L5,7 L5,12 C5,12.5522847 4.55228475,13 4,13 C3.44771525,13 3,12.5522847 3,12 L3,6 C3,5.44771525 3.44771525,5 4,5 L10,5 Z" fill={config.theme.primary} id="Combined-Shape" transform="translate(7.000000, 9.000000) rotate(-45.000000) translate(-7.000000, -9.000000) " />
                        </g>
                      </g>
                    </g>
                  </svg>
                </Link>

                {selectedLocation?.locationName}
              </Heading>
            </Box>

            <Box
              alignItems="center"
              display="flex"
              left={-1}
              marginTop={11}
              overflow="hidden"
              position="relative"
            >
              <LocationOutlinedIcon />

              <Box overflow="hidden">
                <Text color='gray' size="md" truncate>
                  {selectedLocation?.locationAddress} | {selectedLocation?.region}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}