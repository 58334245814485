import {useState} from "react";
import Box from "components/_legacy/Box";
import styles from "../../../../../../../../Notifications/NotificationCalendar/styles.module.scss";
import covidCalendarStyles
  from "components/_legacy/UploadCovidFile/UploadCovidDates/UploadCovidCalendar/styles.module.scss";
import {Calendar as CalendarDateRange} from "react-date-range";
import Button from "components/_legacy/Button";
import {Trans} from "@lingui/macro";

interface Props {
  minDate: Date | string | null;
  maxDate: Date | string | null;
  date: Date | string | null;
  setDate: Function;
  closePopup: any;
}

export default function CancelReservationCalendar(props: Props) {
  const { date, minDate, maxDate, setDate, closePopup } = props;
  const calendarMinDate = minDate ? new Date(minDate) : undefined;
  const calendarMaxDate = maxDate ? new Date(maxDate) : undefined;

  const initSelectedDate = date ? new Date(date) : new Date();
  const [selectedDate, setSelectedDate] = useState<Date | null>(initSelectedDate);

  const handleSelect = (date: any) => {
    setSelectedDate(date);
  };

  const onResetData = () => {
    setSelectedDate(new Date());
  };

  const onSetTodayData = () => {
    setSelectedDate(new Date());
  };

  const onSubmitDate = () => {
    closePopup();
    setDate(selectedDate);
  };

  return (
    <Box className={`${styles.calendar} ${covidCalendarStyles.uploadCovidCalendar}`}>
      <Box>
        <CalendarDateRange
          date={selectedDate || new Date()}
          maxDate={calendarMaxDate}
          minDate={calendarMinDate}
          onChange={handleSelect}
          // @ts-ignore
          showMonthAndYearPickers={false}
        />
      </Box>

      <Box alignItems="center" className={styles.buttons} display="flex" justifyContent="between" marginBottom={8}>
        <Button className={styles.buttonToday} noPaddingX onClick={onSetTodayData} type="clear">
          <Trans>Today</Trans>
        </Button>

        <Box>
          <Button noPaddingX onClick={onResetData} type="clear">
            <Trans>Reset</Trans>
          </Button>

          <Button noPaddingX onClick={onSubmitDate} type="primary">
            <Trans>Done</Trans>
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
