import Api from 'store/_legacy/Services/Api';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  GET_PUSH_NOTIFICATIONS,
  GET_PUSH_NOTIFICATIONS_FAIL,
  GET_PUSH_NOTIFICATIONS_SUCCESS,
  GetPushNotifications,
  UPDATE_PUSH_NOTIFICATION_STATUS,
  UPDATE_PUSH_NOTIFICATION_STATUS_FAIL,
  UPDATE_PUSH_NOTIFICATION_STATUS_SUCCESS,
  UpdatePushNotificationStatus,
} from '.';

function* getPushNotificationsSaga(action: GetPushNotifications): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: GET_PUSH_NOTIFICATIONS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_PUSH_NOTIFICATIONS_FAIL, payload: e });
  }
}

function* updatePushNotificationStatusSaga(action: UpdatePushNotificationStatus): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: UPDATE_PUSH_NOTIFICATION_STATUS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: UPDATE_PUSH_NOTIFICATION_STATUS_FAIL, payload: e });
  }
}

export default [
  takeEvery(GET_PUSH_NOTIFICATIONS, getPushNotificationsSaga),
  takeEvery(UPDATE_PUSH_NOTIFICATION_STATUS, updatePushNotificationStatusSaga),
];
