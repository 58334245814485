import React from "react";
import { EmojiPopoverProps } from "./types";
import { Button, ButtonGroup, Popover, useTheme } from "@mui/material";
import { parseEmoji } from "utils";

const codes = ["1F600", "1F44D", "1F602", "1F60D"];

export const EmojiPopover: React.FC<EmojiPopoverProps> = (props) => {
  const { value, onChange, ...rest } = props;
  const { background } = useTheme();

  return (
    <Popover {...rest}>
      <ButtonGroup sx={{ "& .MuiButtonGroup-grouped:not(:last-of-type)": { border: "none" } }} variant="text">
        {codes.map((code) => (
          <Button key={code} onClick={(event) => onChange?.(event, code)} sx={{ bgcolor: value === code ? background.blue.light : undefined }}>
            {parseEmoji(code)}
          </Button>
        ))}
      </ButtonGroup>
    </Popover>
  );
};
