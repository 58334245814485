import Box from 'components/_legacy/Box';
import Button from 'components/_legacy/Button';
import CustomBooking from './CustomBooking';
import DailyBooking from './DailyBooking';
import datetimeIcon from './assets/datetime.png';
import Divider from 'components/_legacy/Divider';
import OverflowToolTip from 'components/_legacy/OverflowTooltip';
import styles from './styles.module.scss';
import SupportBooking from './SupportBooking';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Text from 'components/_legacy/Text';
import WeeklyBooking from './WeeklyBooking';
import { BookingType } from 'App/Store/Bookings/bookingDuck/models';
import {getClosestAvailableDay, getClosestStartDate} from '../../../Functions/Helpers';
import { setCreateNewBookingData } from 'App/Store/Bookings/createNewBookingDuck';
import { Trans, t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useTypedSelector } from 'store/_legacy/Redux/store';

function a11yProps(index: number) {
  return {
    id: `calendar-tab-${index}`,
    'aria-controls': `calendar-tab-panel-${index}`,
  };
}

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      aria-labelledby={`calendar-tab-${index}`}
      hidden={value !== index}
      id={`calendar-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index && children}
    </div>
  );
}

/**
 * Calendar component to select date and time ranges.
 */
export default function Calendar() {
  const dispatch = useDispatch();
  const { locations } = useTypedSelector(state => state);
  const { bookingType, locationId } = useTypedSelector(state => state.createNewBooking);
  const selectedLocation = locations.locations.find(location => location.id === locationId);

  const getBookingTypeNumber = (bookingType: BookingType): number => {
    let bookingTypeNumber = 0;

    switch (bookingType) {
      case 'daily': {
        bookingTypeNumber = 1;
        break;
      }

      case 'weekly': {
        bookingTypeNumber = 2;
        break;
      }

      default:
        break;
    }

    return bookingTypeNumber;
  };

  const [activeTab, setActiveTab] = useState(getBookingTypeNumber(bookingType));

  const handleSetActiveTab = (_: React.ChangeEvent<{}>, value: any) => {
    setActiveTab(value);

    let bookingType: BookingType = 'custom';

    switch (value) {
      case 1: {
        bookingType = 'daily';
        break;
      }

      case 2: {
        bookingType = 'weekly';
        break;
      }

      default:
        break;
    }

    dispatch(setCreateNewBookingData({ bookingType }));
  };

  const handleTodayClick = () => {
    const closestStartDay = selectedLocation ? getClosestStartDate(selectedLocation?.allowedBookingDayType) : new Date();
    const closestDay = getClosestAvailableDay(closestStartDay, locations.locationDisabledDays);
    dispatch(setCreateNewBookingData({ dateFrom: closestDay, dateTo: closestDay }));
  };

  return (
    <Box className={styles.calendar}>
      <Box alignItems="center" display="flex" justifyContent="between" marginBottom={8}>
        <Box alignItems="center" display="flex">
          <Box className={styles.datetimeIconContainer} marginEnd={10}>
            <img src={datetimeIcon} />
          </Box>

          <Text weight="semi-bold">
            <Trans>Select date and time</Trans>
          </Text>
        </Box>

        <Button noPaddingX onClick={handleTodayClick} type="clear">
          <Trans>Reset</Trans>
        </Button>
      </Box>

      <Box marginBottom={10}>
        <Divider />
      </Box>

      <Tabs
        classes={{
          root: styles.tabs,
          indicator: styles.indicator,
          scroller: styles.scroller,
        }}
        onChange={handleSetActiveTab}
        value={activeTab}
      >
        <Tab
          classes={{
            root: styles.tab,
            selected: styles.tabSelected,
          }}
          label={t`Custom`}
          {...a11yProps(0)}
        />
        <Tab
          classes={{
            root: styles.tab,
            selected: styles.tabSelected,
          }}
          label={t`Daily`}
          {...a11yProps(1)}
        />
        <Tab
          classes={{
            root: styles.tab,
            selected: styles.tabSelected,
          }}
          label={t`Weekly`}
          {...a11yProps(2)}
        />
      </Tabs>

      <TabPanel index={0} value={activeTab}>
        <CustomBooking />
      </TabPanel>

      <TabPanel index={1} value={activeTab}>
        <DailyBooking />
      </TabPanel>

      <TabPanel index={2} value={activeTab}>
        <WeeklyBooking />
      </TabPanel>
    </Box>
  );
}

export function CalendarSupport() {
  const { createNewBooking, locations } = useTypedSelector(state => state);

  const floor = locations.floors.find(floor => floor.id === createNewBooking.floorId);
  const location = locations.locations.find(location => location.id === createNewBooking.locationId);
  const floorName = floor ? floor.floorName : '';
  const locationName = location ? location.locationAddress : '';

  const handleTodayClick = () => {
    // const today = new Date();

    // add dispatch for support desk here
  };

  return (
    <Box className={styles.calendar}>
      <h3 className={styles.detailsHeader}>
        <Trans>Details</Trans>
      </h3>

      <Box alignItems="center" display="flex" justifyContent="between" marginBottom={8}>
        <Box className={styles.floorDetailsContainer}>
          <Box className={styles.floorDetailsBlock}>
            <svg height="16px" version="1.1" viewBox="0 0 16 16" width="16px">
              <g fill="none" fillRule="evenodd" id="Booking" stroke="none" strokeWidth="1">
                <g id="Createbooking-appointment-4" transform="translate(-907.000000, -168.000000)">
                  <g id="labels/amenities" transform="translate(900.000000, 161.000000)">
                    <g transform="translate(0.000000, 3.000000)">
                      <rect fill="#F4F4F4" height="24" id="Rectangle" rx="8" width="82" x="0" y="0"></rect>
                      <g id="Group" transform="translate(7.000000, 4.000000)">
                        <g id="icons/floor" transform="translate(0.000000, 0.000000)"></g>
                        <path d="M12,1 C13.1045695,1 14,1.8954305 14,3 L14,3 L14,15 L2,15 L2,3 C2,1.8954305 2.8954305,1 4,1 L4,1 Z M12,3 L4,3 L4,13 L12,13 L12,3 Z M7,10 C7.55228475,10 8,10.4477153 8,11 C8,11.5522847 7.55228475,12 7,12 L6,12 C5.44771525,12 5,11.5522847 5,11 C5,10.4477153 5.44771525,10 6,10 L7,10 Z M10,10 C10.5522847,10 11,10.4477153 11,11 C11,11.5522847 10.5522847,12 10,12 C9.44771525,12 9,11.5522847 9,11 C9,10.4477153 9.44771525,10 10,10 Z M7,7 C7.55228475,7 8,7.44771525 8,8 C8,8.55228475 7.55228475,9 7,9 L6,9 C5.44771525,9 5,8.55228475 5,8 C5,7.44771525 5.44771525,7 6,7 L7,7 Z M10,7 C10.5522847,7 11,7.44771525 11,8 C11,8.55228475 10.5522847,9 10,9 C9.44771525,9 9,8.55228475 9,8 C9,7.44771525 9.44771525,7 10,7 Z M7,4 C7.55228475,4 8,4.44771525 8,5 C8,5.55228475 7.55228475,6 7,6 L6,6 C5.44771525,6 5,5.55228475 5,5 C5,4.44771525 5.44771525,4 6,4 L7,4 Z M10,4 C10.5522847,4 11,4.44771525 11,5 C11,5.55228475 10.5522847,6 10,6 C9.44771525,6 9,5.55228475 9,5 C9,4.44771525 9.44771525,4 10,4 Z" fill="#5E5E5E" fillRule="nonzero" id="Combined-Shape"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
            <div className={styles.floorDetailsBlockOverflow}>
              <OverflowToolTip text={floorName} />
            </div>
          </Box>
        </Box>
        <Box className={styles.floorAddressContainer}>
          <svg height="16px" version="1.1" viewBox="0 0 16 16" width="16px">
            <g fill="none" fillRule="evenodd" id="Booking" stroke="none" strokeWidth="1">
              <g fill="#5E5E5E" fillRule="nonzero" id="Createbooking-appointment-4" transform="translate(-990.000000, -168.000000)">
                <g id="Combined-Shape" transform="translate(990.000000, 168.000000)">
                  <path d="M8,0 C11.3137085,0 14,2.6862915 14,6 C14,8.06787169 12.3851644,11.0490855 9.17879697,15.1236911 C9.10531148,15.2170754 9.02098986,15.301397 8.92760551,15.3748824 C8.27655656,15.8872019 7.33346014,15.7747394 6.81899381,15.1209524 L6.81899381,15.1209524 L6.54199798,14.7664093 L5.99338953,14.0487452 C3.33812543,10.5201247 2,7.88389093 2,6 C2,2.6862915 4.6862915,0 8,0 Z M8,2 C5.790861,2 4,3.790861 4,6 C4,7.05182083 4.75169047,8.73801773 6.26463644,10.988101 L6.26463644,10.988101 L6.67659285,11.5878243 C6.74813244,11.6898947 6.82111265,11.7930193 6.89553426,11.8971926 L6.89553426,11.8971926 L7.35936708,12.5347686 L7.85783136,13.1972321 L7.999,13.381 L8.37959354,12.8840844 L8.8633195,12.2311144 C9.48506871,11.3772584 10.0139736,10.5907213 10.4504017,9.87434292 L10.4504017,9.87434292 L10.7603891,9.35025824 C11.5408144,7.9880151 11.9517002,6.90924661 11.9959868,6.13667129 L11.9959868,6.13667129 L12,6 C12,3.790861 10.209139,2 8,2 Z M8,5 C8.55228475,5 9,5.44771525 9,6 C9,6.55228475 8.55228475,7 8,7 C7.44771525,7 7,6.55228475 7,6 C7,5.44771525 7.44771525,5 8,5 Z"></path>
                </g>
              </g>
            </g>
          </svg>
          <div className={styles.floorAddressContainerOverflow}>
            <OverflowToolTip text={locationName} />
          </div>
        </Box>
      </Box>

      <Box marginBottom={10}>
        <Divider />
      </Box>

      <Box alignItems="center" display="flex" justifyContent="between" marginBottom={8}>
        <Box alignItems="center" display="flex">
          <Box className={styles.datetimeIconContainer} marginEnd={10}>
            <img src={datetimeIcon} />
          </Box>

          <Text weight="semi-bold">
            <Trans>Select date and time</Trans>
          </Text>
        </Box>

        <Button noPaddingX onClick={handleTodayClick} type="clear">
          <Trans>Today</Trans>
        </Button>
      </Box>

      <Box marginBottom={10}>
        <Divider />
      </Box>

      <SupportBooking />
    </Box>
  );
}
