import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useTypedSelector } from "store/_legacy/Redux/store";
import { FeatureGroup, useMapEvents } from "react-leaflet";
import DeskObject from "./DeskObject";
import { addDeskToPosition } from "../helpers";
import { DeskStatusEnum } from "../../../../../Store/floorMapDuck/desk/models";

export default function MapDrawingDesks() {
  const dispatch = useDispatch();
  const { floorId } = useParams<{ floorId: string; locationId: string }>();
  const { adminFloorMap } = useTypedSelector(state => state);

  const {
    desks,
    drawingStates,
    showSections,
  } = adminFloorMap;
  const { drawDesk } = drawingStates;

  // Transform the objects with desks and sections into arrays | do now show deleted desk with active reservations
  const desksArray = Object.keys(desks).map(key => desks[key]).filter(desk => desk.status !== DeskStatusEnum.DeleteInProgress);

  useMapEvents({
    'click': (e) => {
      const mapElement = document.querySelector('#mapid > div');
      const isClickOnEmptyMapArea = e.originalEvent.target === mapElement;

      if (isClickOnEmptyMapArea) {
        const clickPosition = e.latlng;

        if (drawDesk.active) {
          addDeskToPosition({
            position: clickPosition,
            adminFloorMap,
            dispatch,
          });
        }
      }
    },
  });

  return (
    <>
      {desksArray.map((desk) => {
        return (
          // We need to use parentId and section.length as part of the key here so that after adding
          // a section, the already existing desks can re-render and stay on top of the section.
          <FeatureGroup key={`${desk.id} ${desk.parentId} ${showSections}`}>
            <DeskObject desk={desk} editable floorId={floorId} />
          </FeatureGroup>
        );
      })}
    </>
  );
}
