import {
  BulkCancelReservationsBookingModel,
  BulkCancelReservationsBookingRequest,
  CancelReservationsBookingRequest,
  CancelReservationsBookingResponse,
  ExportReservationsRequest,
  ExportReservationsResponse,
  GetReservationsByFloorResponse,
  GetReservationsRequest,
  GetReservationsResponse,
  ReservationByFloorModel,
  ReservationFiltersModel,
  ReservationMassActionsModel,
  ReservationPageTypeModel,
  SetReservationsDataRequest,
} from './models';
import {
  ActionPayload,
  BaseErrorResponse,
  BaseResponse,
} from 'store/_legacy/Models/ReduxModels';
import {
  BookingModel,
} from '../../../App/Store/Bookings/bookingDuck/models';

const UPDATE_RESERVATIONS_MASS_ACTIONS = 'UPDATE_RESERVATIONS_MASS_ACTIONS';
const RESET_RESERVATIONS_MASS_ACTIONS = 'RESET_RESERVATIONS_MASS_ACTIONS';
const UPDATE_RESERVATIONS_PAGE_TYPE = 'UPDATE_RESERVATIONS_PAGE_TYPE';
const UPDATE_RESERVATIONS_MASS_ACTIONS_IDS = 'UPDATE_RESERVATIONS_MASS_ACTIONS_IDS';
const UPDATE_BULK_RESERVATIONS_MASS_ACTIONS_IDS = 'UPDATE_BULK_RESERVATIONS_MASS_ACTIONS_IDS';
const RESET_RESERVATIONS_MASS_ACTIONS_IDS = 'RESET_RESERVATIONS_MASS_ACTIONS_IDS';
const SET_RESERVATIONS_DATA = 'SET_RESERVATIONS_DATA';

export const GET_RESERVATIONS = 'GET_RESERVATIONS';
export const GET_RESERVATIONS_SUCCESS = 'GET_RESERVATIONS_SUCCESS';
export const GET_RESERVATIONS_FAIL = 'GET_RESERVATIONS_FAIL';

export const CANCEL_RESERVATIONS_BOOKING = 'CANCEL_RESERVATIONS_BOOKING';
export const CANCEL_RESERVATIONS_BOOKING_FAIL = 'CANCEL_RESERVATIONS_BOOKING_FAIL';
export const CANCEL_RESERVATIONS_BOOKING_SUCCESS = 'CANCEL_RESERVATIONS_BOOKING_SUCCESS';

export const BULK_CANCEL_RESERVATIONS_BOOKING = 'BULK_CANCEL_RESERVATIONS_BOOKING';
export const BULK_CANCEL_RESERVATIONS_BOOKING_FAIL = 'BULK_CANCEL_RESERVATIONS_BOOKING_FAIL';
export const BULK_CANCEL_RESERVATIONS_BOOKING_SUCCESS = 'BULK_CANCEL_RESERVATIONS_BOOKING_SUCCESS';

const EXPORT_RESERVATIONS = 'EXPORT_RESERVATIONS';
const EXPORT_RESERVATIONS_SUCCESS = 'EXPORT_RESERVATIONS_SUCCESS';
const EXPORT_RESERVATIONS_FAIL = 'EXPORT_RESERVATIONS_FAIL';

export const GET_RESERVATIONS_BY_FLOORS = 'GET_RESERVATIONS_BY_FLOORS';
export const GET_RESERVATIONS_BY_FLOORS_SUCCESS = 'GET_RESERVATIONS_BY_FLOORS_SUCCESS';
export const GET_RESERVATIONS_BY_FLOORS_FAIL = 'GET_RESERVATIONS_BY_FLOORS_FAIL';

export const GET_RESERVATION_BOOKINGS_BY_LOCATION = 'GET_RESERVATION_BOOKINGS_BY_LOCATION';
export const GET_RESERVATION_BOOKINGS_BY_LOCATION_SUCCESS = 'GET_RESERVATION_BOOKINGS_BY_LOCATION_SUCCESS';
export const GET_RESERVATION_BOOKINGS_BY_LOCATION_FAIL = 'GET_RESERVATION_BOOKINGS_BY_LOCATION_FAIL';

const UPDATE_RESERVATIONS_LOADING = "UPDATE_RESERVATIONS_LOADING";

interface UpdateReservationMassActions {
  type: typeof UPDATE_RESERVATIONS_MASS_ACTIONS;
  payload: ReservationMassActionsModel;
}

interface UpdateBulkReservationMassActions {
  type: typeof UPDATE_BULK_RESERVATIONS_MASS_ACTIONS_IDS;
  payload: string[];
}

interface ResetReservationMassActions {
  type: typeof RESET_RESERVATIONS_MASS_ACTIONS;
}

interface UpdateReservationPageType {
  type: typeof UPDATE_RESERVATIONS_PAGE_TYPE;
  payload: ReservationPageTypeModel;
}

interface UpdateReservationMassActionsIds {
  type: typeof UPDATE_RESERVATIONS_MASS_ACTIONS_IDS;
  payload: string;
}

interface ResetReservationMassActionsIds {
  type: typeof RESET_RESERVATIONS_MASS_ACTIONS_IDS;
}

interface SetReservationsData {
  type: typeof SET_RESERVATIONS_DATA;
  payload: SetReservationsDataRequest;
}

// get list of all reservations
export interface GetReservations {
  type: typeof GET_RESERVATIONS;
  payload: ActionPayload<GetReservationsRequest>
}

interface GetReservationsSuccess {
  type: typeof GET_RESERVATIONS_SUCCESS;
  payload: BaseResponse<GetReservationsResponse>
}

interface GetReservationsFail {
  type: typeof GET_RESERVATIONS_FAIL;
  payload: BaseErrorResponse;
}

// get list of reservation bookings
export interface GetReservationsBookings {
  type: typeof GET_RESERVATION_BOOKINGS_BY_LOCATION;
  payload: ActionPayload<GetReservationsRequest>
}

interface GetReservationsBookingsSuccess {
  type: typeof GET_RESERVATION_BOOKINGS_BY_LOCATION_SUCCESS;
  payload: BaseResponse<GetReservationsResponse>
}

interface GetReservationsBookingsFail {
  type: typeof GET_RESERVATION_BOOKINGS_BY_LOCATION_FAIL;
  payload: BaseErrorResponse;
}

// cancel reservations
export interface CancelReservationsBooking {
  type: typeof CANCEL_RESERVATIONS_BOOKING;
  payload: ActionPayload<CancelReservationsBookingRequest>;
}
interface CancelReservationsBookingFail {
  type: typeof CANCEL_RESERVATIONS_BOOKING_FAIL;
  payload: BaseErrorResponse;
}
interface CancelReservationsBookingSuccess {
  type: typeof CANCEL_RESERVATIONS_BOOKING_SUCCESS;
  payload: CancelReservationsBookingResponse;
}

// cancel reservations bulk
export interface BulkCancelReservationsBooking {
  type: typeof BULK_CANCEL_RESERVATIONS_BOOKING;
  payload: ActionPayload<BulkCancelReservationsBookingModel>;
}
interface BulkCancelReservationsBookingFail {
  type: typeof BULK_CANCEL_RESERVATIONS_BOOKING_FAIL;
  payload: BaseErrorResponse;
}
interface BulkCancelReservationsBookingSuccess {
  type: typeof BULK_CANCEL_RESERVATIONS_BOOKING_SUCCESS;
  payload: string[];
}

// export reservations in csv
interface ExportReservations {
  type: typeof EXPORT_RESERVATIONS;
  payload: ActionPayload<ExportReservationsRequest>;
}

interface ExportReservationsFail {
  type: typeof EXPORT_RESERVATIONS_FAIL;
  payload: BaseErrorResponse;
}
interface ExportReservationsSuccess {
  type: typeof EXPORT_RESERVATIONS_SUCCESS;
  payload: ExportReservationsResponse;
}

// get list of all reservations by floors
export interface GetReservationsByFloors {
  type: typeof GET_RESERVATIONS_BY_FLOORS;
  payload: ActionPayload<GetReservationsRequest>
}

interface GetReservationsByFloorsSuccess {
  type: typeof GET_RESERVATIONS_BY_FLOORS_SUCCESS;
  payload: BaseResponse<GetReservationsByFloorResponse>
}

interface GetReservationsByFloorsFail {
  type: typeof GET_RESERVATIONS_BY_FLOORS_FAIL;
  payload: BaseErrorResponse;
}

interface UpdateReservationsLoading {
  type: typeof UPDATE_RESERVATIONS_LOADING;
  payload: boolean;
}

type Actions =
  | UpdateReservationMassActions
  | ResetReservationMassActions
  | UpdateReservationPageType
  | UpdateReservationMassActionsIds
  | UpdateBulkReservationMassActions
  | ResetReservationMassActionsIds
  | SetReservationsData
  | GetReservationsBookings
  | GetReservationsBookingsSuccess
  | GetReservationsBookingsFail
  | GetReservations
  | GetReservationsSuccess
  | GetReservationsFail
  | CancelReservationsBooking
  | CancelReservationsBookingFail
  | CancelReservationsBookingSuccess
  | BulkCancelReservationsBooking
  | BulkCancelReservationsBookingFail
  | BulkCancelReservationsBookingSuccess
  | ExportReservations
  | ExportReservationsFail
  | ExportReservationsSuccess
  | GetReservationsByFloors
  | GetReservationsByFloorsSuccess
  | GetReservationsByFloorsFail
  | UpdateReservationsLoading

export interface State {
  error: string;
  successMessage: string;
  loading: boolean;
  totalCount: number;
  limit: number;
  mapViewBookings: BookingModel[];
  reservations: BookingModel[];
  reservationsByFloors: ReservationByFloorModel[];
  reservationMassActions: ReservationMassActionsModel;
  reservationPageType: string;
  reservationMassActionsIds: string[];
  filters: ReservationFiltersModel;
}

export const initFiltersData: ReservationFiltersModel = {
  selectedStartDate: new Date(),
  selectedEndDate: new Date(),
  statuses: [],
  documentStatuses: [],
  search: '',
  locationIds: [],
  allSelected: false,
  checking: undefined,
};
const initialState: State = {
  error: '',
  successMessage: '',
  loading: false,
  totalCount: 0,
  limit: 10,
  mapViewBookings: [],
  reservations: [],
  reservationsByFloors: [],
  reservationMassActions: { 'map': false, 'list': false },
  reservationPageType: 'list',
  reservationMassActionsIds: [],
  filters: initFiltersData,
};

export default function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case UPDATE_RESERVATIONS_MASS_ACTIONS:
      return {
        ...state,
        reservationMassActions: action.payload,
      };
    case RESET_RESERVATIONS_MASS_ACTIONS:
      return {
        ...state,
        reservationMassActions: { 'map': false, 'list': false },
      };
    case UPDATE_RESERVATIONS_PAGE_TYPE:
      return {
        ...state,
        reservationPageType: action.payload.type,
      };
    case UPDATE_RESERVATIONS_MASS_ACTIONS_IDS: {
      const updatedIds = [...state.reservationMassActionsIds];
      const found = updatedIds.indexOf(action.payload);

      if (found != -1) {
        updatedIds.splice(found, 1);
      } else {
        updatedIds.push(action.payload);
      }

      return {
        ...state,
        reservationMassActionsIds: updatedIds,
      };
    }
    case UPDATE_BULK_RESERVATIONS_MASS_ACTIONS_IDS:
      return {
        ...state,
        reservationMassActionsIds: action.payload,
      };
    case RESET_RESERVATIONS_MASS_ACTIONS_IDS:
      return {
        ...state,
        reservationMassActionsIds: [],
      };

    case SET_RESERVATIONS_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case GET_RESERVATIONS:
      return {
        ...state,
        loading: true,
      };
    case GET_RESERVATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        reservations: action.payload.data.result.data.bookings,
        totalCount: action.payload.data.result.data.totalCount,
      };
    case GET_RESERVATIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: 'There was an error getting reservations. Please try again.',
      };

    case GET_RESERVATION_BOOKINGS_BY_LOCATION_SUCCESS: {
      return {
        ...state,
        mapViewBookings: action.payload.data.result.data.bookings,
      };
    }
    case GET_RESERVATION_BOOKINGS_BY_LOCATION_FAIL:
      return {
        ...state,
        error: 'There was an error getting reservation bookings. Please try again.',
      };

    case GET_RESERVATIONS_BY_FLOORS:
      return {
        ...state,
        loading: true,
      };
    case GET_RESERVATIONS_BY_FLOORS_SUCCESS:
      return {
        ...state,
        loading: false,
        reservationsByFloors: action.payload.data.result.data.bookings,
        totalCount: action.payload.data.result.data.totalCount,
      };
    case GET_RESERVATIONS_BY_FLOORS_FAIL:
      return {
        ...state,
        loading: false,
        error: 'There was an error getting reservations map view. Please try again.',
      };

    case CANCEL_RESERVATIONS_BOOKING_SUCCESS: {
      const reservations = [...state.reservations];
      const changedReservation = reservations.find(r => r.id === action.payload.bookingId);

      if (changedReservation) {
        changedReservation.status = 'CANCELED';
        const index = reservations.findIndex(x => x.id === action.payload.bookingId);
        reservations[index] = changedReservation;
      }

      return {
        ...state,
        reservations,
      };
    }
    case CANCEL_RESERVATIONS_BOOKING_FAIL:
      return {
        ...state,
        error: 'There was an error cancelling reservations. Please try again.',
      };

    case BULK_CANCEL_RESERVATIONS_BOOKING_SUCCESS: {
      return {
        ...state,
        reservations: state.reservations.map((booking) => ({
          ...booking,
          status: action.payload.includes(booking.id) ? "CANCELED" : booking.status,
        })),
        filters: {
          ...state.filters,
          allSelected: false,
        },
      };
    }
    case BULK_CANCEL_RESERVATIONS_BOOKING_FAIL:
      return {
        ...state,
        error: 'There was an error bulk cancelling reservations. Please try again.',
      };
    case UPDATE_RESERVATIONS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
}

// Actions
export function updateReservationMassActions(data: ReservationMassActionsModel): UpdateReservationMassActions {
  return {
    type: UPDATE_RESERVATIONS_MASS_ACTIONS,
    payload: data,
  };
}

export function resetReservationMassActions(): ResetReservationMassActions {
  return {
    type: RESET_RESERVATIONS_MASS_ACTIONS,
  };
}

export function updateReservationPageType(data: ReservationPageTypeModel): UpdateReservationPageType {
  return {
    type: UPDATE_RESERVATIONS_PAGE_TYPE,
    payload: data,
  };
}

export function updateReservationMassActionsIds(data: string): UpdateReservationMassActionsIds {
  return {
    type: UPDATE_RESERVATIONS_MASS_ACTIONS_IDS,
    payload: data,
  };
}

export function updateBulkReservationMassActionsIds(data: string[]): UpdateBulkReservationMassActions {
  return {
    type: UPDATE_BULK_RESERVATIONS_MASS_ACTIONS_IDS,
    payload: data,
  };
}


export function resetReservationMassActionsIds(): ResetReservationMassActionsIds {
  return {
    type: RESET_RESERVATIONS_MASS_ACTIONS_IDS,
  };
}

export function setReservationsData(data: SetReservationsDataRequest): SetReservationsData {
  return {
    type: SET_RESERVATIONS_DATA,
    payload: data,
  };
}

export function getReservations(data: GetReservationsRequest): GetReservations {
  let url = `/api/bookings/search?page=${data.page}&order=${data.order}&limit=${data.limit}&dateFrom=${data.dateFrom}&dateTo=${data.dateTo}`;
  if (data.statuses?.length) {
    url += `&status=${data.statuses.join(',')}`;
  }

  if (data.documentStatuses?.length) {
    url += `&documentStatus=${data.documentStatuses.join(',')}`;
  }

  if (data.checking !== undefined ) {
    url += `&checking=${data.checking}`;
  }

  if (data.search) {
    url += `&search=${data.search}`;
  }

  if (data.locationIds && data.locationIds.length) {
    url += `&locationIds=${data.locationIds}`;
  }

  return {
    type: GET_RESERVATIONS,
    payload: {
      request: {
        method: 'GET',
        url,
      },
    },
  };
}

export function getReservationsBookings(data: GetReservationsRequest): GetReservationsBookings {
  let url = `/api/bookings/search?page=${data.page}&order=${data.order}&limit=${data.limit}&dateFrom=${data.dateFrom}&dateTo=${data.dateTo}`;

  if (data.locationIds && data.locationIds.length) {
    url += `&locationIds=${data.locationIds}`;
  }

  if (data.statuses) {
    url += `&status=${data.statuses.join(",")}`;
  }

  if (data.floorIds) {
    url += `&floorIds=${data.floorIds.join(",")}`;
  }

  return {
    type: GET_RESERVATION_BOOKINGS_BY_LOCATION,
    payload: {
      request: {
        method: 'GET',
        url,
      },
    },
  };
}

export function getReservationsByFloors(data: GetReservationsRequest): GetReservationsByFloors {
  let url = `/api/bookings/reservations/map-view?page=${data.page}&order=${data.order}&limit=${data.limit}&dateFrom=${data.dateFrom}&dateTo=${data.dateTo}`;
  if (data.statuses?.length) {
    url += `&status=${data.statuses.join(',')}`;
  }

  if (data.search) {
    url += `&search=${data.search}`;
  }

  if (data.locationIds && data.locationIds.length) {
    url += `&locationIds=${data.locationIds}`;
  }

  return {
    type: GET_RESERVATIONS_BY_FLOORS,
    payload: {
      request: {
        method: 'GET',
        url,
      },
    },
  };
}

export function cancelReservationsBooking(data: CancelReservationsBookingRequest): CancelReservationsBooking {
  return {
    type: CANCEL_RESERVATIONS_BOOKING,
    payload: {
      request: {
        method: 'DELETE',
        url: `/api/bookings/${data.bookingId}`,
      },
    },
  };
}

export function bulkCancelReservationsBooking(data: BulkCancelReservationsBookingRequest): BulkCancelReservationsBooking {
  let url = `/api/bookings/cancel?&dateFrom=${data.dateFrom}&dateTo=${data.dateTo}`;

  if (data.cancelAll) {
    url += `&cancelAll=${data.cancelAll}`;

    if (data.statuses?.length) {
      url += `&status=${data.statuses.join(',')}`;
    }

    if (data.search) {
      url += `&search=${data.search}`;
    }

    if (data.locationIds && data.locationIds.length) {
      url += `&locationIds=${data.locationIds}`;
    }
  }

  return {
    type: BULK_CANCEL_RESERVATIONS_BOOKING,
    payload: {
      request: {
        method: 'DELETE',
        url,
        data: { bookingIds: data.bookingIds },
      },
    },
  };
}

export function exportReservations(data: ExportReservationsRequest): ExportReservations {
  let url = `/api/files/reservations?&dateFrom=${data.dateFrom}&dateTo=${data.dateTo}`;

  if (data.statuses?.length) {
    url += `&status=${data.statuses.join(',')}`;
  }

  if (data.documentStatuses?.length) {
    url += `&documentStatus=${data.documentStatuses.join(',')}`;
  }

  if (data.checking !== undefined ) {
    url += `&checking=${data.checking}`;
  }

  if (data.search) {
    url += `&search=${data.search}`;
  }

  if (data.locationIds && data.locationIds.length) {
    url += `&locationIds=${data.locationIds}`;
  }

  return {
    type: EXPORT_RESERVATIONS,
    payload: {
      request: {
        method: 'GET',
        url,
        isUploadFile: true,
      },
    },
  };
}

export const updateReservationsLoading = (payload: boolean): UpdateReservationsLoading => {
  return { type: UPDATE_RESERVATIONS_LOADING, payload };
};
