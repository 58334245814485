import { t } from "@lingui/macro";
import { RecurrenceType } from "types";

export const resolveRecurrenceTypeLabel = (type: RecurrenceType | "none"): string => {
  switch (type) {
    case "none": 
      return t`None`;
    case "daily":
      return t`Daily`;
    case "weekly":
      return t`Weekly`;
    case "monthly":
      return t`Monthly`;
    default:
      return "";
  }
};
