import Box from 'components/_legacy/Box';
import CustomTooltip from './CustomTooltip';
import Select from 'components/_legacy/Select';
import styles from './styles.module.scss';
import Text from 'components/_legacy/Text';
import { DashboardsTotalByDomainPeriod, dashboardsTotalByDomainArray } from 'Admin/Store/locationDashboardDuck/models';
import { getLocationDashboardTotalByDomain, selectDomainsSortedByDomainNameLength } from 'Admin/Store/locationDashboardDuck';
import { SizeMe } from 'react-sizeme';
import { Trans } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTypedSelector } from 'store/_legacy/Redux/store';
import {
  Bar,
  BarChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

export default function TotalByDomain() {
  const dispatch = useDispatch();
  const { locationId } = useParams<{ locationId: string }>();

  const [period, setPeriod] = useState<DashboardsTotalByDomainPeriod>('thisWeek');

  const { config, locationDashboard } = useTypedSelector(state => state);

  useEffect(() => {
    dispatch(getLocationDashboardTotalByDomain({ locationId, period }));
  }, [locationId, period]);

  const counts = locationDashboard.totalCountByDomain[locationId] ?? [];
  const countsSortedByDomainNameLength = selectDomainsSortedByDomainNameLength(locationDashboard, locationId);

  return (
    <Box className={styles.container} dataTestId="dashboard-total-by-domain">
      <Box alignItems='center' display='flex' justifyContent='between'>
        <Box className={styles.inputContainer}>
          <Box alignItems='center' display='flex' gap={10}>
            <Text inline overflow='noWrap' weight="bold">
              <Trans>
                Total by
              </Trans>
            </Text>
            <Text inline>|</Text>
          </Box>

          <Select
            classes={{
              input: styles.input,
              wrapper: styles.select,
              // materialSelect: styles.materialInput,
            }}
            data-testid="dashboard-total-by-domain-select"
            iconColor="#000000"
            id="status"
            items={dashboardsTotalByDomainArray}
            labelColor="gray"
            onChange={(event: any) => setPeriod(event.target.value)}
            value={period}
          />
        </Box>

        <Box alignItems='center' display='flex' gap={10}>
          <Box alignItems='center' display='flex' gap={5}>
            <Box
              backgroundColor={config.theme.primary}
              borderRadius={2}
              height={10}
              width={10}
            />

            <Text color="gray" size="sm">
              <Trans>
                Booked, checked in
              </Trans>
            </Text>
          </Box>

          <Box alignItems='center' display='flex' gap={5}>
            <Box
              backgroundColor={config.theme.primaryLighten50}
              borderRadius={2}
              height={10}
              width={10}
            />

            <Text color="gray" size="sm">
              <Trans>
                Booked, not checked in
              </Trans>
            </Text>
          </Box>

          <Box alignItems='center' display='flex' gap={5}>
            <Box
              backgroundColor={config.theme.warn}
              borderRadius={2}
              height={10}
              width={10}
            />

            <Text color="gray" size="sm">
              <Trans>
                Cancelled
              </Trans>
            </Text>
          </Box>
        </Box>
      </Box>

      <SizeMe>
        {({ size }) => {
          return (
            <div data-testid="dashboard-total-by-domain-chart">
              <BarChart
                data={counts}
                // Calculate the height based on a fixed value (20) + how many domains have on the graph * 40,
                // making sure that the graph has a height that shows all domains
                height={20 + (counts.length * 40)}
                layout='vertical'
                margin={{
                  // Applies a left margin based on the longest domain name, making sure that all domain names appears on the graph
                  left: countsSortedByDomainNameLength.length > 0 ? countsSortedByDomainNameLength[0].domain.length * 7 : 0,
                }}
                width={size.width ?? undefined}
              >
                {counts.length > 0 ? (
                  <>
                    <Tooltip
                      content={<CustomTooltip />}
                      cursor={{ fill: 'transparent' }}
                    />

                    <Bar
                      barSize={10}
                      dataKey="bookedAndCheckedIn"
                      fill={config.theme.primary}
                      stackId="a"
                      yAxisId="left-axis"
                    />
                    <Bar
                      barSize={10}
                      dataKey="bookedAndNotCheckedIn"
                      fill={config.theme.primaryLighten50}
                      stackId="a"
                      width={2}
                      yAxisId="left-axis"
                    />
                    <Bar
                      barSize={10}
                      dataKey="cancelled"
                      fill={config.theme.warn}
                      stackId="a"
                      width={2}
                      yAxisId="left-axis"
                    />

                    <XAxis
                      allowDecimals={false}
                      axisLine={{
                        stroke: config.theme.primaryLight,
                      }}
                      stroke="#5E5E5E"
                      tickLine={false}
                      type="number"
                    />
                    <YAxis
                      axisLine={{
                        stroke: config.theme.primaryLight,
                      }}
                      dataKey="domain"
                      stroke="#5E5E5E"
                      type="category"
                      yAxisId="left-axis"
                    />
                    <YAxis
                      axisLine={{
                        stroke: config.theme.primaryLight,
                      }}
                      dataKey="domain"
                      orientation="right"
                      tickFormatter={() => ''}
                      tickLine={false}
                      tickMargin={0}
                      type="category"
                      width={1}
                      yAxisId="right-axis"
                    />
                  </>
                ) : (
                  <>
                    <XAxis
                      axisLine={{
                        stroke: config.theme.primaryLight,
                      }}
                      stroke="#5E5E5E"
                      tickFormatter={() => ''}
                      tickLine={false}
                      type="number"
                    />
                    <YAxis
                      axisLine={{
                        stroke: config.theme.primaryLight,
                      }}
                      stroke="#5E5E5E"
                      tickFormatter={() => ''}
                      tickLine={false}
                      type="category"
                    />
                  </>
                )}
              </BarChart >
            </div>
          );
        }}
      </SizeMe>
    </Box>
  );
}
