import { t } from "@lingui/macro";
import { Schedule } from "App/Store/Meetings/meetingSchedule/models";
import hoursMinutesToNumber from "Functions/hoursMinutesToNumber.ts";
import numberToHoursMinutes from "Functions/numberToHoursMinutes";
import moment, { Moment } from "moment";
import { MutableRefObject } from "react";

export const BLOCK_DEFAULT_HEIGHT = 63; //63 pixels 
export const BLOCK_DEFAULT_WIDTH = 45; //45 pixels
export const TIME_STEP_CALENDAR = 0.25; //It is equivalent a 15 minutes.
export const INITIAL_CALENDAR_HOUR = 0; //The schedule available time starts from 00:00
export const FINAL_CALENDAR_HOUR = 24; // And goes to 23:59
export const INITIAL_HORIZONTAL_SCROLL_POSITION = 1252; //It is on 7 AM
export const INITIAL_VERTICAL_SCROLL_POSITION = 840; //It is on 7 AM

export interface Item {  
  roomId?: string;
  day?: Moment;
  hours: Array<number>;
}

export enum CalendarType {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL'
}

export function meetingHasMinimumDuration(meetingFrom: Date | undefined, meetingTo: Date | undefined) {  
  if (!meetingFrom || !meetingTo) return false;
 
  const durationInMinnutes = moment(meetingTo).diff(moment(meetingFrom), 'minute');  

  return durationInMinnutes >= 30;
}

/*

  Takes the actual minutes from meeting and round up to actual block value multiple. 

  Example:  

  TIME_STEP_CALENDAR = 0.25 (1/4 from 1) => it is equal 15 minutes (1/4 from 60 minutes).
  minutes = 27 => it will return 30 minutes.
  minutes = 9 => it will return 15 minutes.
*/

export function roundUpMinutes(minutes: number) {  
  const actualBlockValue = TIME_STEP_CALENDAR * 60;

  const minutesDivided = minutes/actualBlockValue;

  if (minutesDivided > 0) return Math.ceil(minutesDivided) * actualBlockValue;
  else if (minutesDivided < 0) return Math.floor(minutesDivided) * actualBlockValue;
  else return minutes;
}

/*

  Takes the meeting's schedule and transform it on values to put
  on calendar.

  Example:

  Schedule start date => 10.30AM
  Schedule end date => 11:15AM  

  it will be

  Schedule start value on calendar => 10.5;
  Schedule end value on calendar => 11.25;
*/

function getMinutesRangeFromSchedule(schedule: Schedule) {
  const startDateTimezoned = new Date(schedule.startDate);
  const endDateTimezoned = new Date(schedule.endDate);

  const startDateRoundUpMinutes = roundUpMinutes(startDateTimezoned.getMinutes());
  const endDateRoundUpMinutes = roundUpMinutes(endDateTimezoned.getMinutes());

  startDateTimezoned.setMinutes(startDateRoundUpMinutes);
  endDateTimezoned.setMinutes(endDateRoundUpMinutes);

  const startSchedule = hoursMinutesToNumber(startDateTimezoned);
  const endSchedule = hoursMinutesToNumber(endDateTimezoned);    

  return { startSchedule, endSchedule };
}

/*
  Its generates the hours to put on calendar. 

  Example
  Meeting start on 10 AM to 11 AM, so the function it will generate 
  the hours between it, like 10:15AM, 10:30AM, etc.
*/

export function calculateHoursBetween(itemSelected: MutableRefObject<Item | undefined>, hoursMap: Array<number>, newHour: number) {
  if (!itemSelected || !itemSelected.current) return [newHour];

  const firstHour = itemSelected.current.hours[0];    

  const newHours = new Array<number>();

  newHours.push(firstHour);    

  hoursMap.map((hour) => {
    if (firstHour > newHour) {
      if (hour > newHour && hour < firstHour) {
        newHours.push(hour);          
      }                
    } else {
      if (hour > firstHour && hour < newHour) {
        newHours.push(hour);                  
      }        
    }      
  });

  newHours.push(newHour);    

  return newHours;
}

/*
  Check if has some item between time range selected
*/

export function isBetweenSomeItem(type: CalendarType,  itemSelected: MutableRefObject<Item | undefined>,
  scheduleArray: Schedule[], hour: number, { roomId, day }: { roomId?: string, day?: Moment }){
  if (!itemSelected || !itemSelected.current) return false;

  const currentHours = type === CalendarType.HORIZONTAL ?
      (itemSelected.current.roomId === roomId? itemSelected.current.hours: [hour]):
       (itemSelected.current.day?.isSame(day, 'day')? itemSelected.current.hours: [hour]);

  const filterQuery = type == CalendarType.HORIZONTAL ? (schedule: Schedule) => schedule.roomId === roomId : 
    (schedule: Schedule) => {
      const startDateTimezoned = new Date(schedule.startDate);
      const endDateTimezoned = new Date(schedule.endDate);

      return day?.isSame(startDateTimezoned, 'day') && day?.isSame(endDateTimezoned, 'day');      
    };

  return scheduleArray.filter(filterQuery)
    .some((schedule) => {
      const { startSchedule, endSchedule } = getMinutesRangeFromSchedule(schedule);
      
      const [newMeetingStart, newMeetingEnd] = currentHours;            
      
      if (!newMeetingEnd || hour > newMeetingEnd) return startSchedule > newMeetingStart && endSchedule <= hour;

      if (newMeetingStart > newMeetingEnd || newMeetingStart > hour) return startSchedule > hour && endSchedule <= newMeetingStart;      

      return hour >= startSchedule && hour <= endSchedule;
    });
}

/*
  Given a selected hour its verify if has some already scheduled meeting on the same hour, day and room.
*/

export function getScheduleMeeting(type: CalendarType, scheduleArray: Schedule[], hour: number, dateFrom: Date | undefined, { roomId, day }: {roomId?: string, day?: Moment}) {
  const filterQuery = type === CalendarType.VERTICAL ? (schedule: Schedule) => day?.isSame(schedule.startDate, 'day'):
    (schedule: Schedule) => schedule.roomId === roomId && moment(schedule.startDate).isSame(dateFrom, 'day');

  const scheduledMeeting = scheduleArray.filter(filterQuery)
    .find((schedule) => {            
      const { startSchedule, endSchedule } = getMinutesRangeFromSchedule(schedule);

      return startSchedule <= hour && endSchedule > hour;
    });
      
  return scheduledMeeting;
}

/*

  Takes a itemSelected to calculate the meetingFrom and meetingTo values.

  Example:

  itemSelected: {
    hours: [10.25, 16.0] //it is the user select values on calendar
  };

  The function will return a Date value from calendar values, so the return in this example
  will be 10:15 AM to 4PM.
*/

export function calculateMeetingTimetable(itemSelected: MutableRefObject<Item | undefined>, 
    day: Moment | Date | undefined): { newMeetingFrom: Date, newMeetingTo: Date } {                

    if (!itemSelected || !itemSelected.current)
      return { newMeetingFrom: new Date(), newMeetingTo: new Date() };

    let firstItem = itemSelected.current.hours[0];
    let lastItem = itemSelected.current.hours[itemSelected.current.hours.length - 1];
        
    if (firstItem > lastItem) firstItem += TIME_STEP_CALENDAR; 
    else lastItem += TIME_STEP_CALENDAR;

    let timeFromHours, timeFromMinutes, timeToHours, timeToMinutes;

    const firstItemHoursAndMinutes = numberToHoursMinutes(firstItem);
    const lastItemHoursAndMinutes = numberToHoursMinutes(lastItem);
    
    if (firstItem > lastItem) {
      timeFromHours = lastItemHoursAndMinutes.hours;
      timeFromMinutes = lastItemHoursAndMinutes.minutes;
      timeToHours = firstItemHoursAndMinutes.hours;
      timeToMinutes = firstItemHoursAndMinutes.minutes;
    } else {
      timeFromHours = firstItemHoursAndMinutes.hours;
      timeFromMinutes = firstItemHoursAndMinutes.minutes;
      timeToHours = lastItemHoursAndMinutes.hours;
      timeToMinutes = lastItemHoursAndMinutes.minutes;
    }        
    
    const meetingFrom = moment(day)      
      .set('hours', timeFromHours)  
      .set('minute', timeFromMinutes).utc().toDate();
    const meetingTo = moment(day)      
      .set('hours', timeToHours)
      .set('minute', timeToMinutes).utc().toDate();    

    return { newMeetingFrom: meetingFrom, newMeetingTo: meetingTo };    
}

/*
  It is responsible to choose the block class from calendar. So each block could be:
  - block enable, that is a block enable to user select;
  - block disabled, block is disabled to select;
  - block scheduled, block already have some meeting scheuled;
  - block selected, block is selected from user to create a new meeting.
*/

export function blockClass(type: CalendarType, scheduleArray: Schedule[], itemSelected: MutableRefObject<Item | undefined>,
  hour: number, styles: any, dateFrom: Date | undefined, { roomId, day }: {roomId?: string, day?: Moment}): any {
    const filterQuery = type === CalendarType.VERTICAL ?
      (schedule: Schedule) => day?.isSame(schedule.startDate, 'day'):
      (schedule: Schedule) => schedule.roomId === roomId && moment(schedule.startDate).isSame(dateFrom, 'day');    

    if (scheduleArray.some(filterQuery)) {
      const scheduledMeeting = getScheduleMeeting(type, scheduleArray, hour, dateFrom, { roomId, day });      

      if (scheduledMeeting) {
        const { startSchedule, endSchedule } = getMinutesRangeFromSchedule(scheduledMeeting);

        if (startSchedule === hour) return styles.blockFirstScheduled;
        if (endSchedule === (hour + TIME_STEP_CALENDAR)) return styles.blockLastScheduled;

        return styles.blockScheduled;      
      }
    }    

    const currentHours = hoursMinutesToNumber(moment().toDate());

    if (moment().isSame(day, 'day') && currentHours >= hour) return styles.blockDisabled;

    if (!itemSelected || !itemSelected.current) return styles.block;

    if (type == CalendarType.VERTICAL && (!itemSelected.current.day || !itemSelected.current.day.isSame(day, 'day'))) return styles.block;    

    if (type == CalendarType.HORIZONTAL && (itemSelected.current.roomId !== roomId)) return styles.block;

    const currentItem = itemSelected.current;    

    const firstHourSelected = currentItem.hours[0];
    const lastHourSelected = currentItem.hours[currentItem.hours.length - 1];
    const isSelectedReversed = firstHourSelected > lastHourSelected;    
    
    if (currentItem.hours.length > 1 && currentItem.hours.includes(hour) && hour === firstHourSelected)
      return isSelectedReversed ? styles.blockLastSelected: styles.blockFirstSelected;      

    if (currentItem.hours.length > 1 && currentItem.hours.includes(hour) && hour === lastHourSelected)
      return isSelectedReversed ? styles.blockFirstSelected: styles.blockLastSelected;      

    if (currentItem.hours.includes(hour)) return styles.blockSelected;

    return styles.block;
}

/*
  It is responsible to choose what tooltip helper text on Calendars (both horizontal and vertical) it will show when
  user hover a block
*/

export function tooltipText(type: CalendarType, scheduleArray: Schedule[], hour: number, dateFrom: Date | undefined, { roomId, day }: {roomId?: string, day?: Moment}) {
  const scheduledMeeting = getScheduleMeeting(type, scheduleArray, hour, dateFrom, { day, roomId });

  if (scheduledMeeting) return scheduledMeeting.summary || t`Time already scheduled`; 
    
  const currentHours = hoursMinutesToNumber(moment().toDate());

  const dayToCompare = type === CalendarType.VERTICAL? day: dateFrom;

  if (moment().isSame(dayToCompare, 'day') && currentHours >= hour) 
      return t`Date and time passed. Please select another date and time.`;

  return t`Click to select time`;              
}

/*
  Takes a list of strings and merge it using 'and' and ','

  Examples:

  ['one'] => 'one';
  ['one', 'two'] => 'one and two';
  ['one', 'two', 'three'] => 'one, two and three';


  Oxford comma is to add a comma after the 'and', if this option is true:

  ['one', 'two', 'three'] => 'one, two and, three'
*/

export function makeCommaSeparatedString(stringArr: String[], useOxfordComma = false) {
  const listStart = stringArr.slice(0, -1).join(', ');
  const listEnd = stringArr.slice(-1);
  const conjunction = stringArr.length <= 1 ? '' 
    : useOxfordComma && stringArr.length > 2 
      ? ', and ' 
      : ' and ';

  return [listStart, listEnd].join(conjunction);
}


/*
  Takes a from and to number and creates all the numbers between it to build the calendar.

  Examples:

  from: 10, to: 16, TIME_STEP_CALENDAR = 0.25
  [10, 16] => 10, 10.25, 10.50, 10.75...16

  The TIME_STEP_CALENDAR choose the timerange from calendar, for example 0.25 it will be equal 15 minutes,
  0.5 it will be 30 minutes.
*/

export function getAllNumbersBetween(type: CalendarType, from: number, to: number) {
  if (type === CalendarType.VERTICAL && from === to) return [from];

  const numbers = [];

  for (let i = from; i <= to; i+=TIME_STEP_CALENDAR) numbers.push(i);

  return numbers;
}

/*

  During the render of CalendarHorizontal it is necessary to show
  the full meeting's summary. This function creates a relative div that
  it is positionate in front of this meeting's blocks with the summary and
  the possibility to hover it.

*/

export function createRelativeDiv(meetingDivs: Element[], schedule: Schedule) {
  const generalPaddingFromApp = 180;
  
  const firstDiv = meetingDivs[0];

  const firstPosition = firstDiv.getBoundingClientRect().left;          
  
  /* 
    Its calculate the position from tooltip with meeting's summary.
    The position uses the first meeting block position minus the general padding from app
    and 50 pixels to positionate the tooltip on right.
  */
  
  const leftPositionCalculated = firstPosition - generalPaddingFromApp - 50;

  const width = meetingDivs.length * BLOCK_DEFAULT_WIDTH;

  const relativeDiv = document.createElement("div");

  relativeDiv.setAttribute("style", "position: relative; width: 0; height: 0");          

  relativeDiv.setAttribute("id", schedule.id);

  relativeDiv.innerHTML = `<div
      style="display: flex;
            align-items: center;
            justify-content: center;            
            width: ${width}px;
            height: ${BLOCK_DEFAULT_HEIGHT}px;
            position: absolute;
            border-radius: 6px;
            left: ${leftPositionCalculated}px;
            background: #FFE4DB;
    ">
      <div class="break-word truncate tooltip">
        ${schedule.summary}                                 

        <span class="tooltipText">
          ${schedule.summary}
        </span>
      </div>              
    </div>`;       
    
  return relativeDiv;
}

/*
  It is responsible to handle calendar (both horizontal and vertical) click
*/

export function handleCalendarClick(type: CalendarType, clicksCount: MutableRefObject<number | undefined>, itemSelected: MutableRefObject<Item | undefined>, scheduleArray: Schedule[],
  hour: number, hours: Array<number>, dateFrom: Date | undefined,
  { roomId, day }: { roomId?: string, day?: Moment }) {

  // Verify if has any already scheduled meeting on selected hour
  const scheduledMeeting = getScheduleMeeting(type, scheduleArray, hour, dateFrom, { day, roomId });

  if (scheduledMeeting) return;

  /* It is the counter from calendar. When the counter receives 3 clicks, it should reset.
    On HORIZONTAL calendar when the roomId is different means that user select another room, so the counter should reset.
    On VERTICAL calendar when the day is different means that user select another day, so the counter should reset.
  */
  
  clicksCount.current = type === CalendarType.HORIZONTAL?
    (itemSelected?.current?.roomId === roomId ? (clicksCount.current || 0) + 1: 0):
    (itemSelected?.current?.day?.isSame(day, 'day') ? (clicksCount.current || 0) + 1: 0);
     
  if (clicksCount.current === 2) return { clicksCount };
  
  const isBetween = isBetweenSomeItem(type, itemSelected, scheduleArray, hour, { day, roomId });    

  if (isBetween) return;
  
  //Its generates the hours values to put on calendar  
  const calculatedHours = type === CalendarType.HORIZONTAL?
    (itemSelected?.current?.roomId === roomId? calculateHoursBetween(itemSelected, hours, hour): [hour]):
    (itemSelected?.current?.day === day? calculateHoursBetween(itemSelected, hours, hour): [hour]);

  if (type === CalendarType.HORIZONTAL) {
    itemSelected.current = {
      roomId,
      hours: calculatedHours,
    };     
  } else {
    itemSelected.current = {
      day, 
      hours: calculatedHours,
    };    
  }

  //Its translate the calendars block values to date values
  const { newMeetingFrom, newMeetingTo } = calculateMeetingTimetable(itemSelected, day);

  return { newMeetingFrom, newMeetingTo, itemSelected, clicksCount };
}