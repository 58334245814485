import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useTypedSelector } from 'store/_legacy/Redux/store';
import moment from 'moment';
import _ from 'underscore';

import Box from 'components/_legacy/Box';
import { setCreateNewMeetingData } from 'App/Store/Meetings/createNewMeetingDuck';
import { getFloorSchema } from 'Admin/Store/floorMapApiRequestsDuck';
import { usePrevious } from '../../../../Functions/hooks';
import { getUserIdForBooking } from "../../../Store/Users/executiveAssistant/helpers";
import { getRooms } from 'App/Store/Meetings/meetingRooms';
import { getFloorRoomSchedule } from 'App/Store/Meetings/meetingSchedule';
import { endOfMoment, startOfMoment } from '../../../Functions/Helpers';
import { ErrorCreateNewMeetingSnackbar } from "../SnackBars";
import MeetingCreatedModal from "./MeetingCreatedModal";
import MeetingViewTabs from "./MeetingViewTabs";
import MeetingBooking from "./MeetingBooking";

export default function Room() {
  const dispatch = useDispatch();
  const {
    profile,
    executiveAssistant,    
    createNewMeeting,
  } = useTypedSelector(state => state);

  const {
    dateFrom,
    dateTo,
    selectedData,
    timeFrom,
    timeTo,
  } = createNewMeeting;

  const { roomId, location, floor } = selectedData;
  const calendarData = { dateFrom, dateTo };
  const { selectedUser } = executiveAssistant;

  const prevCalendarData = usePrevious(calendarData);

  const getFloorRoomsSchedule = () => {
    if (!location || !floor || !dateFrom || !dateTo) return;    

    const locationId = location.id;
    const floorId = floor.id;
    const startDate = startOfMoment(dateFrom);
    const endDate = endOfMoment(dateTo);
    const data = {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),        
    };
    dispatch(getFloorRoomSchedule({ data: { ...data }, locationId, floorId }));
  };

  const getRoomsByLocationAndFloor = () => {
    if (!location || !floor) return;

    const locationId = location.id;
    const floorId = floor.id;
    const formattedDate = moment(dateFrom).format("YYYY-MM-DD");    
    const startTime = moment(timeFrom).format("HH:mm:ssZ");
    const endTime = moment(timeTo).format("HH:mm:ssZ");
    const custom = [
      { key: 'availabilityStartDate', value: formattedDate },
      { key: 'availabilityEndDate', value: formattedDate },
      { key: 'availabilityStartTime', value: startTime },
      { key: 'availabilityEndTime', value: endTime },
    ];    

    dispatch(getRooms({ locationId, floorId, custom }));  
    getFloorRoomsSchedule();  
  };

  useEffect(() => {
    if (floor) {
      const selectedUserId = getUserIdForBooking(profile, executiveAssistant);
      const floorId = floor.id;
      // call admin desks data for executiveAssistant role (need to handle desks with another owners in MapDrawing)
      selectedUserId !== profile.id ? dispatch(getFloorSchema({ floorId, showOwners: true, includeRooms: true }))
        : dispatch(getFloorSchema({ floorId, includeRooms: true }));
    }
  }, [floor, selectedUser]);

  useEffect(() => {
    getRoomsByLocationAndFloor();
  }, [location, floor]);

  useEffect(() => {
    if (!roomId)
      dispatch(setCreateNewMeetingData({
        meetingFrom: undefined,
        meetingTo: undefined,
        selectedData: {
          ...selectedData,
          roomId: null,
          attendees: [],
        }}));
  }, [roomId]);

  useEffect(() => {
    const isCalendarDataChange = prevCalendarData && !_.isEqual(calendarData, prevCalendarData);
    if (isCalendarDataChange) {
      getRoomsByLocationAndFloor();
      dispatch(setCreateNewMeetingData({
        selectedData: {
          ...selectedData,
          roomId: null,
          attendees: [], 
        }}));
    }
  }, [dateFrom, dateTo, timeFrom, timeTo]);

  return (
    <>
      <ErrorCreateNewMeetingSnackbar />
      <MeetingCreatedModal />
      
      <Box display="grid" gridTemplateColumns="8fr 4fr" paddingBottom={50}>
        <MeetingViewTabs />
        <MeetingBooking />
      </Box>
    </>
  );
}
