import Box from 'components/_legacy/Box';
import LocationBodyItem from './LocationBodyItem';
import LocationHeader from 'components/_legacy/Header/LocationHeader';
import styles from './styles.module.scss';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Text from 'components/_legacy/Text';
import { AdminLocation } from '../../../App/Store/Locations/locationsDuck/models';
import {
  getAdminLocations,
  setLocationsData,
  setLocationsSearch,
} from '../../../App/Store/Locations/locationsDuck';
import { Trans, t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useTypedSelector } from 'store/_legacy/Redux/store';
import { ErrorAdminLocationsSnackbar, SuccessAdminLocationsSnackbar } from './SnackBars';
import { getAllowedLocationsByRoles, getTimezoneList } from "./Helpers";

const headCells = [
  { id: 'name', label: t`Location name` },
  { id: 'country', label: t`Country` },
  { id: 'city', label: t`City` },
  { id: 'address', label: t`Address` },
  { id: 'timezone', label: t`Timezone` },
  { id: 'reservations', label: t`Reservation settings` },
  { id: 'floors', label: t`Floors` },
  { id: 'desks', label: t`Desks` },
  { id: 'options', label: '' },
];

function getLocationsBySearch(locations: AdminLocation[], search: string) {
  // make search only for next fields
  const fieldsToSearch = ['city', 'country', 'locationName', 'locationAddress'];

  return locations
    .filter(item => {
      // @ts-ignore
      return Object.keys(item).some(key => fieldsToSearch.includes(key) && item[key].toLowerCase().includes(search.toLowerCase()));
    });
}

export default function Locations() {
  const dispatch = useDispatch();
  const { locations, profile } = useTypedSelector(state => state);
  const { roleAccess, locationIds } = profile;

  useEffect(() => {
    const locationTimezones = getTimezoneList();
    dispatch(setLocationsData({ locationTimezones }));
    dispatch(getAdminLocations({ activeOnly: false })); // get locations with floor + desk count
  }, []);

  useEffect(() => {
    // clear search on page load
    dispatch(setLocationsSearch({ locationSearch: '' }));
  }, [locations.adminLocations]);

  // set restrictions for local admin role
  const allowedLocations = getAllowedLocationsByRoles(roleAccess, locations.adminLocations, locationIds);
  const filteredLocations = getLocationsBySearch(allowedLocations, locations.locationSearch);

  return (
    <>
      <SuccessAdminLocationsSnackbar />
      <ErrorAdminLocationsSnackbar />

      <LocationHeader />

      <Box className={styles.floors} dataTestId="locations" marginTop={20}>
        <TableContainer>
          <Table
            aria-label={t`locations table list`}
            className={styles.locationTable}
          >
            <TableHead classes={{ root: styles.tableHead }}>
              <TableRow classes={{ root: styles.tableRow }}>
                {headCells.map((headCell) => (
                  <TableCell classes={{ root: styles.tableCell }} key={headCell.id} padding="none">
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody classes={{ root: styles.tableBody }}>
              {filteredLocations.map((location: AdminLocation, index) => {
                return <LocationBodyItem key={index} location={location} />;
              })}
            </TableBody>
          </Table>
        </TableContainer>

        {!filteredLocations.length && (
          <Box marginTop={100}>
            <Text align="center" color="lightGray" size="md">
              <Trans>No Locations found</Trans>
            </Text>
          </Box>
        )}
      </Box>
    </>
  );
}
