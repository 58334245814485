import { t } from "@lingui/macro";
import { Box, Dialog, DialogContent, DialogTitle, Divider, IconButton, Popover, Tooltip, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { RecurrenceConflictSchedule, RecurrenceConflictsDialogProps } from "./types";
import { formatInTimeZone } from "date-fns-tz";
import { EventBusy, InfoOutlined } from "@mui/icons-material";

export const RecurrenceConflictsDialog: React.FC<RecurrenceConflictsDialogProps> = (props) => {
  const { open, conflicts, timeZone, onClose } = props;
  const { palette } = useTheme();
  const [conflictScheduleAnchorEl, setConflictScheduleAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [conflictSchedule, setConflictSchedule] = useState<RecurrenceConflictSchedule>();
  
  const handleConflictScheduleClick = (el: HTMLButtonElement, schedule: RecurrenceConflictSchedule) => {
    setConflictScheduleAnchorEl(el);
    setConflictSchedule(schedule);
  };

  const handleConflictScheduleClose = () => {
    setConflictScheduleAnchorEl(null);
    setConflictSchedule(undefined);
  };

  return (
    <>
      <Dialog onClose={onClose} open={open}>
        <DialogTitle>
          <Typography fontSize={18} fontWeight="600">{t`Conflicted days for selected days`}</Typography>
        </DialogTitle>
        <DialogContent>
          <Box maxHeight={350} minHeight={250} minWidth={420} sx={{ overflowY: "auto", overflowX: "hidden" }}>
            {conflicts?.map(({ startDate, endDate, schedule }) => (
              <Box
                alignItems="center"
                bgcolor={palette.error.light}
                borderRadius={2}
                display="flex"
                height={38}
                justifyContent="space-between"
                key={startDate.getTime()}
                mb={1.5}
                px={2}
              >
                <Typography fontSize={14}>
                  {formatInTimeZone(startDate, timeZone, "d LLL, yyyy-HH:mm")}
                  {"-"}
                  {formatInTimeZone(endDate, timeZone, "HH:mm")}
                </Typography>
                <Box alignItems="center" display="flex">
                  <Tooltip title={t`View conflicting event`}>
                    <IconButton
                      onClick={(event) => handleConflictScheduleClick(event.currentTarget, schedule)}
                      size="small"
                      sx={{ padding: 0, color: palette.grey[700] }}
                    >
                      <InfoOutlined fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            ))}
          </Box>
        </DialogContent>
      </Dialog>
      <Popover
        anchorEl={conflictScheduleAnchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={handleConflictScheduleClose}
        open={!!conflictScheduleAnchorEl && !!conflictSchedule}
        sx={{ mt: 1 }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {conflictSchedule ? (
          <Box minWidth={280} p={2}>
            <Box alignItems="center" display="flex">
              <EventBusy sx={{ color: palette.grey[700], mr: 1 }} />
              <Typography fontSize={14}>
                {formatInTimeZone(conflictSchedule.startDate, timeZone, "d LLL, HH:mm")}
                {"-"}
                {formatInTimeZone(conflictSchedule.endDate, timeZone, "HH:mm")}
              </Typography>
            </Box>
            <Divider sx={{ mt: 0.5, mb: 1 }} />
            <Typography color={palette.grey[700]} fontSize={14}>{conflictSchedule.summary}</Typography>
          </Box>
        ) : undefined}
      </Popover>
    </>
  );
};