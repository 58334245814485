import React, { useEffect } from "react";
import { ReservationCateringDetailsDialogProps } from "./types";
import { Box, Dialog, Divider, Grid, Skeleton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Attendee, ReservationServiceOptionType, ReservationServiceRequestSettingType, ReservationServiceType, useLazyGetReservationByIdQuery } from "store";
import { IconBox } from "../box";
import { TogetherFloor, TogetherRoom, TogetherUser } from "components/icons";
import { plural, t } from "@lingui/macro";
import { BasicChip } from "components/chip";
import { LocationOnOutlined } from "@mui/icons-material";
import { union } from "underscore";
import { isNumberString } from "class-validator";
import { format } from "date-fns";
import { ReservationCateringDetails } from "./reservation-catering-details";
import { resolveReservationStatusLabel } from "utils/resolve-reservation-status-label";
import { resolveReservationStatusColor } from "utils/resolve-reservation-status-color";

export const ReservationCateringDetailsDialog: React.FC<ReservationCateringDetailsDialogProps> = (props) => {
  const { reservationId, entryId, open, onClose } = props;
  const { palette, breakpoints } = useTheme();
  const xl = useMediaQuery(breakpoints.up("xl"));
  const [triggerGetReservationByIdQuery, getReservationByIdQuery] = useLazyGetReservationByIdQuery();
  
  useEffect(() => {
    if (reservationId) {
      triggerGetReservationByIdQuery({
        reservationId,
        include: [
          "user",
          "schedule",
          "schedule.entries",
          "room",
          "attendees",
          "floor",
          "floor.location",
          "serviceOptionRequests",
          "serviceOptionRequests.option",
          "serviceRequestSettings",
          "schedule.entries.serviceOptionRequests",
          "schedule.entries.serviceOptionRequests.option",
          "schedule.entries.serviceRequestSettings",
        ],
      }, true);
    }
  }, [reservationId]);

  const { data: getReservationByIdResponse, isLoading: reservationIsLoading, isFetching: reservationIsFetching } = getReservationByIdQuery;
  const { data: reservation } = getReservationByIdResponse?.result || {};
  const {
    status,
    room,
    floor,
    user,
    attendees: internalAttendees,
    externalAttendees,
    schedule,
  } = reservation || {};
  const attendees = union<Attendee>(internalAttendees || [], externalAttendees?.map((email) => ({ email })) || []);
  const entry = entryId ? schedule?.entries?.find(({ id }) => id === entryId) : undefined;
  const serviceOptionRequests = entry?.requestedServices ? entry?.serviceOptionRequests : reservation?.serviceOptionRequests;
  const serviceRequestSettings = entry?.requestedServices ? entry?.serviceRequestSettings : reservation?.serviceRequestSettings;
  const cateringAttendees = serviceRequestSettings?.find(({ type, serviceType }) => {
    return type === ReservationServiceRequestSettingType.ATTENDEES_NUMBER && serviceType === ReservationServiceType.CATERING;
  });
  const isLoading = reservationIsLoading || reservationIsFetching;
  const anyServiceRequested = isLoading || reservation?.requestedServices?.some((service) => {
    return [ReservationServiceType.CATERING, ReservationServiceType.IT_SUPPORT].includes(service);
  });

  return (
    <Dialog maxWidth="xl" onClose={onClose} open={open}>
      <Box p={2} width={anyServiceRequested ? (xl ? 1020 : 880) : (xl ? 640 : 540)}>
      <Box alignItems="center" display="flex" gap={1}>
        {isLoading ? (
          <>
            <Skeleton height={30} variant="rectangular" width={30} />
            <Skeleton height={20} variant="rectangular" width={220} />
          </>
        ) : (
          <>
            <IconBox>
              <TogetherRoom stroke={palette.grey[700]} sx={{ width: 16 }} />
            </IconBox>
            <Typography fontWeight={600} noWrap width={600}>{room?.name}</Typography>
          </>
        )}
      </Box>
      <Divider sx={{ mt: 1, mb: 2 }} />
      <Grid columnSpacing={2} container sx={{ overflowY: "auto", overflowX: "hidden", maxHeight: 420 }}>
        <Grid item sm={anyServiceRequested ? 6 : 12}>
          {isLoading ? (
            <Skeleton height={20} sx={{ mb: 1 }} variant="rectangular" width={80} />
          ) : (
            <Typography fontWeight={600} mb={1} mt={1}>{t`Details`}</Typography>
          )}
          <Box display="flex" gap={1} mb={1}>
            {isLoading ? (
              <>
                <Skeleton height={22} variant="rectangular" width={60} />
                <Skeleton height={22} variant="rectangular" width={80} />
              </>
            ) : (
              <>
                <BasicChip>
                  <TogetherFloor fill={palette.grey[700]} sx={{ height: 14, width: 14 }} />
                  <Typography color={palette.grey[700]} fontSize={14} lineHeight={1} ml={1}>{floor?.name}</Typography>
                </BasicChip>
                <BasicChip>
                  <TogetherUser fill={palette.grey[700]} sx={{ width: 14 }} />
                  <Typography color={palette.grey[700]} fontSize={14} lineHeight={1} ml={1}>{`${room?.capacity} Capacity`}</Typography>
                </BasicChip>
              </>
            )}
          </Box>
          {isLoading ? (
            <Skeleton height={20} sx={{ mb: 3 }} variant="rectangular" width={220} />
          ) : (
            <Box display="flex" mb={3}>
              <LocationOnOutlined fontSize="small" sx={{ color: palette.grey[700] }} />
              <Typography color={palette.grey[700]} fontSize={14} ml={1}>{floor?.location?.name}. {floor?.location?.address}</Typography>
            </Box>
          )}
          {isLoading ? (
            <Grid columnSpacing={2} container mb={2} rowSpacing={1}>
              <Grid item sm={6}><Skeleton height={18} variant="rectangular" width={40} /></Grid>
              <Grid item sm={6}><Skeleton height={18} variant="rectangular" width={68} /></Grid>
              <Grid item sm={6}><Skeleton height={18} variant="rectangular" width={34} /></Grid>
              <Grid item sm={6}><Skeleton height={18} variant="rectangular" width={82} /></Grid>
              <Grid item sm={6}><Skeleton height={18} variant="rectangular" width={42} /></Grid>
              <Grid item sm={6}><Skeleton height={18} variant="rectangular" width={68} /></Grid>
            </Grid>
          ) : (
            <Grid columnSpacing={2} container mb={2} rowSpacing={1}>
              <Grid item sm={4}><Typography color={palette.grey[700]} fontSize={14}>{t`Owner`}:</Typography></Grid>
              <Grid item sm={8}><Typography fontSize={14}>{user?.name}</Typography></Grid>
              <Grid item sm={4}><Typography color={palette.grey[700]} fontSize={14}>{t`Status`}:</Typography></Grid>
              <Grid item sm={8}>
                <Typography color={resolveReservationStatusColor(status)} fontSize={14} textTransform="capitalize">
                  {resolveReservationStatusLabel(status)}
                </Typography>
              </Grid>
            </Grid>
          )}
          {isLoading ? (
            <Skeleton height={20} sx={{ mb: 1 }} variant="rectangular" width={92} />
          ) : (
            <Typography fontWeight={600} mb={1}>{t`Amenities`}</Typography>
          )}
          <Box display="flex" gap={1} mb={2}>
            {isLoading ? (
              <>
                <Skeleton height={22} variant="rectangular" width={60} />
                <Skeleton height={22} variant="rectangular" width={80} />
              </>
            ) : room?.amenities?.map(({ id, name }) => (
              <BasicChip key={id}><Typography color={palette.grey[700]} fontSize={14}>{name}</Typography></BasicChip>
            ))}
          </Box>
          {isLoading ? (
            <Skeleton height={20} sx={{ mb: 1 }} variant="rectangular" width={112} />
          ) : (
            <Typography fontWeight={600} mb={1}>
              {t`Attendees`}{" "}{attendees.length}{room?.capacity ? `/${room.capacity}` : ""}
            </Typography>
          )}
          {isLoading ? (
            <>
              <Box alignItems="center" display="flex" mb={1}>
                <Skeleton height={30} sx={{ borderRadius: 2 }} variant="rectangular" width={30} />
                <Box ml={2}>
                  <Skeleton sx={{ borderRadius: 2, fontSize: 14 }} width={120} />
                  <Skeleton sx={{ borderRadius: 2, fontSize: 12 }} width={180} />
                </Box>
              </Box>
              <Box alignItems="center" display="flex" mb={2}>
                <Skeleton height={30} sx={{ borderRadius: 2 }} variant="rectangular" width={30} />
                <Box ml={2}>
                  <Skeleton sx={{ borderRadius: 2, fontSize: 14 }} width={120} />
                  <Skeleton sx={{ borderRadius: 2, fontSize: 12 }} width={180} />
                </Box>
              </Box>
            </>
          ) : attendees.map(({ name, email }) => (
            <Box alignItems="center" display="flex" height={44} key={email}>
              <Box
                alignItems="center"
                bgcolor={({ palette }) => !name ? palette.grey[400] : palette.primary.main}
                borderRadius={2}
                display="flex"
                height={30}
                justifyContent="center"
                marginRight={1}
                width={30}
              >
                <TogetherUser fill="#fff" sx={{ width: 14 }} />
              </Box>
              <Box maxWidth={280}>
                <Typography fontSize={14} fontWeight="600" noWrap>{name || t`Invited user`}</Typography>
                <Typography color={({ palette }) => palette.grey[700]} fontSize={12} noWrap>{email}</Typography>
              </Box>
            </Box>
          ))}
        </Grid>
        {anyServiceRequested ? <Divider flexItem orientation="vertical" sx={{ mr: "-1px" }} /> : undefined}
        {anyServiceRequested ? (
          <Grid item sm={6}>
            {isLoading ? (
              <Skeleton height={20} sx={{ mb: 1 }} variant="rectangular" width={92} />
            ) : reservation?.requestedServices?.includes(ReservationServiceType.CATERING) ? (
              <Box display="flex" gap={1} mb={2} mt={1}>
                <Typography fontWeight={600}>{t`Catering`}</Typography>
                {cateringAttendees && cateringAttendees.value && isNumberString(cateringAttendees.value) ? (
                  <BasicChip gap={1}>
                    <TogetherUser fill={palette.grey[700]} sx={{ width: 14, height: 14 }} />
                    <Typography color={palette.grey[700]} fontSize={14} lineHeight={1}>
                      {plural(Number.parseInt(cateringAttendees.value), { one: "# attendee", other: "# attendees" })}
                    </Typography>
                  </BasicChip>
                ) : undefined}
              </Box>
            ) : undefined}
            {isLoading ? (
              <>
                <Skeleton height={18} sx={{ mb: 1 }} variant="rectangular" width={74} />
                <Grid container mb={2} rowSpacing={1}>
                  <Grid item sm={6}><Skeleton height={18} variant="rectangular" width={54} /></Grid>
                  <Grid item sm={6}><Skeleton height={18} variant="rectangular" width={66} /></Grid>
                  <Grid item sm={6}><Skeleton height={18} variant="rectangular" width={64} /></Grid>
                  <Grid item sm={6}><Skeleton height={18} variant="rectangular" width={72} /></Grid>
                  <Grid item sm={6}><Skeleton height={18} variant="rectangular" width={40} /></Grid>
                  <Grid item sm={6}><Skeleton height={18} variant="rectangular" width={44} /></Grid>
                </Grid>
                <Skeleton height={18} sx={{ mb: 1 }} variant="rectangular" width={74} />
                <Grid container rowSpacing={1}>
                  <Grid item sm={6}><Skeleton height={18} variant="rectangular" width={54} /></Grid>
                  <Grid item sm={6}><Skeleton height={18} variant="rectangular" width={66} /></Grid>
                  <Grid item sm={6}><Skeleton height={18} variant="rectangular" width={64} /></Grid>
                  <Grid item sm={6}><Skeleton height={18} variant="rectangular" width={72} /></Grid>
                  <Grid item sm={6}><Skeleton height={18} variant="rectangular" width={40} /></Grid>
                  <Grid item sm={6}><Skeleton height={18} variant="rectangular" width={44} /></Grid>
                </Grid>
              </>
            ) : reservation?.requestedServices?.includes(ReservationServiceType.CATERING) ? (
              !serviceRequestSettings?.length ? (
                <Typography color={palette.grey[700]} fontSize={14}>
                  {t`Catering was requested, but no options were selected.`}
                </Typography>
              ) : (
                <>
                  <ReservationCateringDetails
                    requests={serviceOptionRequests}
                    settings={serviceRequestSettings}
                    type={ReservationServiceOptionType.BEVERAGE}
                  />
                  <ReservationCateringDetails
                    requests={serviceOptionRequests}
                    settings={serviceRequestSettings}
                    type={ReservationServiceOptionType.BREAKFAST}
                  />
                  <ReservationCateringDetails
                    requests={serviceOptionRequests}
                    settings={serviceRequestSettings}
                    type={ReservationServiceOptionType.SNACK}
                  />
                  <ReservationCateringDetails
                    requests={serviceOptionRequests}
                    settings={serviceRequestSettings}
                    type={ReservationServiceOptionType.LUNCH}
                  />
                </>
              )
            ) : undefined}
            {isLoading ? (
              <>
                <Skeleton height={20} sx={{ mb: 2, mt: 4 }} variant="rectangular" width={102} />
                <Skeleton height={14} variant="rectangular" width={180} />
              </>
            ) : reservation?.requestedServices?.includes(ReservationServiceType.IT_SUPPORT) ? (
              <>
                <Typography fontWeight={600} mb={1} mt={4}>{t`IT Support`}</Typography>
                <Typography color={palette.grey[700]} fontSize={14}>
                  {t`IT support was requested for this reservation.`}
                </Typography>
              </>
            ) : undefined}
          </Grid>
        ) : undefined}
      </Grid>
      {isLoading ? (
        <Skeleton height={85} sx={{ borderRadius: 3 }} variant="rectangular" width="100%" />
      ) : undefined}
      {!isLoading && entry ? (
        <Box bgcolor={palette.primary.main} borderRadius={3} mt={2} p={2}>
          <Typography color="#fff" fontSize={14} fontWeight={600} mb={1}>{t`Date and time`}</Typography>
          <Typography color="#fff" fontSize={16} fontWeight={600}>
            {format(new Date(entry.startDate), "LLL dd, HH:mm")}{"-"}{format(new Date(entry.endDate), "HH:mm OOOO")}
          </Typography>
        </Box>
      ) : undefined}
      </Box>
    </Dialog>
  );
};
