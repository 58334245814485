import Box from 'components/_legacy/Box';
import Button from 'components/_legacy/Button';
import L from 'leaflet';
import styles from './styles.module.scss';
import Text from 'components/_legacy/Text';
import TextField from 'components/_legacy/TextField';
import { Popup, useMap } from 'react-leaflet';
import { SECTION_COLOR } from '../../../Helpers/consts';
import { Trans, t } from '@lingui/macro';
import { FloorMapObjectTypeEnum } from "../../../../../../Store/floorMapDuck/models";

interface Props {
  center: L.LatLngExpression;
  popupOpened: boolean;
  sectionName: string;
  setCenter: (center: L.LatLngExpression) => void;
  setDrawSectionActive: (active: boolean) => void;
  setSectionName: (sectionName: string) => void;
  setPopupOpened: (popupOpened: boolean) => void;
}

/**
 * Popup to create section.
 * When the map is clicked on an empty area, this popup will open to fill in section name
 * and start drawing the section.
 */
export default function CreateSection({
  center,
  popupOpened,
  sectionName,
  setDrawSectionActive,
  setPopupOpened,
  setSectionName,
}: Props) {
  const map = useMap();

  // @ts-ignore Typescript is not recognizing extension. Argument of type 'Map' is not assignable to parameter of type 'DrawMap'
  const rectangleDrawer = new L.Draw.Polygon(map);
  rectangleDrawer.setOptions({
    shapeOptions: {
      // In the lack of a better way to pass data do draw layer, we're using the attribution field with a JSON string
      attribution: JSON.stringify({ sectionName, type: FloorMapObjectTypeEnum.Section }),
      color: SECTION_COLOR,
    },
    showArea: false,
  });

  const handleClosePopup = () => {
    setPopupOpened(false);
  };

  // Closes popup, set draw state to active and enable rectangle draw
  const handleConfirmAddSection = () => {
    setPopupOpened(false);
    setDrawSectionActive(true);

    rectangleDrawer.enable();
  };

  return (
    <>
      {popupOpened && (
        <Popup
          className={styles.createSectionContainer}
          closeButton={false}
          minWidth={250}
          position={center}
        >
          <Box>
            <Text weight="semi-bold">
              <Trans>Add section</Trans>
            </Text>

            <Box marginTop={5}>
              <TextField
                id="section-name"
                label={t`Section name`}
                onChange={(event) => setSectionName(event.target.value)}
                placeholder={t`Type here`}
                required
                value={sectionName}
              />
            </Box>

            <Box
              alignItems="center"
              display="flex"
              justifyContent="end"
              marginTop={15}
            >
              <Button
                onClick={handleClosePopup}
                size="sm"
                type="clear"
                withShadow={false}
              >
                <Trans>Cancel</Trans>
              </Button>

              <Button
                buttonType="submit"
                disabled={!sectionName}
                onClick={handleConfirmAddSection}
                size="sm"
              >
                <Trans>Add Section</Trans>
              </Button>
            </Box>
          </Box>
        </Popup>
      )}
    </>
  );
}