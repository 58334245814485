import { t } from "@lingui/macro";
import { LoadingButton } from "@mui/lab";
import { Box, FilledInput, Skeleton, Typography } from "@mui/material";
import { CampaignWrapper, Editor, FileUpload, RequiredMark, useToast } from "components";
import { AttachmentImage } from "components/attachments";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminSlice, selectAdminCurrentCampaign, useCreateAttachmentMutation, useGetAttachmentByIdQuery } from "store";

export const CampaignContent: React.FC = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const currentCampaign = useSelector(selectAdminCurrentCampaign);
  const [createAttachment, { isLoading: isUploadingThumbnail }] = useCreateAttachmentMutation();

  const handleThumbnailUpload = (file: File) => {
    void (async () => {
      const response = await createAttachment(file);

      if ("error" in response) {
        toast.showToast({ severity: "error", message: t`Failed to upload image, please try again` });
      } else {
        const { data: attachment } = response;

        dispatch(adminSlice.actions.setCurrentCampaign({ thumbnailId: attachment.id }));
      }
    })();
  };

  const handleThumbnailDelete = () => {
    if (!currentCampaign?.thumbnailId) {
      return;
    }

    dispatch(adminSlice.actions.setCurrentCampaign({ thumbnailId: undefined }));
  };

  return (
    <CampaignWrapper>
      <Typography fontSize={14} mb={1}><RequiredMark />{t`Campaign name (post heading)`}</Typography>
      <FilledInput
        defaultValue={currentCampaign?.title}
        fullWidth
        onChange={(event) => dispatch(adminSlice.actions.setCurrentCampaign({ title: event.currentTarget.value }))}
        placeholder={t`Type here`}
        sx={{ mb: 2 }}
      />
      <Typography fontSize={14} mb={1}>{t`Upload picture (will appear in post preview)`}</Typography>
      {!currentCampaign?.thumbnailId ? (
        <FileUpload
          accept={["image/png", "image/jpeg", "image/webp"]}
          isUploading={isUploadingThumbnail}
          onChange={([file]) => handleThumbnailUpload(file)}
        />
      ) : (
        <Box alignItems="center" display="flex" gap={2} mb={2}>
          <AttachmentImage attachmentId={currentCampaign.thumbnailId} borderRadius={2} height="auto" width={180} />
          <LoadingButton onClick={() => handleThumbnailDelete()}>{t`Delete Picture`}</LoadingButton>
        </Box>
      )}
      <Typography fontSize={14} mb={1}><RequiredMark />{t`Post message`}</Typography>
      <Editor initialValue={currentCampaign?.body} onChange={(body) => dispatch(adminSlice.actions.setCurrentCampaign({ body }))} />
    </CampaignWrapper>
  );
};
