import { addDays, isAfter } from "date-fns";
import { isSameDayInTimeZone } from "utils";

export const isDaySelectorDisabled = (
  direction: "next" | "previous",
  day: Date,
  timeZone: string,
  minDate?: Date,
  maxDate?: Date,
) => {
  if (minDate && direction === "previous") {
    return isSameDayInTimeZone(minDate, day, timeZone) || isAfter(minDate, day);
  }

  if (maxDate) {
    return isSameDayInTimeZone(day, addDays(maxDate, 1), timeZone) || isAfter(day, maxDate);
  }

  return false;
};
