import React, { useEffect, useMemo, useRef, useState } from "react";
import { CalendarViewSchedule, CalendarViewSelection, WeeklyCalendarViewProps } from "./types";
import { Box, CircularProgress, Divider, Fade, IconButton, MenuItem, SelectProps, Skeleton, Typography, useTheme } from "@mui/material";
import { add, addDays, addMinutes, addSeconds, addWeeks, differenceInMinutes, eachDayOfInterval, eachMinuteOfInterval, isAfter, isBefore, isEqual, set, startOfDay } from "date-fns";
import { isCalendarViewScheduleVisible, isCalendarViewStepDisabled, resolveCalendarViewScheduleInterval, resolveCalendarViewScheduleSpan, resolveCalendarViewStepDates } from "./utils";
import { ArrowBackIosRounded, ArrowForwardIosRounded } from "@mui/icons-material";
import { t } from "@lingui/macro";
import { WeeklyCalendarViewStep } from "./weekly-calendar-view-step";
import { WeeklyCalendarViewSchedule } from "./weekly-calendar-view-schedule";
import { isDaySelectorDisabled } from "../day-selector";
import { format, formatInTimeZone, zonedTimeToUtc } from "date-fns-tz";
import { isOverlapping, isSameDayInTimeZone, resolveRecurrenceOptionsOccurrences } from "utils";
import { AllDaySwitch, AllDaySwitchProps } from "../switch";
import { StandardSelect } from "../select";

const WIDTH = 12.5;

// * Right now this expects rooms only, but changes can be made to support other types of reservation resources (desk, etc)

export const WeeklyCalendarView: React.FC<WeeklyCalendarViewProps> = (props) => {
  const {
    selectedDay,
    height,
    step = 15,
    isLoading,
    item,
    stepHeight = 26,
    defaultSelection,
    maxDate,
    minDate,
    timeZone,
    blocks,
    onChange,
    onStartDayChange,
  } = props;
  const { palette, background } = useTheme();
  const [isOnHighLoad, setIsOnHighLoad] = useState(false);
  const [selection, setSelection] = useState<CalendarViewSelection>();
  const [startDay, setStartDay] = useState(selectedDay);
  const [view, setView] = useState<"weekly" | "daily">("weekly");
  const days = useMemo(
    () => view === "weekly"
      ? eachDayOfInterval({ start: startDay, end: add(startDay, { weeks: 1 }) }).slice(0, -1)
      : [startDay],
    [startDay, view],
  );
  const initialScrollTop = useMemo(() => {
    const now = new Date();
    const minutes = eachMinuteOfInterval(
      { start: startOfDay(now), end: set(now, { hours: 8, minutes: 0, seconds: 0, milliseconds: 0 }) },
      { step },
    );
    
    return minutes.slice(0, -1).length * stepHeight;
  }, []);
  const daysStepDates = useMemo(() => days.map((day) => resolveCalendarViewStepDates(day, step)), [days, step]);
  const seventhDay = addDays(startDay, 7);
  const viewRef = useRef<HTMLDivElement>(null);
  const occurrences = useMemo(() => {
    const { start, end, recurrence } = selection || {};

    if (start && end && recurrence) {
      return resolveRecurrenceOptionsOccurrences(start, end, recurrence);
    }
  }, [JSON.stringify(selection?.recurrence)]);
  const daysSchedules = useMemo(() => {
    const daysSchedules = days.map(() => []) as CalendarViewSchedule[][];

    for (const schedule of item?.schedules || []) {
      for (const [index, day] of days.entries()) {
        if (isSameDayInTimeZone(schedule.startDate, day, timeZone) || isSameDayInTimeZone(schedule.endDate, day, timeZone)) {
          daysSchedules[index].push(schedule);
        }
      }
    }

    return daysSchedules;
  }, [JSON.stringify(days), JSON.stringify(item), timeZone]);
  const daysBlocks = useMemo(() => {
    const daysBlocks = days.map(() => []) as [startDate: Date, endDate: Date][][];

    if (blocks?.length) {
      for (const [startDate, endDate] of blocks) {
        for (const [index, day] of days.entries()) {
          if (isSameDayInTimeZone(startDate, day, timeZone) || isSameDayInTimeZone(endDate, day, timeZone)) {
            daysBlocks[index].push([startDate, endDate]);
          }
        } 
      }
    }

    return daysBlocks;
  }, [JSON.stringify(days), JSON.stringify(blocks)]);

  useEffect(() => {
    if (viewRef.current && !selection) {
      viewRef.current.scrollTop = initialScrollTop;
    }
  }, [viewRef, item?.id, startDay.getTime()]);

  useEffect(() => {
    const timeout = isLoading ? setTimeout(() => setIsOnHighLoad(true), 5000) : undefined;

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }

      setIsOnHighLoad(false);
    };
  }, [isLoading, setIsOnHighLoad]);

  useEffect(() => {
    if (!isEqual(selectedDay, startDay)) {
      setStartDay(selectedDay);
    }
  }, [selectedDay.getTime()]);

  useEffect(() => {
    if (view === "weekly") {
      onStartDayChange?.([
        zonedTimeToUtc(`${formatInTimeZone(days[0], timeZone, "yyyy-MM-dd")}T00:00:00`, timeZone),
        zonedTimeToUtc(`${formatInTimeZone(days[days.length - 1], timeZone, "yyyy-MM-dd")}T23:59:59.999999`, timeZone),
      ]);
    } else {
      onStartDayChange?.([
        zonedTimeToUtc(`${formatInTimeZone(startDay, timeZone, "yyyy-MM-dd")}T00:00:00`, timeZone),
        zonedTimeToUtc(`${formatInTimeZone(startDay, timeZone, "yyyy-MM-dd")}T23:59:59.999999`, timeZone),
      ]);
    }
  }, [startDay, view]);

  useEffect(() => {
    if (selection && selection.id !== item?.id) {
      setSelection(undefined);
    }
  }, [item]);

  useEffect(() => {
    if (selection && selection.start) {
      const dayIndex = days.findIndex((day) => selection.start && isSameDayInTimeZone(selection.start, day, timeZone));
      const stepDates = daysStepDates[dayIndex];
      const span = resolveCalendarViewScheduleSpan(selection.start, stepDates[0], step, stepDates[0]) * stepHeight;
      const top = span ? span - stepHeight : 0;

      if (viewRef.current) {
        viewRef.current.scrollTop = top;
      }
    }
  }, [selection, viewRef]);

  useEffect(() => {
    if (defaultSelection) {
      const { id, start, end, summary, recurrence, isAllDay } = defaultSelection;
      const shouldSetSelection = start
        && end
        && (isSameDayInTimeZone(start, days[0], timeZone) || isAfter(start, days[0]) 
        && (isSameDayInTimeZone(end, days[days.length -1], timeZone) || isBefore(end, days[days.length -1])))
        && defaultSelection.id === item?.id;

      if (shouldSetSelection) {
        setSelection({ id, start, end, summary, recurrence, isAllDay });
      }
    } else if (selection) {
      setSelection(undefined);
    }
  }, [startDay?.getTime(), item?.id, JSON.stringify(defaultSelection)]);

  const handleDaySelectorClick = (direction: "next" | "previous") => {
    if (direction == "next") {
      setStartDay(view === "weekly" ? addWeeks(startDay, 1) : addDays(startDay, 1));
    } else {
      setStartDay(view === "weekly" ? addWeeks(startDay, -1) : addDays(startDay, -1));
    }
  };

  const handleStepClick = (stepDate: Date) => {
    const isNewSelection = (selection?.start && !isSameDayInTimeZone(stepDate, selection.start, timeZone))
      || !selection
      || (selection.start && selection.end && differenceInMinutes(selection.end, selection.start) > step)
      || (selection.start && (isEqual(stepDate, selection.start) || isBefore(stepDate, selection.start)));

    if (isNewSelection) {
      setSelection({ id: item?.id || "", start: stepDate, isAllDay: undefined });
      onChange?.({ id: item?.id || "", start: stepDate, end: addMinutes(stepDate, step), isAllDay: undefined });
    } else if (selection) {
      const { id, start } = selection;
      const newSelection = { id, start, end: addMinutes(stepDate, step), isAllDay: undefined };

      setSelection(newSelection);
      onChange?.(newSelection);
    }
  };

  const handleSelectionClick = () => {
    if (selection?.start) {
      setSelection(undefined);
      onChange?.({ id: selection.id, start: undefined, end: undefined, isAllDay: undefined });
    }
  };

  const handleAllDaySwitchChange: AllDaySwitchProps["onChange"] = (checked) => {
    if (selection) {
      setSelection({ ...selection, isAllDay: checked || undefined });
      onChange?.({ ...selection, isAllDay: checked || undefined });
    }
  };
  
  const handleViewSelectChange: SelectProps["onChange"] = (event) => {
    const value = event.target.value;

    if (value === "weekly" || value === "daily") {
      setView(value);
    }
  };

  const labels: JSX.Element[] = [];

  for (let index = 0; index < 24; index++) {
    const hour = `${index}`.padStart(2, "0");
    const label = `${hour}:00`;

    labels.push((
      <Box bgcolor="white" height={stepHeight * (60 / step)} key={label} width="100%">
        {<Typography width="100%">{label}</Typography>}
      </Box>
    ));
  }

  return (
    <Box display="flex" flexDirection="column" height={height} width="100%">
      <Box alignItems="center" display="flex" justifyContent="space-between" marginBottom={1} pb={1} pt={2}>
        {view === "weekly" ? (
          <Typography fontSize={14} fontWeight="600">
            {format(days[0], "d")}-{format(days[days.length - 1], "d LLL, yyyy")}
          </Typography>
        ) : (
          <Typography fontSize={14} fontWeight="600">{format(selectedDay, "d LLL, yyyy")}</Typography>
        )}
        <Box alignItems="center" display="flex">
          <Box mr={2}>
            <StandardSelect onChange={handleViewSelectChange} size="small" sx={{ "& .MuiSelect-select": { fontSize: 14, fontWeight: 400 } }} value={view}>
              <MenuItem value="weekly"><Typography fontSize={14}>{t`Weekly view`}</Typography></MenuItem>
              <MenuItem value="daily"><Typography fontSize={14}>{t`Daily view`}</Typography></MenuItem>
            </StandardSelect>
          </Box>
          <AllDaySwitch
            checked={!!selection?.isAllDay}
            endDate={selection?.end}
            onChange={handleAllDaySwitchChange}
            startDate={selection?.start}
            unavailable={
              selection
                ? !!daysSchedules?.[days?.findIndex((day) => selection.start && isSameDayInTimeZone(day, selection.start, timeZone))]?.length
                : undefined
            }
          />
          <IconButton
            color="primary"
            data-cid="previous-day-button"
            disabled={isDaySelectorDisabled("previous", startDay, timeZone, minDate) || isLoading}
            onClick={() => handleDaySelectorClick("previous")}
            size="small"
          >
            <ArrowBackIosRounded fontSize="small" />
          </IconButton>
          <IconButton
            color="primary"
            data-cid="next-day-button"
            disabled={isDaySelectorDisabled("next", seventhDay, timeZone, minDate, maxDate) || isLoading}
            onClick={() => handleDaySelectorClick("next")}
            size="small"
          >
            <ArrowForwardIosRounded fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      <Box display="flex" marginBottom={1} paddingRight={1}>
        <Box flex={`1 0 ${WIDTH}%`} width={`${WIDTH}%`}>
          <Typography color={palette.grey[400]} fontSize={13} textAlign="center" width="100%">{t`Time`}</Typography>
        </Box>
        {days.map((day) => {
          const isSelectedDay = isSameDayInTimeZone(selectedDay, day, timeZone);
          const isSelectionDate = (selection?.start && isSameDayInTimeZone(selection.start, day, timeZone));
          const isOccurrenceDate = occurrences?.some(([startDate]) => isSameDayInTimeZone(startDate, day, timeZone));
          const isBlocked = maxDate && isAfter(day, maxDate);
          const isHighlighted = (isSelectedDay && !occurrences) || (isSelectionDate && isOccurrenceDate) || (isOccurrenceDate && !isSelectionDate);
          const hasBlocks = blocks?.some(([startDate]) => isSameDayInTimeZone(day, startDate, timeZone));
          const width = (100 - WIDTH) / days.length;
          let color: string | undefined;
          let bgcolor: string | undefined;

          if (isHighlighted) {
            bgcolor = background.blue.light;
            color = palette.primary.main;
          } else if (isBlocked) {
            color = palette.grey[300];
          } else if (hasBlocks || (isSelectionDate && !isOccurrenceDate)) {
            bgcolor = palette.grey[100];
          } else if (isSelectedDay) {
            color = palette.primary.main;
          }

          return (
            <Box display="flex" flex={`1 0 ${width}%`} flexDirection="column" key={day.toISOString()} width={`${width}%`}>
              <Typography
                color={isBlocked ? palette.grey[200] : palette.grey[400]}
                fontSize={13}
                marginBottom={0.5} 
                textAlign="center"
                textTransform="uppercase"
                width="100%"
              >
                {format(day, "E")}
              </Typography>
              <Typography
                bgcolor={bgcolor}
                borderRadius={isHighlighted || hasBlocks || (isSelectionDate && !isOccurrenceDate) ? 2 : 0}
                color={color}
                fontWeight="600"
                paddingBottom={0.5}
                paddingTop={0.5}
                textAlign="center"
                width="100%"
              >
                {format(day, "d")}
              </Typography>
            </Box>
          );
        })}
      </Box>
      <Divider sx={{ borderColor: palette.grey[100] }} />
      <Box display="flex" flexDirection="column" overflow="hidden" position="relative">
        {isLoading ? (
          <>
            <Skeleton
              animation="wave"
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: "12.5%",
                height: "auto",
                zIndex: 100,
                bgcolor: "rgba(245, 245, 245, 0.4)",
                borderRadius: 0,
              }}
              variant="rectangular"
            />
            <Fade in={isOnHighLoad}>
              <Box
                alignItems="center"
                bottom={0}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                left="12.5%"
                paddingX={8}
                position="absolute"
                right={0}
                top={0}
                zIndex={200}
              >
                <CircularProgress size={32} sx={{ mb: 2 }} />
                <Typography color={palette.grey[700]} fontSize={14} textAlign="center">
                  {t`Please, wait while we fetch room schedule from Outlook`}
                </Typography>
              </Box>
            </Fade>
          </>
        ) : undefined}
        <Box display="flex" ref={viewRef} sx={{ overflowY: "auto", overflowX: "unset" }}>
          <Box flex={`1 0 ${WIDTH}%`} width={`${WIDTH}%`}>
            {labels}
          </Box>
          {days.map((day, index) => {
            const stepDates = daysStepDates[index] || [];
            const firstStepDate = stepDates[0];
            const lastStepDate = stepDates[stepDates.length - 1];
            const width = (100 - WIDTH) / days.length;
            const isBlocked = maxDate && isAfter(day, maxDate);
            const selectionIsVisible = selection
              && selection.start
              && (isSameDayInTimeZone(selection.start, day, timeZone) || (selection.end && isSameDayInTimeZone(addSeconds(selection.end, -1), day, timeZone)));
            const occurrence = occurrences?.find(([startDate]) => isSameDayInTimeZone(startDate, day, timeZone));
            const selectionIsOccurrence = selectionIsVisible && (!occurrences || !!occurrence);
            const occurrenceIsVisible = !selectionIsVisible
              && occurrence
              && !daysSchedules[index]?.some(({ startDate, endDate }) => isOverlapping(occurrence, [startDate, endDate]));
            const schedules: [startDate: Date, endDate: Date][] = [];

            if (daysBlocks?.[index]?.length) {
              schedules.push(...daysBlocks[index]);
            }

            if (daysSchedules?.[index]?.length) {
              schedules.push(...daysSchedules[index].map<[Date, Date]>(({ startDate, endDate }) => [startDate, endDate]));
            }

            return (
              <Box flex={`1 0 ${width}%`} key={day.toISOString()} position="relative" width={`${width}%`}>
                {stepDates.map((stepDate) => (
                    <WeeklyCalendarViewStep
                      bgcolor={isBlocked ? palette.grey[100] : undefined}
                      disabled={isCalendarViewStepDisabled(stepDate, item?.id || "", schedules, selection) || isBlocked}
                      height={stepHeight}
                      key={`${day.getTime()}${stepDate.getTime()}`}
                      onClick={() => handleStepClick(stepDate)}
                      tooltip={t`Click to select time`}
                    />
                  ))}
                {daysSchedules[index]?.map(({ startDate, endDate, summary }) => isCalendarViewScheduleVisible(endDate, step, firstStepDate) ? (
                  <WeeklyCalendarViewSchedule
                    endDate={endDate}
                    firstStepDate={firstStepDate}
                    key={`${day.getTime()}${startDate.getTime()}`}
                    lastStepDate={lastStepDate}
                    startDate={startDate}
                    step={step}
                    stepHeight={stepHeight}
                    summary={summary}
                  />
                ): undefined)}
                {selectionIsVisible && selection?.start ? (
                  selection.isAllDay ? (
                    <Box
                      bgcolor={selectionIsOccurrence ? background.blue.light : palette.grey[100]}
                      borderRadius={2}
                      data-cid="selection"
                      height={stepHeight * (1440 / step)}
                      left={0}
                      onClick={handleSelectionClick}
                      position="absolute"
                      top={0}
                      width="100%"
                      zIndex={1}
                    />
                  ) : (
                    <Box
                      bgcolor={selection.start && selection.end && selectionIsOccurrence ? background.blue.light : palette.grey[100]}
                      borderRadius={2}
                      data-cid="selection"
                      height={
                        selection.end 
                          ? (resolveCalendarViewScheduleInterval(selection.start, selection.end, step, firstStepDate, lastStepDate) * stepHeight) 
                          : stepHeight
                      }
                      left={0}
                      onClick={handleSelectionClick}
                      position="absolute"
                      top={resolveCalendarViewScheduleSpan(selection.start, firstStepDate, step, firstStepDate) * stepHeight}
                      width="100%"
                      zIndex={1}
                    />
                  )
                ) : undefined}
                {occurrenceIsVisible && occurrence ? (
                  selection?.isAllDay ? (
                    <Box
                      bgcolor={background.blue.light}
                      borderRadius={2}
                      data-cid="occurrence"
                      height={stepHeight * (1440 / step)}
                      left={0}
                      onClick={handleSelectionClick}
                      position="absolute"
                      top={0}
                      width="100%"
                      zIndex={1}
                    />
                  ) : (
                    <Box
                      bgcolor={background.blue.light}
                      borderRadius={2}
                      data-cid="occurrence"
                      height={resolveCalendarViewScheduleInterval(occurrence[0], occurrence[1], step, firstStepDate, lastStepDate) * stepHeight}
                      left={0}
                      onClick={handleSelectionClick}
                      position="absolute"
                      top={resolveCalendarViewScheduleSpan(occurrence[0], firstStepDate, step, firstStepDate) * stepHeight}
                      width="100%"
                      zIndex={1}
                    />
                  )
                ) : undefined}
                {daysBlocks[index]?.map(([startDate, endDate]) => isCalendarViewScheduleVisible(endDate, step, firstStepDate) ? (
                  <Box
                    bgcolor={palette.grey[100]}
                    borderRadius={2}
                    height={resolveCalendarViewScheduleInterval(startDate, endDate, step, firstStepDate, lastStepDate) * stepHeight}
                    left={0}
                    onClick={handleSelectionClick}
                    position="absolute"
                    top={resolveCalendarViewScheduleSpan(startDate, firstStepDate, step, firstStepDate) * stepHeight}
                    width="100%"
                    zIndex={2}
                  />
                ): undefined)}
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
