import {useDispatch} from "react-redux";
import {useTypedSelector} from "store/_legacy/Redux/store";
import styles from "../../styles.module.scss";
import Checkbox from "components/_legacy/Checkbox";
import { setFloorMapData} from "../../../../Store/floorMapDuck";
import {FloorMapObject} from "../../../../Store/floorMapDuck/models";

interface DeskSelectCheckboxProps {
  desk: FloorMapObject;
  checked: boolean;
}

export default function DeskSelectCheckbox(props: DeskSelectCheckboxProps) {
  const dispatch = useDispatch();
  const { adminFloorMap } = useTypedSelector(state => state);
  const { checked, desk } = props;
  const { editDeskList } = adminFloorMap;

  const onToggleSelection = (isChecked: boolean) => {
    const newSelectedDesks: {
      [deskId: string]: FloorMapObject;
    } = {...editDeskList.selectedDesks};

    if (isChecked) {
      newSelectedDesks[desk.id] = desk;
    } else {
      delete newSelectedDesks[desk.id];
    }

    dispatch(setFloorMapData({
      editDeskList: {
        selectedDesks: newSelectedDesks,
      },
    }));
  };

  return (
    <Checkbox
      checked={checked}
      classes={{container: styles.checkbox}}
      label=""
      name={String(desk.id)}
      onChange={(isChecked) => onToggleSelection(isChecked)}
    />
  );
}