import Box from 'components/_legacy/Box';
import Divider from 'components/_legacy/Divider';
import styles from './styles.module.scss';
import Text from 'components/_legacy/Text';
import VisitBreadcrumbs from '../VisitBreadcrumbs';
import VisitDetails from '../VisitDetails';
import VisitLocationItem, { VisitLocationItemLoading } from './VisitLocationItem';
import visitStyles from '../styles.module.scss';
import { getBookings, selectMoreOftenLocation } from 'App/Store/Bookings/bookingDuck';
import { setCreateNewVisitData } from 'App/Store/Bookings/createNewVisitDuck';
import { StaggeredItems } from 'components/_legacy/Animations/StaggeredItems';
import { Trans } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useTypedSelector } from 'store/_legacy/Redux/store';
import CovidDocConfirmation from '../../CreateNewBooking/CovidDocConfirmation';
import {isLocationRequiredCovidDocument} from "../../../Functions/Helpers";
import {setBookingCovidConfirmationData} from "../../../Store/Bookings/covidConfirmationDuck";
import {getUserCovidDocRestrictions, getUserIdForBooking} from "../../../Store/Users/executiveAssistant/helpers";
import ReservationRegionSearch from "components/_legacy/ReservationRegionSearch";

export default function VisitLocation() {
  const dispatch = useDispatch();
  const { profile, booking: bookingDuck, createNewVisit, locations: locationsDuck, executiveAssistant } = useTypedSelector(state => state);

  const { locationId } = createNewVisit;
  const { actualCovidDocument, approvedAccommodation } = getUserCovidDocRestrictions(profile, executiveAssistant);
  const { loading } = locationsDuck;

  const selectedUserId = getUserIdForBooking(profile, executiveAssistant);
  const defaultLocation = selectMoreOftenLocation(bookingDuck);

  // Sort locations so if there's a default, it's always the first one
  const locations = locationsDuck.locations.sort(a => {
    if (a.id === defaultLocation?.id) {
      return -1;
    }

    return 0;
  });

  // If there're no bookings loaded, tries to load them
  // This is necessary if the user enter this route without
  // navigating on the first page, so we can know what is the default location
  useEffect(() => {
    const hasBookings = Object.keys(bookingDuck.bookings).length > 0;

    if (!hasBookings) {
      dispatch(getBookings({
        data: {
          limit: 20,
          page: 1,
          dateFrom: bookingDuck.selectedDate,
          order: 'ASC',
          selectedUserId,
        },
        clearData: true,
      }));
    }
  }, [selectedUserId]);

  // If there's a defaultLocation, automatically assign it to selected
  useEffect(() => {
    if (defaultLocation) {
      dispatch(setCreateNewVisitData({ locationId: defaultLocation.id }));
    }
  }, [defaultLocation]);

  /**
   * Set locationId on redux
   */
  const onChangeLocation = (locationId: string) => {
    const isRequired = isLocationRequiredCovidDocument(locations, locationId, actualCovidDocument, approvedAccommodation);
    
    dispatch(setCreateNewVisitData({ locationId }));

    if (isRequired) {
      dispatch(setBookingCovidConfirmationData({ openCovidModal: true }));
    }
  };

  return (
    <>
      <Box display="grid" gap={30} gridTemplateColumns="8fr 4fr" paddingBottom={50}>
        <Box>
          <Box marginBottom={15}>
            <VisitBreadcrumbs />
          </Box>
          <Box marginBottom={15}>
            <ReservationRegionSearch />
          </Box>
          {loading ? (
            <VisitLocationItemLoading />
          ) : (
            <Box className={styles.locationList} dataTestId="location-list" display="grid" gap={15}>
              <StaggeredItems className={styles.items}>
                {locations.map((location, index) => (
                  <VisitLocationItem
                    {...location}
                    isDefault={defaultLocation?.id === location.id}
                    key={location.id + index}
                    onClick={onChangeLocation}
                    selected={locationId === location.id}
                  />
                ))}
              </StaggeredItems>
            </Box>
          )}
        </Box>

        <Box className={visitStyles.stickyBookingWrapper}>
          <Box>
            <Text size="xlg" weight="semi-bold">
              <Trans>Visit details</Trans>
            </Text>
          </Box>

          <Box className={visitStyles.stickyBookingLocationRow}>
            <Box marginBottom={10}>
              <Divider />
            </Box>

            <VisitDetails />
          </Box>
        </Box>

        <CovidDocConfirmation locationId={locationId} />
      </Box>
    </>
  );
}
