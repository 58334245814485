export const searchFullText = (target: string, search: string): boolean => {
  const targetInLowerCase = target.toLowerCase();
  const words = search.toLowerCase().split(" ");

  for (const word of words) {
    if (!targetInLowerCase.includes(word)) {
      return false;
    }
  }

  return true;
};
