import * as yup from 'yup';
import Box from 'components/_legacy/Box';
import Button from 'components/_legacy/Button';
import ChevronDownIcon from 'components/_legacy/Icons/ChevronDown';
import CloseIcon from '@material-ui/icons/Close';
import FromToTimeUI from 'Admin/Pages/Notifications/NotificationCalendar/FromToTimeUI';
import HourSlider from 'Admin/Pages/Notifications/NotificationCalendar/HourSlider';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import moment from 'moment';
import Popover from '@material-ui/core/Popover';
import Popup from 'reactjs-popup';
import Select, { components } from 'react-select';
import styles from './styles.module.scss';
import Text from 'components/_legacy/Text';
import TextField from 'components/_legacy/TextField';
import useSnackbar from 'components/_legacy/Snackbar/useSnackbar';
import { Calendar } from 'react-date-range';
import { CreateGlobalNotificationRequest, GlobalNotificationModel, recipientsArray } from 'Admin/Store/globalNotifications/models';
import { Trans, t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTypedSelector } from 'store/_legacy/Redux/store';
import {
  createAndSendGlobalNotification,
  createGlobalNotification,
  setGlobalNotificationsReduxData,
  updateAndSendGlobalNotification,
  updateGlobalNotification,
  } from 'Admin/Store/globalNotifications';

interface Props {
  notification?: GlobalNotificationModel | null; // data used for edit state
  onCancelAction: any;
  open: boolean;
}

const getWhenToSendDateFormat = (date: string) => {
  const hour = parseInt(moment(date).format('H'));
  const minutes = parseInt(moment(date).format('m')) / 0.6 / 100;

  return hour + minutes;
};

const getWhenToSendDate = (sendDate: string | undefined, whenToSend?: string) => {
  if (sendDate && !whenToSend) { // return data on first open (in case of edit state)
    return new Date(sendDate);
  }

  if (whenToSend) { // if user updated sendDate
    return new Date(whenToSend);
  }

  return new Date(moment().format()); // return no data on first open (in case of add state)
};

const getWhenToSendHour = (sendDate: string | undefined, whenToSend?: string) => {
  if (sendDate && !whenToSend) { // return data on first open (in case of edit state)
    return getWhenToSendDateFormat(sendDate);
  }

  if (whenToSend) { // if user updated sendDate
    return getWhenToSendDateFormat(whenToSend);
  }

  return 9; // return no data on first open (in case of add state)
};

export default function CreateNotificationForm({
  notification,
  onCancelAction,
  open,
}: Props) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [errors, setErrors] = useState({
    message: "",
    recipients: "",
    subject: "",
    whenToSend: "",
  });

  const [whenToSendFormated, setWhenToSendFormated] = useState(moment(new Date()).format());

  const initSelectedDate = getWhenToSendDate(notification?.sendDate);
  const notifWhenToHours = getWhenToSendHour(notification?.sendDate);
  const initialRecipients = recipientsArray.find(r => r.value === notification?.recipients) ?? recipientsArray[0];

  const [showFeedbackMessage, setShowFeedbackMessage] = useState(false);
  const [hours, setHours] = useState(notifWhenToHours);
  const [message, setMessage] = useState(notification?.message ?? '');
  const [recipients, setRecipients] = useState(initialRecipients);
  const [subject, setSubject] = useState(notification?.subject ?? '');
  const [whenToSend, setWhenToSend] = useState<Date>(initSelectedDate);

  const [openSnackbar] = useSnackbar();
  const { config, globalNotifications } = useTypedSelector(state => state);

  const { successMessage, successUpdateMessage } = globalNotifications;

  useEffect(() => {
    const initSelectedDate = getWhenToSendDate(notification?.sendDate);
    const notifWhenToHours = getWhenToSendHour(notification?.sendDate);
    const initialRecipients = recipientsArray.find(r => r.value === notification?.recipients) ?? recipientsArray[0];

    setHours(notifWhenToHours);
    setMessage(notification?.message ?? '');
    setRecipients(initialRecipients);
    setSubject(notification?.subject ?? '');
    setWhenToSend(initSelectedDate);
  }, [notification]);

  useEffect(() => {
    if (showFeedbackMessage && (successMessage || successUpdateMessage)) {
      openSnackbar({
        onClose: () => { dispatch(setGlobalNotificationsReduxData({ successMessage: '', successUpdateMessage: '' })); },
        text: successMessage ? successMessage : successUpdateMessage,
        type: 'success',
      });

      // clear all states
      setHours(9);
      setMessage('');
      setRecipients(recipientsArray[0]);
      setSubject('');
      setWhenToSend(new Date());

      setShowFeedbackMessage(false);
      onCancelAction();
    }
  }, [successMessage, successUpdateMessage]);

  /**
   * Returns the schema for validating the form. If the button Create and Send is clicked, it means
   * that the notification should be send right away, so "When to Send" should not be required.
   */
  const getFormSchema = ({ createAndSend = false }: { createAndSend?: boolean }) => {
    return yup.object().shape({
      message: yup.string().required(t`Message can\`t be empty`),
      recipients: yup.string().required(t`Required`),
      subject: yup.string().required().min(3, t`Min 3 characters`),
      whenToSend: createAndSend ? yup.string().nullable() : yup.string().nullable().required(t`Required to schedule notification`),
    });
  };

  const onCreateOrEdit = async (event: any) => {
    event.preventDefault();

    const isValid = await validateForm({ createAndSend: false });

    if (isValid) {
      const sendDate = getWhenToSendDate(notification?.sendDate, whenToSendFormated).toISOString();
      const data: CreateGlobalNotificationRequest = {
        endDate: sendDate,
        message,
        recipients: recipients.value,
        sendDate: sendDate,
        startDate: sendDate,
        subject,
      };
      
      setShowFeedbackMessage(true);

      if (notification) {
        dispatch(updateGlobalNotification({
          notificationId: notification.id,
          data,
        }));
      } else {
        dispatch(createGlobalNotification(data));
      }
    }
  };

  const onCreateOrEditAndSend = async (event: any) => {
    event.preventDefault();

    const isValid = await validateForm({ createAndSend: true });

    if (isValid) {
      const sendDate = getWhenToSendDate(notification?.sendDate, whenToSendFormated).toISOString();
      const data: CreateGlobalNotificationRequest = {
        endDate: sendDate,
        message,
        recipients: recipients.value,
        sendDate: sendDate,
        startDate: sendDate,
        subject,
      };
      
      setShowFeedbackMessage(true);

      if (notification) {
        dispatch(updateAndSendGlobalNotification({
          notificationId: notification.id,
          data,
        }));
      } else {
        dispatch(createAndSendGlobalNotification(data));
      }
    }
  };

  const onResetWhenToSendData = () => {
    setWhenToSend(new Date());
    setHours(9);
    setAnchorEl(null);
  };

  const onSetTodayWhenToSendData = () => {
    setWhenToSend(new Date());
    setAnchorEl(null);
  };

  const onConfirmDate = () => {
    const hour = Math.trunc(hours);
    const minutes = Number((hours - hour).toFixed(2)) * 100 * 0.6;
    const whenToSendDate = new Date(whenToSend);

    whenToSendDate.setHours(hour, minutes, 0);

    const whenToSendDateUTC = moment(whenToSendDate);

    setWhenToSend(whenToSendDate);
    setWhenToSendFormated(whenToSendDateUTC.format());
    setAnchorEl(null);
  };

  const validateForm = async ({
    createAndSend = false,
  }: {
    createAndSend?: boolean;
  }): Promise<boolean> => {
    const schema = getFormSchema({ createAndSend });

    try {
      await schema.validate(
        {
          message,
          recipients: recipients.value,
          subject,
          whenToSend,
        },
        {
          abortEarly: false,
        },
      );

      setErrors({
        message: "",
        recipients: "",
        subject: "",
        whenToSend: "",
      });

      return true;
    } catch (error: any) {
      const newErrors: any = {
        message: "",
        recipients: "",
        subject: "",
        whenToSend: "",
      };

      error.inner.forEach((e: any) => {
        newErrors[e.path] = e.message;
      });

      setErrors(newErrors);

      return false;
    }
  };

  const whenToSendOpen = Boolean(anchorEl);

  const DropdownIndicator = (props: any) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <ChevronDownIcon color={config.theme.primary} />
        </components.DropdownIndicator>
      )
    );
  };

  return (
    <Popup
      className="modal"
      closeOnDocumentClick={!whenToSendOpen}
      onClose={onCancelAction}
      open={open}
    >
      <form className={styles.form}>
        <Box className="modal-inner">
          <Box className="modal-header">
            <h2>
              <Trans>
                Create Notification
              </Trans>
            </h2>

            <IconButton
              onClick={onCancelAction}
              size="small"
              style={{
                backgroundColor: config.theme.primaryLight,
                borderRadius: '50%',
                height: 30,
                width: 30,
              }}
            >
              <CloseIcon style={{ color: config.theme.primary, fontSize: 21 }} />
            </IconButton>
          </Box>

          <Box marginBottom={15}>
            <TextField
              error={Boolean(errors.subject)}
              fullWidth
              helperText={errors.subject}
              id="subject"
              label={t`Subject`}
              onChange={(event) => setSubject(event.target.value)}
              placeholder={t`Type here`}
              required
              value={subject}
            />

            <Box display="flex" marginBottom={15} marginTop={15}>
              <Box minWidth={250}>
                <Box marginBottom={9} paddingLeft={8} position="relative">
                  <InputLabel className={styles.inputLabel} htmlFor="recipients">
                    <Box left={0} position="absolute" top={-1}>
                      <Text color="orange" inline weight="semi-bold">* </Text>
                    </Box>

                    <Text size="md" weight="semi-bold">
                      <Trans>
                        Recipients
                      </Trans>
                    </Text>
                  </InputLabel>
                </Box>

                <Select
                  className="react-select-custom"
                  classNamePrefix="select-custom"
                  components={{ DropdownIndicator }}
                  defaultValue={recipients}
                  id="recipients"
                  isClearable={false}
                  onChange={(item: any) => setRecipients(item)}
                  options={recipientsArray}
                />

                {errors.recipients && (
                  <div className={styles.formErrors}>
                    {errors.recipients}
                  </div>
                )}
              </Box>

              <Box marginStart={30}>
                <Box marginBottom={9} paddingLeft={8} position="relative">
                  <InputLabel className={styles.inputLabel} htmlFor="when-to-send">
                    <Text size="md" weight="semi-bold">
                      <Trans>
                        When to send
                      </Trans>
                    </Text>
                  </InputLabel>
                </Box>

                <Button
                  aria-describedby="when to send"
                  className={styles.whenToSendButton}
                  onClick={(event) => setAnchorEl(event?.currentTarget)}
                >
                  {moment(whenToSend).format('MMM DD, h:mma')}
                </Button>

                <Popover
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  id="when to send"
                  onClose={() => setAnchorEl(null)}
                  open={whenToSendOpen}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Box className={styles.calendarContainer}>
                    <Calendar
                      date={whenToSend}
                      minDate={new Date()}
                      onChange={setWhenToSend}
                      showMonthAndYearPickers={false}
                    />

                    <Box
                      alignItems="center"
                      display="flex"
                      justifyContent="between"
                      marginBottom={14}
                      marginTop={10}
                    >
                      <Text size="md" weight="semi-bold">
                        <Trans>
                          Time
                        </Trans>
                      </Text>

                      <Box>
                        <FromToTimeUI timeFrom={hours} />
                      </Box>
                    </Box>

                    <HourSlider hours={hours} setHours={setHours} />

                    <Box className={styles.buttons}>
                      <Button
                        className={styles.buttonToday}
                        noPaddingX
                        onClick={onSetTodayWhenToSendData}
                        type="clear"
                      >
                        <Trans>
                          Today
                        </Trans>
                      </Button>

                      <Box>
                        <Button noPaddingX onClick={onResetWhenToSendData} type="clear">
                          <Trans>
                            Reset
                          </Trans>
                        </Button>

                        <Button noPaddingX onClick={onConfirmDate} type="primary">
                          <Trans>
                            Done
                          </Trans>
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Popover>

                {errors.whenToSend && (
                  <div className={styles.formErrors}>
                    {errors.whenToSend}
                  </div>
                )}
              </Box>
            </Box>

            <TextField
              error={Boolean(errors.message)}
              fullWidth
              helperText={errors.message}
              id="Message"
              label={t`Message`}
              multiline
              onChange={(event) => setMessage(event.target.value)}
              placeholder={t`Type here`}
              required
              rows={5}
              value={message}
            />
          </Box>

          <Box display="flex" justifyContent="end">
            <Button
              aria-label={notification ? t`Cancel edit notification` : t`Cancel create notification`}
              name={notification ? t`Cancel edit notification` : t`Cancel create notification`}
              onClick={onCancelAction}
              size="sm"
              type="clear"
            >
              <Trans>
                Cancel
              </Trans>
            </Button>

            <Button
              aria-label={notification ? t`Edit notification` : t`Create notification`}
              name={notification ? t`Edit notification` : t`Create notification`}
              onClick={onCreateOrEdit}
              size="sm"
            >
              {notification ? t`Edit Notification` : t`Create Notification`}
            </Button>

            <Box marginStart={20}>
              <Button
                aria-label={notification ? t`Edit and send` : t`Create and send`}
                name={notification ? t`Edit and send` : t`Create and send`}
                onClick={onCreateOrEditAndSend}
                size="sm"
              >
                {notification ? t`Edit and send` : t`Create and send`}
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
    </Popup>
  );
}
