import Api from 'store/_legacy/Services/Api';
import {
  call,
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import {
  CREATE_COVID_QUESTION,
  CREATE_COVID_QUESTION_FAIL,
  CREATE_COVID_QUESTION_SUCCESS,
  CreateCovidQuestion,
  DELETE_COVID_QUESTION,
  DELETE_COVID_QUESTION_FAIL,
  DELETE_COVID_QUESTION_SUCCESS,
  DeleteCovidQuestion,
  EDIT_COVID_QUESTION,
  EDIT_COVID_QUESTION_FAIL,
  EDIT_COVID_QUESTION_SUCCESS,
  EDIT_COVID_SCHEDULE,
  EDIT_COVID_SCHEDULE_FAIL,
  EDIT_COVID_SCHEDULE_SUCCESS,
  EditCovidQuestion, EditCovidSchedule,
  GET_COVID_QUESTIONS,
  GET_COVID_QUESTIONS_FAIL,
  GET_COVID_QUESTIONS_SUCCESS,
  GET_COVID_SCHEDULE,
  GET_COVID_SCHEDULE_FAIL,
  GET_COVID_SCHEDULE_SUCCESS,
  GetCovidQuestions,
  GetCovidSchedule,
} from './index';

function* getCovidQuestions(action: GetCovidQuestions): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: GET_COVID_QUESTIONS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_COVID_QUESTIONS_FAIL, payload: e });
  }
}

function* createCovidQuestionSaga(action: CreateCovidQuestion): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: CREATE_COVID_QUESTION_SUCCESS, payload });
  } catch (e) {
    yield put({ type: CREATE_COVID_QUESTION_FAIL, payload: e });
  }
}

function* editCovidQuestionSaga(action: EditCovidQuestion): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: EDIT_COVID_QUESTION_SUCCESS, payload });
  } catch (e) {
    yield put({ type: EDIT_COVID_QUESTION_FAIL, payload: e });
  }
}

function* deleteCovidQuestionSaga(action: DeleteCovidQuestion): any {
  try {
    const payload = yield call(Api, action);
    yield put({ type: DELETE_COVID_QUESTION_SUCCESS, payload });
  } catch (e) {
    yield put({ type: DELETE_COVID_QUESTION_FAIL, payload: e });
  }
}

// schedule
function* getCovidSchedule(action: GetCovidSchedule): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: GET_COVID_SCHEDULE_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_COVID_SCHEDULE_FAIL, payload: e });
  }
}

function* editCovidScheduleSaga(action: EditCovidSchedule): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: EDIT_COVID_SCHEDULE_SUCCESS, payload });
  } catch (e) {
    yield put({ type: EDIT_COVID_SCHEDULE_FAIL, payload: e });
  }
}

export default [
  takeEvery(GET_COVID_QUESTIONS, getCovidQuestions),
  takeLatest(CREATE_COVID_QUESTION, createCovidQuestionSaga),
  takeLatest(EDIT_COVID_QUESTION, editCovidQuestionSaga),
  takeLatest(DELETE_COVID_QUESTION, deleteCovidQuestionSaga),
  takeEvery(GET_COVID_SCHEDULE, getCovidSchedule),
  takeLatest(EDIT_COVID_SCHEDULE, editCovidScheduleSaga),
];
