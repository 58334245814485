import { TogetherApiTag } from "../enums";
import { togetherApi } from "../together-api";
import { APIResponseBody, CreateReservationServiceOptionInput, DeleteReservationServiceOptionInput, GetReservationServiceOptionsInput, PaginatedAPIResponseBody, ReservationServiceOption, UpdateReservationServiceOptionInput, UpdateReservationServiceOptionsInput } from "../types";
import { parsePaginationQueryParams } from "../../utils";

const api = togetherApi.injectEndpoints({
  endpoints: (builder) => ({
    getReservationServiceOptions: builder.query<PaginatedAPIResponseBody<ReservationServiceOption>, GetReservationServiceOptionsInput>({
      query: ({ locationId, ...params }) => ({
        url: `/api/locations/${locationId}/reservations/services/options`,
        params: parsePaginationQueryParams(params),
      }),
      providesTags: [{ type: TogetherApiTag.RESERVATION_SERVICE_OPTION }],
    }),
    createReservationServiceOption: builder.mutation<APIResponseBody<ReservationServiceOption>, CreateReservationServiceOptionInput>({
      query: ({ locationId, ...body }) => ({
        body,
        url: `/api/locations/${locationId}/reservations/services/options`,
        method: "POST",
      }),
      invalidatesTags: [{ type: TogetherApiTag.RESERVATION_SERVICE_OPTION }],
    }),
    updateReservationServiceOption: builder.mutation<APIResponseBody<ReservationServiceOption>, UpdateReservationServiceOptionInput>({
      query: ({ locationId, optionId, ...body }) => ({
        body,
        url: `/api/locations/${locationId}/reservations/services/options/${optionId}`,
        method: "PATCH",
      }),
      invalidatesTags: [{ type: TogetherApiTag.RESERVATION_SERVICE_OPTION }],
    }),
    updateReservationServiceOptions: builder.mutation<APIResponseBody<ReservationServiceOption>, UpdateReservationServiceOptionsInput>({
      query: ({ locationId, options }) => ({
        body: { entities: options },
        url: `/api/locations/${locationId}/reservations/services/options`,
        method: "PATCH",
      }),
      invalidatesTags: [{ type: TogetherApiTag.RESERVATION_SERVICE_OPTION }],
    }),
    getAvailableReservationServiceOptions: builder.query<PaginatedAPIResponseBody<ReservationServiceOption>, GetReservationServiceOptionsInput>({
      query: ({ locationId, ...params }) => ({
        url: `/api/locations/${locationId}/reservations/services/options/available`,
        params: parsePaginationQueryParams(params),
      }),
      providesTags: [{ type: TogetherApiTag.RESERVATION_SERVICE_OPTION }],
    }),
    deleteReservationServiceOption: builder.mutation<APIResponseBody<ReservationServiceOption>, DeleteReservationServiceOptionInput>({
      query: ({ locationId, optionId, ...body }) => ({
        body,
        url: `/api/locations/${locationId}/reservations/services/options/${optionId}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: TogetherApiTag.RESERVATION_SERVICE_OPTION }],
    }),
  }),
});

export const {
  useGetReservationServiceOptionsQuery,
  useLazyGetReservationServiceOptionsQuery,
  useCreateReservationServiceOptionMutation,
  useUpdateReservationServiceOptionMutation,
  useUpdateReservationServiceOptionsMutation,
  useGetAvailableReservationServiceOptionsQuery,
  useLazyGetAvailableReservationServiceOptionsQuery,
  useDeleteReservationServiceOptionMutation,
} = api;
