import Box from 'components/_legacy/Box';
import Button from 'components/_legacy/Button';
import moment from 'moment';
import styles from './styles.module.scss';
import { BookingDateRangeModel, NotificationModel } from '../../../Store/notifications/models';
import { DateRange, Range, RangeKeyDict } from 'react-date-range';
import { setDateRange } from '../../../Store/notifications';
import { Trans } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useTypedSelector } from 'store/_legacy/Redux/store';

interface DateRangeCalendarProps {
  notification?: NotificationModel; // data used for edit state
}

const getDateRangeInitialData = (
  startDate: Date | string | null | undefined,
  endDate: Date | string | null | undefined,
  bookingDateRange: BookingDateRangeModel,
) => {
  if (startDate && endDate && !bookingDateRange.startDate) { // return data on first open (in case of edit state)
    return {
      startDate: new Date(moment(startDate).utc().format()),
      endDate: new Date(moment(endDate).utc().format()),
      key: 'selection',
    };
  }

  if (bookingDateRange.startDate && bookingDateRange.endDate) { // if user updated sendDate
    return {
      startDate: new Date(moment(bookingDateRange.startDate).utc().format()),
      endDate: new Date(moment(bookingDateRange.endDate).utc().format()),
      key: 'selection',
    };
  }

  return {
    startDate: new Date(moment().utc().format()),
    endDate: new Date(moment().utc().format()),
    key: 'selection',
  }; // return no data on first open (in case of add state)
};

export default function DateRangeCalendar(props: DateRangeCalendarProps) {
  const dispatch = useDispatch();
  const { adminNotifications } = useTypedSelector(state => state);
  const bookingDateRange = adminNotifications.notificationsTimeSettings.bookingDateRange;
  const initSelectionRange = getDateRangeInitialData(props.notification?.startDate, props.notification?.endDate, bookingDateRange);
  const [selectionRange, setSelectionRange] = useState<Range>(initSelectionRange);

  const handleSelect = (ranges: RangeKeyDict) => {
    setSelectionRange(ranges.selection);
  };

  const onResetDateRangeData = () => {
    setSelectionRange({
      startDate: new Date(moment().utc().format()),
      endDate: new Date(moment().utc().format()),
      key: 'selection',
    });
  };

  const onSetTodayDateRangeData = () => {
    setSelectionRange({
      startDate: new Date(moment().utc().format()),
      endDate: new Date(moment().utc().format()),
      key: 'selection',
    });
  };

  const onSubmitDate = () => {
    const startDate = moment(selectionRange.startDate).utc(true).format();
    const endDate = moment(selectionRange.endDate).utc(true).format();

    dispatch(setDateRange({ startDate, endDate }));
  };

  return (
    <Box className={styles.calendar}>
      <Box>
        <DateRange
          minDate={new Date()}
          onChange={handleSelect}
          rangeColors={['#efeff8']}
          ranges={[selectionRange]}
          showDateDisplay={false}
          showMonthAndYearPickers={false}
        />
      </Box>

      <Box alignItems="center" className={styles.buttons} display="flex" justifyContent="between" marginBottom={8}>
        <Button className={styles.buttonToday} noPaddingX onClick={onSetTodayDateRangeData} type="clear">
          <Trans>Today</Trans>
        </Button>

        <Box>
          <Button noPaddingX onClick={onResetDateRangeData} type="clear">
            <Trans>Reset</Trans>
          </Button>

          <Button noPaddingX onClick={onSubmitDate} type="primary">
            <Trans>Done</Trans>
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
