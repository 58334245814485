import moment from 'moment-timezone';
import {locationTimezonesInterface} from '../../../../App/Store/Locations/locationsDuck/models';
import { RolesAccessType } from "../../../../App/Store/Users/profileDuck/models";
import { AdminLocation, Location } from "../../../../App/Store/Locations/locationsDuck/models";
import { ReservationFiltersModel } from "../../../Store/reservations/models";

const sortByGmt = (a: string, b: string) => {
  // function sort array by hours with GMT+hh:mm format
  const [ahh,amm] = moment.tz(a).format('Z').split(":");
  const [bhh,bmm] = moment.tz(b).format('Z').split(":");

  // @ts-ignore
  return (+ahh*60+amm) - (+bhh*60+bmm);
};

export function getTimezoneList() {
  const timeZones = moment.tz.names().sort(sortByGmt);
  const timezoneList: locationTimezonesInterface[] = [];
  for (const i in timeZones) {
    const gmtOffset = `${moment.tz(timeZones[i]).format('Z')}`;
    const zoneName = timeZones[i];

    timezoneList.push({
      offset: `GMT${gmtOffset}`,
      name: zoneName,
    });
  }

  return timezoneList;
}

function isRestrictedLocationsRole(roleAccess: RolesAccessType): boolean {
  return roleAccess.localAdmin || roleAccess.hr || roleAccess.reservationManager;
}

export function getAllowedLocationsByRoles(
  roleAccess: RolesAccessType,
  locations: Location[] | AdminLocation[],
  profileLocationIds: string[],
): Location[] | AdminLocation[] {
  const isRestrictedRole = isRestrictedLocationsRole(roleAccess);
  return isRestrictedRole ? locations.filter((l: Location | AdminLocation) => profileLocationIds.includes(l.id)) : locations;
}

export function getLocationIdsByRoles(
  roleAccess: RolesAccessType,
  filters: ReservationFiltersModel,
  allowedLocations: Location[] | AdminLocation[],
): string[] {
  // add extra check for location Restricted roles - get only accessible locations
  const isRestrictedRole = isRestrictedLocationsRole(roleAccess);

  // return all filtered locations for super admin | some of them if other role has some filtered locations
  if (!isRestrictedRole || filters.locationIds.length) {
    return filters.locationIds;
  }

  // RESTRICTED ONLY ROLES
  // add unused locations id to return no data if case if restricted role (local admin/hr/...) have no allowed locations
  if (!allowedLocations.length) {
    return ['11111111-79ff-4dec-994c-4199060e7c11'];
  }

  // return only allowed locations by default
  if (!filters.locationIds.length) {
    return allowedLocations.map((location: Location | AdminLocation) => location.id);
  }

  // return filtered locations by default
  return filters.locationIds;
}
