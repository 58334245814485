import { ButtonProps, List, ListItem, ListItemButton, Popover, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { Order } from "types";
import { TextButton } from "./text-button";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { t } from "@lingui/macro";

export const OrderButton: React.FC<ButtonProps & { order?: Order; onOrderChange?: (order: Order) => void }> = (props) => {
  const { children, order, onOrderChange, ...rest } = props;
  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOrderClick = (order: Order) => {
    setAnchorEl(null);
    onOrderChange?.(order);
  };

  return (
    <>
      <TextButton
        data-cid="sort-button"
        {...rest}
        onClick={(event) => setAnchorEl(event.currentTarget)}
        sx={{
          ...rest?.sx,
          "& .MuiSvgIcon-root": {
            transition: !order ? "opacity .12s" : undefined,
            opacity: !order ? 0 : undefined,
          },
          ":hover": {
            "& .MuiSvgIcon-root": {
              opacity: !order ? 1 : undefined,
            },
          },
        }}
      >
        {children}
        {order === "asc" ? <ArrowDropUp sx={{ color: palette.grey[700] }} /> : <ArrowDropDown sx={{ color: palette.grey[700] }} />}
      </TextButton>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        onClose={() => setAnchorEl(null)}
        open={!!anchorEl}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
      >
        <List disablePadding>
          <ListItem disablePadding divider>
            <ListItemButton data-cid="sort-descending-button" onClick={() => handleOrderClick("desc")}>
              <ArrowDropDown sx={{ color: palette.grey[700] }} />
              <Typography color={palette.grey[700]} fontSize={13} ml={1}>{t`Sort descending`}</Typography>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton data-cid="sort-ascending-button" onClick={() => handleOrderClick("asc")}>
              <ArrowDropUp sx={{ color: palette.grey[700] }} />
              <Typography color={palette.grey[700]} fontSize={13} ml={1}>{t`Sort ascending`}</Typography>
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </>
  );
};
