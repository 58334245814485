import Api from 'store/_legacy/Services/Api';
import arrowIcon from './assets/arrow-down.svg';
import Box from 'components/_legacy/Box';
import Button from 'components/_legacy/Button';
import Popup from 'reactjs-popup';
import styles from './styles.module.scss';
import { Autocomplete } from '@material-ui/lab';
import { Trans, t } from '@lingui/macro';
import TextField from '@material-ui/core/TextField';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTypedSelector } from 'store/_legacy/Redux/store';
import {
  createLocation,
  editLocation,
  getLocationAutocomplete,
} from 'App/Store/Locations/locationsDuck';
import {
  Location,
  locationTimezonesInterface,
} from '../../../../App/Store/Locations/locationsDuck/models';
import _ from 'lodash';
import Text from 'components/_legacy/Text';

function parseLocations(locations: any): any {
  const parsedLocations: parsedLocationInterface[] = [];

  if (locations && Array.isArray(locations)) {
    for (const location of locations) {
      const { fullAddress, city, country, region, externalCode, geolocation, externalFullCode } = location;
      const parsedLocation: parsedLocationInterface = { fullAddress, city, country, region, externalCode, externalFullCode };

      if (geolocation && Array.isArray(geolocation)) {
        const [latitude, longitude] = geolocation as [number, number];

        parsedLocation.latitude = latitude;
        parsedLocation.longitude = longitude;
      }

      parsedLocations.push(parsedLocation);
    }
  }

  return parsedLocations;
}

interface parsedLocationInterface {
  fullAddress: string;
  city: string;
  country: string;
  region: string;
  externalCode?: string;
  externalFullCode?: string;
  latitude?: number;
  longitude?: number;
}

interface LocationFormProps {
  actionState: 'add' | 'edit';
  open: boolean;
  onCancelAction: any;
  location?: Location; // data used for edit state
}

export default function LocationForm(props: LocationFormProps) {
  const dispatch = useDispatch();
  const { config, locations } = useTypedSelector(state => state);
  const { locationTimezones } = locations;
  const {
    location,
    actionState,
    open,
    onCancelAction,
  } = props;

  const actionTypeName = actionState === 'add' ? t`Add` : t`Edit`;

  const locationName = location && location.locationName ? location.locationName : '';
  const locationAddress = location && location.locationAddress ? {
    fullAddress: location.locationAddress,
    city: location.city,
    country: location.country,
    region: location.region,
  } : { fullAddress: '', city: '', country: '', region: '' };
  const locationTimezone = location && location.timezone && locationTimezones.find(t => t.name === location.timezone) || { offset: "GMT+00:00", name: "UTC" };

  const [locationNameValue, setLocationNameValue] = useState(locationName);
  const [locationAddressList, setLocationAddressList] = useState([]);
  const [locationAddressValue, setLocationAddressValue] = useState<parsedLocationInterface>(locationAddress);
  const [locationTimezoneValue, setLocationTimezoneValue] = useState<locationTimezonesInterface>(locationTimezone);
  const [locationTimezoneList, setLocationTimezoneList] = useState<locationTimezonesInterface[]>(locationTimezones);

  const [locationNameError, setLocationNameError] = useState(false);
  const [locationAddressError, setLocationAddressError] = useState(false);
  const [locationAddressErrorText, setLocationAddressErrorText] = useState('');
  const [locationTimezoneError, setLocationTimezoneError] = useState(false);

  useEffect(() => {
    setLocationNameValue(locationName);
    setLocationAddressValue(locationAddress);
    setLocationTimezoneValue(locationTimezone);
  }, [location]);

  const locationNameErrorClass = locationNameError ? styles.formErrorShow : '';
  const locationAddressErrorClass = locationAddressError ? styles.formErrorShow : '';
  const locationTimezoneErrorClass = locationTimezoneError ? styles.formErrorShow : '';

  const onNameChange = (event: any) => {
    setLocationNameValue(event.target.value);

    if (event.target.value) {
      setLocationNameError(false);
    }
  };

  const onAddressInputChange = _.debounce(async (event: any) => {
    if (event && !event.target.value) {
      setLocationAddressList([]);
      return;
    }

    try {
      const action = dispatch(getLocationAutocomplete({ search: event.target.value }));
      const payload = await Api(action);

      if (payload.status === 200) {
        setLocationAddressList(parseLocations(payload.data?.result?.data?.items));
      }
    } catch (e) {
      console.log('error with location autocomplete');
    }
  }, 300);

  const onAddressChange = (option: parsedLocationInterface) => {
    setLocationAddressValue(option);

    if (option) {
      setLocationAddressError(false);
    }
  };

  const onTimezoneChange = (event: any, option: locationTimezonesInterface) => {
    setLocationTimezoneValue(option);

    if (option) {
      setLocationTimezoneError(false);
    }

    if (event.target.value === 0) {
      setLocationTimezoneList(locationTimezones);
    }
  };

  const onTimezoneSearchChange = (event: any) => {
    // make search only for next fields
    const fieldsToSearch = ['offset', 'name'];
    const search = event.target.value;

    const filteredTimezones = locationTimezones
      .filter(item => {
        // @ts-ignore
        return Object.keys(item).some(key => fieldsToSearch.includes(key) && item[key].toLowerCase().includes(search.toLowerCase()));
      });

    setLocationTimezoneList(filteredTimezones);
  };

  const validateFields = () => {
    let validate = true;

    if (!locationNameValue.length || !locationAddressValue?.fullAddress.length || !locationTimezoneValue || !locationAddressValue?.city.length) {
      validate = false;
    }

    // set errors messages
    if (!locationNameValue.length) {
      setLocationNameError(true);
    }

    if (!locationAddressValue?.city.length) {
      setLocationAddressErrorText(t`There is no city in current address. Please add the city`);
      setLocationAddressError(true);
    }

    if (!locationAddressValue?.fullAddress.length) {
      setLocationAddressErrorText(t`This field can\'t be empty`);
      setLocationAddressError(true);
    }

    if (!locationTimezoneValue) {
      setLocationTimezoneError(true);
    }

    return validate;
  };

  const onLocationFormSubmit = () => {
    if (!validateFields()) {
      return;
    }

    // collect data from fields
    const locationData = {
      active: location ? location.active : true,
      city: locationAddressValue.city || '',
      country: locationAddressValue.country || '',
      region: locationAddressValue.region || '',
      locationName: locationNameValue || '',
      locationAddress: locationAddressValue.fullAddress || '',
      timezone: locationTimezoneValue && locationTimezoneValue.name || '',
      uploadDocNotification: location ? location.uploadDocNotification : false,
      allowedBookingDayType: location ? location.allowedBookingDayType : 1,
      externalCode: locationAddressValue.externalCode,
      latitude: locationAddressValue.latitude,
      longitude: locationAddressValue.longitude,
    };

    // submit logic
    if (actionState === 'add') {
      dispatch(createLocation(locationData));
    }
    if (actionState === 'edit') {
      if (location) {
        dispatch(editLocation({ location: locationData, locationId: location.id }));
      }
    }
    onCancelAction();
  };

  const submitButtonDisabled = !locationNameValue.length || !locationAddressValue?.fullAddress.length || !locationTimezoneValue;

  return (
    <Popup
      className={'modal'}
      closeOnDocumentClick
      nested
      onClose={onCancelAction}
      open={open}
    >
      <div className="modal-inner">
        <div className="modal-header">
          <h2>
            <Trans>
              {actionTypeName} location
            </Trans>
          </h2>

          <svg className="modal-header__close" height="30px" onClick={() => onCancelAction()} viewBox="0 0 30 30" width="30px">
            <g fill="none" fillRule="evenodd" id="Booking" stroke="none" strokeWidth="1">
              <g id="Booking-savedall" transform="translate(-875.000000, -132.000000)">
                <g id="Group-8" transform="translate(515.000000, 112.000000)">
                  <g id="icons/close" transform="translate(360.000000, 20.000000)">
                    <rect fill={config.theme.primaryLight} height="30" id="Rectangle" rx="8" width="30" x="0" y="0"></rect>
                    <path d="M20.704633,9.29536704 C21.0984557,9.68918977 21.0984557,10.3277026 20.704633,10.7215253 L16.4261582,15 L20.704633,19.2784747 C21.0984557,19.6722974 21.0984557,20.3108102 20.704633,20.704633 C20.3108102,21.0984557 19.6722974,21.0984557 19.2784747,20.704633 L15,16.4261582 L10.7215253,20.704633 C10.3277026,21.0984557 9.68918977,21.0984557 9.29536704,20.704633 C8.90154432,20.3108102 8.90154432,19.6722974 9.29536704,19.2784747 L13.5738418,15 L9.29536704,10.7215253 C8.90154432,10.3277026 8.90154432,9.68918977 9.29536704,9.29536704 C9.68918977,8.90154432 10.3277026,8.90154432 10.7215253,9.29536704 L15,13.5738418 L19.2784747,9.29536704 C19.6722974,8.90154432 20.3108102,8.90154432 20.704633,9.29536704 Z" fill={config.theme.primary}></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div className={`modal-inner-content ${styles.modalInnerContent}`}>
          <Box className={''} marginBottom={15}>
            <span className={styles.requiredSign}>*</span><label htmlFor="location-name"><Trans>Location name</Trans></label>

            <TextField
              className={`input input--default input--inline ${locationNameErrorClass}`}
              defaultValue={locationNameValue}
              fullWidth
              id="location-name"
              onChange={onNameChange}
              placeholder={t`Type here`}
              rows={5}
              variant="outlined"
            />
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            {locationNameError ? <div className={styles.formErrors}><Trans>This field can't be empty</Trans></div> : null}
          </Box>

          <Box className={''} marginBottom={15}>
            <span className={styles.requiredSign}>*</span><label htmlFor="address"><Trans>Address</Trans></label>

            <Autocomplete
              className={`input input--default input--inline ${locationAddressErrorClass}`}
              filterOptions={(x) => x} // fix load throttling
              getOptionLabel={({ fullAddress }) => fullAddress}
              id="address"
              onChange={(event: any, newValue: any) => {
                onAddressChange(newValue);
              }}
              onInputChange={onAddressInputChange}
              options={locationAddressList}
              popupIcon={
                <img height={10} src={arrowIcon} width={14} />
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t`Not selected`}
                  variant="outlined"
                />
              )}
              renderOption={(option) => (
                <Box direction="column" display="flex">
                  <Text size="md" weight="regular">{option.fullAddress}</Text>
                  <Text color="gray" size="sm" weight="regular">{option.externalFullCode}</Text>
                </Box>
              )}
              value={locationAddressValue}
            />
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            {locationAddressError ? <div className={styles.formErrors}>{locationAddressErrorText}</div> : null}
          </Box>
          <Box className={''} marginBottom={20}>
            <span className={styles.requiredSign}>*</span><label htmlFor="timezone"><Trans>Timezone</Trans></label>

            <Autocomplete
              className={`input input--default input--inline ${locationTimezoneErrorClass}`}
              defaultValue={locationTimezoneValue}
              filterOptions={(x) => x} // fix load throttling
              getOptionLabel={(option) => `${option.offset} (${option.name})`}
              id="timezone"
              onBlur={() => setLocationTimezoneList(locationTimezones)}
              onChange={(event: any, option: any) => {
                onTimezoneChange(event, option);
              }}
              options={locationTimezoneList}
              popupIcon={
                <img height={10} src={arrowIcon} width={14} />
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  onChange={(e) => onTimezoneSearchChange(e)}
                  placeholder={t`Not selected`}
                  variant="outlined"
                />
              )}
            />
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            {locationTimezoneError ? <div className={styles.formErrors}><Trans>This field can't be empty</Trans></div> : null}
          </Box>
          <Box display="flex" justifyContent="end">
            <Button
              aria-label={t`cancel location edition`}
              name={t`cancel location edition`}
              onClick={() => onCancelAction()}
              size="sm"
              type="clear"
            >
              <Trans>Cancel</Trans>
            </Button>

            <Button
              aria-label={t`confirm location edition`}
              disabledStyle={submitButtonDisabled}
              name={t`confirm location edition`}
              onClick={() => onLocationFormSubmit()}
              size="sm"
            >
              <Trans>{actionTypeName} location</Trans>
            </Button>
          </Box>
        </div>
      </div>
    </Popup >
  );
}
