export enum EditorElementType {
  HEADING = "heading",
  PARAGRAPH = "paragraph",
  LIST = "list",
  LIST_ITEM = "list-item",
  ANCHOR = "anchor",
  IMAGE = "image",
  HTML = "html",
  EMBEDDED_VIDEO = "embedded-video",
  BOX = "box",
  BOX_CELL = "box-cell",
}
