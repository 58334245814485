import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'store/_legacy/Redux/store';

import { useMapEvents } from 'react-leaflet';
import 'leaflet-draw';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';

import { setFloorMapData } from 'Admin/Store/floorMapDuck';
import MapDrawingSections from "./MapDrawingSections";
import MapDrawingDesks from "./MapDrawingDesks";
import MapDrawingMeetings from "./MapDrawingMeetings";

export default function MapDrawing() {
  const dispatch = useDispatch();
  const { adminFloorMap } = useTypedSelector(state => state);

  const {
    activeDeskId,
    activeSectionId,
    activeMeetingId,
  } = adminFloorMap;

  // disable all objects active state in case on creating new one
  useMapEvents({
    'click': (e) => {
      const mapElement = document.querySelector('#mapid > div');
      const isClickOnEmptyMapArea = e.originalEvent.target === mapElement;

      if (isClickOnEmptyMapArea) {
        // Disables active desk if there's one
        if (activeDeskId) {
          dispatch(setFloorMapData({ activeDeskId: undefined }));
        }

        // Disables active section if there's one
        if (activeSectionId) {
          dispatch(setFloorMapData({ activeSectionId: undefined }));
        }

        // Disables active meeting room if there's one
        if (activeMeetingId) {
          dispatch(setFloorMapData({ activeMeetingId: undefined }));
        }
      }
    },
  });

  return (
    <>
      <MapDrawingSections />
      <MapDrawingDesks />
      <MapDrawingMeetings />
    </>
  );
}
