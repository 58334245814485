import { createApi } from "@reduxjs/toolkit/query/react";
import { CommApiTag } from "./enums";
import { baseQuery } from "../utils";

export const commApi = createApi({
  reducerPath: "commApi",
  tagTypes: [
    CommApiTag.POST,
    CommApiTag.POST_TEMPLATE,
    CommApiTag.POST_COMMENT,
    CommApiTag.POST_REACTION,
    CommApiTag.POST_COMMENT_REACTION,
    CommApiTag.POST_SHARE,
    CommApiTag.POLICY,
  ],
  baseQuery: baseQuery({ baseUrl: process.env.REACT_APP_COMM_API_URL }),
  endpoints: () => ({}),
});
