import Skeleton from '@material-ui/lab/Skeleton';
import { t } from '@lingui/macro';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ConfirmationDialog, SortableList, SortableListItem, SortableListProps, TogetherDelete, useToast } from 'components';
import { Box, Button, Dialog, FilledInput, IconButton, Switch, Typography, useTheme } from '@mui/material';
import { DragIndicator } from '@mui/icons-material';
import { ReservationServiceOption, ReservationServiceOptionStatus, ReservationServiceOptionType, ReservationServiceType, isSuccessAPIResponse, useCreateReservationServiceOptionMutation, useDeleteReservationServiceOptionMutation, useGetReservationServiceOptionsQuery, useUpdateReservationServiceOptionMutation, useUpdateReservationServiceOptionsMutation } from 'store';
import { moveArrayItem } from 'utils';
import { DraggableAttributes } from '@dnd-kit/core';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';

const resolveTypeLabel = (type?: ReservationServiceOptionType): string | undefined => {
  switch (type) {
    case ReservationServiceOptionType.BEVERAGE:
      return t`beverage`;
    case ReservationServiceOptionType.BREAKFAST:
      return t`breakfast`;
    case ReservationServiceOptionType.LUNCH:
      return t`lunch`;
    case ReservationServiceOptionType.SNACK:
      return t`snack`;
    default:
      return undefined;
  }
};

const SortableListItemComponent: React.FC<{
  attributes: DraggableAttributes;
  listeners: SyntheticListenerMap | undefined;
  option: ReservationServiceOption;
  isDragging?: boolean;
}> = (props) => {
  const { attributes, listeners, option, isDragging } = props;
  const { locationId } = useParams<{ locationId: string }>();
  const { palette } = useTheme();
  const toast = useToast();
  const [updateReservationServiceOption] = useUpdateReservationServiceOptionMutation();
  const [deleteReservationServiceOption] = useDeleteReservationServiceOptionMutation();

  const handleDeleteClick = () => {
    void (async () => {
      const response = await deleteReservationServiceOption({ locationId, optionId: option.id });

      if (isSuccessAPIResponse(response)) {
        toast.showToast({ severity: "success", message: t`"${option.name}" was removed` });
      } else {
        toast.showToast({ severity: "error", message: t`Failed to remove "${option.name}"` });
      }
    })();
  };

  const handleSwitchClick = () => {
    void (async () => {
      const response = await updateReservationServiceOption({
        locationId,
        optionId: option.id,
        status: option.status === ReservationServiceOptionStatus.AVAILABLE 
          ? ReservationServiceOptionStatus.UNAVAILABLE
          : ReservationServiceOptionStatus.AVAILABLE,
      });

      if (isSuccessAPIResponse(response)) {
        toast.showToast({ severity: "success", message: t`"${option.name}" was updated` });
      } else {
        toast.showToast({ severity: "error", message: t`Failed to update "${option.name}"` });
      }
    })();
  };

  return (
    <>
      <Box borderBottom="1px solid" borderColor={palette.grey[100]} display="flex" sx={{ ":hover": { bgcolor: palette.grey[100] } }}>
        <Box
          alignItems="center"
          display="flex"
          flexBasis={36}
          justifyContent="center"
          sx={{ cursor: isDragging ? "grabbing" : "grab" }}
          width={36}
          {...attributes}
          {...listeners}
        >
          <DragIndicator color="primary" fontSize="small" />
        </Box>
        <Box
          alignItems="center"
          display="flex"
          flex="1 0 auto"
          paddingY={1}
        >
          <Box width={360}>
            <Typography fontSize={14}>{option.name}</Typography>
          </Box>
          <Box flex={1}>
            <Switch checked={option.status === ReservationServiceOptionStatus.AVAILABLE} onClick={handleSwitchClick} />
          </Box>
          <Box display="flex" flexBasis={50} justifyContent='center' width={50}>
            <ConfirmationDialog
              Trigger={({ onClick }) => (
                <IconButton onClick={onClick} size="small"><TogetherDelete fill={palette.primary.main} sx={{ width: 16, height: 16 }} /></IconButton>
              )}
              description={t`Are you sure you want to delete the catering option "${option.name}"?`}
              onConfirm={handleDeleteClick}
              title={t`Delete catering option`}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

const Section: React.FC<{
  type: ReservationServiceOptionType;
  options?: ReservationServiceOption[];
  isLoading?: boolean;
  onAddNew?: () => void;
  onOrderChange?: SortableListProps["onChange"],
}> = (props) => {
  const { type, options, isLoading, onAddNew, onOrderChange } = props;
  const { palette } = useTheme();

  return (
    <Box mb={3}>
      <Box alignItems="center" display="flex" gap={2}>
        <Typography fontSize={16} fontWeight={600} textTransform="capitalize">{resolveTypeLabel(type)}</Typography>
        <Button onClick={onAddNew} variant="text">{t`Add new`}</Button>
      </Box>
      <Box bgcolor={palette.grey[100]} borderRadius={1} display="flex" marginY={1} paddingY={0.5}>
        <Box flexBasis={36} width={36} />
        <Box width={360}>
          <Typography color={palette.grey[700]} fontSize={13}>{t`Name`}</Typography>
        </Box>
        <Box flex={1}>
          <Typography color={palette.grey[700]} fontSize={13}>{t`Available`}</Typography>
        </Box>
        <Box flexBasis={50} width={50} />
      </Box>
      {isLoading && !options?.length ? <Skeleton height={46} variant="rect" width="100%" /> : undefined}
      {!isLoading && !options?.length ? (
        <Box paddingY={2} pl={4}>
          <Typography color={palette.grey[700]} fontSize={14}>
            {t`The are no ${resolveTypeLabel(type)} options for this location, you can add a new one above.`}
          </Typography>
        </Box>
      ) : undefined}
      {!isLoading && !!options?.length ? (
        <SortableList items={options} onChange={onOrderChange}>
          {options.map((option) => (
            <SortableListItem
              Component={(props) => <SortableListItemComponent {...props} option={option} />}
              id={option.id}
              key={option.id}
            />
          ))}
        </SortableList>
      ) : undefined}
    </Box>
  );
};

export const CateringList: React.FC = () => {
  const { locationId } = useParams<{ locationId: string }>();
  const toast = useToast();
  const [hasSorted, setHasSorted] = useState<boolean>(false);
  const [beverage, setBeverage] = useState<ReservationServiceOption[]>([]);
  const [breakfast, setBreakfast] = useState<ReservationServiceOption[]>([]);
  const [snack, setSnack] = useState<ReservationServiceOption[]>([]);
  const [lunch, setLunch] = useState<ReservationServiceOption[]>([]);
  const [option, setOption] = useState<{ type: ReservationServiceOptionType; name: string }>();
  const [updateReservationServiceOptions] = useUpdateReservationServiceOptionsMutation();
  const [createReservationServiceOption] = useCreateReservationServiceOptionMutation();
  const getReservationServiceOptionsQuery = useGetReservationServiceOptionsQuery({
    locationId,
    limit: -1,
    filter: { serviceType: ReservationServiceType.CATERING },
  });
  const { data: getReservationServiceOptionsResponse, isLoading: optionsAreLoading } = getReservationServiceOptionsQuery;
  const { items: options } = getReservationServiceOptionsResponse?.result?.data || {};

  useEffect(() => {
    const beverage = options?.filter(({ type }) => type === ReservationServiceOptionType.BEVERAGE);
    const breakfast = options?.filter(({ type }) => type === ReservationServiceOptionType.BREAKFAST);
    const snack = options?.filter(({ type }) => type === ReservationServiceOptionType.SNACK);
    const lunch = options?.filter(({ type }) => type === ReservationServiceOptionType.LUNCH);

    setBeverage(beverage?.length ? beverage : []);
    setBreakfast(breakfast?.length ? breakfast : []);
    setSnack(snack?.length ? snack : []);
    setLunch(lunch?.length ? lunch : []);
  }, [JSON.stringify(options)]);

  useEffect(() => {
    setHasSorted(false);
  }, [locationId]);

  const updateOptionsSortIndex = async (options: ReservationServiceOption[]) => {
    const response = await updateReservationServiceOptions({ locationId, options: options.map(({ id }, sortIndex) => ({ id, sortIndex })) });

    if (isSuccessAPIResponse(response)) {
      toast.showToast({ severity: "success", message: t`Sorting order updated` });
    } else {
      toast.showToast({ severity: "error", message: t`Failed to update sorting order` });
    }

    setHasSorted(false);
  };

  useEffect(() => {
    if (beverage.length && hasSorted) {
      const timeout = setTimeout(() => void updateOptionsSortIndex(beverage), 700);

      return () => clearTimeout(timeout);
    }
  }, [JSON.stringify(beverage)]);

  useEffect(() => {
    if (breakfast.length && hasSorted) {
      const timeout = setTimeout(() => void updateOptionsSortIndex(breakfast), 700);

      return () => clearTimeout(timeout);
    }
  }, [JSON.stringify(breakfast)]);

  useEffect(() => {
    if (snack.length && hasSorted) {
      const timeout = setTimeout(() => void updateOptionsSortIndex(snack), 700);

      return () => clearTimeout(timeout);
    }
  }, [JSON.stringify(snack)]);

  useEffect(() => {
    if (lunch.length && hasSorted) {
      const timeout = setTimeout(() => void updateOptionsSortIndex(lunch), 700);

      return () => clearTimeout(timeout);
    }
  }, [JSON.stringify(lunch)]);

  // const debouncedUpdateLocationFloors = useDebouncedCallback(async (floors: FloorAdmin[]) => {
  //   const orderedFloors = floors.map(({ id }, sortIndex) => ({ id, sortIndex }));

  //   const response = await updateLocationFloors({ locationId, floors: orderedFloors });

  //   if (isSuccessAPIResponse(response)) {
  //     toast.showToast({ severity: "success", message: t`Location's floors sorting updated` });
  //   }
  // }, [updateLocationFloors], 700);

  // useEffect(() => {
  //   if (hasSorted && floors.length) {
  //     void debouncedUpdateLocationFloors(floors);
  //   }
  // }, [floors, hasSorted]);

  const handleOrderChange = (
    current: number,
    next: number,
    state: ReservationServiceOption[],
    setState: Dispatch<SetStateAction<ReservationServiceOption[]>>,
  ) => {
    setState(moveArrayItem(state, current, next));
    setHasSorted(true);
  };

  const handleAddClick = () => {
    if (option) {
      const { type, name } = option;

      void (async () => {
        const response = await createReservationServiceOption({
          locationId,
          type,
          name,
          serviceType: ReservationServiceType.CATERING,
          status: ReservationServiceOptionStatus.UNAVAILABLE,
        });

        if (isSuccessAPIResponse(response)) {
          toast.showToast({ severity: "success", message: `${resolveTypeLabel(type)} ${t`added`}` });
        } else {
          toast.showToast({ severity: "error", message: `${t`Failed to add`} ${resolveTypeLabel(type)}` });
        }
      })();
  
      setOption(undefined);
    }
  };

  return (
    <>
      <Box pt={2}>
        <Section
          isLoading={optionsAreLoading}
          onAddNew={() => setOption({ type: ReservationServiceOptionType.BEVERAGE, name: "" })}
          onOrderChange={(current, next) => handleOrderChange(current, next, beverage, setBeverage)}
          options={beverage}
          type={ReservationServiceOptionType.BEVERAGE}
        />
        <Section
          isLoading={optionsAreLoading}
          onAddNew={() => setOption({ type: ReservationServiceOptionType.BREAKFAST, name: "" })}
          onOrderChange={(current, next) => handleOrderChange(current, next, breakfast, setBreakfast)}
          options={breakfast}
          type={ReservationServiceOptionType.BREAKFAST}
        />
        <Section
          isLoading={optionsAreLoading}
          onAddNew={() => setOption({ type: ReservationServiceOptionType.SNACK, name: "" })}
          onOrderChange={(current, next) => handleOrderChange(current, next, snack, setSnack)}
          options={snack}
          type={ReservationServiceOptionType.SNACK}
        />
        <Section
          isLoading={optionsAreLoading}
          onAddNew={() => setOption({ type: ReservationServiceOptionType.LUNCH, name: "" })}
          onOrderChange={(current, next) => handleOrderChange(current, next, lunch, setLunch)}
          options={lunch}
          type={ReservationServiceOptionType.LUNCH}
        />
      </Box>
      <Dialog onClose={() => setOption(undefined)} open={!!option}>
        {option ? (
          <Box p={2}>
            <Typography fontSize={18} fontWeight={600} mb={2}>{t`Add`}{" "}{resolveTypeLabel(option?.type)}</Typography>
            <Typography fontSize={14} mb={1}>{t`Name`}:</Typography>
            <FilledInput onChange={(event) => setOption({ ...option, name: event.target.value })} placeholder={t`Type here`} value={option?.name} />
            <Box display="flex" gap={2} justifyContent="flex-end" pt={2}>
              <Button onClick={() => setOption(undefined)}>{t`Cancel`}</Button>
              <Button disabled={!option?.name} onClick={handleAddClick} variant="contained">{t`Add`}</Button>
            </Box>
          </Box>
        ) : undefined}
      </Dialog>
    </>
  );
};
