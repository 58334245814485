import { assign } from "underscore";
import { parseArrayQueryParam } from "./parse-array-query-param";
import { parseObjectQueryParam } from "./parse-object-query-param";
import { PaginationQueryParams } from "../types";

export const parsePaginationQueryParams = (params: PaginationQueryParams): Record<string, string | number | undefined> => {
  const { page, limit, filter, include, orderBy, search } = params;
  const query: Record<string, string | number | undefined> = {
    page,
    limit,
    search,
  };

  if (include) {
    assign(query, parseArrayQueryParam("include", include));
  }

  if (filter) {
    assign(query, parseObjectQueryParam("filter", filter));
  }

  if (orderBy) {
    if (Array.isArray(orderBy)) {
      assign(query, parseArrayQueryParam("orderBy", orderBy));
    } else {
      query.orderBy = orderBy;
    }
  }

  return query;
};
