import { Box, useTheme } from "@mui/material";
import React from "react";

export const ListHeader: React.FC<React.PropsWithChildren<{}>> = (props) => {
  const { children } = props;
  const { palette } = useTheme();

  return (
    <Box alignItems="center" bgcolor={palette.grey[100]} borderRadius={1} display="flex" gap={1} px={1} py={0.5}>{children}</Box>
  );
};