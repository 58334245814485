import { RecurrenceOptions } from "types";
import { recurrenceOptionsToRRule } from "./recurrence-options-to-rrule";

export const resolveRecurrenceOptionsOccurrences = (
  startDate: Date,
  endDate: Date,
  recurrence?: RecurrenceOptions,
): [startDate: Date, endDate: Date][] => {
  if (!recurrence) {
    return [[startDate, endDate]];
  }

  const duration = endDate.getTime() - startDate.getTime();
  const dates: [Date, Date][] = [];
  const occurrences = recurrenceOptionsToRRule(recurrence)?.all();

  for (const startDate of occurrences || []) {
    const endDate = new Date(startDate.getTime() + duration);

    dates.push([startDate, endDate]);
  }

  return dates;
};
