import Api from 'store/_legacy/Services/Api';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  GET_GROUP_USERS,
  GET_GROUP_USERS_FAIL,
  GET_GROUP_USERS_SUCCESS,
  GetGroupsUsers,
  UPDATE_GROUP_USERS,
  UPDATE_GROUP_USERS_FAIL,
  UPDATE_GROUP_USERS_SUCCESS,
  UpdateGroupUsers,
} from "./index";

function* getGroupUsersSaga(action: GetGroupsUsers): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: GET_GROUP_USERS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_GROUP_USERS_FAIL, payload: e });
  }
}

function* updateGroupUsersSaga(action: UpdateGroupUsers): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: UPDATE_GROUP_USERS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: UPDATE_GROUP_USERS_FAIL, payload: e });
  }
}

export default [
  takeLatest(GET_GROUP_USERS, getGroupUsersSaga),
  takeLatest(UPDATE_GROUP_USERS, updateGroupUsersSaga),
];
