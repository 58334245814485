import getErrorMessageFromPayload from 'store/_legacy/Redux/Helpers/getErrorMessageFromPayload';
import { t } from '@lingui/macro';
import {
  CancelReservationsByDeskRequest,
  CancelReservationsByDeskResponse,
  CheckDeskReservationModel,
  CheckDeskReservationRequest,
  CheckDeskReservationResponse,
  DeleteDeskFromFloorRequest,
  DeleteDeskFromFloorResponse,
  DeleteMeetingFromFloorRequest,
  DeleteMeetingFromFloorResponse,
  DeleteSectionFromFloorRequest,
  DeleteSectionFromFloorResponse,
  EditMapFileRequest,
  EditMapFileResponse,
  GetFloorSchemaRequest,
  GetFloorSchemaResponse,
  SaveMapDrawingsRequest,
  SaveMapDrawingsResponse,
  SaveMapFileRequest,
  SaveMapFileResponse,
  SetFloorMapApiRequestsDataRequest,
} from './models';
import {
  ActionPayload,
  BaseErrorResponse,
  BaseResponse,
} from 'store/_legacy/Models/ReduxModels';

export const SAVE_MAP_DRAWINGS = 'SAVE_MAP_DRAWINGS';
export const SAVE_MAP_DRAWINGS_FAILURE = 'SAVE_MAP_DRAWINGS_FAILURE';
export const SAVE_MAP_DRAWINGS_SUCCESS = 'SAVE_MAP_DRAWINGS_SUCCESS';

export const GET_FLOOR_SCHEMA = 'GET_FLOOR_SCHEMA';
export const GET_FLOOR_SCHEMA_FAILURE = 'GET_FLOOR_SCHEMA_FAILURE';
export const GET_FLOOR_SCHEMA_SUCCESS = 'GET_FLOOR_SCHEMA_SUCCESS';

export const SAVE_MAP_FILE = 'SAVE_MAP_FILE';
export const SAVE_MAP_FILE_FAILURE = 'SAVE_MAP_FILE_FAILURE';
export const SAVE_MAP_FILE_SUCCESS = 'SAVE_MAP_FILE_SUCCESS';

export const EDIT_MAP_FILE = 'EDIT_MAP_FILE';
export const EDIT_MAP_FILE_FAILURE = 'EDIT_MAP_FILE_FAILURE';
export const EDIT_MAP_FILE_SUCCESS = 'EDIT_MAP_FILE_SUCCESS';

export const DELETE_SECTION_FROM_FLOOR = 'DELETE_SECTION_FROM_FLOOR';
export const DELETE_SECTION_FROM_FLOOR_FAILURE = 'DELETE_SECTION_FROM_FLOOR_FAILURE';
export const DELETE_SECTION_FROM_FLOOR_SUCCESS = 'DELETE_SECTION_FROM_FLOOR_SUCCESS';

export const DELETE_MEETING_FROM_FLOOR = 'DELETE_MEETING_FROM_FLOOR';
export const DELETE_MEETING_FROM_FLOOR_FAILURE = 'DELETE_MEETING_FROM_FLOOR_FAILURE';
export const DELETE_MEETING_FROM_FLOOR_SUCCESS = 'DELETE_MEETING_FROM_FLOOR_SUCCESS';

export const DELETE_DESK_FROM_FLOOR = 'DELETE_DESK_FROM_FLOOR';
export const DELETE_DESK_FROM_FLOOR_FAILURE = 'DELETE_DESK_FROM_FLOOR_FAILURE';
export const DELETE_DESK_FROM_FLOOR_SUCCESS = 'DELETE_DESK_FROM_FLOOR_SUCCESS';

export const CHECK_DESK_RESERVATIONS = 'CHECK_DESK_RESERVATIONS';
export const CHECK_DESK_RESERVATIONS_FAILURE = 'CHECK_DESK_RESERVATIONS_FAILURE';
export const CHECK_DESK_RESERVATIONS_SUCCESS = 'CHECK_DESK_RESERVATIONS_SUCCESS';

export const CANCEL_RESERVATIONS_BY_DESK = 'CANCEL_RESERVATIONS_BY_DESK';
export const CANCEL_RESERVATIONS_BY_DESK_FAILURE = 'CANCEL_RESERVATIONS_BY_DESK_FAILURE';
export const CANCEL_RESERVATIONS_BY_DESK_SUCCESS = 'CANCEL_RESERVATIONS_BY_DESK_SUCCESS';

const SET_FLOOR_MAP_API_REQUESTS_DATA = 'SET_FLOOR_MAP_API_REQUESTS_DATA';

export interface GetFloorSchema {
  type: typeof GET_FLOOR_SCHEMA;
  payload: ActionPayload<GetFloorSchemaRequest>;
}
interface GetFloorSchemaFailure {
  type: typeof GET_FLOOR_SCHEMA_FAILURE;
  payload: BaseErrorResponse;
}
export interface GetFloorSchemaSuccess {
  type: typeof GET_FLOOR_SCHEMA_SUCCESS;
  payload: BaseResponse<GetFloorSchemaResponse>;
}

export interface SaveMapFile {
  type: typeof SAVE_MAP_FILE;
  payload: ActionPayload<FormData>;
}
interface SaveMapFileFail {
  type: typeof SAVE_MAP_FILE_FAILURE;
  payload: BaseErrorResponse;
}
export interface SaveMapFileSuccess {
  type: typeof SAVE_MAP_FILE_SUCCESS;
  payload: BaseResponse<SaveMapFileResponse>;
}

export interface EditMapFile {
  type: typeof EDIT_MAP_FILE;
  payload: ActionPayload<FormData>;
}
interface EditMapFileFail {
  type: typeof EDIT_MAP_FILE_FAILURE;
  payload: BaseErrorResponse;
}
export interface EditMapFileSuccess {
  type: typeof EDIT_MAP_FILE_SUCCESS;
  payload: BaseResponse<EditMapFileResponse>;
}

export interface SaveMapDrawings {
  type: typeof SAVE_MAP_DRAWINGS;
  payload: ActionPayload<SaveMapDrawingsRequest>;
}
export interface SaveMapDrawingsFailure {
  type: typeof SAVE_MAP_DRAWINGS_FAILURE;
  payload: BaseErrorResponse;
}
export interface SaveMapDrawingsSuccess {
  type: typeof SAVE_MAP_DRAWINGS_SUCCESS;
  payload: BaseResponse<SaveMapDrawingsResponse>;
}

export interface DeleteSectionFromFloor {
  type: typeof DELETE_SECTION_FROM_FLOOR;
  payload: ActionPayload<DeleteSectionFromFloorRequest>;
}
export interface DeleteSectionFromFloorFailure {
  type: typeof DELETE_SECTION_FROM_FLOOR_FAILURE;
  payload: BaseErrorResponse;
}
export interface DeleteSectionFromFloorSuccess {
  type: typeof DELETE_SECTION_FROM_FLOOR_SUCCESS;
  payload: BaseResponse<DeleteSectionFromFloorResponse>;
  sectionId: number;
}

export interface DeleteMeetingFromFloor {
  type: typeof DELETE_MEETING_FROM_FLOOR;
  payload: ActionPayload<DeleteMeetingFromFloorRequest>;
}
export interface DeleteMeetingFromFloorFailure {
  type: typeof DELETE_MEETING_FROM_FLOOR_FAILURE;
  payload: BaseErrorResponse;
}
export interface DeleteMeetingFromFloorSuccess {
  type: typeof DELETE_MEETING_FROM_FLOOR_SUCCESS;
  payload: BaseResponse<DeleteMeetingFromFloorResponse>;
  meetingId: number;
}

export interface DeleteDeskFromFloor {
  type: typeof DELETE_DESK_FROM_FLOOR;
  payload: ActionPayload<DeleteDeskFromFloorRequest>;
}
interface DeleteDeskFromFloorFailure {
  type: typeof DELETE_DESK_FROM_FLOOR_FAILURE;
  payload: BaseErrorResponse;
}
export interface DeleteDeskFromFloorSuccess {
  type: typeof DELETE_DESK_FROM_FLOOR_SUCCESS;
  payload: BaseResponse<DeleteDeskFromFloorResponse>;
  deskId: string;
}

export interface CheckDeskReservation {
  type: typeof CHECK_DESK_RESERVATIONS;
  payload: ActionPayload<CheckDeskReservationRequest>;
}
export interface CheckDeskReservationFailure {
  type: typeof CHECK_DESK_RESERVATIONS_FAILURE;
  payload: BaseErrorResponse;
}
export interface CheckDeskReservationSuccess {
  type: typeof CHECK_DESK_RESERVATIONS_SUCCESS;
  payload: BaseResponse<CheckDeskReservationResponse>;
}

export interface CancelReservationsByDesk {
  type: typeof CANCEL_RESERVATIONS_BY_DESK;
  payload: ActionPayload<CancelReservationsByDeskRequest>;
}
interface CancelReservationsByDeskFailure {
  type: typeof CANCEL_RESERVATIONS_BY_DESK_FAILURE;
  payload: BaseErrorResponse;
}
interface CancelReservationsByDeskSuccess {
  type: typeof CANCEL_RESERVATIONS_BY_DESK_SUCCESS;
  payload: BaseResponse<CancelReservationsByDeskResponse>;
}

interface SetFloorMapApiRequestsData {
  type: typeof SET_FLOOR_MAP_API_REQUESTS_DATA;
  payload: SetFloorMapApiRequestsDataRequest;
}

type Actions =
  | GetFloorSchema
  | GetFloorSchemaFailure
  | GetFloorSchemaSuccess
  | SaveMapFile
  | SaveMapFileFail
  | SaveMapFileSuccess
  | EditMapFile
  | EditMapFileFail
  | EditMapFileSuccess
  | SaveMapDrawings
  | SaveMapDrawingsFailure
  | SaveMapDrawingsSuccess
  | DeleteSectionFromFloor
  | DeleteSectionFromFloorFailure
  | DeleteSectionFromFloorSuccess
  | DeleteMeetingFromFloor
  | DeleteMeetingFromFloorFailure
  | DeleteMeetingFromFloorSuccess
  | DeleteDeskFromFloor
  | DeleteDeskFromFloorFailure
  | DeleteDeskFromFloorSuccess
  | CheckDeskReservation
  | CheckDeskReservationFailure
  | CheckDeskReservationSuccess
  | CancelReservationsByDesk
  | CancelReservationsByDeskFailure
  | CancelReservationsByDeskSuccess
  | SetFloorMapApiRequestsData;

export interface State {
  error: string;
  loading: boolean;
  mapFileUploaded: boolean;
  mapFileUpdated: boolean;
  mapSectionSaved: boolean;
}

const initialState: State = {
  error: '',
  loading: false,
  mapFileUploaded: false,
  mapFileUpdated: false,
  mapSectionSaved: false,
};

export default function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case GET_FLOOR_SCHEMA: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }
    case GET_FLOOR_SCHEMA_FAILURE: {
      return {
        ...state,
        error: t`Get floor schema failure`,
        loading: false,
      };
    }
    case GET_FLOOR_SCHEMA_SUCCESS: {
      return {
        ...state,
        error: '',
        loading: false,
      };
    }

    case SAVE_MAP_FILE: {
      return {
        ...state,
        error: '',
        loading: true,
        mapFileUploaded: false,
      };
    }
    case SAVE_MAP_FILE_FAILURE: {
      return {
        ...state,
        error: getErrorMessageFromPayload({ payload: action.payload, fallbackMessage: t`Map file upload failure` }),
        loading: false,
        mapFileUploaded: false,
      };
    }
    case SAVE_MAP_FILE_SUCCESS: {
      return {
        ...state,
        error: '',
        loading: false,
        mapFileUploaded: true,
      };
    }

    case EDIT_MAP_FILE: {
      return {
        ...state,
        error: '',
        loading: true,
        mapFileUploaded: false,
        mapFileUpdated: false,
      };
    }
    case EDIT_MAP_FILE_FAILURE: {
      return {
        ...state,
        error: getErrorMessageFromPayload({ payload: action.payload, fallbackMessage: t`Map file edited failure` }),
        loading: false,
        mapFileUploaded: false,
        mapFileUpdated: false,
      };
    }
    case EDIT_MAP_FILE_SUCCESS: {
      return {
        ...state,
        error: '',
        loading: false,
        mapFileUploaded: true,
        mapFileUpdated: true,
      };
    }

    case SAVE_MAP_DRAWINGS: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }
    case SAVE_MAP_DRAWINGS_FAILURE: {
      return {
        ...state,
        error: getErrorMessageFromPayload({ payload: action.payload, fallbackMessage: t`Save map sections and desks failure` }),
        loading: false,
        mapSectionSaved: true,
      };
    }
    case SAVE_MAP_DRAWINGS_SUCCESS: {
      return {
        ...state,
        error: '',
        loading: false,
        mapSectionSaved: true,
      };
    }

    case DELETE_SECTION_FROM_FLOOR_FAILURE: {
      return {
        ...state,
        error: getErrorMessageFromPayload({ payload: action.payload, fallbackMessage: 'Removing section failure' }),
      };
    }

    case DELETE_MEETING_FROM_FLOOR_FAILURE: {
      return {
        ...state,
        error: getErrorMessageFromPayload({ payload: action.payload, fallbackMessage: 'Removing meeting room failure' }),
      };
    }

    case DELETE_DESK_FROM_FLOOR_FAILURE: {
      return {
        ...state,
        error: getErrorMessageFromPayload({ payload: action.payload, fallbackMessage: 'Removing desk failure' }),
      };
    }

    case CHECK_DESK_RESERVATIONS_FAILURE: {
      return {
        ...state,
        error: getErrorMessageFromPayload({ payload: action.payload, fallbackMessage: 'Check desk reservations failure' }),
      };
    }

    case CANCEL_RESERVATIONS_BY_DESK_FAILURE: {
      return {
        ...state,
        error: getErrorMessageFromPayload({ payload: action.payload, fallbackMessage: 'Cancel desk reservations failure' }),
      };
    }

    case SET_FLOOR_MAP_API_REQUESTS_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
}

// Actions
export function getFloorSchema(data: GetFloorSchemaRequest): GetFloorSchema {
  const { floorId, showOwners, includeRooms, showDeleted } = data;
  const url = `/api/locations/${floorId}/floor-schema/`;
  const searchParams = new URLSearchParams();

  if (showOwners) {
    searchParams.append("showOwners", "true");
  }

  if (includeRooms) {
    searchParams.append("includeRooms", "true");
  }

  if (showDeleted) {
    searchParams.append("showDeleted", "true");
  }

  return {
    type: GET_FLOOR_SCHEMA,
    payload: {
      request: {
        method: 'GET',
        url: `${url}?${searchParams.toString()}`,
      },
    },
  };
}

export function deleteSectionFromFloor({ sectionId, floorId}: DeleteSectionFromFloorRequest): DeleteSectionFromFloor {
  return {
    type: DELETE_SECTION_FROM_FLOOR,
    payload: {
      request: {
        method: 'DELETE',
        url: `/api/locations/${floorId}/section/${sectionId}`,
      },
    },
  };
}

export function deleteMeetingFromFloor({ meetingId, floorId}: DeleteMeetingFromFloorRequest): DeleteMeetingFromFloor {
  return {
    type: DELETE_MEETING_FROM_FLOOR,
    payload: {
      request: {
        method: 'DELETE',
        url: `/api/locations/${floorId}/room/${meetingId}`,
      },
    },
  };
}

export function deleteDeskFromFloor({ deskId, floorId, saveBookings}: DeleteDeskFromFloorRequest): DeleteDeskFromFloor {
  let url = `/api/desks/${deskId}/floor/${floorId}`;

  if (saveBookings) {
    url = `${url}?saveBookings=${saveBookings}`;
  }

  return {
    type: DELETE_DESK_FROM_FLOOR,
    payload: {
      request: {
        method: 'DELETE',
        url,
      },
    },
  };
}

export function checkDeskReservation({ desk, date}: CheckDeskReservationModel): CheckDeskReservation {
  return {
    type: CHECK_DESK_RESERVATIONS,
    payload: {
      request: {
        method: 'GET',
        url: `/api/bookings/${desk.id}/date/${date}/reservationsByDesk`,
        data: { desk },
      },
    },
  };
}

export function cancelReservationsByDesk({ deskId, date}: CancelReservationsByDeskRequest): CancelReservationsByDesk {
  return {
    type: CANCEL_RESERVATIONS_BY_DESK,
    payload: {
      request: {
        method: 'DELETE',
        url: `/api/bookings/reservationsByDesk`,
        data: {
          deskId,
          date,
        },
      },
    },
  };
}

export function saveMapFile({
  data,
  locationId,
}: {
  data: SaveMapFileRequest,
  locationId: string,
}): SaveMapFile {
  const formData = new FormData();

  formData.append('floorName', data.floorName);
  formData.append('map', data.map);
  formData.append('mapSize[height]', String(data.mapSize.height));
  formData.append('mapSize[width]', String(data.mapSize.width));
  formData.append('mapImageBounds', JSON.stringify(data.mapImageBounds));

  return {
    type: SAVE_MAP_FILE,
    payload: {
      request: {
        method: 'POST',
        url: `/api/locations/${locationId}/floor`,
        data: formData,
      },
    },
  };
}

export function editMapFile({
  data,
  floorId,
}: {
  data: EditMapFileRequest,
  floorId: string,
}): EditMapFile {
  const formData = new FormData();

  formData.append('map', data.map);
  formData.append('mapSize[height]', String(data.mapSize.height));
  formData.append('mapSize[width]', String(data.mapSize.width));
  formData.append('mapImageBounds', JSON.stringify(data.mapImageBounds));

  return {
    type: EDIT_MAP_FILE,
    payload: {
      request: {
        method: 'PUT',
        url: `/api/locations/${floorId}/floor-map`,
        data: formData,
      },
    },
  };
}

export function saveMapSectionsAndDesks({
  data,
  floorId,
}: {
  data: SaveMapDrawingsRequest,
  floorId: string,
}): SaveMapDrawings {
  return {
    type: SAVE_MAP_DRAWINGS,
    payload: {
      request: {
        method: 'PUT',
        url: `/api/locations/${floorId}/floor-schema`,
        data,
      },
      additionalData: { floorId },
    },
  };
}

export function setFloorMapApiRequestsData(data: SetFloorMapApiRequestsDataRequest): SetFloorMapApiRequestsData {
  return {
    type: SET_FLOOR_MAP_API_REQUESTS_DATA,
    payload: data,
  };
}