
import styles from './styles.module.scss';
import { t } from '@lingui/macro';
import { TextField } from '@material-ui/core';
import _ from 'underscore';
import Search from '../../Icons/Search';

interface SearchLocationProps {
  disabled: boolean;
  setSearch: (search: string) => void;
}

export default function SearchLocation(props: SearchLocationProps) {  
  const { disabled, setSearch } = props;

  const onSearchChange = _.debounce((event: any) => {
    setSearch(event.target.value as string);    
  }, 600);

  return (    
    <TextField
      InputProps={{
        startAdornment: (<Search size={24} /> ),
      }}
      className={`input input--default input--search ${styles.searchInput}`}                
      defaultValue={''}
      disabled={disabled}
      fullWidth
      onChange={onSearchChange}                
      placeholder={t`Search by location, address`}
      variant="outlined"
    />         
  );
}