import { Editor, Element, Range, Transforms } from "slate";
import { isEditorElementActive } from "./is-editor-element-active";
import { EditorElementType } from "../enums";
import { unwrapEditorLink } from "./unwrap-editor-link";

export const wrapEditorLink = (editor: Editor, href: string) => {
  if (isEditorElementActive(editor, { type: EditorElementType.ANCHOR })) {
    unwrapEditorLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const anchor: Element = {
    type: EditorElementType.ANCHOR,
    href,
    children: isCollapsed ? [{ text: href }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, anchor);
  } else {
    Transforms.wrapNodes(editor, anchor, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};
