import { ActionPayload, BaseErrorResponse, BaseResponse } from 'store/_legacy/Models/ReduxModels';
import { Location } from 'App/Store/Locations/locationsDuck/models';
import { State as LocationsState } from 'App/Store/Locations/locationsDuck';
import { t } from '@lingui/macro';
import {
  AddUserDeviceTokenRequest,
  AddUserDeviceTokenResponse,
  GetActualDocumentRequest,
  GetActualDocumentResponse,
  GetProfileData,
  GetProfileResponse,
  RolesAccessType,
  SetProfileDataRequest,
} from './models';
import {
  UserDocument,
  UserRoleSelectModel,
} from '../../../../Admin/Store/userManagement/models';
import {getDomainMatch} from "store/_legacy/Redux/Ducks/configDuck";

const SET_PROFILE_DATA = 'SET_PROFILE_DATA';

export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILE_FAIL = 'GET_PROFILE_FAIL';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';

export const ADD_USER_DEVICE_TOKEN = 'ADD_USER_DEVICE_TOKEN';
export const ADD_USER_DEVICE_TOKEN_FAIL = 'ADD_USER_DEVICE_TOKEN_FAIL';
export const ADD_USER_DEVICE_TOKEN_SUCCESS = 'ADD_USER_DEVICE_TOKEN_SUCCESS';

export const GET_USER_ACTUAL_DOCUMENT = 'GET_USER_ACTUAL_DOCUMENT';
export const GET_USER_ACTUAL_DOCUMENT_FAIL = 'GET_USER_ACTUAL_DOCUMENT_FAIL';
export const GET_USER_ACTUAL_DOCUMENT_SUCCESS = 'GET_USER_ACTUAL_DOCUMENT_SUCCESS';

interface SetProfileData {
  type: typeof SET_PROFILE_DATA;
  payload: SetProfileDataRequest;
}

export interface GetProfile {
  type: typeof GET_PROFILE;
  payload: ActionPayload<null>;
}
interface GetProfileFail {
  type: typeof GET_PROFILE_FAIL;
  payload: BaseErrorResponse;
}
interface GetProfileSuccess {
  type: typeof GET_PROFILE_SUCCESS;
  payload: BaseResponse<GetProfileResponse>;
}

export interface AddUserDeviceToken {
  type: typeof ADD_USER_DEVICE_TOKEN;
  payload: ActionPayload<AddUserDeviceTokenRequest>;
}
interface AddUserDeviceTokenFail {
  type: typeof ADD_USER_DEVICE_TOKEN_FAIL;
  payload: BaseErrorResponse;
}
interface AddUserDeviceTokenSuccess {
  type: typeof ADD_USER_DEVICE_TOKEN_SUCCESS;
  payload: BaseResponse<AddUserDeviceTokenResponse>;
}

export interface GetActualDocument {
  type: typeof GET_USER_ACTUAL_DOCUMENT;
  payload: ActionPayload<GetActualDocumentRequest>;
}
interface GetActualDocumentFail {
  type: typeof GET_USER_ACTUAL_DOCUMENT_FAIL;
  payload: BaseErrorResponse;
}
interface GetActualDocumentSuccess {
  type: typeof GET_USER_ACTUAL_DOCUMENT_SUCCESS;
  payload: BaseResponse<GetActualDocumentResponse>;
}

type Actions = SetProfileData
  | GetProfile
  | GetProfileFail
  | GetProfileSuccess
  | AddUserDeviceToken
  | AddUserDeviceTokenFail
  | AddUserDeviceTokenSuccess
  | GetActualDocument
  | GetActualDocumentFail
  | GetActualDocumentSuccess;

export interface State extends GetProfileData {
  error: string;
  loading: boolean;
  actualCovidDocumentLoaded: boolean;
  roleAccess: RolesAccessType,
  actualCovidDocument: UserDocument | null,
}

const initialState: State = {
  error: '',
  loading: false,
  actualCovidDocumentLoaded: false,
  id: '',
  name: '',
  email: '',
  mobilePhone: '',
  location: '',
  isAdmin: false,
  approvedAccommodation: false,
  locationIds: [],
  groups: [],
  role: {
    id: '',
    name: 'User',
  },
  roleAccess: {
    superAdmin: false,
    localAdmin: false,
    hr: false,
    user: true,
    reservationManager: false,
    delegate: false,
    executiveAssistant: false,
    cateringAdmin: false,
    campaignManager: false,
  },
  actualCovidDocument: null,
};

export default function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case GET_PROFILE:
      return {
        ...state,
        error: '',
        loading: true,
      };
    case GET_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload.error?.message ?? t`There was an error. Please try again.`,
        loading: false,
      };
    case GET_PROFILE_SUCCESS: {
      const { data } = action.payload.data.result;

      const roleAccess: RolesAccessType = {
        superAdmin: data.role.name === 'Super Admin',
        localAdmin: data.role.name === 'Local Admin',
        hr: data.role.name === 'HR',
        user: data.role.name === 'User',
        reservationManager: data.role.name === 'Reservation Manager',
        delegate: data.role.name === 'Delegate',
        executiveAssistant: data.role.name === 'Executive Assistant',
        cateringAdmin: data.role.name === 'Catering Admin',
        campaignManager: data.role.name === 'Campaign Manager',
      };

      return {
        ...state,
        error: '',
        loading: false,
        roleAccess,
        ...data,
      };
    }

    case ADD_USER_DEVICE_TOKEN:
      return {
        ...state,
      };
    case ADD_USER_DEVICE_TOKEN_FAIL:
      return {
        ...state,
        error: t`There was an error adding user device token. Please try again.`,
      };
    case ADD_USER_DEVICE_TOKEN_SUCCESS: {
      return {
        ...state,
      };
    }

    case GET_USER_ACTUAL_DOCUMENT:
      return {
        ...state,
        actualCovidDocumentLoaded: false,
      };
    case GET_USER_ACTUAL_DOCUMENT_FAIL:
      return {
        ...state,
        actualCovidDocumentLoaded: false,
        error: t`There was an error adding user actual document. Please try again.`,
      };
    case GET_USER_ACTUAL_DOCUMENT_SUCCESS: {
      return {
        ...state,
        actualCovidDocumentLoaded: true,
        actualCovidDocument: action.payload.data.result.data,
      };
    }

    case SET_PROFILE_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
}

// Actions
export function setProfileData(data: SetProfileDataRequest): SetProfileData {
  return {
    type: SET_PROFILE_DATA,
    payload: data,
  };
}

export function getProfile(): GetProfile {
  return {
    type: GET_PROFILE,
    payload: {
      request: {
        method: 'GET',
        url: '/api/users/profile',
      },
    },
  };
}

export function addUserDeviceToken(data: AddUserDeviceTokenRequest): AddUserDeviceToken {
  return {
    type: ADD_USER_DEVICE_TOKEN,
    payload: {
      request: {
        method: 'POST',
        url: `/api/users`,
        data,
      },
    },
  };
}

export function getActualDocument(data: GetActualDocumentRequest): GetActualDocument {
  return {
    type: GET_USER_ACTUAL_DOCUMENT,
    payload: {
      request: {
        method: 'GET',
        url: `/api/users/${data.userId}/actualDocument`,
      },
    },
  };
}

// Selectors
export function isRestrictedLocationsByRole(userRole: UserRoleSelectModel | null) {
  if (userRole) {
    return userRole.label === 'Local Admin' || userRole.label === 'HR'  || userRole.label === 'Reservation Manager';
  }

  return false;
}

export function selectUserAllowedLocations(state: State, locationsState: LocationsState): Location[] {
  if (state.roleAccess.localAdmin || state.roleAccess.hr) {
    return locationsState.locations.filter(l => state.locationIds.includes(l.id));
  } else {
    return locationsState.locations ?? [];
  }
}

export function isSupportLinkShow(email: string) {
  const domainForSupportLinks = ['mbww.com', 'mediaexperts.com', 'mediaexperts.com.duplicated', 'mediabrands.com', 'mediabrands.fr', 'kinesso.com'];
  return getDomainMatch(domainForSupportLinks, email);
}
