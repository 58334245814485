import Api from 'store/_legacy/Services/Api';
import { call, put, takeLatest } from 'redux-saga/effects';
import { DeleteFloorFakeResponse } from './models';
import {
  CREATE_LOCATION,
  CREATE_LOCATION_FAIL,
  CREATE_LOCATION_SUCCESS,
  CreateLocation,
  DELETE_FLOOR,
  DELETE_FLOOR_FAIL,
  DELETE_FLOOR_SUCCESS,
  DELETE_LOCATION,
  DELETE_LOCATION_FAIL,
  DELETE_LOCATION_SUCCESS,
  DeleteFloor,
  DeleteLocation,
  EDIT_FLOOR,
  EDIT_FLOOR_FAIL,
  EDIT_FLOOR_SUCCESS,
  EDIT_LOCATION,
  EDIT_LOCATION_FAIL,
  EDIT_LOCATION_SUCCESS,
  EditFloor,
  EditLocation,
  GET_ADMIN_LOCATIONS,
  GET_ADMIN_LOCATIONS_FAIL,
  GET_ADMIN_LOCATIONS_SUCCESS,
  GET_FLOORS,
  GET_FLOORS_ADMIN,
  GET_FLOORS_ADMIN_FAIL,
  GET_FLOORS_ADMIN_SUCCESS,
  GET_FLOORS_FAIL,
  GET_FLOORS_SUCCESS,
  GET_FLOORS_V2,
  GET_FLOORS_V2_FAIL,
  GET_FLOORS_V2_SUCCESS,
  GET_LOCATIONS,
  GET_LOCATIONS_FAIL,
  GET_LOCATIONS_SUCCESS,
  GET_LOCATION_DISABLED_DATES,
  GET_LOCATION_DISABLED_DATES_FAIL,
  GET_LOCATION_DISABLED_DATES_SUCCESS,
  GetAdminLocations,
  GetFloors,
  GetFloorsAdmin,
  GetFloorsAdminSuccess,
  GetFloorsV2,
  GetLocationDisabledDates,
  GetLocations,
} from '.';

function* getFloorsSaga(action: GetFloors): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: GET_FLOORS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_FLOORS_FAIL, payload: e });
  }
}

function* getFloorsV2Saga(action: GetFloorsV2): any {
  try {
    const payload = yield call(Api, action, false, true);

    yield put({ type: GET_FLOORS_V2_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_FLOORS_V2_FAIL, payload: e });
  }
}

function* getFloorsAdminSaga(action: GetFloorsAdmin): any {
  try {
    const payload = yield call(Api, action);

    const success: GetFloorsAdminSuccess = {
      type: GET_FLOORS_ADMIN_SUCCESS,
      payload,
    };

    yield put(success);
  } catch (e) {
    yield put({ type: GET_FLOORS_ADMIN_FAIL, payload: e });
  }
}

function* getLocationsSaga(action: GetLocations): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: GET_LOCATIONS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_LOCATIONS_FAIL, payload: e });
  }
}

function* getAdminLocationsSaga(action: GetAdminLocations): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: GET_ADMIN_LOCATIONS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_ADMIN_LOCATIONS_FAIL, payload: e });
  }
}

function* getLocationDisabledDatesSaga(action: GetLocationDisabledDates): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: GET_LOCATION_DISABLED_DATES_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_LOCATION_DISABLED_DATES_FAIL, payload: e });
  }
}

function* createLocationSaga(action: CreateLocation): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: CREATE_LOCATION_SUCCESS, payload });
  } catch (e) {
    yield put({ type: CREATE_LOCATION_FAIL, payload: e });
  }
}

function* editFloorSaga(action: EditFloor): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: EDIT_FLOOR_SUCCESS, payload });
  } catch (e) {
    yield put({ type: EDIT_FLOOR_FAIL, payload: e });
  }
}

function* editLocationSaga(action: EditLocation): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: EDIT_LOCATION_SUCCESS, payload });
  } catch (e) {
    yield put({ type: EDIT_LOCATION_FAIL, payload: e });
  }
}

function* deleteFloorSaga(action: DeleteFloor): any {
  try {
    yield call(Api, action);
    const payload: DeleteFloorFakeResponse = { floorId: action.payload.request.data?.floorId ?? '' };

    yield put({ type: DELETE_FLOOR_SUCCESS, payload });
  } catch (e) {
    yield put({ type: DELETE_FLOOR_FAIL, payload: e });
  }
}

function* deleteLocationSaga(action: DeleteLocation): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: DELETE_LOCATION_SUCCESS, payload });
  } catch (e) {
    yield put({ type: DELETE_LOCATION_FAIL, payload: e });
  }
}

export default [
  takeLatest(GET_FLOORS, getFloorsSaga),
  takeLatest(GET_FLOORS_V2, getFloorsV2Saga),
  takeLatest(GET_FLOORS_ADMIN, getFloorsAdminSaga),
  takeLatest(GET_LOCATIONS, getLocationsSaga),
  takeLatest(GET_ADMIN_LOCATIONS, getAdminLocationsSaga),
  takeLatest(GET_LOCATION_DISABLED_DATES, getLocationDisabledDatesSaga),
  takeLatest(CREATE_LOCATION, createLocationSaga),
  takeLatest(EDIT_FLOOR, editFloorSaga),
  takeLatest(EDIT_LOCATION, editLocationSaga),
  takeLatest(DELETE_FLOOR, deleteFloorSaga),
  takeLatest(DELETE_LOCATION, deleteLocationSaga),
];

