import { t } from "@lingui/macro";
import { Box, Button, ButtonProps, CircularProgress, Collapse, FilledInput, FormControlLabel, IconButton, List, ListItem, Radio, RadioGroup, Typography, useTheme } from "@mui/material";
import { CampaignWrapper, DefaultChip, FileUpload, FileUploadProps, GroupsSelect, ListHeader, ListHeaderLabel, MenuTab, MenuTabs, TogetherDelete, UsersSelect, useToast } from "components";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Group, PostRecipient, PostRecipientStatus, PostRecipientType, PostVerificationStatus, User, adminSlice, selectAdminCurrentCampaign, useImportUsersMutation, useVerifyPostMutation } from "store";
import { useRouteMatch } from "react-router-dom";
import { MarkRequired } from "ts-essentials";
import { orderBy } from "lodash";
import { CheckRounded, Search, WarningAmberRounded } from "@mui/icons-material";
import { useDebouncedCallback } from "hooks";
import { searchFullTextObject } from "utils";
import { Order } from "types";
import { amber } from "@mui/material/colors";

export const CampaignRecipients: React.FC = () => {
  const { palette, background } = useTheme();
  const toast = useToast();
  const match = useRouteMatch<{ recipientType: PostRecipientType }>({
    path: ["/admin/campaigns/new/:recipientType", "/admin/campaigns/:campaignId/edit/:recipientType"],
  });
  const dispatch = useDispatch();
  const currentCampaign = useSelector(selectAdminCurrentCampaign);
  const [inputTab, setInputTab] = useState(0);
  const [listTab, setListTab] = useState(0);
  const [selectedGroups, setSelectedGroups] = useState<Group[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [userOrdering, setUserOrdering] = useState<["name" | "email", Order]>();
  const [groupOrdering, setGroupOrdering] = useState<["name", Order]>();
  const [listSearch, setListSearch] = useState("");
  const [showVerificationDisclaimer, setShowVerificationDisclaimer] = useState(false);
  const debouncedSetListSearch = useDebouncedCallback(setListSearch, [setListSearch], 500);
  const [importUsers, { isLoading: isUploadingCSV }] = useImportUsersMutation();
  const [verifyPost, { isLoading: isVerifyingPost, data: verification }] = useVerifyPostMutation();
  const { recipientType = PostRecipientType.AUDIENCE } = match?.params || {};

  const [users, groups] = useMemo(() => {
    const users: MarkRequired<PostRecipient, "user">[] = [];
    const groups: MarkRequired<PostRecipient, "group">[] = [];

    for (const recipient of currentCampaign?.recipients || []) {
      const { type, status, user, group } = recipient;

      if (type !== recipientType) {
        continue;
      }

      if (group && (!listSearch || searchFullTextObject(group, ["id", "name"], listSearch))) {
        groups.push({ type, status, group });
      } else if (user && (!listSearch || searchFullTextObject(user, ["id", "name"], listSearch))) {
        users.push({ type, status, user });
      }
    }

    return [
      userOrdering ? orderBy(users, [({ user }) => user[userOrdering[0]].toLowerCase()], [userOrdering[1]]) : users,
      groupOrdering ? orderBy(groups, [({ group }) => group[groupOrdering[0]].toLowerCase()], [groupOrdering[1]]) : groups,
    ];
  }, [currentCampaign?.recipients, listSearch, userOrdering, groupOrdering]);

  useEffect(() => {
    if (recipientType !== PostRecipientType.AUDIENCE) {
      return;
    }

    const timeout = setTimeout(() => {
      if (currentCampaign?.author?.id || currentCampaign?.recipients?.length) {
        verifyPost({
          postId: currentCampaign?.id,
          authorId: currentCampaign?.author?.id,
          recipients: currentCampaign?.recipients?.map(({ type, user, group }) => ({ type, userId: user?.id, groupId: group?.id })),
        });
      }
    }, 300);

    return () => clearTimeout(timeout);
  }, [recipientType, currentCampaign?.id, currentCampaign?.recipients, currentCampaign?.author?.id, verifyPost]);

  useEffect(() => {
    if (recipientType !== PostRecipientType.AUDIENCE) {
      return;
    }

    if (!showVerificationDisclaimer && isVerifyingPost) {
      setShowVerificationDisclaimer(true);
    }
  }, [recipientType, isVerifyingPost, verification, showVerificationDisclaimer, setShowVerificationDisclaimer]);

  const handleAddUsersClick = () => {
    if (!selectedUsers?.length) {
      return;
    }

    const recipients = [...(currentCampaign?.recipients || [])];

    for (const user of selectedUsers) {
      if (!recipients.some((recipient) => recipient.type === recipientType && recipient.user?.id === user.id)) {
        recipients.push({ type: recipientType, user });
      }
    }

    dispatch(adminSlice.actions.setCurrentCampaign({ recipients }));
    setSelectedUsers([]);
  };

  const handleAddGroupsClick = () => {
    if (!selectedGroups?.length) {
      return;
    }

    const recipients = [...(currentCampaign?.recipients || [])];

    for (const group of selectedGroups) {
      if (!recipients.some((recipient) => recipient.type === recipientType && recipient.group?.id === group.id)) {
        recipients.push({ type: recipientType, group });
      }
    }

    dispatch(adminSlice.actions.setCurrentCampaign({ recipients }));
    setSelectedGroups([]);
  };

  const handleDownloadCSVTemplateClick: ButtonProps["onClick"] = (event) => {
    const file = new Blob(["email\njohn.doe@example.org\njane.doe@example.org"], { type: "text/csv" });
    const url = URL.createObjectURL(file);
    const link = document.createElement("a");

    link.setAttribute("style", "display:none");
    link.setAttribute("href", url);
    link.setAttribute("download", "example.csv");
    event.currentTarget.parentElement?.append(link);
    link.click();
  };

  const handleCSVUploadChange: FileUploadProps["onChange"] = ([file]) => {
    if (isUploadingCSV) {
      return;
    }

    void (async () => {
      const response = await importUsers(file);

      if ("error" in response) {
        toast.showToast({ severity: "error", message: t`Failed to upload file, please try again.` });
      } else {
        const { data: { items: users } } = response;
        const recipients = [...(currentCampaign?.recipients || [])];

        for (const user of users) {
          if (!recipients.some((recipient) => recipient.type === recipientType && recipient.user?.id === user.id)) {
            recipients.push({ type: recipientType, user });
          }
        }

        dispatch(adminSlice.actions.setCurrentCampaign({ recipients }));
        toast.showToast({ severity: "success", message: t`Recipients added.` });
      }
    })();
  };

  const removeRecipient = (recipient: PostRecipient) => {
    dispatch(adminSlice.actions.setCurrentCampaign({
      recipients: currentCampaign?.recipients?.filter(({ type, user, group }) => {
        return recipientType !== type || ((!group || group.id !== recipient.group?.id) && (!user || user.id !== recipient.user?.id));
      }),
    }));
  };

  const needsApproval = verification?.author === PostVerificationStatus.APPROVAL_REQUIRED || 
      verification?.recipients === PostVerificationStatus.APPROVAL_REQUIRED;
  let verificationBackground: string | undefined = palette.grey[100];
  let verificationIcon = <CircularProgress size={20} />;
  let verificationText = t`Verifying campaign settings.`;

  if (!isVerifyingPost && verification) {
    verificationBackground = needsApproval ? amber[100] : background.blue.light;
    verificationIcon = needsApproval ? <WarningAmberRounded fontSize="small" /> : <CheckRounded color="primary" fontSize="small" />;
    verificationText = needsApproval ? t`Campaign needs approval.` : t`Campaigns settings verified.`;
  }

  return (
    <CampaignWrapper>
      <Collapse in={showVerificationDisclaimer}>
        <Box
          alignItems="center"
          bgcolor={verificationBackground}
          borderRadius={2}
          display="flex"
          mb={1}
          onClick={needsApproval && !isVerifyingPost ? undefined : () => setShowVerificationDisclaimer(false)}
          px={2}
          py={1.5}
          sx={{ cursor: needsApproval && !isVerifyingPost ? undefined : "pointer" }}
          width="100%"
        >
          {verificationIcon}
          <Typography fontSize={14} lineHeight={1} ml={1}>{verificationText}</Typography>
        </Box>
      </Collapse>
      <RadioGroup defaultValue={0} onChange={(_, inputTab) => setInputTab(Number.parseInt(inputTab))} row sx={{ mb: 1 }} value={inputTab}>
        <FormControlLabel control={<Radio size="small" />} label={t`Select users`} slotProps={{ typography: { fontSize: 14 } }} value={0} />
        <FormControlLabel control={<Radio size="small" />} label={t`Select groups`} slotProps={{ typography: { fontSize: 14 } }} value={1} />
        <FormControlLabel control={<Radio size="small" />} label={t`From CSV file`} slotProps={{ typography: { fontSize: 14 } }} value={2} />
      </RadioGroup>
      {inputTab === 0 ? (
        <Box alignItems="flex-end" display="flex" gap={2} mb={2}>
          <Box flex={1}>
            <UsersSelect
              disabledIds={users.map(({ user }) => user?.id)}
              onChange={setSelectedUsers}
              placeholder={selectedUsers.length ? undefined : t`Search users`}
              value={selectedUsers}
            />
          </Box>
          <Button disabled={!selectedUsers.length} onClick={handleAddUsersClick} variant="contained">{t`Add`}</Button>
        </Box>
      ) : undefined}
      {inputTab === 1 ? (
        <Box alignItems="flex-end" display="flex" gap={2} mb={2}>
          <Box flex={1}>
            <GroupsSelect
              disabledIds={groups.map(({ group }) => group.id)}
              onChange={setSelectedGroups}
              placeholder={selectedGroups.length ? undefined : t`Search groups`}
              value={selectedGroups}
            />
          </Box>
          <Button disabled={!selectedGroups.length} onClick={handleAddGroupsClick} variant="contained">{t`Add`}</Button>
        </Box>
      ) : undefined}
      {inputTab === 2 ? (
        <Box mb={2}>
          <Box alignItems="baseline" display="flex" gap={2} mb={2}>
            <Typography fontSize={14}>{t`Upload CSV`}</Typography>
            <Button onClick={handleDownloadCSVTemplateClick} size="small">{t`Download CSV Template`}</Button>
          </Box>
          <FileUpload accept={["text/csv"]} isUploading={isUploadingCSV} onChange={handleCSVUploadChange} />
        </Box>
      ) : undefined}
      <Box display="flex" justifyContent="space-between" mb={2}>
        <MenuTabs onChange={(_, value) => setListTab(value)} value={listTab}>
          <MenuTab count={users.length} index={0} label={t`Users`} sx={{ fontSize: 14 }} />
          <MenuTab count={groups.length} index={1} label={t`Groups`} sx={{ fontSize: 14 }} />
        </MenuTabs>
        <FilledInput
          onChange={(event) => debouncedSetListSearch(event.currentTarget.value)}
          placeholder={t`Search`}
          size="small"
          startAdornment={<Search fontSize="small" sx={{ color: palette.grey[700] }} />}
        />
      </Box>
      {listTab === 0 ? (
        <>
          <ListHeader>
            <ListHeaderLabel
              onOrderChange={(order) => setUserOrdering(
                !order || (userOrdering?.[0] === "name" && userOrdering?.[1] === order) ? undefined : ["name", order],
              )}
              order={userOrdering?.[0] === "name" ? userOrdering[1] : undefined}
              orderable
              sx={{ flex: 1 }}
            >
              {t`Name`}
            </ListHeaderLabel>
            <ListHeaderLabel
              onOrderChange={(order) => setUserOrdering(
                !order || (userOrdering?.[0] === "email" && userOrdering?.[1] === order) ? undefined : ["email", order],
              )}
              order={userOrdering?.[0] === "email" ? userOrdering[1] : undefined}
              orderable
              sx={{ width: 360 }}
            >
              {t`Email`}
            </ListHeaderLabel>
            <Box component="span" width={32} />
          </ListHeader>
          {users.length ? (
            <List disablePadding>
              {users.map(({ type, status, user }) => (
                <ListItem disablePadding key={user.id} sx={{ p: 1, gap: 1, borderBottom: "1px solid rgba(0, 0, 0, 0.05)" }}>
                  <Typography alignItems="baseline" display="flex" flex={1} fontSize={14} gap={1} noWrap>
                    {user.name}
                    {status === PostRecipientStatus.PENDING ? <DefaultChip fontSize={12}>{t`New`}</DefaultChip> : undefined}
                  </Typography>
                  <Typography fontSize={14} noWrap width={360}>{user.email}</Typography>
                  <IconButton onClick={() => removeRecipient({ type, user })}>
                    <TogetherDelete sx={{ fill: palette.primary.main, width: 16, height: 16 }} />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography color={palette.grey[700]} fontSize={14} my={3} textAlign="center">{t`No user selected`}</Typography>
          )}
        </>
      ) : undefined}
      {listTab === 1 ? (
        <>
          <ListHeader>
            <ListHeaderLabel
              onOrderChange={(order) => setGroupOrdering(
                !order || (groupOrdering?.[0] === "name" && groupOrdering?.[1] === order) ? undefined : ["name", order],
              )}
              order={groupOrdering?.[0] === "name" ? groupOrdering[1] : undefined}
              orderable
              sx={{ flex: 1 }}
            >
              {t`Name`}
            </ListHeaderLabel>
            <Box component="span" width={32} />
          </ListHeader>
          {groups.length ? (
            <List disablePadding>
              {groups.map(({ type, status, group }) => (
                <ListItem disablePadding key={group.id} sx={{ p: 1, gap: 1, borderBottom: "1px solid rgba(0, 0, 0, 0.05)" }}>
                  <Typography alignItems="baseline" display="flex" flex={1} fontSize={14} gap={1} noWrap>
                    {group.name}
                    {status === PostRecipientStatus.PENDING ? <DefaultChip fontSize={12}>{t`New`}</DefaultChip> : undefined}
                  </Typography>
                  <IconButton onClick={() => removeRecipient({ type, group })}>
                    <TogetherDelete sx={{ fill: palette.primary.main, width: 16, height: 16 }} />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography color={palette.grey[700]} fontSize={14} my={3} textAlign="center">{t`No group selected`}</Typography>
          )}
        </>
      ) : undefined}
    </CampaignWrapper>
  );
};
