import React, { useEffect, useState } from "react";

import Box from "components/_legacy/Box";
import SearchLocation from "./SearchLocation";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "store/_legacy/Redux/store";
import { GetLocationsRequest } from "App/Store/Locations/locationsDuck/models";
import { getLocations } from "App/Store/Locations/locationsDuck";

interface Props {
  withRoomsOnly?: boolean;
}

export default function ReservationRegionSearch(props: Props) {
  const dispatch = useDispatch();
  const { withRoomsOnly = false } = props;
  const { locations: locationsDuck } = useTypedSelector(state => state);
  const { loading } = locationsDuck;

  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    const payload: GetLocationsRequest = { withRoomsOnly, search };

    dispatch(getLocations(payload));
  }, [search]);

  return (
    <Box marginBottom={16}>
      <SearchLocation disabled={loading} setSearch={setSearch} />
    </Box>
  );
}
