import { ApproveOrDenyUserDocumentResponse } from './models';

export const approveOrDenyUserDocumentSuccess: ApproveOrDenyUserDocumentResponse = {
  result: {
    statusCode: 200,
    data: {
      approver: "Scherbyna, Vlad (DET-MBW)",
      createdAt: "2022-01-11T14:00:27.923Z",
      documentUrl: "https://storetogetherstage.blob.core.windows.net/vaccination-certificate/1641909627852blob",
      id: "1eb3322b-74dc-4a10-b000-10c3f7eac783",
      status: "approved",
      type: "vaccineRecord",
      updatedAt: "2022-01-24T10:30:03.630Z",
      updatedBy: "f81a126d-d904-4a49-b703-216e7734b4b9",
      userId: "f81a126d-d904-4a49-b703-216e7734b4b9",
      vaccinationDate: "2022-01-10T00:00:00.000Z",
      vaccinationExpirationDate: "2022-01-29T00:00:00.000Z",
      verificationDate: "2022-01-24T10:30:03.588Z",
    },
  },
};
