import Box from 'components/_legacy/Box';
import clsx from 'clsx';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import styles from './styles.module.scss';
import CheckboxCustom from "../CheckBoxCustom";

interface Props {
  checked: boolean;
  classes?: {
    container?: string;
    checkbox?: string;
  };
  onChange: (value: boolean, name: string) => void;
  label: React.ReactNode;
  name: string;
}

type PropsA = Props;

export default function Checkbox({
  checked,
  classes,
  label,
  name,
  onChange,
}: React.PropsWithChildren<PropsA>) {
  const checkboxClasses = clsx(
    classes?.checkbox,
    styles.checkbox,
  );
  const containerClasses = clsx(
    classes?.container,
    styles.checkboxContainer,
  );
  const labelClasses = clsx(
    !label && styles.emptyLabel,
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked, name);
  };

  return (
    <Box className={containerClasses}>
      <FormControlLabel
        classes={{ label: labelClasses }}
        control={(
          <CheckboxCustom
            checked={checked}
            className={checkboxClasses}
            classes={{ checked: styles.checked }}
            name={name}
            onChange={handleChange}
          />
        )}
        label={label}
      />
    </Box>
  );
}
