import { t } from "@lingui/macro";
import { Box, Button, IconButton, Skeleton, Tooltip, Typography, useTheme } from "@mui/material";
import { Link, useHistory, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { ArrowBackRounded } from "@mui/icons-material";
import { APIError, CommPolicyRuleInput, CommPolicyRuleType, CommPolicyType, adminSlice, selectAdminCurrentCampaignPolicy, useCreateCommPolicyMutation, useGetCommPolicyByIdQuery, useGetMeQuery, useUpdateCommPolicyByIdMutation } from "store";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "components/toast-provider";
import { UserRole } from "enums";

export const CampaignPolicyWrapper: React.FC<React.PropsWithChildren<{ type: CommPolicyType; policyId?: string; parentId?: string }>> = (props) => {
  const { type, policyId, parentId, children } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname, search } = useLocation();
  const toast = useToast();
  const { palette } = useTheme();
  const currentPolicy = useSelector(selectAdminCurrentCampaignPolicy);
  const [submitTooltip, setSubmitTooltip] = useState("");
  const [createPolicy, { isLoading: isCreating }] = useCreateCommPolicyMutation();
  const [updatePolicyById, { isLoading: isUpdating }] = useUpdateCommPolicyByIdMutation();
  const { data: getMeResponse, isLoading: isLoadingMe } = useGetMeQuery();
  const isEdit = policyId || type === CommPolicyType.MASTER;
  const { data: getPolicyByIdResponse, isLoading: isLoadingPolicy } = useGetCommPolicyByIdQuery(
    { policyId: policyId || "master", include: ["domains", "user", "rules", "rules.user"] },
    { skip: !isEdit },
  );
  const policy = isEdit ? getPolicyByIdResponse : undefined;
  const { data: me } = getMeResponse?.result || {};

  useEffect(() => {
    if (!me) {
      return;
    }

    const skip = (type === CommPolicyType.MASTER && currentPolicy?.id === "master")
      || (!policyId && type !== CommPolicyType.MASTER)
      || (currentPolicy?.id && currentPolicy?.id === policyId);

    if (skip) {
      return;
    }

    dispatch(adminSlice.actions.resetCurrentCampaignPolicy({
      id: type === CommPolicyType.MASTER ? "master" : policyId,
      isEditable: me?.role === UserRole.SUPER_ADMIN,
    }));
  }, [policyId, me, currentPolicy, type, dispatch]);

  useEffect(() => {
    if (!search || !me) {
      return;
    }

    const params = new URLSearchParams(search || "");

    if (params.get("reset") === "true") {
      dispatch(adminSlice.actions.resetCurrentCampaignPolicy({
        type,
        id: policyId,
        parent: parentId ? { id: parentId } : undefined,
        isEditable: me?.role === UserRole.SUPER_ADMIN,
      }));
    }

    if (params.size) {
      params.delete("reset");
      history.push(params.size > 0 ? `${pathname}?${params.toString()}` : pathname);
    }
  }, [type, search, pathname, policyId, parentId, history, me, dispatch]);

  useEffect(() => {
    if (currentPolicy?.type === type || currentPolicy?.parent?.id === parentId || !me) {
      return;
    }

    dispatch(adminSlice.actions.resetCurrentCampaignPolicy({
      type,
      id: policyId,
      parent: parentId ? { id: parentId } : undefined,
      isEditable: me?.role === UserRole.SUPER_ADMIN,
    }));
  }, [type, currentPolicy, pathname, policyId, parentId, history, me, dispatch]);

  useEffect(() => {
    if (!policy || !isEdit || !me) {
      return;
    }

    const isEditable = 
      me?.role === UserRole.SUPER_ADMIN ||
      (me?.role === UserRole.COMMUNICATION_MANAGER &&
        policy?.rules?.some(({ type, user }) => type === CommPolicyRuleType.POLICY_MANAGER && user?.id === me?.id));

    dispatch(adminSlice.actions.resetCurrentCampaignPolicy({ ...policy, isEditable, id: type === CommPolicyType.MASTER ? "master" : policy.id }));
  }, [type, isEdit, policy, me, dispatch]);

  useEffect(() => {
    const { type, name, domains, user } = currentPolicy || {};

    if (type === CommPolicyType.BRAND) {
      if (!name) {
        setSubmitTooltip(t`Policy needs a name.`);
      } else if (!domains?.length) {
        setSubmitTooltip(t`Policy needs at least one domain.`);
      } else {
        setSubmitTooltip("");  
      }
    } else if (type === CommPolicyType.USER && !user) {
      setSubmitTooltip(t`Policy needs an user.`);
    } else {
      setSubmitTooltip("");
    }
  }, [currentPolicy]);

  const handleSaveClick = () => {
    const { id, type, name, parent, user, domains, rules: currentRules } = currentPolicy || {};

    if (!type || submitTooltip) {
      return;
    }
    
    const rules = currentRules
      ?.filter((rule) => me?.role === UserRole.SUPER_ADMIN || rule?.type !== CommPolicyRuleType.POLICY_MANAGER)
      ?.map<CommPolicyRuleInput>(({ type, user, value }) => ({ type, userId: user?.id, value }));

    void (async () => {
      const response = id 
        ? await updatePolicyById({ rules, name, policyId: id, parentId: parent?.id, userId: user?.id, domains: domains?.map(({ name }) => name) })
        : await createPolicy({ rules, type, name, parentId: parent?.id, userId: user?.id, domains: domains?.map(({ name }) => name) });

      if ("error" in response) {
        const { data: error } = response.error as { data: APIError };

        if (error.code === 1012009) {
          toast.showToast({ severity: "error", message: t`A policy already exists for selected user.` });
        } else if (error.code === 1012008) {
          toast.showToast({ severity: "error", message: t`A policy already exists for selected domains.` });
        } else {
          toast.showToast({ severity: "error", message: t`Failed to save policy, please try again.` });
        }
      } else {
        const { data: policy } = response;

        if (currentPolicy?.id !== policy.id) {
          dispatch(adminSlice.actions.setCurrentCampaignPolicy({ id: policy.id }));
        }

        toast.showToast({ severity: "success", message: t`Policy was saved.` });
      }
    })();
  };

  const isLoading = isLoadingPolicy || isLoadingMe;

  return (
    <Box py={2}>
      <Box alignItems="center" display="flex" justifyContent="space-between" mb={2}>
        <Box alignItems="center" display="flex" gap={1}>
          <IconButton
            color="primary"
            component={Link}
            size="small"
            to={type === CommPolicyType.USER && parentId ? `/admin/campaigns/policies/${parentId}/users` : "/admin/campaigns/policies"}
          >
            <ArrowBackRounded fontSize="small" />
          </IconButton>
          <Typography fontSize={22} fontWeight={600} lineHeight={1}>
            {type === CommPolicyType.MASTER ? t`Master policy` : undefined}
            {type === CommPolicyType.BRAND ? t`Brand policy` : undefined}
            {type === CommPolicyType.USER ? t`User policy` : undefined}
          </Typography>
          {!currentPolicy?.isEditable ? (
            <Typography color={palette.grey[700]} fontSize={13} lineHeight={1}>{t`Only super admins can change this policy.`}</Typography>
          ) : undefined}
        </Box>
        {currentPolicy?.isEditable ? (
          <Box display="flex" gap={2}>
            <Button disabled={!!submitTooltip || isLoading || isCreating || isUpdating}>{t`Cancel`}</Button>
            <Tooltip title={submitTooltip}>
              <span>
                <LoadingButton disabled={!!submitTooltip || isLoading || isCreating || isUpdating} onClick={handleSaveClick} variant="contained">
                  {t`Save Policy`}
                </LoadingButton>
              </span>
            </Tooltip>
          </Box>
        ) : undefined}
      </Box>
      {isLoading ? (
        <>
          <Skeleton height={18} sx={{ mb: 1 }} variant="rectangular" width={220} />
          <Skeleton height={36} sx={{ mb: 2 }} variant="rectangular" width="100%" />
          <Skeleton height={18} sx={{ mb: 1 }} variant="rectangular" width={280} />
          <Skeleton height={108} sx={{ mb: 2 }} variant="rectangular" width="100%" />
          <Skeleton height={18} sx={{ mb: 1 }} variant="rectangular" width={280} />
          <Skeleton height={216} sx={{ mb: 2 }} variant="rectangular" width="100%" />
        </>
      ) : children}
    </Box>
  );
};  
