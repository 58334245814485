import { ReservationStatus } from "store";

export const resolveReservationStatusColor = (type?: ReservationStatus): string | undefined => {
  switch (type) {
    case ReservationStatus.BOOKED:
      return "#5BC535";
    case ReservationStatus.CANCELED:
      return "#FF4C0E";
    case ReservationStatus.FAILED:
      return "#A12222";
    case ReservationStatus.PENDING:
      return "#FFBD0E";
    case ReservationStatus.PROCESSING:
      return "#03A9F4";
    default:
      return undefined;
  }
};
