import InfiniteNotificationList from './InfiniteNotificationList';
import Popup from 'reactjs-popup';
import styles from './styles.module.scss';
import { getPushNotifications, updatePushNotificationStatus } from 'App/Store/Notifications';
import { PushNotificationModel } from 'App/Store/Notifications/models';
import { Trans, t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTypedSelector } from 'store/_legacy/Redux/store';
import { getMessagingToken, subscribeMessagingHandler } from 'messaging';

const notificationTrigger = (isNewNotifications: boolean) => {
  const { config } = useTypedSelector(state => state);
  const isNewNotificationsClass = isNewNotifications ? styles.newNotificationsExist : '';

  return (
    <button
      aria-label="notifications button"
      className={styles.notificationTrigger}
      name="notifications button"
    >
      <div className={isNewNotificationsClass}>
        <svg height="30px" version="1.1" viewBox="0 0 30 30" width="30px">
          <g fill="none" fillRule="evenodd" id="Booking" stroke="none" strokeWidth="1">
            <g id="Createbooking-appointment-4" transform="translate(-900.000000, -30.000000)">
              <g id="notifications" transform="translate(900.000000, 25.000000)">
                <g id="icons/notification" transform="translate(0.000000, 5.000000)">
                  <rect fill="#F1F8FF" height="30" id="Rectangle" rx="8" width="30" x="0" y="0"></rect>
                  <path d="M18,21 C18,22.6568542 16.6568542,24 15,24 C13.4023191,24 12.0963391,22.75108 12.0050927,21.1762728 L12,21 L7,21 C6.44771525,21 6,20.5522847 6,20 C6,19.4477153 6.44771525,19 7,19 L9,19 L9,12 C9,8.6862915 11.6862915,6 15,6 C18.2383969,6 20.8775718,8.56557489 20.9958615,11.7750617 L21,12 L21,19 L23,19 C23.5522847,19 24,19.4477153 24,20 C24,20.5522847 23.5522847,21 23,21 L18,21 Z M15,8 C12.8578046,8 11.1089211,9.68396847 11.0048953,11.8003597 L11,12 L11,19 L19,19 L19,12 C19,9.92690768 17.4229223,8.22216159 15.4030605,8.02005514 L15.1996403,8.00489531 L15,8 Z" fill={config.theme.primary}></path>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </button>
  );
};

const getIsNewNotifications = (pushNotifications: PushNotificationModel[]) => {
  // return "true" if has at least 1 isWatched = false
  if (pushNotifications.length) {
    return pushNotifications.some(notif => !notif.isWatched);
  }

  return false;
};

export default function PushNotifications() {
  const dispatch = useDispatch();
  const { profile, pushNotifications } = useTypedSelector(state => state);

  const [isTokenFound, setTokenFound] = useState(false);
  const [isNewNotifications, setIsNewNotifications] = useState(getIsNewNotifications(pushNotifications.pushNotifications));  // flag to show "have new notif sign"

  const onUpdateIsWatchedStatus = () => {
    if (pushNotifications.pushNotifications.length) {
      const newNotifications = pushNotifications.pushNotifications.filter(notif => !notif.isWatched);
      if (newNotifications) {
        // set all notifs as watched
        setIsNewNotifications(false);
        newNotifications.map(notif => {
          dispatch(updatePushNotificationStatus({ notificationId: notif.id }));
        });
      }
    }
  };

  const onOpenNotifications = () => {
    onUpdateIsWatchedStatus();
  };

  const onCloseNotifications = () => {
    // load getPushNotifications with page 1 to clear all loaded data
    dispatch(getPushNotifications({ userId: profile.id, page: 1 }));
  };

  useEffect(() => {
    // concat notifications from previous and next pages \ check isWatched status after load
    if (pushNotifications.page !== 1) {
      onUpdateIsWatchedStatus();
    }

    // update "have new notif sign" after load list
    setIsNewNotifications(getIsNewNotifications(pushNotifications.pushNotifications));
  }, [pushNotifications.pushNotifications]);

  useEffect(() => {
    // load notifications on main page load
    if (profile.id) {
      dispatch(getPushNotifications({ userId: profile.id, page: 1 }));
    }
  }, [profile.id]);

  // logic for receive push notification
  useEffect(() => {
    getMessagingToken()?.then((data) => {
      if (data) {
        setTokenFound(true);
      }
    });
  }, []);

  useEffect(() => {
    const subscription = subscribeMessagingHandler((payload: any) => {
      if (isTokenFound) {
        if (payload) {
          // temp logic to load history notif to check updates // to do - add websockets
          if (profile.id) {
            dispatch(getPushNotifications({ userId: profile.id, page: 1 }));
          }
        }
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <div>
      <Popup
        closeOnDocumentClick
        onClose={onCloseNotifications}
        onOpen={onOpenNotifications}
        position="bottom right"
        trigger={notificationTrigger(isNewNotifications)}
      >
        <div
          aria-label={t`Notifications Dialog`}
          className={styles.notificationContentWrapper}
          role="dialog"
        >
          <h2>
            <Trans>
              Notifications
            </Trans>
          </h2>

          <div
            aria-label={t`Notifications List`}
            className={styles.notificationContent}
            role="list"
          >
            <InfiniteNotificationList />
          </div>
        </div>
      </Popup>
    </div>
  );
}
