// UI guideline IPG https://projects.invisionapp.com/d/main#/console/21248637/453183410/inspect
// UI guideline Kinesso https://projects.invisionapp.com/d/main#/console/21458539/453374544/inspect

export const blue = "#5FB3DF";
export const blueLight = "#EFEFF8";
export const darkBlue = "#1E1F7B";
export const darkBlue2 = "#2A2B95"; // hover color for darkBlue
export const darkBlueLigthen20 = "#3234cd";
export const darkBlueLigthen50 = "#1e1f7b80";
export const darkBlueTransparent = "#1e1f7b59";

// dark blue theme colors
export const darkBlue3 = "#003767";
export const darkBlue4 = "#135392"; // hover color for darkBlue3
export const blue2 = "#0079C1";
export const darkBlueLight = "#F1F8FF";
// end dark blue theme colors

export const green = "#099a35";
export const greenTransparent = "#099a359c";
export const grey = "#5E5E5E";
export const greyDark = "#292929";
export const greyDarkTransparent = "#2929295c";
export const greyLight = "#F4F4F4";
export const greyLightTransparent = "#A2A2A233";
export const greyMedium = "#ABABAB";
export const greyTransparent = "#5e5e5e4a";
export const orange = "#FF4C0E";
export const orangeLight = "#FFECE6";
export const persianGreen = "#00B1A4";
export const persianGreen2 = "#197b77"; // hover color for persianGreen
export const persianGreenLight = "#EEF9F8";
export const persianGreenLighten20 = "#18ffee";
export const persianGreenLighten50 = "#00b1a480";
export const persianGreenTransparent= "#00b1a461";
export const red = "#fb2626";
export const redTransparent = "#fb262633";
