import styles from './styles.module.scss';
import Text from 'components/_legacy/Text';
import Box from 'components/_legacy/Box';
import { Room } from 'App/Store/Meetings/meetingRooms/models';
import { MutableRefObject } from 'react';
import { Item } from '../../../Helpers';

interface Props {    
  room: Room;
  itemSelected: MutableRefObject<Item | undefined >  
}

export default function Group({ room, itemSelected }: Props) {    
  const groupClass = (roomId: string) => {
    if (itemSelected && itemSelected.current && itemSelected.current.roomId == roomId) return styles.groupSelected;

    return styles.group;    
  };  

  return (   
    <Box className={styles.line} direction='row' display='flex'>            
      <Box className={groupClass(room.id)} direction='column' display="flex" justifyContent='between' minWidth={180}>
        <Text size='lg' truncate>
          {room.name}
        </Text>
      </Box>                  
    </Box>
  );
}
