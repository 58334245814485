import Tooltip from '../Tooltip';
import { useEffect, useRef, useState } from 'react';
import { useTypedSelector } from 'store/_legacy/Redux/store';

type placementType =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';

interface OverflowToolTipProps {
  text: string | number;
  placement?: placementType;
  isLocation?: boolean; // recalculate width for table cell with mass actions
  className?: string;
}

const OverflowToolTip = (props: OverflowToolTipProps) => {
  const { adminReservations } = useTypedSelector(state => state);
  const { reservationMassActions } = adminReservations;
  const { text, placement = 'top', isLocation = false, className } = props;
  const textElementRef = useRef(null);

  const compareSize = () => {
    // @ts-ignore
    const compare = textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
    setHover(compare);
  };

  useEffect(() => {
    if (isLocation) {
      compareSize();
    }
  }, [reservationMassActions]);

  // compare size after text change (on any edit action)
  useEffect(() => {
    compareSize();
  }, [text]);

  // compare once and add resize listener on "componentDidMount"
  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);
  }, []);

  // remove resize listener again on "componentWillUnmount"
  useEffect(() => () => {
    window.removeEventListener('resize', compareSize);
  }, []);

  // Define state and function to update the value
  const [hoverStatus, setHover] = useState(false);

  return (
    <Tooltip
      className={className}
      classes={{
        tooltip: 'overflow-tooltip',
      }}
      disableHoverListener={!hoverStatus}
      interactive
      placement={placement}
      title={text}
    >
      <div
        ref={textElementRef}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {text}
      </div>
    </Tooltip>
  );
};

export default OverflowToolTip;
