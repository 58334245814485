import React from "react";
import { EditorFormatMark, EditorLeafProps } from "./types";
import { Typography, TypographyProps } from "@mui/material";
import { matchArray } from "utils";

const leftMarkMapping: Record<EditorFormatMark, React.ElementType> = {
  bold: "b",
  italic: "i",
  underline: "u",
  "line-through": "s",
};

const resolveInlineTags = (leaf: EditorLeafProps["leaf"]): React.ElementType[] => {
  const tags: React.ElementType[] = [];

  for (const key of Object.keys(leaf)) {
    const leafMark = leftMarkMapping[key as EditorFormatMark];

    if (leafMark) {
      tags.push(leafMark);
    }
  }

  return tags;
};

export const EditorLeaf: React.FC<EditorLeafProps> = (props) => {
  const { leaf, attributes, children } = props;
  const tags = resolveInlineTags(leaf);
  let actualChildren = children;

  for (const tag of tags) {
    const textDecoration = matchArray([[tag === "u", "underline"], [tag === "s", "line-through"]]);
    const sx: TypographyProps["sx"] = {
      textDecoration,
      fontWeight: tag === "b" ? 600 : "inherit",
      fontStyle: tag === "i" ? "italic" : undefined,
    };

    actualChildren = <Typography component={tag} display="inline" fontSize="inherit" sx={sx}>{actualChildren}</Typography>;
  }

  return <Typography component="span" fontSize="inherit" {...attributes}>{actualChildren}</Typography>;
};
