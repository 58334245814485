import format from "date-fns/format";
import {getAvailableDesks} from "../../../../../../App/Store/Bookings/availableDesksDuck";
import {BookingType} from "../../../../../../App/Store/Bookings/bookingDuck/models";
import {WeekDaysObject, WeeklySlot} from "../../../../../../App/Store/Bookings/createNewBookingDuck/models";
import { weeklySlotsToWeeklyBooking } from "App/Store/Utils";

export interface onLoadDesksFilters {
  bookingType: BookingType;
  dateFrom?: Date | string;
  dateTo?: Date | string;
  timeFrom?: Date | string;
  timeTo?: Date | string;
  weekDays?: WeekDaysObject;
  showDeletedDesks?: boolean;
  weeklySlots?: WeeklySlot[];
}

// load desks based on filters
export const onLoadDesks = (floorId: string | undefined, onLoadDesksFilters: onLoadDesksFilters, dispatch: Function) => {
  if (floorId) {
    const { bookingType, timeFrom, timeTo, dateFrom, dateTo, weeklySlots, showDeletedDesks } = onLoadDesksFilters;

    switch (bookingType) {
      case "custom": {
        if (dateFrom && dateTo && timeFrom && timeTo) {
          const timeFromFormatted = timeFrom ? format(new Date(timeFrom), 'HH:mm') : '';
          const timeToFormatted = timeTo ? format(new Date(timeTo), 'HH:mm') : '';

          dispatch(getAvailableDesks({
            data: {
              custom: {
                dateFrom: dateFrom ? format(new Date(dateFrom), 'yyyy-MM-dd') : '',
                dateTo: dateTo ? format(new Date(dateTo), 'yyyy-MM-dd') : '',
                timeFrom: timeFromFormatted,
                timeTo: timeToFormatted,
              },
              showDeletedDesks,
            },
            floorId,
          }));
        }
        break;
      }
      case "daily": {
        if (timeFrom && timeTo) {
          const timeFromFormatted = timeFrom ? format(new Date(timeFrom), 'HH:mm') : '';
          const timeToFormatted = timeTo ? format(new Date(timeTo), 'HH:mm') : '';

          dispatch(getAvailableDesks({
            data: {
              daily: { timeFrom: timeFromFormatted, timeTo: timeToFormatted },
              showDeletedDesks,
            },
            floorId,
          }));
        }
        break;
      }
      case "weekly": {
        if (weeklySlots?.length) {
          const weeklyBooking = weeklySlotsToWeeklyBooking(weeklySlots);

          dispatch(getAvailableDesks({
            data: {
              weekly: weeklyBooking,
              showDeletedDesks,
            },
            floorId,
          }));
        }
        break;
      }
      default:
        break;
    }
  }
};
