import { Link, useLocation, useParams } from "react-router-dom";
import clsx from "clsx";
import styles from "./styles.module.scss";
import Text from "components/_legacy/Text";

export default function FloorHeaderNavigation() {
  const location = useLocation();
  const { floorId, locationId } = useParams<{ floorId: string; locationId: string; }>();

  const NAVIGATION_ITEMS = [
    {
      name: 'Map',
      to: `/admin/location/${locationId}/add-floor/${floorId}/floor-map`,
      alias: 'map',
    },
    {
      name: 'Desk list',
      to: `/admin/location/${locationId}/add-floor/${floorId}/desk-list`,
      alias: 'deskList',
    },
    {
      name: 'Room list',
      to: `/admin/location/${locationId}/add-floor/${floorId}/room-list`,
      alias: 'roomList',
    },
    {
      name: 'Attributes',
      to: `/admin/location/${locationId}/add-floor/${floorId}/attributes`,
      alias: 'attributes',
    },
  ];

  return (
    <nav className={styles.nav} data-testid="floor-map-details-menu">
      <ul>
        {NAVIGATION_ITEMS.map((item, index) => {
          const isSelected = item.to === location.pathname;
          const classes = clsx(isSelected && styles.isSelected);

          return (
            <li className={classes} data-testid={`floor-map-details-menu-item`} key={item.name + index}>
              <Link to={item.to}>
                <Text color={isSelected ? 'blue' : 'black'} size="md" weight="semi-bold">
                  {item.name}
                </Text>
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}
