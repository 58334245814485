import { t } from "@lingui/macro";
import { NotificationsNone } from "@mui/icons-material";
import { Box, Button, ButtonProps, Popover, Typography } from "@mui/material";
import { NotificationsList } from "../notifications";
import { useState } from "react";

export const UserNotificationsButton: React.FC<ButtonProps> = (props) => {
  const { children, ...rest } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <>
      <Button
        color="secondary"
        disableElevation
        onClick={(event) => setAnchorEl(event.currentTarget)}
        size="small"
        sx={{ minWidth: 32, width: 32, height: 32, padding: 0, borderRadius: 2 }}
        variant="contained"
        {...rest}
        data-cid="notifications-button"
      >
        {children || <NotificationsNone />}
      </Button>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        onClose={() => setAnchorEl(null)}
        open={!!anchorEl}
        sx={{ mt: 1 }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Box p={2} width={412}>
          <Typography fontSize={18} fontWeight="600" mb={2}>{t`Notifications`}</Typography>
          <Box maxHeight={360} sx={{ overflowX: "hidden", overflowY: "auto" }}>
            {anchorEl ? <NotificationsList limit={25} /> : undefined}
          </Box>
        </Box>
      </Popover>
    </>
  );
};
