export enum UserRole {
  USER = "User",
  LOCAL_ADMIN = "Local Admin",
  SUPER_ADMIN = "Super Admin",
  HR = "HR",
  DELEGATE = "Delegate",
  RESERVATION_MANAGER = "Reservation Manager",
  EXECUTIVE_ASSISTANT = "Executive Assistant",
  CATERING_ADMIN = "Catering Admin",
  COMMUNICATION_MANAGER = "Communication Manager",
}
