import {useDispatch} from "react-redux";
import {useTypedSelector} from "store/_legacy/Redux/store";
import {useEffect} from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import {setUserManagementData} from "../../../Store/userManagement";

export function SuccessUserManagementSnackbar() {
  const dispatch = useDispatch();
  const { successMessage } = useTypedSelector(state => state.adminUserManagement);

  const handleClose = () => {
    dispatch(setUserManagementData({ successMessage: '' }));
  };

  useEffect(() => {
    return () => {
      dispatch(setUserManagementData({ successMessage: '' }));
    };
  }, []);

  return successMessage ? (
    <Snackbar autoHideDuration={6000} onClose={handleClose} open>
      <Alert onClose={handleClose} severity="success">
        {successMessage}
      </Alert>
    </Snackbar>
  ) : null;
}

export function ErrorUserManagementSnackbar() {
  const dispatch = useDispatch();
  const { error } = useTypedSelector(state => state.adminUserManagement);

  const handleClose = () => {
    dispatch(setUserManagementData({ error: '' }));
  };

  useEffect(() => {
    return () => {
      dispatch(setUserManagementData({ error: '' }));
    };
  }, []);

  return error ? (
    <Snackbar autoHideDuration={6000} onClose={handleClose} open>
      <Alert onClose={handleClose} severity="error">
        {error}
      </Alert>
    </Snackbar>
  ) : null;
}
