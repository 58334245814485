import {useDispatch} from "react-redux";
import {useTypedSelector} from "store/_legacy/Redux/store";
import {selectAvailableAttributes} from "Admin/Store/attributes";
import {setFloorMapData} from "Admin/Store/floorMapDuck";
import Box from "components/_legacy/Box";
import Text from "components/_legacy/Text";
import {Trans} from "@lingui/macro";
import styles from "../../../styles.module.scss";
import Checkbox from "components/_legacy/Checkbox";
import Button from "components/_legacy/Button";
import {AttributeModel} from "Admin/Store/attributes/models";

interface MassActionsEditAmenitiesProps {
  onUpdateAllDesks: Function;
  onClosePopupEdit: Function;
}

export default function MassActionsEditAmenities(props: MassActionsEditAmenitiesProps) {
  const dispatch = useDispatch();
  const { adminAttributes, adminFloorMap } = useTypedSelector(state => state);
  const { onUpdateAllDesks, onClosePopupEdit } = props;
  const { editDeskList } = adminFloorMap;
  const { attributesDesk } = adminAttributes;
  const attributes: AttributeModel[] = selectAvailableAttributes(attributesDesk);
  
  const onAttributeUpdate = (attribute: AttributeModel, isChecked: boolean) => {
    const newSelectAll = {
      ...editDeskList.selectAllResources,
      [attribute.id]: {
        id: attribute.id,
        name: attribute.name,
        selected: isChecked,
      },
    };

    dispatch(setFloorMapData({
      editDeskList: {
        selectAllResources: newSelectAll,
      },
    }));
  };
  
  const onApplyClick = () => {
    const resourceIds: { [resourceId: string]: string } = {};

    Object.keys(editDeskList.selectAllResources).forEach(resourceId => {
      const resource = editDeskList.selectAllResources[resourceId];

      if (resource.selected) {
        resourceIds[resourceId] = resourceId;
      }
    });

    onUpdateAllDesks({ resources: resourceIds });
  };
  
  return (
    <Box>
      <Box margin="9px 0 4px">
        <Text color="gray" size="md">
          <Trans>
            Select amenities in order to apply for selected desks
          </Trans>
        </Text>
      </Box>

      <Box className="popover-content" marginBottom={20}>
        {attributes.map((attribute, index) => {
          const checked = editDeskList.selectAllResources[attribute.id]?.selected;

          return (
            <Checkbox
              checked={checked}
              classes={{ container: styles.checkbox }}
              key={attribute.id + index}
              label={attribute.name}
              name={attribute.name}
              onChange={(isChecked) => onAttributeUpdate(attribute, isChecked)}
            />
          );
        })}
      </Box>

      <Box alignItems="center" display="flex" justifyContent="end">
        <Button
          onClick={() => onClosePopupEdit()}
          size="sm"
          type="clear"
          withShadow={false}
        >
          <Trans>
            Cancel
          </Trans>
        </Button>

        <Button
          onClick={() => onApplyClick()}
          size="sm"
        >
          <Trans>
            Apply Changes
          </Trans>
        </Button>
      </Box>
    </Box>
  );
}
