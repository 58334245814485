import LocationDetailsHeader from 'components/_legacy/Header/LocationDetailsHeader';
import { FloorsList } from './floors-list';

export default function Floors() {
  return (
    <>
      <LocationDetailsHeader />
      <FloorsList />
    </>
  );
}
