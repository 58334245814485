import Appointments from './Pages/Appointments';
import Attributes from './Pages/Attributes';
import Box from 'components/_legacy/Box';
import Button from 'components/_legacy/Button';
import CalendarPage from './Pages/Calendar';
import Container from 'components/_legacy/Container';
import CovidQuestions from './Pages/CovidQuestions';
import Dashboard from './Pages/Dashboard';
import DeskList from './Pages/DeskList';
import Groups from './Pages/Groups';
import GroupDetails from './Pages/GroupDetails';
import RoomsManagement from './Pages/RoomsManagement';
import EmailTemplates from './Pages/EmailTemplates';
import Error404 from 'App/Pages/Error404';
import FloorMap from './Pages/FloorMap';
import FloorMapEdit from './Pages/FloorMapEdit';
import FloorMapAttributes from './Pages/FloorMapAttributes';
import Floors from './Pages/Floors';
import Heading from 'components/_legacy/Heading';
import Locations from './Pages/Locations';
import Menu from 'components/_legacy/Menu';
import DelegateMenu from 'components/_legacy/Menu/DelegateMenu';
import Notifications from './Pages/Notifications';
import Parking from './Pages/Parking';
import PushTemplates from './Pages/PushTemplates';
import ReservationJustInTime from './Pages/ReservationJustInTime';
import Reservations from './Pages/Reservations';
import ReservationsMapViewDetails from './Pages/Reservations/ReservationMapViewDetails';
import SendNotification from './Pages/SendNotification';
// import SettingsColorSchemes from './Pages/Settings/SettingsColorSchemes';
import SettingsMobile from './Pages/Settings/SettingsMobile';
import Templates from "./Pages/Templates";
import LocationSettings from "./Pages/LocationSettings";
import styles from './styles.module.scss';
import UserManagement from './Pages/UserManagement';
import { AnimatePresence } from 'framer-motion';
import { PageTransition } from 'App/AppRoutes';
import { roleAlias } from './Store/userManagement/models';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { Trans, t } from '@lingui/macro';
import Catering from './Pages/Catering';
import { MeetingsCalendarView, MeetingsListView } from './Pages/Meetings';
import { CampaignBrandPolicy, CampaignBrandPolicyUsers, CampaignContent, CampaignDetails, CampaignMasterPolicy, CampaignPolicies, CampaignRecipients, CampaignSettings, CampaignUserPolicy, CampaignsActiveList, CampaignsList, CampaignsPendingList, CampaignsTemplateList } from './Pages/Campaigns';
import { useTypedSelector } from 'store/_legacy/Redux/store';

function LocationRoutes() {
  const location = useLocation();

  return (
    // AnimatePresence will make pages have a fade in/out transition. This will affect only the content, not the Menu and Header
    <AnimatePresence exitBeforeEnter initial={true}>
      <Switch key={location.pathname} location={location}>
        <Route exact path='/admin/location/:locationId/add-floor/:floorId/attributes' render={() => <FloorMapAttributes />} />
        <Route exact path='/admin/location/:locationId/add-floor/:floorId/desk-list' render={() => <DeskList />} />
        <Route exact path='/admin/location/:locationId/add-floor/:floorId/room-list' render={() => <RoomsManagement />} />
        <Route exact path='/admin/location/:locationId/add-floor/:floorId/floor-map' render={() => <FloorMap />} />
        <Route exact path='/admin/location/:locationId/add-floor/:floorId/floor-map-edit' render={() => <FloorMapEdit />} />
        <Route exact path='/admin/location/:locationId/templates/questions' render={() => <Templates><CovidQuestions /></Templates>} />
        <Route exact path='/admin/location/:locationId/templates/notifications' render={() => <Templates><Notifications /></Templates>} />
        <Route exact path='/admin/location/:locationId/templates/email-templates' render={() => <Templates><EmailTemplates /></Templates>} />
        <Route exact path='/admin/location/:locationId/templates/push-templates' render={() => <Templates><PushTemplates /></Templates>} />
        <Route exact path='/admin/location/:locationId/attributes' render={() => <Attributes />} />
        <Route exact path='/admin/location/:locationId/calendar' render={() => <CalendarPage />} />
        <Route exact path='/admin/location/:locationId/location-settings' render={() => <LocationSettings />} />
        <Route exact path='/admin/location/:locationId/dashboard' render={() => <Dashboard />} />
        <Route exact path='/admin/location/:locationId/floors' render={() => <Floors />} />
        <Route exact path='/admin/location/:locationId/parking' render={() => <Parking />} />
        <Route exact path='/admin/location/:locationId/catering' render={() => <Catering />} />
        <Route exact path='/admin' render={() => <PageTransition><Locations /></PageTransition>} />
        <Route path='*' render={() => <Error404 />} />
      </Switch>
    </AnimatePresence >
  );
}

function ReservationRoutes() {
  const location = useLocation();

  return (
    // AnimatePresence will make pages have a fade in/out transition. This will affect only the content, not the Menu and Header
    <AnimatePresence exitBeforeEnter initial={true}>
      <Switch key={location.pathname} location={location}>
        <Route exact path='/admin/reservations/:locationId/map-view/:floorId' render={() => <ReservationsMapViewDetails />} />
        <Route exact path='/admin/reservations' render={() => <PageTransition><Reservations /></PageTransition>} />
        <Route path='*' render={() => <Error404 />} />
      </Switch>
    </AnimatePresence >
  );
}

function SettingsRoutes() {
  return (
    // AnimatePresence will make pages have a fade in/out transition. This will affect only the content, not the Menu and Header
    <AnimatePresence exitBeforeEnter initial={true}>
      <Switch>
        <Route exact path='/admin/settings/mobile' render={() => <PageTransition><SettingsMobile /></PageTransition>} />
        {/*<Route exact path='/admin/settings/color-schemes' render={() => <PageTransition><SettingsColorSchemes /></PageTransition>} />*/}
        <Route path='*' render={() => <Error404 />} />
      </Switch>
    </AnimatePresence >
  );
}

// return only create-reservation page with 1 link in menu
function DelegateRoutes() {
  return (
    <Box
      display="grid"
      gridTemplateColumns="230px 1fr"
      minHeight="100vh"
    >
      <DelegateMenu />

      {/* overflow: hidden fixes an issue with location dashboards, where a horizontal scroll was being added incorrectly */}
      <Container dataTestId="admin-content" style={{ overflow: 'hidden' }}>
        <Switch>
          <Route path='/admin/create-reservation' render={() => <ReservationJustInTime />} />
          <Route path='/admin' render={() => <Redirect to={'/admin/create-reservation'} />} />
        </Switch>
      </Container>
    </Box >
  );
}

function GroupRoutes() {
  const location = useLocation();

  return (
    // AnimatePresence will make pages have a fade in/out transition. This will affect only the content, not the Menu and Header
    <AnimatePresence exitBeforeEnter initial={true}>
      <Switch key={location.pathname} location={location}>
        <Route exact path='/admin/groups/:groupId' render={() => <GroupDetails />} />
        <Route exact path='/admin/groups' render={() => <PageTransition><Groups /></PageTransition>} />
        <Route path='*' render={() => <Error404 />} />
      </Switch>
    </AnimatePresence >
  );
}

const ROLES_THAT_CAN_ACCESS_ADMIN_ROUTES: roleAlias[] = [
  'superAdmin',
  'localAdmin',
  'hr',
  'reservationManager',
  'delegate',
  'cateringAdmin',
  'campaignManager',
];

export default function AdminRoutes() {
  const location = useLocation();
  const { config, profile } = useTypedSelector(state => state);
  const { roleAccess } = profile;

  const userRoles = Object.keys(roleAccess).filter(key => roleAccess[key as roleAlias]).map(key => key as roleAlias);

  if (userRoles.includes('delegate')) {
    return <DelegateRoutes />;
  }

  return ROLES_THAT_CAN_ACCESS_ADMIN_ROUTES.some(role => userRoles.includes(role)) ? (
    <Box
      display="grid"
      gridTemplateColumns="230px 1fr"
      minHeight="100vh"
    >
      <Menu />

      {/* overflow: hidden fixes an issue with location dashboards, where a horizontal scroll was being added incorrectly */}
      <div data-TestId="admin-content" style={{ overflow: 'hidden', paddingLeft: 16, paddingRight: 16 }}>
        <Switch key={location.pathname} location={location}>
          <Route path='/admin/visits' render={() => <Appointments />} />
          <Route path='/admin/reservations' render={() => <ReservationRoutes />} />
          <Route exact path="/admin/meetings" render={() => <MeetingsListView />} />
          <Route exact path="/admin/meetings/calendar-view" render={() => <MeetingsCalendarView />} />
          <Route exact path="/admin/campaigns" render={() => <CampaignsList />} />
          <Route exact path="/admin/campaigns/pending" render={() => <CampaignsPendingList />} />
          <Route exact path="/admin/campaigns/active" render={() => <CampaignsActiveList />} />
          <Route exact path="/admin/campaigns/templates" render={() => <CampaignsTemplateList />} />
          <Route exact path={["/admin/campaigns/new", "/admin/campaigns/:campaignId/edit"]} render={() => <CampaignContent />} />
          <Route
            exact
            path={["/admin/campaigns/new/(audience|test)", "/admin/campaigns/:campaignId/edit/(audience|test)"]}
            render={() => <CampaignRecipients />}
          />
          <Route
            exact
            path={["/admin/campaigns/new/settings", "/admin/campaigns/:campaignId/edit/settings"]}
            render={() => <CampaignSettings />}
          />
          <Route exact path="/admin/campaigns/policies" render={() => <CampaignPolicies />} />
          <Route exact path="/admin/campaigns/policies/master" render={() => <CampaignMasterPolicy />} />
          <Route exact path="/admin/campaigns/policies/new" render={() => <CampaignBrandPolicy />} />
          <Route exact path="/admin/campaigns/policies/:policyId" render={() => <CampaignBrandPolicy />} />
          <Route exact path="/admin/campaigns/policies/:policyId/users" render={() => <CampaignBrandPolicyUsers />} />
          <Route exact path="/admin/campaigns/policies/:parentId/users/new" render={() => <CampaignUserPolicy />} />
          <Route exact path="/admin/campaigns/policies/:parentId/users/:policyId" render={() => <CampaignUserPolicy />} />
          <Route exact path="/admin/campaigns/:campaignId" render={() => <CampaignDetails />} />
          {(roleAccess.superAdmin || roleAccess.localAdmin) && <Route path='/admin/groups' render={() => <GroupRoutes />} />}
          {(roleAccess.superAdmin) && <Route path='/admin/settings' render={() => <SettingsRoutes />} />}
          {roleAccess.superAdmin && <Route path='/admin/send-notification' render={() => <SendNotification />} />}
          {(roleAccess.superAdmin || roleAccess.hr || roleAccess.localAdmin) && <Route path='/admin/user-management' render={() => <UserManagement />} />}
          <Route path='/admin/create-reservation' render={() => <ReservationJustInTime />} />
          {(roleAccess.superAdmin || roleAccess.hr || roleAccess.localAdmin) && <Route path='/admin' render={() => <LocationRoutes />} />}
        </Switch>
      </div>
    </Box >
  ) : (
    <Box
      alignItems="center"
      backgroundColor={config.theme.primary}
      borderRadius={80}
      className={styles.admin}
      direction="column"
      display="flex"
      justifyContent="center"
      margin="145px auto"
      maxWidth={974}
      padding="150px 50px"
    >
      <Heading align="center" className={styles.h1}>
        <Trans>
          Access Denied
        </Trans>
      </Heading>

      <Box marginBottom={23} marginTop={16}>
        <Heading align="center" color="white" size="md">
          <Trans>
            Only admin users can access this area
          </Trans>
        </Heading>
      </Box>

      <Button
        aria-label={t`Back to Home Page`}
        name={t`Back to Home Page`}
        size="sm"
        to="/"
        type="white"
        withShadow={false}
      >
        <Trans>
          Back to Home Page
        </Trans>
      </Button>
    </Box>
  );
}
