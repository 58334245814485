import Api from 'store/_legacy/Services/Api';
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import {
  ADD_ROOM_IMAGE,
  ADD_ROOM_IMAGE_FAIL,
  ADD_ROOM_IMAGE_SUCCESS,
  AddRoomImage,
} from "./index";

function* addRoomImageSaga(action: AddRoomImage): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: ADD_ROOM_IMAGE_SUCCESS, payload });
  } catch (e) {
    yield put({ type: ADD_ROOM_IMAGE_FAIL, payload: e });
  }
}

export default [
  takeLatest(ADD_ROOM_IMAGE, addRoomImageSaga),
];
