import { t } from "@lingui/macro";
import { without } from "lodash";
import { CheckRounded, InfoOutlined, WarningAmberRounded } from "@mui/icons-material";
import { Box, CircularProgress, Collapse, Switch, SwitchProps, Typography, useTheme } from "@mui/material";
import { DesktopDateTimePicker, DesktopDateTimePickerProps, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { CampaignWrapper, DefaultChip, RequiredMark, UserSelect, UserSelectProps } from "components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PostApprovalStatus, PostInteractionType, PostNotificationChannel, PostStatus, PostVerificationStatus, TrackingEventType, adminSlice, selectAdminCurrentCampaign, useVerifyPostMutation } from "store";
import { amber } from "@mui/material/colors";

export const CampaignSettings: React.FC = () => {
  const { palette, background } = useTheme();
  const dispatch = useDispatch();
  const currentCampaign = useSelector(selectAdminCurrentCampaign);
  const [showVerificationDisclaimer, setShowVerificationDisclaimer] = useState(false);
  const [overrideAuthor, setOverrideAuthor] = useState(false);
  const [verifyPost, { isLoading: isVerifyingPost, data: verification }] = useVerifyPostMutation();

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (currentCampaign?.author?.id || currentCampaign?.recipients?.length) {
        verifyPost({
          postId: currentCampaign?.id,
          authorId: currentCampaign?.author?.id,
          recipients: currentCampaign?.recipients?.map(({ type, user, group }) => ({ type, userId: user?.id, groupId: group?.id })),
        });
      }
    }, 900);

    return () => clearTimeout(timeout);
  }, [currentCampaign?.id, currentCampaign?.recipients, currentCampaign?.author?.id, verifyPost]);

  useEffect(() => {
    if (!showVerificationDisclaimer && isVerifyingPost) {
      setShowVerificationDisclaimer(true);
    }
  }, [isVerifyingPost, verification, showVerificationDisclaimer, setShowVerificationDisclaimer]);

  const toggleNotificationChannel = (channel: PostNotificationChannel): void => {
    if (currentCampaign?.notifies?.includes(channel)) {
      dispatch(adminSlice.actions.setCurrentCampaign({ notifies: without(currentCampaign.notifies, channel) }));
    } else {
      dispatch(adminSlice.actions.setCurrentCampaign({ notifies: [...(currentCampaign?.notifies || []), channel] }));
    }
  };

  const toggleInteractionType = (type: PostInteractionType): void => {
    if (currentCampaign?.allows?.includes(type)) {
      dispatch(adminSlice.actions.setCurrentCampaign({ allows: without(currentCampaign.allows, type) }));
    } else {
      dispatch(adminSlice.actions.setCurrentCampaign({ allows: [...(currentCampaign?.allows || []), type] }));
    }
  };

  const toggleTrackType = (type: TrackingEventType): void => {
    if (currentCampaign?.tracks?.includes(type)) {
      dispatch(adminSlice.actions.setCurrentCampaign({ tracks: without(currentCampaign.tracks, type) }));
    } else {
      dispatch(adminSlice.actions.setCurrentCampaign({ tracks: [...(currentCampaign?.tracks || []), type] }));
    }
  };

  const handlePublishAtSwitchChange: SwitchProps["onChange"] = (_, checked) => {
    if (checked) {
      dispatch(adminSlice.actions.setCurrentCampaign({ publishAt: undefined }));
    } else {
      dispatch(adminSlice.actions.setCurrentCampaign({ publishAt: "" }));
    }
  };

  const handleSendAtChange: DesktopDateTimePickerProps<Date>["onChange"] = (value) => {
    dispatch(adminSlice.actions.setCurrentCampaign({ publishAt: value?.toISOString() }));
  };

  const handleDisableCommentsAtSwitchChange: SwitchProps["onChange"] = (_, checked) => {
    if (checked) {
      dispatch(adminSlice.actions.setCurrentCampaign({ disableCommentsAt: "" }));
    } else {
      dispatch(adminSlice.actions.setCurrentCampaign({ disableCommentsAt: undefined }));
    }
  };

  const handleDisableCommentsAtChange: DesktopDateTimePickerProps<Date>["onChange"] = (value) => {
    dispatch(adminSlice.actions.setCurrentCampaign({ disableCommentsAt: value?.toISOString() }));
  };

  const handleAuthorChange: UserSelectProps["onChange"] = (author) => {
    if (author) {
      setOverrideAuthor(true);
      dispatch(adminSlice.actions.setCurrentCampaign({ author }));
    }
  };

  const needsApproval = verification?.author === PostVerificationStatus.APPROVAL_REQUIRED || 
      verification?.recipients === PostVerificationStatus.APPROVAL_REQUIRED;
  let verificationBackground: string | undefined = palette.grey[100];
  let verificationIcon = <CircularProgress size={20} />;
  let verificationText = t`Verifying campaign settings.`;

  if (!isVerifyingPost && verification) {
    verificationBackground = needsApproval ? amber[200] : background.blue.light;
    verificationIcon = needsApproval ? <WarningAmberRounded fontSize="small" /> : <CheckRounded color="primary" fontSize="small" />;
    verificationText = needsApproval ? t`Campaign settings needs approval.` : t`Campaigns settings verified.`;
  }

  const approval = currentCampaign?.approvals?.find(({ status }) => status === PostApprovalStatus.PENDING);

  return (
    <CampaignWrapper>
      <Collapse in={showVerificationDisclaimer}>
        <Box
          alignItems="center"
          bgcolor={verificationBackground}
          borderRadius={2}
          display="flex"
          mb={1}
          onClick={needsApproval && !isVerifyingPost ? undefined : () => setShowVerificationDisclaimer(false)}
          px={2}
          py={1.5}
          sx={{ cursor: needsApproval && !isVerifyingPost ? undefined : "pointer" }}
          width="100%"
        >
          {verificationIcon}
          <Typography fontSize={14} lineHeight={1} ml={1}>{verificationText}</Typography>
        </Box>
      </Collapse>
      <Box maxWidth={420}>
        {currentCampaign?.status !== PostStatus.PUBLISHED ? (
          <>
            <Box alignItems="center" display="flex" justifyContent="space-between" mb={2}>
              <Typography color={palette.grey[700]} fontSize={14}>{t`Send instantly`}</Typography>
              <Switch checked={currentCampaign?.publishAt === undefined} onChange={handlePublishAtSwitchChange} />
            </Box>
            {currentCampaign?.publishAt !== undefined ? (
              <Box mb={2}>
                <Typography fontSize={14} mb={1}><RequiredMark />{t`Send date and time`}</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDateTimePicker
                    ampm={false}
                    defaultValue={currentCampaign.publishAt ? new Date(currentCampaign.publishAt) : undefined}
                    onChange={handleSendAtChange}
                    slotProps={{ textField: { variant: "filled" }, openPickerButton: { color: "primary" } }}
                    sx={{ "& .MuiInputBase-input": { fontSize: 14 } }}
                  />
                </LocalizationProvider>
              </Box>
            ) : undefined}
          </>
        ) : undefined}
        <Box alignItems="center" display="flex" justifyContent="space-between" mb={2}>
          <Typography color={palette.grey[700]} fontSize={14}>{t`Send via email`}</Typography>
          <Switch
            checked={currentCampaign?.notifies?.includes(PostNotificationChannel.EMAIL)}
            onChange={() => toggleNotificationChannel(PostNotificationChannel.EMAIL)}
          />
        </Box>
        <Box alignItems="center" display="flex" justifyContent="space-between" mb={2}>
          <Typography color={palette.grey[700]} fontSize={14}>{t`Send via apps`}</Typography>
          <Switch
            checked={currentCampaign?.notifies?.includes(PostNotificationChannel.PUSH)}
            onChange={() => toggleNotificationChannel(PostNotificationChannel.PUSH)}
          />
        </Box>
        <Box alignItems="center" display="flex" justifyContent="space-between" mb={2}>
          <Typography alignItems="center" color={palette.grey[700]} display="flex" fontSize={14} gap={1}>
            {t`Send reminder`}
            <InfoOutlined fontSize="small" />
          </Typography>
          <Switch
            checked={currentCampaign?.sendReminder}
            onChange={(_, sendReminder) => dispatch(adminSlice.actions.setCurrentCampaign({ sendReminder }))}
          />
        </Box>
        <Box alignItems="center" display="flex" justifyContent="space-between" mb={2}>
          <Typography color={palette.grey[700]} fontSize={14}>{t`Allow reactions`}</Typography>
          <Switch
            checked={currentCampaign?.allows?.includes(PostInteractionType.REACT)}
            onChange={() => toggleInteractionType(PostInteractionType.REACT)}
          />
        </Box>
        <Box alignItems="center" display="flex" justifyContent="space-between" mb={2}>
          <Typography color={palette.grey[700]} fontSize={14}>{t`Allow comments`}</Typography>
          <Switch
            checked={currentCampaign?.allows?.includes(PostInteractionType.COMMENT)}
            onChange={() => toggleInteractionType(PostInteractionType.COMMENT)}
          />
        </Box>
        <Box alignItems="center" display="flex" justifyContent="space-between" mb={2}>
          <Typography color={palette.grey[700]} fontSize={14}>{t`Allow post share`}</Typography>
          <Switch
            checked={currentCampaign?.allows?.includes(PostInteractionType.SHARE)}
            onChange={() => toggleInteractionType(PostInteractionType.SHARE)}
          />
        </Box>
        <Box alignItems="center" display="flex" justifyContent="space-between" mb={2}>
          <Typography color={palette.grey[700]} fontSize={14}>{t`Track clicks`}</Typography>
          <Switch checked={currentCampaign?.tracks?.includes(TrackingEventType.CLICK)} onChange={() => toggleTrackType(TrackingEventType.CLICK)} />
        </Box>
        {currentCampaign?.id === undefined ? (
          <Box alignItems="center" display="flex" justifyContent="space-between" mb={2}>
            <Typography alignItems="center" color={palette.grey[700]} display="flex" fontSize={14} gap={1}>
              {t`Add to templates`}
              <InfoOutlined sx={{ fontSize: 14 }} />
            </Typography>
            <Switch
              checked={currentCampaign?.isTemplate}
              onChange={(_, isTemplate) => dispatch(adminSlice.actions.setCurrentCampaign({ isTemplate }))}
            />
          </Box>
        ) : undefined}
        <Box alignItems="center" display="flex" justifyContent="space-between" mb={2}>
          <Typography color={palette.grey[700]} fontSize={14}>{t`Date to disable comments`}</Typography>
          <Switch checked={currentCampaign?.disableCommentsAt !== undefined} onChange={handleDisableCommentsAtSwitchChange} />
        </Box>
        {currentCampaign?.disableCommentsAt !== undefined ? (
          <Box mb={2}>
            <Typography fontSize={14} mb={1}><RequiredMark />{t`Send date and time`}</Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDateTimePicker
                ampm={false}
                defaultValue={currentCampaign.disableCommentsAt ? new Date(currentCampaign.disableCommentsAt) : undefined}
                onChange={handleDisableCommentsAtChange}
                slotProps={{ textField: { variant: "filled" }, openPickerButton: { color: "primary" } }}
                sx={{ "& .MuiInputBase-input": { fontSize: 14 } }}
              />
            </LocalizationProvider>
          </Box>
        ) : undefined}
        <Typography alignItems="baseline" display="flex" fontSize={14} gap={1} mb={1}>
          <span><RequiredMark />{t`From (indicated in post)`}</span>
          <DefaultChip fontSize={12}>{t`New`}</DefaultChip>
        </Typography>
        <UserSelect disableClearable onChange={handleAuthorChange} value={approval?.author && !overrideAuthor ? approval.author : currentCampaign?.author} />
      </Box>
    </CampaignWrapper>
  );
};
