import { commApi } from "../comm-api";
import { DeletePostCommentReactionInput, PostCommentReaction, SetPostCommentReactionInput } from "../types";
import { CommApiTag } from "../enums";

const postCommentReactions = commApi.injectEndpoints({
  endpoints: (builder) => ({
    setPostCommentReaction: builder.mutation<PostCommentReaction, SetPostCommentReactionInput>({
      query: ({ commentId, ...body }) => ({ body, url: `/api/v1/comments/${commentId}/reactions`, method: "PUT" }),
      invalidatesTags: (_, __, { postId }) => [{ type: CommApiTag.POST_COMMENT, id: postId }],
    }),
    deletePostCommentReactionById: builder.mutation<void, DeletePostCommentReactionInput>({
      query: ({ commentId, commentReactionId }) => ({ url: `/api/v1/comments/${commentId}/reactions/${commentReactionId}`, method: "DELETE" }),
      invalidatesTags: (_, __, { postId }) => [{ type: CommApiTag.POST_COMMENT, id: postId }],
    }),
  }),
});

export const { useDeletePostCommentReactionByIdMutation, useSetPostCommentReactionMutation } = postCommentReactions;
