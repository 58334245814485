import Box from 'components/_legacy/Box';
import DeleteCovidQuestion from './DeleteModal';
import drag from './assets/drag.svg';
import OpenQuestionForm from '../OpenQuestionForm';
import styles from './styles.module.scss';
import { CovidQuestionModel } from 'Admin/Store/covidQuestions/models';
import { deleteCovidQuestion, editCovidSchedule } from 'Admin/Store/covidQuestions';
import { Trans, t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useTypedSelector } from 'store/_legacy/Redux/store';

interface CovidQuestionProps {
  question: CovidQuestionModel;
  index: number
}

export default function CovidQuestion({
  question,
  index,
}: CovidQuestionProps) {
  const dispatch = useDispatch();
  const { locationId } = useParams<{ locationId: string }>();
  const [editState, setEditState] = useState(false);
  const { config, adminCovidQuestions } = useTypedSelector(state => state);

  const editQuestion = () => {
    // TO DO add logic after backend will be done
    setEditState(!editState);
  };

  const onCancelQuestionForm = () => {
    setEditState(false);
  };

  const deleteQuestion = () => {
    dispatch(deleteCovidQuestion({ questionId: question.id }));

    // set inactive schedule if no questions
    if (adminCovidQuestions.covidQuestions.length === 1) {
      const updatedSchedule = {
        ...adminCovidQuestions.covidSchedule,
        isActive: false,
      };

      dispatch(editCovidSchedule({ locationId, schedule: updatedSchedule }));
    }
  };

  const covidStatus = question.isPositive ? 'Yes' : 'No';

  return (
    <div className={styles.covidQuestion} data-testid="covid-question-item">
      <Box className={styles.covidQuestionHeader} display="flex" marginBottom={5} position="relative">
        <img alt={t`Drag question`} className={styles.covidQuestionAction} height={24} src={drag} />

        <h3 data-testid="covid-question-item-header">
          <Trans comment="Eg: Question 1">
            Question {index + 1}
          </Trans>
        </h3>

        <svg className={styles.covidQuestionAction} data-testid="covid-question-item-edit" height="24px" onClick={editQuestion} version="1.1" viewBox="0 0 24 24" width="24px">
          <g fill="none" fillRule="evenodd" id="A-locations" stroke="none" strokeWidth="1">
            <g className={styles.fillColor} fill={config.theme.primary} fillRule="nonzero" id="A-Location-covidquestions" transform="translate(-329.000000, -185.000000)">
              <g id="icons/editpencilgrey" transform="translate(329.000000, 185.000000)">
                <path
                  d="M17.2077147,4.29289322 L19.329035,6.41421356 C19.7195593,6.80473785 19.7195593,7.43790283 19.329035,7.82842712 L9.42954011,17.7279221 L5.89400621,14.1923882 L15.7935011,4.29289322 C16.1840254,3.90236893 16.8171904,3.90236893 17.2077147,4.29289322 Z M16.5006079,6.41421356 L8.72243333,14.1923882 L9.42954011,14.8994949 L17.2077147,7.12132034 L16.5006079,6.41421356 Z M5.31649907,19.5703403 C5.11123226,19.6387625 4.88931034,19.6387625 4.68404354,19.5703403 C4.16010022,19.3956925 3.87694023,18.8293725 4.051588,18.3054292 L5.18689943,14.8994949 L8.72243333,18.4350288 L5.31649907,19.5703403 Z"
                  id="pen"
                />
              </g>
            </g>
          </g>
        </svg>

        <DeleteCovidQuestion deleteQuestion={deleteQuestion} index={index} />
      </Box>

      {editState ? (
        <OpenQuestionForm
          actionState={'edit'}
          newQuestionIndex={index + 1}
          onCancelAction={onCancelQuestionForm}
          question={question}
        />
      ) : (
        <Box>
          <div className={styles.covidQuestionTextBlock}>
            <div
              className={styles.covidQuestionText}
              dangerouslySetInnerHTML={{ __html: question.questionText }}
              data-testid="covid-question-item-questionText"
            />
          </div>

          <div className={styles.covidQuestionTextBlock}>
            <h4>
              <Trans>
                Details
              </Trans>
            </h4>

            <div
              className={styles.covidQuestionText}
              dangerouslySetInnerHTML={{ __html: question.questionDetails ?? '' }}
              data-testid="covid-question-item-questionDetails"
            />
          </div>

          <Box display="flex">
            <h4>
              <Trans>
                What is the correct answer?
              </Trans>
            </h4>

            <div className={styles.covidQuestionActionButton} data-testid="covid-question-item-covidStatus">
              {covidStatus}
            </div>
          </Box>
        </Box>
      )}
    </div>
  );
}
