import React from "react";
import { DayPickerPopoverProps } from "./types";
import { Box, Popover } from "@mui/material";

import { DatePickerCalendar } from "../date-picker-calendar";

export const DayPickerPopover: React.FC<DayPickerPopoverProps> = (props) => {
  const { value, onChange, maxDate, minDate, timeZone = "UTC", ...rest } = props;

  return (
    <Popover {...rest}>
      <Box padding={2} width={420}>
        <DatePickerCalendar date={value} maxDate={maxDate} minDate={minDate} onChange={onChange} timeZone={timeZone} />
      </Box>
    </Popover>
  );
};
