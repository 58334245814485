interface Props {
  size?: number;
  color?: string;
  className?: string;
}

export default function ArrowRight({ className = "", color = "#1E1F7B" }: React.PropsWithChildren<Props>) {
  return (
    <svg className={className} height="18px" viewBox="0 0 10 18" width="10px">
    <title>9D8FA9D9-99F4-4B2F-A14A-9151F6995624</title>
    <g fill="none" fillRule="evenodd" id="Rooms-booking" stroke="none" strokeWidth="1">
        <g fill={color} id="1.3.0-Book-a-room-select-room-calendar" transform="translate(-270.000000, -176.000000)">
            <g id="Header" transform="translate(140.000000, 171.000000)">
                <g id="icons/arrow-left" transform="translate(130.000000, 5.000000)">
                    <path d="M8.29396103,1.29289322 C8.68448532,0.902368927 9.3176503,0.902368927 9.70817459,1.29289322 C10.0986989,1.68341751 10.0986989,2.31658249 9.70817459,2.70710678 L3.34421356,9.07106781 L9.70817459,15.4350288 C10.0986989,15.8255531 10.0986989,16.4587181 9.70817459,16.8492424 C9.3176503,17.2397667 8.68448532,17.2397667 8.29396103,16.8492424 L1.22289322,9.77817459 C0.832368927,9.3876503 0.832368927,8.75448532 1.22289322,8.36396103 L8.29396103,1.29289322 Z" transform="translate(5.465534, 9.071068) scale(-1, 1) translate(-5.465534, -9.071068) "></path>
                </g>
            </g>
        </g>
    </g>
</svg>  
  );
}
