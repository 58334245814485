import { fetchBaseQuery, retry } from "@reduxjs/toolkit/query";
import { ReduxStore } from "store/_legacy/Redux";

type PrepareAuthorizationHeadersF = NonNullable<Parameters<typeof fetchBaseQuery>[0]>["prepareHeaders"];

const prepareAuthorizationHeaders: PrepareAuthorizationHeadersF = (headers, { getState }) => {
  const state = getState() as ReduxStore;
  const { token, tokenGraphApi } = state.login || {};

  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }

  if (tokenGraphApi) {
    headers.set("Graph-Token", tokenGraphApi);
  }

  return headers;
};

export const baseQuery: typeof fetchBaseQuery = (params) => retry(
  fetchBaseQuery({ prepareHeaders: prepareAuthorizationHeaders, ...params }),
  {
    retryCondition: (response, _, { attempt }) => {
      return (typeof response.status === "number" && Math.floor(response.status / 100)) === 5 && attempt < 4;
    },
  },
);
