import L from 'leaflet';
import { FloorMapObject } from 'Admin/Store/floorMapDuck/models';
import { Rectangle, Tooltip } from 'react-leaflet';
import { selectDesksFiltered } from 'App/Store/Bookings/availableDesksDuck';
import { setCreateNewBookingData } from 'App/Store/Bookings/createNewBookingDuck';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTypedSelector } from 'store/_legacy/Redux/store';
import styles from './styles.module.scss';
import { DeskStatusEnum } from "../../../../../../Admin/Store/floorMapDuck/desk/models";
import { BookingsStateNeighbor, bookingsSlice } from 'store';
import { darkBlue } from 'theme/_legacy/variables';
import { Box, Popover, Typography } from '@mui/material';
import { TogetherDesk, TogetherUser } from 'components';
import { t } from '@lingui/macro';
import { getUserGroupsForBooking, getUserIdForBooking } from 'App/Store/Users/executiveAssistant/helpers';

interface Props {
  desk: FloorMapObject;
  map: L.Map;
  neighbor?: BookingsStateNeighbor;
}

/**
 * Desk Drawing
 *
 * This component handles deletion and edition internally.
 *
 * React Leaflet Draw handles it's own states, so this component syncs them with internal states.
 */
export default function DeskObjet({ desk, neighbor, map }: Props) {
  const { coordinatesInLatLngBounds, id, name, status } = desk;
  const dispatch = useDispatch();
  const [rectangle, setRectangle] = useState<L.Rectangle>();
  const [anchorEl, setAnchorEl] = useState<Element>();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const { adminFloorMap, availableDesks, createNewBooking, executiveAssistant, profile } = useTypedSelector(state => state);
  const { deskId } = createNewBooking;
  const isActive = deskId === id;
  const isBooked = availableDesks.availableDesks[id]?.isBooked;
  const selectedUserId = getUserIdForBooking(profile, executiveAssistant);
  const selectedUserGroups = getUserGroupsForBooking(profile, executiveAssistant);
  const hasOwner = desk?.owners?.length && !desk.owners.some(({ id }) => id === selectedUserId);
  const notInGroup = desk?.groups?.length 
    && selectedUserGroups
    && !selectedUserGroups.some((groupId) => desk?.groups?.some(({ id }) => id === groupId));
  const isHighLighted = availableDesks.highlightNeighbourDeskId === id;
  const desksFiltered = selectDesksFiltered({
    availableDesksState: availableDesks,
    filters: createNewBooking.filters,
    floorMapState: adminFloorMap,
  });
  const isDeskOnFiltered = Boolean(desksFiltered.find(desk => desk.id === id));
  const isCreateNewBooking = location.pathname.includes('/create-new-booking');
  const isNeighborDesk = neighbor && neighbor.deskId === desk.id;

  useEffect(() => {
    if (rectangle) {
      setAnchorEl(rectangle.getElement());
    }
  }, [rectangle]);

  useEffect(() => {
    if (isNeighborDesk) {
      setTimeout(() => setIsPopoverOpen(true), 500);

      if (rectangle) {
        rectangle.bringToFront();
        map.fitBounds(rectangle.getBounds(), { padding: new L.Point(300, 300), duration: 1000 });
      }
    } else {
      if (isPopoverOpen) {
        setIsPopoverOpen(false);
      }
    }
  }, [isNeighborDesk, rectangle]);

  // Returns desk color depending on it's status and active state
  const getDeskColor = (): string => {
    let color = isActive ? '#3f8627' : desk.line;
    const unAvailableStatuses = [DeskStatusEnum.Unavailable, DeskStatusEnum.Deleted, DeskStatusEnum.DeleteInProgress];

    if (isNeighborDesk) {
      color = darkBlue;
    } else {
      if (status === DeskStatusEnum.ApprovalRequired) {
        if (isActive) {
          color = '#de5e33';
        } else {
          color = '#fd855c';
        }
      } else if (unAvailableStatuses.includes(status)) {
        if (isActive) {
          color = '#a50707';
        } else {
          color = '#ef0000';
        }
      }

      if (hasOwner || notInGroup) {
        color = "#a3a3a3";
      }
  
      if (isBooked) {
        if (isActive) {
          color = '#a50707';
        } else {
          color = '#ef0000';
        }
      }
    }

    return `${color}${!isDeskOnFiltered ? '38' : ''}`;
  };

  // Set as selectedDesk
  const onClick = () => {
    if ([DeskStatusEnum.Available, DeskStatusEnum.ApprovalRequired].includes(status) && isCreateNewBooking && !isBooked) {
      if (typeof desk.id === 'string') {
        dispatch(setCreateNewBookingData({ deskId: desk.id }));
      }
    }
  };

  const arrayOfCoordinatesInLatLng = coordinatesInLatLngBounds.map(coordinate => L.latLng(coordinate.lat, coordinate.lng));
  const bounds: L.LatLngBoundsExpression = L.latLngBounds(arrayOfCoordinatesInLatLng);
  const color = getDeskColor();

  return (
    <>
      {isNeighborDesk ? (
        <>
          <Rectangle
            bounds={bounds.pad(0.22)}
            className={styles.desk}
            pathOptions={{
              fillColor: color,
              fillOpacity: 0.5,
              lineJoin: "round",
              weight: 0,
            }}
          />
          <Popover
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            onClose={() => dispatch(bookingsSlice.actions.setCurrentNeighbor(undefined))}
            open={!!anchorEl && isNeighborDesk && isPopoverOpen}
            sx={{ marginTop: 2 }}
            transformOrigin={{ horizontal: "center", vertical: "top" }}
          >
            <Box display="flex" key={id} padding={2}>
              <Box
                alignItems="center"
                bgcolor={"#f5f5f5"}
                borderRadius={2}
                display="flex"
                height={70}
                justifyContent="center"
                marginRight="10px"
                width={70}
              >
                {neighbor?.userProfilePictureUrl ? (
                  <img height="100%" src={neighbor.userProfilePictureUrl} style={{ borderRadius: 8 }} width="100%" />
                ) : (
                  <TogetherUser fill={"#616161"} stroke={"#616161"} sx={{ width: 32 }} />
                )}
              </Box>
              <Box alignItems="flex-start" display="flex" flexDirection="column" height={70} justifyContent="space-between">
                <Box>
                  <Typography fontSize={14}>{neighbor?.userName}</Typography>
                  <Typography color={"#616161"} fontSize={14}>
                    {`${neighbor?.floorName}, ${neighbor?.startTime} - ${neighbor?.endTime}`}
                  </Typography>
                </Box>
                <Box alignItems="center" bgcolor={"#f5f5f5"} borderRadius={2} display="flex" paddingX={1} paddingY={0.2}>
                  <TogetherDesk stroke={"#616161"} sx={{ width: 16, marginRight: 0.5 }} />
                  <Typography color={"#616161"} fontSize={14}>{neighbor?.deskName}</Typography>
                </Box>
              </Box>
            </Box>
          </Popover>
        </>
      ) : undefined}
      <Rectangle
        bounds={bounds}
        className={styles.desk}
        eventHandlers={{
          click: isNeighborDesk || hasOwner || notInGroup ? () => {} : onClick,
        }}
        pathOptions={{
          color: isNeighborDesk ? "#FFF" : color,
          fillColor: color,
          fillOpacity: isHighLighted || isNeighborDesk ? 1 : 0.2,
          weight: isNeighborDesk ? 1 : 3,
        }}
        ref={(ref) => ref && setRectangle(ref)}
      >
        {isNeighborDesk ? <Tooltip sticky>{name}</Tooltip> : undefined}
        {(hasOwner || notInGroup) && !isBooked ? <Tooltip sticky>{t`Sorry, you can't book this desk`}</Tooltip> : undefined}
      </Rectangle>
    </>
  );
}
