import Box from 'components/_legacy/Box';
import Divider from 'components/_legacy/Divider';
import Select from 'components/_legacy/Select';
import styles from './styles.module.scss';
import Text from 'components/_legacy/Text';
import TextField from 'components/_legacy/TextField';
import { AttributeModel } from 'Admin/Store/attributes/models';
import {FloorMapObject} from 'Admin/Store/floorMapDuck/models';
import { regexEmail } from 'Functions/helpers/regex';
import { selectIsDeskNameValid, updateDesk } from 'Admin/Store/floorMapDuck';
import { Trans, t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTypedSelector } from 'store/_legacy/Redux/store';
import DeskOwnerMultiSelect from "./DeskOwnerMultiSelect";
import DeskGroupMultiSelect from "./DeskGroupMultiSelect";
import Amenities from "../Components/Amenities";
import { DeskStatusEnum } from "../../../../../Store/floorMapDuck/desk/models";
import { DESK_STATUS_TO_EDIT } from "../../../../../Store/floorMapDuck/desk";

/**
 * Change desk details, delete desk, and select amenities (resources).
 * 
 * reference: https://projects.invisionapp.com/d/main?origin=v7#/console/21248637/449340369/inspect?scrollOffset=2540
 * 
 * Owned by: Bruno
 */

interface Props {
  desk: FloorMapObject;
}

export default function DeskDetails(props: Props) {
  const dispatch = useDispatch();
  const { adminFloorMap, adminAttributes: { attributesDesk } } = useTypedSelector(state => state);
  const { desk } = props;

  const { approvers, id, name, resourceIds, status } = desk;

  const [deskNameError, setDeskNameError] = useState(false);
  const [deskNameState, setDeskNameState] = useState(name);

  // Sync desk name on redux and on state
  useEffect(() => {
    setDeskNameState(name);
  }, [name]);

  const [approver1, setApprover1] = useState(approvers?.approver1.email ?? '');
  const [approver2, setApprover2] = useState(approvers?.approver2.email ?? '');
  const [approver1Error, setApprover1Error] = useState(false);
  const [approver2Error, setApprover2Error] = useState(false);

  const handleChangeDeskStatus = (event: any) => {
    dispatch(updateDesk({
      deskId: typeof id === 'string' ? id : '',
      updateDeskProperties: {
        status: event.target.value,
      },
    }));
  };

  const handleChangeResources = (value: boolean, attribute: AttributeModel) => {
    const newResources = { ...resourceIds };

    if (value === true) {
      newResources[attribute.id] = attribute.id;
    } else {
      delete newResources[attribute.id];
    }

    dispatch(updateDesk({
      deskId: typeof id === 'string' ? id : '',
      updateDeskProperties: {
        resources: newResources,
      },
    }));
  };

  /**
   * Updates desk name on state and check if it's valid
   */
  const handleChangeDeskName = (event: any) => {
    // Always update the desk name
    setDeskNameState(event.target.value);
    // But also check if it's valid and don't update if it's not
    // eg: The type check is to satisfy TypeScript. See comment on handleUpdateDeskName
    setDeskNameError(!selectIsDeskNameValid(adminFloorMap, event.target.value, typeof id === 'string' ? id : ''));
  };

  /**
   * Update desk name on redux state
   */
  const handleUpdateDeskName = () => {
    // eg: The type check is to satisfy TypeScript, since sections can have id's as number and they share
    // the same model, but not actually necessary since the id of a desk will always be a string
    if (!deskNameError && name !== deskNameState && typeof id === 'string') {
      dispatch(updateDesk({
        deskId: id,
        updateDeskProperties: {
          name: deskNameState,
        },
      }));
    }
  };

  // Validates the value to email and update redux if it's valid
  const handleUpdateApprover = (approver: 'approver1' | 'approver2', approverEmail: string) => {
    const isValid = new RegExp(regexEmail).test(approverEmail);

    if (approver === 'approver1') {
      setApprover1Error(!isValid);
    } else if (approver === 'approver2') {
      setApprover2Error(!isValid);
    }

    const newApprovers = approvers ? { ...approvers } : { approver1: { email: '' }, approver2: { email: '' } };

    if (newApprovers[approver]) {
      newApprovers[approver].email = approverEmail;
    } else {
      newApprovers[approver] = { email: approverEmail };
    }

    dispatch(updateDesk({
      deskId: typeof id === 'string' ? id : '',
      updateDeskProperties: {
        approvers: newApprovers,
      },
    }));
  };

  return (
    <Box className={styles.deskDetails}>
      <Text size="xlg" weight="semi-bold">
        <Trans>Desk details</Trans>
      </Text>

      <Box marginTop={16}>
        <Box marginBottom={22}>
          <Box className={styles.detailsItem}>
            <Text color="gray" size="md">
              <Trans>Desk name:</Trans>
            </Text>

            <Text color="gray" size="md">
              <TextField
                classes={{ input: styles.textFieldInput }}
                error={deskNameError}
                helperText={deskNameError ? t`This name is already in use in this floor` : ''}
                id="desk-name"
                onBlur={handleUpdateDeskName}
                onChange={handleChangeDeskName}
                value={deskNameState}
              />
            </Text>
          </Box>

          <Box className={styles.detailsItem}>
            <Text color="gray" size="md">
              Access for Group:
            </Text>
            <DeskGroupMultiSelect desk={desk} />
          </Box>

          <Box className={styles.detailsItem}>
            <Text color="gray" size="md">
              Access for Users:
            </Text>
            <DeskOwnerMultiSelect desk={desk} />
          </Box>

          <Box marginTop={3} width={'100%'}>
            <Select
              classes={{
                wrapper: styles.detailsItem,
                materialSelect: styles.selectFullWidth,
                materialLabel: styles.selectLabel,
              }}
              id="desk-status"
              items={DESK_STATUS_TO_EDIT}
              label={t`Status:`}
              labelColor="gray"
              onChange={handleChangeDeskStatus}
              value={status}
            />
          </Box>

          {status === DeskStatusEnum.ApprovalRequired &&
            <Box marginTop={1}>
              <Box className={styles.detailsItem}>
                <Text color="gray" size="md">
                  <Trans>Approver 1:</Trans>
                </Text>

                <TextField
                  classes={{ input: styles.textFieldInput }}
                  error={approver1Error}
                  id="approver-1"
                  onBlur={() => handleUpdateApprover('approver1', approver1)}
                  onChange={(event) => setApprover1(event.target.value)}
                  placeholder={t`Approver 1 e-mail`}
                  type="email"
                  value={approver1}
                />
              </Box>

              <Box className={styles.detailsItem}>
                <Text color="gray" size="md">
                  <Trans>Approver 2:</Trans>
                </Text>

                <TextField
                  classes={{ input: styles.textFieldInput }}
                  error={approver2 ? approver2Error : false}
                  id="approver-2"
                  onBlur={() => handleUpdateApprover('approver2', approver2)}
                  onChange={(event) => setApprover2(event.target.value)}
                  placeholder={t`Approver 2 e-mail`}
                  type="email"
                  value={approver2}
                />
              </Box>
            </Box>
          }
        </Box>

        <Divider />

        <Box marginTop={20}>
          <Amenities attributes={attributesDesk} onUpdate={handleChangeResources} selected={desk.resourceIds} />
        </Box>
      </Box>
    </Box>
  );
}
