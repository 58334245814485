import { useIsAuthenticated } from "@azure/msal-react";
import { useTypedSelector } from "store/_legacy/Redux/store";

export const useIsAuthenticatedInAllScopes = (): boolean => {
  const isAuthenticated = useIsAuthenticated();
  const { tokenExpiration, tokenGraphApiExpiration } = useTypedSelector((state) => state.login);
  const now = new Date();

  const parsedTokenExpiration = tokenExpiration
    ? tokenExpiration instanceof Date ? tokenExpiration : new Date(tokenExpiration)
    : undefined;
  const parsedTokenGraphApiExpiration = tokenGraphApiExpiration
    ? tokenGraphApiExpiration instanceof Date ? tokenGraphApiExpiration : new Date(tokenGraphApiExpiration)
    : undefined;
  const isAuthenticatedWithValidTokens = isAuthenticated
    && !!parsedTokenExpiration
    && !!parsedTokenGraphApiExpiration
    && parsedTokenExpiration.getTime() > now.getTime()
    && parsedTokenGraphApiExpiration.getTime() > now.getTime();

  if (isAuthenticatedWithValidTokens) {
    return true;
  } else {
    return false;
  }
};