import {useDispatch} from "react-redux";
import {useTypedSelector} from "store/_legacy/Redux/store";
import Popup from "reactjs-popup";
import {Trans} from "@lingui/macro";
import moment from "moment";

import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ButtonBase from "@material-ui/core/ButtonBase";
import Text from "components/_legacy/Text";
import Box from "components/_legacy/Box";
import {FloorMapObject} from "../../../../Store/floorMapDuck/models";
import {deleteDesk} from "../../../../Store/floorMapDuck";
import {checkDeskReservation} from "../../../../Store/floorMapApiRequestsDuck";
import DeskDeleteModal from "../../../FloorMap/Map/MapDrawing/Components/DeleteDeskForm";

interface DeskDeletePopupProps {
  desk: FloorMapObject;
}

export default function DeskDeletePopup(props: DeskDeletePopupProps) {
  const dispatch = useDispatch();
  const { config, adminFloorMap } = useTypedSelector(state => state);
  const { desk } = props;
  const { deskToDelete, deskReservationChecking, id: floorId } = adminFloorMap;

  const onDeleteDesk = () => {
    dispatch(checkDeskReservation({ // initiate delete desk by checking reservations, it also open delete modal
      desk,
      date: moment().format(), // check reservations from current time
    }));
  };

  const onDeleteClearData = () => {
    if (typeof desk.id === 'string') {
      dispatch(deleteDesk(desk.id));
    }
  };

  return (
    <>
      <Popup
        closeOnDocumentClick
        contentStyle={{marginLeft: 27, width: 230}}
        position="bottom right"
        trigger={
          <IconButton style={{padding: 0}}>
            <MoreVertIcon style={{color: config.theme.primary, fontSize: 22}}/>
          </IconButton>
        }
      >
        <Box borderStyle="shadow" className="popover-content">
          <ButtonBase
            className="popover-item-button"
            disabled={deskReservationChecking}
            onClick={onDeleteDesk}
          >
            <DeleteOutlineIcon style={{color: config.theme.primary, fontSize: 20}}/>

            <Box marginStart={5}>
              <Text color={'blue'} size="md">
                <Trans>
                  Delete Desk
                </Trans>
              </Text>
            </Box>
          </ButtonBase>
        </Box>
      </Popup>
      {/* confirmation for delete desk with reservation canceling */}
      {desk.id === deskToDelete?.id &&
        <DeskDeleteModal floorId={floorId} onDelete={onDeleteClearData} />
      }
    </>
  );
}
