import Box from 'components/_legacy/Box';
import Button from 'components/_legacy/Button';
import CovidScheduleTimes from './CovidScheduleTimes';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import generalCovidStyles from '../../CovidQuestions/styles.module.scss';
import styles from './styles.module.scss';
import Switch from '@material-ui/core/Switch';
import { Trans } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTypedSelector } from 'store/_legacy/Redux/store';
import {
  editCovidSchedule,
  getCovidSchedule,
  setCovidQuestionsData,
} from '../../../Store/covidQuestions';

export default function CovidSchedule() {
  const dispatch = useDispatch();
  const { locationId } = useParams<{ locationId: string }>();
  const { adminCovidQuestions } = useTypedSelector(state => state);
  const { covidSchedule, covidQuestions } = adminCovidQuestions;

  useEffect(() => {
    // get updated data on location change
    if (locationId) {
      dispatch(getCovidSchedule({ locationId }));
    }
  }, [locationId]);

  useEffect(() => {
    // get updated data if no covidSchedule
    if (locationId && covidSchedule && !covidSchedule.id && covidQuestions.length) {
      dispatch(getCovidSchedule({ locationId }));
    }
  }, [locationId, covidQuestions]);

  const onUpdateScheduleActive = (event: any) => {
    if (!covidQuestions.length) {
      dispatch(setCovidQuestionsData({ error: 'Need to have at least 1 question to enable questionnaire schedule' }));
      return;
    }

    const updatedSchedule = {
      ...covidSchedule,
      isActive: event.target.checked,
    };

    dispatch(editCovidSchedule({ locationId, schedule: updatedSchedule }));
  };

  const onResetScheduleTimes = () => {
    const updatedSchedule = {
      ...covidSchedule,
      startTime: 4,
      reminderTime: 15,
      cancellationTime: 5,
    };

    dispatch(editCovidSchedule({ locationId, schedule: updatedSchedule }));
  };

  return (
    <Box dataTestId="covid-schedule">
      <Box className={generalCovidStyles.covidQuestionsAction}>
        <Box
          alignItems="center"
          className={styles.covidScheduleHeader}
          display="flex"
          justifyContent="between"
        >
          <Box display="flex">
            <Box alignItems="center" display="flex">
              <svg height="30px" version="1.1" viewBox="0 0 30 30" width="30px">
                <g fill="none" fillRule="evenodd" id="A-locations" stroke="none" strokeWidth="1">
                  <g id="A-Location-covidquestions-add" transform="translate(-1030.000000, -135.000000)">
                    <g id="icons/datetimewhite" transform="translate(1030.000000, 135.000000)">
                      <rect fill="#F4F4F4" height="30" id="Rectangle" rx="8" width="30" x="0" y="0"></rect>
                      <path
                        d="M19,7 C20.0543618,7 20.9181651,7.81587779 20.9945143,8.85073766 L21,9 L21.0008689,12.5285648 C22.2278632,13.6271816 23,15.2234197 23,17 C23,20.3137085 20.3137085,23 17,23 C15.2234197,23 13.6271816,22.2278632 12.5285648,21.0008689 L9,21 C7.9456382,21 7.08183488,20.1841222 7.00548574,19.1492623 L7,19 L7,9 C7,7.9456382 7.81587779,7.08183488 8.85073766,7.00548574 L9,7 L19,7 Z M17,13 C14.790861,13 13,14.790861 13,17 C13,19.209139 14.790861,21 17,21 C19.209139,21 21,19.209139 21,17 C21,14.790861 19.209139,13 17,13 Z M19,9 L9,9 L9,19 L11.3416539,19.0006859 C11.1203964,18.3749342 11,17.7015272 11,17 C11,13.6862915 13.6862915,11 17,11 C17.7015272,11 18.3749342,11.1203964 19.0006859,11.3416539 L19,9 Z M17,14 C17.5522847,14 18,14.4477153 18,15 L18,16 L19,16 C19.5522847,16 20,16.4477153 20,17 C20,17.5522847 19.5522847,18 19,18 L17,18 L17,18 C16.4477153,18 16,17.5522847 16,17 L16,15 C16,14.4477153 16.4477153,14 17,14 Z M12,10 C12.5522847,10 13,10.4477153 13,11 C13,11.5522847 12.5522847,12 12,12 L11,12 C10.4477153,12 10,11.5522847 10,11 C10,10.4477153 10.4477153,10 11,10 L12,10 Z"
                        fill="#5E5E5E"
                        fillRule="nonzero"
                        id="Combined-Shape"
                      >
                      </path>
                    </g>
                  </g>
                </g>
              </svg>

              <h3>
                <Trans>Questionnaire schedule</Trans>
              </h3>

              <FormControlLabel
                control={
                  <Switch
                    checked={covidSchedule?.isActive}
                    className="switcher switcher--default"
                    data-testid="covid-schedule-toggle"
                    name={'test'}
                    onChange={(e) => onUpdateScheduleActive(e)}
                  />
                }
                label=""
              />
            </Box>
          </Box>
          <Button
            className={generalCovidStyles.covidScheduleReset}
            data-testid="covid-schedule-reset"
            disabled={!covidSchedule?.isActive}
            noPaddingX
            onClick={onResetScheduleTimes}
            size="xs"
            type="clear"
            withShadow={true}
          >
            <Trans>Reset</Trans>
          </Button>
        </Box>
      </Box>
      <CovidScheduleTimes />
    </Box>
  );
}
