import { CREATE_VISIT_SUCCESS, CreateVisitSuccess } from '../bookingDuck';
import { CreateNewVisitModel } from './models';
import {
  UPLOAD_COVID_CERTIFICATE,
  UPLOAD_COVID_CERTIFICATE_FAIL,
  UPLOAD_COVID_CERTIFICATE_SUCCESS,
  UploadCovidCertificate,
  UploadCovidCertificateFail,
  UploadCovidCertificateSuccess,
} from 'App/Store/CovidSertificate';

const CLEAR_CREATE_NEW_VISIT_DATA = 'CLEAR_CREATE_NEW_VISIT_DATA';
const CLEAR_CREATE_NEW_VISIT_DATA_IF_VISIT_CREATED = 'CLEAR_CREATE_NEW_VISIT_DATA_IF_VISIT_CREATED';
const SET_CREATE_NEW_VISIT_DATA = 'SET_CREATE_NEW_VISIT_DATA';

interface ClearCreateNewVisitData {
  type: typeof CLEAR_CREATE_NEW_VISIT_DATA;
}

interface ClearCreateNewVisitDataIfVisitCreated {
  type: typeof CLEAR_CREATE_NEW_VISIT_DATA_IF_VISIT_CREATED;
}

interface SetCreateNewVisitData {
  type: typeof SET_CREATE_NEW_VISIT_DATA;
  payload: CreateNewVisitModel;
}

type Actions =
  | ClearCreateNewVisitData
  | ClearCreateNewVisitDataIfVisitCreated
  | CreateVisitSuccess
  | SetCreateNewVisitData
  | UploadCovidCertificate
  | UploadCovidCertificateFail
  | UploadCovidCertificateSuccess;

export interface State extends CreateNewVisitModel {
  error: string;
  loading: boolean;
  covidCertificateUploaded: boolean;
  covidCertificateUploading: boolean;
  visitCreated: boolean;
}

const tomorrow = new Date(new Date().getTime() + 86400000);
const initialState: State = {
  error: '',
  loading: false,
  covidCertificateUploaded: false,
  covidCertificateUploading: false,
  visitCreated: false,
  dateFrom: tomorrow,
  dateTo: tomorrow,
  floorId: '',
  locationId: '',
  timeFrom: "09:00",
  timeTo: "11:00",
};

export default function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case CLEAR_CREATE_NEW_VISIT_DATA: {
      return {
        ...initialState,
      };
    }

    case CLEAR_CREATE_NEW_VISIT_DATA_IF_VISIT_CREATED: {
      const newState = state.visitCreated ? { ...initialState } : state;

      return newState;
    }

    case CREATE_VISIT_SUCCESS: {
      return {
        ...state,
        error: '',
        loading: false,
        visitCreated: true,
      };
    }

    case SET_CREATE_NEW_VISIT_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case UPLOAD_COVID_CERTIFICATE: {
      return {
        ...state,
        covidCertificateUploaded: false,
        covidCertificateUploading: true,
      };
    }
    case UPLOAD_COVID_CERTIFICATE_FAIL: {
      return {
        ...state,
        covidCertificateUploading: false,
      };
    }
    case UPLOAD_COVID_CERTIFICATE_SUCCESS: {
      return {
        ...state,
        covidCertificateUploaded: true,
        covidCertificateUploading: false,
      };
    }

    default:
      return state;
  }
}

export function clearCreateNewVisitDataIfVisitCreated(): ClearCreateNewVisitDataIfVisitCreated {
  return {
    type: CLEAR_CREATE_NEW_VISIT_DATA_IF_VISIT_CREATED,
  };
}

export function setCreateNewVisitData(data: CreateNewVisitModel): SetCreateNewVisitData {
  return {
    type: SET_CREATE_NEW_VISIT_DATA,
    payload: data,
  };
}

/**
 * Receives the number of the step and the redux state, and returns if that
 * step is completed.
 */
export function isStepCompleted(number: number, state: State): boolean {
  let isCompleted = false;

  switch (number) {
    case 1: {
      isCompleted = !!state.locationId;
      break;
    }

    case 2: {
      isCompleted = !!state.floorId;
      break;
    }

    default:
      break;
  }

  return isCompleted;
}

/**
 * Receives the number of the step and the redux state, and returns if that
 * step should be disabled. This means, if the step before is completed.
 */
export function isStepDisabled(number: number, state: State, disableFloorPage: boolean): boolean {
  let isDisabled = false;

  switch (number) {
    case 2: {
      isDisabled = !state.locationId || disableFloorPage;
      break;
    }

    case 3: {
      isDisabled = !state.floorId || !state.locationId || disableFloorPage;
      break;
    }

    default:
      break;
  }

  return isDisabled;
}
