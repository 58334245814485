import Api from 'store/_legacy/Services/Api';
import {call, put, takeLatest} from 'redux-saga/effects';
import {
  CANCEL_RESERVATIONS_BY_DESK,
  CANCEL_RESERVATIONS_BY_DESK_FAILURE,
  CANCEL_RESERVATIONS_BY_DESK_SUCCESS,
  CHECK_DESK_RESERVATIONS,
  CHECK_DESK_RESERVATIONS_FAILURE,
  CHECK_DESK_RESERVATIONS_SUCCESS,
  CancelReservationsByDesk,
  CheckDeskReservation,
  DELETE_DESK_FROM_FLOOR,
  DELETE_DESK_FROM_FLOOR_FAILURE,
  DELETE_DESK_FROM_FLOOR_SUCCESS,
  DELETE_MEETING_FROM_FLOOR,
  DELETE_MEETING_FROM_FLOOR_FAILURE,
  DELETE_MEETING_FROM_FLOOR_SUCCESS,
  DELETE_SECTION_FROM_FLOOR,
  DELETE_SECTION_FROM_FLOOR_FAILURE,
  DELETE_SECTION_FROM_FLOOR_SUCCESS,
  DeleteDeskFromFloor,
  DeleteDeskFromFloorSuccess,
  DeleteMeetingFromFloor,
  DeleteMeetingFromFloorSuccess,
  DeleteSectionFromFloor,
  DeleteSectionFromFloorSuccess,
  EDIT_MAP_FILE,
  EDIT_MAP_FILE_FAILURE,
  EDIT_MAP_FILE_SUCCESS,
  EditMapFile,
  GET_FLOOR_SCHEMA,
  GET_FLOOR_SCHEMA_FAILURE,
  GET_FLOOR_SCHEMA_SUCCESS,
  GetFloorSchema,
  SAVE_MAP_DRAWINGS,
  SAVE_MAP_DRAWINGS_FAILURE,
  SAVE_MAP_DRAWINGS_SUCCESS,
  SAVE_MAP_FILE,
  SAVE_MAP_FILE_FAILURE,
  SAVE_MAP_FILE_SUCCESS,
  SaveMapDrawings,
  SaveMapFile,
  getFloorSchema,
} from './index';
import {matchPath} from "react-router-dom";

function* getFloorSchemaSaga(action: GetFloorSchema): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: GET_FLOOR_SCHEMA_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_FLOOR_SCHEMA_FAILURE, payload: e });
  }
}

function* saveMapFileSaga(action: SaveMapFile): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: SAVE_MAP_FILE_SUCCESS, payload });
  } catch (e) {
    yield put({ type: SAVE_MAP_FILE_FAILURE, payload: e });
  }
}

function* editMapFileSaga(action: EditMapFile): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: EDIT_MAP_FILE_SUCCESS, payload });
  } catch (e) {
    yield put({ type: EDIT_MAP_FILE_FAILURE, payload: e });
  }
}

function* saveMapDrawingsSaga(action: SaveMapDrawings): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: SAVE_MAP_DRAWINGS_SUCCESS, payload });
    
    if (action.payload?.additionalData?.floorId) {
      yield put(getFloorSchema({ floorId: action.payload.additionalData.floorId, showOwners: true, includeRooms: true }));
    }

  } catch (e) {
    yield put({ type: SAVE_MAP_DRAWINGS_FAILURE, payload: e });
  }
}

function* deleteSectionFromFloorSaga(action: DeleteSectionFromFloor): any {
  try {
    // temp adding sectionId via params (waiting for BE update)
    const match = matchPath<{ sectionId: string }>(action.payload.request.url, {
      path: "/api/locations/:floorId/section/:sectionId",
    });

    const payload = yield call(Api, action);

    const success: DeleteSectionFromFloorSuccess = {
      type: DELETE_SECTION_FROM_FLOOR_SUCCESS,
      payload,
      sectionId: match?.params?.sectionId ? +match?.params?.sectionId : 1,
    };

    yield put(success);
  } catch (e) {
    yield put({ type: DELETE_SECTION_FROM_FLOOR_FAILURE, payload: e });
  }
}

function* deleteMeetingFromFloorSaga(action: DeleteMeetingFromFloor): any {
  try {
    // temp adding sectionId via params (waiting for BE update)
    const match = matchPath<{ meetingId: string }>(action.payload.request.url, {
      path: "/api/locations/:floorId/room/:meetingId",
    });

    const payload = yield call(Api, action);

    const success: DeleteMeetingFromFloorSuccess = {
      type: DELETE_MEETING_FROM_FLOOR_SUCCESS,
      payload,
      meetingId: match?.params?.meetingId ? +match?.params?.meetingId : 1,
    };

    yield put(success);
  } catch (e) {
    yield put({ type: DELETE_MEETING_FROM_FLOOR_FAILURE, payload: e });
  }
}

function* deleteDeskFromFloorSaga(action: DeleteDeskFromFloor): any {
  try {
    // temp adding deskId via params (waiting for BE update)
    const match = matchPath<{ deskId: string }>(action.payload.request.url, {
      path: "/api/desks/:deskId/floor/:floorId",
    });

    const payload = yield call(Api, action);

    const success: DeleteDeskFromFloorSuccess = {
      type: DELETE_DESK_FROM_FLOOR_SUCCESS,
      payload,
      deskId: match?.params.deskId ?? '',
    };

    yield put(success);
  } catch (e) {
    yield put({ type: DELETE_DESK_FROM_FLOOR_FAILURE, payload: e });
  }
}

function* checkDeskReservationSaga(action: CheckDeskReservation): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: CHECK_DESK_RESERVATIONS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: CHECK_DESK_RESERVATIONS_FAILURE, payload: e });
  }
}

function* cancelReservationsByDeskSaga(action: CancelReservationsByDesk): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: CANCEL_RESERVATIONS_BY_DESK_SUCCESS, payload });
  } catch (e) {
    yield put({ type: CANCEL_RESERVATIONS_BY_DESK_FAILURE, payload: e });
  }
}

export default [
  takeLatest(SAVE_MAP_DRAWINGS, saveMapDrawingsSaga),
  takeLatest(GET_FLOOR_SCHEMA, getFloorSchemaSaga),
  takeLatest(SAVE_MAP_FILE, saveMapFileSaga),
  takeLatest(EDIT_MAP_FILE, editMapFileSaga),
  takeLatest(DELETE_SECTION_FROM_FLOOR, deleteSectionFromFloorSaga),
  takeLatest(DELETE_MEETING_FROM_FLOOR, deleteMeetingFromFloorSaga),
  takeLatest(DELETE_DESK_FROM_FLOOR, deleteDeskFromFloorSaga),
  takeLatest(CHECK_DESK_RESERVATIONS, checkDeskReservationSaga),
  takeLatest(CANCEL_RESERVATIONS_BY_DESK, cancelReservationsByDeskSaga),
];
