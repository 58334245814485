import { GuardFunction } from "components/_legacy/ProtectedRoute/models";
import { useTypedSelector } from "store/_legacy/Redux/store";

const DeskGuard: GuardFunction = () => {
  const { floorId } = useTypedSelector(state => state.createNewVisit);

  if (!floorId) {
    return false;
  }
  
  return true;
};

export default DeskGuard;
