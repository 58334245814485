import Box from 'components/_legacy/Box';
import EmailTemplate from './EmailTemplate';
import { getEmailTemplates } from 'Admin/Store/emailTemplatesDuck';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTypedSelector } from 'store/_legacy/Redux/store';
import Skeleton from '@material-ui/lab/Skeleton';

/**
 * Configure Email Templates
 *
 * OwnedBy: Bruno
 */
export default function EmailTemplates() {
  const dispatch = useDispatch();
  const { locationId } = useParams<{ locationId: string }>();
  const { emailTemplates: emailTemplatesDuck } = useTypedSelector(state => state);

  const { emailTemplates } = emailTemplatesDuck;
  
  const [firstLoadCompleted, setFirstLoadCompleted] = useState(false);

  // @ts-ignore
  const emailTemplatesArray = emailTemplates[locationId] ? Object.keys(emailTemplates[locationId]).map(key => emailTemplates[locationId][key]) : [];

  useEffect(() => {
    dispatch(getEmailTemplates({ locationId }));
    setFirstLoadCompleted(true);
  }, [locationId]);

  return (
    <>
      {(firstLoadCompleted && !emailTemplatesDuck.loading) ? (
        <Box dataTestId="email-emplates">
          {emailTemplatesArray.map((emailTemplate, index) => {
            return (
              <EmailTemplate
                {...emailTemplate}
                key={emailTemplate.id + index}
                locationId={locationId}
              />
            );
          })}
        </Box>
      ) : null}

      {emailTemplatesDuck.loading ? (
        <Box height={200} marginTop={20}>
          <Skeleton height="100%" variant="rect" />
        </Box>
      ) : null}
    </>
  );
}
