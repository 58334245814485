import { KeyboardSensor, MouseSensor, SensorDescriptor, SensorOptions, useSensor, useSensors } from "@dnd-kit/core";
import React from "react";

const shouldHandleEvent = (element: HTMLElement | null): boolean => {
  let current = element;

  while (current) {
    if (current.dataset && current.dataset.noDnd) {
      return false;
    }
    current = current.parentElement;
  }

  return true;
};

class CustomMouseSensor extends MouseSensor {
  static activators = [
    {
      eventName: "onMouseDown" as const,
      handler: ({ nativeEvent }: React.MouseEvent) => shouldHandleEvent(nativeEvent.target as HTMLElement),
    },
  ]
}

class CustomKeyboardSensor extends KeyboardSensor {
  static activators = [
    {
      eventName: "onKeyDown" as const,
      handler: ({ nativeEvent: event }: React.KeyboardEvent) => shouldHandleEvent(event.target as HTMLElement),
    },
  ]
}

export const useCustomDndSensors = (): SensorDescriptor<SensorOptions>[] => {
  const mouseSensor = useSensor(CustomMouseSensor);
  const keyboardSensor = useSensor(CustomKeyboardSensor);
  const sensors = useSensors(mouseSensor, keyboardSensor);

  return sensors;
};
