import { t } from "@lingui/macro";
import { Box, Skeleton, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useLazyGetFeedQuery } from "store";
import { NewsFeedItem } from "./news-feed-item";

export const NewsFeed: React.FC = () => {
  const { palette, breakpoints } = useTheme();
  const xl = useMediaQuery(breakpoints.up("xl"));
  const [moreRef, loadMore] = useInView();
  const [page, setPage] = useState(1);
  const [triggerGetFeedQuery, getFeedQuery] = useLazyGetFeedQuery();

  useEffect(() => {
    setPage(1);
    triggerGetFeedQuery(
      {
        page,
        orderBy: ["desc:schedules.doneAt", "desc:createdAt"],
        include: ["author", "schedules", "thumbnail", "$extra.reactionsCount", "$extra.reactionsCodes", "$extra.commentsCount", "$extra.myReaction"],
      },
      true,
    );
  }, [page, triggerGetFeedQuery, setPage]);

  const { data: getFeedResponse, isLoading } = getFeedQuery;
  const { items: posts, links } = getFeedResponse || {};
  const hasMore = !!links?.next;

  useEffect(() => {
    if (loadMore && hasMore && !isLoading) {
      setPage((prev) => prev + 1);
    }
  }, [loadMore, hasMore, isLoading]);

  return (
    <Box alignItems="stretch" display="flex" flexDirection="column">
      {page === 1 && isLoading ? (
        <Box bgcolor={palette.grey[100]} borderRadius={2} p={2}>
          <Box display="flex" gap={1} mb={1}>
            <Skeleton height={18} sx={{ bgcolor: palette.grey[200] }} variant="rectangular" width={140} />
            <Skeleton height={18} sx={{ bgcolor: palette.grey[200] }} variant="rectangular" width={60} />
          </Box>
          <Skeleton height={380} sx={{ bgcolor: palette.grey[200], mb: xl ? 3 : 2 }} variant="rectangular" width="100%" />
          <Skeleton height={18} sx={{ bgcolor: palette.grey[200], mb: 2 }} variant="rectangular" width="50%" />
          <Skeleton height={16} sx={{ bgcolor: palette.grey[200], mb: 1 }} variant="rectangular" width="100%" />
          <Skeleton height={16} sx={{ bgcolor: palette.grey[200], mb: 1 }} variant="rectangular" width="100%" />
          <Skeleton height={16} sx={{ bgcolor: palette.grey[200], mb: 1 }} variant="rectangular" width="80%" />
        </Box>
      ) : (
        posts?.length === 0 ? (
          <Typography color={palette.grey[700]} fontSize={14} fontWeight="600" mt={2} textAlign="center">
            {t`No posts yet.`}
          </Typography>
        ) : (
          posts?.map((post, index) => {
            const isLast = index + 1 === posts.length; 

            return <NewsFeedItem key={post.id} mb={isLast ? 0 : 1} post={post} />;
          })
        )
      )}
      {hasMore ? <Skeleton height={420} ref={moreRef} variant="rectangular" /> : undefined}
    </Box>
  );
};
