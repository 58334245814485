import { useDispatch } from "react-redux";
import { useState } from "react";
import { useTypedSelector } from "store/_legacy/Redux/store";
import { Trans, t } from "@lingui/macro";
import styles from "./styles.module.scss";
import Button from "components/_legacy/Button";
import RoomManagementForm from "Admin/Pages/RoomsManagement/RoomManagementForm";
import TextField from "@material-ui/core/TextField";
import Box from "components/_legacy/Box";
import { setAdminFloorRoomsData } from "Admin/Store/roomsManagement";

export default function AddFloorRoomData() {
  const dispatch = useDispatch();
  const { filters } = useTypedSelector(state => state.roomsManagement);
  const [isOpenRoomManagementForm, setIsOpenRoomManagementForm] = useState(false);

  const onOpenRoomManagementForm = () => {
    setIsOpenRoomManagementForm(o => !o);
  };

  const onCloseRoomManagementForm = () => {
    setIsOpenRoomManagementForm(false);
  };

  const onSearchChange = (event: any) => {
    dispatch(setAdminFloorRoomsData({
      filters: {
        ...filters,
        search: event.target.value,
      },
    }));
  };

  return (
    <>
      <Box alignItems="center" display="flex" gap={10}>
        <TextField
          InputProps={{
            startAdornment: (
              <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px">
                <g fill="none" fillRule="evenodd" id="Symbols" stroke="none" strokeWidth="1">
                  <g fill="#5E5E5E" fillRule="nonzero" id="icons/search">
                    <g id="delete-white-copy-5">
                      <path d="M6.05025253,6.05025253 C8.78392257,3.31658249 13.2160774,3.31658249 15.9497475,6.05025253 C18.4434716,8.54397661 18.6623564,12.4511046 16.6064021,15.1929497 L20.1923882,18.7781746 C20.5829124,19.1686989 20.5829124,19.8018639 20.1923882,20.1923882 C19.8018639,20.5829124 19.1686989,20.5829124 18.7781746,20.1923882 L15.1929497,16.6064021 C12.4511046,18.6623564 8.54397661,18.4434716 6.05025253,15.9497475 C3.31658249,13.2160774 3.31658249,8.78392257 6.05025253,6.05025253 Z M14.5355339,7.46446609 C12.5829124,5.51184464 9.41708755,5.51184464 7.46446609,7.46446609 C5.51184464,9.41708755 5.51184464,12.5829124 7.46446609,14.5355339 C9.41708755,16.4881554 12.5829124,16.4881554 14.5355339,14.5355339 C16.4881554,12.5829124 16.4881554,9.41708755 14.5355339,7.46446609 Z" id="Combined-Shape"></path>
                    </g>
                  </g>
                </g>
              </svg>
            ),
          }}
          className={`input input--default input--search ${styles.searchInput}`}
          defaultValue={''}
          fullWidth
          onChange={onSearchChange}
          placeholder={t`Search by name`}
          variant="outlined"
        />
        <Button onClick={onOpenRoomManagementForm} size="sm">
          <Trans>Add Room</Trans>
        </Button>
      </Box>
      <RoomManagementForm
        actionState={'add'}
        onCancelAction={onCloseRoomManagementForm}
        open={isOpenRoomManagementForm}
      />
    </>
  );
}
