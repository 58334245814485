import { IAttendees } from "App/Store/Meetings/meetingAttendees/models";
import Text from "components/_legacy/Text";

interface Props {
  capacity?: number; // max count of users for room / if not set - do not show
  attendees?: IAttendees[]; // selected users for meeting
  externalAttendees?: string[]
}

export default function CapacityCount(props: Props) {
  const { attendees, capacity, externalAttendees } = props;
  const total = (attendees?.length || 0) + (externalAttendees?.length || 0);

  if (!total) {
    return null;
  }

  return (
    <>
      {capacity ?
        <Text>({total} / {capacity})</Text>
       : <Text>{total}</Text>
      }
    </>
  );
}
