import { RecurrenceOptions } from "types";
import { Frequency, Weekday, rrulestr } from "rrule";

const resolveWeekdays = (weekdays: number[] | Weekday[]): number[] => {
  const resolved: number[] = [];

  for (const weekday of weekdays) {
    if (weekday instanceof Weekday) {
      resolved.push(weekday.weekday);
    } else {
      resolved.push(weekday);
    }
  }

  return resolved;
};

export const rruleToRecurrenceOptions = (rrule?: string): RecurrenceOptions | undefined => {
  if (!rrule) {
    return undefined;
  }

  try {
    const { options } = rrulestr(rrule);
    const { freq, dtstart, until, interval, byweekday, bymonthday, bysetpos } = options;

    if (until) {
      switch (freq) {
        case Frequency.DAILY: {
          return {
            type: "daily",
            start: dtstart,
            end: until,
            interval: interval,
          };
        }
        case Frequency.WEEKLY: {
          return {
            type: "weekly",
            start: dtstart,
            end: until,
            interval: interval,
            weekdays: resolveWeekdays(byweekday),
          };
        }
        case Frequency.MONTHLY: {
          return {
            type: "monthly",
            start: dtstart,
            end: until,
            interval: interval,
            dayOfMonth: bymonthday?.[0],
            dayOfWeek: resolveWeekdays(byweekday)?.[0],
            index: bysetpos?.[0],
          };
        }
        default: {
          return undefined;
        }
      }
    } else {
      return undefined;
    }
  } catch {
    return undefined;
  }
};
