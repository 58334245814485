import Api from 'store/_legacy/Services/Api';
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import {
  GET_SETTINGS_MOBILE_DATA,
  GET_SETTINGS_MOBILE_DATA_FAIL,
  GET_SETTINGS_MOBILE_DATA_SUCCESS,
  GetSettingsMobileData,
  UPDATE_SETTINGS_MOBILE_DATA,
  UPDATE_SETTINGS_MOBILE_DATA_FAIL,
  UPDATE_SETTINGS_MOBILE_DATA_SUCCESS,
  UpdateSettingsMobileData,
} from "./index";

function* getSettingsMobileDataSaga(action: GetSettingsMobileData): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: GET_SETTINGS_MOBILE_DATA_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_SETTINGS_MOBILE_DATA_FAIL, payload: e });
  }
}

function* updateSettingsMobileDataSaga(action: UpdateSettingsMobileData): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: UPDATE_SETTINGS_MOBILE_DATA_SUCCESS, payload });
  } catch (e) {
    yield put({ type: UPDATE_SETTINGS_MOBILE_DATA_FAIL, payload: e });
  }
}

export default [
  takeLatest(GET_SETTINGS_MOBILE_DATA, getSettingsMobileDataSaga),
  takeLatest(UPDATE_SETTINGS_MOBILE_DATA, updateSettingsMobileDataSaga),
];
