import { MaterialThemeBlue, ThemeBlue } from './ThemeBlue';
import { MaterialThemeDarkBlue, ThemeDarkBlue } from './ThemeDarkBlue';
import { MaterialThemeGreen, ThemeGreen } from './ThemeGreen';
import { Themes } from 'store/_legacy/Redux/Ducks/configDuck/models';
import { ThemeColors } from './models';

interface ThemesResponse {
  material: Object;
  styles: ThemeColors;
}

/**
 * Receives a Theme string and return the correct Theme objects.
 */
export function getThemes(theme: Themes):ThemesResponse {
  const themeComponents: ThemesResponse = {
    material: MaterialThemeBlue,
    styles: ThemeBlue,
  };
  
  if (theme === 'ogBlue') {
    themeComponents.material = MaterialThemeBlue;
    themeComponents.styles = ThemeBlue;
  } else if (theme === 'ipg') {
    themeComponents.material = MaterialThemeDarkBlue;
    themeComponents.styles = ThemeDarkBlue;
  } else if (theme === 'green') {
    themeComponents.material = MaterialThemeGreen;
    themeComponents.styles = ThemeGreen;
  }

  return themeComponents;
}