import React from "react";
import { MenuTabProps } from "./types";
import { Box, Tab, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { CountBadge } from "../badge";

export const MenuTab: React.FC<MenuTabProps> = (props) => {
  const { index, last, label, cid, to, count, sx, ...rest } = props;
  return (
    <Tab
      label={to ? (
        <Box alignItems="center" component={Link} data-cid={cid} display="flex" gap={1} py={1} to={to}>
          <Typography fontSize="inherit" fontWeight="600">{label}</Typography>
          {count ? <CountBadge>{count}</CountBadge> : undefined}
        </Box>
      ) : (
        <Box alignItems="center" data-cid={cid} display="flex" gap={1} py={1}>
          <Typography fontSize="inherit" fontWeight="600">{label}</Typography>
          {count ? <CountBadge>{count}</CountBadge> : undefined}
        </Box>
      )}
      sx={{
        padding: 0,
        minWidth: 0,
        minHeight: 0,
        textTransform: "capitalize",
        fontWeight: "600",
        fontSize: 16,
        color: "black",
        paddingRight: !last ? 1 : 0,
        paddingLeft: index ? 1 : 0,
        borderRadius: 1,
        ...sx,
      }}
      {...rest}
    />
  );
};
