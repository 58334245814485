import { LoginQAResponse, LoginRequest } from './models';
import { rest } from 'msw';

const api = process.env.REACT_APP_API_URL;

const loginQAResponseSuccess: LoginQAResponse = {
  result: {
    statusCode: 200,
    data: {
      access_token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6Ik1yNS1BVWliZkJpaTdOZDFqQmViYXhib1hXMCIsImtpZCI6Ik1yNS1BVWliZkJpaTdOZDFqQmViYXhib1hXMCJ9.eyJhdWQiOiJodHRwczovL2dyYXBoLndpbmRvd3MubmV0IiwiaXNzIjoiaHR0cHM6Ly9zdHMud2luZG93cy5uZXQvZDAyNmU0YzEtNTg5Mi00OTdhLWI5ZGEtZWU0OTNjOWYwMzY0LyIsImlhdCI6MTY0MTQwNzI3NywibmJmIjoxNjQxNDA3Mjc3LCJleHAiOjE2NDE0MTI0NzgsImFjciI6IjEiLCJhaW8iOiJBVlFBcS84VEFBQUFJTmROKy9ONzludTR3OXJMUFgwaFFrNkEvZVRqMC9NekoyN2xWSFhsNExsL25lRDJxSUJDa2hDdUd2NkZJVmZTdVpZSU5uNGV6UUMwNDROTHRZdU1MMWVHTVJOcjd3Q3dDSmVuRlJ1OWwwUT0iLCJhbXIiOlsibWZhIl0sImFwcGlkIjoiOGU3MDJiY2UtNDRmZS00ODk3LWIzNTYtYjQzNTQwOThiOWJjIiwiYXBwaWRhY3IiOiIwIiwiZmFtaWx5X25hbWUiOiJMdWNlbmEiLCJnaXZlbl9uYW1lIjoiQnJ1bm8iLCJpcGFkZHIiOiIxODcuMTgwLjE0OS44MiIsIm5hbWUiOiJMdWNlbmEsIEJydW5vIChERVQtTUJXKSIsIm9pZCI6IjVhNzUxODliLWU4YzQtNGRmNC1iYjdmLWY5MmVkMzZlOGQ4YSIsIm9ucHJlbV9zaWQiOiJTLTEtNS0yMS0xMTQwMjkyMDU0LTQxMjk2NjQ4LTMxMjc3ODQ0MjUtODM5NjY3IiwicHVpZCI6IjEwMDMyMDAxNDMwNTYyNkQiLCJyaCI6IjAuQVEwQXdlUW0wSkpZZWttNTJ1NUpQSjhEWk00cmNJNy1SSmRJczFhME5VQ1l1YndOQUY0LiIsInNjcCI6IkRpcmVjdG9yeS5SZWFkLkFsbCBVc2VyLlJlYWQgVXNlci5SZWFkLkFsbCBVc2VyLlJlYWRCYXNpYy5BbGwiLCJzdWIiOiJzZUh1VHVrcGFsLU16SUtWVXVRZy00T3l4TWdYRUFmaFZzLU9QYU1QV3FzIiwidGVuYW50X3JlZ2lvbl9zY29wZSI6Ik5BIiwidGlkIjoiZDAyNmU0YzEtNTg5Mi00OTdhLWI5ZGEtZWU0OTNjOWYwMzY0IiwidW5pcXVlX25hbWUiOiJicnVuby5sdWNlbmFAa2luZXNzby5jb20iLCJ1cG4iOiJicnVuby5sdWNlbmFAa2luZXNzby5jb20iLCJ1dGkiOiJVODQ1eU8tNHRFLTd1UVRzTVZoVkFnIiwidmVyIjoiMS4wIn0.TpMyxr0RVSkkGu7iKf_1d7ecjmZ3GLla_FH9kMMDZtFbRj-CMqs-mC6-GjXl5PrKqfU_U3Ibu6o9CXW5dlBLCvrJ32GbzFj2ppvIvMV1QPAQizBIHeg-f7mZcWtaK9g_rTM__CQXxHJX4KPRQoJtLbYryCcmutg7YPOYqtf9n9mGjKEjLoYhqQry4LZUvIvCfQNJpfCKYgMKKDw5xxRFDrWIYuPt309XBlMNO2k4ygOuCD5bM0HMCQ1l9v7ZL8fkMGeHHYaViwq4ZhyD0BfQiXaJK3fqdB-OiUeD2t6abjLpq1JM0R60pSDhtyYppnREb0k7Qi5b5bU2fdVsTFCQGQ',
    },
  },
};

export const handlers = [
  rest.post<LoginRequest>(`${api}/api/auth/login`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(loginQAResponseSuccess),
    );
  }),
];
