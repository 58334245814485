import { t } from "@lingui/macro";
import { AddCircleOutline, CancelOutlined, MoreVert } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Divider, IconButton, IconButtonProps, List, ListItem, Pagination, Popover, Skeleton, Switch, Typography, useMediaQuery, useTheme } from "@mui/material";
import { CampaignsWrapper, ConfirmationDialog, ListHeader, ListHeaderLabel, useToast } from "components";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { PostTemplate, selectAdminCampaignsQuery, useDeletePostTemplateByIdMutation, useGetPostTemplatesQuery } from "store";

export const CampaignsTemplateList: React.FC = () => {
  const { palette, breakpoints } = useTheme();
  const xl = useMediaQuery(breakpoints.up("xl"));
  const toast = useToast();
  const query = useSelector(selectAdminCampaignsQuery);
  const [page, setPage] = useState(1);
  const [postTemplate, setPostTemplate] = useState<PostTemplate>();
  const [willDelete, setWillDelete] = useState(false);
  const [moreAnchorEl, setMoreAnchorEl] = useState<HTMLButtonElement | null>(null);
  const getPostTemplatesQuery = useGetPostTemplatesQuery({
    page,
    limit: 15,
    include: ["post"],
    search: query?.search,
  });
  const [deletePostTemplateById] = useDeletePostTemplateByIdMutation();

  const handleMoreClick: IconButtonProps["onClick"] = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setMoreAnchorEl(event.currentTarget);
  };

  const deletePostTemplate = async (postTemplate?: PostTemplate): Promise<void> => {
    setWillDelete(false);
    setMoreAnchorEl(null);
    setPostTemplate(undefined);

    if (!postTemplate) {
      return;
    }

    const response = await deletePostTemplateById(postTemplate.id);

    if ("error" in response) {
      toast.showToast({ severity: "error", message: t`Failed to delete post template, please try again.` });
    } else {
      toast.showToast({ severity: "success", message: t`Post template was deleted.` });
    }
  };

  const width = {
    audience: xl ? 76 : 76,
    sendDate: xl ? 76 : 76,
    type: xl ? 76 : 76,
    approver: xl ? 76 : 76,
    testInternally: xl ? 92 : 92,
  };
  const fontSize = { header: xl ? 13 : 12, body: xl ? 14 : 12 };
  const { data: getPostTemplatesResponse, isLoading } = getPostTemplatesQuery;
  const { items: postTemplates, meta } = getPostTemplatesResponse || {};

  return (
    <>
      <CampaignsWrapper>
      <ListHeader>
        <ListHeaderLabel sx={{ flex: 1 }}>{t`Name`}</ListHeaderLabel>
        <ListHeaderLabel sx={{ width: width.audience }}>{t`Audience`}</ListHeaderLabel>
        <ListHeaderLabel sx={{ width: width.sendDate }}>{t`Send date`}</ListHeaderLabel>
        <ListHeaderLabel sx={{ width: width.type }}>{t`Type`}</ListHeaderLabel>
        <ListHeaderLabel sx={{ width: width.approver }}>{t`Approver`}</ListHeaderLabel>
        <ListHeaderLabel sx={{ width: width.testInternally }}>{t`Test internally`}</ListHeaderLabel>
        <Box component="span" width={30} />
      </ListHeader>
      {isLoading ? (
        <Box mb={2} minHeight={xl ? 690 : 645}>
          <Skeleton height={45} sx={{ borderRadius: 0 }} variant="rectangular" />
          <Divider sx={{ opacity: 0.6 }} />
          <Skeleton height={45} sx={{ borderRadius: 0 }} variant="rectangular" />
          <Divider sx={{ opacity: 0.6 }} />
          <Skeleton height={45} sx={{ borderRadius: 0 }} variant="rectangular" />
          <Divider sx={{ opacity: 0.6 }} />
          <Skeleton height={45} sx={{ borderRadius: 0 }} variant="rectangular" />
          <Divider sx={{ opacity: 0.6 }} />
          <Skeleton height={45} sx={{ borderRadius: 0 }} variant="rectangular" />
        </Box>
      ) : postTemplates?.length ? (
        <List disablePadding sx={{ mb: 2 }}>
          {postTemplates?.map((postTemplate) => {
            const handleDeleteClick = () => {
              setPostTemplate(postTemplate);
              setWillDelete(true);
            };

            return (
              <>
                <ListItem
                  disablePadding
                  divider
                  key={postTemplate.id}
                  sx={{ px: 1, py: 1.5, gap: 1, flex: 1, borderBottom: "1px solid rgba(0, 0, 0, 0.05)", "&:hover": { bgcolor: palette.grey[100] } }}
                >
                  <Typography flex={1} fontSize={fontSize.body} noWrap title={postTemplate.post?.title}>{postTemplate.post?.title}</Typography>
                  <Typography fontSize={fontSize.body} noWrap title="-" width={width.audience}>-</Typography>
                  <Typography fontSize={fontSize.body} noWrap title="-" width={width.sendDate}>-</Typography>
                  <Typography fontSize={fontSize.body} noWrap title="-" width={width.type}>-</Typography>
                  <Typography fontSize={fontSize.body} noWrap title="-" width={width.approver}>-</Typography>
                  <Box display="flex" width={width.testInternally}>
                    <Switch disabled size="small" />
                  </Box>
                  <IconButton color="primary" onClick={handleMoreClick} size="small">
                    <MoreVert color={"primary"} fontSize="small" />
                  </IconButton>
                </ListItem>
                <Popover
                  anchorEl={moreAnchorEl}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  onClose={() => setMoreAnchorEl(null)}
                  open={!!moreAnchorEl}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                >
                  <ButtonGroup
                    orientation="vertical"
                    sx={{ "& .MuiButton-root": { justifyContent: "flex-start" }, "& .MuiButtonGroup-grouped:not(:last-of-type)": { border: "none" } }}
                    variant="text"
                  >
                    <Button component={Link} sx={{ gap: 1 }} to={`/admin/campaigns/new?templateId=${postTemplate.post?.id}`}>
                      <AddCircleOutline fontSize="small" />
                      <Typography fontSize={14}>{t`Create Campaign`}</Typography>
                    </Button>
                    <Button onClick={handleDeleteClick} sx={{ gap: 1 }}>
                      <CancelOutlined fontSize="small" />
                      <Typography fontSize={14}>{t`Remove from Templates`}</Typography>
                    </Button>
                  </ButtonGroup>
                </Popover>
              </>
            );
          })}
        </List>
      ) : (
        <Typography color={palette.grey[700]} fontSize={14} my={4} textAlign="center">
          {t`No post templates found.`}
        </Typography>
      )}
      {(meta?.pages || 0) > 1 ? (
        <Pagination count={meta?.pages} onChange={(_, page) => setPage(page)} page={page} shape="rounded" />
      ) : undefined}
      </CampaignsWrapper>
      <ConfirmationDialog
        description={t`Are you sure you want to remove "${postTemplate?.post?.title}" from templates?`}
        onClose={() => setWillDelete(false)}
        onConfirm={() => void deletePostTemplate(postTemplate)}
        open={!!willDelete}
        title={t`Delete template`}
      />
    </>
  );
};
