interface Props {  
  size?: number;
}

export default function RoomDetails({    
  size = 30,
}: Props) {
  return (
    <svg height={size} version="1.1" viewBox="0 0 30 30" width={size}>
    <title>C794EA53-F66E-44E1-8375-705DD2C13558@1x</title>
    <g fill="none" fillRule="evenodd" id="Rooms-booking" stroke="none" strokeWidth="1">
        <g id="1.3.1-Book-a-room-select-room-calendar-selected" transform="translate(-900.000000, -180.000000)">
            <g id="icons/roomdetails" transform="translate(900.000000, 180.000000)">
                <rect fill="#F4F4F4" height="30" id="Rectangle" rx="8" width="30" x="0" y="0"></rect>
                <g fill="#5E5E5E" fillRule="nonzero" id="icons/room" transform="translate(7.000000, 7.000000)">
                    <g id="Combined-Shape" transform="translate(0.000000, 0.000000)">
                        <path d="M12,4 L12,15 L4,15 L4,4 L12,4 Z M13.5,1 C14.2796961,1 14.9204487,1.59488808 14.9931334,2.35553999 L15,2.5 L15,14 C15,14.5522847 14.5522847,15 14,15 C13.4871642,15 13.0644928,14.6139598 13.0067277,14.1166211 L13,14 L13,3 L3,3 L3,14 C3,14.5128358 2.61395981,14.9355072 2.11662113,14.9932723 L2,15 C1.48716416,15 1.06449284,14.6139598 1.00672773,14.1166211 L1,14 L1,2.5 C1,1.72030388 1.59488808,1.07955132 2.35553999,1.00686658 L2.5,1 L13.5,1 Z M10,6 L6,6 L6,13 L10,13 L10,11 L9,11 C8.44771525,11 8,10.5522847 8,10 C8,9.44771525 8.44771525,9 9,9 L10,9 L10,6 Z"></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>      
  );
}
