import { Box, BoxProps, Skeleton } from "@mui/material";
import axios from "axios";
import { useToast } from "../toast-provider";
import React, { useEffect, useState } from "react";
import { useTypedSelector } from "store/_legacy/Redux/store";
import { useExceptionTracker } from "hooks";
import { t } from "@lingui/macro";

export const AttachmentImage: React.FC<BoxProps<"img"> & { attachmentId: string }> = (props) => {
  const { attachmentId, ...rest } = props;
  const toast = useToast();
  const trackException = useExceptionTracker();
  const authorization = useTypedSelector((state) => state.login.token);
  const [src, setSrc] = useState<string>();

  useEffect(() => {
    if (!authorization) {
      return;
    }

    void (async () => {
      try {
        const response = await axios.get<Blob>(
          `${process.env.REACT_APP_MEDIA_API_URL}/api/v1/attachments/${attachmentId}/download`,
          { headers: { authorization: `Bearer ${authorization}` }, responseType: "blob" },
        );
        
        setSrc(URL.createObjectURL(response.data));
      } catch (error) {
        trackException(error as Error);

        toast.showToast({ severity: "error", message: t`Failed to load image, please try again.` });
      }
    })();
  }, [attachmentId, authorization]);

  const { width, height, borderRadius } = rest;

  return !src ? (
    <Skeleton sx={{ width, height: typeof width === "number" && height === "auto" ? 0.5625 * width : height, borderRadius }} variant="rectangular" />
  ) : (
    <Box {...rest} borderRadius={borderRadius} component="img" height={height} src={src} width={width} />
  );
};
