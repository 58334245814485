import { KeyboardArrowDown } from "@mui/icons-material";
import { Select, SelectProps, useTheme } from "@mui/material";
import React from "react";
import { assign } from "underscore";

export const StandardSelect: React.FC<SelectProps> = (props) => {
  const { children, ...rest } = props;
  const theme = useTheme();

  return (
    <Select
      IconComponent={KeyboardArrowDown}
      disableUnderline
      variant="standard"
      {...rest}
      sx={({ palette }) => assign({
        "& .MuiSelect-select": {
          padding: 0,
          fontSize: 16,
          fontWeight: "600",
        },
        "& .MuiSelect-icon": {
          color: palette.primary.main,
          top: 0,
        },
      }, typeof rest.sx === "function" ? rest.sx(theme) : rest.sx)}
    >
      {children}
    </Select>
  );
};
