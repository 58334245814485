import React from "react";
import { EditorAlignmentButtonProps } from "./types/editor-alignment-button-props.type";
import { useSlate } from "slate-react";
import { isEditorAlignmentActive, toggleEditorAlignment } from "./utils";
import { EditorToolbarButton } from "./editor-toolbar-button";

export const EditorAlignmentButton: React.FC<EditorAlignmentButtonProps> = (props) => {
  const { alignment, Icon, ...rest } = props;
  const editor = useSlate();

  return (
    <EditorToolbarButton
      Icon={Icon}
      active={isEditorAlignmentActive(editor, alignment)}
      onMouseDown={() => toggleEditorAlignment(editor, alignment)}
      {...rest}
    />
  );
};
