import { Box, ButtonBase, Typography, useTheme } from "@mui/material";
import { LinkTreeProps } from "./types";
import { TogetherLink } from "../icons";

export const LinkTree: React.FC<LinkTreeProps> = (props) => {
  const { links } = props;
  const { palette } = useTheme();

  return (
    <Box alignItems="stretch" display="flex" flexDirection="column" flexWrap="nowrap">
      {links.map(({ title, url, cid }) => (
        <ButtonBase
          data-cid={cid}
          href={url}
          key={url}
          sx={{ justifyContent: "space-between", alignItems: "center", bgcolor: palette.grey[100], mb: 1, py: 1, px: 2, borderRadius: 2 }}
          target="_blank"
        >
          <Typography color={palette.primary.main} fontSize={14}>{title}</Typography>
          <TogetherLink fill={palette.primary.main} sx={{ width: 14 }} />
        </ButtonBase>
      ))}
    </Box>
  );
};
