import { t } from "@lingui/macro";
import { differenceInHours, differenceInMinutes, differenceInSeconds, format } from "date-fns";

export const formatDistance = (date: Date): string => {
  const now = new Date();
  const hours = differenceInHours(now, date);

  if (hours) {
    if (hours > 23) {
      return format(date, "LLL dd, yyyy");
    }

    return t`${hours}h ago`;
  } 

  const minutes = differenceInMinutes(now, date);

  if (minutes) {
    return t`${minutes}m ago`;
  } 
  
  const seconds = differenceInSeconds(now, date);

  if (seconds > 10) {
    return t`${seconds}s ago`;
  }

  return t`Just now`;
};
