import { useDispatch } from "react-redux";
import { useTypedSelector } from "store/_legacy/Redux/store";
import { useEffect, useState } from "react";
import moment from "moment/moment";
import { setCreateNewMeetingData } from "../../../../Store/Meetings/createNewMeetingDuck";
import Box from "components/_legacy/Box";
import styles from "./styles.module.scss";
import Text from "components/_legacy/Text";
import { Trans } from "@lingui/macro";
import Datetime from "components/_legacy/Icons/Datetime";
import Button from "components/_legacy/Button";
import Divider from "components/_legacy/Divider";
import CalendarVerticalView from "../Calendars/CalendarVerticalView";
import AccordionRoomCalendar from "../../Calendar/AccordionRoomCalendar";
import MeetingDetails from "../MeetingDetails";
import { RoomSelectEnum } from "../../../../Store/Meetings/createNewMeetingDuck/models";

export default function MeetingBooking() {
  const dispatch = useDispatch();
  const { createNewMeeting } = useTypedSelector(state => state);
  const {
    selectedData,
    meetingFrom,
    meetingTo,
    roomSelect,
  } = createNewMeeting;
  const { roomId } = selectedData;

  const [formattedSelectedDate, setFormattedSelectedDate] = useState('');

  const isListViewSelected = roomSelect === RoomSelectEnum.LIST_VIEW;
  const showFormattedSelectedDate = roomId && meetingFrom && meetingTo;

  const handleReset = (e: any) => {
    e.stopPropagation();
    dispatch(setCreateNewMeetingData({
      meetingFrom: undefined,
      meetingTo: undefined,
      selectedData: {
        ...selectedData,
        roomId: null,
        isTeamsMeeting: true,
        requestedServices: [],
        attendees: [],
      }}));
  };

  useEffect(() => {
    if (!meetingFrom && !meetingTo) return;

    const formattedSelectedDate = formatSelectedDate();
    setFormattedSelectedDate(formattedSelectedDate);
  }, [roomId, meetingFrom, meetingTo]);

  const formatSelectedDate = () => {
    const formattedDay = moment(meetingFrom).format("DD MMM, YYYY");
    const timetable = `${moment(meetingFrom).format('HH:mm')} - ${moment(meetingTo).format('HH:mm')}`;

    return `${formattedDay} ${timetable}`;
  };

  return (
    <Box className={styles.stickyBookingWrapper}>
      <Box marginBottom={15}>
        <Text size="xlg" weight="semi-bold">
          <Trans>Meeting Details</Trans>
        </Text>
      </Box>

      <Box alignItems="center" direction='column' display="flex" justifyContent="between" marginBottom={20}>
        { isListViewSelected && roomId?
          <>
            <Box alignItems="center" display="flex" height={'100%'} justifyContent="between" width={'100%'}>
              <Box alignItems="center" display="flex" gap={10}>
                <Datetime size={30} />

                <Text weight="semi-bold">
                  {showFormattedSelectedDate?
                    formattedSelectedDate:
                    <Trans>Select date and time</Trans>
                  }
                </Text>
              </Box>

              <Box marginRight={10}>
                <Button noPaddingX onClick={handleReset} type="clear">
                  <Trans>Reset</Trans>
                </Button>
              </Box>
            </Box>
            <Box marginTop={7}>
              <Divider />
            </Box>
            <CalendarVerticalView roomId={roomId} />
          </>:
          <AccordionRoomCalendar showExpand={isListViewSelected} />}
      </Box>

      <MeetingDetails />
    </Box>
  );
}
