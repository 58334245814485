import AddFloorHeader from 'components/_legacy/Header/AddFloorHeader';
import Attributes from '../Attributes';
import Box from 'components/_legacy/Box';
import AddFloorDataUpdate from 'components/_legacy/Header/AddFloorHeader/Components/AddFloorDataUpdate';
import AddFloorActionMenu from 'components/_legacy/Header/AddFloorHeader/Components/AddFloorActionMenu';

/**
 * Floor map attributes. Edit attributes from the selected location
 *
 * OwnedBy: Bruno
 */
export default function FloorMapAttributes() {
  return (
    <>
      <AddFloorHeader>
        <AddFloorDataUpdate />
        <AddFloorActionMenu />
      </AddFloorHeader>

      <Box marginTop={20}>
        <Attributes />
      </Box>
    </>
  );
}
