import Api from 'store/_legacy/Services/Api';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  LOGIN_QA,
  LOGIN_QA_FAIL,
  LOGIN_QA_SUCCESS,
  LoginQA,
} from '.';

function* loginQASaga(action: LoginQA): any {
  try {
    const payload = yield call(Api, action, true);

    yield put({ type: LOGIN_QA_SUCCESS, payload });
  } catch (e) {
    yield put({ type: LOGIN_QA_FAIL, payload: e });
  }
}

export default [
  takeLatest(LOGIN_QA, loginQASaga),
];
