import { t } from "@lingui/macro";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Dialog, Skeleton, Typography, useTheme } from "@mui/material";
import { HtmlContent } from "../html-content";
import { subscribeMessagingHandler } from "messaging";
import React, { useEffect, useState } from "react";
import { PendingQuestionnaire, QuestionnaireQuestion, Reservation, TogetherApiTag, togetherApi, useFinishQuestionnaireMutation, useGetMyReservationByIdQuery, useGetPendingQuestionnairesQuery, useLazyGetQuestionnaireQuestionsByLocationIdQuery, useSaveQuestionnaireAnswersMutation } from "store";
import { ExpandMoreRounded } from "@mui/icons-material";
import { useToast } from "../toast-provider";
import { useDispatch } from "react-redux";
import { ReservationListItem } from "components/reservations-list";

const Question: React.FC<{
  question: QuestionnaireQuestion;
  onAnswer: (question: QuestionnaireQuestion, answer: boolean) => void;
}> = (props) => {
  const { question, onAnswer } = props;
  const { questionText, questionDetails, isPositive } = question;
  const { palette } = useTheme();

  return (
    <Box>
      <HtmlContent html={questionText} />
      {questionDetails ? (
        <Accordion elevation={0} sx={{ mt: 1 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreRounded color="primary" />}
            sx={{
              justifyContent: "flex-start",
              "& .MuiAccordionSummary-content": { flexGrow: 0 },
            }}
          >
            <Typography color={palette.primary.main} fontSize={14} fontWeight="600">{t`See details`}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box maxHeight={420} pr={1} sx={{ overflowX: "hidden", overflowY: "auto" }}>
              <HtmlContent html={questionDetails} />
            </Box>
          </AccordionDetails>
        </Accordion>
      ) : undefined}
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button data-cid="decline-button" onClick={() => onAnswer(question, false)} sx={{ width: 120 }} variant={isPositive ? "outlined" : "contained"}>
          {t`No`}
        </Button>
        <Button data-cid="confirm-button" onClick={() => onAnswer(question, true)} sx={{ ml: 2, width: 120 }} variant={isPositive ? "contained" : "outlined"}>
          {t`Yes`}
        </Button>
      </Box>
    </Box>
  );
};

export const Questionnaires: React.FC = () => {
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const toast = useToast();
  const [questionnaires, setQuestionnaires] = useState<PendingQuestionnaire[]>([]);
  const [currentQuestion, setCurrentQuestion] = useState<number>(0);
  const [answers, setAnswers] = useState<Array<{ questionId: string; answer: boolean }>>([]);
  const [saveQuestionnaireAnswers] = useSaveQuestionnaireAnswersMutation();
  const [finishQuestionnaire] = useFinishQuestionnaireMutation();
  const [currentReservation, setCurrentReservation] = useState<Reservation>();
  const { data: getPendingQuestionnairesResponse } = useGetPendingQuestionnairesQuery();
  const [
    triggerGetQuestionnaireQuestionsByLocationId,
    getQuestionnaireQuestionsByLocationIdQuery,
  ] = useLazyGetQuestionnaireQuestionsByLocationIdQuery();
  const { data: getMyReservationByIdResponse, isLoading: isLoadingReservation } = useGetMyReservationByIdQuery(
    {
      reservationId: questionnaires?.[0]?.reservationId || "",
      include: ["attendees", "desk", "room", "schedule", "schedule.entries", "floor", "floor.location", "children", "children.parkingSpot"],
    },
    { skip: !questionnaires?.[0]?.reservationId },
  );
  const { data: pendingQuestionnaires } = getPendingQuestionnairesResponse?.result || {};
  const {
    data: getQuestionnaireQuestionsByLocationIdResponse,
    isLoading: questionsAreLoading,
    isFetching: questionsAreFetching,
  } = getQuestionnaireQuestionsByLocationIdQuery;
  const { data: questions } = getQuestionnaireQuestionsByLocationIdResponse?.result || {};
  const { data: reservation } = getMyReservationByIdResponse?.result || {};

  const pushQuestionnaire = (questionnaire: PendingQuestionnaire) => {
    const { locationId, bookingId, appointmentId } = questionnaire;
    
    if (locationId && (bookingId || appointmentId)) {
      const exists = questionnaires.some((questionnaire) => {
        return questionnaire.locationId === locationId && (
          (questionnaire.bookingId && questionnaire.bookingId === bookingId) 
          || (questionnaire.appointmentId && questionnaire.appointmentId === appointmentId)
        );
      });

      if (!exists) {
        setQuestionnaires((prev) => [...prev, questionnaire]);
      }
    }
  };

  useEffect(() => {
    const { bookings, appointments } = pendingQuestionnaires || {};

    if (bookings?.length) {
      for (const questionnaire of bookings) {
        pushQuestionnaire(questionnaire);
      }
    }

    if (appointments?.length) {
      for (const questionnaire of appointments) {
        pushQuestionnaire(questionnaire);
      }
    }
  }, [JSON.stringify(pendingQuestionnaires)]);

  useEffect(() => {
    const subscription = subscribeMessagingHandler((payload) => {
      const { slug, locationId, bookingId, appointmentId, reservationId } = payload?.data || {};

      if (slug === "questionnaire") {
        pushQuestionnaire({ locationId, bookingId, appointmentId, reservationId });
      }
    });

    return () => subscription.unsubscribe();
  }, [questionnaires, setQuestionnaires]);

  useEffect(() => {
    if (questionnaires.length) {
      const [{ locationId }] = questionnaires;

      setCurrentQuestion(0);
      setAnswers([]);
      triggerGetQuestionnaireQuestionsByLocationId(locationId, true);
    }
  }, [JSON.stringify(questionnaires)]);

  useEffect(() => {
    const [questionnaire] = questionnaires;

    if (!questionnaire || !reservation || !reservation.schedule) {
      return;
    }

    const { bookingId, appointmentId, reservationId } = questionnaire;

    if ((!bookingId && !appointmentId) || !reservationId) {
      return;
    }

    const entry = reservation.schedule?.entries?.find(({ legacyId }) => {
      return (bookingId && legacyId === bookingId) || (appointmentId && legacyId === appointmentId);
    });

    setCurrentReservation({ ...reservation, schedule: { ...reservation.schedule, entries: entry ? [entry] : [] } });
  }, [questionnaires, reservation]);

  const handleAnswer = async (question: QuestionnaireQuestion, answer: boolean) => {
    const newAnswers = [...answers, { questionId: question.id, answer }];
    const hasWrongAnswers = newAnswers.some(({ questionId, answer }) => {
      const question = questions?.find(({ id }) => id === questionId);

      return question && question.isPositive !== answer;
    });
    
    if (currentQuestion === (questions?.length || 1) - 1 && !hasWrongAnswers) {
      const [{ locationId, bookingId, appointmentId }] = questionnaires;
      const answers = newAnswers.map(({ questionId, answer }) => ({ questionId, answer, bookingId, appointmentId }));

      await Promise.all([
        saveQuestionnaireAnswers({ locationId, answers }),
        finishQuestionnaire({ bookingId, appointmentId, result: !hasWrongAnswers }),
      ]);

      toast.showToast({ severity: "success", message: t`Your reservation was confirmed.` });
      setQuestionnaires((questionnaires) => questionnaires.slice(1));
    } else {
      setAnswers(newAnswers);
      setCurrentQuestion((prev) => prev + 1);
    }
  };

  const handleReset = () => {
    setCurrentQuestion(0);
    setAnswers([]);
  };

  const handleCancelation = async () => {
    const [{ locationId, bookingId, appointmentId }] = questionnaires;

    await Promise.all([
      saveQuestionnaireAnswers({ locationId, answers: answers.map(({ questionId, answer }) => ({ questionId, answer, bookingId, appointmentId })) }),
      finishQuestionnaire({ bookingId, appointmentId, result: false }),
    ]);

    dispatch(togetherApi.util.invalidateTags([{ type: TogetherApiTag.RESERVATION }]));
    toast.showToast({ severity: "error", message: t`Your reservation was canceled.` });
    setQuestionnaires((questionnaires) => questionnaires.slice(1));
  };

  return (
    <Dialog maxWidth={false} open={questionnaires.length > 0}>
      {questionsAreFetching || questionsAreLoading || !questions ? (
        <Box p={2} width={720}>
          <Skeleton height={20} sx={{ mb: 2 }} variant="rectangular" width={240} />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton width={400} />
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Skeleton height={36} variant="rectangular" width={120} />
            <Skeleton height={36} sx={{ ml: 2 }} variant="rectangular" width={120} />
          </Box>
        </Box>
      ) : (
        currentQuestion < questions.length ? (
          <Box p={2} width={720}>
            <Typography fontSize={16} fontWeight={600} mb={1}>{t`Reservation`}</Typography>
            {isLoadingReservation ? <Skeleton height={85} variant="rectangular" width="100%" /> : undefined}
            {currentReservation ? <ReservationListItem disableInteraction mb={1} reservation={currentReservation} /> : undefined}
            <Box display="flex" justifyContent="space-between" mb={2}>
              <Typography fontSize={18} fontWeight="600">{t`Questionnaire`}</Typography>
              <Box alignItems="baseline" display="flex">
                <Typography color={palette.primary.main} fontSize={18} fontWeight="600">{currentQuestion + 1}</Typography>
                <Typography color={palette.grey[700]} fontSize={14} fontWeight="600">/{questions?.length || 0}</Typography>
              </Box>
            </Box>
            <Question onAnswer={(question, answer) => void handleAnswer(question, answer)} question={questions[currentQuestion]} />
          </Box>
        ) : (
          <Box p={2} width={520}>
            <Typography fontSize={18} fontWeight="600" mb={2}>{t`Please confirm your response`}</Typography>
            <Typography fontSize={14} mb={4}>{t`Based on your responses, your reservation will be cancelled.`}</Typography>
            <Box display="flex" justifyContent="flex-end" mt={2}>
              <Button data-cid="go-back-button" onClick={handleReset} sx={{ width: 120 }} variant="contained">
                {t`No, go back`}
              </Button>
              <Button data-cid="agree-button" onClick={handleCancelation} sx={{ ml: 2, width: 120 }} variant="outlined">
                {t`I Agree`}
              </Button>
            </Box>
          </Box>
        )
      )}
    </Dialog>
  );
};
