import { addWeeks, getDate, getMonth } from "date-fns";

export const resolveWeekdayIndex = (date: Date): number => {
  const month = getMonth(date);
  let base: Date | undefined = undefined;

  while (getMonth(addWeeks(base || date, -1)) === month) {
    base = addWeeks(base || date, -1);
  }

  if (base) {
    const days: number[] = [];

    do {
      days.push(getDate(base));

      base = addWeeks(base, 1);
    } while (getMonth(base) === month);

    const index = days.indexOf(getDate(date));

    return index === days.length - 1 ? -1 : index + 1;
  }

  return 1;
};
