import axios from "axios";
import { useEffect, useState } from "react";
import { useTypedSelector } from "store/_legacy/Redux/store";

const getStorageFile = async (authorization: string, url: string): Promise<Blob> => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/files/storage`, {
    headers: { authorization: `Bearer ${authorization}` },
    responseType: "blob",
    params: { url },
  });

  return response.data;
};

export const useStorageFileObjectURL = (url?: string): string | undefined => {
  const authorization = useTypedSelector(({ login }) => login.token);
  const [objectURL, setObjectURL] = useState<string>();

  useEffect(() => {
    if (!authorization || !url) {
      return;
    }

    void (async () => {
      try {
        const file = await getStorageFile(authorization, url);
        
        setObjectURL(URL.createObjectURL(file));
      } catch (error) {
        console.warn(`Failed to load storage file ${url}.`);
      }
    })();
  }, [authorization, url]);

  return objectURL;
};
