import { SvgIcon, SvgIconProps } from "@mui/material";

export const TogetherMeetingFilled: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path
        d="M13 3v13H3V3Zm1.5-3a1.5 1.5 0 0 1 1.493 1.356L16 1.5V15a1 1 0 0 1-1.993.117L14 15V2H2v13a1 1 0 0 1-.883.993L1 16a1 1 0 0 1-.993-.883L0 15V1.5A1.5 1.5 0 0 1 1.356.007L1.5 0zM11 8h-1a1 1 0 1 0 0 2h1a1 1 0 1 0 0-2z"
        display="inline"
        fill={props.fill}
        fillOpacity="1"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
      />
    </SvgIcon>
  );
};
