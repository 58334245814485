import Box from 'components/_legacy/Box';
import moment from 'moment';
import styles from './styles.module.scss';
import { PushNotificationModel } from 'App/Store/Notifications/models';

interface PushNotificationProps {
  notification: PushNotificationModel;
}

export default function PushNotification(props: PushNotificationProps) {
  const { notification } = props;
  const sendDate = moment(notification.sendDate).utc(true).format('MMM DD, h:mma');

  return (
    <Box
      ariaLabel="notification"
      className={styles.notification}
      role="listitem"
    >
      <div className={styles.notificationSubject}>{notification.subject}</div>
      <div>{sendDate}</div>
      {
        <div className={styles.notificationMessage}>
          {notification.message}
        </div>
      }
    </Box>
  );
}
