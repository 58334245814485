import { useDispatch } from "react-redux";
import { useTypedSelector } from "store/_legacy/Redux/store";
import { useEffect, useState } from "react";
import { Trans, t } from "@lingui/macro";
import styles from "../../../../../Locations/LocationForm/styles.module.scss";

import Box from "components/_legacy/Box";
import TextField from "@material-ui/core/TextField";
import { setGroupsCreateEditFormData } from "../../../../../../Store/groups/forms/createEditForm";
import { GroupCreateEditFormActionStateEnum } from "../../../../../../Store/groups/forms/createEditForm/models";

export default function Name() {
  const dispatch = useDispatch();
  const { actionState, group, formDataAdGroup, formErrors } = useTypedSelector(state => state.groupCreateEditForm);

  const groupName: string = actionState === GroupCreateEditFormActionStateEnum.edit && group?.name ? group.name : '';
  const [groupNameValue, setGroupNameValue] = useState<string>(groupName);
  const groupNameErrorClass = formErrors.groupNameError ? styles.formErrorShow : '';

  // set actual redux data after load group data
  useEffect(() => {
    dispatch(setGroupsCreateEditFormData({
      formDataName: groupNameValue,
    }));
  }, [group]);

  // set actual name after change ad group email
  useEffect(() => {
    if (formDataAdGroup) {
      setGroupNameValue(formDataAdGroup.displayName);

      dispatch(setGroupsCreateEditFormData({
        formDataName: formDataAdGroup.displayName,
      }));
    }
  }, [formDataAdGroup]);


  const onNameChange = (event: any) => {
    setGroupNameValue(event.target.value);

    dispatch(setGroupsCreateEditFormData({
      formDataName: event.target.value,
    }));

    // set error as false if fields is not empty
    if (event.target.value) {
      dispatch(setGroupsCreateEditFormData({
        formErrors: {
          ...formErrors,
          groupNameError: false,
        },
      }));
    }
  };

  return (
    <Box>
      <span className={styles.requiredSign}>*</span><label htmlFor="group-name"><Trans>Name</Trans></label>

      <TextField
        className={`input input--default input--inline ${groupNameErrorClass}`}
        fullWidth
        id="group-name"
        minRows={5}
        onChange={onNameChange}
        placeholder={t`Type here`}
        value={groupNameValue}
        variant="outlined"
      />
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      {formErrors.groupNameError ? <div className={styles.formErrors}><Trans>This field can't be empty</Trans></div> : null}
    </Box>
  );
}
