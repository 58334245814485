import Box from 'components/_legacy/Box';
import L from 'leaflet';
import MapDrawing from './MapDrawing';
import useSnackbar from 'components/_legacy/Snackbar/useSnackbar';
import { getImageBoundsLimited } from './Helpers/mapFunctions';
import { getMapMinZoomValue } from '../../../../App/Pages/CreateNewBooking/Desk/Map/Helpers/mapFunctions';
import { MAP_HEIGHT } from './Helpers/consts';
import { setFloorMapData } from 'Admin/Store/floorMapDuck';
import { useDispatch } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useTypedSelector } from 'store/_legacy/Redux/store';
import 'leaflet-draw';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import {
  ImageOverlay,
  MapContainer,
} from 'react-leaflet';
import {MapImageBoundsType} from "../../../Store/floorMapDuck/models";

interface Props {
  mapImageBounds: MapImageBoundsType | null;
  mapSize: {
    height: number;
    width: number;
  };
  name: string;
  previewUrl: string;
}


export default function Map({ mapImageBounds, mapSize, name, previewUrl }: Props) {
  const { height = 100, width = 100 } = mapSize;

  const dispatch = useDispatch();
  // We use this key to set the map size correctly after it's rendered.
  // Ref: https://zira.zstream.io/app/tasks/task/IPG-772
  const [key, setKey] = useState(0);
  const [openSnackbar] = useSnackbar();
  const [map, setMap] = useState<L.Map | null>(null);
  const [mapCentered, setMapCentered] = useState(false);
  const [minZoom, setMinZoom] = useState(0);
  const imageRef = useRef<L.ImageOverlay>(null);
  const { adminFloorMap } = useTypedSelector(state => state);

  // @ts-ignore its correct type for bounds // use getImageBoundsLimited for support old image bounds format
  const mapBounds: L.LatLngBoundsExpression = mapImageBounds ? mapImageBounds : getImageBoundsLimited({ height, proportion: 100, width });

  useEffect(() => {
    if (imageRef.current && map && !mapCentered) {
      const mapImage = imageRef.current;

      // @ts-ignore Typescript is not recognizing this objects. It's correct though
      const containerSize = mapImage._map._size;
      const mapMinZoom = getMapMinZoomValue({
        imageSize: { height, width },
        containerSize: { height: containerSize.y, width: containerSize.x },
      });

      setMinZoom(mapMinZoom);
      setMapCentered(true);
    }
  }, [imageRef.current, map]);

  useEffect(() => {
    if (imageRef.current && map) {
      const mapImage = imageRef.current;
      const imageBounds = mapImage.getBounds();

      // Set the map bounds to the image uploaded. This will center the map correctly.
      map.fitBounds(imageBounds);
    }
  }, [imageRef.current, map, minZoom]);

  useEffect(() => {
    if (adminFloorMap.errorMessage) {
      const handleClose = () => {
        dispatch(setFloorMapData({ errorMessage: '' }));
      };

      openSnackbar({
        onClose: handleClose,
        text: adminFloorMap.errorMessage,
        type: 'error',
      });
    }

    if (adminFloorMap.successMessage) {
      const handleClose = () => {
        dispatch(setFloorMapData({ successMessage: '' }));
      };

      openSnackbar({
        onClose: handleClose,
        text: adminFloorMap.successMessage,
        type: 'success',
      });
    }
  }, [adminFloorMap.errorMessage, adminFloorMap.successMessage]);

  useEffect(() => {
    if (imageRef.current && !mapCentered) {
      setKey(prevKey => prevKey + 1);
    }
  }, [imageRef.current, mapBounds, map, minZoom]);

  return (
    <Box data-testid="floor-map-details-map" height={MAP_HEIGHT} id="mapid">
      <MapContainer
        attributionControl={false}
        boundsOptions={{ padding: [0, 0] }}
        center={[50, 50]}
        crs={L.CRS.Simple}
        key={key}
        maxZoom={5}
        minZoom={minZoom}
        // scrollWheelZoom={false}
        style={{ height: MAP_HEIGHT }}
        whenCreated={setMap}
        zoom={minZoom}
        zoomSnap={0}
      >
        {previewUrl &&
        <ImageOverlay
          alt={`floor ${name}`}
          bounds={mapBounds}
          key={key}
          ref={imageRef}
          url={previewUrl}
        />
        }

        <MapDrawing />
      </MapContainer>
    </Box>
  );
}
