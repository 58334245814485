import { rest } from 'msw';
import {
  AddLocationParkingSpotRequest,
  AddLocationParkingSpotRequestData,
  AddLocationParkingSpotResponse,
  DeleteParkingSpotRequest,
  DeleteParkingSpotResponse,
  EditParkingSpotRequest,
  EditParkingSpotRequestData,
  EditParkingSpotResponse,
  GetLocationAvailableParkingSpotsRequest,
  GetLocationAvailableParkingSpotsRequestData,
  GetLocationAvailableParkingSpotsResponse,
  GetLocationParkingSpotsRequest,
  GetLocationParkingSpotsResponse,
  GetParkingSpotRequest,
  GetParkingSpotResponse,
} from './models';

const api = process.env.REACT_APP_API_URL;

const addLocationParkingSpotResponseSuccess: AddLocationParkingSpotResponse = {
  result: {
    statusCode: 200,
    data: {
      id: "1",
      name: "PARKING_9",
      locationId: "d06ddbdb-75de-4175-b997-aeacc47c5aa2",
      floor: "Ground Floor",
      isAvailable: true,
      isBooked: false,
    },
  },
};

const deleteParkingSpotResponseSuccess: DeleteParkingSpotResponse = {
  result: {
    statusCode: 200,
    parkingSpotId: "1",
  },
};

const editParkingSpotResponseSuccess: EditParkingSpotResponse = {
  result: {
    statusCode: 200,
    data: {
      id: "1",
      name: "PARKING_9",
      locationId: "d06ddbdb-75de-4175-b997-aeacc47c5aa2",
      floor: "Ground Floor",
      isAvailable: true,
      isBooked: false,
    },
  },
};

const getLocationAvailableParkingSpotsResponseSuccess: GetLocationAvailableParkingSpotsResponse = {
  result: {
    statusCode: 200,
    data: [
      {
        id: "1",
        name: "PARKING_1",
        locationId: "d06ddbdb-75de-4175-b997-aeacc47c5aa2",
        floor: "Ground Floor",
        isAvailable: true,
        isBooked: false,
      },
      {
        id: "2",
        name: "PARKING_2",
        locationId: "d06ddbdb-75de-4175-b997-aeacc47c5aa2",
        floor: "Ground Floor",
        isAvailable: true,
        isBooked: false,
      },
      {
        id: "3",
        name: "PARKING_3",
        locationId: "d06ddbdb-75de-4175-b997-aeacc47c5aa2",
        floor: "Ground Floor",
        isAvailable: true,
        isBooked: false,
      },
      {
        id: "4",
        name: "PARKING_4",
        locationId: "d06ddbdb-75de-4175-b997-aeacc47c5aa2",
        floor: "Ground Floor",
        isAvailable: true,
        isBooked: false,
      },
    ],
  },
};

const getLocationParkingSpotsResponseSuccess: GetLocationParkingSpotsResponse = {
  result: {
    statusCode: 200,
    data: [
      {
        id: "1",
        name: "PARKING_1",
        locationId: "d06ddbdb-75de-4175-b997-aeacc47c5aa2",
        floor: "Ground Floor",
        isAvailable: true,
        isBooked: false,
      },
      {
        id: "2",
        name: "PARKING_2",
        locationId: "d06ddbdb-75de-4175-b997-aeacc47c5aa2",
        floor: "Ground Floor",
        isAvailable: true,
        isBooked: false,
      },
      {
        id: "3",
        name: "PARKING_3",
        locationId: "d06ddbdb-75de-4175-b997-aeacc47c5aa2",
        floor: "Ground Floor",
        isAvailable: true,
        isBooked: false,
      },
      {
        id: "4",
        name: "PARKING_4",
        locationId: "d06ddbdb-75de-4175-b997-aeacc47c5aa2",
        floor: "Ground Floor",
        isAvailable: true,
        isBooked: false,
      },
    ],
  },
};

const getParkingSpotResponseSuccess: GetParkingSpotResponse = {
  result: {
    statusCode: 200,
    data: {
      id: "1",
      name: "PARKING_1",
      locationId: "d06ddbdb-75de-4175-b997-aeacc47c5aa2",
      floor: "Ground Floor",
      isAvailable: true,
      isBooked: false,
    },
  },
};

export const handlers = [
  rest.post<AddLocationParkingSpotRequestData, {}, AddLocationParkingSpotRequest>(`${api}/api/parking/:locationId`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        ...addLocationParkingSpotResponseSuccess,
        result: {
          ...addLocationParkingSpotResponseSuccess.result,
          data: addLocationParkingSpotResponseSuccess.result.data = {
            ...addLocationParkingSpotResponseSuccess.result.data,
            ...req.body,
            locationId: req.params.locationId,
          },
        },
      }),
    );
  }),
  rest.put<EditParkingSpotRequestData, {}, EditParkingSpotRequest>(`${api}/api/parking/:parkingSpotId`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        ...editParkingSpotResponseSuccess,
        result: {
          ...editParkingSpotResponseSuccess.result,
          data: {
            ...editParkingSpotResponseSuccess.result.data,
            ...req.body,
            id: req.params.parkingSpotId,
          },
        },
      }),
    );
  }),
  rest.delete<{}, {}, DeleteParkingSpotRequest>(`${api}/api/parking/:parkingSpotId`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        ...deleteParkingSpotResponseSuccess,
        result: {
          ...deleteParkingSpotResponseSuccess.result,
          parkingSpotId: req.params.parkingSpotId,
        },
      }),
    );
  }),
  rest.get<GetLocationAvailableParkingSpotsRequestData, {}, GetLocationAvailableParkingSpotsRequest>(`${api}/api/parking/:locationId/parkingSpots`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        ...getLocationAvailableParkingSpotsResponseSuccess,
        result: {
          ...getLocationAvailableParkingSpotsResponseSuccess.result,
          data: getLocationAvailableParkingSpotsResponseSuccess.result.data.map(parkingSpot => {
            return {
              ...parkingSpot,
              locationId: req.params.locationId,
            };
          }),
        },
      }),
    );
  }),
  rest.get<{}, {}, GetLocationParkingSpotsRequest>(`${api}/api/parking/location/:locationId`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        ...getLocationParkingSpotsResponseSuccess,
        result: {
          ...getLocationParkingSpotsResponseSuccess.result,
          data: getLocationParkingSpotsResponseSuccess.result.data.map(parkingSpot => {
            return {
              ...parkingSpot,
              locationId: req.params.locationId,
            };
          }),
        },
      }),
    );
  }),
  rest.get<{}, {}, GetParkingSpotRequest>(`${api}/api/parking/:parkingSpotId`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        ...getParkingSpotResponseSuccess,
        result: {
          ...getParkingSpotResponseSuccess.result,
          data: {
            ...getParkingSpotResponseSuccess.result.data,
            locationId: req.params.parkingSpotId,
          },
        },
      }),
    );
  }),
];
