import Box from 'components/_legacy/Box';
import MapViewBodyItem from './MapViewBodyItem';
import styles from '../styles.module.scss';
// @ts-ignore
import DoubleScrollbar from 'react-double-scrollbar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { ReservationByFloorModel } from '../../../Store/reservations/models';
import { Trans, t } from '@lingui/macro';
import {Skeleton} from "@material-ui/lab";
import Text from "components/_legacy/Text";
import {useTypedSelector} from "store/_legacy/Redux/store";

interface ReservationMapViewProps {
  reservationsByFloors: ReservationByFloorModel[];
}

export default function ReservationMapView(props: ReservationMapViewProps) {
  const { loading } = useTypedSelector(state => state.adminReservations);
  const reservationsByFloors = props.reservationsByFloors.filter(r => r.active);

  return (
    <Box className={styles.mapView} dataTestId="reservation-map-view">
      <TableContainer data-testid="reservation-map-view-content">
        <DoubleScrollbar>
          <Table
            aria-label={t`Reservation map view`}
            aria-labelledby={t`map view`}
            className={styles.reservationTable}
          >
            <TableHead classes={{ root: styles.tableHead }}>
              <TableRow classes={{ root: styles.tableRow }}>
                <TableCell classes={{ root: styles.tableCell }} padding="none">
                  <Box alignItems="center" display="flex">
                  <span>
                    <Trans>Location name</Trans>
                  </span>
                  </Box>
                </TableCell>

                <TableCell classes={{ root: styles.tableCell }} padding="none">
                  <Trans>
                    Floor
                  </Trans>
                </TableCell>

                <TableCell classes={{ root: styles.tableCell }} padding="none">
                  <Trans>
                    Desks
                  </Trans>
                </TableCell>

                <TableCell classes={{ root: styles.tableCell }} padding="none">
                  <Trans>
                    Available Desks
                  </Trans>
                </TableCell>

                <TableCell classes={{ root: styles.tableCell }} padding="none">
                  <Trans>
                    Booked Desks
                  </Trans>
                </TableCell>
              </TableRow>
            </TableHead>

            {!loading && (
              <TableBody classes={{ root: styles.tableBody }}>
                {
                  reservationsByFloors.map((reservationsByFloors, index) => {
                    return (<MapViewBodyItem key={index} reservationsByFloors={reservationsByFloors} />);
                  })
                }
              </TableBody>
            )}
          </Table>
        </DoubleScrollbar>
      </TableContainer>
      {loading ? (
        <Box dataTestId="reservation-map-view-loading" height={200}>
          <Skeleton height="100%" variant="rect" />
        </Box>
      ) : (
        !reservationsByFloors.length && (
          <Box dataTestId="reservation-map-view-no-data" marginTop={100}>
            <Text align="center" color="lightGray" size="md">
              No reservations found
            </Text>
          </Box>
        )
      )}
    </Box>
  );
}
