import { rest } from 'msw';
import {
  GetLocationDashboardTotalByDayResponse,
  GetLocationDashboardTotalByDomainResponse,
  GetLocationDashboardsTotalCountByPeriodResponse,
  GetLocationDashboardsTotalCountResponse,
} from './models';

const api = process.env.REACT_APP_API_URL;

const getLocationDashboardTotalByDayResponseSuccess: GetLocationDashboardTotalByDayResponse = {
  result: {
    data: [
      {
        booked: 40,
        cancelled: 22,
        day: "Tuesday",
        dayDate: "2021-11-26",
      },
    ],
  },
};

const getLocationDashboardTotalByDomainResponseSuccess: GetLocationDashboardTotalByDomainResponse = {
  result: {
    data: [
      {
        bookedAndCheckedIn: 40,
        bookedAndNotCheckedIn: 20,
        cancelled: 22,
        domain: "Kinesso",
      },
    ],
  },
};

const getLocationDashboardsTotalCountResponseSuccess: GetLocationDashboardsTotalCountResponse = {
  result: {
    data: {
      cancelled: 78,
      total: 1295,
      unique: 309,
    },
  },
};

const getLocationDashboardsTotalCountByPeriodResponseSuccess: GetLocationDashboardsTotalCountByPeriodResponse = {
  result: {
    data: [
      {
        month: "Jan",
        quarter: 1,
        quarterDetail: "Jan–Mar,2021",
        week: 1,
        booked: 30,
        cancelled: 12,
      },
    ],
  },
};

export const handlers = [
  rest.get(`${api}/api/bookings/:locationId/period/:period/dashboards/totalByDay`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(getLocationDashboardTotalByDayResponseSuccess),
    );
  }),
  rest.get(`${api}/api/bookings/:locationId/period/:period/dashboards/totalByDomain`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(getLocationDashboardTotalByDomainResponseSuccess),
    );
  }),
  rest.get(`${api}/api/bookings/:locationId/dashboards/totalCount`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(getLocationDashboardsTotalCountResponseSuccess),
    );
  }),
  rest.get(`${api}/api/bookings/:locationId/period/:period/dashboards/totalCountByPeriod`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(getLocationDashboardsTotalCountByPeriodResponseSuccess),
    );
  }),
];
