import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import {useTypedSelector} from "store/_legacy/Redux/store";
import moment from "moment";
import format from "date-fns/format";
import Box from "components/_legacy/Box";
import ReservationMapViewHeader from 'components/_legacy/Header/ReservationMapViewHeader';
import {getFloorSchema} from "../../../Store/floorMapApiRequestsDuck";
import Text from "components/_legacy/Text";
import Map from './Map';
import {ReservationMapViewCalendar} from "../ReservationCalendar";
import {getReservationsBookings, updateReservationPageType} from "../../../Store/reservations";
import {SuccessReservationsSnackbar} from "../SnackBars";
import {onLoadDesks, onLoadDesksFilters} from "../../ReservationJustInTime/ReservationJustIntimeDetails/Floor/helpers";

export default function ReservationsMapViewDetails() {
  const dispatch = useDispatch();
  const { adminReservations, adminFloorMap } = useTypedSelector(state => state);
  const { filters } = adminReservations;
  const { mapSize, name, previewUrl, mapImageBounds } = adminFloorMap;
  const { floorId, locationId } = useParams<{ floorId: string; locationId: string }>();

  useEffect(() => {
    // set default map view type
    dispatch(updateReservationPageType({ type: 'map' }));

    if (floorId) {
      dispatch(getFloorSchema({ floorId, showOwners: true }));
    }
  }, [floorId]);

  useEffect(() => {
    // get bookings all bookings for map view details
    dispatch(getReservationsBookings({
      page: 1,
      limit: 1000,
      order: 'ASC',
      locationIds: [locationId],
      floorIds: [floorId],
      statuses: ["BOOKED", "PAST"],
      dateFrom: moment(filters.selectedStartDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss'), // start of day in iso format
      dateTo: moment(filters.selectedEndDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss'), // end of day in iso format
    }));
  }, [filters.selectedStartDate, filters.selectedEndDate]);

  // load floor desks
  useEffect(() => {
    if (floorId) {
      const deskFilters: onLoadDesksFilters = {
        dateFrom: filters.selectedStartDate ? format(new Date(filters.selectedStartDate), 'yyyy-MM-dd') : '',
        dateTo: filters.selectedEndDate ? format(new Date(filters.selectedEndDate), 'yyyy-MM-dd') : '',
        bookingType: 'custom',
        showDeletedDesks: true,
      };
      onLoadDesks(floorId, deskFilters, dispatch); // update desk list
    }
  }, [filters.selectedStartDate, filters.selectedEndDate]);

  return (
    <>
      <SuccessReservationsSnackbar />

      <Box marginBottom={24}>
        <ReservationMapViewHeader />
      </Box>
      <Box display="grid" gap={30} gridTemplateColumns="8fr 4fr" paddingBottom={50}>
        <Box display="grid" gap={15}>
          <Text weight="semi-bold">
            {(previewUrl) && (
              <Map
                mapImageBounds={mapImageBounds}
                mapSize={mapSize}
                name={name}
                previewUrl={previewUrl}
              />
            )}
          </Text>
        </Box>
        <ReservationMapViewCalendar />
      </Box>
    </>
  );
}
