import {useDispatch} from "react-redux";
import {useTypedSelector} from "store/_legacy/Redux/store";
import {setSettingsMobileData} from "../../../../Store/settingsMobile";
import {useEffect} from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

export function SuccessSettingsMobileSnackbar() {
  const dispatch = useDispatch();
  const { successMessage } = useTypedSelector(state => state.adminSettingsMobile);

  const handleClose = () => {
    dispatch(setSettingsMobileData({ successMessage: '' }));
  };

  useEffect(() => {
    return () => {
      dispatch(setSettingsMobileData({ successMessage: '' }));
    };
  }, []);

  return successMessage ? (
    <Snackbar autoHideDuration={6000} onClose={handleClose} open>
      <Alert onClose={handleClose} severity="success">
        {successMessage}
      </Alert>
    </Snackbar>
  ) : null;
}

export function ErrorSettingsMobileSnackbar() {
  const dispatch = useDispatch();
  const { error } = useTypedSelector(state => state.adminSettingsMobile);

  const handleClose = () => {
    dispatch(setSettingsMobileData({ error: '' }));
  };

  useEffect(() => {
    return () => {
      dispatch(setSettingsMobileData({ error: '' }));
    };
  }, []);

  return error ? (
    <Snackbar autoHideDuration={6000} onClose={handleClose} open>
      <Alert onClose={handleClose} severity="error">
        {error}
      </Alert>
    </Snackbar>
  ) : null;
}
