import {
  BookingCovidConfirmationModel,
  SetBookingCovidConfirmationModel,
} from "./models";

const SET_BOOKING_COVID_CONFIRMATION_DATA = 'SET_BOOKING_COVID_CONFIRMATION_DATA';

interface SetBookingCovidConfirmationData {
  type: typeof SET_BOOKING_COVID_CONFIRMATION_DATA;
  payload: SetBookingCovidConfirmationModel;
}

type Actions = SetBookingCovidConfirmationData;

export interface State extends BookingCovidConfirmationModel {}

const initialState: State = {
  openCovidModal: false,
  disableFloorPage: false,
};

export default function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case SET_BOOKING_COVID_CONFIRMATION_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
}

// actions
export function setBookingCovidConfirmationData(data: SetBookingCovidConfirmationModel): SetBookingCovidConfirmationData {
  return {
    type: SET_BOOKING_COVID_CONFIRMATION_DATA,
    payload: data,
  };
}
