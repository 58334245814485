import Popup from "reactjs-popup";

import ButtonBase from "@material-ui/core/ButtonBase";
import Text from "components/_legacy/Text";
import Box from "components/_legacy/Box";
import Checkbox from "components/_legacy/Checkbox";
import { AttributeModel } from "Admin/Store/attributes/models";

const attributesIcons: { name: string; image: string }[] = [];

interface Props {
  attributes: AttributeModel[];
  selected?: Record<string, string>;
  onUpdate: (value: boolean, attribute: AttributeModel) => void;
}

export default function Amenities(props: Props) {
  const { attributes, selected = {}, onUpdate } = props;
  const selectedAttributes = Object.keys(selected).map((id) => attributes.find((attribute) => attribute.id === id ));

  return (
    <Popup
      closeOnDocumentClick
      contentStyle={{ marginLeft: 27, width: 270 }}
      position="bottom left"
      trigger={(
        <ButtonBase
          style={{
            minWidth: "50%",
            maxWidth: "380px",
            height: "32px",
            display: "flex",
            padding: "0 8px",
            justifyContent: "flex-start",
          }}
        >
          <Text size="md" truncate>
            {selectedAttributes.length === 0
              ? '-'
              : selectedAttributes.map((attribute, index) => {
                return `${attribute?.name}${index + 1 < selectedAttributes.length ? ', ' : ''}`;
              })}
          </Text>
        </ButtonBase>
      )}
    >
      <Box borderStyle="shadow" className={"popover-content"}>
        {attributes.map((amenity, index) => {
          const imagePath = attributesIcons.find(icon => icon.name === amenity.name);
          const checked = !!selected?.[amenity.id];

          return (
            <Box key={amenity.name + index} paddingLeft={10}>
              <Checkbox
                checked={checked}
                label={
                <Box alignItems="center" display="flex">
                  {imagePath && <img alt={imagePath.name} src={imagePath.image} />}
                  {amenity.name}
                </Box>
              }
                name={amenity.name}
                onChange={(value) => onUpdate(value, amenity)}
              />
            </Box>
          );
        })}
      </Box>
    </Popup>
  );
}