import styles from "./styles.module.scss";
import LocationDetailsHeader from "components/_legacy/Header/LocationDetailsHeader";
import Box from "components/_legacy/Box";
import LocationStartDayList from "./LocationStartDayList";
import LocationNotificationSwitcher from "./LocationNotificationSwitcher";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useTypedSelector } from "store/_legacy/Redux/store";
import { useEffect } from "react";
import { editLocation, getAdminLocations } from "../../../App/Store/Locations/locationsDuck";
import { EditLocationModel } from "../../../App/Store/Locations/locationsDuck/models";
import { Skeleton } from "@material-ui/lab";
import DefaultWeekRecurrenceSelect from "./DefaultWeekRecurrenceSelect";
import DeskReservationDayLimit from "./DeskReservationDayLimit";

export default function LocationSettings() {
  const dispatch = useDispatch();
  const { locationId } = useParams<{ locationId: string }>();
  const { locations } = useTypedSelector(state => state);
  const selectedLocation = locations.adminLocations.find(location => location.id === locationId);

  useEffect(() => {
    if (!selectedLocation) {
      dispatch(getAdminLocations({ activeOnly: false }));
    }
  }, [locationId]);

  const handleChange = (location: EditLocationModel) => {
    if (selectedLocation?.id) {
      dispatch(editLocation({ location, locationId: selectedLocation.id }));
    }
  };

  return (
    <>
      <LocationDetailsHeader />

      <Box className={styles.locationSettingsContainer} dataTestId="location-settings" marginTop={20}>
        {selectedLocation ? (
          <>
            <LocationStartDayList selectedLocation={selectedLocation} />
            <LocationNotificationSwitcher selectedLocation={selectedLocation} />
            <DefaultWeekRecurrenceSelect
              onChange={(defaultWeekRecurrence) => handleChange({ defaultWeekRecurrence })}
              value={selectedLocation.defaultWeekRecurrence}
            />
            <DeskReservationDayLimit
              onChange={(customReservationDayLimit) => handleChange({ customReservationDayLimit })}
              value={selectedLocation.customReservationDayLimit}
            />
          </>
        ) : (
          <Box height={200}>
            <Skeleton height="100%" variant="rect" />
          </Box>
        )}
      </Box>
    </>
  );
}
