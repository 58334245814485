import { t } from "@lingui/macro";
import { Autocomplete, AutocompleteProps, Box, FilledInput, TextField, Typography, useTheme } from "@mui/material";
import { isURL } from "class-validator";
import { CampaignPolicyWrapper, NumberListInput, NumberListInputProps, RequiredMark, Tab, Tabs, UsersListSelect, useToast } from "components";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { CommPolicyRule, CommPolicyRuleType, CommPolicyType, User, adminSlice, selectAdminCurrentCampaignPolicy, useCurrentCampaignPolicyRules } from "store";
import { MarkOptional } from "ts-essentials";

export const CampaignBrandPolicy: React.FC = () => {
  const dispatch = useDispatch();
  const { background } = useTheme();
  const history = useHistory();
  const { policyId } = useParams<{ policyId?: string }>();
  const toast = useToast();
  const currentPolicy = useSelector(selectAdminCurrentCampaignPolicy);
  const { approvers, recipientUsersCount, recipients, authors, editors } = useCurrentCampaignPolicyRules();

  const updateUserRules = useCallback((type: CommPolicyRuleType, value: User[]) => {
    const newRules: MarkOptional<CommPolicyRule, "id" | "createdAt">[] = [];

    for (const rule of currentPolicy?.rules || []) {
      if (rule.type !== type || value.some(({ id }) => rule.user?.id === id)) {
        newRules.push(rule);
      }
    }

    for (const user of value) {
      if (!newRules.some((rule) => rule.type === type && rule.user?.id === user.id)) {
        newRules.push({ type, user });
      }
    }

    dispatch(adminSlice.actions.setCurrentCampaignPolicy({ rules: newRules }));
  }, [currentPolicy?.rules, dispatch]);

  const handleNumberListInputChange: NumberListInputProps["onChange"] = (value) => {
    const ruleExists = currentPolicy?.rules?.some(({ type }) => type === CommPolicyRuleType.RECIPIENT_USERS_COUNT);

    if (ruleExists) {
      dispatch(adminSlice.actions.setCurrentCampaignPolicy({
        rules: currentPolicy?.rules?.map((rule) => rule.type === CommPolicyRuleType.RECIPIENT_USERS_COUNT ? { ...rule, value: `${value}` } : rule ),
      }));
    } else {
      dispatch(adminSlice.actions.setCurrentCampaignPolicy({
        rules: [...(currentPolicy?.rules || []), { type: CommPolicyRuleType.RECIPIENT_USERS_COUNT, value: `${value}` }],
      }));
    }
  };

  const handleDomainsChange: AutocompleteProps<string, true, false, true>["onChange"] = (_, value) => {
    const domains = value.filter((domain) => isURL(domain));

    if (domains.length !== value.length) {
      toast.showToast({ severity: "warning", message: t`Only valid domains are allowed.` });
    }

    dispatch(adminSlice.actions.setCurrentCampaignPolicy({ domains: domains.map((name) => ({ name })) }));
  };

  return (
    <CampaignPolicyWrapper policyId={policyId} type={CommPolicyType.BRAND}>
      <Box display="flex" flexDirection="column" gap={2}>
        {policyId || currentPolicy?.id ? (
          <Tabs inline sx={{ padding: 0, bgcolor: "#fff" }} value={0}>
            <Tab
              data-cid="brand-button"
              label={t`Brand`}
              onClick={() => history.push(`/admin/campaigns/policies/${policyId || currentPolicy?.id}`)}
              sx={{ color: "#000", minWidth: 0, "&.Mui-selected": { bgcolor: background.blue.main } }}
              value={0}
            />
            <Tab
              data-cid="user-policy-button"
              label={t`User policy`}
              onClick={() => history.push(`/admin/campaigns/policies/${policyId || currentPolicy?.id}/users`)}
              sx={{ color: "#000", minWidth: 0, "&.Mui-selected": { bgcolor: background.blue.main } }}
              value={1}
            />
          </Tabs>
        ) : undefined}
        <Box>
          <Typography fontSize={14} mb={1}><RequiredMark />{t`Brand name`}</Typography>
          <FilledInput
            fullWidth
            onChange={(event) => dispatch(adminSlice.actions.setCurrentCampaignPolicy({ name: event.currentTarget.value }))}
            readOnly={!currentPolicy?.isEditable}
            value={currentPolicy?.name || ""}
          />
        </Box>
        <Box>
          <Typography fontSize={14} mb={1}><RequiredMark />{t`Company email domains this profile applies to`}</Typography>
          <Autocomplete
            freeSolo
            multiple
            onChange={handleDomainsChange}
            options={[]}
            readOnly={!currentPolicy?.isEditable}
            renderInput={(props) => (
              <TextField
                {...props}
                fullWidth
                sx={{
                  "& .MuiFilledInput-root.MuiInputBase-sizeSmall": { paddingTop: 0.7, paddingBottom: 0.7, paddingLeft: 1 },
                  "& .MuiFilledInput-input": { fontSize: 14 },
                }}
                variant="filled"
              />
            )}
            size="small"
            value={currentPolicy?.domains?.map(({ name }) => name) || []}
          />
        </Box>
        <UsersListSelect
          labels={{ title: t`Approver`, disclaimer: t`(any of the following names can approve)` }}
          onChange={(value) => updateUserRules(CommPolicyRuleType.APPROVER, value || [])}
          readOnly={!currentPolicy?.isEditable}
          value={approvers}
        />
        <NumberListInput
          labels={{ title: t`Recipients count`, disclaimer: t`(require approval If attendee user count is greater than)` }}
          min={0}
          onChange={handleNumberListInputChange}
          readOnly={!currentPolicy?.isEditable}
          value={recipientUsersCount}
        />
        <UsersListSelect
          labels={{ title: t`Recipients list`, disclaimer: t`(require approval if any of the following names are in the recipients list)` }}
          onChange={(value) => updateUserRules(CommPolicyRuleType.RECIPIENT, value || [])}
          readOnly={!currentPolicy?.isEditable}
          value={recipients}
        />
        <UsersListSelect
          labels={{
            title: t`Message is sent from`,
            disclaimer: t`(require approval if any of the following names are who the message is sent from)`,
          }}
          onChange={(value) => updateUserRules(CommPolicyRuleType.AUTHOR, value || [])}
          readOnly={!currentPolicy?.isEditable}
          value={authors}
        />
        <UsersListSelect
          labels={{
            title: t`Default access`,
            disclaimer: t`(who by default can see this campaign)`,
          }}
          onChange={(value) => updateUserRules(CommPolicyRuleType.EDITOR, value || [])}
          readOnly={!currentPolicy?.isEditable}
          value={editors}
        />
      </Box>
    </CampaignPolicyWrapper>
  );
};
