import { commApi } from "../comm-api";
import { TrackInput } from "../types";

const tracking = commApi.injectEndpoints({
  endpoints: (builder) => ({
    track: builder.mutation<void, TrackInput>({
      query: (body) => ({ body: { ...body, timestamp: new Date().toISOString() }, url: "/api/v1/track", method: "POST" }),
    }),
  }),
});

export const { useTrackMutation } = tracking;
