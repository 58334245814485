import { useDispatch } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useTypedSelector } from 'store/_legacy/Redux/store';

import moment from "moment";
import L from 'leaflet';
import { Popup, Rectangle, Tooltip } from 'react-leaflet';
import styles from './styles.module.scss';

import CheckIcon from '@material-ui/icons/Check';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import IconButton from '@material-ui/core/IconButton';
import Box from 'components/_legacy/Box';
import DeskDeleteModal from "../../Components/DeleteDeskForm";
import { deleteDesk, setFloorMapData, updateDesk } from 'Admin/Store/floorMapDuck';
import {FloorMapObject} from 'Admin/Store/floorMapDuck/models';
import {checkDeskReservation} from "../../../../../../Store/floorMapApiRequestsDuck";
import { DeskStatusEnum } from "../../../../../../Store/floorMapDuck/desk/models";

interface Props {
  desk: FloorMapObject;
  floorId: string;
  editable?: boolean;
}

/**
 * Desk Drawing
 *
 * This component handles deletion and edition internally.
 *
 * React Leaflet Draw handles it's own states, so this component syncs them with internal states.
 */
export default function DeskObjet({ desk, floorId, editable }: Props) {
  const dispatch = useDispatch();
  const ref = useRef<any>(null);
  const { coordinatesInLatLngBounds, id } = desk;
  const [editDeskEnabled, setEditDeskEnabled] = useState(false);
  const [deskPopupOpened, setDeskPopupOpened] = useState(false);
  const { adminFloorMap, config } = useTypedSelector(state => state);

  const { activeDeskId, deskToDelete, deskReservationChecking } = adminFloorMap;
  const isActive = activeDeskId === id;

  // Enable or disable edition when editSectionEnabled changes
  useEffect(() => {
    if (ref.current) {
      if (editDeskEnabled) {
        ref.current.editing.enable();
      } else {
        ref.current.editing.disable();
      }
    }
  }, [editDeskEnabled]);

  // Close popup if needed when deskPopupOpened changes
  useEffect(() => {
    if (ref.current && editable) {
      if (!deskPopupOpened) {
        ref.current._popup.remove();
      }
    }
  }, [deskPopupOpened]);

  const deactivateStates = () => {
    setEditDeskEnabled(false);
    setDeskPopupOpened(false);
  };

  // Updates the drawing on redux and deactivates all states
  const confirmEdition = () => {
    if (ref.current) {
      const coordinatesInLatLngBounds: L.LatLng[] = ref.current._latlngs.length ? ref.current._latlngs[0] : [];
      const coordinatesInPoints = coordinatesInLatLngBounds.map(coordinate => L.CRS.Simple.latLngToPoint(coordinate, 1));

      dispatch(updateDesk({
        deskId: typeof id === 'string' ? id : '',
        updateDeskProperties: {
          coordinatesInLatLngBounds,
          coordinatesInPoints,
        },
      }));

      deactivateStates();
    }
  };

  // Returns desk color depending on it's status and active state
  const getDeskColor = (): string => {
    let color = isActive ? '#3f8627' : desk.line;

    if (desk.status === DeskStatusEnum.ApprovalRequired) {
      if (isActive) {
        color = '#de5e33';
      } else {
        color = '#fd855c';
      }
    } else if (desk.status === DeskStatusEnum.Unavailable) {
      if (isActive) {
        color = '#a50707';
      } else {
        color = '#ef0000';
      }
    }

    return color;
  };

  // Delete drawing from redux and deactivates all states
  const onDeleteDesk = () => {
    if (typeof id === 'string') {
      dispatch(deleteDesk(id));
      deactivateStates();
    }
  };

  // Delete desk from database and drawing
  const onDeleteDeskPermanently = () => {
    dispatch(checkDeskReservation({ // initiate delete desk by checking reservations, it also open delete modal
      desk,
      date: moment().format(), // check reservations from current time
    }));
  };

  // Set as active desk. If the desk active is the one being clicked, clears this state.
  const onClick = () => {
    if (isActive) {
      dispatch(setFloorMapData({
        activeDeskId: undefined,
        activeSectionId: undefined,
        activeMeetingId: undefined,
      }));
    } else {
      dispatch(setFloorMapData({
        activeDeskId: typeof desk.id === 'string' ? desk.id : '',
        activeSectionId: undefined,
        activeMeetingId: undefined,
      }));
    }
  };

  // Set popup state to false and if the section is being edited, saves the editions
  const onPopupClose = () => {
    if (editDeskEnabled) {
      confirmEdition();
    } else {
      setDeskPopupOpened(false);
    }
  };

  // Set popup state to true. Since leaflet draw handles states separately, this syncs both
  const onPopupOpen = () => {
    setDeskPopupOpened(true);
  };

  // Toggle edit section functionality and set desk as active
  const toggleEditDeskEnabled = () => {
    dispatch(setFloorMapData({
      activeDeskId: typeof desk.id === 'string' ? desk.id : '',
      activeSectionId: undefined,
      activeMeetingId: undefined,
    }));
    setEditDeskEnabled(!editDeskEnabled);
  };

  const arrayOfCoordinatesInLatLng = coordinatesInLatLngBounds.map(coordinate => L.latLng(coordinate.lat, coordinate.lng));
  const bounds: L.LatLngBoundsExpression = L.latLngBounds(arrayOfCoordinatesInLatLng);

  const color = getDeskColor();

  return (
    <>
      <Rectangle
        bounds={bounds}
        className="floor-map-details-desk"
        eventHandlers={{
          click: onClick,
          popupclose: onPopupClose,
          popupopen: onPopupOpen,
        }}
        pathOptions={{
          color,
          fillColor: color,
        }}
        ref={ref}
      >
        <Tooltip sticky>
          {desk.name}
        </Tooltip>

        {editable &&
        <Popup
          className={styles.popupContainer}
          closeButton={false}
        >
          {editDeskEnabled ? (
            <Box alignItems="center" display="flex">
              <IconButton
                onClick={confirmEdition}
                size="small"
                style={{ borderRadius: 6, backgroundColor: '#5BC535', padding: 0 }}
              >
                <CheckIcon
                  style={{
                    color: "#fff",
                    fontSize: 20,
                  }}
                />
              </IconButton>
            </Box>
          ) : (
            <Box alignItems="center" display="flex">
              <IconButton
                onClick={toggleEditDeskEnabled}
                size="small"
                style={{ padding: 0 }}
              >
                <CreateOutlinedIcon
                  style={{
                    color: config.theme.primary,
                    fontSize: 20,
                  }}
                />
              </IconButton>

              {(desk.saved) &&
              <IconButton
                disabled={deskReservationChecking}
                onClick={onDeleteDeskPermanently}
                size="small"
                style={{ marginInlineStart: 10, padding: 0, opacity: deskReservationChecking ? .5 : 1 }}
              >
                <DeleteOutlineIcon
                  style={{
                    color: config.theme.primary,
                    fontSize: 20,
                  }}
                />
              </IconButton>
              }

              {(!desk.saved) &&
              <IconButton
                onClick={onDeleteDesk}
                size="small"
                style={{ marginInlineStart: 10, padding: 0 }}
              >
                <DeleteOutlineIcon
                  style={{
                    color: config.theme.primary,
                    fontSize: 20,
                  }}
                />
              </IconButton>
              }
            </Box>
          )}
        </Popup>
        }

        {/* confirmation for delete desk with reservation canceling */}
        {id === deskToDelete?.id &&
          <DeskDeleteModal floorId={floorId} onDelete={deactivateStates} />
        }
      </Rectangle>
    </>
  );
}
