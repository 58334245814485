import { ActionPayload, BaseErrorResponse, BaseResponse } from 'store/_legacy/Models/ReduxModels';
import { t } from '@lingui/macro';
import {
  GetLocationResourcesRequest,
  GetLocationResourcesResponse,
  Resource,
} from './models';

export const GET_LOCATION_RESOURCES = 'GET_LOCATION_RESOURCES';
export const GET_LOCATION_RESOURCES_FAIL = 'GET_LOCATION_RESOURCES_FAIL';
export const GET_LOCATION_RESOURCES_SUCCESS = 'GET_LOCATION_RESOURCES_SUCCESS';

export interface GetLocationResources {
  type: typeof GET_LOCATION_RESOURCES;
  payload: ActionPayload<GetLocationResourcesRequest>;
}
interface GetLocationResourcesFail {
  type: typeof GET_LOCATION_RESOURCES_FAIL;
  payload: BaseErrorResponse;
}
export interface GetLocationResourcesSuccess {
  type: typeof GET_LOCATION_RESOURCES_SUCCESS;
  payload: BaseResponse<GetLocationResourcesResponse>;
}

type Actions = GetLocationResources
  | GetLocationResourcesFail
  | GetLocationResourcesSuccess;

export interface State {
  error: string;
  loading: boolean;
  // Location resources are stored as objects to make accessing and finding them
  // easier, as well as being able to store multiple location details at the same time
  // without having to worry about array operations, making processing faster
  resources: {
    [resourceId: string]: Resource;
  }
  resourcesByLocation: {
    [locationId: string]: {
      [resourceId: string]: Resource;
    };
  };
}

const initialState: State = {
  error: '',
  loading: false,
  resources: {},
  resourcesByLocation: {},
};

export default function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case GET_LOCATION_RESOURCES:
      return {
        ...state,
        error: '',
        loading: true,
      };
    case GET_LOCATION_RESOURCES_FAIL:
      return {
        ...state,
        error: t`There was an error loading locations.`,
        loading: false,
      };
    case GET_LOCATION_RESOURCES_SUCCESS: {
      const { data: resources } = action.payload.data.result;
      const newResources = { ...state.resources };
      const newResourcesByLocation = { ...state.resourcesByLocation };

      const sampleResource = resources[0];

      if (sampleResource) {
        newResourcesByLocation[sampleResource.locationId] = {};

        resources.forEach(resource => {
          newResources[resource.id] = resource;
          newResourcesByLocation[sampleResource.locationId][resource.id] = resource;
        });
      }

      return {
        ...state,
        error: '',
        loading: false,
        resources: newResources,
        resourcesByLocation: newResourcesByLocation,
      };
    }

    default:
      return state;
  }
}

// Selectors
/**
 * Returns all resources from a location.
 */
export function selectLocationResources({ locationId, state }: { locationId: string, state: State }): Resource[] {
  const locationResources = state.resourcesByLocation[locationId];
  const resources = locationResources
    ? Object.keys(locationResources).map(resourceId => locationResources[resourceId]).filter(resource => resource.isAvailable)
    : [];

  return resources;
}

/**
 * Returns a resource based on its location.
 */
export function selectResourceById({
  locationId,
  resourceId,
  state,
}: {
  locationId: string,
  resourceId: string,
  state: State,
}): Resource | undefined {
  let resource;
  const locationResources = state.resourcesByLocation[locationId];

  if (locationResources) {
    resource = locationResources[resourceId];
  }

  return resource;
}
