import { t } from "@lingui/macro";
import { ArrowBackRounded } from "@mui/icons-material";
import { Box, Button, Divider, IconButton, IconButtonProps, List, ListItem, ListItemButton, Pagination, Skeleton, Typography, useTheme } from "@mui/material";
import { ConfirmationDialog, ListHeader, ListHeaderLabel, TogetherDelete, useToast } from "components";
import { UserRole } from "enums";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { CommPolicy, CommPolicyType, useDeleteCommPolicyByIdMutation, useGetMeQuery, useLazyGetCommPoliciesQuery } from "store";

export const CampaignPolicies: React.FC = () => {
  const { palette } = useTheme();
  const toast = useToast();
  const history = useHistory();
  const [policy, setPolicy] = useState<CommPolicy>();
  const [willDelete, setWillDelete] = useState(false);
  const [page, setPage] = useState(1);
  const [deletePolicyById] = useDeleteCommPolicyByIdMutation();
  const [triggerGetPoliciesQuery, getPoliciesQuery] = useLazyGetCommPoliciesQuery();
  const { data: getMeResponse, isLoading: isLoadingMe } = useGetMeQuery();
  const { data: getPoliciesResponse, isLoading } = getPoliciesQuery;
  const { items: policies, meta } = getPoliciesResponse || {};

  useEffect(() => {
    triggerGetPoliciesQuery({
      page,
      limit: 15,
      filter: { type: CommPolicyType.BRAND },
      include: ["domains"],
      orderBy: "desc:createdAt",
    }, true);
  }, [page, triggerGetPoliciesQuery]);

  const handleDeleteConfirm = () => {
    if (!policy) {
      return;
    }

    void (async () => {
      const response = await deletePolicyById({ policyId: policy.id });

      if ("error" in response) {
        toast.showToast({ severity: "error", message: t`Failed to delete policy, please try again.` });
      } else {
        toast.showToast({ severity: "success", message: t`Policy was deleted.` });
      }

      setPolicy(undefined);
    })();
  };

  const { data: me } = getMeResponse?.result || {};
  const isEditable = me?.role === UserRole.SUPER_ADMIN;
  
  return (
    <>
      <Box display="flex" flexDirection="column" gap={2} py={2}>
        <Box display="flex" gap={1}>
          <IconButton color="primary" component={Link} size="small" to="/admin/campaigns"><ArrowBackRounded fontSize="small" /></IconButton>
          <Typography fontSize={22} fontWeight={600}>{t`Campaign policies`}</Typography>
        </Box>
        <Box alignItems="center" bgcolor={palette.grey[100]} borderRadius={2} display="flex" justifyContent="space-between" px={2} py={1.5}>
          <Typography fontSize={16} fontWeight={600}>{t`Master policy`}</Typography>
          <Button component={Link} size="small" to="/admin/campaigns/policies/master">{t`View policy`}</Button>
        </Box>
        <Box>
          <Box alignItems="center" display="flex" gap={2} mb={1}>
            <Typography fontWeight={600} lineHeight={1}>{t`Brand policy`}</Typography>
            {isEditable ? (
              <Button component={Link} to="/admin/campaigns/policies/new?reset=true">{t`Add Policy`}</Button>
            ) : undefined}
            {isLoadingMe ? <Skeleton height={36} variant="rectangular" width={90} /> : undefined}
          </Box>
          <ListHeader>
            <ListHeaderLabel sx={{ width: 360 }}>{t`Name`}</ListHeaderLabel>
            <ListHeaderLabel sx={{ flex: 1 }}>{t`Domain`}</ListHeaderLabel>
            {isEditable ? <Box component="span" width={30} /> : undefined}
          </ListHeader>
          {isLoading ? (
            <Box minHeight={645}>
              <Skeleton height={45} sx={{ borderRadius: 0 }} variant="rectangular" />
              <Divider sx={{ opacity: 0.6 }} />
              <Skeleton height={45} sx={{ borderRadius: 0 }} variant="rectangular" />
              <Divider sx={{ opacity: 0.6 }} />
              <Skeleton height={45} sx={{ borderRadius: 0 }} variant="rectangular" />
            </Box>
          ) : policies?.length ? (
            <List disablePadding sx={{ mb: 2 }}>
              {policies?.map((policy) => {
                const handleDeleteClick: IconButtonProps["onClick"] = (event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  setPolicy(policy);
                  setWillDelete(true);
                };

                const domains = policy?.domains?.map(({ name }) => name).join(", ");

                return (
                  <ListItem disablePadding divider key={policy.id} sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.05)" }}>
                    <ListItemButton onClick={() => history.push(`/admin/campaigns/policies/${policy.id}`)} sx={{ px: 1, py: 1.5, gap: 1, flex: 1 }}>
                      <Typography fontSize={14} noWrap title={policy.name} width={360}>{policy.name}</Typography>
                      <Typography flex={1} fontSize={14} noWrap title={domains}>{domains}</Typography>
                      {isEditable ? (
                        <IconButton color="primary" onClick={handleDeleteClick} size="small">
                          <TogetherDelete sx={{ fill: palette.primary.main, width: 16, height: 16 }} />
                        </IconButton>
                      ) : undefined}
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          ) : (
            <Typography color={palette.grey[700]} fontSize={14} my={4} textAlign="center">
              {t`No brand policies yet.`}
            </Typography>
          )}
          {(meta?.pages || 0) > 1 ? (
            <Pagination count={meta?.pages} onChange={(_, page) => setPage(page)} page={page} shape="rounded" />
          ) : undefined}
        </Box>
      </Box>
      <ConfirmationDialog
        description={t`Are you sure you want to delete brand policy "${policy?.name}", and all user policies related to it?`}
        onClose={() => setWillDelete(false)}
        onConfirm={handleDeleteConfirm}
        open={!!willDelete}
        title={t`Delete brand policy`}
      />
    </>
  );
};
