import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Trans, t } from "@lingui/macro";
import styles from "../../styles.module.scss";

import { regexEmail } from "../../../../../../../../Functions/helpers/regex";
import Text from "components/_legacy/Text";
import Box from "components/_legacy/Box";
import Checkbox from "components/_legacy/Checkbox";
import TextField from "components/_legacy/TextField";
import { updateMeeting } from "../../../../../../../Store/floorMapDuck";
import { FloorMapObject } from "../../../../../../../Store/floorMapDuck/models";
import { RoomServiceType } from "App/Store/Meetings/meetingRooms/models";
import { ResourceAvailableService } from "Admin/Store/floorMapDuck/meeting/models";

interface Props {
  drawingObject: FloorMapObject; // TODO - in future use separate models for desk/section/meeting object
}

export default function Catering(props: Props) {
  const dispatch = useDispatch();
  const { drawingObject } = props;
  const { id, availableServices } = drawingObject;
  const catering = availableServices?.find(({ type }) => type === RoomServiceType.CATERING);
  const cateringInitEmails = catering?.emails?.[0] || "";
  const [cateringData, setCateringData] = useState<string | undefined>(cateringInitEmails);
  const [cateringError, setCateringError] = useState(false);

  useEffect(() => {
    setCateringData(cateringInitEmails);
  }, [cateringInitEmails]);

  const setMeetingError = (hasError: boolean) => {
    dispatch(updateMeeting({
      meetingId: typeof id === "string" ? parseInt(id) : id,
      meeting: {
        hasError,
      },
    }));
  };

  const onSetErrorHandlers = (hasError: boolean) => {
    setCateringError(hasError);
    setMeetingError(hasError);
  };

  const onToggleCatering = (available: boolean) => {
    if (!available) {
      onSetErrorHandlers(false);
    } else {
      onSetErrorHandlers(!cateringData?.length);
    }

    if (available) {
      dispatch(updateMeeting({
        meetingId: typeof id === "string" ? parseInt(id) : id,
        meeting: {
          availableServices: [...(availableServices || []), { type: RoomServiceType.CATERING }],
        },
      }));
      setCateringData(undefined);
    } else {
      dispatch(updateMeeting({
        meetingId: typeof id === "string" ? parseInt(id) : id,
        meeting: {
          availableServices: availableServices?.filter(({ type }) => type !== RoomServiceType.CATERING),
        },
      }));
    }
  };

  const onChangeCateringData = (email: string) => {
    const isValid = new RegExp(regexEmail).test(email);
    onSetErrorHandlers(!isValid);

    setCateringData(email);
  };

  const onSaveCatering = (email?: string) => {
    if (catering && email) {
      dispatch(updateMeeting({
        meetingId: typeof id === "string" ? parseInt(id) : id,
        meeting: {
          availableServices: availableServices?.map((service): ResourceAvailableService => {
            return service.type === RoomServiceType.CATERING ? { ...service, emails: [email] } : service; 
          }),
        },
      }));
    }
  };

  return (
    <Box className={styles.amenitiesItems}>
      <Checkbox
        checked={!!catering}
        classes={{checkbox: styles.checkbox}}
        key={id}
        label={<Box><Text size="md">I need catering</Text></Box>}
        name={'catering'}
        onChange={(value) => onToggleCatering(value)}
      />

      {!!catering &&
        <Box className={styles.detailsItem}>
          <Text color="gray" size="md">
            <Trans>Email:</Trans>
          </Text>

          <TextField
            classes={{ input: styles.textFieldInput }}
            error={cateringError}
            helperText={cateringError ? t`This should be a valid email` : ''}
            id={'catering-emails'}
            onBlur={() => onSaveCatering(cateringData)}
            onChange={(event) => onChangeCateringData(event.target.value)}
            placeholder={t`Type here`}
            type="email"
            value={cateringData}
          />
        </Box>
      }
    </Box>
  );
}
