import Box from "components/_legacy/Box";
import styles from "./styles.module.scss";
import Text from "components/_legacy/Text";
import { Room } from "../../../../../../Store/Meetings/meetingRooms/models";
import { Trans } from "@lingui/macro";
import getDisplayedResourceName from "../../../../../../../Functions/resourseNameParsing";

interface Props {
  roomInformation?: Room;
}

export default function Amenities(props: Props) {
  const { roomInformation } = props;

  return (
    <Box gap={5}>
      <Box marginBottom={10}>
        <Text weight="semi-bold">
          <Trans>
            Amenities
          </Trans>
        </Text>
      </Box>
      <Box className={styles.items} dataTestId="bookingItemRoomResources">
        {roomInformation?.amenities.length ? (
          roomInformation.amenities.map(({ name }) => {
            return name ? (
              <Box className={styles.item} key={name}>
                <Text color="gray" size="md">
                  {getDisplayedResourceName(name)}
                </Text>
              </Box>
            ) : null;
          })
        ) : (
          <Box margin={5}>
            <Text>
              -
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  );
}
