import {JustInTimeReservationSelectedData, SetJustInTimeReservationDataRequest} from './models';
import {BookingType} from "../../../App/Store/Bookings/bookingDuck/models";
import {WeekDaysObject, WeeklySlot, WeeklySlotDay} from "../../../App/Store/Bookings/createNewBookingDuck/models";

const SET_JUST_IN_TIME_RESERVATION_DATA = 'SET_JUST_IN_TIME_RESERVATION_DATA';

interface SetJustInTimeReservationData {
  type: typeof SET_JUST_IN_TIME_RESERVATION_DATA;
  payload: SetJustInTimeReservationDataRequest;
}

type Actions = SetJustInTimeReservationData

export interface State {
  error: string;
  successMessage: string;
  bookingType: BookingType;
  filters: { [key: string]: boolean };
  dateFrom: Date;
  dateTo: Date;
  timeFrom?: Date;
  timeTo?: Date;
  weekDays: WeekDaysObject;
  selectedData: JustInTimeReservationSelectedData;
  weeklySlots?: WeeklySlot[];
}

const initialState: State = {
  error: '',
  successMessage: '',
  bookingType: 'custom',
  filters: {},
  dateFrom: new Date(),
  dateTo: new Date(),
  timeFrom: new Date(1970, 0, 1, 9),
  timeTo: new Date(1970, 0, 1, 18),
  weekDays: {
    0: {
      label: 'Sun',
      selected: false,
      timeFrom: 9,
      timeTo: 18,
      value: 0,
    },
    1: {
      label: 'Mon',
      selected: false,
      timeFrom: 9,
      timeTo: 18,
      value: 1,
    },
    2: {
      label: 'Tue',
      selected: false,
      timeFrom: 9,
      timeTo: 18,
      value: 2,
    },
    3: {
      label: 'Wed',
      selected: false,
      timeFrom: 9,
      timeTo: 18,
      value: 3,
    },
    4: {
      label: 'Thu',
      selected: false,
      timeFrom: 9,
      timeTo: 18,
      value: 4,
    },
    5: {
      label: 'Fri',
      selected: false,
      timeFrom: 9,
      timeTo: 18,
      value: 5,
    },
    6: {
      label: 'Sat',
      selected: false,
      timeFrom: 9,
      timeTo: 18,
      value: 6,
    },
  },
  weeklySlots: [
    {
      index: 0,
      day: WeeklySlotDay.SUNDAY,
      isSelected: false,
      timeFrom: new Date(1970, 0, 1, 9),
      timeTo: new Date(1970, 0, 1, 18),
    },
    {
      index: 1,
      day: WeeklySlotDay.MONDAY,
      isSelected: false,
      timeFrom: new Date(1970, 0, 1, 9),
      timeTo: new Date(1970, 0, 1, 18),
    },
    {
      index: 2,
      day: WeeklySlotDay.TUESDAY,
      isSelected: false,
      timeFrom: new Date(1970, 0, 1, 9),
      timeTo: new Date(1970, 0, 1, 18),
    },
    {
      index: 3,
      day: WeeklySlotDay.WEDNESDAY,
      isSelected: false,
      timeFrom: new Date(1970, 0, 1, 9),
      timeTo: new Date(1970, 0, 1, 18),
    },
    {
      index: 4,
      day: WeeklySlotDay.THURSDAY,
      isSelected: false,
      timeFrom: new Date(1970, 0, 1, 9),
      timeTo: new Date(1970, 0, 1, 18),
    },
    {
      index: 5,
      day: WeeklySlotDay.FRIDAY,
      isSelected: false,
      timeFrom: new Date(1970, 0, 1, 9),
      timeTo: new Date(1970, 0, 1, 18),
    },
    {
      index: 6,
      day: WeeklySlotDay.SATURDAY,
      isSelected: false,
      timeFrom: new Date(1970, 0, 1, 9),
      timeTo: new Date(1970, 0, 1, 18),
    },
  ],
  selectedData: {
    user: null,
    location: null,
    dateTime: '',
    floor: null,
    desk: null,
    parkingSpot: null,
  },
};

export default function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case SET_JUST_IN_TIME_RESERVATION_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
}

// Actions
export function setJustInTimeReservationData(data: SetJustInTimeReservationDataRequest): SetJustInTimeReservationData {
  return {
    type: SET_JUST_IN_TIME_RESERVATION_DATA,
    payload: data,
  };
}
