import Box from 'components/_legacy/Box';
import Button from 'components/_legacy/Button';
import FromToTimeUI from './FromToTimeUI';
import HourSlider from './HourSlider';
import moment from 'moment';
import styles from './styles.module.scss';
import Text from 'components/_legacy/Text';
import { Calendar as CalendarDateRange } from 'react-date-range';
import { NotificationModel } from '../../../Store/notifications/models';
import { Trans } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useTypedSelector } from 'store/_legacy/Redux/store';
import {
  setWhenToSendDate,
} from '../../../Store/notifications';

/**
 * Calendar component to select date and time ranges.
 */
interface WhenToSendCalendarProps {
  notification?: NotificationModel; // data used for edit state
}

const getWhenToSendDateFormat = (date: Date | string) => {
  const hour = parseInt(moment(date).utc().format('H'));
  const minutes = parseInt(moment(date).utc().format('m')) / 0.6 / 100;

  return hour + minutes;
};

const getWhenToSendDate = (sendDate: Date | string | undefined, whenToSend: Date | string) => {
  if (sendDate && !whenToSend) { // return data on first open (in case of edit state)
    return new Date(sendDate);
  }

  if (whenToSend) { // if user updated sendDate
    return new Date(whenToSend);
  }

  return new Date(moment().utc().format()); // return no data on first open (in case of add state)
};

const getWhenToSendHour = (sendDate: Date | string | undefined, whenToSend: Date | string) => {
  if (sendDate && !whenToSend) { // return data on first open (in case of edit state)
    return getWhenToSendDateFormat(sendDate);
  }

  if (whenToSend) { // if user updated sendDate
    return getWhenToSendDateFormat(whenToSend);
  }

  return 9; // return no data on first open (in case of add state)
};

export default function WhenToSendCalendar(props: WhenToSendCalendarProps) {
  const dispatch = useDispatch();
  const { adminNotifications } = useTypedSelector(state => state);

  const initSelectedDate = getWhenToSendDate(props.notification?.sendDate, adminNotifications.notificationsTimeSettings.whenToSend);
  const [selectedDate, setSelectedDate] = useState(initSelectedDate);

  const notifWhenToHours = getWhenToSendHour(props.notification?.sendDate, adminNotifications.notificationsTimeSettings.whenToSend);
  const [hours, setHours] = useState(notifWhenToHours);

  const handleSelect = (date: any) => {
    setSelectedDate(date);
  };

  const onResetWhenToSendData = () => {
    setSelectedDate(new Date());
    setHours(9);
  };

  const onSetTodayWhenToSendData = () => {
    setSelectedDate(new Date());
  };

  const onSubmitDate = () => {
    // to do close modal after click

    const hour = Math.trunc(hours);
    const minutes = Number((hours - hour).toFixed(2)) * 100 * 0.6;
    const whenToSendDate = new Date(selectedDate);
    whenToSendDate.setHours(hour, minutes, 0);
    const whenToSendDateUTC = moment(whenToSendDate).utc(true).format();

    dispatch(setWhenToSendDate(whenToSendDateUTC));
  };

  return (
    <Box className={styles.calendar}>
      <Box>
        <CalendarDateRange
          date={selectedDate}
          minDate={new Date()}
          onChange={handleSelect}
          showMonthAndYearPickers={false}
        />

        <Box alignItems="center" display="flex" justifyContent="between" marginBottom={14} marginTop={10}>
          <Text size="md" weight="semi-bold">
            <Trans>Time</Trans>
          </Text>

          <Box>
            <FromToTimeUI timeFrom={hours} />
          </Box>

        </Box>
        <HourSlider hours={hours} setHours={setHours} />
      </Box>

      <Box alignItems="center" className={styles.buttons} display="flex" justifyContent="between" marginBottom={8}>
        <Button className={styles.buttonToday} noPaddingX onClick={onSetTodayWhenToSendData} type="clear">
          <Trans>Today</Trans>
        </Button>

        <Box>
          <Button noPaddingX onClick={onResetWhenToSendData} type="clear">
            <Trans>Reset</Trans>
          </Button>

          <Button noPaddingX onClick={onSubmitDate} type="primary">
            <Trans>Done</Trans>
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
