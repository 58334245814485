import { SvgIconComponent } from "@mui/icons-material";
import { Button, ButtonProps, useTheme } from "@mui/material";
import React from "react";

export const EditorToolbarButton: React.FC<ButtonProps & { Icon: SvgIconComponent; active?: boolean }> = (props) => {
  const { Icon, active, disabled, children, onClick, onMouseDown, ...rest } = props;
  const { palette } = useTheme();

  const handleClick: ButtonProps["onClick"] = (event) => {
    event.preventDefault();
    onClick?.(event);
  };

  const handleMouseDown: ButtonProps["onMouseDown"] = (event) => {
    event.preventDefault();
    onMouseDown?.(event);
  };

  return (
    <Button
      {...rest}
      disabled={disabled}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      sx={{ p: 0.5, minWidth: 0, borderRadius: 0, bgcolor: active ? palette.grey[50] : undefined, ...rest?.sx }}
    >
      <Icon
        fontSize="small"
        sx={{ color: active ? palette.primary.main : palette.grey[700], opacity: disabled ? 0.5 : 1 }}
      />
      {children}
    </Button>
  );
};
