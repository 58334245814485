interface Props {
  color?: string;
  size?: number;
}

export default function Floor({ color = '#4A4A4A', size = 16 }: React.PropsWithChildren<Props>) {
  return (    
    <svg height={size} version="1.1" viewBox={`0 0 ${size} ${size}`} width={size}>        
        <g fill="none" fillRule="evenodd" id="Rooms-booking" stroke="none" strokeWidth="1">
            <g id="1.2.0-Book-a-room-select-room" transform="translate(-395.000000, -221.000000)">
                <g id="Room-1" transform="translate(100.000000, 175.000000)">
                    <rect fill="none" height="170" id="Rectangle" rx="14" width="770" x="0" y="0"></rect>
                    <g id="labels/amenities" transform="translate(295.000000, 42.000000)">
                        <g id="icons/location" transform="translate(0.000000, 4.000000)">
                            <g id="icons/floor" transform="translate(0.000000, 0.000000)"></g>
                            <path d="M12,1 C13.1045695,1 14,1.8954305 14,3 L14,15 L2,15 L2,3 C2,1.8954305 2.8954305,1 4,1 Z M12,3 L4,3 L4,13 L12,13 L12,3 Z M7,10 C7.55228475,10 8,10.4477153 8,11 C8,11.5522847 7.55228475,12 7,12 L6,12 C5.44771525,12 5,11.5522847 5,11 C5,10.4477153 5.44771525,10 6,10 L7,10 Z M10,10 C10.5522847,10 11,10.4477153 11,11 C11,11.5522847 10.5522847,12 10,12 C9.44771525,12 9,11.5522847 9,11 C9,10.4477153 9.44771525,10 10,10 Z M7,7 C7.55228475,7 8,7.44771525 8,8 C8,8.55228475 7.55228475,9 7,9 L6,9 C5.44771525,9 5,8.55228475 5,8 C5,7.44771525 5.44771525,7 6,7 L7,7 Z M10,7 C10.5522847,7 11,7.44771525 11,8 C11,8.55228475 10.5522847,9 10,9 C9.44771525,9 9,8.55228475 9,8 C9,7.44771525 9.44771525,7 10,7 Z M7,4 C7.55228475,4 8,4.44771525 8,5 C8,5.55228475 7.55228475,6 7,6 L6,6 C5.44771525,6 5,5.55228475 5,5 C5,4.44771525 5.44771525,4 6,4 L7,4 Z M10,4 C10.5522847,4 11,4.44771525 11,5 C11,5.55228475 10.5522847,6 10,6 C9.44771525,6 9,5.55228475 9,5 C9,4.44771525 9.44771525,4 10,4 Z" fill={color} fillRule="nonzero" id="Combined-Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>    
  );
}
