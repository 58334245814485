import Box from 'components/_legacy/Box';
import CheckboxCustom from 'components/_legacy/CheckBoxCustom';
import styles from './styles.module.scss';
import { filterStatusType } from '../../../../Store/reservations/models';
import { FormControlLabel } from '@material-ui/core';
import { setReservationsData } from '../../../../Store/reservations';
import { t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'store/_legacy/Redux/store';
import FilterAccordion from "components/_legacy/Filters/FilterAccordion";

interface StatusFilterProps {
  filters: { [key in filterStatusType]: boolean },
  initialFiltersState: { [key: string]: boolean },
  setFilters: Function,
}

export default function StatusFilter(props: StatusFilterProps) {
  const dispatch = useDispatch();
  const { filters: reduxFilters } = useTypedSelector(state => state.adminReservations);
  const { filters, initialFiltersState, setFilters } = props;
  const localCounter = Object.keys(filters).map((key: any) => key).filter((key: filterStatusType) => filters[key]).length;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({ ...filters, [event.target.name]: event.target.checked });
  };

  const onResetFilter = () => {
    dispatch(setReservationsData({
      filters: {
        ...reduxFilters,
        statuses: [],
      },
    }));

    setFilters(initialFiltersState);
  };

  return (
    <Box display="flex" justifyContent="between" marginTop={10}>
      <FilterAccordion headerName="Status" localCounter={localCounter} resetFilter={onResetFilter}>
        <Box className={styles.filtersFormControlWrapper} display="grid">
          <FormControlLabel
            className={`${styles.bookedStyle} ${filters.BOOKED ? styles.checkedLabel : ''}`}
            control={<CheckboxCustom checked={filters.BOOKED} classes={{ checked: styles.checked }} name="BOOKED" onChange={handleChange} />}
            label={t`Booked`}
          />
          <FormControlLabel
            className={`${styles.pendingStyle} ${filters.PENDING ? styles.checkedLabel : ''}`}
            control={<CheckboxCustom checked={filters.PENDING} classes={{ checked: styles.checked }} name="PENDING" onChange={handleChange} />}
            label={t`Pending`}
          />
          <FormControlLabel
            className={`${styles.cancelledStyle} ${filters.CANCELED ? styles.checkedLabel : ''}`}
            control={<CheckboxCustom checked={filters.CANCELED} classes={{ checked: styles.checked }} name="CANCELED" onChange={handleChange} />}
            label={t`Cancelled`}
          />
          <FormControlLabel
            className={`${styles.passedStyle} ${filters.PAST ? styles.checkedLabel : ''}`}
            control={<CheckboxCustom checked={filters.PAST} classes={{ checked: styles.checked }} name="PAST" onChange={handleChange} />}
            label={t`Past`}
          />
        </Box>
      </FilterAccordion>
    </Box>
  );
}
