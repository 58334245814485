import L from 'leaflet';

/**
 * Receives an image height and size with an already calculated proportion
 * that it must have, and returns a Latitude/Longitude expression to create
 * that image into a square starting from point 0,0.
 */
export function getImageBoundsLimited({
  height,
  width,
  proportion,
}: {
  height: number;
  width: number
  proportion: number;
}): L.LatLngBoundsExpression {
  const limitedHeight = height * proportion / 100;
  const limitedWidth = width * proportion / 100;

  const latlng = L.CRS.Simple.pointToLatLng(L.point(-limitedHeight, -limitedWidth), 0);

  return [[latlng.lng, 0], [0, latlng.lat]];
}
