import React, { useCallback, useMemo } from "react";
import { EditorElementProps, EditorFormatMark, EditorLeafProps, EditorProps } from "./types";
import { Descendant, createEditor } from "slate";
import { EditorElementType } from "./enums";
import { EditorElement } from "./editor-element";
import { EditorLeaf } from "./editor-leaf";
import { withHistory } from "slate-history";
import { Editable, Slate, withReact } from "slate-react";
import { t } from "@lingui/macro";
import isHotkey from "is-hotkey";
import { toggleEditorFormatMark, withCopyPaste, withElements } from "./utils";
import { Box, useTheme } from "@mui/material";
import { EditorToolbar } from "./editor-toolbar";

const hotkeys: Record<string, EditorFormatMark> = {
  'mod+b': "bold",
  'mod+i': "italic",
  'mod+u': "underline",
};

const defaultInitialValue: Descendant[] = [{ type: EditorElementType.PARAGRAPH, children: [{ text: "" }] }];

export const Editor: React.FC<EditorProps> = (props) => {
  const { initialValue = defaultInitialValue, onChange } = props;
  const { palette } = useTheme();
  const renderElement = useCallback<React.FC<EditorElementProps>>((props) => <EditorElement {...props} />, []);
  const renderLeaf = useCallback<React.FC<EditorLeafProps>>((props) => <EditorLeaf {...props} />, []);
  const editor = useMemo(() => withCopyPaste(withElements(withHistory(withReact(createEditor())))), []);

  return (
    <Slate editor={editor} initialValue={initialValue} onChange={onChange}>
      <Box bgcolor={palette.grey[100]} borderRadius={2} display="flex" flexDirection="column" gap={1} p={1}>
        <EditorToolbar />
        <Editable
          autoFocus
          onKeyDown={(event) => {
            for (const hotkey in hotkeys) {
              if (isHotkey(hotkey, event)) {
                event.preventDefault();
                const mark = hotkeys[hotkey];

                toggleEditorFormatMark(editor, mark);
              }
            }
          }}
          placeholder={t`Type here`}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          spellCheck
          style={{ outline: "none" }}
        />
      </Box>
    </Slate>
  );
};
