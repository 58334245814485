import { useTypedSelector } from "store/_legacy/Redux/store";
import { Trans } from "@lingui/macro";

import Box from "components/_legacy/Box";
import Button from "components/_legacy/Button";
import { GroupCreateEditFormTabEnum, TabsState } from "../../../../../Store/groups/forms/createEditForm/models";
import { useDispatch } from "react-redux";
import { setGroupsCreateEditFormData } from "../../../../../Store/groups/forms/createEditForm";

export default function Tabs() {
  const dispatch = useDispatch();
  const { tabsState } = useTypedSelector(state => state.groupCreateEditForm);

  const onChangeTabIndex = (tabsState: TabsState) => {
    dispatch(setGroupsCreateEditFormData({ tabsState }));
  };

  return (
    <Box className="tabs-custom">
      <Button
        className={tabsState === GroupCreateEditFormTabEnum.newGroup ? 'active' : ''}
        onClick={() => onChangeTabIndex(GroupCreateEditFormTabEnum.newGroup)}
        size="sm"
      >
        <Trans>New</Trans>
      </Button>

      <Button
        className={tabsState ===  GroupCreateEditFormTabEnum.activeDirectory ? 'active' : ''}
        onClick={() => onChangeTabIndex(GroupCreateEditFormTabEnum.activeDirectory)}
        size="sm"
      >
        <Trans>From Active Directory</Trans>
      </Button>
    </Box>
  );
}
