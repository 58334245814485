import { addMonths, getDate } from "date-fns";
import { MonthlyRecurrenceOptions } from "types/monthly-recurrence-options.type";

export const resolveDefaultMonthlyRecurrenceOptions = (options?: Partial<MonthlyRecurrenceOptions>): MonthlyRecurrenceOptions => {
  const start = options?.start || new Date();

  return {
    start,
    type: "monthly",
    end: options?.end || addMonths(start, 4),
    interval: options?.interval || 1,
    dayOfMonth: getDate(start),
  };
};
