import { Box, BoxProps, Typography, useTheme } from "@mui/material";

export const CountBadge: React.FC<BoxProps> = (props) => {
  const { children, ...rest } = props;
  const { palette } = useTheme();

  return (
    <Box
      alignItems="center"
      bgcolor={palette.secondary.main}
      borderRadius={2}
      display="flex"
      justifyContent="center"
      px="7px"
      py="5px"
      {...rest}
    >
      <Typography color="primary" fontSize={14} fontWeight="600" lineHeight={1} minWidth={10} textAlign="center">{children}</Typography>
    </Box>
  );
};