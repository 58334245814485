import Api from 'store/_legacy/Services/Api';
import Box from 'components/_legacy/Box';
import Button from 'components/_legacy/Button';
import DateRangeCalendar from '../NotificationCalendar/DateRangeCalendar';
import moment from 'moment';
import Popup from 'reactjs-popup';
import Select from 'react-select';
import styles from './styles.module.scss';
import TextField from '@material-ui/core/TextField';
import WhenToSendCalendar from '../NotificationCalendar/WhenToSendCalendar';
import { Trans, t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTypedSelector } from 'store/_legacy/Redux/store';
import { DropdownIndicator } from 'components/_legacy/Select';
import {
  CREATE_NOTIFICATION_SUCCESS,
  EDIT_NOTIFICATION_SUCCESS,
  createNotification,
  editNotification,
  sendNotification,
} from '../../../Store/notifications';
import {
  BookingDateRangeModel,
  CreateNotificationModel,
  NotificationModel,
  Recipient,
} from '../../../Store/notifications/models';

interface OpenQuestionFormProps {
  actionState: 'add' | 'edit';
  notification?: NotificationModel; // data used for edit state
  onCancelAction: Function;
}

const recipientsList: Array<{ label: string; value: Recipient; }> = [
  { label: t`All`, value: 'all' },
  { label: t`Who has booking`, value: 'whoHasBooking' },
];

const getWhenToSendDate = (sendDate: Date | string | undefined, whenToSend: Date | string) => {
  if (sendDate && !whenToSend) { // return data on first open (in case of edit state)
    return sendDate;
  }

  if (whenToSend) { // if user updated sendDate
    return whenToSend;
  }

  return new Date(moment().utc().format()); // return no data on first open (in case of add state)
};

const getWhenToSendDateFormat = (sendDate: Date | string | undefined, whenToSend: Date | string) => {
  if (sendDate && !whenToSend) { // return data on first open (in case of edit state)
    return moment(sendDate).utc().format('MMM DD, h:mma');
  }

  if (whenToSend) { // if user updated sendDate
    return moment(whenToSend).utc().format('MMM DD, h:mma');
  }

  return ''; // return no data on first open (in case of add state)
};

const getBookingDateRangeDates = (
  startDate: Date | string | null | undefined,
  endDate: Date | string | null | undefined,
  bookingDateRange: BookingDateRangeModel,
) => {
  if (startDate && endDate && !bookingDateRange.startDate) { // return data on first open (in case of edit state)
    return {
      startDate: startDate,
      endDate: endDate,
    };
  }

  if (bookingDateRange.startDate && bookingDateRange.endDate) { // if user updated bookingDateRange
    return {
      startDate: bookingDateRange.startDate,
      endDate: bookingDateRange.endDate,
    };
  }

  return {
    startDate: new Date(moment().utc().format()),
    endDate: new Date(moment().utc().format()),
  }; // return no data on first open (in case of add state)
};

const getbookingDateRangeDateFormat = (
  startDate: Date | string | null | undefined,
  endDate: Date | string | null | undefined,
  bookingDateRange: BookingDateRangeModel,
) => {
  if (startDate && endDate && !bookingDateRange.startDate) { // return data on first open (in case of edit state)
    return `${moment(startDate).utc().format('MMM DD')} - ${moment(endDate).utc().format('MMM DD')}`;
  }

  if (bookingDateRange.startDate && bookingDateRange.endDate) { // if user updated bookingDateRange
    return `${moment(bookingDateRange.startDate).utc().format('MMM DD')} - ${moment(bookingDateRange.endDate).utc().format('MMM DD')}`;
  }

  return ''; // return no data on first open (in case of add state)
};

export default function NotificationForm(props: OpenQuestionFormProps) {
  const dispatch = useDispatch();
  const { locationId } = useParams<{ locationId: string }>();
  const { adminNotifications } = useTypedSelector(state => state);

  const {
    notification,
    actionState,
    onCancelAction,
  } = props;

  const notifSubject = notification?.subject ? notification.subject : '';
  const notifMessage = notification?.message ? notification.message : '';
  const notifWhenToSend = getWhenToSendDateFormat(notification?.sendDate, adminNotifications.notificationsTimeSettings.whenToSend);
  const notifBookingDateRange = getbookingDateRangeDateFormat(notification?.startDate, notification?.endDate, adminNotifications.notificationsTimeSettings.bookingDateRange);
  const notifRecipients = notification && notification.recipients ?
    recipientsList.find(recipient => recipient.value === notification.recipients) : recipientsList[0];

  const [notifSubjectValue, setNotifSubjectValue] = useState(notifSubject);
  const [notifMessageValue, setNotifMessageValue] = useState(notifMessage);
  const [notifRecipientsValue, setNotifRecipientsValue] = useState(notifRecipients);

  const hasBookingSet = notifRecipientsValue && notifRecipientsValue.value === 'whoHasBooking';

  const [notifSubjectError, setNotifSubjectError] = useState(false);
  const [notifMessageError, setNotifMessageError] = useState(false);
  const [notifWhenToSendError, setNotifWhenToSendError] = useState(false);
  const [notifDateRangeError, setNotifDateRangeError] = useState(false);

  // unset error if add when to send / date range
  useEffect(() => {
    if (notifWhenToSend.length) {
      setNotifWhenToSendError(false);
    }

    if (notifBookingDateRange.length) {
      setNotifDateRangeError(false);
    }
  }, [notifWhenToSend, notifBookingDateRange]);

  const onSubjectChange = (event: any) => {
    setNotifSubjectValue(event.target.value);

    if (event.target.value) {
      setNotifSubjectError(false);
    }
  };

  const onMessageChange = (event: any) => {
    setNotifMessageValue(event.target.value);

    if (event.target.value) {
      setNotifMessageError(false);
    }
  };

  const validateFields = () => {
    let validate = true;

    if (!notifSubjectValue.length || !notifMessageValue.length
      || !notifWhenToSend.length || (hasBookingSet && !notifBookingDateRange.length)) {
      validate = false;
    }

    // set errors messages
    if (!notifSubjectValue.length) {
      setNotifSubjectError(true);
    }

    if (!notifMessageValue.length) {
      setNotifMessageError(true);
    }

    if (!notifWhenToSend.length) {
      setNotifWhenToSendError(true);
    }

    if (hasBookingSet && !notifBookingDateRange.length) {
      setNotifDateRangeError(true);
    }

    return validate;
  };

  const onQuestionFormCollectData = () => {
    // collect data from fields
    const sendDate = getWhenToSendDate(props.notification?.sendDate, adminNotifications.notificationsTimeSettings.whenToSend);
    const startDate = getBookingDateRangeDates(props.notification?.startDate, props.notification?.endDate, adminNotifications.notificationsTimeSettings.bookingDateRange).startDate;
    const endDate = getBookingDateRangeDates(props.notification?.startDate, props.notification?.endDate, adminNotifications.notificationsTimeSettings.bookingDateRange).endDate;

    const notification: CreateNotificationModel = {
      subject: notifSubjectValue,
      message: notifMessageValue,
      sendDate,
      // @ts-ignore
      recipients: notifRecipientsValue && notifRecipientsValue.value,
    };

    if (notifRecipientsValue && notifRecipientsValue.value === 'whoHasBooking') {
      notification.startDate = startDate;
      notification.endDate = endDate;
    }

    // add error if validation error
    if (!validateFields()) {
      return;
    }

    return notification;
  };

  const onQuestionFormSubmit = () => {
    const notification = onQuestionFormCollectData();

    if (notification) {
      // submit logic
      if (actionState === 'add') {
        dispatch(createNotification({ notification, locationId }));
      }
      if (actionState === 'edit') {
        const notificationId = props.notification && props.notification.id || '';
        dispatch(editNotification({ notification, notificationId }));
      }
      onCancelAction();
    }
  };

  const onSendNotification = (notification: NotificationModel) => {
    const notificationEmailData = {
      recipientType: notification.recipients,
      startDate: notification.startDate,
      endDate: notification.endDate,
    };

    dispatch(sendNotification({ notificationId: notification.id, notificationEmailData }));
  };

  const onQuestionFormSubmitSend = async () => {
    const notification = onQuestionFormCollectData();

    if (notification) {
      // submit and send logic
      if (actionState === 'add') {
        const action = createNotification({ notification, locationId });
        const payload = await Api(action);
        if (payload.status === 200 || payload.status === 201) {
          dispatch({ type: CREATE_NOTIFICATION_SUCCESS, payload });
          const resultNotification = payload.data.result.data;
          onSendNotification(resultNotification);
        }
      }
      if (actionState === 'edit') {
        const notificationId = props.notification && props.notification.id || '';
        const action = editNotification({ notification, notificationId });
        const payload = await Api(action);
        if (payload.status === 200 || payload.status === 201) {
          dispatch({ type: EDIT_NOTIFICATION_SUCCESS, payload });
          const resultNotification = payload.data.result.data;
          onSendNotification(resultNotification);
        }
      }
      onCancelAction();
    }
  };

  const onUpdateRecipients = (item: any) => {
    setNotifRecipientsValue(item);
  };

  const notifSubjectErrorClass = notifSubjectError ? styles.formErrorShow : '';
  const notifMessageErrorClass = notifMessageError ? styles.formErrorShow : '';
  const notifWhenToSendErrorClass = notifWhenToSendError ? styles.formErrorShow : '';
  const notifDateRangeErrorClass = notifDateRangeError ? styles.formErrorShow : '';
  const submitButtonDisabled = !notifSubjectValue.length || !notifMessageValue.length
    || !notifWhenToSend.length || (hasBookingSet && !notifBookingDateRange.length);
  const actionEditClass = actionState === 'edit' ? styles.notificationFormEdit : '';

  return (
    <div className={`${styles.notificationForm} ${actionEditClass}`}>
      <Box alignItems="baseline" display="flex" justifyContent="between" marginBottom={15}>
        <Box className={styles.inputSubject}>
          <h5><span className={styles.requiredSign}>*</span>Subject</h5>
          <TextField
            className={`input input--default ${styles.inputInline} ${notifSubjectErrorClass}`}
            defaultValue={notifSubject}
            fullWidth
            onChange={onSubjectChange}
            placeholder={t`Type here`}
            rows={5}
            variant="outlined"
          />
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          {notifSubjectError ? <div className={styles.formErrors}><Trans>This field can't be empty</Trans></div> : null}
        </Box>
      </Box>
      <Box alignItems="baseline" className={styles.inputDatesWrapper} display="flex" marginBottom={15}>
        <Box className={styles.inputDate}>
          <h5><span className={styles.requiredSign}>*</span><Trans>Recipients</Trans></h5>
          <Select
            className="react-select-custom"
            classNamePrefix="select-custom"
            components={{ DropdownIndicator }}
            defaultValue={notifRecipientsValue}
            isClearable={false}
            onChange={(item) => onUpdateRecipients(item)}
            options={recipientsList}
          />
        </Box>
        {
          hasBookingSet ?
            <Box className={styles.inputDate}>
              <h5><span className={styles.requiredSign}>*</span>Booking date range</h5>
              <Popup
                closeOnDocumentClick
                contentStyle={{ marginLeft: 27, width: 230 }}
                key={notifBookingDateRange} // prop to rerender TextField after notifBookingDateRange change
                position="bottom right"
                trigger={
                  <TextField
                    className={`input input--default ${styles.inputInline} ${notifDateRangeErrorClass}`}
                    defaultValue={notifBookingDateRange}
                    fullWidth
                    placeholder={t`Select date and time`}
                    rows={5}
                    variant="outlined"
                  />
                }
              >
                <DateRangeCalendar notification={notification} />
              </Popup>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              {notifDateRangeErrorClass ? <div className={styles.formErrors}><Trans>This field can't be empty</Trans></div> : null}
            </Box> : null
        }
        <Box className={styles.inputDate}>
          <h5><span className={styles.requiredSign}>*</span><Trans>When to send</Trans></h5>
          <Popup
            closeOnDocumentClick
            contentStyle={{ marginLeft: 27, width: 230 }}
            key={notifWhenToSend} // prop to rerender TextField after notifWhenToSend change
            position="bottom right"
            trigger={
              <TextField
                className={`input input--default ${styles.inputInline} ${notifWhenToSendErrorClass}`}
                defaultValue={notifWhenToSend}
                fullWidth
                placeholder={t`Select date and time`}
                rows={5}
                variant="outlined"
              />
            }
          >
            <WhenToSendCalendar notification={notification} />
          </Popup>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          {notifWhenToSendErrorClass ? <div className={styles.formErrors}><Trans>This field can't be empty</Trans></div> : null}
        </Box>
      </Box>
      <Box marginBottom={10}>
        <h5><span className={styles.requiredSign}>*</span><Trans>Message</Trans></h5>
        <TextField
          className={`textarea textarea--default ${notifMessageErrorClass}`}
          defaultValue={notifMessage}
          fullWidth
          multiline
          onChange={onMessageChange}
          placeholder={t`Type here`}
          rows={5}
          variant="outlined"
        />
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        {notifMessageErrorClass ? <div className={styles.formErrors}><Trans>This field can't be empty</Trans></div> : null}
      </Box>
      <Box className={styles.buttonsBlock} display="flex" justifyContent="end">
        <Button onClick={() => onCancelAction()} size="sm" type="clear">
          <Trans>Cancel</Trans>
        </Button>

        <Button
          disabledStyle={submitButtonDisabled}
          onClick={onQuestionFormSubmit}
          size="sm"
        >
          <Trans comment="Edit/Create Notification">{actionState === 'edit' ? 'Edit' : 'Create'} Notification</Trans>
        </Button>
        
        <Button
          disabledStyle={submitButtonDisabled}
          onClick={onQuestionFormSubmitSend}
          size="sm"
        >
          <Trans comment="Edit/Create and Send">{actionState === 'edit' ? 'Edit' : 'Create'} and Send</Trans>
        </Button>
      </Box>
    </div>
  );
}
