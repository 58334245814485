import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useTypedSelector } from "store/_legacy/Redux/store";
import { Trans, t } from "@lingui/macro";
import Tooltip from "@material-ui/core/Tooltip";
import styles from "./styles.module.scss";

import Box from "components/_legacy/Box";
import Button from "components/_legacy/Button";
import TextField from "components/_legacy/TextField";
import { editFloor } from "../../../../../../App/Store/Locations/locationsDuck";

export default function FloorHeaderTitleEdit() {
  const dispatch = useDispatch();
  const { config, adminFloorMap, locations } = useTypedSelector(state => state);
  const { floorId } = useParams<{ floorId: string; locationId: string; }>();
  const floor = locations.floorsAdmin.find(floor => floor.id === floorId);
  const initValue = adminFloorMap.name;

  const [editState, setEditState] = useState(false);
  const [editFloorName, setEditFloorName] = useState(initValue);

  const hasChanges = initValue !== editFloorName;

  const openEditState = () => {
    setEditState(true);
    setEditFloorName(initValue); // reset state value if no redux update
  };

  const onCancel = () => {
    setEditState(false);
  };

  const onUpdate = () => {
    setEditState(false);
    if (editFloorName !== initValue) {
      if (floor) {
        dispatch(editFloor(floor.id, {
          active: floor.active,
          floorName: editFloorName,
        }));
      }
    }
  };

  return (
    <Box alignItems="center" display="flex" justifyContent="center">
      {
        editState ?
          <TextField
            autoFocus
            id="floor-name"
            onChange={(event) => setEditFloorName(event.target.value)}
            value={editFloorName}
          /> : <div>{initValue}</div>
      }
      <Box alignItems="center" display="flex">
        {
          editState ?
            <Box alignItems="center" display="flex" justifyContent="end">
              <Button
                aria-label={t`Cancel`}
                name={t`Cancel`}
                onClick={onCancel}
                size="sm"
                type="clear"
              >
                <Trans>Cancel</Trans>
              </Button>

              <Button
                aria-label={t`Update Email Content`}
                disabled={!hasChanges}
                name={t`Update Email Content`}
                onClick={onUpdate}
                size="sm"
              >
                <Trans>Update</Trans>
              </Button>
            </Box> :
            <Tooltip
              classes={{ tooltip: 'overflow-tooltip' }}
              placement="right"
              title={<div className={styles.tooltipContent}>Edit floor name</div>}
            >
              <div className={styles.editSetting} onClick={openEditState}>
                <svg height="24px"  version="1.1" viewBox="0 0 24 24" width="24px">
                  <g fill="none" fillRule="evenodd" id="A-locations" stroke="none" strokeWidth="1">
                    <g className={styles.fillColor} fill={config.theme.primary} fillRule="nonzero" id="A-Location-notifications" transform="translate(-329.000000, -185.000000)">
                      <g id="icons/editpencilgrey" transform="translate(329.000000, 185.000000)">
                        <path d="M17.2077147,4.29289322 L19.329035,6.41421356 C19.7195593,6.80473785 19.7195593,7.43790283 19.329035,7.82842712 L9.42954011,17.7279221 L5.89400621,14.1923882 L15.7935011,4.29289322 C16.1840254,3.90236893 16.8171904,3.90236893 17.2077147,4.29289322 Z M16.5006079,6.41421356 L8.72243333,14.1923882 L9.42954011,14.8994949 L17.2077147,7.12132034 L16.5006079,6.41421356 Z M5.31649907,19.5703403 C5.11123226,19.6387625 4.88931034,19.6387625 4.68404354,19.5703403 C4.16010022,19.3956925 3.87694023,18.8293725 4.051588,18.3054292 L5.18689943,14.8994949 L8.72243333,18.4350288 L5.31649907,19.5703403 Z" id="pen"></path>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </Tooltip>
        }
      </Box>
    </Box>
  );
}
