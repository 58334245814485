import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useTypedSelector } from "store/_legacy/Redux/store";
import { Trans, t } from "@lingui/macro";

import { Autocomplete, AutocompleteInputChangeReason } from "@material-ui/lab";
import { AutocompleteRenderInputParams } from "@material-ui/lab/Autocomplete/Autocomplete";
import { TextField } from "@material-ui/core";
import arrowIcon from "../../../../../../Locations/LocationForm/assets/arrow-down.svg";
import { MapDrawingGroup } from "../../../../../../../Store/floorMapDuck/models";
import { getAvailableAdminFloorRooms, setAdminFloorRoomsData } from "../../../../../../../Store/roomsManagement";
import styles from "../../../../../../Locations/LocationForm/styles.module.scss";
import _ from "lodash";
import { IAvailableRoom } from "Admin/Store/roomsManagement/models";
import { useParams } from "react-router-dom";
import Box from "components/_legacy/Box";
import Text from "components/_legacy/Text";

interface Props {
  meetingData: IAvailableRoom;
  setMeetingData: (meetingData: MapDrawingGroup) => void;
  error?: boolean;
}

export default function MeetingSearching(props: Props) {
  const dispatch = useDispatch();
  const { floorId } = useParams<{ floorId: string }>();
  const { availableFloorRooms, loadingAvailableFloorRooms } = useTypedSelector(state => state.roomsManagement);
  const { meetingData, setMeetingData, error } = props;

  const noOptionsText = loadingAvailableFloorRooms ? 'Loading...' : 'No options by this search criteria';
  const errorAutoCompleteClass = error ? 'input--withError' : '';

  const onResetSearchState = () => {
    dispatch(setAdminFloorRoomsData({
      availableFloorRooms: [],
    }));
  };

  useEffect(() => {
    onResetSearchState();
  }, []);

  const onMeetingDataChange = (event: any, meetingData: MapDrawingGroup) => {
    setMeetingData(meetingData);
  };

  const onSearchChange = _.debounce((event: any, value: string, reason: AutocompleteInputChangeReason) => {
    if (reason !== 'reset' && reason !== 'clear') { // 'reset' fires on select from list, no need to handle api
      const search = event.target.value;

      dispatch(getAvailableAdminFloorRooms({
        search,
        limit: 10,
        includeUnmappedRooms: true,
      }));
    }
  }, 300);

  return (
    <div>
      <Autocomplete
        className={`input input--default input--inline`}
        filterOptions={(x) => x} // fix load throttling
        getOptionDisabled={(option) => !!option?.isMapped || (!!option?.isAssigned && option?.floorId !== floorId)}
        getOptionLabel={({ name }) => name}
        id="meeting-name"
        noOptionsText={noOptionsText}
        onBlur={() => onResetSearchState()}
        onChange={(event: any, meetingGroup: any) => onMeetingDataChange(event, meetingGroup)}
        onInputChange={(event, value, reason) => onSearchChange(event, value, reason)}
        options={availableFloorRooms}
        popupIcon={
          <img height={10} src={arrowIcon} width={14} />
        }
        renderInput={(params: AutocompleteRenderInputParams) => {
          return (
            <TextField
              {...params}
              className={`input input--default ${errorAutoCompleteClass}`}
              placeholder={t`Search by email`}
              variant="outlined"
            />
          );
        }}
        renderOption={(option) => (
          <Box direction="column" display="flex">
            <Text size="md" weight="regular">{option.name}</Text>
            <Text color="gray" size="sm" weight="regular">{option.id}</Text>
          </Box>
        )}
        value={meetingData}
      />
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      {error ? <div className={styles.formErrors}><Trans>This field can't be empty</Trans></div> : null}
    </div>
  );
}
