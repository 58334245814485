import { Trans } from "@lingui/macro";
import { add, differenceInMinutes, eachMinuteOfInterval, format, parse } from "date-fns";
import React, { useEffect, useState } from "react";
import { TimeField, TimeFieldProps } from '@mui/x-date-pickers/TimeField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Box, InputAdornment, List, ListItem, ListItemButton, ListItemText, Typography, darken, useTheme } from "@mui/material";
import { useAutocomplete } from "@material-ui/lab";
import { blueLight } from "theme/_legacy/variables";
import Clock from "components/_legacy/Icons/Clock";

type FromToTimeInputValue = {
  from?: Date;
  to?: Date;
}

type FromToTimeInputValueError = {
  from?: boolean;
  to?: boolean;
}

export type FromToTimeInputProps = FromToTimeInputValue & {
  minutesStep?: number;
  disabled?: boolean;
  defaultValue?: FromToTimeInputValue;
  onChange: (value: FromToTimeInputValue, error: FromToTimeInputValueError) => void;
};

const zeroDay = new Date(1970, 0, 1);
const autocompleteOptions = eachMinuteOfInterval({ start: zeroDay, end: add(zeroDay, { days: 1, minutes: -15 }) }, { step: 15 })
  .map((value) => format(value, "hh:mm aaa"));

type TimeInputProps = {
  error?: boolean;
  disabled?: boolean;
  value?: Date;
  defaultValue?: Date;
  onChange: (value: Date) => void;
};

const TimeInputStartAdornment = ({ error, isFocused }: { error?: boolean, isFocused?: boolean }): JSX.Element => {
  const { palette } = useTheme();
  let color = palette.grey[400];

  if (error) {
    color = darken("#ffbebe", 0.2);
  } else if (isFocused) {
    color = darken(blueLight, 0.2);
  }

  return (
    <InputAdornment position="start" sx={{ marginBottom: "2px" }}>
      <Clock color={color} /> 
    </InputAdornment>
  );
};

const TimeInput = (props: TimeInputProps): JSX.Element => {
  const { onChange, disabled, error, value, defaultValue } = props;
  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    value: value ? format(value, "hh:mm aaa") : undefined,
    defaultValue: defaultValue ? format(defaultValue, "hh:mm aaa") : undefined,
    options: autocompleteOptions,
    disableClearable: true,
    freeSolo: true,
    onChange: (event, value) => value && onChange(parse(value, "hh:mm aaa", zeroDay)),
  });
  const [isFocused, setIsFocused] = useState(false);
  const { palette } = useTheme();
  const backgroundColor = isFocused ? blueLight : palette.grey[200];
  const inputProps = getInputProps();
  const { onFocus, onBlur } = inputProps as TimeFieldProps<Date>;

  return (
    <Box>
      <Box {...getRootProps()}>
        <TimeField
          {...inputProps}
          disabled={disabled}
          onBlur={(event) => {
            if (!disabled) {
              onBlur?.(event);
              setIsFocused(false);
            }
          }}
          onChange={(value) => onChange(value as Date)}
          onFocus={(event) => {
            if (!disabled) {
              onFocus?.(event);
              setIsFocused(true);
            }
          }}
          slotProps={{
            textField: {
              size: "small",
              InputProps: { 
                startAdornment: <TimeInputStartAdornment error={error} isFocused={isFocused} />,
              },
            },
          }}
          sx={{
            backgroundColor: error ? "#ffbebe" : backgroundColor,
            borderRadius: "5px",
            "& input": {
              width: "92px",
              paddingRight: 0,
              fontSize: 16,
            },
            "& fieldset": {
              border: "none",
              borderRadius: 0,
            },
          }}
          value={value}
        />
      </Box>
      {!disabled && groupedOptions.length > 0 ? (
        <List
          {...getListboxProps()}
          disablePadding
          sx={{
            zIndex: 1,
            position: 'absolute',
            background: "white",
            overflow: 'auto',
            maxHeight: 200,
            width: 130,
            boxShadow: "0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12), 0 2px 4px -1px rgba(0,0,0,.2)",
            borderRadius: "5px",
            "& li[aria-selected='true']": {
              backgroundColor: blueLight,
            },
          }}
        >
          {(groupedOptions as typeof autocompleteOptions).map((option, index) => (
            <ListItem {...getOptionProps({ option, index })} disablePadding key={option}>
              <ListItemButton sx={{ height: 40, cursor: "unset" }}>
                <ListItemText primary={option} primaryTypographyProps={{ fontSize: 16, noWrap: true }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      ) : null}
    </Box>
  );
};

export const FromToTimeInput = (props: FromToTimeInputProps): JSX.Element => {
  const {
    from,
    to,
    onChange,
    disabled,
    defaultValue,
    minutesStep = 15,
  } = props;
  const [fromValue, setFromValue] = useState(from);
  const [toValue, setToValue] = useState(to);
  const [valueError, setValueError] = useState<FromToTimeInputValueError>({});

  useEffect(() => {
    if (from && from.getTime() !== fromValue?.getTime()) {
      setFromValue(from);
    }

    if (to && to.getTime() !== toValue?.getTime()) {
      setToValue(to);
    }
  }, [from, to]);

  const onInputChange = (key: keyof FromToTimeInputValue, value?: Date) => {
    if (!value) {
      setValueError((prev) => ({ ...prev, [key]: true }));

      return;
    }
 
    const isFrom = key === "from";
    const nextFromValue = isFrom ? value : fromValue;
    const nextToValue = !isFrom ? value : toValue;
    const currentTime = isFrom ? fromValue?.getTime() : toValue?.getTime();

    if (value.getTime() === currentTime) {
      return;
    }

    const isFromValid = nextFromValue && nextFromValue.getMinutes() % minutesStep === 0;

    if (!isFromValid) {
      if (!valueError.from) {
        setValueError((prev) => ({ ...prev, from: true }));
      }
    } else {
      setValueError((prev) => ({ ...prev, from: false }));
    }

    const isToValid = nextToValue
      && nextToValue.getMinutes() % minutesStep === 0 
      && nextFromValue
      && differenceInMinutes(nextToValue, nextFromValue) >= 30;

    if (!isToValid) {
      if (!valueError.to) {
        setValueError((state) => ({ ...state, to: true }));
      }
    } else {
      setValueError((state) => ({ ...state, to: false }));
    }

    if (isFrom) {
      setFromValue(nextFromValue);
    } else {
      setToValue(nextToValue);
    }

    onChange({ from: nextFromValue, to: nextToValue }, { from: !isFromValid, to: !isToValid });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box display="flex">
        <Box alignItems="center" display="flex" mr={2}>
          <Box mr={2}>
            <Typography color={(theme) => theme.palette.grey[500]} component="span" fontWeight="500" variant="body2">
              <Trans>From</Trans>
            </Typography>
          </Box>
          <TimeInput
            defaultValue={defaultValue?.from}
            disabled={disabled}
            error={valueError.from}
            onChange={(value) => onInputChange("from", value)}
            value={fromValue}
          />
        </Box>
        <Box alignItems="center" display="flex">
          <Box mr={2}>
            <Typography color={(theme) => theme.palette.grey[500]} component="span" fontWeight="500" variant="body2">
              <Trans>To</Trans>
            </Typography>
          </Box>
          <TimeInput
            defaultValue={defaultValue?.to}
            disabled={disabled}
            error={valueError.to}
            onChange={(value) => onInputChange("to", value)}
            value={toValue}
          />
        </Box>
      </Box>
    </LocalizationProvider>
  );
};
