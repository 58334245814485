import Box from 'components/_legacy/Box';
import clsx from 'clsx';
import Eye1 from './assets/eye-1.png';
import Eye2 from './assets/eye-2.png';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import InputBase, { InputBaseProps } from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import styles from './styles.module.scss';
import Text from 'components/_legacy/Text';
import { useState } from 'react';

interface Props {
  className?: string;
  helperText?: string;
  id: string;
  label?: string;
}

type PropsA = Props & InputBaseProps;

export default function TextField({
  classes,
  className,
  error = false,
  fullWidth = true,
  helperText,
  id,
  label,
  required,
  type = 'text',
  ...props
}: React.PropsWithChildren<PropsA>) {
  const isPassword = type === 'password';
  const [showAsPassword, setShowAsPassword] = useState(type === 'password');
  const inputBaseClasses = clsx(
    className,
  );
  const helperTextClasses = clsx(styles.helperText, {
    [styles.error]: error,
  });
  const inputClasses = clsx(styles.input, classes && classes.input);
  const inputPasswordClasses = clsx(styles.inputPassword, classes && classes.input);

  const endAdornment = isPassword && (
    <IconButton onClick={() => setShowAsPassword(!showAsPassword)}>
      <img src={showAsPassword ? Eye1 : Eye2} width={20} />
    </IconButton>
  );

  return (
    <Box>
      {label && (
        <Box marginBottom={9} paddingLeft={8} position="relative">
          <InputLabel className={styles.inputLabel} htmlFor={id}>
            {required && (
              <Box left={0} position="absolute" top={-1}>
                <Text color="orange" inline weight="semi-bold">* </Text>
              </Box>
            )}
            <Text size="md" weight="semi-bold">{label}</Text>
          </InputLabel>
        </Box>)
      }

      <InputBase
        {...props}
        className={inputBaseClasses}
        classes={{
          error: styles.error,
          input: isPassword && showAsPassword ? inputPasswordClasses : inputClasses,
          focused: styles.focused,
          root: styles.textField,
        }}
        endAdornment={endAdornment}
        error={error}
        fullWidth={fullWidth}
        id={id}
        type={isPassword && !showAsPassword ? 'text' : type}
      />

      {helperText &&
        <FormHelperText className={helperTextClasses}>
          {helperText}
        </FormHelperText>
      }
    </Box>
  );
}
