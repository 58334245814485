import Box from 'components/_legacy/Box';
import PushTemplate from './PushTemplate';
import Skeleton from '@material-ui/lab/Skeleton';
import { EmailTemplateTypes } from 'Admin/Store/emailTemplatesDuck/models';
import { getEmailTemplates } from 'Admin/Store/emailTemplatesDuck';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTypedSelector } from 'store/_legacy/Redux/store';

/**
 * Configure Push Templates
 *
 * OwnedBy: Bruno
 */
export default function PushTemplates() {
  const dispatch = useDispatch();
  const { locationId } = useParams<{ locationId: string }>();
  const { emailTemplates: emailTemplatesDuck } = useTypedSelector(state => state);

  const { emailTemplates: pushTemplates } = emailTemplatesDuck;

  const [firstLoadCompleted, setFirstLoadCompleted] = useState(false);

  const pushTemplatesArray = pushTemplates[locationId] ? Object.keys(pushTemplates[locationId])
    .map(key => pushTemplates[locationId][key as Exclude<EmailTemplateTypes, "attendeesInvitation">]) : [];

  useEffect(() => {
    dispatch(getEmailTemplates({ locationId }));
    setFirstLoadCompleted(true);
  }, [locationId]);

  return (
    <>
      <Box dataTestId="push-templates">
        {(firstLoadCompleted && !emailTemplatesDuck.loading) ? (
          <Box>
            {pushTemplatesArray
              .filter(pushTemplate => pushTemplate.type !== 'confirmBooking')
              .map((pushTemplate, index) => {
                return (
                  <PushTemplate
                    {...pushTemplate}
                    key={pushTemplate.id + index}
                    locationId={locationId}
                  />
                );
              })}
          </Box>
        ) : null}

        {emailTemplatesDuck.loading ? (
          <Box height={200} marginTop={20}>
            <Skeleton height="100%" variant="rect" />
          </Box>
        ) : null}
      </Box>
    </>
  );
}
