import Box from 'components/_legacy/Box';
import Button from 'components/_legacy/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import HtmlEditor from 'components/_legacy/HtmlEditor';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import styles from './styles.module.scss';
import { CovidQuestionModel } from 'Admin/Store/covidQuestions/models';
import { createCovidQuestion, editCovidQuestion } from 'Admin/Store/covidQuestions';
import { Trans, t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useState } from 'react';

interface OpenQuestionFormProps {
  actionState: 'add' | 'edit';
  newQuestionIndex: number;
  question?: CovidQuestionModel; // data used for edit state
  onCancelAction: Function;
}

export default function OpenQuestionForm(props: OpenQuestionFormProps) {
  const {
    actionState,
    newQuestionIndex,
    onCancelAction,
    question,
  } = props;
  const dispatch = useDispatch();

  const getQuestionCovidStatus = (status: boolean) => {
    return status ? 'yes' : 'no';
  };
  const questionCovidStatus = question && typeof question.isPositive === 'boolean'
    ? getQuestionCovidStatus(question.isPositive)
    : 'yes';

  const { locationId } = useParams<{ locationId: string }>();
  const [questionCovidValue, setQuestionCovidValue] = useState(questionCovidStatus);
  const [questionDetailsValue, setQuestionDetailsValue] = useState('');
  const [questionTextError, setQuestionTextError] = useState('');
  const [questionTextValue, setQuestionTextValue] = useState('');
  const [questionPlainTextValue, setQuestionPlainTextValue] = useState('');

  const isQuestionEmpty = !questionPlainTextValue;

  const onCovidStatusChange = (event: any) => {
    setQuestionCovidValue(event.target.value);
  };

  const onQuestionFormSubmit = () => {

    // collect data from fields
    const isPositive = questionCovidValue === 'yes';
    const question = {
      questionText: questionTextValue,
      questionDetails: questionDetailsValue,
      isPositive,
    };

    // add error if questionText field is empty
    if (isQuestionEmpty) {
      setQuestionTextError(t`This field can't be empty`);

      return;
    }

    // submit logic
    if (actionState === 'add') {
      dispatch(createCovidQuestion({ question, locationId }));
    } else if (actionState === 'edit') {
      const questionId = props.question && props.question.id;

      dispatch(editCovidQuestion({ question, questionId }));
    }

    onCancelAction();
  };

  return (
    <div className={styles.covidNewQuestion}>
      <Box marginBottom={15}>
        <h4>
          <Trans comment="Eg: Question 1 edit">
            Question {newQuestionIndex} {actionState === 'edit' ? 'edit' : ''}
          </Trans>
        </h4>

        <HtmlEditor
          dataTestId="covid-question-form-questionText"
          errorMessage={questionTextError}
          initialText={question && question.questionText ? question.questionText : ''}
          onChangeText={(text, plainText) => {
            setQuestionTextValue(text);
            setQuestionPlainTextValue(plainText);

            if (plainText) {
              setQuestionTextError('');
            }
          }}
        />
      </Box>

      <Box marginBottom={10}>
        <h5>
          <Trans>
            Details
          </Trans>
        </h5>

        <HtmlEditor
          dataTestId="covid-question-form-questionDetails"
          initialText={question && question.questionDetails ? question.questionDetails : ''}
          onChangeText={text => {
            setQuestionDetailsValue(text);
          }}
        />
      </Box>

      <Box alignItems="center" dataTestId="covid-question-form-questionAnswer" display="flex" marginBottom={15}>
        <span className={styles.questionCovidStatusText}>
          <Trans>
            What is the correct answer?
          </Trans>
        </span>

        <RadioGroup
          aria-label={t`covidStatus`}
          className="radio-buttons-group"
          name={t`covidStatus`}
          onChange={onCovidStatusChange}
          row
          value={questionCovidValue}
        >
          <FormControlLabel control={<Radio />} label={t`Yes`} value='yes' />
          <FormControlLabel control={<Radio />} label={t`No`} value='no' />
        </RadioGroup>
      </Box>

      <Box display="flex" justifyContent="end">
        <Button data-testid="covid-question-form-buttonCancel" onClick={() => onCancelAction()} size="sm" type="clear">
          <Trans>
            Cancel
          </Trans>
        </Button>

        <Button
          data-testid="covid-question-form-buttonConfirm"
          disabledStyle={isQuestionEmpty}
          onClick={onQuestionFormSubmit}
          size="sm"
        >
          <Trans comment="Edit/Add Question">
            {actionState === 'edit' ? 'Edit' : 'Add'} Question
          </Trans>
        </Button>
      </Box>
    </div>
  );
}
