import { useTypedSelector } from "store/_legacy/Redux/store";
import { useState } from "react";
import CapacityPopup from "./CapacityPopup";
import Box from "components/_legacy/Box";
import Text from "components/_legacy/Text";
import { Trans} from "@lingui/macro";
import NeighborsIcon from "components/_legacy/Icons/Neighbors";
import Button from "components/_legacy/Button";
import { Room as RoomModel } from "../../../../../Store/Meetings/meetingRooms/models";
import CapacityCount from "./CapacityCount";

interface Props {
  room?: RoomModel;
}

export default function Capacity(props: Props) {
  const { selectedData } = useTypedSelector(state => state.createNewMeeting);
  const { attendees, externalAttendees } = selectedData;

  const { room } = props;

  const [attendeesPopupOpened, setAttendeesPopupOpened] = useState(false);

  const handleAttendeesClick = () => {
    setAttendeesPopupOpened(true);
  };

  return (
    <>
      <Box alignItems="center" display="flex" justifyContent="between" marginBottom={20}>
        <Box alignItems="center" display="flex">
          <Box
            alignItems="center"
            backgroundColor="#F4F4F4"
            borderRadius={8}
            display="flex"
            height={30}
            justifyContent="center"
            marginRight={10}
            width={30}
          >
            <NeighborsIcon color="#5E5E5E" size={16} />
          </Box>

          <Box alignItems="center" display='flex' gap={5}>
            <Box alignItems="center" display="flex">
              <Text weight="semi-bold">
                <Trans>Attendees</Trans>
              </Text>
            </Box>
            <CapacityCount attendees={attendees} capacity={room?.capacity} externalAttendees={externalAttendees} />
          </Box>
        </Box>

        <Box marginRight={10}>
          <Button noPaddingX onClick={handleAttendeesClick} type="clear">
            {(attendees || []).length > 0 ?
              <Trans>Edit</Trans>:
              <Trans>Select</Trans>
            }
          </Button>
        </Box>
      </Box>
      <CapacityPopup open={attendeesPopupOpened} setOpen={setAttendeesPopupOpened} />
    </>
  );
}
