import React from "react";

interface IconProps {
  color?: string;
  margin?: string;
}

export function LocationOutlinedIcon({ color = 'var(--grey)', margin = '0 5px 0 0' }: IconProps) {
  return (
    <svg height="16px" style={{ margin }} version="1.1" viewBox="0 0 16 16" width="16px">
      <g fill="none" fillRule="evenodd" id="Booking" stroke="none" strokeWidth="1">
        <g id="Createbooking" transform="translate(-120.000000, -290.000000)">
          <g id="Group" transform="translate(100.000000, 236.000000)">
            <rect fill="transparent" height="90" id="Rectangle" rx="14" width="770" x="0" y="0"></rect>
            <g fill={color} fillRule="nonzero" id="icons/location" transform="translate(20.000000, 54.000000)">
              <g id="Combined-Shape">
                <path d="M8,0 C11.3137085,0 14,2.6862915 14,6 C14,8.06787169 12.3851644,11.0490855 9.17879697,15.1236911 C9.10531148,15.2170754 9.02098986,15.301397 8.92760551,15.3748824 C8.27655656,15.8872019 7.33346014,15.7747394 6.81899381,15.1209524 L6.81899381,15.1209524 L6.54199798,14.7664093 L5.99338953,14.0487452 C3.33812543,10.5201247 2,7.88389093 2,6 C2,2.6862915 4.6862915,0 8,0 Z M8,2 C5.790861,2 4,3.790861 4,6 C4,7.05182083 4.75169047,8.73801773 6.26463644,10.988101 L6.26463644,10.988101 L6.67659285,11.5878243 C6.74813244,11.6898947 6.82111265,11.7930193 6.89553426,11.8971926 L6.89553426,11.8971926 L7.35936708,12.5347686 L7.85783136,13.1972321 L7.999,13.381 L8.37959354,12.8840844 L8.8633195,12.2311144 C9.48506871,11.3772584 10.0139736,10.5907213 10.4504017,9.87434292 L10.4504017,9.87434292 L10.7603891,9.35025824 C11.5408144,7.9880151 11.9517002,6.90924661 11.9959868,6.13667129 L11.9959868,6.13667129 L12,6 C12,3.790861 10.209139,2 8,2 Z M8,5 C8.55228475,5 9,5.44771525 9,6 C9,6.55228475 8.55228475,7 8,7 C7.44771525,7 7,6.55228475 7,6 C7,5.44771525 7.44771525,5 8,5 Z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
