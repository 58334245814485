import Error404 from '../Error404';
import Layout from '../CreateNewBooking/Layout';
import VisitDate from './VisitDate';
import VisitFloor from './VisitFloor';
import VisitLocation from './VisitLocation';
import { AnimatePresence } from 'framer-motion';
import { ProtectedRoute } from 'components/_legacy/ProtectedRoute';
import { Redirect, Route, Switch } from 'react-router-dom';
import { t } from '@lingui/macro';
import { VisitDateGuard, VisitFloorGuard } from './guards';

export default function CreateNewVisitRoutes() {
  return (
    <Layout title={t`Create new visit`}>
      <AnimatePresence exitBeforeEnter initial={true}>
        <Switch>
          <Route
            exact
            path='/create-new-visit/onsite/select-location'
            render={() => <VisitLocation />}
          />

          <ProtectedRoute
            exact
            fallback={() => <Redirect to="/create-new-visit/onsite/select-location" />}
            guards={[VisitFloorGuard]}
            path='/create-new-visit/onsite/select-floor'
            render={() => <VisitFloor />}
          />

          <ProtectedRoute
            exact
            fallback={() => <Redirect to="/create-new-visit/onsite/select-location" />}
            guards={[VisitDateGuard]}
            path='/create-new-visit/onsite/select-date'
            render={() => <VisitDate />}
          />

          <Route path='*' render={() => <Error404 />} />
        </Switch>
      </AnimatePresence >
    </Layout >
  );
}
