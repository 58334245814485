import Box from 'components/_legacy/Box';
import clsx from 'clsx';
import styles from './styles.module.scss';
import Text from 'components/_legacy/Text';
import { isStepCompleted, isStepDisabled } from 'App/Store/Bookings/createNewBookingDuck';
import { Link, useLocation } from 'react-router-dom';
import { t } from '@lingui/macro';
import { useTypedSelector } from 'store/_legacy/Redux/store';
import NumberCounter from "components/_legacy/NumberCounter";

interface BreadcrumbItem {
  label: string; // TO DO add logic for support steps
  number: number;
  to: string;
}

interface ItemProps extends BreadcrumbItem {
  hasArrow: boolean;
}

interface BreadcrumbsProps {
  type?: 'support' | 'desk';
}

function Item({ hasArrow, label, number, to }: React.PropsWithChildren<ItemProps>) {
  const location = useLocation();
  const { config, createNewBooking, bookingCovidConfirmation } = useTypedSelector(state => state);

  const isActive = location.pathname.includes(to);
  const isCompleted = isStepCompleted(number, createNewBooking);
  const isDisabled = isStepDisabled(number, createNewBooking, bookingCovidConfirmation.disableFloorPage);
  const arrowClasses = clsx(styles.arrow, isActive && styles.active, isCompleted && styles.completed);

  const item = (
    <Box alignItems="center" display="flex" style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}>
      <Box alignItems="center" display="grid" gap={5} gridTemplateColumns="auto auto">
        <NumberCounter
          boxColor={isActive || isCompleted ? config.theme.primaryLight : '#F4F4F4'}
          count={number}
          textColor={isActive ? 'blue' : isCompleted ? 'black' : 'lightGray'}
        />

        <Text color={isActive ? 'blue' : isCompleted ? 'black' : 'lightGray'} size="xlg" weight="semi-bold">
          {label}
        </Text>
      </Box>

      {hasArrow &&
        <Box className={styles.arrowContainer}>
          <svg height="16px" viewBox="0 0 16 16" width="16px">
            <g enableBackground="new">
              <clipPath id="cp0">
                <path d="M 9.050253 12.94975 C 8.659728 12.55922 8.659728 11.92606 9.050253 11.53553 L 11.586 9.000641 L 1.757359 9 C 1.205075 9 .7573593 8.552285 .7573593 8 C .7573593 7.447715 1.205075 7 1.757359 7 L 11.586 7.000641 L 9.050253 4.464466 C 8.659728 4.073942 8.659728 3.440777 9.050253 3.050253 C 9.440777 2.659728 10.07394 2.659728 10.46447 3.050253 L 14.70711 7.292893 C 15.09763 7.683418 15.09763 8.316582 14.70711 8.707107 L 10.46447 12.94975 C 10.07394 13.34027 9.440777 13.34027 9.050253 12.94975 Z " fillRule="evenodd" transform="matrix(1,0,0,-1,0,16)"/>
              </clipPath>
              <g clipPath="url(#cp0)">
                <clipPath id="cp1">
                  <path d="M -272 -937 L 1168 -937 L 1168 109 L -272 109 Z " transform="matrix(1,0,0,-1,0,16)"/>
                </clipPath>
                <g clipPath="url(#cp1)">
                  <path className={arrowClasses} d="M -.2426407 1.659728 L 16.097628 1.659728 L 16.097628 14.340268 L -.2426407 14.340268 Z " transform="matrix(1,0,0,-1,0,16)"/>
                </g>
              </g>
            </g>
          </svg>
        </Box>
      }
    </Box>
  );

  return isDisabled ? item : (
    <Link to={to}>
      {item}
    </Link>
  );
}

export default function Breadcrumbs({ type = 'desk' }: BreadcrumbsProps) {
  const DESK_ITEMS: BreadcrumbItem[] = [
    { label: t`Select location`, number: 1, to: '/create-new-booking/select-location' },
    { label: t`Select floor`, number: 2, to: '/create-new-booking/select-floor' },
    { label: t`Select desk`, number: 3, to: '/create-new-booking/select-desk' },
  ];

  const SUPPORT_ITEMS: BreadcrumbItem[] = [
    { label: t`Select location`, number: 1, to: '/create-new-booking-support/select-location' },
    { label: t`Select floor`, number: 2, to: '/create-new-booking-support/select-floor' },
    { label: t`Select date and read Instruction`, number: 3, to: '/create-new-booking-support/select-desk' },
  ];

  const TYPE_ITEMS = {
    'desk': DESK_ITEMS,
    'support': SUPPORT_ITEMS,    
  };

  const ITEMS = TYPE_ITEMS[type];

  return (
    <Box alignItems="center" display="flex">
      {ITEMS.map((item, index) => (
        <Box key={item.to + index} marginEnd={5}>
          <Item
            {...item}
            hasArrow={index + 1 < ITEMS.length}
          />
        </Box>
      ))}
    </Box >
  );
}
