import getErrorMessageFromPayload from 'store/_legacy/Redux/Helpers/getErrorMessageFromPayload';
import { ActionPayload, BaseErrorResponse, BaseResponse } from 'store/_legacy/Models/ReduxModels';
import { t } from '@lingui/macro';
import {
  CreateGlobalNotificationRequest,
  CreateGlobalNotificationResponse,
  DeleteGlobalNotificationRequest,
  DeleteGlobalNotificationResponse,
  GetGlobalNotificationsRequest,
  GetGlobalNotificationsResponse,
  GlobalNotificationModel,
  SendGlobalNotificationRequest,
  SendGlobalNotificationRequestData,
  SendGlobalNotificationResponse,
  SetGlobalNotificationsReduxDataRequest,
  UpdateGlobalNotificationRequest,
  UpdateGlobalNotificationResponse,
} from './models';

const SET_GLOBAL_NOTIFICATIONS_REDUX_DATA = 'SET_GLOBAL_NOTIFICATIONS_REDUX_DATA';

export const CREATE_GLOBAL_NOTIFICATION = 'CREATE_GLOBAL_NOTIFICATION';
export const CREATE_GLOBAL_NOTIFICATION_FAIL = 'CREATE_GLOBAL_NOTIFICATION_FAIL';
export const CREATE_GLOBAL_NOTIFICATION_SUCCESS = 'CREATE_GLOBAL_NOTIFICATION_SUCCESS';

export const CREATE_AND_SEND_GLOBAL_NOTIFICATION = 'CREATE_AND_SEND_GLOBAL_NOTIFICATION';

export const DELETE_GLOBAL_NOTIFICATION = 'DELETE_GLOBAL_NOTIFICATION';
export const DELETE_GLOBAL_NOTIFICATION_FAIL = 'DELETE_GLOBAL_NOTIFICATION_FAIL';
export const DELETE_GLOBAL_NOTIFICATION_SUCCESS = 'DELETE_GLOBAL_NOTIFICATION_SUCCESS';

export const GET_GLOBAL_NOTIFICATIONS = 'GET_GLOBAL_NOTIFICATIONS';
export const GET_GLOBAL_NOTIFICATIONS_FAIL = 'GET_GLOBAL_NOTIFICATIONS_FAIL';
export const GET_GLOBAL_NOTIFICATIONS_SUCCESS = 'GET_GLOBAL_NOTIFICATIONS_SUCCESS';

export const SEND_GLOBAL_NOTIFICATION = 'SEND_GLOBAL_NOTIFICATION';
export const SEND_GLOBAL_NOTIFICATION_FAIL = 'SEND_GLOBAL_NOTIFICATION_FAIL';
export const SEND_GLOBAL_NOTIFICATION_SUCCESS = 'SEND_GLOBAL_NOTIFICATION_SUCCESS';

export const UPDATE_GLOBAL_NOTIFICATION = 'UPDATE_GLOBAL_NOTIFICATION';
export const UPDATE_GLOBAL_NOTIFICATION_FAIL = 'UPDATE_GLOBAL_NOTIFICATION_FAIL';
export const UPDATE_GLOBAL_NOTIFICATION_SUCCESS = 'UPDATE_GLOBAL_NOTIFICATION_SUCCESS';

export const UPDATE_AND_SEND_GLOBAL_NOTIFICATION = 'UPDATE_AND_SEND_GLOBAL_NOTIFICATION';

export interface CreateGlobalNotification {
  type: typeof CREATE_GLOBAL_NOTIFICATION;
  payload: ActionPayload<CreateGlobalNotificationRequest>;
}
interface CreateGlobalNotificationFail {
  type: typeof CREATE_GLOBAL_NOTIFICATION_FAIL;
  payload: BaseErrorResponse;
}
interface CreateGlobalNotificationSuccess {
  type: typeof CREATE_GLOBAL_NOTIFICATION_SUCCESS;
  payload: BaseResponse<CreateGlobalNotificationResponse>;
}

interface CreateAndSendGlobalNotification {
  type: typeof CREATE_AND_SEND_GLOBAL_NOTIFICATION;
  payload: ActionPayload<CreateGlobalNotificationRequest>;
}

export interface DeleteGlobalNotification {
  type: typeof DELETE_GLOBAL_NOTIFICATION;
  payload: ActionPayload<DeleteGlobalNotificationRequest>;
}
interface DeleteGlobalNotificationFail {
  type: typeof DELETE_GLOBAL_NOTIFICATION_FAIL;
  payload: BaseErrorResponse;
}
interface DeleteGlobalNotificationSuccess {
  type: typeof DELETE_GLOBAL_NOTIFICATION_SUCCESS;
  payload: BaseResponse<DeleteGlobalNotificationResponse>;
}

export interface GetGlobalNotifications {
  type: typeof GET_GLOBAL_NOTIFICATIONS;
  payload: ActionPayload<GetGlobalNotificationsRequest>;
}
interface GetGlobalNotificationsFail {
  type: typeof GET_GLOBAL_NOTIFICATIONS_FAIL;
  payload: BaseErrorResponse;
}
interface GetGlobalNotificationsSuccess {
  type: typeof GET_GLOBAL_NOTIFICATIONS_SUCCESS;
  payload: BaseResponse<GetGlobalNotificationsResponse>;
}

export interface SendGlobalNotification {
  type: typeof SEND_GLOBAL_NOTIFICATION;
  payload: ActionPayload<SendGlobalNotificationRequestData>;
}
interface SendGlobalNotificationFail {
  type: typeof SEND_GLOBAL_NOTIFICATION_FAIL;
  payload: BaseErrorResponse;
}
interface SendGlobalNotificationSuccess {
  type: typeof SEND_GLOBAL_NOTIFICATION_SUCCESS;
  payload: BaseResponse<SendGlobalNotificationResponse>;
}

interface SetGlobalNotificationsReduxData {
  type: typeof SET_GLOBAL_NOTIFICATIONS_REDUX_DATA;
  payload: SetGlobalNotificationsReduxDataRequest;
}

export interface UpdateGlobalNotification {
  type: typeof UPDATE_GLOBAL_NOTIFICATION;
  payload: ActionPayload<CreateGlobalNotificationRequest>;
}
interface UpdateGlobalNotificationFail {
  type: typeof UPDATE_GLOBAL_NOTIFICATION_FAIL;
  payload: BaseErrorResponse;
}
interface UpdateGlobalNotificationSuccess {
  type: typeof UPDATE_GLOBAL_NOTIFICATION_SUCCESS;
  payload: BaseResponse<UpdateGlobalNotificationResponse>;
}

interface UpdateAndSendGlobalNotification {
  type: typeof UPDATE_AND_SEND_GLOBAL_NOTIFICATION;
  payload: ActionPayload<CreateGlobalNotificationRequest>;
}

type Actions =
  | CreateGlobalNotification
  | CreateGlobalNotificationFail
  | CreateGlobalNotificationSuccess
  | CreateAndSendGlobalNotification
  | DeleteGlobalNotification
  | DeleteGlobalNotificationFail
  | DeleteGlobalNotificationSuccess
  | GetGlobalNotifications
  | GetGlobalNotificationsFail
  | GetGlobalNotificationsSuccess
  | SendGlobalNotification
  | SendGlobalNotificationFail
  | SendGlobalNotificationSuccess
  | SetGlobalNotificationsReduxData
  | UpdateGlobalNotification
  | UpdateGlobalNotificationFail
  | UpdateGlobalNotificationSuccess;

export interface State {
  error: string;
  loading: boolean;
  successMessage: string;
  successDeleteMessage: string;
  successSendMessage: string;
  successUpdateMessage: string;
  notifications: GlobalNotificationModel[];
  limit: number;
  page: number;
  totalPages: number;
  search: string;
}

const initialState: State = {
  error: '',
  loading: false,
  successMessage: '',
  successDeleteMessage: '',
  successSendMessage: '',
  successUpdateMessage: '',
  notifications: [],
  limit: 10,
  page: 0,
  totalPages: 0,
  search: '',
};

export default function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case CREATE_GLOBAL_NOTIFICATION: {
      return {
        ...state,
        error: '',
        successMessage: '',
      };
    }
    case CREATE_GLOBAL_NOTIFICATION_FAIL: {
      return {
        ...state,
        error: t`There was an error creating the notification. Please try again.`,
      };
    }
    case CREATE_GLOBAL_NOTIFICATION_SUCCESS: {
      const notification = action.payload.data.result.data;

      return {
        ...state,
        error: '',
        successMessage: t`Notification was created`,
        notifications: [notification, ...state.notifications],
      };
    }

    case CREATE_AND_SEND_GLOBAL_NOTIFICATION: {
      return {
        ...state,
        error: '',
        successMessage: '',
      };
    }

    case DELETE_GLOBAL_NOTIFICATION: {
      return {
        ...state,
        error: '',
        successDeleteMessage: '',
      };
    }
    case DELETE_GLOBAL_NOTIFICATION_FAIL: {
      return {
        ...state,
        error: t`There was an error deleting the notification. Please try again.`,
      };
    }
    case DELETE_GLOBAL_NOTIFICATION_SUCCESS: {
      const notificationId = action.payload.data.result.data.id;

      const filteredNotifications = state.notifications.filter(n => n.id !== notificationId);

      return {
        ...state,
        error: '',
        successDeleteMessage: t`Notification deleted`,
        notifications: filteredNotifications,
      };
    }

    case GET_GLOBAL_NOTIFICATIONS: {
      return {
        ...state,
        error: '',
        loading: true,
        page: action.payload.request.data?.page ?? 1,
      };
    }
    case GET_GLOBAL_NOTIFICATIONS_FAIL: {
      return {
        ...state,
        error: getErrorMessageFromPayload({ payload: action.payload, fallbackMessage: t`There was an error getting global notifications. Please try again.` }),
        loading: false,
      };
    }
    case GET_GLOBAL_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        error: '',
        loading: false,
        notifications: action.payload.data.result.data.notifications,
        totalPages: action.payload.data.result.data.totalPages,
      };
    }

    case SEND_GLOBAL_NOTIFICATION: {
      return {
        ...state,
        error: '',
        successSendMessage: '',
      };
    }
    case SEND_GLOBAL_NOTIFICATION_FAIL: {
      return {
        ...state,
        error: getErrorMessageFromPayload({ payload: action.payload, fallbackMessage: t`There was an error sending the notification. Please try again.` }),
      };
    }
    case SEND_GLOBAL_NOTIFICATION_SUCCESS: {
      const newNotifications = [...state.notifications];

      const index = newNotifications.findIndex(n => n.id === action.payload.data.result.data.id);

      if (index > -1) {
        newNotifications[index] = { ...newNotifications[index], status: 'sent' };
      }

      return {
        ...state,
        error: '',
        successSendMessage: t`Notification sent`,
        notifications: newNotifications,
      };
    }

    case SET_GLOBAL_NOTIFICATIONS_REDUX_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case UPDATE_GLOBAL_NOTIFICATION: {
      return {
        ...state,
        error: '',
        loading: true,
        successUpdateMessage: '',
      };
    }
    case UPDATE_GLOBAL_NOTIFICATION_FAIL: {
      return {
        ...state,
        error: t`There was an error updating the notification. Please try again.`,
        loading: false,
      };
    }
    case UPDATE_GLOBAL_NOTIFICATION_SUCCESS: {
      const notification = action.payload.data.result.data;
      const notificationIndex = state.notifications.findIndex(n => n.id === notification.id);

      const newNotifications = [...state.notifications];

      newNotifications[notificationIndex] = notification;

      return {
        ...state,
        error: '',
        loading: false,
        notifications: newNotifications,
        successUpdateMessage: t`Notification updated`,
      };
    }

    default:
      return state;
  }
}

// Actions
export function createGlobalNotification(data: CreateGlobalNotificationRequest): CreateGlobalNotification {
  return {
    type: CREATE_GLOBAL_NOTIFICATION,
    payload: {
      request: {
        method: 'POST',
        url: '/api/notifications/general',
        data,
      },
    },
  };
}

export function createAndSendGlobalNotification(data: CreateGlobalNotificationRequest): CreateAndSendGlobalNotification {
  return {
    type: CREATE_AND_SEND_GLOBAL_NOTIFICATION,
    payload: {
      request: {
        method: 'POST',
        url: '/api/notifications/general',
        data,
      },
    },
  };
}

export function deleteGlobalNotification(data: DeleteGlobalNotificationRequest): DeleteGlobalNotification {
  return {
    type: DELETE_GLOBAL_NOTIFICATION,
    payload: {
      request: {
        method: 'DELETE',
        url: `/api/${data.notificationId}/notifications`,
      },
    },
  };
}

export function getGlobalNotifications(data: GetGlobalNotificationsRequest): GetGlobalNotifications {
  return {
    type: GET_GLOBAL_NOTIFICATIONS,
    payload: {
      request: {
        method: 'GET',
        url: '/api/notifications/general',
        data,
      },
    },
  };
}

export function sendGlobalNotification(data: SendGlobalNotificationRequest): SendGlobalNotification {
  return {
    type: SEND_GLOBAL_NOTIFICATION,
    payload: {
      request: {
        method: 'POST',
        url: `/api/${data.notificationId}/send-notification/general`,
        data: data.data,
      },
    },
  };
}

export function setGlobalNotificationsReduxData(data: SetGlobalNotificationsReduxDataRequest): SetGlobalNotificationsReduxData {
  return {
    type: SET_GLOBAL_NOTIFICATIONS_REDUX_DATA,
    payload: data,
  };
}

export function updateGlobalNotification(data: UpdateGlobalNotificationRequest): UpdateGlobalNotification {
  return {
    type: UPDATE_GLOBAL_NOTIFICATION,
    payload: {
      request: {
        method: 'PUT',
        url: `/api/${data.notificationId}/notifications`,
        data: data.data,
      },
    },
  };
}

export function updateAndSendGlobalNotification(data: UpdateGlobalNotificationRequest): UpdateAndSendGlobalNotification {
  return {
    type: UPDATE_AND_SEND_GLOBAL_NOTIFICATION,
    payload: {
      request: {
        method: 'PUT',
        url: `/api/${data.notificationId}/notifications`,
        data: data.data,
      },
    },
  };
}
