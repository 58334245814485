import React from "react";
import { Element } from "slate";
import { EditorElementType } from "../enums";

export const editorElementToReactElementType = (element: Element): React.ElementType | undefined => {
  switch (element.type) {
    case EditorElementType.HEADING: {
      const values: React.ElementType[] = ["h1", "h2", "h3", "h4", "h5", "h6"];

      return values[element.level ? element.level - 1 : 0];
    }
    case EditorElementType.PARAGRAPH: {
      return "p";
    }
    default: {
      return undefined;
    }
  }
};
