import {t} from "@lingui/macro";
import Box from 'components/_legacy/Box';
import CheckboxCustom from 'components/_legacy/CheckBoxCustom';
import styles from '../StatusFilter/styles.module.scss';
import { FormControlLabel } from '@material-ui/core';
import { setVisitsData } from 'Admin/Store/visits';
import { useDispatch } from 'react-redux';
import { UserDocumentStatus } from '../../../../Store/userManagement/models';
import { useTypedSelector } from 'store/_legacy/Redux/store';
import DocStatusLabel from "components/_legacy/Label";
import FilterAccordion from "components/_legacy/Filters/FilterAccordion";

interface DocStatusFilterProps {
  initialFiltersState: { [key in UserDocumentStatus]: boolean },
  filters: { [key in UserDocumentStatus]: boolean },
  setFilters: Function,
}

export default function DocStatusFilter(props: DocStatusFilterProps) {
  const dispatch = useDispatch();
  const { filters: reduxFilters } = useTypedSelector(state => state.adminVisits);
  const { filters, initialFiltersState, setFilters } = props;
  const localCounter = Object.keys(filters).map((key: any) => key)
    .filter((key: UserDocumentStatus) => filters[key]).length;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({ ...filters, [event.target.name]: event.target.checked });
  };

  const onResetFilter = () => {
    dispatch(setVisitsData({
      filters: {
        ...reduxFilters,
        documentStatuses: [],
      },
    }));

    setFilters(initialFiltersState);
  };

  return (
    <Box display="flex" justifyContent="between" marginTop={10}>
      <FilterAccordion headerName="Doc Status" localCounter={localCounter} resetFilter={onResetFilter}>
        <Box className={styles.filtersFormControlWrapper} display="grid">
          <FormControlLabel
            className={`${styles.docStatusStyle} ${filters.approved ? styles.checkedLabel : ''}`}
            control={<CheckboxCustom checked={filters.approved} classes={{ checked: styles.checked }} name="approved" onChange={handleChange} />}
            label={<DocStatusLabel docStatus="approved" text={t`Approved`} />}
          />
          <FormControlLabel
            className={`${styles.docStatusStyle} ${filters.needsApproval ? styles.checkedLabel : ''}`}
            control={<CheckboxCustom checked={filters.needsApproval} classes={{ checked: styles.checked }} name="needsApproval" onChange={handleChange} />}
            label={<DocStatusLabel docStatus="needsApproval" text={t`Needs approval`} />}
          />
          <FormControlLabel
            className={`${styles.docStatusStyle} ${filters.notUploaded ? styles.checkedLabel : ''}`}
            control={<CheckboxCustom checked={filters.notUploaded} classes={{ checked: styles.checked }} name="notUploaded" onChange={handleChange} />}
            label={<DocStatusLabel docStatus="notUploaded" text={t`Not uploaded`} />}
          />
          <FormControlLabel
            className={`${styles.docStatusStyle} ${filters.denied ? styles.checkedLabel : ''}`}
            control={<CheckboxCustom checked={filters.denied} classes={{ checked: styles.checked }} name="denied" onChange={handleChange} />}
            label={<DocStatusLabel docStatus="denied" text={t`Denied`} />}
          />
          <FormControlLabel
            className={`${styles.docStatusStyle} ${filters.expired ? styles.checkedLabel : ''}`}
            control={<CheckboxCustom checked={filters.expired} classes={{ checked: styles.checked }} name="expired" onChange={handleChange} />}
            label={<DocStatusLabel docStatus="expired" text={t`Expired`} />}
          />
        </Box>
      </FilterAccordion>
    </Box>
  );
}
