import React from "react";
import { NumberListInputProps } from "./types";
import { t } from "@lingui/macro";
import { Box, FilledInput, InputProps, Typography, useTheme } from "@mui/material";

export const NumberListInput: React.FC<NumberListInputProps> = (props) => {
  const { value, min, max, labels, readOnly, onChange } = props;
  const { palette } = useTheme();

  const handleInputChange: InputProps["onChange"] = (event) => {
    const value = Number.parseInt(event.currentTarget.value || "");

    if ((min === undefined || value >= min) && (max === undefined || value <= max)) {
      onChange?.(value);
    }
  };

  const { title = t`User count`, disclaimer } = labels || {};

  return (
    <Box>
      <Box alignItems="baseline" display="flex" gap={1} mb={1}>
        <Typography fontSize={14} fontWeight={600}>{title}</Typography>
        {disclaimer ? <Typography color={palette.grey[700]} fontSize={13}>{disclaimer}</Typography> : undefined}
      </Box>
      <Typography fontSize={14} mb={1}>{t`User count`}</Typography>
      <FilledInput onChange={handleInputChange} readOnly={readOnly} type="number" value={value} />
    </Box>
  );
};
