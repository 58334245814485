import { Frequency, rrulestr } from "rrule";
import { resolveRecurrenceTypeLabel } from "./resolve-recurrence-type-label";

export const resolveRRuleRecurrenceLabel = (rrule?: string): string | undefined => {
  if (!rrule) {
    return undefined;
  }

  try {
    const { options } = rrulestr(rrule);

    switch (options.freq) {
      case Frequency.DAILY:
        return resolveRecurrenceTypeLabel("daily");
      case Frequency.WEEKLY:
        return resolveRecurrenceTypeLabel("weekly");
      case Frequency.MONTHLY:
        return resolveRecurrenceTypeLabel("monthly");
      default:
        return undefined;
    }
  } catch {
    return undefined;
  }
};
