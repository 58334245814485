import {useTypedSelector} from "store/_legacy/Redux/store";
import styles from "../../styles.module.scss";
import {t} from "@lingui/macro";

export default function ExecutiveAssistantProfileName() {
  const { executiveAssistant, profile } = useTypedSelector(state => state);
  const profileName = executiveAssistant.selectedUser?.id !== profile?.id ? t`This is account of` : t`Welcome`;
  const executiveAssistantClass = executiveAssistant.selectedUser ? styles.executiveAssistant : '';

  return (
    <div className={`${styles.profileName} ${executiveAssistantClass}`}>
      {profileName}
    </div>
  );
}
