import moment from "moment";
import { weekDays } from "../../../../Store/calendar";
import {EditCalendarSingleDaysModel, EditCalendarWeekDaysModel} from "../../../../Store/calendar/models";

// helper function for date compare
export function compareDateFormat(date: Date | string | undefined, keepLocalTime = false): string {
  return moment(date).utc(keepLocalTime).startOf('day').format();
}

// helper function for timezone offset compensating (used when need to keep Date format)
export function compensateTimezoneOffset(date: Date | string) {
  return new Date(new Date(date).getTime() + new Date(date).getTimezoneOffset() * 60000);
}

export function getUpdatedWeekDays(selected: boolean, updateDisabledWeekDays: number[]): EditCalendarWeekDaysModel {
  if (selected) { // if enable week day return array of all enabled days of week
    return { enableWeekDays: weekDays.filter(x => !updateDisabledWeekDays.includes(x)) };
  }

  // if disable week day return array of all disabled days of week
  return { disabledWeekDays: updateDisabledWeekDays };
}

// helpers for monthly booking
export function getUpdatedSingleDay(selected: boolean, selectionDate: Date | string): EditCalendarSingleDaysModel {
  if (selected) {
    return { enableDates: [compareDateFormat(selectionDate)] };
  }

  return { disabledDates: [compareDateFormat(selectionDate)] };
}

export function getDisabledDaysFromWeeks(lastSelectedWeekNumber: number | null, initialMonth: Date | string, numberOfMonths: number) {
  // function returns array days on week from Week widget
  // init available days range
  const start = moment().add(1, 'day').startOf('day').utc(true); // start from tomorrow
  const end = moment(start).add(numberOfMonths - 1, 'month').endOf('month').endOf('day').utc(true);
  const lastDisabledDates: string[] = [];

  // get array last disabled action
  if (typeof lastSelectedWeekNumber === 'number') { // check if numbers 0/1/2/3/4/5/6
    const lastCurrent = moment(start.startOf('day').utc()).clone().day(lastSelectedWeekNumber);

    if ( lastCurrent.isSameOrAfter(start, 'd') ) {
      lastDisabledDates.push(moment(lastCurrent).format());
    }
    while ( lastCurrent.isSameOrBefore(end) ) {
      lastCurrent.add(7, 'days');
      lastDisabledDates.push(moment(lastCurrent).format());
    }
  }

  return lastDisabledDates;
}

export function getAllDisabledDates(lastSelectedWeekAction: boolean, disabledWeekDaysArray: string[], disabledDates: Date[] | string[]) {
  // function returns manual disabled days + disabled from Week widget
  const initialDates = disabledDates
    .map(date => moment(date).utc().format())
    .filter(date => moment(date).utc().isAfter(moment().utc())); // filter only future dates
  let modifyWeekDays = [...disabledWeekDaysArray];

  if (!lastSelectedWeekAction) {
    modifyWeekDays = initialDates.concat(modifyWeekDays);
  } else {
    modifyWeekDays = initialDates.filter(x => !modifyWeekDays.includes(x));
  }

  return modifyWeekDays;
}

export function reservationExist(bookedDays: Date[] | string[], selectionDate: Date | string) {
  // function check if current day has reservation - used for confirmation popup show
  const bookedDaysFormat = bookedDays.map(date => compareDateFormat(date)); // format for comparing
  const selectionDateFormat = compareDateFormat(selectionDate); // format for comparing

  return bookedDaysFormat.includes(selectionDateFormat);
}

export function reservationFromWeeklyExist(updatedDisabledDays: Date[] | string[], bookedDays: Date[] | string[]) {
  // function check if any day from week has reservation - used for confirmation popup show
  const disabledDaysFormat = updatedDisabledDays.map(date => compareDateFormat(date)); // format for comparing
  const bookedDaysFormat = bookedDays.map(date => compareDateFormat(date)); // format for comparing

  return disabledDaysFormat.some( day => bookedDaysFormat.includes(day) );
}
