import Popup from 'reactjs-popup';
import Calendar from 'components/_legacy/Icons/Calendar';
import {
  Calendar as CalendarDateRange,  
} from 'react-date-range';
import moment from 'moment';
import styles from './styles.module.scss';


interface Props {  
  config: any;  
  opened: boolean;
  selectedDate: Date | undefined;
  setCalendarOpened: (open: boolean) => void; 
  handleChangeDate: (date: Date) => void;  
}

export default function CalendarDatePopup({ opened, selectedDate, setCalendarOpened, handleChangeDate, config }: Props) {
  return (
    <Popup
      className={'modal'}
      modal
      nested   
      onOpen={() => setCalendarOpened(true)}
      open={opened}
      trigger={(
          <div style={{display: 'flex'}}>
            <Calendar
              backgroundColor={config.theme.primaryLight}
              className={styles.calendarDateTrigger}
              color={config.theme.primary}
              size={30}
            />
          </div>
        )}
    >
      <CalendarDateRange
        className="custom-calendar"
        date={moment(selectedDate).toDate()}
        minDate={new Date()}
        onChange={handleChangeDate}
        showMonthAndYearPickers={false}                
      />                                            
    </Popup>
  );
}
