import { ActionPayload, BaseErrorResponse, BaseResponse } from "store/_legacy/Models/ReduxModels";
import {
  CancelMeetingReservationRequest,
  CancelMeetingReservationResponse,
  CreateMeetingReservationRequest,
  CreateMeetingReservationResponse,
  GetMeetingReservationsRequest,
  GetMeetingReservationsResponse,
  IMeetingReservation,  
  IMeetingReservationLocation,
} from "./models";
import { t } from "@lingui/macro";

export const GET_MEETING_RESERVATIONS = 'GET_MEETING_RESERVATIONS';
export const GET_MEETING_RESERVATIONS_FAIL = 'GET_MEETING_RESERVATIONS_FAIL';
export const GET_MEETING_RESERVATIONS_SUCCESS = 'GET_MEETING_RESERVATIONS_SUCCESS';

export const GET_PREVIOUS_MEETING_RESERVATIONS = 'GET_PREVIOUS_MEETING_RESERVATIONS';
export const GET_PREVIOUS_MEETING_RESERVATIONS_FAIL = 'GET_PREVIOUS_MEETING_RESERVATIONS_FAIL';
export const GET_PREVIOUS_MEETING_RESERVATIONS_SUCCESS = 'GET_PREVIOUS_MEETING_RESERVATIONS_SUCCESS';

export const CREATE_MEETING_RESERVATION = 'CREATE_MEETING_RESERVATION';
export const CREATE_MEETING_RESERVATION_FAIL = 'CREATE_MEETING_RESERVATION_FAIL';
export const CREATE_MEETING_RESERVATION_SUCCESS = 'CREATE_MEETING_RESERVATION_SUCCESS';

export const CANCEL_MEETING_RESERVATION = 'CANCEL_MEETING_RESERVATION';
export const CANCEL_MEETING_RESERVATION_FAIL = 'CANCEL_MEETING_RESERVATION_FAIL';
export const CANCEL_MEETING_RESERVATION_SUCCESS = 'CANCEL_MEETING_RESERVATION_SUCCESS';

const SET_MEETING_RESERVATION_DATA = 'SET_MEETING_RESERVATION_DATA';

// get meeting reservations list
export interface GetMeetingReservations {
  type: typeof GET_MEETING_RESERVATIONS;
  payload: ActionPayload<GetMeetingReservationsRequest>;
  clearData?: boolean;
}
interface GetMeetingReservationsFail {
  type: typeof GET_MEETING_RESERVATIONS_FAIL;
  payload: BaseErrorResponse;
}
interface GetMeetingReservationsSuccess {
  type: typeof GET_MEETING_RESERVATIONS_SUCCESS;
  payload: BaseResponse<GetMeetingReservationsResponse>;
}

// get previous meeting reservations list
interface GetPreviousMeetingReservations {
  type: typeof GET_PREVIOUS_MEETING_RESERVATIONS;
  payload: ActionPayload<GetMeetingReservationsRequest>;
  clearData?: boolean;
  noLoadState?: boolean;
}
interface GetPreviousMeetingReservationsFail {
  type: typeof GET_PREVIOUS_MEETING_RESERVATIONS_FAIL;
  payload: BaseErrorResponse;
}
interface GetPreviousMeetingReservationsSuccess {
  type: typeof GET_PREVIOUS_MEETING_RESERVATIONS_SUCCESS;
  payload: BaseResponse<GetMeetingReservationsResponse>;  
}

// create meeting reservation
export interface CreateMeetingReservation {
  type: typeof CREATE_MEETING_RESERVATION;
  payload: ActionPayload<CreateMeetingReservationRequest>;
}
interface CreateMeetingReservationFail {
  type: typeof CREATE_MEETING_RESERVATION_FAIL;
  payload: BaseErrorResponse;
}
export interface CreateMeetingReservationSuccess {
  type: typeof CREATE_MEETING_RESERVATION_SUCCESS;
  payload: BaseResponse<CreateMeetingReservationResponse>;
}

// cancel meeting reservation
export interface CancelMeetingReservation {
  type: typeof CANCEL_MEETING_RESERVATION;
  payload: ActionPayload<CancelMeetingReservationRequest>;
}
interface CancelMeetingReservationFail {
  type: typeof CANCEL_MEETING_RESERVATION_FAIL;
  payload: BaseErrorResponse;
}
interface CancelMeetingReservationSuccess {
  type: typeof CANCEL_MEETING_RESERVATION_SUCCESS;
  payload: BaseResponse<CancelMeetingReservationResponse>;
}

// set meeting reservation data
interface SetMeetingReservationData {
  type: typeof SET_MEETING_RESERVATION_DATA;
  payload: Partial<State>;
}

type Actions = GetMeetingReservations
  | GetMeetingReservationsFail
  | GetMeetingReservationsSuccess
  | GetPreviousMeetingReservations
  | GetPreviousMeetingReservationsFail
  | GetPreviousMeetingReservationsSuccess
  | CreateMeetingReservation
  | CreateMeetingReservationFail
  | CreateMeetingReservationSuccess
  | CancelMeetingReservation
  | CancelMeetingReservationFail
  | CancelMeetingReservationSuccess
  | SetMeetingReservationData  

export interface State {
  error: string;
  successMessage: string,
  loading: boolean;  
  reservationCreationLoading: boolean;
  totalCount: number;
  totalUpcomingCount: number;
  previousTotalCount: number;
  reservationSelected: string;
  detailsOpened: boolean;  
  reservationCanceled: boolean;
  reservationCreated: boolean;
  previousReservations: {
    [key: string]: IMeetingReservation;
  };
  meetingReservations: {
    [key: string]: IMeetingReservation
  };  
  pages: number;
}

const initialState: State = {
  error: '',
  successMessage: '',
  loading: false,  
  reservationCreationLoading: false,
  totalCount: 0,
  totalUpcomingCount: 0,
  previousTotalCount: 0,  
  reservationSelected: '',
  detailsOpened: false,  
  reservationCanceled: false,
  reservationCreated: false,
  previousReservations: {},
  meetingReservations: {},
  pages: 0,
};

export default function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case GET_MEETING_RESERVATIONS:
      return {
        ...state,
        loading: true,
        meetingReservations: action.clearData ? {} : state.meetingReservations,
      };
    case GET_MEETING_RESERVATIONS_SUCCESS: {
      const { items } = action.payload.data.result.data;      

      const newReservations = { ...state.meetingReservations };

      items.forEach(reservation => {
        newReservations[reservation.id] = reservation;
      });

      return {
        ...state,
        loading: false,
        error: '',
        meetingReservations: newReservations,
        totalCount: action.payload.data.result.data.meta.total,
      };
    }      
    case GET_MEETING_RESERVATIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: t`There was an error getting meeting reservations. Please try again.`,
      };
    case GET_PREVIOUS_MEETING_RESERVATIONS:
      return {
        ...state,        
        previousReservations: action.clearData ? { } : state.previousReservations,
        loading: action.noLoadState ? state.loading : true,
      };
    case GET_PREVIOUS_MEETING_RESERVATIONS_SUCCESS: {
      const { items } = action.payload.data.result.data;      

      const newReservations = { ...state.previousReservations };

      items.forEach(reservation => {
        newReservations[reservation.id] = reservation;
      });

      return {
        ...state,
        loading: false,
        error: '',
        previousReservations: newReservations,
        previousTotalCount: action.payload.data.result.data.meta.total,
        pages: action.payload.data.result.data.meta.pages,
      };
    }
    case GET_PREVIOUS_MEETING_RESERVATIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: t`There was an error getting meeting reservations. Please try again.`,
      };
    case CREATE_MEETING_RESERVATION:
      return {
        ...state,
        reservationCreationLoading: true,
        error: '',
      };
    case CREATE_MEETING_RESERVATION_FAIL: {
      const errors: string[] = [t`There was an error creating meeting reservation.`];

      if (action.payload?.error?.code === 1_001_011) {
        errors.push(t`You don't have permissions to access the user's calendar.`);
      }
      
      return {
        ...state,
        reservationCreationLoading: false,
        error: errors.join(" "),
      };
    }
    case CREATE_MEETING_RESERVATION_SUCCESS: {
      return {
        ...state,    
        reservationCreationLoading: false,            
        error: '',
      };
    }

    case CANCEL_MEETING_RESERVATION: {
      return {
        ...state,
        loading: true,
      };
    }

    case CANCEL_MEETING_RESERVATION_FAIL: {
      const errors: string[] = [t`There was an error cancelling meeting reservation.`];

      if (action.payload?.error?.code === 1_001_011) {
        errors.push(t`You don't have permissions to access the user's calendar.`);
      }

      return {
        ...state,
        loading: false,
        error: errors.join(" "),        
      };
    }
    case CANCEL_MEETING_RESERVATION_SUCCESS: {
      const reservations = state.meetingReservations;

      reservations[action.payload.data.result.data.id].status = 'canceled';

      return {
        ...state,
        error: '',
        loading: false,
        detailsOpened: false,
        reservationCanceled: true,
        meetingReservations: reservations,
      };
    }    

    case SET_MEETING_RESERVATION_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
}

// Actions
export function getMeetingReservations
(data: GetMeetingReservationsRequest): GetMeetingReservations {
  let url = `/api/users/${data.delegatedUserId || "me"}/reservations?page=${data.page}&limit=${data.limit}&filter${data.filter}&include[0]=schedule&include[1]=room&include[2]=floor&include[3]=floor.location&include[4]=attendees&filter[status]=booked&orderBy=asc:schedule.startDate`;

  // update BE for statuses
  // if (data.statuses?.length) {
  //   url += `&status=${data.statuses.join(',')}`;
  // }

  // replace s with search after BE update
  if (data.s) {
    url += `&s=${data.s}`;
  }

  return {
    type: GET_MEETING_RESERVATIONS,
    payload: {
      request: {
        method: 'GET',
        url,
      },
    },
    clearData: data.clearData,
  };
}

export function createMeetingReservation(data: CreateMeetingReservationRequest, delegatedUserId?: string): CreateMeetingReservation {
  return {
    type: CREATE_MEETING_RESERVATION,
    payload: {
      request: {
        method: 'POST',
        url: `/api/users/${delegatedUserId || "me"}/reservations`,
        data,
      },
    },
  };
}

export function setMeetingReservationData(data: Partial<State>): SetMeetingReservationData {
  return {
    type: SET_MEETING_RESERVATION_DATA,
    payload: data,
  };
}

/**
 * Counts all reservations locations and returns the one that appears more often
 */
 export function selectMoreOftenLocation(state: State): IMeetingReservationLocation | undefined {
  const reservationsArray = Object.keys(state.meetingReservations)
    .map(reservationId => state.meetingReservations[reservationId]);

  const locationsCount: { [locationId: string]: { location: IMeetingReservationLocation; count: number } } = {};
  let moreOftenLocation: { location: IMeetingReservationLocation; count: number } | undefined;

  reservationsArray.forEach(reservation => {
      if (!reservation.floor.location) return;

      const locationCount = locationsCount[reservation.floor.location.id];

      if (!locationCount) {
        locationsCount[reservation.floor.location.id] = {
          count: 0,
          location: reservation.floor.location,
        };
      } else {
        locationsCount[reservation.floor.location.id] = {
          count: locationCount.count + 1,
          location: locationCount.location,
        };
      }
  });

  Object.keys(locationsCount).forEach(locationId => {
    const locationCount = locationsCount[locationId];

    if (!moreOftenLocation) {
      moreOftenLocation = locationCount;
    } else {
      if (locationCount.count > moreOftenLocation.count) {
        moreOftenLocation = locationCount;
      }
    }
  });

  return moreOftenLocation?.location;
}