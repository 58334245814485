import backgroundImage from './assets/welcome-bg.png';
import Box from 'components/_legacy/Box';
import Button from 'components/_legacy/Button';
import Container from 'components/_legacy/Container';
import Heading from 'components/_legacy/Heading';
import Select from 'components/_legacy/Select';
import styles from './styles.module.scss';
import TextField from 'components/_legacy/TextField';
import { loginQA } from 'App/Store/Auth/loginDuck';
import { setLanguage } from 'store/_legacy/Redux/Ducks/configDuck';
import { Trans, t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useTypedSelector } from 'store/_legacy/Redux/store';
import { Redirect } from 'react-router-dom';
import { LANGUAGES } from "store/_legacy/Redux/Ducks/configDuck/models";

export default function LoginQA() {
  const dispatch = useDispatch();
  const { language } = useTypedSelector(state => state.config);
  const { error, loginQA: loginQAVerification } = useTypedSelector(state => state.login);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const getErrorMessage = (): string => {
    if (!submitted) return '';

    let errorMessage = !email ? 'Email is requireqd' : '';

    if (error) {
      errorMessage = error;
    }

    return errorMessage;
  };

  const onSubmit = (event: any) => {
    event.preventDefault();

    setSubmitted(true);

    if (email && password) {
      dispatch(loginQA({ email, password, platform: 'web' }));
    }
  };

  const languages = LANGUAGES;

  const handleChangeLanguage = (event: React.ChangeEvent<{ name?: string | undefined; value: any; }>) => {
    const newLanguage = [...languages].find(language => language.value === event.target.value);
    if (newLanguage) {
      dispatch(setLanguage(newLanguage));
    }
  };

  if (loginQAVerification) {
    return <Redirect to="/" />;
  }

  return (
    <Box className={styles.container}>
      <Box className={styles.background}>
        <Box />

        <Box height="100vh">
          <img alt={t`Background Image`} className={styles.backgroundImage} src={backgroundImage} />
        </Box>
      </Box>

      <Container>
        <form onSubmit={onSubmit}>
          <Box
            alignItems="center"
            display="grid"
            gap={50}
            gridTemplateColumns="1fr 1fr"
            minHeight="100vh"
          >
            <Box maxWidth={370}>
              <Box marginBottom={20}>
                <Heading>
                  <Trans>
                    Welcome to Together
                  </Trans>
                </Heading>
              </Box>
              <Box display="grid" gap={20}>
                <TextField
                  autoComplete="email"
                  error={submitted && (!email || !!error)}
                  helperText={getErrorMessage()}
                  id="email"
                  label="Email"
                  onChange={(event) => setEmail(event.target.value)}
                  placeholder="example@example.com"
                  required
                  type="email"
                  value={email}
                />

                <TextField
                  autoComplete="password"
                  error={submitted && !password}
                  helperText={submitted && !password ? 'Password is required' : ''}
                  id="password"
                  label="Password"
                  onChange={(event) => setPassword(event.target.value)}
                  placeholder="Type here"
                  required
                  type="password"
                  value={password}
                />
              </Box>

              <Box marginBottom={20} marginTop={20}>
                <div className={styles.textBlock}>
                  <Trans>
                    Book and manage your places, get notified on arrival day in one app.
                  </Trans>
                </div>
              </Box>

              <Box
                alignItems="center"
                display="flex"
                justifyContent="between"
                marginTop={15}
              >
                <Button
                  aria-label={t`login`}
                  buttonType="submit"
                  name={t`login`}
                  size="full"
                  type="black"
                >
                  <Trans>
                    Log In
                  </Trans>
                </Button>
              </Box>

              <Box marginTop={20}>
                <Select
                  aria-label={t`language`}
                  className={styles.selectContainer}
                  id="language"
                  items={languages}
                  label={t`Language:`}
                  name={t`language`}
                  onChange={handleChangeLanguage}
                  value={language.value}
                />
              </Box>
            </Box>
          </Box>
        </form>
      </Container>
    </Box>
  );
}
