import React from "react";
import { NumberInputProps } from "./types";
import { Box, FilledInput, IconButton } from "@mui/material";
import { AddRounded, RemoveRounded } from "@mui/icons-material";

export const NumberInput: React.FC<NumberInputProps> = (props) => {
  const { value = 0, disabled, min, onChange } = props;

  const handleChange = (value?: number) => {
    if (value && min && value < min) {
      return;
    }

    onChange?.(value);
  };

  return (
    <Box alignItems="center" display="flex">
      <IconButton color="primary" disabled={disabled} onClick={() => handleChange(value - 1)} size="small">
        <RemoveRounded fontSize="small" />
      </IconButton>
      <FilledInput
        disabled={disabled}
        onChange={(event) => handleChange(Number.parseInt(event.currentTarget.value))}
        sx={{
          width: 30,
          height: 30,
          fontSize: 14,
          "& input": { p: 0, textAlign: "center", appearance: "textfield", "&::-webkit-inner-spin-button": { appearance: "none" } },
        }}
        type="number"
        value={value || ""}
      />
      <IconButton color="primary" disabled={disabled} onClick={() => handleChange(value + 1)} size="small">
        <AddRounded fontSize="small" />
      </IconButton>
    </Box>
  );
};