import { searchFullText } from "./search-full-text";

export const searchFullTextObject = <T>(target: T, keys: Array<keyof T>, search: string): boolean => {
  for (const key of keys) {
    const value = target[key];

    if (typeof value === "string" && searchFullText(value, search)) {
      return true;
    }
  }

  return false;
};
