import ArrowRightIcon from 'components/_legacy/Icons/ArrowRight';
import Box from 'components/_legacy/Box';
import styles from './styles.module.scss';
import Text from 'components/_legacy/Text';
import { isStepCompleted, isStepDisabled } from 'App/Store/Bookings/createNewVisitDuck';
import { Link, useLocation } from 'react-router-dom';
import { t } from '@lingui/macro';
import { useTypedSelector } from 'store/_legacy/Redux/store';
import NumberCounter from "components/_legacy/NumberCounter";

interface VisitBreadcrumbItem {
  label: string; // TO DO add logic for support steps
  number: number;
  to: string;
}

interface VisitItemProps extends VisitBreadcrumbItem {
  hasArrow: boolean;
}

function VisitItem({ hasArrow, label, number, to }: React.PropsWithChildren<VisitItemProps>) {
  const location = useLocation();
  const { config, createNewVisit, bookingCovidConfirmation } = useTypedSelector(state => state);

  const isActive = location.pathname.includes(to);
  const isCompleted = isStepCompleted(number, createNewVisit);
  const isDisabled = isStepDisabled(number, createNewVisit, bookingCovidConfirmation.disableFloorPage);

  const item = (
    <Box alignItems="center" display="flex" style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}>
      <Box alignItems="center" display="grid" gap={5} gridTemplateColumns="auto auto">
        <NumberCounter
          boxColor={isActive || isCompleted ? config.theme.primaryLight : '#F4F4F4'}
          count={number}
          textColor={isActive ? 'blue' : isCompleted ? 'black' : 'lightGray'}
        />

        <Text color={isActive ? 'blue' : isCompleted ? 'black' : 'lightGray'} size="xlg" weight="semi-bold">
          {label}
        </Text>
      </Box>


      {hasArrow &&
        <Box className={styles.arrowContainer}>
          <ArrowRightIcon color={isActive || isCompleted ? config.theme.primary : config.theme.greyMedium} />
        </Box>
      }
    </Box>
  );

  return isDisabled ? item : (
    <Link to={to}>
      {item}
    </Link>
  );
}

export default function VisitBreadcrumbs() {
  const ITEMS: VisitBreadcrumbItem[] = [
    { label: t`Select location`, number: 1, to: '/create-new-visit/onsite/select-location' },
    { label: t`Select floor`, number: 2, to: '/create-new-visit/onsite/select-floor' },
    { label: t`Select date and read instructions`, number: 3, to: '/create-new-visit/onsite/select-date' },
  ];

  return (
    <Box alignItems="center" display="flex">
      {ITEMS.map((item, index) => (
        <Box key={item.to + index} marginEnd={5}>
          <VisitItem
            {...item}
            hasArrow={index + 1 < ITEMS.length}
          />
        </Box>
      ))}
    </Box >
  );
}
