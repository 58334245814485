import {useDispatch} from "react-redux";
import {useTypedSelector} from "store/_legacy/Redux/store";
import {useEffect, useState} from "react";
import {Trans, t} from "@lingui/macro";
import styles from "./styles.module.scss";
import Box from "components/_legacy/Box";
import Button from "components/_legacy/Button";
import {createBooking, setBookingsData} from "../../../../../App/Store/Bookings/bookingDuck";
import format from "date-fns/format";
import {CreateWeeklyBookingModel} from "../../../../../App/Store/Bookings/bookingDuck/models";
import useSnackbar from "components/_legacy/Snackbar/useSnackbar";

export default function BookDesk() {
  const dispatch = useDispatch();
  const [openSnackbar] = useSnackbar();
  const { config, booking: bookingDuck, adminReservationJustInTime } = useTypedSelector(state => state);
  const { selectedData, bookingType, dateFrom, dateTo, timeFrom, timeTo, weeklySlots } = adminReservationJustInTime;
  const { user, dateTime, location, floor, desk, parkingSpot } = selectedData;
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (bookingDuck.error) {
      const handleClose = () => {
        dispatch(setBookingsData({ error: '' }));
      };

      openSnackbar({
        onClose: handleClose,
        text: bookingDuck.error,
        type: 'error',
      });
    }
  }, [bookingDuck.error]);

  useEffect(() => {
    if (bookingDuck.bookingCreated || bookingDuck.error) {
      setIsSubmitting(false); // enable book button after action has processed
    }
  }, [bookingDuck.bookingCreated, bookingDuck.error]);

  const allStepsCompleted = Boolean(user && dateTime && location && floor && desk);

  const onBookDesk = () => {
    switch (bookingType) {
      case "custom": {
        if (user && location && desk && dateFrom && dateTo && timeFrom && timeTo) {
          const timeFromFormatted = timeFrom ? format(new Date(timeFrom), 'HH:mm') : '';
          const timeToFormatted = timeTo ? format(new Date(timeTo), 'HH:mm') : '';

          setIsSubmitting(true);
          dispatch(createBooking({
            custom: {
              dateFrom: format(new Date(dateFrom), 'yyyy-MM-dd'),
              dateTo: format(new Date(dateTo), 'yyyy-MM-dd'),
              timeFrom: timeFromFormatted,
              timeTo: timeToFormatted,
            },
            deskId: desk.id,
            userId: user.id,
            parkingSpotId: parkingSpot?.id,
          }, location.id));
        }
        break;
      }
      case "daily": {
        if (user && location && desk && timeFrom && timeTo) {
          const timeFromFormatted = timeFrom ? format(new Date(timeFrom), 'HH:mm') : '';
          const timeToFormatted = timeTo ? format(new Date(timeTo), 'HH:mm') : '';

          setIsSubmitting(true);
          dispatch(createBooking({
            daily: {
              timeFrom: timeFromFormatted,
              timeTo: timeToFormatted,
            },
            deskId: desk.id,
            userId: user.id,
            parkingSpotId: parkingSpot?.id,
          }, location.id));
        }
        break;
      }
      case "weekly": {
        if (user && location && desk && timeFrom && weeklySlots) {
          const weekly: CreateWeeklyBookingModel[] = weeklySlots
            .filter(({ isSelected, timeFrom, timeTo }) => isSelected && timeFrom && timeTo)
            .map(({ index, isSelected: selected, day: weekDay, timeFrom, timeTo }) => ({
              index,
              selected,
              weekDay,
              timeFrom: timeFrom ? format(new Date(timeFrom), "HH:mm") : new Date(),
              timeTo: timeTo ? format(new Date(timeTo), "HH:mm") : new Date(),
            }));
          
          if (weekly.length) {
            setIsSubmitting(true);
            dispatch(createBooking({
              weekly,
              deskId: desk.id,
              userId: user.id,
              parkingSpotId: parkingSpot?.id,
            }, location.id));
          }
        }
        break;
      }
      default:
        break;
    }
  };

  return (
    <Box
      alignItems="center"
      backgroundColor={config.theme.primary}
      borderRadius={20}
      display="flex"
      justifyContent="between"
      padding={22}
      style={{
        backgroundColor: config.theme.primary,
        opacity: !allStepsCompleted ? '0.5' : '1',
      }}
    >
      <Button
        aria-label={t`Book a Desk`}
        className={styles.bookDeskButton}
        disabled={!allStepsCompleted || isSubmitting}
        name={"Book a Desk"}
        onClick={onBookDesk}
        size="sm"
        type="white"
      >
        <Trans>Book a Desk</Trans>
      </Button>
    </Box>
  );
}
