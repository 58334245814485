import React, { useEffect, useState } from "react";
import { ReservationsListProps } from "./types";
import { useLazyGetUserReservationsQuery } from "store";
import { Box, Skeleton, Typography, useTheme } from "@mui/material";
import { useInView } from "react-intersection-observer";
import { ReservationListItem } from "./reservation-list-item";

export const ReservationsList: React.FC<ReservationsListProps> = (props) => {
  const { limit, filter, orderBy, include, userId, placeholder, modifiable } = props;
  const { palette } = useTheme();
  const [page, setPage] = useState(1);
  const [moreRef, loadMore] = useInView();
  const [triggerGetUserReservationsQuery, getUserReservationsQuery] = useLazyGetUserReservationsQuery();
  const { data: getUserReservationsResponse, isLoading: reservationsAreLoading } = getUserReservationsQuery;
  const { items: reservations, links } = getUserReservationsResponse?.result?.data || {};
  const hasMore = !!links?.next;

  useEffect(() => {
    setPage(1);
    triggerGetUserReservationsQuery({ limit, filter, orderBy, include, userId, page: 1, singleEntryListing: true }, true);
  }, [limit, JSON.stringify(filter), orderBy?.toString(), include?.toString(), userId]);

  useEffect(() => {
    if (page > 1) {
      triggerGetUserReservationsQuery({ limit, filter, orderBy, include, userId, page, singleEntryListing: true }, true);
    }
  }, [page]);

  useEffect(() => {
    if (loadMore && hasMore && !reservationsAreLoading) {
      setPage((prev) => prev + 1);
    }
  }, [loadMore, hasMore, reservationsAreLoading]);

  return (
    <Box alignItems="stretch" display="flex" flexDirection="column">
      {page === 1 && reservationsAreLoading || !reservations ? (
        <>
          <Skeleton height={88} sx={{ mb: 1 }} variant="rectangular" />
          <Skeleton height={88} sx={{ mb: 1 }} variant="rectangular" />
          <Skeleton height={88} variant="rectangular" />
        </>
      ) : (
        reservations.length === 0 ? (
          Array.isArray(placeholder) ? (
            placeholder.map((placeholder, index) => (
              <Typography color={palette.grey[700]} fontSize={14} fontWeight="600" key={placeholder} mt={index ? 0 : 2} textAlign="center">
                {placeholder}
              </Typography>
            ))
          ) : (
            <Typography color={palette.grey[700]} fontSize={14} fontWeight="600" mt={2} textAlign="center">
              {placeholder}
            </Typography>
          )
        ) : reservations.map((reservation, index) => {
          const isLast = index + 1 === reservations.length;
  
          return (
            <ReservationListItem
              key={reservation.id + reservation.schedule?.entries?.[0]?.id || ""}
              mb={isLast ? 0 : 1}
              modifiable={modifiable}
              reservation={reservation}
            />
          );
        })
      )}
      {hasMore ? <Skeleton height={88} ref={moreRef} sx={{ mt: 1 }} variant="rectangular" /> : undefined}
    </Box>
  );
};
