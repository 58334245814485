import { t } from "@lingui/macro";
import { Box, Typography, useTheme } from "@mui/material";
import { endOfToday, startOfToday } from "date-fns";
import React, { useMemo } from "react";
import { ReservationStatus, ReservationType, useGetUserReservationsQuery } from "store";
import { ReservationListItem } from "./reservation-list-item";
import { useDelegatedId } from "hooks";

export const TodaysReservationsList: React.FC = () => {
  const { palette } = useTheme();
  const delegatedId = useDelegatedId();
  const now = useMemo(() => new Date(), [delegatedId]);
  const { data: getUserReservationsResponse, isLoading, isFetching } = useGetUserReservationsQuery({
    filter: {
      "status": ReservationStatus.BOOKED,
      "type": `$in:${[ReservationType.DESK, ReservationType.VISIT, ReservationType.ROOM].join(",")}`,
      "schedule.entries.startDate": `$gte:${startOfToday().toISOString()}`,
      "schedule.entries.endDate": `$btw:${now.toISOString()},${endOfToday().toISOString()}`,
    },
    limit: -1,
    include: [
      "attendees",
      "desk",
      "room",
      "schedule",
      "schedule.entries",
      "floor",
      "floor.location",
      "children",
      "children.parkingSpot",
      "desk",
      "desk.amenities",
    ],
    orderBy: "asc:schedule.startDate",
    userId: delegatedId,
  });
  const { items: reservations } = getUserReservationsResponse?.result?.data || {};

  if (isLoading || isFetching || !reservations || !reservations.length) {
    return <></>;
  }

  return (
    <Box bgcolor={palette.primary.main} borderRadius={4} mb={1} p={2} sx={{ borderTopLeftRadius: 0 }}>
      <Box alignItems="center" display="flex" justifyContent="space-between" mb={2}>
        <Box alignItems="center" display="flex">
          <Typography color="#fff" fontSize={18} fontWeight="600" lineHeight={1}>
            {reservations.length > 1 ? t`Your bookings today` : t`Your booking today`}
          </Typography>
          <Box alignItems="center" bgcolor="#fff" borderRadius={2} display="flex" height={18} justifyContent="center" ml={1} width={18}>
            <Typography color={palette.primary.main} fontSize={14} fontWeight="600" lineHeight={1}>{reservations.length}</Typography>
          </Box>
        </Box>
        <Box bgcolor="#fff" height="1px" width={38} />
      </Box>
      <Box alignItems="stretch" display="flex" flexDirection="column">
        {reservations.map((reservation, index) => {
          const isLast = index + 1 === reservations.length;
  
          return (
            <ReservationListItem
              bgcolor="#fff"
              key={reservation.id}
              mb={isLast ? 0 : 1}
              modifiable={!delegatedId}
              reservation={reservation}
              showCountdown
            />
          );
        })}
      </Box>
    </Box>
  );
};
