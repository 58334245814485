import { useDispatch } from "react-redux";
import { useTypedSelector } from "store/_legacy/Redux/store";
import { Trans, t } from "@lingui/macro";

import Box from "components/_legacy/Box";
import Button from "components/_legacy/Button";
import { createGroup, editGroup } from "../../../../../Store/groups";
import { setGroupsCreateEditFormData } from "../../../../../Store/groups/forms/createEditForm";
import {
  GroupCreateEditFormActionStateEnum,
  GroupCreateEditFormTabEnum,
} from "../../../../../Store/groups/forms/createEditForm/models";
import { CreateGroupRequest } from "../../../../../Store/groups/models";

interface Props {
  onCancelAction: () => void;
}

export default function ActionButtons(props: Props) {
  const dispatch = useDispatch();
  const { onCancelAction } = props;
  const {
    actionState,
    tabsState,
    group,
    formDataName,
    formDataLocationIds,
    formDataAdGroup,
    formErrors,
  } = useTypedSelector(state => state.groupCreateEditForm);

  const isActiveDirectory = tabsState === GroupCreateEditFormTabEnum.activeDirectory;
  const isAddState = actionState === GroupCreateEditFormActionStateEnum.add;
  const isEditState = actionState === GroupCreateEditFormActionStateEnum.edit;
  const isActiveAdGroup = isActiveDirectory || (isEditState && group?.adGroup);

  const actionTypeName = isAddState ? t`Add` : t`Edit`;

  const validateFields = () => {
    let validate = true;

    if (!formDataName.length && !formDataAdGroup?.displayName.length) {
      validate = false;

      dispatch(setGroupsCreateEditFormData({
        formErrors: {
          ...formErrors,
          groupNameError: true,
        },
      }));
    }

    if (isActiveAdGroup && !formDataAdGroup?.id) {
      validate = false;

      dispatch(setGroupsCreateEditFormData({
        formErrors: {
          ...formErrors,
          groupAdError: true,
        },
      }));
    }

    return validate;
  };

  const onGroupFormSubmit = () => {
    if (!validateFields()) {
      return;
    }

    // submit logic
    if (isAddState) {
      const groupData: CreateGroupRequest  = {
        name: isActiveDirectory && formDataAdGroup ? formDataAdGroup?.displayName : formDataName,
        locationIds: formDataLocationIds || [],
      };

      if (isActiveAdGroup) {
        groupData.adGroupId = formDataAdGroup?.id;
      }

      dispatch(createGroup(groupData));
    }
    if (isEditState && group) {
      const groupData: CreateGroupRequest  = {
        name: isActiveDirectory && formDataAdGroup ? (formDataName || formDataAdGroup?.displayName) : formDataName,
        locationIds: formDataLocationIds,
        adGroupId: formDataAdGroup?.id,
      };

      dispatch(editGroup({ group: groupData, groupId: group.id }));
    }
    onCancelAction();
  };

  return (
    <Box display="flex" justifyContent="end">
      <Button
        aria-label={t`cancel group edition`}
        name={t`cancel group edition`}
        onClick={() => onCancelAction()}
        size="sm"
        type="clear"
      >
        <Trans>Cancel</Trans>
      </Button>

      <Button
        aria-label={t`confirm group edition`}
        name={t`confirm group edition`}
        onClick={() => onGroupFormSubmit()}
        size="sm"
      >
        {actionTypeName} group
      </Button>
    </Box>
  );
}
