export const parseEmoji = (code?: string): string | undefined => {
  if (!code) {
    return undefined;
  }

  try {
    return String.fromCodePoint(Number.parseInt(code, 16));
  } catch {
    return undefined;
  }
};
