import { ActionPayload, BaseErrorResponse, BaseResponse } from 'store/_legacy/Models/ReduxModels';
import {  
  CustomRequest,
  GetRoomsRequest,
  GetRoomsResponse,
  Room,  
  SetRoomDataRequest,
} from './models';
import { FloorMapObject } from "../../../../Admin/Store/floorMapDuck/models";
import { DeskStatus, DeskStatusEnum } from "../../../../Admin/Store/floorMapDuck/desk/models";

export const GET_ROOMS = 'GET_ROOMS';

export const GET_ROOMS_SUCCESS = 'GET_ROOMS_SUCCESS';

export const GET_ROOMS_FAIL = 'GET_ROOMS_FAIL';

const SET_ROOMS_DATA = 'SET_ROOMS_DATA';

export interface GetRooms {
  type: typeof GET_ROOMS;
  payload: ActionPayload<GetRoomsRequest>;
}

interface GetRoomSuccess {
  type: typeof GET_ROOMS_SUCCESS;
  payload: BaseResponse<GetRoomsResponse>;
}

interface GetRoomsFail {
  type: typeof GET_ROOMS_FAIL;
  payload: BaseErrorResponse;
}

interface SetRooms {
  type: typeof SET_ROOMS_DATA;
  payload: SetRoomDataRequest
}

type Actions = SetRooms
  | GetRooms
  | GetRoomSuccess
  | GetRoomsFail;

export interface State {
  error: string;
  loading: boolean;
  rooms: Room[];  
}

const initialState: State = {
  error: '',
  loading: false,
  rooms: [],  
};

export default function reducer(state = initialState, action: Actions): State {  
  switch (action.type) {
    case GET_ROOMS: {      
      return {
        ...state,
        error: '',
        loading: true,
      };
    }      
    case GET_ROOMS_SUCCESS: {            
      const rooms = action.payload.data.result.data.items;

      return {
        ...state,
        error: '',
        loading: false,
        rooms,
      };
    }
    case GET_ROOMS_FAIL: {      
      return {
        ...state,
        error: '',
        loading: false,
      };
    }
    case SET_ROOMS_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
}

export function getRooms({  
  locationId,
  floorId,
  custom = [],     
}: {  
  floorId: string,
  locationId: string,  
  custom: CustomRequest[]
}): GetRooms {
  let url = `/api/v2/locations/${locationId}/floors/${floorId}/rooms?`;

  if (custom) {
    custom.map((custom) => {
      url += `&custom[${custom.key}]=${encodeURIComponent(custom.value)}`;
    });    
  }

  return {
    type: GET_ROOMS,
    payload: {
      request: {
        method: 'GET',
        url,
      },
    },
  };
}

export function getRoomById(state: State, roomId: string): Room | undefined {
  const { rooms } = state;

  return rooms.find((room) => room.id === roomId);
}

export function getMeetingsDraw(meetings: { [meetingId: number]: FloorMapObject; }): FloorMapObject[] {
  const meetingStatusToHide: DeskStatus[] = [DeskStatusEnum.Unavailable];
  // Transform the objects with desks and sections into arrays
  return Object.keys(meetings)
    .map(key => meetings[parseInt(key)])
    .filter(desk => !meetingStatusToHide.includes(desk.status));
}
