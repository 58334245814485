import { getThemes } from 'theme/_legacy/getThemes';
import { Language, Themes } from './models';
import { ThemeBlue } from 'theme/_legacy/ThemeBlue';
import { ThemeColors } from 'theme/_legacy/models';
import { CompanyAccount } from 'store/_legacy/Services/AuthConfig';
import {domainSchemes} from "./settingData";

const SET_LANGUAGE = 'SET_LANGUAGE';
const SET_THEME = 'SET_THEME';
const SET_COMPANY_ACCOUNT = 'SET_COMPANY_ACCOUNT';
const SET_CALENDAR_TRANSLATIONS = 'SET_CALENDAR_TRANSLATIONS';

interface SetLanguage {
  type: typeof SET_LANGUAGE;
  payload: Language;
}

interface SetTheme {
  type: typeof SET_THEME;
  payload: Themes;
}
interface SetCompanyAccount {
  type: typeof SET_COMPANY_ACCOUNT;
  payload: CompanyAccount;
}
interface SetCalendarTranslations {
  type: typeof SET_CALENDAR_TRANSLATIONS;
  payload: Locale;
}

export interface State {
  language: Language;
  theme: ThemeColors;
  themeName: Themes;
  companyAccount: CompanyAccount;
  calendarTranslations?: Locale | undefined; // data is used for "locale" param for "react-date-range"
}

const initialState: State = {
  language: {
    name: 'English',
    value: 'en',
  },
  theme: ThemeBlue,
  themeName: 'ogBlue',
  companyAccount: 'MBWW/Kinesso',
  calendarTranslations: undefined,
};

type Actions = SetCompanyAccount | SetLanguage | SetTheme | SetCalendarTranslations;

export default function reducer(state = initialState, action: Actions | null): State {
  switch (action?.type) {
    case SET_LANGUAGE: {
      return {
        ...state,
        language: action.payload,
      };
    }

    case SET_THEME: {
      const theme = action.payload;

      const themes = getThemes(theme);

      return {
        ...state,
        theme: themes.styles,
        themeName: theme,
      };
    }

    case SET_CALENDAR_TRANSLATIONS: {
      return {
        ...state,
        calendarTranslations: action.payload,
      };
    }

    case SET_COMPANY_ACCOUNT: {
      return {
        ...state,
        companyAccount: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}

// helper functions
export function getDomainMatch(domains: string[], email: string): boolean {
  return domains.some(domain => { // return true if email matching one of domain list | case-insensitive
    const rgxp = new RegExp(domain, "i");
    return email.match(rgxp);
  });
}

// Actions
export function setLanguage(language: Language): SetLanguage {
  return {
    type: SET_LANGUAGE,
    payload: language,
  };
}

export function setTheme(theme: Themes): SetTheme {
  return {
    type: SET_THEME,
    payload: theme,
  };
}

export function setCalendarTranslations(calendarTranslations: Locale): SetCalendarTranslations {
  return {
    type: SET_CALENDAR_TRANSLATIONS,
    payload: calendarTranslations,
  };
}

// Selectors
export function selectThemeFromEmail(email: string): Themes {
  const { ogBlue, ipg, green } = domainSchemes;

  if (getDomainMatch(ogBlue, email)) {
    return 'ogBlue';
  }

  if (getDomainMatch(ipg, email)) {
    return 'ipg';
  }

  if (getDomainMatch(green, email)) {
    return 'green';
  }

  return 'ipg';
}
