import React, { useState } from "react";
import { NewsFeedItemCommentProps } from "./types";
import { Box, Button, FilledInput, IconButton, Typography, useTheme } from "@mui/material";
import { formatDistance, parseEmoji } from "utils";
import { t } from "@lingui/macro";
import { useCreatePostCommentReplyMutation, useDeletePostCommentByIdMutation, useDeletePostCommentReactionByIdMutation, useGetMeQuery, useSetPostCommentReactionMutation, useUpdatePostCommentByIdMutation } from "store";
import { useToast } from "../toast-provider";
import { AddReactionOutlined, ChatOutlined, EditOutlined } from "@mui/icons-material";
import { TogetherDelete } from "../icons";
import { ConfirmationDialog } from "../dialog";
import { LoadingButton } from "@mui/lab";
import { EmojiPopover, EmojiPopoverProps } from "components/popover";

export const NewsFeedItemComment: React.FC<NewsFeedItemCommentProps> = (props) => {
  const { postId, comment, mb, disableCommenting, depth = 1 } = props;
  const { palette } =  useTheme();
  const toast = useToast();
  const [body, setBody] = useState(comment.body);
  const [reply, setReply] = useState("");
  const [willEdit, setWillEdit] = useState(false);
  const [willDelete, setWillDelete] = useState(false);
  const [willReply, setWillReply] = useState(false);
  const [emojiPopoverAnchorEl, setEmojiPopoverAnchorEl] = useState<HTMLButtonElement | null>(null);
  const getMeQuery = useGetMeQuery();
  const [deletePostCommentById] = useDeletePostCommentByIdMutation();
  const [updatePostCommentById, { isLoading: isUpdating }] = useUpdatePostCommentByIdMutation();
  const [createPostCommentReply, { isLoading: isReplying }] = useCreatePostCommentReplyMutation();
  const [setPostCommentReaction, { isLoading: isSettingReaction }] = useSetPostCommentReactionMutation();
  const [deletePostCommentReaction, { isLoading: isDeletingReaction }] = useDeletePostCommentReactionByIdMutation();

  const handleUpdateClick = () => {
    if (!body.length) {
      return;
    }

    void (async () => {
      const response = await updatePostCommentById({ body, commentId: comment.id });

      if ("error" in response) {
        toast.showToast({ severity: "error", message: t`Failed to update comment, please try again.` });
      } else {
        toast.showToast({ severity: "success", message: t`Comment was updated.` });
      }

      setWillEdit(false);
    })();
  };

  const handlePostClick = () => {
    if (!reply.length) {
      return;
    }

    void (async () => {
      const response = await createPostCommentReply({ postId, commentId: comment.id, body: reply });

      if ("error" in response) {
        toast.showToast({ severity: "error", message: t`Failed to post reply, please try again.` });
      } else {
        toast.showToast({ severity: "success", message: t`Reply was posted.` });
        setReply("");
        setWillReply(false);
      }
    })();
  };

  const deleteComment = async (commentId: string) => {
    const response = await deletePostCommentById(commentId);

    if ("error" in response) {
      toast.showToast({ severity: "error", message: t`Failed to delete comment, please try again.` });
    } else {
      toast.showToast({ severity: "success", message: t`Comment was deleted.` });
    }
  };

  const myReaction = comment.$extra?.myReaction;

  const handleEmojiPopoverChange: EmojiPopoverProps["onChange"] = (_, code) => {
    setEmojiPopoverAnchorEl(null);

    if (isSettingReaction || isDeletingReaction) {
      return;
    }

    void (async () => {
      const response = myReaction?.code === code
        ? await deletePostCommentReaction({ postId, commentId: comment.id, commentReactionId: myReaction?.id })
        : await setPostCommentReaction({ postId, commentId: comment.id, code });

      if ("error" in response) {
        toast.showToast({ severity: "error", message: t`Failed to set reaction, please try again` });
      }
    })();
  };

  const { data: getMeResponse } = getMeQuery;
  const { data: user } = getMeResponse?.result || {};

  return (
    <>
      <Box mb={mb}>
        <Box alignItems="flex-end" display="flex" justifyContent="space-between" mb={1}>
          <Box display="flex" gap={1}>
            <Typography fontSize={14} fontWeight={600}>{comment.author?.name}</Typography>
            <Typography color={palette.grey[700]} fontSize={14}>{formatDistance(new Date(comment.createdAt))}</Typography>
          </Box>
          {comment.author && comment.author.id === user?.id ? (
            <Box display="flex" gap={1}>
              <IconButton onClick={() => setWillEdit(true)} size="small" sx={{ p: 0 }}><EditOutlined color="primary" fontSize="small" /></IconButton>
              <IconButton onClick={() => setWillDelete(true)} size="small" sx={{ p: 0 }}>
                <Box alignItems="center" display="flex" height={20} justifyContent="center" width={20}>
                  <TogetherDelete fill={palette.primary.main} sx={{ width: 15, height: 15 }} />
                </Box>
              </IconButton>
            </Box>
          ) : undefined}
        </Box>
        {willEdit ? (
          <>
            <FilledInput fullWidth multiline onChange={(event) => setBody(event.currentTarget.value)} sx={{ bgcolor: "#fff", p: 0 }} value={body} />
            <Box display="flex" gap={2} justifyContent="flex-end" pt={1}>
              <Button onClick={() => setWillEdit(false)} size="small">{t`Cancel`}</Button>
              <LoadingButton disabled={!body.length} loading={isUpdating} onClick={handleUpdateClick} size="small" variant="contained">
                {t`Update`}
              </LoadingButton>
            </Box>
          </>
        ) : (
          <Box display="flex" gap={1}>
            <Box borderLeft={`1px solid ${palette.grey[300]}`} />
            <Box alignItems="stretch" display="flex" flex={1} flexDirection="column">
              <Typography color={palette.grey[700]} fontSize={14} mb={1} ml={1}>{comment.body}</Typography>
              {comment.$extra?.reactionsCount ? (
                <Box alignItems="center" display="flex" gap={1} mb={1} ml={1}>
                  {comment?.$extra?.reactionsCodes?.map((code) => <Typography component="span" fontSize={14} key={code}>{parseEmoji(code)}</Typography>)}
                  <Typography color={palette.grey[700]} fontSize={14}>{comment.$extra.reactionsCount}</Typography>
                </Box>
              ) : undefined}
              <Box display="flex" gap={2}>
                <Button
                  onClick={(event) => setEmojiPopoverAnchorEl(event.currentTarget)}
                  size="small"
                  startIcon={myReaction?.code ? parseEmoji(myReaction.code) : <AddReactionOutlined />}
                  sx={{ p: 0 }}
                >
                  {t`Like`}
                </Button>
                {depth < 3 && !disableCommenting ? (
                  <Button onClick={() => setWillReply(!willReply)} size="small" startIcon={<ChatOutlined />} sx={{ p: 0 }}>{t`Reply`}</Button>
                ) : undefined}
              </Box>
              {comment.children?.length ? (
                <Box pl={1} pt={2}>
                  {comment.children.map((comment, index, children) => (
                    <NewsFeedItemComment
                      comment={comment}
                      depth={depth + 1}
                      disableCommenting={disableCommenting}
                      key={comment.id}
                      mb={index + 1 === children.length ? 0 : 2}
                      postId={postId}
                    />
                  ))}
                </Box>
              ) : undefined}
              {willReply ? (
                <Box display="flex" gap={2} pt={1}>
                  <FilledInput
                    fullWidth
                    onChange={(event) => setReply(event.currentTarget.value)}
                    placeholder={t`Add a reply...`}
                    size="small"
                    sx={{ bgcolor: "#fff" }}
                    value={reply}
                  />
                  <LoadingButton disabled={!reply.length} loading={isReplying} onClick={handlePostClick} variant="contained">{t`Post`}</LoadingButton>
                </Box>
              ) : undefined}
            </Box>
          </Box>
        )}
      </Box>
      <ConfirmationDialog
        description={t`Are you sure that you want to delete your comment?`}
        onClose={() => setWillDelete(false)}
        onConfirm={() => void deleteComment(comment.id)}
        open={willDelete}
        title={t`Delete comment`}
      />
      <EmojiPopover
        anchorEl={emojiPopoverAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        onChange={handleEmojiPopoverChange}
        onClose={() => setEmojiPopoverAnchorEl(null)}
        open={!!emojiPopoverAnchorEl}
        transformOrigin={{ vertical: "bottom", horizontal: "left" }}
        value={myReaction?.code}
      />
    </>
  );
};
