import { t } from "@lingui/macro";
import { ReservationServiceOptionType } from "store";

export const resolveReservationServiceOptionTypeLabel = (type?: ReservationServiceOptionType): string | undefined => {
  switch (type) {
    case ReservationServiceOptionType.BEVERAGE:
      return t`beverage`;
    case ReservationServiceOptionType.BREAKFAST:
      return t`breakfast`;
    case ReservationServiceOptionType.LUNCH:
      return t`lunch`;
    case ReservationServiceOptionType.SNACK:
      return t`snack`;
    default:
      return undefined;
  }
};
