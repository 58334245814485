import React, { useState } from "react";
import { UsersListSelectProps } from "./types";
import { t } from "@lingui/macro";
import { Box, Button, Dialog, IconButton, List, ListItem, Typography, useTheme } from "@mui/material";
import { ListHeader, ListHeaderLabel } from "components/list";
import { TogetherDelete } from "components/icons";
import { User } from "store";
import { UsersSelect } from "./users-select";

export const UsersListSelect: React.FC<UsersListSelectProps> = (props) => {
  const { value, labels, readOnly, onChange } = props;
  const { palette } = useTheme();
  const [addDialogIsOpen, setAddDialogIsOpen] = useState(false);
  const [usersToAdd, setUsersToAdd] = useState<User[]>([]);

  const handleCancelClick = () => {
    setUsersToAdd([]);
    setAddDialogIsOpen(false);
  };

  const handleAddUsersClick = () => {
    const newValue = [...(value || [])];

    for (const user of usersToAdd) {
      if (!newValue.some(({ id }) => user.id === id)) {
        newValue.push(user);
      }
    }

    setUsersToAdd([]);
    setAddDialogIsOpen(false);
    onChange?.(newValue);
  };

  const { title = t`Users`, disclaimer } = labels || {};

  return (
    <>
      <Box>
        <Box alignItems="baseline" display="flex" justifyContent="space-between" mb={1}>
          <Box alignItems="baseline" display="flex" gap={1}>
            <Typography fontSize={14} fontWeight={600}>{title}</Typography>
            {disclaimer ? <Typography color={palette.grey[700]} fontSize={13}>{disclaimer}</Typography> : undefined}
          </Box>
          {!readOnly ? <Button onClick={() => setAddDialogIsOpen(true)}>{t`Add Users`}</Button> : undefined}
        </Box>
        <ListHeader>
          <ListHeaderLabel sx={{ width: 420 }} >{t`Name`}</ListHeaderLabel>
          <ListHeaderLabel sx={{ flex: 1 }}>{t`Email`}</ListHeaderLabel>
          {!readOnly ? <Box component="span" width={32} /> : undefined}
        </ListHeader>
        <List disablePadding>
          {value?.length ? (
            value.map(({ id, name, email }) => (
              <ListItem disablePadding key={id} sx={{ p: 1, gap: 1, borderBottom: "1px solid rgba(0, 0, 0, 0.05)" }}>
                <Typography fontSize={14} noWrap width={420}>{name}</Typography>
                <Typography flex={1} fontSize={14} noWrap>{email}</Typography>
                {!readOnly ? (
                  <IconButton onClick={() => onChange?.(value?.filter((user) => user.id !== id))}>
                    <TogetherDelete sx={{ fill: palette.primary.main, width: 16, height: 16 }} />
                  </IconButton>
                ) : undefined}
              </ListItem>
            ))  
          ) : (
            <Typography color={palette.grey[700]} fontSize={14} my={3} textAlign="center">{t`No user selected`}</Typography>
          )}
        </List>
      </Box>
      <Dialog onClose={() => setAddDialogIsOpen(false)} open={addDialogIsOpen}>
        <Box display="flex" flexDirection="column" gap={2} p={2} width={520}>
          <Typography fontWeight={600}>{t`Add users`}</Typography>
          <UsersSelect onChange={setUsersToAdd} value={usersToAdd} />
          <Box display="flex" gap={2} justifyContent="flex-end">
            <Button onClick={handleCancelClick}>{t`Cancel`}</Button>
            <Button disabled={!usersToAdd.length} onClick={handleAddUsersClick} variant="contained">{t`Add Users`}</Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};
