import Text from "components/_legacy/Text";

interface Props {
  searchText: string;
}

export default function AllSelectedText(props: Props) {
  const { searchText } = props;

  return (
    <Text align="center" color="lightGray" size="md">
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      All users selected by search result "{searchText}". Please use search to change the search criteria.
    </Text>
  );
}
