import {useTypedSelector} from "store/_legacy/Redux/store";
import {Trans, t} from "@lingui/macro";
import format from "date-fns/format";
import isSameDay from "date-fns/isSameDay";
import Box from "components/_legacy/Box";
import Text from "components/_legacy/Text";
import Button from "components/_legacy/Button";

interface DeskBookingDetailsProps {
  onCancel: Function;
  disabledCancel: boolean;
}

export default function DeskBookingDetails(props: DeskBookingDetailsProps) {
  const { config, adminReservations } = useTypedSelector(state => state);
  const { filters } = adminReservations;
  const { onCancel, disabledCancel } = props;

  const dateFrom = filters.selectedStartDate;
  const dateTo = filters.selectedEndDate;
  
  let dateFromDay;
  let dateToDay;
  let dateFromMonth;
  let dateToMonth;

  if (dateFrom && dateTo) {
    dateFromDay = format(new Date(dateFrom), 'dd');
    dateToDay = format(new Date(dateTo), 'dd');
    dateFromMonth = format(new Date(dateFrom), 'LLL');
    dateToMonth = format(new Date(dateTo), 'LLL');
  }

  const datesAreSameDay = dateFrom && dateTo ? isSameDay(new Date(dateFrom), new Date(dateTo)) : true;
  
  return (
    <Box
      alignItems="center"
      backgroundColor={config.theme.primary}
      borderRadius={20}
      display="flex"
      justifyContent="between"
      padding={22}
      style={{
        backgroundColor: config.theme.primary,
      }}
    >
      <Box>
        <Box>
          <Text color="white" size="md" weight="semi-bold">
            <Trans>
              Date and time
            </Trans>

            <br/>

            <>
              {dateFromMonth} {dateFromDay}{!datesAreSameDay ? ` - ${dateToMonth} ${dateToDay}` : ''}
              &nbsp;9am-6pm
            </>
          </Text>
        </Box>
      </Box>

      <Box>
        <Button
          aria-label={t`Cancel Booking`}
          disabled={disabledCancel}
          name={t`Cancel Booking`}
          onClick={() => onCancel()}
          size="sm"
          type="white"
        >
          <Trans>Cancel Booking</Trans>
        </Button>
      </Box>
    </Box>
  );
}
