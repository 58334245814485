import {useHistory} from "react-router";
import {useState} from "react";
import {useTypedSelector} from "store/_legacy/Redux/store";
import {Trans} from "@lingui/macro";
import moment from "moment";
import styles from "../styles.module.scss";

import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import ButtonBase from "@material-ui/core/ButtonBase";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import Box from "components/_legacy/Box";
import OverflowToolTip from "components/_legacy/OverflowTooltip";
import TableSubmenuButton from "components/_legacy/Buttons/TableSubmenuButton";
import Text from "components/_legacy/Text";
import CreateEditGroupModal from "../../GroupForms/CreateEditGroupModal";
import GroupDeleteModal from "../../GroupForms/DeleteGroupModal";
import LocationsGroupModal from "../../GroupForms/LocationsGroupModal";
import Name from "./Name";
import {IGroup} from "../../../../Store/groups/models";
import { GroupCreateEditFormActionStateEnum } from "../../../../Store/groups/forms/createEditForm/models";

interface Props {
  group: IGroup;
}

export default function GroupBodyItem({ group }: Props) {
  const history = useHistory();
  const [isOpenGroupForm, setIsOpenGroupForm] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const { config } = useTypedSelector(state => state);

  // navigate to group
  const onNavigateToGroup = (event: any, groupId: string) => {
    // navigate only on clicking table row (prevent navigate by close edit/delete modal)
    if (!event.target?.classList?.contains('popup-overlay')) {
      history.push(`/admin/groups/${groupId}`);
    }
  };

  // edit from modal
  const onOpenGroupForm = () => {
    setIsOpenGroupForm(o => !o);
  };

  const onCloseGroupForm = () => {
    setIsOpenGroupForm(false);
  };

  // delete modal
  const onOpenDeleteModal = () => {
    setIsOpenDeleteModal(o => !o);
  };

  const onCloseDeleteModal = () => {
    setIsOpenDeleteModal(false);
  };

  const createdGroupDate = moment(group.createdAt).format('DD/MM/YYYY');

  return (
    <TableRow
      classes={{root: `${styles.tableRow} ${styles.pointerRow}`}}
      hover
      key={group.id}
      onClick={(event) => onNavigateToGroup(event, group.id)}
      role="row"
      tabIndex={-1}
    >
      <TableCell classes={{ root: styles.tableCell }}>
        <Name group={group} />
      </TableCell>
      <TableCell classes={{ root: styles.tableCell }}>
        <LocationsGroupModal group={group} />
      </TableCell>
      <TableCell classes={{ root: styles.tableCell }}>
        <OverflowToolTip text={group.members} />
      </TableCell>
      <TableCell classes={{ root: styles.tableCell }}>
        <OverflowToolTip text={createdGroupDate} />
      </TableCell>

      {/* location editing only for super admin */}
      <TableCell align="right" classes={{ root: styles.tableCell }}>
        <TableSubmenuButton
          iconButtonName={"group-item-options"}
          iconButtonStyle={{padding: 0, width: '30px', height: '30px'}}
        >
          {
            !isOpenGroupForm && !isOpenDeleteModal ?
              <Box borderStyle="shadow" className="popover-content" dataTestId="group-item-options-popup">
                <ButtonBase className="popover-item-button" onClick={onOpenGroupForm}>
                  <CreateOutlinedIcon style={{color: config.theme.primary, fontSize: 20}}/>

                  <Box marginStart={5}>
                    <Text color="blue" size="md">
                      <Trans>Edit Group</Trans>
                    </Text>
                  </Box>
                </ButtonBase>

                <ButtonBase
                  className="popover-item-button"
                  disabled={!!group.adGroup?.id}
                  onClick={onOpenDeleteModal}
                  style={{opacity: group.adGroup?.id ? '0.5' : 1}}
                >
                  <DeleteOutlineIcon style={{color: config.theme.primary, fontSize: 20}}/>

                  <Box marginStart={5}>
                    <Text color="blue" size="md">
                      <Trans>Delete Group</Trans>
                    </Text>
                  </Box>
                </ButtonBase>
              </Box> : null
          }
        </TableSubmenuButton>

        <CreateEditGroupModal
          actionState={GroupCreateEditFormActionStateEnum.edit}
          group={group}
          onCancelAction={onCloseGroupForm}
          open={isOpenGroupForm}
        />
        <GroupDeleteModal
          group={group}
          onCancelAction={onCloseDeleteModal}
          open={isOpenDeleteModal}
        />
      </TableCell>
    </TableRow>
  );
}
