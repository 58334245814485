import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import subDays from 'date-fns/subDays';
import subHours from 'date-fns/subHours';
import { addDeskToSavedListSuccess, getSavedDesksResponseSuccess } from 'App/Store/Desk/deskDuck/fixtures';
import { addUserDeviceTokenResponse, getProfileSuccess } from 'App/Store/Users/profileDuck/fixtures';
import { checkinResponseSuccess } from 'App/Store/Bookings/checkinDuck/fixtures';
import { createCustomBookingSuccess, getBookingsSuccess } from 'App/Store/Bookings/bookingDuck/fixtures';
import { CreateGlobalNotificationRequest, DeleteGlobalNotificationRequest } from 'Admin/Store/globalNotifications/models';
import {
  createGlobalNotificationResponse,
  deleteGlobalNotificationResponse,
  getGlobalNotificationsResponse,
  sendGlobalNotificationResponse,
  updateGlobalNotificationResponse,
} from 'Admin/Store/globalNotifications/fixtures';
import { createOrUpdateEmailTemplateSuccess, getEmailTemplatesSuccess } from 'Admin/Store/emailTemplatesDuck/fixtures';
import { getAvailableDesksSuccess } from 'App/Store/Bookings/availableDesksDuck/fixtures';
import { getFloorSchemaSuccess, saveMapFileSuccess } from 'Admin/Store/floorMapApiRequestsDuck/fixtures';
import { getLocationResourcesSuccess } from 'App/Store/Resources/locationsResourcesDuck/fixtures';
import { getPushNotificationsResponse, updatePushNotificationStatusResponse } from 'App/Store/Notifications/fixtures';
import { handlers as handlersLocationDashboard } from 'Admin/Store/locationDashboardDuck/fixtures';
import { handlers as handlersParking } from 'Admin/Store/parking/fixtures';
import { handlers as handlersLogin } from 'App/Store/Auth/loginDuck/fixtures';
import { rest } from 'msw';
import { SendNotificationRequest } from 'Admin/Store/notifications/models';
import {
  getQuestionQuestionnaireSuccessEmpty,
  getQuestionnaireBookingByIdSuccess,
  getQuestionnaireBookingsSuccess,
} from 'App/Store/Questionnaire/fixtures';
import {
  deleteFloorSuccess,
  deleteLocationSuccess,
  editLocationSuccess,
  getFloorsAdminSuccess,
  getFloorsSuccess,
  getLocationsSuccess, getUserLocationsSuccess,
} from 'App/Store/Locations/locationsDuck/fixtures';
import {
  getCertificatesByUserResponse,
  uploadCovidCertificateResponse,
} from '../App/Store/CovidSertificate/fixtures';
import { approveOrDenyUserDocumentSuccess } from '../Admin/Store/userManagement/fixtures';
import { getReservationsResponse } from 'App/Store/Meetings/meetingReservations/fixtures';

const api = process.env.REACT_APP_API_URL;

export const handlers = [
  ...handlersLocationDashboard,
  ...handlersLogin,
  ...handlersParking,

  rest.delete(`${api}/api/desks/:deskId/users/:userId/saved`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(addDeskToSavedListSuccess),
    );
  }),
  rest.delete(`${api}/api/locations/:locationId`, (req, res, ctx) => {
    deleteLocationSuccess.result.data = req.params.locationId;

    return res(
      ctx.status(200),
      ctx.json(deleteLocationSuccess),
    );
  }),
  rest.delete(`${api}/api/locations/:locationId/floor`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(deleteFloorSuccess),
    );
  }),
  rest.delete(`${api}/api/notifications-history/:notificationId`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(updatePushNotificationStatusResponse),
    );
  }),
  rest.delete<DeleteGlobalNotificationRequest, any, { notificationId: string }>(`${api}/api/:notificationId/notifications`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        ...deleteGlobalNotificationResponse,
        result: {
          data: {
            ...deleteGlobalNotificationResponse.result.data,
            id: req.params.notificationId,
          },
        },
      }),
    );
  }),
  rest.get(`${api}/api/bookings`, (req, res, ctx) => {
    let today = new Date();
    today = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59 + today.getTimezoneOffset(), 59);
    const success = getBookingsSuccess;

    const todayOneHourInPast = subHours(today, 1);

    success.result.data.bookings[0].dateFrom = format(today, 'yyyy-MM-dd');
    success.result.data.bookings[0].dateTo = format(today, 'yyyy-MM-dd');
    success.result.data.bookings[0].timeFrom = format(today, 'hh:mm aa');
    success.result.data.bookings[0].timeTo = format(today, 'hh:mm aa');

    success.result.data.bookings[1].dateFrom = format(todayOneHourInPast, 'yyyy-MM-dd');
    success.result.data.bookings[1].dateTo = format(todayOneHourInPast, 'yyyy-MM-dd');
    success.result.data.bookings[1].timeFrom = format(todayOneHourInPast, 'hh:mm aa');
    success.result.data.bookings[1].timeTo = format(todayOneHourInPast, 'hh:mm aa');

    success.result.data.bookings[2].dateFrom = format(addDays(today, 2), 'yyyy-MM-dd');
    success.result.data.bookings[2].dateTo = format(addDays(today, 2), 'yyyy-MM-dd');
    success.result.data.bookings[2].timeFrom = format(today, 'hh:mm aa');
    success.result.data.bookings[2].timeTo = format(today, 'hh:mm aa');

    success.result.data.bookings[3].dateFrom = format(addDays(today, 3), 'yyyy-MM-dd');
    success.result.data.bookings[3].dateTo = format(addDays(today, 3), 'yyyy-MM-dd');
    success.result.data.bookings[3].timeFrom = format(today, 'hh:mm aa');
    success.result.data.bookings[3].timeTo = format(today, 'hh:mm aa');

    success.result.data.bookings[4].dateFrom = format(subDays(today, 2), 'yyyy-MM-dd');
    success.result.data.bookings[4].dateTo = format(subDays(today, 2), 'yyyy-MM-dd');
    success.result.data.bookings[4].timeFrom = format(today, 'hh:mm aa');
    success.result.data.bookings[4].timeTo = format(today, 'hh:mm aa');

    success.result.data.bookings[5].dateFrom = format(subDays(today, 2), 'yyyy-MM-dd');
    success.result.data.bookings[5].dateTo = format(subDays(today, 2), 'yyyy-MM-dd');
    success.result.data.bookings[5].timeFrom = format(today, 'hh:mm aa');
    success.result.data.bookings[5].timeTo = format(today, 'hh:mm aa');

    success.result.data.bookings[6].dateFrom = format(today, 'yyyy-MM-dd');
    success.result.data.bookings[6].dateTo = format(today, 'yyyy-MM-dd');
    success.result.data.bookings[6].timeFrom = format(today, 'hh:mm aa');
    success.result.data.bookings[6].timeTo = format(today, 'hh:mm aa');

    success.result.data.bookings[7].dateFrom = format(today, 'yyyy-MM-dd');
    success.result.data.bookings[7].dateTo = format(today, 'yyyy-MM-dd');
    success.result.data.bookings[7].timeFrom = format(today, 'hh:mm aa');
    success.result.data.bookings[7].timeTo = format(today, 'hh:mm aa');

    return res(
      ctx.status(200),
      ctx.json(getBookingsSuccess),
    );
  }),
  rest.get(`${api}/api/bookings/qrcode/checking`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(checkinResponseSuccess),
    );
  }),
  rest.get(`${api}/api/bookings/questionnaire-bookings`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(getQuestionnaireBookingsSuccess),
    );
  }),
  rest.get(`${api}/api/bookings/:bookingId`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(getQuestionnaireBookingByIdSuccess),
    );
  }),
  rest.get(`${api}/api/bookings/:floorId/desks`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(getAvailableDesksSuccess),
    );
  }), rest.get(`${api}/api/covid-questions/:locationId/location-questions`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(getQuestionQuestionnaireSuccessEmpty),
    );
  }),
  rest.get(`${api}/api/desks/saved`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(getSavedDesksResponseSuccess),
    );
  }),
  rest.get(`${api}/api/notifications/general`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(getGlobalNotificationsResponse),
    );
  }),
  rest.get(`${api}/api/locations/admin`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(getLocationsSuccess),
    );
  }),
  rest.get(`${api}/api/v2/locations`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(getUserLocationsSuccess),
    );
  }),
  rest.get(`${api}/api/locations/:locationId/admin/floors`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(getFloorsAdminSuccess),
    );
  }),
  rest.get(`${api}/api/locations/:locationId/floors`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(getFloorsSuccess),
    );
  }),
  rest.get(`${api}/api/locations/:locationId/floor-schema`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(getFloorSchemaSuccess),
    );
  }),
  rest.get(`${api}/api/locations/:locationId/notification-templates`, (req, res, ctx) => {
    const response = { ...getEmailTemplatesSuccess };

    response.result.data[0].locationId = req.params.locationId;

    return res(
      ctx.status(200),
      ctx.json(response),
    );
  }),
  rest.get(`${api}/api/resources/locations/:locationId/location-resources`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(getLocationResourcesSuccess),
    );
  }),
  rest.get(`${api}/api/users/profile`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(getProfileSuccess),
    );
  }),
  rest.get(`${api}/api/users/:userId/documents`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(getCertificatesByUserResponse),
    );
  }),
  rest.get(`${api}/api/users/:userId/notifications-history`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(getPushNotificationsResponse),
    );
  }),
  rest.get(`${api}/api/users/me/reservations`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(getReservationsResponse),
    );
  }),
  rest.post(`${api}/api/bookings/:locationId/bookingType/:bookingType`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(createCustomBookingSuccess),
    );
  }),
  rest.post(`${api}/api/desks/saved`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(addDeskToSavedListSuccess),
    );
  }),
  rest.post<CreateGlobalNotificationRequest>(`${api}/api/notifications/general`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        ...createGlobalNotificationResponse,
        result: {
          data: {
            ...createGlobalNotificationResponse.result.data,
            ...req.body,
          },
        },
      }),
    );
  }),
  rest.post(`${api}/api/locations/:locationId/floor`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(saveMapFileSuccess),
    );
  }),
  rest.post<SendNotificationRequest, any, { notificationId: string }>(`${api}/api/:notificationId/send-notification/general`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        ...sendGlobalNotificationResponse,
        result: {
          data: {
            id: req.params.notificationId,
          },
        },
      }),
    );
  }),
  rest.post(`${api}/api/users`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(addUserDeviceTokenResponse),
    );
  }),
  rest.post(`${api}/api/users/upload-certificate`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(uploadCovidCertificateResponse),
    );
  }),
  rest.put(`${api}/api/bookings/:bookingId/hasPassedQuestionnaire/:hasPassedQuestionnaire`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({ status: 200 }),
    );
  }),
  rest.put<any>(`${api}/api/locations/:locationId`, (req, res, ctx) => {
    editLocationSuccess.result.data.id = req.params.locationId;
    editLocationSuccess.result.data.locationName = req.body?.locationName;
    editLocationSuccess.result.data.locationAddress = req.body?.locationAddress;
    editLocationSuccess.result.data.timezone = req.body?.timezone;

    return res(
      ctx.status(200),
      ctx.json(editLocationSuccess),
    );
  }),
  rest.put(`${api}/api/locations/:floorId/floor-schema`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(getFloorSchemaSuccess),
    );
  }),
  rest.put<any>(`${api}/api/locations/:locationId/notification-templates`, (req, res, ctx) => {
    const success = { ...createOrUpdateEmailTemplateSuccess };

    success.result.data.emailNotificationText = req.body.emailNotificationText;

    return res(
      ctx.status(200),
      ctx.json(success),
    );
  }),
  rest.put<CreateGlobalNotificationRequest, any, { notificationId: string }>(`${api}/api/:notificationId/notifications`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        ...updateGlobalNotificationResponse,
        result: {
          data: {
            ...updateGlobalNotificationResponse.result.data,
            id: req.params.notificationId,
            ...req.body,
          },
        },
      }),
    );
  }),
  rest.put(`${api}/api/users/:documentId/document/:userId/approve`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(approveOrDenyUserDocumentSuccess),
    );
  }),
];
