import Box from 'components/_legacy/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import clsx from 'clsx';
import Skeleton from '@material-ui/lab/Skeleton';
import styles from './styles.module.scss';
import Text from 'components/_legacy/Text';
import { Floor as FloorModel } from 'App/Store/Locations/locationsDuck/models';
import { Trans, t } from '@lingui/macro';
import { useTypedSelector } from 'store/_legacy/Redux/store';
import { Link } from 'react-router-dom';
import Room from 'components/_legacy/Icons/Room';

interface FloorItemProps extends FloorModel {
  onClick: (floorId: string) => any;
  type?: string;
  selected?: boolean;
}

/**
 * Loading state for floors.
 * Renders a few placeholders.
 */
export function FloorItemLoading() {
  const item = (
    <Box borderRadius={14} height={90}>
      <Skeleton classes={{ root: styles.skeletonRoot }} height="100%" variant="rect" />
    </Box>
  );

  return (
    <Box display="grid" gap={15}>
      {item}
      {item}
      {item}
      {item}
      {item}
    </Box>
  );
}

/**
 * Renders the floor UI as a dumb component.
 */
export default function FloorItem({
  id,
  sitDesks: desks,
  floorName,
  extra,
  onClick,
  selected,
  type = "desk",
}: FloorItemProps) {
  const classes = clsx(styles.container, selected && styles.selected);
  const { config } = useTypedSelector(state => state);
  const isDisabled = desks === 0;
  const bookingPath = type === 'desk' ? 'create-new-booking' : 'create-new-booking-support';

  return (
    <Link onClick={() => onClick(id)} to={`/${bookingPath}/select-desk`}>
      <ButtonBase
        aria-label={t`FloorItem`}
        classes={{ root: `${styles.buttonBase} ${styles.floorItem}` }}
        disableRipple
        disabled={isDisabled}
        name={t`FloorItem`}
        style={{ opacity: isDisabled ? .5 : 1 }}
      >
        <Box className={classes}>
          <Box>
            <Text color={selected ? 'blue' : 'gray'} size="md" weight="semi-bold">
              {floorName}
            </Text>

            <Box
              alignItems="center"
              display="flex"
              left={-1}
              marginTop={11}
              position="relative"
            >
              <Box className={styles.itemContainer}>
                <svg height="16px" viewBox="0 0 16 16" width="16px">
                  <g fill="none" fillRule="evenodd" id="Booking" stroke="none" strokeWidth="1">
                    <g id="Createbooking2" transform="translate(-120.000000, -183.000000)">
                      <g id="Group" transform="translate(100.000000, 129.000000)">
                        <rect fill="transparent" height="90" id="Rectangle" rx="14" width="770" x="0" y="0"></rect>
                        <g id="labels/amenities" transform="translate(13.000000, 47.000000)">
                          <g transform="translate(0.000000, 3.000000)">
                            <rect fill="transparent" height="24" id="Rectangle" rx="8" width="97" x="0" y="0"></rect>
                            <g id="Group" transform="translate(7.000000, 4.000000)">
                              <g id="icons/sit" transform="translate(0.000000, 0.000000)"></g>
                              <rect fill={selected ? config.theme.primary : "#5E5E5E"} height="5" id="Rectangle" rx="1" width="2" x="7" y="10"></rect>
                              <path d="M10,13 C11.5976809,13 12.9036609,14.24892 12.9949073,15.8237272 L13,16 L11,16 C11,15.4871642 10.6139598,15.0644928 10.1166211,15.0067277 L10,15 L6,15 C5.48716416,15 5.06449284,15.3860402 5.00672773,15.8833789 L5,16 L3,16 C3,14.4023191 4.24891996,13.0963391 5.82372721,13.0050927 L6,13 L10,13 Z M10,0 C11.6568542,0 13,1.34314575 13,3 L13,3 L13.0004345,7.05009729 C14.1413382,7.28190033 15,8.29067455 15,9.5 C15,10.8807119 13.8807119,12 12.5,12 L12.5,12 L3.5,12 C2.11928813,12 1,10.8807119 1,9.5 C1,8.29031824 1.85916789,7.2813059 3.00057405,7.0498926 L3,3 C3,1.34314575 4.34314575,0 6,0 L6,0 Z M12.5,9 L3.5,9 C3.22385763,9 3,9.22385763 3,9.5 C3,9.77614237 3.22385763,10 3.5,10 L3.5,10 L12.5,10 C12.7761424,10 13,9.77614237 13,9.5 C13,9.22385763 12.7761424,9 12.5,9 L12.5,9 Z M10,2 L6,2 C5.44771525,2 5,2.44771525 5,3 L5,3 L5,7 L11,7 L11,3 C11,2.48716416 10.6139598,2.06449284 10.1166211,2.00672773 L10.1166211,2.00672773 L10,2 Z" fill={selected ? config.theme.primary : "#5E5E5E"} fillRule="nonzero" id="Combined-Shape"></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>

                <Text color={selected ? 'blue' : 'gray'} size="md">
                  <Trans>{desks} Desks</Trans>
                </Text>
              </Box>
            </Box>

            {extra && (
              <Box className={styles.itemContainer}>
                <Room />

                <Text color={selected? 'blue': 'gray'} size="md">
                  {extra.availableRooms} <Trans>Rooms</Trans>
                </Text>
              </Box>
            )}
          </Box>
        </Box>
      </ButtonBase>
    </Link>
  );
}
