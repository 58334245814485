import Box from 'components/_legacy/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import CheckboxCustom from 'components/_legacy/CheckBoxCustom';
import format from 'date-fns/format';
import OverflowToolTip from 'components/_legacy/OverflowTooltip';
import styles from '../../styles.module.scss';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Text from 'components/_legacy/Text';
import { BookingModelStatus } from 'App/Store/Bookings/bookingDuck/models';
import { cancelVisit, updateVisitMassActionsIds } from 'Admin/Store/visits';
import { getDocStatusText } from 'Admin/Pages/Reservations/ReservationListView/ListViewBodyItem';
import { Trans, t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'store/_legacy/Redux/store';
import { VisitModel } from 'Admin/Store/visits/models';
import UserAccommodation from "../../../UserManagement/AllUsersTable/AllUsersTableBody/UserAccommodation";
import TableSubmenuButton from "components/_legacy/Buttons/TableSubmenuButton";

interface Props {
  appointment: VisitModel;
  index: number;
}

function getAppointmentDate(appointment: VisitModel) {
  const startDate = new Date(appointment.dateFromUTC);
  const endDate = new Date(appointment.dateToUTC);

  return `${format(startDate, 'MMM dd, h:mm aa')}-${format(endDate, 'h:mm aa')}`;
}

function getStatusClass(status: BookingModelStatus) {
  switch (status) {
    case 'BOOKED':
      return styles.statusBooked;
    case 'CANCELED':
      return styles.statusCancelled;
    case 'PENDING':
      return styles.statusPending;
    case 'PAST':
      return styles.statusPassed;
    default:
      return styles.statusPending;
  }
}

function getStatusText(status: BookingModelStatus) {
  switch (status) {
    case 'BOOKED':
      return t`Booked`;
    case 'CANCELED':
      return t`Cancelled`; // fix spelling error from backend
    case 'PENDING':
      return t`Pending`;
    case 'PAST':
      return t`Past`;
    default:
      return t`Past`;
  }
}

export default function ListBodyItem(props: Props) {
  const { appointment, index } = props;
  const dispatch = useDispatch();
  const { adminVisits, config } = useTypedSelector(state => state);
  const { visitMassActionsIds } = adminVisits;

  const isMassActiveActive = adminVisits.visitMassActions;
  const isVisitChecked = visitMassActionsIds.includes(appointment.id);
  const checkedRowClass = isVisitChecked && isMassActiveActive ? styles.checkedRow : '';

  const visitDate = getAppointmentDate(appointment);
  const visitStatus = getStatusText(appointment.status);
  const visitStatusClass = getStatusClass(appointment.status);
  const visitDocStatus = getDocStatusText(appointment.user.userDocument);
  const visitCheckin = appointment.checking ? "Yes" : "No";

  const onCancel = () => {
    dispatch(cancelVisit({ appointmentId: appointment.id }));
  };

  const onCheckedChange = () => {
    dispatch(updateVisitMassActionsIds(appointment.id));
  };

  return (
    <TableRow
      className={checkedRowClass}
      classes={{ root: styles.tableRow }}
      hover
      key={index}
      tabIndex={-1}
    >
      <TableCell classes={{ root: styles.tableCell }}>
        <Box alignItems="center" display="flex">
          {
            isMassActiveActive ?
              <CheckboxCustom
                checked={isVisitChecked}
                name={appointment.id}
                onChange={onCheckedChange}
              /> : null
          }
          <OverflowToolTip isLocation text={appointment.location?.locationName} />
        </Box>
      </TableCell>
      <TableCell classes={{ root: styles.tableCell }}>
        <OverflowToolTip text={appointment.floor?.floorName} />
      </TableCell>
      <TableCell classes={{ root: styles.tableCell }}>
        <OverflowToolTip text={appointment.user.name} />
      </TableCell>
      <TableCell classes={{ root: styles.tableCell }}>
        <OverflowToolTip text={visitDate} />
      </TableCell>
      <TableCell classes={{ root: styles.tableCell }}>
        <OverflowToolTip text={appointment.parkingSpot ? t`${appointment.parkingSpot.floor ? `Floor ${appointment.parkingSpot.floor}, ` : ''}${appointment.parkingSpot.name}` : '-'} />
      </TableCell>
      <TableCell classes={{ root: styles.tableCell }}>
        <UserAccommodation
          approvedAccommodation={appointment.user?.approvedAccommodation}
          disabled
          userId={appointment.user?.id}
        />
      </TableCell>
      <TableCell classes={{ root: styles.tableCell }}>
        <span className={visitStatusClass}>
          <OverflowToolTip text={visitStatus} />
        </span>
      </TableCell>
      <TableCell classes={{ root: styles.tableCell }}>
        <OverflowToolTip text={visitCheckin} />
      </TableCell>
      <TableCell classes={{ root: styles.tableCell }}>
        <OverflowToolTip text={visitDocStatus} />
      </TableCell>
      <TableCell align="right" classes={{ root: styles.tableCell }}>
        <TableSubmenuButton>
          {(close: any) => (
            <Box borderStyle="shadow" className="popover-content">
              <ButtonBase
                className="popover-item-button"
                onClick={() => {
                  onCancel();
                  close();
                }}
              >
                <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px">
                  <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                    <g transform="translate(-785.000000, -208.000000)">
                      <g id="dd-actions" transform="translate(770.000000, 193.000000)">
                        <g id="delete-white-copy-5" transform="translate(15.000000, 15.000000)">
                          <circle
                            cx="12"
                            cy="12"
                            id="Oval"
                            r="8"
                            stroke={config.theme.primary}
                            strokeWidth="2"
                          ></circle>
                          <path
                            d="M12,8 C12.5522847,8 13,8.44771525 13,9 L13,11 L15,11 C15.5522847,11 16,11.4477153 16,12 C16,12.5522847 15.5522847,13 15,13 L13,13 L13,15 C13,15.5522847 12.5522847,16 12,16 C11.4477153,16 11,15.5522847 11,15 L11,13 L9,13 C8.44771525,13 8,12.5522847 8,12 C8,11.4477153 8.44771525,11 9,11 L11,11 L11,9 C11,8.44771525 11.4477153,8 12,8 Z"
                            fill={config.theme.primary}
                            id="Combined-Shape"
                            transform="translate(12.000000, 12.000000) rotate(-315.000000) translate(-12.000000, -12.000000) "
                          >
                          </path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>

                <Box marginStart={5}>
                  <div>
                    <Text color="blue" size="md">
                      <Trans>Cancel Visit</Trans>
                    </Text>
                  </div>
                </Box>
              </ButtonBase>
            </Box>
          )}
        </TableSubmenuButton>
      </TableCell>
    </TableRow>
  );
}
