import React, { useCallback, useEffect, useRef, useState } from "react";
import { AttendeesSearchProps } from "./types";
import { Box, Button, Checkbox, Dialog, Skeleton, TextField, Typography, useTheme } from "@mui/material";
import { TogetherUser } from "../icons";
import { t } from "@lingui/macro";
import { debounce } from "underscore";
import { Attendee, useSearchAttendeesQuery } from "store";
import { isEmail } from "class-validator";
import { useLazyLoadScrollHandler } from "hooks";
import { parse } from "qs";

export const AttendeesSearch: React.FC<AttendeesSearchProps> = (props) => {
  const { capacity, value, onChange, Trigger } = props;
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [canInvite, setCanInvite] = useState(false);
  const [search, setSearch] = useState<string>();
  const [skipToken, setSkipToken] = useState<string>();
  const [attendeesToList, setAttendeesToList] = useState<Attendee[]>([]);
  const viewRef = useRef<HTMLDivElement>(null);
  const debouncedSetSearch = useCallback(debounce((search: string) => {
    setAttendeesToList(value || []);

    setSearch(search);
  }, 700), [setSearch]);
  const searchAttendeesQuery = useSearchAttendeesQuery({ limit: 15, search, skipToken });
  const { data: searchAttendeesResponse, isLoading: attendeesAreLoading, isFetching: attendeesAreFetching } = searchAttendeesQuery;
  const attendees = searchAttendeesResponse?.result?.data?.items || [];
  const hasMore = searchAttendeesResponse?.result?.data?.next;

  useEffect(() => {
    if (value) {
      if (search) {
        setAttendeesToList(value);
        setSearch(undefined);  
      } else {
        setAttendeesToList([...value, ...attendees.filter((attendee) => !value.some(({ email }) => email === attendee.email))]);
      }
    }
  }, [value]);

  useEffect(() => {
    const isAlreadyInvited = value?.some(({ email }) => email.toLowerCase() === search?.toLowerCase());

    if (isEmail(search) && !attendees.length && !isAlreadyInvited) {
      setCanInvite(true);
    } else if (canInvite) {
      setCanInvite(false);
    }
  }, [search, attendees]);

  useEffect(() => {
    if (attendees.length) {
      if (attendeesToList.length) {
        setAttendeesToList([
          ...attendeesToList,
          ...attendees.filter(({ email }) => !attendeesToList.some((attendee) => attendee.email === email)),
        ]);
      } else {
        setAttendeesToList([
          ...(value || []),
          ...attendees.filter(({ email }) => !attendeesToList.some((attendee) => attendee.email === email)),
        ]);
      }
    }
  }, [attendees]);

  const handleScroll = useLazyLoadScrollHandler(viewRef, () => {
    if (hasMore) {
      const [, queryString] = hasMore.split("?");
      const queryParams = parse(queryString);

      if (typeof queryParams?.skipToken === "string") {
        setSkipToken(queryParams.skipToken);
      }
    }
  }, 200);

  const handleAttendeeSelection = (attendee: Attendee, checked: boolean) => {
    const isSelected = value?.some(({ email }) => attendee.email === email);

    if (checked && !isSelected) {
      onChange?.([...(value || []), attendee]);
    } else if (!checked && isSelected) {
      onChange?.(value?.filter(({ email }) => attendee.email !== email) || []);
    }
  };

  const handleInvite = () => {
    const email = isEmail(search) ? search?.toLowerCase() : undefined;

    if (email && isEmail(email) && !value?.some((attendee) => email === attendee.email)) {
      onChange?.([...(value || []), { email }]);
    }
  };
  
  return (
    <Box>
      {Trigger ? <Trigger onClick={() => setIsOpen(true)} /> : undefined}
      <Dialog onClose={() => setIsOpen(false)} open={isOpen}>
        <Box maxWidth={440} padding={2}>
          <Box alignItems="center" display="flex" justifyContent="space-between" marginBottom={2}>
            <Box alignItems="center" display="flex">
              <Box
                alignItems="center"
                bgcolor={theme.palette.grey[100]}
                borderRadius={2}
                display="flex"
                height={32}
                justifyContent="center"
                marginRight={1}
                width={32}
              >
                <TogetherUser fill={theme.palette.grey[700]} stroke={theme.palette.grey[700]} sx={{ width: 16 }} />
              </Box>
              <Typography fontWeight="600">
                {t`Attendees`}{" "}{capacity ? `(${value?.length || 0}/${capacity})` : `(${value?.length || 0})`}
              </Typography>
            </Box>
            <Button data-cid="attendees-done-button" onClick={() => setIsOpen(false)} variant="contained">{t`Done`}</Button>
          </Box>
          <Box alignItems="center" display="flex" marginBottom={2}>
            <TextField onChange={(event) => debouncedSetSearch(event.currentTarget.value)} size="small" variant="filled" />
            <Button data-cid="attendees-invite-button" disabled={!canInvite} onClick={handleInvite} sx={{ marginLeft: 2 }}>{t`Invite`}</Button>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            height={450}
            onScroll={handleScroll}
            ref={viewRef}
            sx={{ overflowY: "auto", overflowX: "hidden" }}
          >
            {attendeesToList.map((attendee) => {
              const isSelected = value?.some(({ email }) => attendee.email === email);

              return (
                <Box alignItems="center" display="flex" key={attendee.email} paddingBottom={0.5} paddingTop={0.5}>
                  <Box minWidth={42}>
                    <Checkbox checked={isSelected} onChange={(_, checked) => handleAttendeeSelection(attendee, checked)} />
                  </Box>
                  <Box
                    alignItems="center"
                    bgcolor={({ palette }) => !attendee.id ? palette.grey[400] : palette.primary.main}
                    borderRadius={2}
                    display="flex"
                    height={32}
                    justifyContent="center"
                    marginRight={1}
                    minWidth={32}
                    width={32}
                  >
                    <TogetherUser fill="#fff" stroke="#fff" sx={{ width: 16 }} />
                  </Box>
                  <Box maxWidth={280}>
                    <Typography fontSize={14} fontWeight="600" noWrap>{attendee.id ? attendee.name : t`Invited user`}</Typography>
                    <Typography color={({ palette }) => palette.grey[700]} fontSize={12} noWrap>{attendee.email}</Typography>
                  </Box>
                </Box>
              );
            })}
            {hasMore || attendeesAreFetching || attendeesAreLoading ? (
              <Box alignItems="center" display="flex">
                <Box alignItems="center" display="flex" height={42} justifyContent="center" width={42}>
                  <Skeleton sx={{ width: 20, height: 20, borderRadius: 1 }} variant="rectangular" />
                </Box>
                <Skeleton sx={{ width: 32, height: 32, borderRadius: 2, marginRight: 1 }} variant="rectangular" />
                <Box>
                  <Skeleton sx={{ fontSize: 14, width: 220 }} variant="text" />
                  <Skeleton sx={{ fontSize: 12, width: 160 }} variant="text" />
                </Box>
              </Box>
            ) : undefined}
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};
