import { Element } from "slate";
import { Editor } from "slate";
import { matchesEditorElement } from "./matches-editor-element";

export const isEditorElementActive = (editor: Editor, element: Partial<Element>): boolean => {
  const [match] = Editor.nodes(editor, {
    match: (node) => !Editor.isEditor(node) && Element.isElement(node) && matchesEditorElement(element, node),
  });

  return !!match;
};
