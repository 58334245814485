import Box from 'components/_legacy/Box';
import styles from './styles.module.scss';

export default function PrivacyPolicy() {
  return (
    <Box className={styles.container}>
      <p>
        Together has developed this Privacy Policy to describe how we collect, use and otherwise process information about you, that directly identifies you, or that makes you identifiable {`("Personal Data")`} in connection with our website {`(the "Site")`} and our mobile application {`("Consumer Mobile Application")`}, {`(together the "Services")`} and what choices visitors you have with respect to that information.
      </p>

      <h3>Information We Collect</h3>

      <p>
        When you interact with us through the Services, we may collect Personal Data and other information from you, as further described below:
      </p>

      <h4>Personal Data That You Provide</h4>

      <ul>
        <li>
          <strong>Service Inquiries.</strong> We collect Personal Data from you when you voluntarily provide such information, such as when you contact us with inquiries or register yourself or your company online for access to the Services.
        </li>

        <li>
          <strong>Account Creation and Profile Information.</strong> We collect Personal Data that you provide when you register for an account. This information includes your full name, company and email address. Once you create an account, you may also be able to update your account information, such as your name, phone number, email address, and other preferences and information.
        </li>

        <li>
          <strong>Communications.</strong> When you contact us through any method of communications, we may collect your name, email address, mailing address, phone number, or any other Personal Data you choose to provide to us.
        </li>
      </ul>

      <h4>
        Personal Data Automatically Collected
      </h4>

      <p>
        As is true of most digital platforms, we and our third-party providers and partners collect certain Personal Data automatically when you visit or interact with our Service:
      </p>

      <ul>
        <li>
          <strong>Log Data.</strong> Including your internet protocol (IP) address, operating system, browser type, browser ID, the URL you entered and the referring page/campaign, date/time of your visit, the time you spent on our services and any errors that may occur during your visit to our Service. Our Services may collect and use your location information (for example, by using the Location Services on your mobile device) to provide certain functionality of our Services, including facilitating Visitor registration for Business Customers (as discussed below). Your location information is not made available to other users without your express permission.
        </li>

        <li>
          <strong>Analytics Data.</strong> Including the electronic path you take to our services, through our services and when exiting our services, as well as your usage and activity on our services, such as the links, objects, products and benefits you view, click or otherwise interact with, including your search history (also known as {`"Clickstream Data"`}). We may also derive information on your preferences based on Clickstream Data.
        </li>
      </ul>

      <h4>Personal Data from Other Sources and Third Parties</h4>

      <p>
        Although we often collect the Personal Data described above directly from you, we also collect this information through service providers and other third parties that collect it on our behalf, as noted below.
      </p>

      <ul>
        <li>
          <strong>Social Media.</strong> If you choose to interact with us on social media or third party service, such as when you like, follow or share our content on Facebook, Twitter, Instagram or other sites, or interact with social media icons on our Service, we may collect Personal Data about you from your social media account that you make public. We use Personal Data collected in connection with your social media account to communicate with you, better understand your interests and preferences, and better understand our customer base in the aggregate.
        </li>

        <li>
          <strong>Account Creation / Single Sign-On.</strong> We may use single sign-on ({`"SSO"`}) to allow a user to authenticate their account through their work email. We will have access to certain information from those third parties in accordance with the authorization procedures determined by those third parties, including, for example, your username, password, name, email address, and profile picture.
        </li>

        <li>
          <strong>Business Partners.</strong> Our business partners collect Personal Data in connection with our Services and often share some or all of this information with us.
        </li>
      </ul>

      <h4>
        Our Use of Personal Data
      </h4>

      <p>
        We may use the Personal Data we collect through our Service to:
      </p>

      <ul>
        <li>
          Provide the Service;
        </li>
        <li>
          Administer and improve our Service, including test different configurations of our Service, diagnose errors and problems with our Service;
        </li>
        <li>Remember you and the choices you have made in relation to our Service;</li>
        <li>Incorporate ideas from your responses into our business operations and customer service models;</li>
        <li>Respond to your comments and questions and provide customer service;</li>
        <li>Communicate with you regarding our Services, offerings, and products (including by sending you service-related emails);</li>
        <li>Facilitate our day-to-day business operations, such as maintaining accounts and processing transactions;</li>
        <li>Prevent, investigate or provide notice of fraud or unlawful or criminal activity;</li>
        <li>Enforce and carry out contracts and agreements, including our Terms of Use ;</li>
        <li>Comply with legal obligations;</li>
        <li>To fulfill any other purpose for which you provide Personal Data; and</li>
        <li>For any other lawful purpose, or other purpose that you consent to.</li>
      </ul>
      <h4>Our Disclosure of Personal Data</h4>
      <p>
        We may also share, transmit, disclose, grant access to, make available, and provide Personal Data with and to third parties, as described below.
      </p>

      <ul>
        <li>
          <strong>Within Zazmic.</strong> We are able to offer you the services we make available because of the hard work of the entire Zazmic team. Zazmic entities disclose Personal Data to other Zazmic companies and brands that are owned or controlled by Zazmic, and other companies owned by or under common ownership as Zazmic. These companies will use your Personal Data in the same way as we can under this Privacy Notice.
        </li>
        <li>
          <strong>Legal Obligations and Rights.</strong> We may disclose Personal Data to third parties, such as legal advisors and law enforcement:

          <ul>
            <li>In connection with the establishment, exercise, or defense of legal claims;</li>
            <li>To comply with laws and regulations or to respond to lawful requests and legal process;</li>
            <li>To protect our rights and property and the rights and property of our agents, customers, and others, including to enforce our agreements, policies, and Terms of Service;</li>
            <li>To detect, suppress, or prevent fraud;</li>
            <li>To reduce credit risk and collect debts owed to us;</li>
            <li>To protect the health and safety of us, our customers, or any person; or</li>
            <li>As otherwise required by applicable law.</li>
          </ul>
        </li>
      </ul>

      <h3>
        We do not use Cookies or other tracking technologies to gather information.
      </h3>
    </Box>
  );
}
