import { t } from "@lingui/macro";
import { Box } from "@mui/material";
import { CampaignPolicyWrapper, NumberListInput, NumberListInputProps, UsersListSelect } from "components";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CommPolicyRule, CommPolicyRuleType, CommPolicyType, User, adminSlice, selectAdminCurrentCampaignPolicy, useCurrentCampaignPolicyRules } from "store";
import { MarkOptional } from "ts-essentials";

export const CampaignMasterPolicy: React.FC = () => {
  const dispatch = useDispatch();
  const currentPolicy = useSelector(selectAdminCurrentCampaignPolicy);
  const { approvers, recipientUsersCount, recipients, authors } = useCurrentCampaignPolicyRules();
  const { rules } = currentPolicy || {};

  const updateUserRules = useCallback((type: CommPolicyRuleType, value: User[]) => {
    const newRules: MarkOptional<CommPolicyRule, "id" | "createdAt">[] = [];

    for (const rule of rules || []) {
      if (rule.type !== type || value.some(({ id }) => rule.user?.id === id)) {
        newRules.push(rule);
      }
    }

    for (const user of value) {
      if (!newRules.some((rule) => rule.type === type && rule.user?.id === user.id)) {
        newRules.push({ type, user });
      }
    }

    dispatch(adminSlice.actions.setCurrentCampaignPolicy({ rules: newRules }));
  }, [rules, dispatch]);

  const handleNumberListInputChange: NumberListInputProps["onChange"] = (value) => {
    const ruleExists = rules?.some(({ type }) => type === CommPolicyRuleType.RECIPIENT_USERS_COUNT);

    if (ruleExists) {
      dispatch(adminSlice.actions.setCurrentCampaignPolicy({
        rules: rules?.map((rule) => rule.type === CommPolicyRuleType.RECIPIENT_USERS_COUNT ? { ...rule, value: `${value}` } : rule ),
      }));
    } else {
      dispatch(adminSlice.actions.setCurrentCampaignPolicy({
        rules: [...(rules || []), { type: CommPolicyRuleType.RECIPIENT_USERS_COUNT, value: `${value}` }],
      }));
    }
  };

  return (
    <CampaignPolicyWrapper type={CommPolicyType.MASTER}>
      <Box display="flex" flexDirection="column" gap={2}>
        <UsersListSelect
          labels={{ title: t`Approver`, disclaimer: t`(any of the following names can approve)` }}
          onChange={(value) => updateUserRules(CommPolicyRuleType.APPROVER, value || [])}
          readOnly={!currentPolicy?.isEditable}
          value={approvers}
        />
        <NumberListInput
          labels={{ title: t`Recipients count`, disclaimer: t`(require approval If attendee user count is greater than)` }}
          min={0}
          onChange={handleNumberListInputChange}
          readOnly={!currentPolicy?.isEditable}
          value={recipientUsersCount}
        />
        <UsersListSelect
          labels={{ title: t`Recipients list`, disclaimer: t`(require approval if any of the following names are in the recipients list)` }}
          onChange={(value) => updateUserRules(CommPolicyRuleType.RECIPIENT, value || [])}
          readOnly={!currentPolicy?.isEditable}
          value={recipients}
        />
        <UsersListSelect
          labels={{
            title: t`Message is sent from`,
            disclaimer: t`(require approval if any of the following names are who the message is sent from)`,
          }}
          onChange={(value) => updateUserRules(CommPolicyRuleType.AUTHOR, value || [])}
          readOnly={!currentPolicy?.isEditable}
          value={authors}
        />
      </Box>
    </CampaignPolicyWrapper>
  );
};
