import { t } from "@lingui/macro";

import {
  ActionState,
  GroupCreateEditFormActionStateEnum,
  GroupCreateEditFormTabEnum,
  IFormErrors,
  SetGroupsCreateEditFormDataRequest,
  TabsState,
} from "./models";
import { IGroup, IGroupAdSearch } from "../../models";
import {
  SEARCH_GROUPS_FROM_AD,
  SEARCH_GROUPS_FROM_AD_FAIL,
  SEARCH_GROUPS_FROM_AD_SUCCESS,
  SearchGroupsFromAd,
  SearchGroupsFromAdFail,
  SearchGroupsFromAdSuccess,
} from "../../index";

const SET_GROUPS_CREATE_EDIT_FORM_DATA = 'SET_GROUPS_CREATE_EDIT_FORM_DATA';

interface SetGroupsCreateEditFormData {
  type: typeof SET_GROUPS_CREATE_EDIT_FORM_DATA;
  payload: SetGroupsCreateEditFormDataRequest;
}

type Actions =
  | SetGroupsCreateEditFormData
  | SearchGroupsFromAd
  | SearchGroupsFromAdFail
  | SearchGroupsFromAdSuccess

export interface State {
  error: string;
  loadingAdSearch: boolean;
  actionState: ActionState;
  tabsState: TabsState;
  actionTypeName: string;
  groupsAd: IGroupAdSearch[],
  group?: IGroup;
  formDataName: string;
  formDataLocationIds: string[];
  formDataAdGroup: IGroupAdSearch | null;
  formErrors: IFormErrors;
}

export const initialFormErrors: IFormErrors = {
  groupNameError: false,
  groupAdError: false,
};

const initialState: State = {
  error: '',
  loadingAdSearch: false,
  actionState: GroupCreateEditFormActionStateEnum.add,
  tabsState: GroupCreateEditFormTabEnum.newGroup,
  actionTypeName: '',
  groupsAd: [], // search result of AD groups
  group: undefined, // current group from edit state

  formDataName: '',
  formDataLocationIds: [],
  formDataAdGroup: null,

  formErrors: initialFormErrors,
};

export default function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case SEARCH_GROUPS_FROM_AD:
      return {
        ...state,
        error: '',
        loadingAdSearch: true,
        groupsAd: [],
      };
    case SEARCH_GROUPS_FROM_AD_FAIL:
      return {
        ...state,
        loadingAdSearch: false,
        error: t`There was an error loading groups from AD.`,
      };
    case SEARCH_GROUPS_FROM_AD_SUCCESS: {
      return {
        ...state,
        error: '',
        loadingAdSearch: false,
        groupsAd: action.payload.data.result.data.items,
      };
    }

    case SET_GROUPS_CREATE_EDIT_FORM_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
}

// Actions
export function setGroupsCreateEditFormData(data: SetGroupsCreateEditFormDataRequest): SetGroupsCreateEditFormData
{
  return {
    type: SET_GROUPS_CREATE_EDIT_FORM_DATA,
    payload: data,
  };
}
