import {GetCertificatesByUserResponse, UploadCovidCertificateResponse} from "./models";

export const uploadCovidCertificateResponse: UploadCovidCertificateResponse = {
  result: {
    statusCode: 200,
    data: {
      approver: null,
      createdAt: "2022-01-11T14:00:27.923Z",
      documentUrl: "https://storetogetherstage.blob.core.windows.net/vaccination-certificate/1641909627852blob",
      id: "1eb3322b-74dc-4a10-b000-10c3f7eac783",
      status: "needsApproval",
      type: "vaccineRecord",
      updatedAt: null,
      updatedBy: null,
      userId: "f81a126d-d904-4a49-b703-216e7734b4b9",
      vaccinationDate: "2022-01-10T00:00:00.000Z",
      vaccinationExpirationDate: "2022-01-29T00:00:00.000Z",
      verificationDate: null,
    },
  },
};

export const getCertificatesByUserResponse: GetCertificatesByUserResponse = {
  result: {
    statusCode: 200,
    data: {
      documents: [
        {
          approver: null,
          createdAt: "2022-01-11T14:00:27.923Z",
          documentUrl: "https://storetogetherstage.blob.core.windows.net/vaccination-certificate/1641909627852blob",
          id: "1eb3322b-74dc-4a10-b000-10c3f7eac783",
          status: "needsApproval",
          type: "vaccineRecord",
          updatedAt: null,
          updatedBy: null,
          userId: "f81a126d-d904-4a49-b703-216e7734b4b9",
          vaccinationDate: "2022-01-10T00:00:00.000Z",
          vaccinationExpirationDate: "2022-01-29T00:00:00.000Z",
          verificationDate: null,
        },
      ],
      limit: 10,
      page: 1,
      totalPages: 5,
    },
  },
};
