import { IFileWithMeta } from 'react-dropzone-uploader';
import { LatLngExpression, LatLngLiteral } from 'leaflet';
import { ResourceAvailableService } from "./meeting/models";
import { DeskStatus } from "./desk/models";

export enum DeskStatusEnum {
  Available = "AVAILABLE",
  Unavailable = "UNAVAILABLE",
  ApprovalRequired = "APPROVAL_REQUIRED",
  DeleteInProgress = "DELETE_IN_PROGRESS",
  Deleted = "DELETED",
}

export enum FloorMapObjectTypeEnum {
  Section = "Section",
  Desk = "Desk",
  Meeting = "Room",
}

export type FloorMapObjectType =
  | FloorMapObjectTypeEnum.Section
  | FloorMapObjectTypeEnum.Desk
  | FloorMapObjectTypeEnum.Meeting

export interface DrawingStates {
  drawDesk: DrawState;
  drawSection: DrawState;
  drawMeeting: DrawState;
}

export interface IResource {
  id: string;
  name: string;
  locationId: string;
  isAvailable: boolean;
  createdAt: string;
}

type ResourcesObject = {
  [key: string]: {
    id: string;
    name: string;
    selected: boolean;
  };
}

export interface ICoordinate {
  x: number;
  y: number;
}

export interface ILatLng {
  lat: number;
  lng: number;
}

export type MapImageBoundsType = number[][];

interface IApprover {
  email: string;
}

export interface MapDrawingOwner {
  id: string;
  name: string;
  email: string;
}

export interface MapDrawingGroup {
  id: string;
  name: string;
}

export interface FloorMapObject {
  approvers?: {
    approver1: IApprover;
    approver2: IApprover;
  };
  coordinatesInLatLngBounds: ILatLng[];
  coordinatesInPoints: ICoordinate[];
  fill: string;
  id: number | string;
  isAvailable?: boolean;
  latlngs?: LatLngExpression[] | Array<LatLngLiteral[]> | undefined;
  line: string;
  name: string;
  owners: MapDrawingOwner[];
  groups: MapDrawingGroup[];
  parentId?: number | undefined;
  provisory: boolean;
  hasError: boolean;
  resourceIds: { [resourceId: string]: string };
  saved: boolean;
  status: DeskStatus;
  type: FloorMapObjectType;
  // meeting options only
  capacity?: number | null;
  roomId?: string;
  availableServices?: ResourceAvailableService[];
  amenityIds?: { [amenityId: string]: string };
  description?: string;
  reservationDayLimit?: number;
}

export interface FloorMapSectionUpdate {
  approvers?: {
    approver1: IApprover;
    approver2: IApprover;
  };
  coordinatesInLatLngBounds?: ILatLng[];
  coordinatesInPoints?: ICoordinate[];
  fill?: string;
  id?: number;
  line?: string;
  name?: string;
  owners?: MapDrawingOwner[];
  groups?: MapDrawingGroup[];
  capacity?: number | null;
  provisory?: boolean;
  hasError?: boolean;
  resources?: { [resourceId: string]: string };
  status?: DeskStatus;
  type?: FloorMapObjectType;
  // meeting options only
  roomId? : string;
  availableServices?: ResourceAvailableService[];
  amenities?: { [resourceId: string]: string };
  description?: string;
  reservationDayLimit?: number;
}

interface FloorMapMeetingUpdate extends FloorMapSectionUpdate {}

export interface UpdateAllDesksRequest {
  updateDeskProperties: FloorMapSectionUpdate;
}

export interface UpdateDeskRequest {
  deskId: string;
  updateDeskProperties: FloorMapSectionUpdate;
}

export interface UpdateSectionRequest {
  section: FloorMapSectionUpdate;
  sectionId: number;
}

export interface UpdateMeetingRequest {
  meeting: FloorMapMeetingUpdate;
  meetingId: number;
}

export interface SetFloorMapDataRequest {
  activeDeskId?: string;
  activeSectionId?: number;
  activeMeetingId?: number;

  showDeleteDeskModal?: boolean;
  deskHasReservation?: boolean;
  deskDeletedId?: string;

  desks?: {
    [deskId: string]: FloorMapObject;
  };
  deskSize?: {
    height: number;
    width: number;
  };
  drawingStates?: DrawingStates;
  editDeskList?: {
    selectAllResources?: ResourcesObject;
    selectAllStatus?: DeskStatus;
    selectedDesks?: {
      [deskId: string]: FloorMapObject;
    };
  };
  errorMessage?: string;
  id?: string;
  locationId?: string;
  mapFileWithMeta?: IFileWithMeta | null;
  mapSize?: {
    height: number;
    width: number;
  },
  mapImageBounds?: MapImageBoundsType;
  name?: string;
  previewUrl?: string;
  mapSizeToEdit?: {
    height: number;
    width: number;
  };
  mapImageBoundsToEdit?: MapImageBoundsType;
  fileWithMetaToEdit?: IFileWithMeta | null;
  sections?: {
    [sectionId: number]: FloorMapObject;
  };
  showSections?: boolean;
  successMessage?: string;
}
export interface DrawState {
  active: boolean;
}

export interface EditDeskList {
  selectAllResources: ResourcesObject;
  selectAllStatus: DeskStatus;
  selectedDesks: {
    [deskId: string]: FloorMapObject;
  };
}