import { SvgIcon, SvgIconProps } from "@mui/material";

export const TogetherCatering: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path
        d="M3.843 15.652c-.256-.173-.55-.48-.654-.68-.15-.29-.19-.868-.19-2.747 0-2.212-.015-2.381-.225-2.381-.124 0-.476-.16-.783-.354-.306-.194-.791-.62-1.077-.944C.628 8.22.305 7.695.197 7.377.089 7.06 0 6.426 0 5.97c0-.457.094-1.108.209-1.447.148-.436.43-.835.969-1.367.522-.516.955-.82 1.384-.97.344-.12.779-.217.966-.217.238 0 .512-.17.912-.57.315-.316.949-.751 1.41-.97C6.625.061 6.785.032 8 .032c1.216 0 1.374.029 2.153.398.467.221 1.077.642 1.375.947.44.453.641.565 1.145.633.337.046.863.21 1.17.363.308.154.82.565 1.14.915.318.35.678.913.798 1.252.125.35.22.97.219 1.438 0 .452-.095 1.098-.21 1.437-.146.433-.437.845-.976 1.385-.52.523-.935.82-1.29.926l-.525.157v2.4c0 2.282-.013 2.419-.28 2.81a2.393 2.393 0 0 1-.718.644c-.395.21-.772.231-4.064.231h-3.63ZM11 11.97v-2H5v4h6ZM5.243 7.453c.537-.375.565-.381.875-.18.645.422 1.188.59 1.89.587.704-.003 1.235-.17 1.874-.586.31-.203.337-.196.916.211.482.34.702.417 1.152.4.306-.013.738-.128.959-.257.221-.13.529-.423.684-.653.193-.287.281-.603.281-1.006s-.088-.72-.281-1.006a2.543 2.543 0 0 0-.687-.655c-.404-.235-1.242-.321-1.719-.175-.203.061-.329-.054-.666-.61-.3-.495-.595-.788-1.062-1.056-.523-.3-.794-.37-1.413-.372-.421 0-.955.078-1.187.175a3.936 3.936 0 0 0-.762.444c-.187.147-.496.545-.687.885-.283.503-.393.602-.597.537-.427-.136-1.264-.074-1.64.122a2.973 2.973 0 0 0-.663.51c-.218.234-.317.51-.357.992-.039.461.007.788.145 1.057.111.214.385.527.608.697.224.169.547.335.719.368.172.034.481.038.687.01.207-.027.625-.225.93-.439z"
        fill={props.fill}
        strokeWidth="1.5"
      />
    </SvgIcon>
  );
};
