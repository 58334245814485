import {useDispatch} from "react-redux";
import {useLocation} from "react-router-dom";
import {useHistory} from "react-router";
import {useTypedSelector} from "store/_legacy/Redux/store";
import styles from "../../styles.module.scss";

import {Trans, t} from "@lingui/macro";
import Button from "../../../Button";
import {setExecutiveAssistantData} from "App/Store/Users/executiveAssistant";

interface Props {
  onClosePopup: Function;
}

export default function SwitchToAccount(props: Props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { executiveAssistant } = useTypedSelector(state => state);
  const { selectedUser } = executiveAssistant;
  const { onClosePopup } = props;

  const onNavigateByPath = () => {
    const isCreatedBookingsPath = location.pathname.includes('/booking' || 'create-new-booking');

    if (isCreatedBookingsPath) {
      history.push(`/`);
    }
  };

  const onSwitchToMyAccount = () => {
    const isNewUserTheSame = !selectedUser;

    dispatch(setExecutiveAssistantData({ selectedUser: null })); // clear selected user selection
    onClosePopup(); // close after user change

    if (!isNewUserTheSame) {
      onNavigateByPath(); // navigate to home after user change if some booking is open + its not same user as choosed
    }
  };

  return (
    <Button
      aria-label={t`Switch to my account`}
      className={styles.delegateModalButton}
      name={t`Switch to my account`}
      onClick={() => onSwitchToMyAccount()}
      size="sm"
      type="clear"
      withShadow={false}
    >
      <Trans>
        Switch to my account
      </Trans>
    </Button>
  );
}
