import React, { useEffect, useMemo, useRef, useState } from "react";
import { TimeInputProps } from "./types";
import { Box, List, ListItem, ListItemButton, ListItemText, Skeleton, useAutocomplete, useTheme } from "@mui/material";
import { add, eachMinuteOfInterval, isBefore, isSameHour, isSameMinute, parse, set, startOfDay } from "date-fns";
import { TimeField, TimeFieldProps } from "@mui/x-date-pickers";
import { blueLight } from "theme/_legacy/variables";
import { flooredCalendarViewDate } from "../calendar-view";
import { format } from "date-fns-tz";
import { localZeroDay } from "utils";

export const TimeInput: React.FC<TimeInputProps> = (props) => {
  const { step = 15, value, disabled, error, isLoading, unavailable, min, listAlign, onChange } = props;
  const { palette } = useTheme();
  const optionsListRef = useRef<HTMLUListElement>(null);
  const formattedValue = value ? format(value, "HH:mm") : undefined;
  const normalizedValue = formattedValue ? parse(formattedValue, "HH:mm", localZeroDay) : undefined;
  const options = useMemo(() => {
    const hours = eachMinuteOfInterval({start: startOfDay(localZeroDay), end: add(localZeroDay, { days: 1, minutes: step * -1 }) }, { step });

    return hours.map((hour) => format(hour, "HH:mm"));
  }, [step]);
  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    value: formattedValue || "",
    options,
    disableClearable: true,
    freeSolo: true,
    isOptionEqualToValue: (option, value) => {
      const optionDate = parse(option, "HH:mm", localZeroDay);
      const valueDate = parse(value, "HH:mm", localZeroDay);

      return isSameMinute(optionDate, valueDate) && isSameHour(optionDate, valueDate);
    },
    onChange: (event, value) => {
      if (value) {
        onChange?.(parse(value, "HH:mm", localZeroDay));
      }
    },
  });
  const [isFocused, setIsFocused] = useState(false);
  const backgroundColor = isFocused ? blueLight : palette.grey[200];
  const optionHeight = 40;
  const { onFocus, onBlur, ...inputProps } = getInputProps() as TimeFieldProps<Date>;
  const unavailableOptions = useMemo(() => unavailable?.map((value) => format(value, "HH:mm")), [unavailable]);

  const minOption = useMemo(() => {
    if (min) {
      return set(
        flooredCalendarViewDate(min, step),
        { year: localZeroDay.getFullYear(), month: localZeroDay.getMonth(), date: localZeroDay.getDate() },
      );
    }
  }, [min]);

  useEffect(() => {
    if (isFocused && optionsListRef.current) {
      if (formattedValue || (minOption && minOption.getHours() >= 8)) {
        const optionIndex = (formattedValue && options.indexOf(formattedValue)) 
          || (minOption && minOption.getHours() >= 8 && options.indexOf(format(minOption, "HH:mm")));

        if (optionIndex && optionIndex !== -1) {
          optionsListRef.current.scrollTop = optionIndex * optionHeight;
        }
      } else {
        optionsListRef.current.scrollTop = (60 / step) * 8 * optionHeight;
      }
    }
  }, [isFocused, formattedValue, optionHeight, minOption, optionsListRef]);

  const getOptionDisabled = (option: string) => {
    if (minOption) {
      const optionDate = parse(option, "HH:mm", localZeroDay);

      if (isBefore(optionDate, minOption)) {
        return true;
      }
    }

    return unavailableOptions?.includes(option);
  };

  return isLoading ? (
    <Skeleton height={37} variant="rectangular" width={80} />
  ) : (
    <Box {...getRootProps()} position="relative">
      <Box>
        <TimeField
          {...inputProps}
          ampm={false}
          disabled={disabled}
          format="HH:mm"
          onBlur={(event) => {
            if (!disabled) {
              onBlur?.(event);
              setIsFocused(false);
            }
          }}
          onChange={(value) => {
            if (value && value instanceof Date && !isNaN(value.getTime())) {
              onChange?.(value);
            }
          }}
          onFocus={(event) => {
            if (!disabled) {
              onFocus?.(event);
              setIsFocused(true);
            }
          }}
          size="small"
          sx={{
            backgroundColor: error ? "#ffbebe" : backgroundColor,
            borderRadius: "8px",
            "& input": {
              width: 52,
              textAlign: "center",
              fontSize: 14,
            },
            "& fieldset": {
              border: "none",
              borderRadius: 0,
            },
          }}
          value={normalizedValue}
        />
      </Box>
      {!disabled && groupedOptions.length > 0 ? (
        <List
          {...getListboxProps()}
          disablePadding
          ref={optionsListRef}
          sx={{
            zIndex: 100,
            position: 'absolute',
            background: "white",
            overflow: 'auto',
            maxHeight: 200,
            width: 130,
            right: listAlign === "right" ? 0 : undefined,
            boxShadow: "0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12), 0 2px 4px -1px rgba(0,0,0,.2)",
            borderRadius: "5px",
            "& li[aria-selected='true']": {
              backgroundColor: blueLight,
            },
          }}
        >
          {(groupedOptions as typeof options).map((option, index) => (
            <ListItem {...getOptionProps({ option, index })} disablePadding key={option}>
              <ListItemButton disabled={getOptionDisabled(option)} sx={{ height: optionHeight, cursor: "unset" }}>
                <ListItemText primary={option} primaryTypographyProps={{ fontSize: 16, noWrap: true }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      ) : null}
    </Box>
  );
};
