import { FloorMapObject } from "../../../../../../Store/floorMapDuck/models";
import Box from "components/_legacy/Box";
import Text from "components/_legacy/Text";
import { Trans } from "@lingui/macro";
import Catering from "./Catering";
import ItSupport from "./ItSupport";

interface Props {
  meeting: FloorMapObject;
}

export default function MeetingDetailsAdditionServices(props: Props) {
  const { meeting } = props;

  return (
    <Box>
      <Text size="md">
        <Trans>Additional service</Trans>:
      </Text>

      <Catering drawingObject={meeting} />
      <ItSupport drawingObject={meeting} />
    </Box>
  );
}
