import Box from "components/_legacy/Box";
import LocationDetailsHeader from 'components/_legacy/Header/LocationDetailsHeader';
import {t} from "@lingui/macro";
import styles from './styles.module.scss';
import {Link, useParams} from "react-router-dom";
import clsx from "clsx";
import Text from "components/_legacy/Text";

export default function Templates({ children }: React.PropsWithChildren<any>) {
  const { locationId } = useParams<{ locationId: string }>();
  const NAVIGATION_ITEMS = [
    {
      name: t`Questions`,
      to: `/admin/location/${locationId}/templates/questions`,
    },
    {
      name: t`Notifications`,
      to: `/admin/location/${locationId}/templates/notifications`,
    },
    {
      name: t`Emails`,
      to: `/admin/location/${locationId}/templates/email-templates`,
    },
    {
      name: t`Pushes`,
      to: `/admin/location/${locationId}/templates/push-templates`,
    },
  ];


  return (
    <>
      <LocationDetailsHeader />

      <Box marginTop={20}>
        <Box display="flex" justifyContent="between">
          <Box width={165}>
            <Box className={styles.menuWrapper}>
              <nav aria-label={t`Templates Navigation Items`} className={styles.nav} data-testid="location-details-menu-templates-menu">
                <ul>
                  {NAVIGATION_ITEMS.map((item, index) => {
                    const isSelected = item.to === location.pathname;
                    const classes = clsx(isSelected && styles.isSelected);

                    return (
                      <li className={classes} data-testid={`location-details-menu-templates-menu-item`} key={item.name + index}>
                        <Link to={item.to}>
                          <Text color={isSelected ? 'blue' : 'black'} size="md" weight="semi-bold">
                            {item.name}
                          </Text>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </nav>
            </Box>
          </Box>
          <div className={styles.templateWrapper}>
            {children}
          </div>
        </Box>
      </Box>
    </>
  );
}
