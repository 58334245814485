import Box from 'components/_legacy/Box';
import { compensateTimezoneOffset } from 'Admin/Pages/Calendar/AdminCalendar/Helpers/Helpers';
import Text from 'components/_legacy/Text';
import { DateRange, Range, RangeKeyDict } from 'react-date-range';
import { setCreateNewBookingData } from 'App/Store/Bookings/createNewBookingDuck';
import { Plural, Trans } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTypedSelector } from 'store/_legacy/Redux/store';
import {getClosestAvailableDay, getClosestStartDate, getStartDisabledDaysArray} from '../../../../Functions/Helpers';
import {setLocationsData} from "../../../../Store/Locations/locationsDuck";
import { FromToTimeInput, FromToTimeInputProps } from '../FromToTimeInput';
import { resolvePersistedDate } from 'store/_legacy/Utils';
import { add } from 'date-fns';

const DEFAULT_TIME_FROM = new Date(1970, 0, 1, 9);
const DEFAULT_TIME_TO = new Date(1970, 0, 1, 18);

export default function CustomBooking() {
  const dispatch = useDispatch();
  const { config, createNewBooking, locations: locationsDuck } = useTypedSelector(state => state);
  const { dateFrom: reduxDateFrom, dateTo: reduxDateTo, locationId } = createNewBooking;
  const selectedLocation = locationsDuck.locations.find(location => location.id === locationId);
  const locationDisabledDays = locationsDuck.locationDisabledDays.map(d => compensateTimezoneOffset(d));
  const startDisabledDaysArray = selectedLocation ? getStartDisabledDaysArray(selectedLocation?.allowedBookingDayType) : [];
  const disabledDays = startDisabledDaysArray.length ? [...startDisabledDaysArray, ...locationDisabledDays] : [...locationDisabledDays];
  const [selectionRange, setSelectionRange] = useState<Range>({
    startDate: undefined,
    endDate: undefined,
    key: 'selection',
  });

  useEffect(() => {
    const closestStartDay = selectedLocation ? getClosestStartDate(selectedLocation?.allowedBookingDayType) : new Date();

    if (locationsDuck.locationDisabledDaysLoaded) {
      dispatch(setCreateNewBookingData({
        dateFrom: getClosestAvailableDay(closestStartDay, locationsDuck.locationDisabledDays),
        dateTo: getClosestAvailableDay(closestStartDay, locationsDuck.locationDisabledDays),
      }));
      dispatch(setLocationsData({ locationDisabledDaysLoaded: false }));
    }
  }, [locationsDuck.locationDisabledDaysLoaded]);

  useEffect(() => {
    const dateFrom = getClosestAvailableDay(reduxDateFrom, locationsDuck.locationDisabledDays);
    const dateTo = getClosestAvailableDay(reduxDateTo, locationsDuck.locationDisabledDays);

    setSelectionRange({
      startDate: dateFrom,
      endDate: dateTo,
      key: 'selection',
    });
  }, [reduxDateFrom, reduxDateTo, locationsDuck.locationDisabledDays]);

  const { timeFrom, timeTo } = createNewBooking;
  const timeFromDate = resolvePersistedDate(timeFrom);
  const timeToDate = resolvePersistedDate(timeTo);

  useEffect(() => {
    dispatch(setCreateNewBookingData({
      timeFrom: timeFromDate || DEFAULT_TIME_FROM,
      timeTo: timeToDate || DEFAULT_TIME_TO,
    }));
  }, []);

  const handleDateRangeChange = (ranges: RangeKeyDict) => {
    dispatch(setCreateNewBookingData({
      dateFrom: ranges.selection.startDate,
      dateTo: ranges.selection.endDate,
    }));
  };

  const handleFromToTimeInputChange: FromToTimeInputProps["onChange"] = (value, error) => {
    if (error.from || error.to) {
      dispatch(setCreateNewBookingData({
        timeFrom: undefined,
        timeTo: undefined,
      }));
    } else {
      dispatch(setCreateNewBookingData({
        timeFrom: value.from,
        timeTo: value.to,
      }));
    }
  };

  return (
    <Box paddingTop={20}>
      <Text color="gray" size="md">
        <Plural 
          one="You can book a desk up to # day in advance."
          other="You can book a desk up to # days in advance."
          value={selectedLocation?.customReservationDayLimit}
        />
      </Text>
      <DateRange
        className="custom-calendar"
        disabledDates={disabledDays}
        locale={config.calendarTranslations}
        maxDate={add(new Date(), { days: selectedLocation?.customReservationDayLimit || 1 })}
        minDate={new Date()}
        onChange={handleDateRangeChange}
        rangeColors={[config.theme.primaryLight]}
        ranges={[selectionRange]}
        showDateDisplay={false}
        showMonthAndYearPickers={false}
      />
      <Box alignItems="center" display="flex" justifyContent="between" marginBottom={14} marginTop={10}>
        <Box style={{ width: "120px", flex: "0 0 120px" }}>
          <Text size="md" weight="semi-bold">
            <Trans>Time</Trans>
          </Text>
        </Box>
        <Box>
          <FromToTimeInput
            defaultValue={{ from: new Date(0, 0, 0, 9), to: new Date(0, 0, 0, 18) }}
            from={timeFromDate}
            onChange={handleFromToTimeInputChange}
            to={timeToDate}
          />
        </Box>
      </Box>
    </Box>
  );
}
