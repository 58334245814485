import { matchPath } from 'react-router-dom';
import { t } from '@lingui/macro';
import {
  ActionPayload,
  BaseErrorResponse,
  BaseResponse,
} from 'store/_legacy/Models/ReduxModels';
import {
  CreateOrUpdateEmailTemplateData,
  CreateOrUpdateEmailTemplateRequest,
  CreateOrUpdateEmailTemplateResponse,
  EmailTemplate,
  EmailTemplateTypes,
  EmailTemplateTypesNames,
  GetEmailTemplatesRequest,
  GetEmailTemplatesResponse,
  SetEmailTemplatesDataRequest,
} from './models';

export const CREATE_OR_UPDATE_EMAIL_TEMPLATE = 'CREATE_OR_UPDATE_EMAIL_TEMPLATE';
export const CREATE_OR_UPDATE_EMAIL_TEMPLATE_FAIL = 'CREATE_OR_UPDATE_EMAIL_TEMPLATE_FAIL';
export const CREATE_OR_UPDATE_EMAIL_TEMPLATE_SUCCESS = 'CREATE_OR_UPDATE_EMAIL_TEMPLATE_SUCCESS';

export const CREATE_OR_UPDATE_PUSH_NOTIFICATION_TEMPLATE = 'CREATE_OR_UPDATE_PUSH_NOTIFICATION_TEMPLATE';
export const CREATE_OR_UPDATE_PUSH_NOTIFICATION_TEMPLATE_FAIL = 'CREATE_OR_UPDATE_PUSH_NOTIFICATION_TEMPLATE_FAIL';
export const CREATE_OR_UPDATE_PUSH_NOTIFICATION_TEMPLATE_SUCCESS = 'CREATE_OR_UPDATE_PUSH_NOTIFICATION_TEMPLATE_SUCCESS';

export const GET_EMAIL_TEMPLATES = 'GET_EMAIL_TEMPLATES';
export const GET_EMAIL_TEMPLATES_FAIL = 'GET_EMAIL_TEMPLATES_FAIL';
export const GET_EMAIL_TEMPLATES_SUCCESS = 'GET_EMAIL_TEMPLATES_SUCCESS';

const SET_EMAIL_TEMPLATES_DATA = 'SET_EMAIL_TEMPLATES_DATA';

export interface CreateOrUpdateEmailTemplate {
  type: typeof CREATE_OR_UPDATE_EMAIL_TEMPLATE;
  payload: ActionPayload<CreateOrUpdateEmailTemplateData>;
}
interface CreateOrUpdateEmailTemplateFail {
  type: typeof CREATE_OR_UPDATE_EMAIL_TEMPLATE_FAIL;
  payload: BaseErrorResponse;
}
interface CreateOrUpdateEmailTemplateSuccess {
  type: typeof CREATE_OR_UPDATE_EMAIL_TEMPLATE_SUCCESS;
  payload: BaseResponse<CreateOrUpdateEmailTemplateResponse>;
}

export interface CreateOrUpdatePushNotificationTemplate {
  type: typeof CREATE_OR_UPDATE_PUSH_NOTIFICATION_TEMPLATE;
  payload: ActionPayload<CreateOrUpdateEmailTemplateData>;
}
interface CreateOrUpdatePushNotificationTemplateFail {
  type: typeof CREATE_OR_UPDATE_PUSH_NOTIFICATION_TEMPLATE_FAIL;
  payload: BaseErrorResponse;
}
interface CreateOrUpdatePushNotificationTemplateSuccess {
  type: typeof CREATE_OR_UPDATE_PUSH_NOTIFICATION_TEMPLATE_SUCCESS;
  payload: BaseResponse<CreateOrUpdateEmailTemplateResponse>;
}

export interface GetEmailTemplates {
  type: typeof GET_EMAIL_TEMPLATES;
  payload: ActionPayload<GetEmailTemplatesRequest>;
}
interface GetEmailTemplatesFail {
  type: typeof GET_EMAIL_TEMPLATES_FAIL;
  payload: BaseErrorResponse;
}
interface GetEmailTemplatesSuccess {
  type: typeof GET_EMAIL_TEMPLATES_SUCCESS;
  payload: BaseResponse<GetEmailTemplatesResponse>;
}

interface SetEmailTemplatesData {
  type: typeof SET_EMAIL_TEMPLATES_DATA;
  payload: SetEmailTemplatesDataRequest;
}

type Actions =
  | CreateOrUpdateEmailTemplate
  | CreateOrUpdateEmailTemplateFail
  | CreateOrUpdateEmailTemplateSuccess
  | CreateOrUpdatePushNotificationTemplate
  | CreateOrUpdatePushNotificationTemplateFail
  | CreateOrUpdatePushNotificationTemplateSuccess
  | GetEmailTemplates
  | GetEmailTemplatesSuccess
  | GetEmailTemplatesFail
  | SetEmailTemplatesData;

export interface State {
  error: string;
  success: string;
  loading: boolean;
  // Email Templates are stored by locationId
  // Eg: { locationId: { emailTemplateType: { ...emailTemplate } } }
  emailTemplates: Record<string, Record<EmailTemplateTypes, EmailTemplate>>;
}

const initialState: State = {
  error: '',
  success: '',
  loading: false,
  emailTemplates: {},
};

export default function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case CREATE_OR_UPDATE_EMAIL_TEMPLATE: {
      return {
        ...state,
        success: '',
        loading: true,
      };
    }
    case CREATE_OR_UPDATE_EMAIL_TEMPLATE_FAIL: {
      return {
        ...state,
        error: t`There was an error creating/updating the email template. Please try again.`,
        success: '',
        loading: false,
      };
    }
    case CREATE_OR_UPDATE_EMAIL_TEMPLATE_SUCCESS: {
      const { data: emailTemplate } = action.payload.data.result;
      const emailTemplates = { ...state.emailTemplates };

      emailTemplates[emailTemplate.locationId][emailTemplate.type] = emailTemplate;

      const title = emailTemplate.emailNotificationTitle ? emailTemplate.emailNotificationTitle : EmailTemplateTypesNames[emailTemplate.type];

      return {
        ...state,
        error: '',
        success: `The template for ${title} was updated`,
        loading: false,
        emailTemplates,
      };
    }

    case CREATE_OR_UPDATE_PUSH_NOTIFICATION_TEMPLATE: {
      return {
        ...state,
        success: '',
        loading: true,
      };
    }
    case CREATE_OR_UPDATE_PUSH_NOTIFICATION_TEMPLATE_FAIL: {
      return {
        ...state,
        error: t`There was an error creating/updating the push notification template. Please try again.`,
        success: '',
        loading: false,
      };
    }
    case CREATE_OR_UPDATE_PUSH_NOTIFICATION_TEMPLATE_SUCCESS: {
      const { data: emailTemplate } = action.payload.data.result;
      const emailTemplates = { ...state.emailTemplates };

      emailTemplates[emailTemplate.locationId][emailTemplate.type] = emailTemplate;

      const title = emailTemplate.pushNotificationTitle ? emailTemplate.pushNotificationTitle : EmailTemplateTypesNames[emailTemplate.type];

      return {
        ...state,
        error: '',
        success: `The template for ${title} was updated`,
        loading: false,
        emailTemplates,
      };
    }

    case GET_EMAIL_TEMPLATES: {
      const match = matchPath<{ locationId: string }>(action.payload.request.url, {
        path: "/api/locations/:locationId/notification-templates",
      });
      const match2 = matchPath<{ locationId: string }>(action.payload.request.url, {
        path: "/api/locations/:locationId/push-templates",
      });

      const locationId = match?.params.locationId ?? match2?.params.locationId ?? '';
      const emailTemplates = { ...state.emailTemplates };

      const locationEmailTemplates = emailTemplates[locationId];

      if (!locationEmailTemplates) {
        emailTemplates[locationId] = {
          cancelByAdmin: {
            emailNotificationText: '',
            emailNotificationTitle: '',
            id: '',
            locationId,
            pushNotificationText: '',
            pushNotificationTitle: '',
            type: 'cancelByAdmin',
          },
          confirmBooking: {
            emailNotificationText: '',
            emailNotificationTitle: '',
            id: '',
            locationId,
            pushNotificationText: '',
            pushNotificationTitle: '',
            type: 'confirmBooking',
          },
          questionnaireCancellation: {
            emailNotificationText: '',
            emailNotificationTitle: '',
            id: '',
            locationId,
            pushNotificationText: '',
            pushNotificationTitle: '',
            type: 'questionnaireCancellation',
          },
          questionnaireReminder: {
            emailNotificationText: '',
            emailNotificationTitle: '',
            id: '',
            locationId,
            pushNotificationText: '',
            pushNotificationTitle: '',
            type: 'questionnaireReminder',
          },
          questionnaireStart: {
            emailNotificationText: '',
            emailNotificationTitle: '',
            id: '',
            locationId,
            pushNotificationText: '',
            pushNotificationTitle: '',
            type: 'questionnaireStart',
          },
          attendeesInvitation: {
            emailNotificationText: '',
            emailNotificationTitle: '',
            id: '',
            locationId,
            pushNotificationText: '',
            pushNotificationTitle: '',
            type: 'attendeesInvitation',
          },
        };
      }

      return {
        ...state,
        error: '',
        loading: true,
        emailTemplates,
      };
    }
    case GET_EMAIL_TEMPLATES_FAIL: {
      return {
        ...state,
        error: t`There was an error getting the email/push templates. Please try again.`,
        loading: false,
      };
    }
    case GET_EMAIL_TEMPLATES_SUCCESS: {
      const emailTemplatesData = action.payload.data.result.data;
      const emailTemplates = { ...state.emailTemplates };

      if (emailTemplatesData.length > 0) {
        const locationId = emailTemplatesData[0].locationId;

        emailTemplatesData.forEach(emailTemplate => {
          emailTemplates[locationId][emailTemplate.type] = {
            ...emailTemplate,
          };
        });
      }

      return {
        ...state,
        error: '',
        loading: false,
        emailTemplates,
      };
    }

    case SET_EMAIL_TEMPLATES_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
}

// Actions
export function createOrUpdateEmailTemplate(data: CreateOrUpdateEmailTemplateRequest): CreateOrUpdateEmailTemplate {
  return {
    type: CREATE_OR_UPDATE_EMAIL_TEMPLATE,
    payload: {
      request: {
        method: 'PUT',
        url: `/api/locations/${data.locationId}/notification-templates`,
        data: data.emailTemplateData,
      },
    },
  };
}

export function createOrUpdatePushNotificationTemplate(data: CreateOrUpdateEmailTemplateRequest): CreateOrUpdatePushNotificationTemplate {
  return {
    type: CREATE_OR_UPDATE_PUSH_NOTIFICATION_TEMPLATE,
    payload: {
      request: {
        method: 'PUT',
        url: `/api/locations/${data.locationId}/notification-templates`,
        data: data.emailTemplateData,
      },
    },
  };
}

export function getEmailTemplates(data: GetEmailTemplatesRequest): GetEmailTemplates {
  return {
    type: GET_EMAIL_TEMPLATES,
    payload: {
      request: {
        method: 'GET',
        url: `/api/locations/${data.locationId}/notification-templates`,
      },
    },
  };
}

export function setEmailTemplatesData(data: SetEmailTemplatesDataRequest): SetEmailTemplatesData {
  return {
    type: SET_EMAIL_TEMPLATES_DATA,
    payload: data,
  };
}
