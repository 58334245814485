import { t } from "@lingui/macro";
import { RRule, Weekday } from "rrule";

export const resolveWeekdayLabel = (weekday: Weekday): string => {
  if (weekday.equals(RRule.MO)) {
    return t`Mon`;
  } else if (weekday.equals(RRule.TU)) {
    return t`Tue`;
  } else if (weekday.equals(RRule.WE)) {
    return t`Wed`;
  } else if (weekday.equals(RRule.TH)) {
    return t`Thu`;
  } else if (weekday.equals(RRule.FR)) {
    return t`Fri`;
  } else if (weekday.equals(RRule.SA)) {
    return t`Sat`;
  } else {
    return t`Sun`;
  }
};
