import { useEffect, useState } from 'react';
import { useTypedSelector } from 'store/_legacy/Redux/store';
import { useDispatch } from "react-redux";
import Api from 'store/_legacy/Services/Api';
import format from 'date-fns/format';
import { Trans, t } from '@lingui/macro';
import { Popup } from 'react-leaflet';
import styles from './styles.module.scss';

import SitIcon from "./assets/sit.svg";
import Box from 'components/_legacy/Box';
import OverflowToolTip from 'components/_legacy/OverflowTooltip';
import numberToHoursMinutes from 'Functions/numberToHoursMinutes';
import { getSectionNeighbours } from '../../../../../../../App/Store/Bookings/sectionNeighbors';
import {setAvailableDesksData} from "../../../../../../../App/Store/Bookings/availableDesksDuck";
import { FloorMapObject } from '../../../../../../Store/floorMapDuck/models';
import { GetSectionNeighboursRequest, Neighbor } from '../../../../../../../App/Store/Bookings/sectionNeighbors/models';
import { BookingType, WeekDays } from 'App/Store/Bookings/bookingDuck/models';
import { WeekDaysObject } from "../../../../../../../App/Store/Bookings/createNewBookingDuck/models";

export interface INeighborsTooltipFilterData {
  bookingType: BookingType;
  dateFrom?: string | Date;
  dateTo?: string | Date;
  timeFrom?: Date;
  timeTo?: Date;
  weekDays?: WeekDaysObject;
}

interface Props {
  floorId?: string | undefined;
  neighboursFilters?: INeighborsTooltipFilterData;
  sectionRef: any; // ref to section object
  section: FloorMapObject;
  zoomLevel: number;
  hasFilters?: boolean; // used in app booking step for dates filters
  setIconMarkupOpacity: Function;
  neighbors?: Neighbor[];
}

const getNeighboursFilters = (neighboursFilters: INeighborsTooltipFilterData) => {
  const data: GetSectionNeighboursRequest = {};
  const {
    bookingType,
    dateFrom,
    dateTo,
    timeFrom,
    timeTo,
    weekDays,
  } = neighboursFilters;

  const weeklyBooking: Array<{ weekDay: WeekDays; timeFrom: string; timeTo: string }> = [];
  if (weekDays) {
    Object.keys(weekDays).forEach(weekDayNumber => {
      const weekDay = weekDays[parseInt(weekDayNumber)];

      if (weekDay.selected) {
        const { hours: timeFromHours, minutes: timeFromMinutes } = numberToHoursMinutes(weekDay.timeFrom);
        const { hours: timeToHours, minutes: timeToMinutes } = numberToHoursMinutes(weekDay.timeTo);

        weeklyBooking.push({
          timeFrom: `${timeFromHours}:${String(timeFromMinutes).length === 1 ? timeFromMinutes + '0' : timeFromMinutes}`,
          timeTo: `${timeToHours}:${String(timeToMinutes).length === 1 ? timeToMinutes + '0' : timeToMinutes}`,
          weekDay: weekDay.label,
        });
      }
    });
  }

  const timeFromFormated = timeFrom ? format(new Date(timeFrom), 'HH:mm') : '09:00';
  const timeToFormated = timeTo ? format(new Date(timeTo), 'HH:mm') : '18:00';

  data.weekly = bookingType === 'weekly' ? weeklyBooking : undefined;
  data.daily = bookingType === 'daily' ? { timeFrom: timeFromFormated, timeTo: timeToFormated } : undefined;
  data.custom = bookingType === 'custom'
    ? {
      dateFrom: dateFrom ? format(new Date(dateFrom), 'yyyy-MM-dd') : '',
      dateTo: dateTo ? format(new Date(dateTo), 'yyyy-MM-dd') : '',
      timeFrom: timeFromFormated,
      timeTo: timeToFormated,
    }
    : undefined;

  return data;
};

/**
 * Custom Tooltip for Neighbors
 * Shows Neighbors for each section based on /api/locations/section/{sectionId}/users call
 */
export default function NeighborsTooltip(props: Props) {
  const { config, availableDesks, profile } = useTypedSelector(state => state);
  const { floorId, neighbors, neighboursFilters, section, sectionRef, hasFilters, setIconMarkupOpacity } = props;
  const {
    bookingType,
    dateFrom,
    dateTo,
    timeFrom,
    timeTo,
    weekDays,
  } = neighboursFilters || {};

  const dispatch = useDispatch();
  const [key, setKey] = useState(0);
  const [sectionNeighbors, setSectionNeighbors] = useState<Neighbor[]>([]);
  const actualNeighbors = neighbors || sectionNeighbors;

  const popupExtraPadding = 15;
  const tooltipOffsetValue = sectionRef?._path.getBoundingClientRect().height / 2 - popupExtraPadding || 0; // Add half of section height as Y offset
  let neighborsFilters = hasFilters && neighboursFilters ? getNeighboursFilters(neighboursFilters) : {};

  const onLoadNeighbours = async () => {
    if (floorId) {
      try {
        const action = getSectionNeighbours({
          data: neighborsFilters,
          floorId,
          sectionId: section.id,
        });

        const payload = await Api(action);

        if (payload.status === 200 || payload.status === 201) {
          // This will remove the own user if it exists on the neighbours list
          const neighbours = payload.data.result.data.filter((n: Neighbor) => n.id !== profile.id);

          setSectionNeighbors(neighbours);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const onPopupMouseOut = (e: any) => {
    const classList = e.relatedTarget?.classList || {};
    const mapWrapper = Object.values(classList).includes('app-map');

    if (mapWrapper) {
      sectionRef.closePopup();
    }
  };

  const onNeighborsItemHighlight = (focus: boolean, deskId: string) => {
    const highlightNeighbourDeskId = focus ? deskId : ''; // set or unset highlighted desk
    return dispatch(setAvailableDesksData({ highlightNeighbourDeskId }));
  };

  const onClosePopup = () => {
    onNeighborsItemHighlight(false, ''); // clear highlight data on close
    setIconMarkupOpacity(1); // show section name after popup close
  };

  const onCleanupState = () => {
    setKey(0);
    setSectionNeighbors([]);
  };

  // Changing the key is necessary to fix a bug where the neighbors wouldn't update correctly after changing.
  useEffect(() => {
    setKey(prevKey => prevKey + 1);
  }, [actualNeighbors, tooltipOffsetValue]);

  useEffect(() => {
    if (!neighbors) {
      neighborsFilters = hasFilters && neighboursFilters ? getNeighboursFilters(neighboursFilters) : {};
      onLoadNeighbours();
    }
  }, [neighbors, bookingType, dateFrom, dateTo, timeFrom, timeTo, weekDays]);

  useEffect(() => {
    return () => onCleanupState(); // clean state on unmount to avoid memory leaks
  }, []);

  const neighborsHeaderText = actualNeighbors.length ?
    t`Section ${section.name}, ${actualNeighbors.length} neighbors` : t`Section ${section.name}`;

  return (
    <Popup
      autoPan={false}
      className={`${styles.neighborsTooltip} neighbors-popup`}
      closeButton={false}
      key={key}
      offset={[0, tooltipOffsetValue]}
      onClose={onClosePopup}
    >
     <div
       className={`${styles.neighborsTooltipContent}`}
       onMouseOut={(e) => onPopupMouseOut(e)}
     >
       <div className={styles.neighborsTooltipHeader}>
         <svg height="30px" version="1.1" viewBox="0 0 30 30" width="30px">
           <g fill="none" fillRule="evenodd" id="Booking" stroke="none" strokeWidth="1">
             <g id="h-Neighbors" transform="translate(-50.000000, -64.000000)">
               <g id="Group-3" transform="translate(30.000000, 48.000000)">
                 <g id="icons/neighbors" transform="translate(20.000000, 16.000000)">
                   <rect fill="#F4F4F4" height="30" id="Rectangle" rx="8" width="30" x="0" y="0"></rect>
                   <path d="M19,8 C20.6568542,8 22,9.34314575 22,11 L22,11 L22,19 C22,20.6568542 20.6568542,22 19,22 L19,22 L11,22 C9.34314575,22 8,20.6568542 8,19 L8,19 L8,11 C8,9.34314575 9.34314575,8 11,8 L11,8 Z M19,10 L11,10 C10.4477153,10 10,10.4477153 10,11 L10,11 L10,19 C10,19.5522847 10.4477153,20 11,20 L11,20 L19,20 C19.5522847,20 20,19.5522847 20,19 L20,19 L20,11 C20,10.4477153 19.5522847,10 19,10 L19,10 Z M18,15 C18.5522847,15 19,15.4477153 19,16 L19,18 C19,18.5522847 18.5522847,19 18,19 L16,19 C15.4477153,19 15,18.5522847 15,18 L15,16 C15,15.4477153 15.4477153,15 16,15 L18,15 Z" fill="#5E5E5E" fillRule="nonzero" id="Combined-Shape"></path>
                 </g>
               </g>
             </g>
           </g>
         </svg>
         <span>{neighborsHeaderText}</span>
       </div>

       <Box className={styles.neighborsTooltipItemWrapper} key={key}>
         {
           actualNeighbors && actualNeighbors.length ?
             actualNeighbors.map(neighborItem => {
               const deskName = availableDesks.availableDesks[neighborItem.deskId]?.name;
               
               return (
                 <div
                   className={styles.neighborsTooltipItem}
                   key={neighborItem.id}
                   onMouseOut={() => onNeighborsItemHighlight(false, neighborItem.deskId)}
                   onMouseOver={() => onNeighborsItemHighlight(true, neighborItem.deskId)}
                 >
                   <Box alignItems="center" display="flex">
                     <svg height="30px" version="1.1" viewBox="0 0 30 30" width="30px">
                       <g fill="none" fillRule="evenodd" id="Booking" stroke="none" strokeWidth="1">
                         <g id="h-Neighbors" transform="translate(-50.000000, -114.000000)">
                           <g id="Group-3" transform="translate(30.000000, 48.000000)">
                             <g id="icons/profileplaceholder" transform="translate(20.000000, 66.000000)">
                               <rect fill={config.theme.primary} height="30" id="Rectangle" rx="8" width="30" x="0" y="0"></rect>
                               <path d="M15,7 C17.7614237,7 20,9.23857625 20,12 C20,13.4808123 19.356267,14.8112727 18.3333763,15.7268057 C21.0871152,16.989024 23,19.7711229 23,23 C23,23.5522847 22.5522847,24 22,24 C21.4477153,24 21,23.5522847 21,23 C21,19.6862915 18.3137085,17 15,17 C11.6862915,17 9,19.6862915 9,23 C9,23.5522847 8.55228475,24 8,24 C7.44771525,24 7,23.5522847 7,23 C7,19.7711229 8.91288484,16.989024 11.6672239,15.7251339 C10.643733,14.8112727 10,13.4808123 10,12 C10,9.23857625 12.2385763,7 15,7 Z M15,9 C13.3431458,9 12,10.3431458 12,12 C12,13.6568542 13.3431458,15 15,15 C16.6568542,15 18,13.6568542 18,12 C18,10.3431458 16.6568542,9 15,9 Z" fill="#FFFFFF" fillRule="nonzero"></path>
                             </g>
                           </g>
                         </g>
                       </g>
                     </svg>

                     <div className={styles.neighborsTooltipItemText}>
                       <OverflowToolTip text={`${neighborItem.name} ${neighborItem.surname}`} />
                     </div>
                   </Box>
                   <div className={styles.neighborsTooltipSitIcon}>
                     <img height={16} src={SitIcon} width={16} />
                     {deskName}
                   </div>
                 </div>
               );
             }) : (
               <div>
                 <Trans>No Neighbours</Trans>
               </div>
             )
         }
       </Box>
     </div>
    </Popup>
  );
}
