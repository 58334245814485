import Box from 'components/_legacy/Box';
import CheckboxCustom from 'components/_legacy/CheckBoxCustom';
import clsx from 'clsx';
import SearchIcon from 'components/_legacy/Icons/Search';
import styles from './styles.module.scss';
import Text from 'components/_legacy/Text';
import { FormControlLabel, TextField } from '@material-ui/core';
import {initialFilters, selectUserManagementCountries, setUserManagementData} from 'Admin/Store/userManagement';
import { Trans, t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useTypedSelector } from 'store/_legacy/Redux/store';
import FilterAccordion from "components/_legacy/Filters/FilterAccordion";

export default function CountriesFilter() {
  const dispatch = useDispatch();

  const [search, setSearch] = useState('');

  const { adminUserManagement } = useTypedSelector(state => state);

  const { filters: reduxFilters } = adminUserManagement;

  const countriesCount = reduxFilters.countries.length;
  const countries = selectUserManagementCountries(adminUserManagement, search);

  const onResetFilter = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => {
    event?.stopPropagation();

    dispatch(setUserManagementData({
      filters: {
        ...reduxFilters,
        countries: initialFilters.countries,
      },
    }));

    setSearch('');
  };

  return (
    <Box display="flex" justifyContent="between" marginTop={10}>
      <FilterAccordion
        headerName={t`Country`}
        localCounter={countriesCount}
        resetFilter={onResetFilter}
      >
        <Box direction="column" display="flex" width="100%">
          <TextField
            InputProps={{
              startAdornment: <SearchIcon color="#5E5E5E" />,
            }}
            // className={`input input--default input--search ${styles.search}`}
            className={`input input--default input--search`}
            defaultValue={''}
            fullWidth
            onChange={(event) => setSearch(event.target.value)}
            placeholder="Search"
            value={search}
            variant="outlined"
          />

          <Box className={styles.filtersFormControlWrapper} display="grid" >
            {countries.length ? (
              <>
                {countries.map(((country, index) => {
                  const isChecked = adminUserManagement.filters.countries.some(c => c === country);

                  return (
                    <FormControlLabel
                      className={clsx(isChecked && styles.checkedLabel)}
                      control={(
                        <CheckboxCustom
                          checked={isChecked}
                          classes={{ checked: styles.checked }}
                          name={country}
                          onChange={() => {
                            let newCountries = [...reduxFilters.countries].filter(c => c === country);

                            if (isChecked) {
                              newCountries = newCountries.filter(c => c !== country);
                            } else {
                              newCountries.push(country);
                            }

                            dispatch(setUserManagementData({
                              filters: {
                                ...reduxFilters,
                                countries: newCountries,
                              },
                            }));
                          }}
                        />
                      )}
                      key={`${country} ${index}`}
                      label={country}
                    />
                  );
                }))}
              </>
            ) : (
              <Text align="center" color="lightGray" size="md">
                <Trans>
                  No countries found
                </Trans>
              </Text>
            )}
          </Box>
        </Box>
      </FilterAccordion>
    </Box>
  );
}
