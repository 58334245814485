import Box from "components/_legacy/Box";
import Button from "components/_legacy/Button";
import {FilterIcon} from "../../Icons/FilterIcon";
import Text from "components/_legacy/Text";
import {Trans} from "@lingui/macro";
import styles from "./styles.module.scss";
import Menu from "@material-ui/core/Menu";
import {useState} from "react";

interface Props {
  children: React.PropsWithChildren<any>;
  onSubmitFilters: Function;
  onResetFilters: Function;
  setSavedData: Function;
  filterCount: number;
}

export default function FilterModal(props: Props) {
  const { children, onSubmitFilters, onResetFilters, setSavedData, filterCount } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const onFiltersButtonClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setSavedData();
  };

  const onFiltersModalClose = () => {
    setAnchorEl(null);
  };

  const onSubmit = () => {
    onSubmitFilters();
    onFiltersModalClose();
  };

  return (
    <Box>
      <Button
        aria-controls="filters"
        aria-haspopup="true"
        onClick={onFiltersButtonClick}
        size="xs"
        withShadow
      >
        <Box alignItems="center" display="flex">
          <FilterIcon margin={"0 5px 0 0"} />

          <Text color="white" size="md">
            <Trans>Filters</Trans>
          </Text>

          {
            filterCount ?
              <div className={styles.filtersCounter}>
                {filterCount}
              </div> : null
          }
        </Box>
      </Button>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        className={styles.menuContainer}
        classes={{
          paper: styles.menu,
        }}
        elevation={0}
        getContentAnchorEl={null}
        id="customized-menu"
        keepMounted
        onClose={onFiltersModalClose}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box>
          <Box alignItems="center" display="flex" justifyContent="between">
            <Box alignItems="center" display="flex" gap={10}>
              <Box
                alignItems="center"
                backgroundColor="#F4F4F4"
                borderRadius={8}
                display="flex"
                justifyContent="center"
                padding={4}
              >
                <FilterIcon color={'#5E5E5E'} />
              </Box>

              <Text weight="semi-bold">
                <Trans>Filters</Trans>
              </Text>
              {
                filterCount ?
                  <div className={styles.filtersCounter}>
                    {filterCount}
                  </div> : null
              }
            </Box>

            <Box alignItems="center" display="grid" gap={10} gridTemplateColumns="auto auto">
              <Button onClick={() => onResetFilters()} size="xsm" type="clear">
                <Trans>Reset</Trans>
              </Button>

              <Button className={styles.buttonDone} onClick={() => onSubmit()} withShadow>
                <Trans>Done</Trans>
              </Button>
            </Box>
          </Box>

          {children}
        </Box>
      </Menu>
    </Box>
  );
}
