export enum DeskStatusEnum {
  Available = "AVAILABLE",
  Unavailable = "UNAVAILABLE",
  ApprovalRequired = "APPROVAL_REQUIRED",
  DeleteInProgress = "DELETE_IN_PROGRESS",
  Deleted = "DELETED",
}

export type DeskStatus =
  | DeskStatusEnum.Available
  | DeskStatusEnum.Unavailable
  | DeskStatusEnum.ApprovalRequired
  | DeskStatusEnum.DeleteInProgress
  | DeskStatusEnum.Deleted
