import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

export const reactPlugin = new ReactPlugin();

export const applicationInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRING,
    extensions: [reactPlugin],
    disableCookiesUsage: true,
    disableCorrelationHeaders: true,
    disableFetchTracking: true,
  },
});

applicationInsights.addTelemetryInitializer((item) => {
  item.data = { ...(item.data || {}), appName: "together-web" };

  return true;
});

if (process.env.NODE_ENV !== "test") {
  applicationInsights.loadAppInsights();
}
