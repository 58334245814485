import Api from 'store/_legacy/Services/Api';
import { CancelBookingResponseFake, CancelVisitFakeResponse } from './models';
import { matchPath } from 'react-router-dom';
import {
  all,
  call,
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import {
  CANCEL_BOOKING,
  CANCEL_BOOKING_FAIL,
  CANCEL_BOOKING_SUCCESS,
  CANCEL_VISIT,
  CANCEL_VISIT_FAIL,
  CANCEL_VISIT_SUCCESS,
  CREATE_BOOKING,
  CREATE_BOOKING_FAIL,
  CREATE_BOOKING_SUCCESS,
  CREATE_VISIT,
  CREATE_VISIT_FAIL,
  CREATE_VISIT_SUCCESS,
  CancelBooking,
  CancelVisit,
  CreateBooking,
  CreateVisit,
  GET_BOOKINGS,
  GET_BOOKINGS_FAIL,
  GET_BOOKINGS_SUCCESS,
  GET_BOOKING_BY_ID,
  GET_BOOKING_BY_ID_FAIL,
  GET_BOOKING_BY_ID_SUCCESS,
  GET_HOME_VISITS,
  GET_HOME_VISITS_FAIL,
  GET_HOME_VISITS_SUCCESS,
  GET_PAST_BOOKINGS_AND_VISITS,
  GET_PAST_BOOKINGS_AND_VISITS_FAIL,
  GET_PAST_BOOKINGS_AND_VISITS_SUCCESS,
  GET_PREVIOUS_BOOKINGS,
  GET_PREVIOUS_BOOKINGS_FAIL,
  GET_PREVIOUS_BOOKINGS_SUCCESS,
  GET_PREVIOUS_VISITS,
  GET_PREVIOUS_VISITS_FAIL,
  GET_PREVIOUS_VISITS_SUCCESS,
  GET_UPCOMING_BOOKINGS_AND_VISITS,
  GET_UPCOMING_BOOKINGS_AND_VISITS_FAIL,
  GET_UPCOMING_BOOKINGS_AND_VISITS_SUCCESS,
  GetBookingById,
  GetBookings,
  GetPreviousBookings,
  GetPreviousVisits,
  GetUpcomingBookingsAndVisits,
  GetVisits,
} from '.';
import { isUUID } from 'class-validator';

function* cancelBookingSaga(action: CancelBooking): any {
  try {
    const match = matchPath<{ bookingId: string }>(action.payload.request.url, {
      path: '/api/bookings/:bookingId',
    });

    const payload: CancelBookingResponseFake = { bookingId: match?.params.bookingId ?? '' };

    yield put({ type: CANCEL_BOOKING_SUCCESS, payload });
  } catch (e) {
    yield put({ type: CANCEL_BOOKING_FAIL, payload: e });
  }
}

function* createBookingSaga(action: CreateBooking): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: CREATE_BOOKING_SUCCESS, payload });
  } catch (e) {
    yield put({ type: CREATE_BOOKING_FAIL, payload: e });
  }
}

function* cancelVisitSaga(action: CancelVisit): any {
  try {
    yield call(Api, action);

    const match = matchPath<{ appointmentId: string }>(action.payload.request.url, {
      path: '/api/bookings/appointments/:appointmentId',
    });

    const payload: CancelVisitFakeResponse = { appointmentId: match?.params.appointmentId ?? '' };

    yield put({ type: CANCEL_VISIT_SUCCESS, payload });
  } catch (e) {
    yield put({ type: CANCEL_VISIT_FAIL, payload: e });
  }
}

function* createVisitSaga(action: CreateVisit): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: CREATE_VISIT_SUCCESS, payload });
  } catch (e) {
    yield put({ type: CREATE_VISIT_FAIL, payload: e });
  }
}

function* getBookingByIdSaga(action: GetBookingById): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: GET_BOOKING_BY_ID_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_BOOKING_BY_ID_FAIL, payload: e });
  }
}

function* getBookingsSaga(action: GetBookings): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: GET_BOOKINGS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_BOOKINGS_FAIL, payload: e });
  }
}

function* getPreviousBookingsSaga(action: GetPreviousBookings): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: GET_PREVIOUS_BOOKINGS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_PREVIOUS_BOOKINGS_FAIL, payload: e });
  }
}

function* getVisitsSaga(action: GetVisits): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: GET_HOME_VISITS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_HOME_VISITS_FAIL, payload: e });
  }
}

function* getPreviousVisitsSaga(action: GetPreviousVisits): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: GET_PREVIOUS_VISITS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_PREVIOUS_VISITS_FAIL, payload: e });
  }
}

function *getUpcomingBookingsAndVisits(action: GetUpcomingBookingsAndVisits): any {
  try {
    const { userId: selectedUserId, date } = action.payload;
    const parameters = {
      selectedUserId,
      limit: 20,
      page: 1,
      dateFrom: date.toISOString(),
      order: "ASC",
    };

    if (!parameters.selectedUserId || !isUUID(parameters.selectedUserId)) {
      delete parameters.selectedUserId;
    }

    const bookingsRequest = {
      method: "GET",
      url: "/api/bookings",
      data: parameters,
    };
    const visitsRequest = {
      method: "GET",
      url: "/api/bookings/appointments",
      data: parameters,
    };

    const [bookings, visits] = yield all([
      call(Api, { type: GET_UPCOMING_BOOKINGS_AND_VISITS, payload: { request: bookingsRequest } }),
      call(Api, { type: GET_UPCOMING_BOOKINGS_AND_VISITS, payload: { request: visitsRequest } }),
    ]);
    
    yield put({
      type: GET_UPCOMING_BOOKINGS_AND_VISITS_SUCCESS,
      payload: {
        bookings: { success: true, data: bookings.data },
        visits: { success: true, data: visits.data },
      },
    });
  } catch(e) {
    yield put({ type: GET_UPCOMING_BOOKINGS_AND_VISITS_FAIL });
  }
}

function *getPastBookingsAndVisits(action: GetUpcomingBookingsAndVisits): any {
  try {
    const { userId: selectedUserId, date } = action.payload;
    const parameters = {
      selectedUserId,
      limit: 20,
      page: 1,
      dateTo: date.toISOString(),
      order: "DESC",
    };

    if (!parameters.selectedUserId || !isUUID(parameters.selectedUserId)) {
      delete parameters.selectedUserId;
    }

    const bookingsRequest = {
      method: "GET",
      url: "/api/bookings",
      data: parameters,
    };
    const visitsRequest = {
      method: "GET",
      url: "/api/bookings/appointments",
      data: parameters,
    };

    const [bookings, visits] = yield all([
      call(Api, { type: GET_PAST_BOOKINGS_AND_VISITS, payload: { request: bookingsRequest } }),
      call(Api, { type: GET_PAST_BOOKINGS_AND_VISITS, payload: { request: visitsRequest } }),
    ]);
    
    yield put({
      type: GET_PAST_BOOKINGS_AND_VISITS_SUCCESS,
      payload: {
        bookings: { success: true, data: bookings.data },
        visits: { success: true, data: visits.data },
      },
    });
  } catch(e) {
    yield put({ type: GET_PAST_BOOKINGS_AND_VISITS_FAIL });
  }
}

export default [
  takeEvery(GET_BOOKINGS, getBookingsSaga),
  takeEvery(GET_PREVIOUS_BOOKINGS, getPreviousBookingsSaga),
  takeEvery(GET_HOME_VISITS, getVisitsSaga),
  takeEvery(GET_PREVIOUS_VISITS, getPreviousVisitsSaga),
  takeLatest(CANCEL_BOOKING, cancelBookingSaga),
  takeLatest(CREATE_BOOKING, createBookingSaga),
  takeLatest(CANCEL_VISIT, cancelVisitSaga),
  takeLatest(CREATE_VISIT, createVisitSaga),
  takeLatest(GET_BOOKING_BY_ID, getBookingByIdSaga),
  takeLatest(GET_UPCOMING_BOOKINGS_AND_VISITS, getUpcomingBookingsAndVisits),
  takeLatest(GET_PAST_BOOKINGS_AND_VISITS, getPastBookingsAndVisits),
];
