import {useTypedSelector} from "store/_legacy/Redux/store";
import {useState} from "react";
import {Accordion, AccordionDetails, AccordionSummary} from "@material-ui/core";
import styles from "./styles.module.scss";
import Box from "components/_legacy/Box";
import Text from "components/_legacy/Text";
import FiltersCounter from "../FiltersCounter";

interface Props {
  children: React.PropsWithChildren<any>;
  headerName: string;
  resetFilter: Function;
  localCounter: number;
}

export default function FilterAccordion(props: Props) {
  const { config } = useTypedSelector(state => state);
  const { children, headerName, resetFilter, localCounter } = props;
  const [expanded, setExpanded] = useState(false);
  const showReset = !!localCounter;

  const onToggleExpanded = () => {
    setExpanded(expanded => !expanded);
  };

  const onResetFilter = (e: any) => {
    e.stopPropagation(); // to not handle location link
    resetFilter();
  };

  return (
    <Accordion
      classes={{ expanded: styles.accordionExpanded, root: styles.accordion }}
      expanded={expanded}
    >
      <AccordionSummary
        aria-controls="panel1a-content"
        classes={{
          content: styles.accordionSummaryContent,
          expandIcon: styles.accordionSummaryIcon,
          root: styles.accordionSummary,
        }}
        expandIcon={
          <svg height="18px" version="1.1" viewBox="0 0 10 18" width="10px">
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
              <g transform="translate(-1390.000000, -176.000000)">
                <rect fill="transparent" height="900" width="1440" x="0" y="0"></rect>
                <g fill={config.theme.primary} id="Header" transform="translate(1030.000000, 171.000000)">
                  <g id="icons/arrow-left" transform="translate(360.000000, 5.000000)">
                    <path
                      d="M8.29396103,1.29289322 C8.68448532,0.902368927 9.3176503,0.902368927 9.70817459,1.29289322 C10.0986989,1.68341751 10.0986989,2.31658249 9.70817459,2.70710678 L3.34421356,9.07106781 L9.70817459,15.4350288 C10.0986989,15.8255531 10.0986989,16.4587181 9.70817459,16.8492424 C9.3176503,17.2397667 8.68448532,17.2397667 8.29396103,16.8492424 L1.22289322,9.77817459 C0.832368927,9.3876503 0.832368927,8.75448532 1.22289322,8.36396103 L8.29396103,1.29289322 Z"
                      transform="translate(5.465534, 9.071068) scale(-1, 1) translate(-5.465534, -9.071068) "
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        }
        onClick={onToggleExpanded}
      >
        <Box alignItems="center" display="flex" justifyContent="between" width="100%">
          <Text size="md" weight="semi-bold">
            {headerName}
          </Text>
          {showReset &&
            <FiltersCounter count={localCounter} onClick={(e: any) => onResetFilter(e)} />
          }
        </Box>
      </AccordionSummary>
      <AccordionDetails
        classes={{
          root: styles.accordionDetails,
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
}
