import React from "react";
import { Element } from "slate";
import { EditorElementType } from "../enums";

export const reactElementTypeToEditorElement = (type: React.ElementType): Partial<Element> | undefined => {
  switch(type) {
    case "p": {
      return { type: EditorElementType.PARAGRAPH };
    }
    case "h1":
    case "h2":
    case "h3":
    case "h4":
    case "h5":
    case "h6": {
      const types: React.ElementType[] = ["h1", "h2", "h3", "h4", "h5", "h6"];

      return { type: EditorElementType.HEADING, level: (types.indexOf(type) || 0) + 1 };
    }
    default: {
      return undefined;
    }
  }
};
