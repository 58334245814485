import Box from 'components/_legacy/Box';
import Button from 'components/_legacy/Button';
import Heading from 'components/_legacy/Heading';
import styles from './styles.module.scss';
import { Trans } from '@lingui/macro';
import { useTypedSelector } from 'store/_legacy/Redux/store';

export default function Error404() {
  const { theme } = useTypedSelector(state => state.config);

  return (
    <Box
      alignItems="center"
      backgroundColor={theme.primary}
      borderRadius={80}
      className={styles.error404}
      direction="column"
      display="flex"
      justifyContent="center"
      margin="145px auto"
      maxWidth={974}
      padding="150px 50px"
    >
      <Heading className={styles.h1}>
        404
      </Heading>

      <Box marginBottom={23} marginTop={16}>
        <Heading align="center" color="white" size="md">
          <Trans>
            Something went wrong. Page not found!
          </Trans>
        </Heading>
      </Box>

      <Button size="sm" to="/" type="white" withShadow={false}>
        <Trans>
          Back to Home Page
        </Trans>
      </Button>
    </Box>
  );
}
