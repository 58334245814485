import { Trans } from "@lingui/macro";

import { isNumberString } from "class-validator";
import Box from "components/_legacy/Box";
import Text from "components/_legacy/Text";
import TextField from "components/_legacy/TextField";

interface Props {
  value: number | null;
  setValue: React.Dispatch<React.SetStateAction<number | null>>;
}

export default function Capacity(props: Props) {
  const { value, setValue } = props;
  const capacityValue = value ? `${value}` : "";
  const handleCapacityChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    const { value = "" } = event.target;

    if (!value) {
      setValue(null);
    }

    if (isNumberString(value)) {
      const capacityIntValue = Number.parseInt(value);

      if (capacityIntValue > 0) {
        setValue(capacityIntValue);
      }
    }
  };

  return (
    <Box>
      <Box marginBottom={4}>
        <Text color="black" size="md" weight="regular">
          <Trans>Capacity:</Trans>
        </Text>
      </Box>
      <Text color="gray" size="md">
        <TextField
          className={`input input--default input--inline`}
          id="meeting-capacity"
          onChange={handleCapacityChange}
          placeholder="Not set"
          value={capacityValue}
        />
      </Text>
    </Box>
  );
}
