import React from "react";
import { FileUploadProps } from "./types";
import { UploadBox } from "../box";
import { Typography, useTheme } from "@mui/material";
import { ProgressOverlay } from "../progress";
import { t } from "@lingui/macro";
import { FileUploadButton } from "../button";

export const FileUpload: React.FC<FileUploadProps> = (props) => {
  const { isUploading, multiple, accept, onChange } = props;
  const { palette } = useTheme();

  return (
    <UploadBox
      alignItems="center"
      bgcolor={palette.grey[100]}
      border="1px dashed"
      borderColor={palette.grey[400]}
      borderRadius={1}
      display="flex"
      flexDirection="column"
      mb={2}
      padding={2}
      position="relative"
      uploadOptions={{ accept, onChange }}
    > 
      {isUploading ? <ProgressOverlay bgcolor={palette.grey[100]} borderRadius={1} /> : undefined}
      <Typography color={palette.grey[700]} fontSize={14} mb={1} mt={1}>{t`Drop files here or click "Browse Files"`}</Typography>
      <FileUploadButton uploadOptions={{ accept, multiple, onChange }} variant="text">
        {t`Browse files`}
      </FileUploadButton>
    </UploadBox>
  );
};
