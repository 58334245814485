import React from "react";
import { EditorElementButtonProps } from "./types/editor-element-button-props.type";
import { EditorToolbarButton } from "./editor-toolbar-button";
import { isEditorElementActive, toggleEditorElement } from "./utils";
import { useSlate } from "slate-react";

export const EditorElementButton: React.FC<EditorElementButtonProps> = (props) => {
  const { element, Icon, ...rest } = props;
  const editor = useSlate();

  return (
    <EditorToolbarButton
      Icon={Icon}
      active={isEditorElementActive(editor, element)}
      onMouseDown={() => toggleEditorElement(editor, element)}
      {...rest}
    />
  );
};
