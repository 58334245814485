import {useDispatch} from "react-redux";
import {editLocation} from "../../../../App/Store/Locations/locationsDuck";
import Box from "components/_legacy/Box";
import styles from "../styles.module.scss";
import {Trans} from "@lingui/macro";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { AdminLocation } from "../../../../App/Store/Locations/locationsDuck/models";

interface Props {
  selectedLocation: AdminLocation;
}

export default function LocationNotificationSwitcher(props: Props) {
  const dispatch = useDispatch();
  const { selectedLocation } = props;
  const notificationChecked = selectedLocation ? selectedLocation.uploadDocNotification : false;

  const onChangeRequiredNotifications = (e: any) => {
    if (selectedLocation) {
      dispatch(editLocation({
        location: {
          ...selectedLocation,
          uploadDocNotification: e.target.checked,
        },
        locationId: selectedLocation.id,
      }));
    }
  };

  return (
    <Box alignItems="center" display="flex">
      <div className={styles.notificationSwitcher}>
        <Trans>Don’t allow booking without an approved document</Trans>
      </div>

      <FormControlLabel
        control={
          <Switch
            checked={notificationChecked}
            className="switcher switcher--default"
            data-testid="location-settings-toggleRequiredDocument"
            name={'test'}
            onChange={onChangeRequiredNotifications}
          />
        }
        label=""
      />
    </Box>
  );
}
