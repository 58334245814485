import { t } from "@lingui/macro";
import { Box, Button, Divider, IconButton, IconButtonProps, List, ListItem, ListItemButton, Pagination, Skeleton, Typography, useTheme } from "@mui/material";
import { CampaignPolicyWrapper, ConfirmationDialog, ListHeader, ListHeaderLabel, Tab, Tabs, TogetherDelete, useToast } from "components";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { CommPolicy, CommPolicyRule, CommPolicyRuleType, CommPolicyType, User, adminSlice, selectAdminCurrentCampaignPolicy, useCurrentCampaignPolicyRules, useDeleteCommPolicyByIdMutation, useLazyGetCommPoliciesQuery } from "store";
import { MarkOptional } from "ts-essentials";

export const CampaignBrandPolicyUsers: React.FC = () => {
  const { palette, background } = useTheme();
  const dispatch = useDispatch();
  const [policy, setPolicy] = useState<CommPolicy>();
  const [willDelete, setWillDelete] = useState(false);
  const toast = useToast();
  const history = useHistory();
  const { policyId } = useParams<{ policyId: string }>();
  const currentPolicy = useSelector(selectAdminCurrentCampaignPolicy);
  const [page, setPage] = useState(1);
  const [deletePolicyById] = useDeleteCommPolicyByIdMutation();
  const [triggerGetPoliciesQuery, getPoliciesQuery] = useLazyGetCommPoliciesQuery();
  const { data: getPoliciesResponse, isLoading } = getPoliciesQuery;
  const { items: policies, meta } = getPoliciesResponse || {};

  useEffect(() => {
    triggerGetPoliciesQuery({
      page,
      limit: 15,
      filter: { type: CommPolicyType.USER, "parent.id": policyId },
      include: ["user", "parent"],
      orderBy: "desc:createdAt",
    }, true);
  }, [page, policyId, triggerGetPoliciesQuery]);

  const handleDeleteConfirm = () => {
    if (!policy) {
      return;
    }

    void (async () => {
      const response = await deletePolicyById({ policyId: policy.id, parentId: policyId });

      if ("error" in response) {
        toast.showToast({ severity: "error", message: t`Failed to delete policy, please try again.` });
      } else {
        toast.showToast({ severity: "success", message: t`Policy was deleted.` });
      }

      setPolicy(undefined);
    })();
  };

  return (
    <>
      <CampaignPolicyWrapper policyId={policyId} type={CommPolicyType.BRAND}>
        <Box display="flex" flexDirection="column" gap={2}>
          <Tabs inline sx={{ padding: 0, bgcolor: "#fff" }} value={1}>
            <Tab
              data-cid="brand-button"
              label={t`Brand`}
              onClick={() => history.push(`/admin/campaigns/policies/${policyId || currentPolicy?.id}`)}
              sx={{ color: "#000", minWidth: 0, "&.Mui-selected": { bgcolor: background.blue.main } }}
              value={0}
            />
            <Tab
              data-cid="user-policy-button"
              label={t`User policy`}
              onClick={() => history.push(`/admin/campaigns/policies/${policyId || currentPolicy?.id}/users`)}
              sx={{ color: "#000", minWidth: 0, "&.Mui-selected": { bgcolor: background.blue.main } }}
              value={1}
            />
          </Tabs>
          <Box>
            {currentPolicy?.isEditable ? (
              <Button component={Link} to={`/admin/campaigns/policies/${policyId}/users/new?reset=true`}>{t`Add Policy`}</Button>
            ) : undefined}
            <ListHeader>
              <ListHeaderLabel sx={{ width: 420 }}>{t`Name`}</ListHeaderLabel>
              <ListHeaderLabel sx={{ flex: 1 }}>{t`Email`}</ListHeaderLabel>
              {currentPolicy?.isEditable ? <Box component="span" width={30} /> : undefined}
            </ListHeader>
            {isLoading ? (
              <Box minHeight={645}>
                <Skeleton height={45} sx={{ borderRadius: 0 }} variant="rectangular" />
                <Divider sx={{ opacity: 0.6 }} />
                <Skeleton height={45} sx={{ borderRadius: 0 }} variant="rectangular" />
                <Divider sx={{ opacity: 0.6 }} />
                <Skeleton height={45} sx={{ borderRadius: 0 }} variant="rectangular" />
              </Box>
            ) : policies?.length ? (
              <List disablePadding sx={{ mb: 2 }}>
                {policies?.map((policy) => {
                  const handleDeleteClick: IconButtonProps["onClick"] = (event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    setPolicy(policy);
                    setWillDelete(true);
                  };

                  return (
                    <ListItem disablePadding divider key={policy.id} sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.05)" }}>
                      <ListItemButton onClick={() => history.push(`/admin/campaigns/policies/${policyId}/users/${policy.id}`)} sx={{ px: 1, py: 1.5, gap: 1, flex: 1 }}>
                        <Typography fontSize={14} noWrap title={policy.user?.name} width={420}>{policy.user?.name}</Typography>
                        <Typography flex={1} fontSize={14} noWrap title={policy.user?.email}>{policy.user?.email}</Typography>
                        {currentPolicy?.isEditable ? (
                          <IconButton color="primary" onClick={handleDeleteClick} size="small">
                            <TogetherDelete sx={{ fill: palette.primary.main, width: 16, height: 16 }} />
                          </IconButton>
                        ) : undefined}
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            ) : (
              <Typography color={palette.grey[700]} fontSize={14} my={4} textAlign="center">
                {t`No user policies yet.`}
              </Typography>
            )}
            {(meta?.pages || 0) > 1 ? (
              <Pagination count={meta?.pages} onChange={(_, page) => setPage(page)} page={page} shape="rounded" />
            ) : undefined}
          </Box>
        </Box>
      </CampaignPolicyWrapper>
      <ConfirmationDialog
        description={t`Are you sure you want to delete policy for user "${policy?.user?.name}"?`}
        onClose={() => setWillDelete(false)}
        onConfirm={handleDeleteConfirm}
        open={!!willDelete}
        title={t`Delete brand policy`}
      />
    </>
  );
};
