import { User } from "../../together";
import { commApi } from "../comm-api";

const users = commApi.injectEndpoints({
  endpoints: (builder) => ({
    importUsers: builder.mutation<{ items: User[] }, File>({
      query: (file) => {
        const body = new FormData();

        body.append("file", file);

        return { body, url: "/api/v1/users/import", method: "POST" };
      },
    }),
  }),
});

export const { useImportUsersMutation } = users;
