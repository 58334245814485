import { ActionPayload, BaseErrorResponse, BaseResponse } from 'store/_legacy/Models/ReduxModels';
import { BookingModel } from 'App/Store/Bookings/bookingDuck/models';
import {
  GET_BOOKING_BY_ID_SUCCESS,
  GetBookingByIdSuccess,
  SET_BOOKINGS_DATA,
  SetBookingsData,
  } from 'App/Store/Bookings/bookingDuck';
import { t } from '@lingui/macro';
import {
  AddDeskToSavedListRequest,
  AddDeskToSavedListResponse,
  GetSavedDesksResponse,
  RemoveDeskFromSavedListResponseFake,
  SavedDeskModel,
} from './models';

export const ADD_DESK_TO_SAVED_LIST = 'ADD_DESK_TO_SAVED_LIST';
export const ADD_DESK_TO_SAVED_LIST_FAIL = 'ADD_DESK_TO_SAVED_LIST_FAIL';
export const ADD_DESK_TO_SAVED_LIST_SUCCESS = 'ADD_DESK_TO_SAVED_LIST_SUCCESS';

export const GET_SAVED_DESKS = 'GET_SAVED_DESKS';
export const GET_SAVED_DESKS_FAIL = 'GET_SAVED_DESKS_FAIL';
export const GET_SAVED_DESKS_SUCCESS = 'GET_SAVED_DESKS_SUCCESS';

export const REMOVE_DESK_FROM_SAVED_LIST = 'REMOVE_DESK_FROM_SAVED_LIST';
export const REMOVE_DESK_FROM_SAVED_LIST_FAIL = 'REMOVE_DESK_FROM_SAVED_LIST_FAIL';
export const REMOVE_DESK_FROM_SAVED_LIST_SUCCESS = 'REMOVE_DESK_FROM_SAVED_LIST_SUCCESS';

export interface AddDeskToSavedList {
  type: typeof ADD_DESK_TO_SAVED_LIST;
  payload: ActionPayload<AddDeskToSavedListRequest>;
}
interface AddDeskToSavedListFail {
  type: typeof ADD_DESK_TO_SAVED_LIST_FAIL;
  payload: BaseErrorResponse;
}
export interface AddDeskToSavedListSuccess {
  type: typeof ADD_DESK_TO_SAVED_LIST_SUCCESS;
  payload: BaseResponse<AddDeskToSavedListResponse>;
}

export interface GetSavedDesks {
  type: typeof GET_SAVED_DESKS;
  payload: ActionPayload<{}>;
  clearData?: boolean;
}
interface GetSavedDesksFail {
  type: typeof GET_SAVED_DESKS_FAIL;
  payload: BaseErrorResponse;
}
interface GetSavedDesksSuccess {
  type: typeof GET_SAVED_DESKS_SUCCESS;
  payload: BaseResponse<GetSavedDesksResponse>;
}

export interface RemoveDeskFromSavedList {
  type: typeof REMOVE_DESK_FROM_SAVED_LIST;
  payload: ActionPayload<{}>;
}
interface RemoveDeskFromSavedListFail {
  type: typeof REMOVE_DESK_FROM_SAVED_LIST_FAIL;
  payload: BaseErrorResponse;
}
export interface RemoveDeskFromSavedListSuccess {
  type: typeof REMOVE_DESK_FROM_SAVED_LIST_SUCCESS;
  payload: RemoveDeskFromSavedListResponseFake;
}

type Actions = AddDeskToSavedList
  | AddDeskToSavedListFail
  | AddDeskToSavedListSuccess
  | GetBookingByIdSuccess
  | GetSavedDesks
  | GetSavedDesksFail
  | GetSavedDesksSuccess
  | RemoveDeskFromSavedList
  | RemoveDeskFromSavedListFail
  | RemoveDeskFromSavedListSuccess
  | SetBookingsData;

export interface State {
  activeBooking: BookingModel | undefined;
  error: string;
  loading: boolean;
  savedDesks: {
    [key: string]: SavedDeskModel;
  };
}

const initialState: State = {
  activeBooking: undefined,
  error: '',
  loading: false,
  savedDesks: {},
};

export default function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ADD_DESK_TO_SAVED_LIST:
      return {
        ...state,
      };
    case ADD_DESK_TO_SAVED_LIST_FAIL:
      return {
        ...state,
        error: t`There was an error adding the desk to saved list. Please try again.`,
      };
    case ADD_DESK_TO_SAVED_LIST_SUCCESS: {
      const activeBooking = state.activeBooking ? { ...state.activeBooking } : undefined;
      const savedDesk = action.payload.data.result.data;

      return {
        ...state,
        error: '',
        activeBooking,
        savedDesks: {
          ...state.savedDesks,
          [savedDesk.desk.id]: savedDesk,
        },
      };
    }
    
    case GET_BOOKING_BY_ID_SUCCESS: {
      const booking = action.payload.data.result.data;
      
      return {
        ...state,
        activeBooking: booking,
      };
    }

    case GET_SAVED_DESKS:
      return {
        ...state,
        error: '',
        loading: true,
        savedDesks: action.clearData ? {} : state.savedDesks,
      };
    case GET_SAVED_DESKS_FAIL:
      return {
        ...state,
        error: t`There was an error. Please try again.`,
        loading: false,
      };
    case GET_SAVED_DESKS_SUCCESS: {
      const savedDesksArray = action.payload.data.result.data;
      const savedDesks: {
        [key: string]: SavedDeskModel;
      } = {};

      savedDesksArray.forEach(savedDesk => {
        savedDesks[savedDesk.desk.id] = savedDesk;
      });

      return {
        ...state,
        error: '',
        loading: false,
        savedDesks,
      };
    }

    case REMOVE_DESK_FROM_SAVED_LIST:
      return {
        ...state,
        error: '',
      };
    case REMOVE_DESK_FROM_SAVED_LIST_FAIL:
      return {
        ...state,
        error: t`There was an error removing the desk from saved list. Please try again.`,
      };
    case REMOVE_DESK_FROM_SAVED_LIST_SUCCESS: {
      const activeBooking = state.activeBooking ? { ...state.activeBooking } : undefined;
      const savedDesks = { ...state.savedDesks };

      delete savedDesks[action.payload.deskId];

      return {
        ...state,
        error: '',
        activeBooking,
        savedDesks,
      };
    }

    case SET_BOOKINGS_DATA: {
      return {
        ...state,
        activeBooking: action.payload.activeBooking ?? state.activeBooking,
      };
    }

    default:
      return state;
  }
}

// Actions
export function addDeskToSavedList(data: AddDeskToSavedListRequest): AddDeskToSavedList {
  return {
    type: ADD_DESK_TO_SAVED_LIST,
    payload: {
      request: {
        method: 'POST',
        url: `/api/desks/saved`,
        data,
      },
    },
  };
}

export function removeDeskFromSavedList(deskId: string, userId: string): RemoveDeskFromSavedList {
  return {
    type: REMOVE_DESK_FROM_SAVED_LIST,
    payload: {
      additionalData: {
        deskId,
      },
      request: {
        method: 'DELETE',
        url: `/api/desks/${deskId}/users/${userId}/saved`,
      },
    },
  };
}

export function isDeskSaved(state: State, deskId: string): boolean {
  const desk = state.savedDesks[deskId];

  return Boolean(desk);
}