import accordionStyles from './styles.module.scss';
import Box from 'components/_legacy/Box';
import Button from 'components/_legacy/Button';
import datetimeIcon from '../assets/datetime.png';
import Divider from 'components/_legacy/Divider';
import styles from '../styles.module.scss';
import Text from 'components/_legacy/Text';
import VisitCustom from '../VisitCustom';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import {getClosestAvailableDay, getClosestStartDate} from 'App/Functions/Helpers';
import { setCreateNewVisitData } from 'App/Store/Bookings/createNewVisitDuck';
import { Trans } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'store/_legacy/Redux/store';

/**
 * Calendar component to select date and time ranges.
 */
export default function VisitCalendar() {
  const dispatch = useDispatch();
  const { locations, createNewVisit } = useTypedSelector(state => state);
  const { locationId } = createNewVisit;
  const selectedLocation = locations.locations.find(location => location.id === locationId);

  const handleTodayClick = (e: any) => {
    e.stopPropagation(); // to not handle accordion

    const closestStartDay = selectedLocation ? getClosestStartDate(selectedLocation?.allowedBookingDayType) : new Date();
    const closestDay = getClosestAvailableDay(closestStartDay, locations.locationDisabledDays);

    dispatch(setCreateNewVisitData({ dateFrom: closestDay, dateTo: closestDay }));
  };

  return (
    <Accordion
      classes={{ expanded: accordionStyles.accordionExpanded, root: accordionStyles.accordion }}
      expanded
    >
      <AccordionSummary
        aria-controls="panel1a-content"
        classes={{
          content: accordionStyles.accordionSummaryContent,
          expandIcon: accordionStyles.accordionSummaryIcon,
          root: accordionStyles.accordionSummary,
        }}
      >
        <Box className={styles.calendar} height={35} width={'100%'}>
          <Box alignItems="center" display="flex" height={'100%'} justifyContent="between">
            <Box alignItems="center" display="flex">
              <Box className={styles.datetimeIconContainer} marginEnd={10}>
                <img src={datetimeIcon} />
              </Box>

              <Text weight="semi-bold">
                <Trans>Select date and time</Trans>
              </Text>
            </Box>

            <Box marginRight={10}>
              <Button noPaddingX onClick={handleTodayClick} type="clear">
                <Trans>Reset</Trans>
              </Button>
            </Box>
          </Box>
        </Box>
      </AccordionSummary>

      <Box marginTop={7}>
        <Divider />
      </Box>

      <AccordionDetails
        classes={{
          root: accordionStyles.accordionDetails,
        }}
      >
        <Box className={styles.calendar} marginTop={10}>
          <VisitCustom />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
