import { parseArrayQueryParam, parsePaginationQueryParams } from "../../utils";
import { Paginated, PaginationQueryParams } from "../../types";
import { commApi } from "../comm-api";
import { CommPolicy, CommPolicyInclude, CreateCommPolicyInput, DeleteCommPolicyByIdInput, UpdateCommPolicyByIdInput } from "../types";
import { CommApiTag } from "../enums";

const policies = commApi.injectEndpoints({
  endpoints: (builder) => ({
    createCommPolicy: builder.mutation<CommPolicy, CreateCommPolicyInput>({
      query: (body) => ({ body, url: "/api/v1/policies", method: "POST" }),
      invalidatesTags: (_, __, { parentId }) => [{ type: CommApiTag.POLICY, id: parentId }],
    }),
    getCommPolicies: builder.query<Paginated<CommPolicy>, PaginationQueryParams<CommPolicyInclude>>({
      query: (params) => ({ url: "/api/v1/policies", params: parsePaginationQueryParams(params) }),
      providesTags: (_, __, { filter }) => [{ type: CommApiTag.POLICY, id: filter?.["parent.id"] }],
    }),
    getCommPolicyById: builder.query<CommPolicy, { policyId: string, include?: CommPolicyInclude[] }>({
      query: ({ policyId, include }) => ({ url: `/api/v1/policies/${policyId}`, params: parseArrayQueryParam("include", include) }),
      providesTags: (_, __, { policyId }) => [{ type: CommApiTag.POLICY, id: policyId }],
    }),
    updateCommPolicyById: builder.mutation<CommPolicy, UpdateCommPolicyByIdInput>({
      query: ({ policyId, ...body }) => ({ body, url: `/api/v1/policies/${policyId}`, method: "PATCH" }),
      invalidatesTags: (_, __, { parentId }) => [{ type: CommApiTag.POLICY, id: parentId }],
    }),
    deleteCommPolicyById: builder.mutation<void, DeleteCommPolicyByIdInput>({
      query: ({ policyId }) => ({ url: `/api/v1/policies/${policyId}`, method: "DELETE" }),
      invalidatesTags: (_, __, { parentId }) => [{ type: CommApiTag.POLICY, id: parentId }],
    }),
  }),
});

export const {
  useCreateCommPolicyMutation,
  useGetCommPoliciesQuery,
  useLazyGetCommPoliciesQuery,
  useGetCommPolicyByIdQuery,
  useLazyGetCommPolicyByIdQuery,
  useUpdateCommPolicyByIdMutation,
  useDeleteCommPolicyByIdMutation,
} = policies;
