import { AttributeModel, AttributeType } from "../../../Store/attributes/models";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { editAttribute } from "../../../Store/attributes";
import Box from "components/_legacy/Box";
import { t } from "@lingui/macro";
import styles from "../styles.module.scss";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import getDisplayedResourceName from "../../../../Functions/resourseNameParsing";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

interface BodyCellsInterface {
  name: string;
  image: string;
}

interface BodyCellsDataInterface extends AttributeModel {
  image: string;
}

interface Props {
  bodyCells: BodyCellsInterface[];
  attributes: AttributeModel[];
  type: AttributeType;
}

function getMappedCellsData(bodyCells: BodyCellsInterface[], attributes: AttributeModel[]) {
  return attributes.map((attribute) => {
    const associatedCell = bodyCells.find((cell: any) => cell.name === attribute.name);

    if (associatedCell) {
      return {
        ...attribute,
        image: associatedCell.image,
      };
    }

    return { ...attribute, image: bodyCells[0].image };
  });
}

export default function AttributesBody(props: Props) {
  const dispatch = useDispatch();
  const { bodyCells, attributes, type } = props;
  const [bodyCellsData, setBodyCellsData] = useState<BodyCellsDataInterface[]>([]);

  useEffect(() => {
    const mappedCellsData = getMappedCellsData(bodyCells, attributes);
    setBodyCellsData(mappedCellsData);
  }, []);

  const handleAvailableChange = (event: any, index: number, id: string) => {
    const updatedData = [...bodyCellsData];
    updatedData[index].isAvailable = event.target.checked;
    setBodyCellsData(updatedData);

    dispatch(editAttribute({
        resourceId: id,
        attribute: { isAvailable: event.target.checked, type },
      },
    ));
  };

  return (
    <>
      {bodyCellsData.map((attribute, index) => {
        return (
          <TableRow
            classes={{ root: styles.tableRow }}
            hover
            key={attribute.id + index}
            tabIndex={-1}
          >
            <TableCell classes={{ root: styles.tableCell }} component="th" id={attribute.id} padding="none" scope="row">
              <Box alignItems="center" display="flex">
                <img alt={t`attribute image`} src={attribute.image} />
                {getDisplayedResourceName(attribute.name)}
              </Box>
            </TableCell>
            <TableCell classes={{ root: styles.tableCell }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={attribute.isAvailable}
                    className="switcher switcher--default"
                    data-testId={attribute.isAvailable ? "adminAttribute-checked" : "adminAttribute-non-checked"}
                    name={attribute.id}
                    onChange={(e) => handleAvailableChange(e, index, attribute.id)}
                  />
                }
                label=""
              />
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
}
