import format from 'date-fns/format';
import {
  BookingFloor,
  BookingLocation,
  BookingModel,
  BookingSitDesk,
  CreateBookingResponse,
  GetBookingsResponse,
} from './models';
import { DeskStatusEnum } from "../../../../Admin/Store/floorMapDuck/desk/models";

let today = new Date();
today = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59 + today.getTimezoneOffset(), 59);

export const createCustomBookingSuccess: CreateBookingResponse = {
  result: {
    statusCode: 201,
    data: {
      id: 'cf856976-317f-4e07-aff1-1be370411554',
      dateFrom: '2021-07-14T00:00:00.000Z',
      dateTo: '2021-07-14T00:00:00.000Z',
      dateFromUTC: '2021-07-14T00:00:00.000Z',
      dateToUTC: '2021-07-14T00:00:00.000Z',
      dateFromLocal: '2021-07-14T00:00:00.000Z',
      dateToLocal: '2021-07-14T00:00:00.000Z',
      createdByAdmin: false,
      index: 0,
      status: 'BOOKED',
      user: {
        id: "0e0ef224-0fa4-44d6-8e20-440948847c2f",
        email: "MeganB@M365x214355.onmicrosoft.com",
        name: "Megan Bowen",
        mobilePhone: "+1 412 555 0109",
        location: "100 Wellington St, Leeds, LS1 4LT",
        isAdmin: true,
        approvedAccommodation: false,
        userDocument: {
          approver: null,
          createdAt: "2021-12-31T14:53:44.823141+00:00",
          documentUrl: '',
          id: "8792ca63-13e7-4f3c-b944-cc92de787d2f",
          status: "expired",
          type: "negativeCovidTestResult",
          updatedAt: "2022-01-01T00:00:01.634448+00:00",
          updatedBy: null,
          userId: "96c76e01-16a9-4055-91ba-0599f6a7f572",
          vaccinationDate: "2021-12-03T00:00:00+00:00",
          vaccinationExpirationDate: "2021-12-08T00:00:00+00:00",
          verificationDate: null,
        },
      },
      weekDay: 'Mon',
      selected: false,
      timeFrom: '9:00 AM',
      timeTo: '6:00 PM',
      userId: '5a75189b-e8c4-4df4-bb7f-f92ed36e8d8a',
      location: {
        id: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
        locationName: 'Toronto',
        locationAddress: '10 Bay Street, Toronto, Canada',
        businessHours: '00:00:00 - 24:00:00',
        qrCodeUrl: null,
        country: 'Canada',
        city: 'Toronto',
        region: 'North America',
        desks: 39,
        floors: 4,
        createdAt: '2021-07-09T16:16:25.560Z',
        timezone: 'America/New_York',
        active: true,
      },
      floor: {
        id: 'd0d37cdf-7be2-4a55-9643-6305bde441ee',
        floorName: 'My Floor 3',
        locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
      },
      desk: {
        id: '2154bb36-ad0b-458f-86f4-c2bf5f773253',
        name: 'Desk-7',
        owner: null,
        floorId: 'd0d37cdf-7be2-4a55-9643-6305bde441ee',
        status: DeskStatusEnum.Available,
        isBooked: false,
        resources: [],
        section: "Section 2",
        sectionId: 2,
      },
      isCancelled: false,
      type: 'custom',
      hasPassedQuestionnaire: null,
      checking: true,
    },
  },
  error: null,
};

export const location: BookingLocation = {
  id: 'd06ddbdb-75de-4175-b997-aeacc47c5aa2',
  locationName: 'New York',
  locationAddress: '100 W 33rd St, New York, NY 10001',
  businessHours: '00:00:00 - 24:00:00',
  country: 'USA',
  city: 'NY',
  region: 'NA',
  createdAt: '2021-05-24T11:28:12.739Z',
  qrCodeUrl: null,
  timezone: 'GMT',
  active: true,
  desks: 20,
  floors: 2,
};

const floor: BookingFloor = {
  id: '9aca3bad-5dd7-42f0-be57-6bec294b8389',
  floorName: '3rd Floor',
  locationId: 'd06ddbdb-75de-4175-b997-aeacc47c5aa2',
};

const desk: BookingSitDesk = {
  id: '1111-2222-3333-4444-5555',
  name: 'Desk 5555',
  section: null,
  sectionId: null,
  owner: null,
  floorId: '9aca3bad-5dd7-42f0-be57-6bec294b8389',
  status: DeskStatusEnum.Available,
  resources: [
    {
      id: 'd0ba1769-391c-4d6e-a368-c627fbb09465',
      name: 'Monitor',
      locationId: 'd06ddbdb-75de-4175-b997-aeacc47c5aa2',
      isAvailable: true,
      createdAt: '2021-05-27T11:05:00.806Z',
    },
    {
      id: 'a8275c5f-0e7d-44a5-a1f7-67878b15c3f9',
      name: 'Stand',
      locationId: 'd06ddbdb-75de-4175-b997-aeacc47c5aa2',
      isAvailable: true,
      createdAt: '2021-05-27T11:08:31.563Z',
    },
    {
      id: '5d46384c-167b-4946-afda-3efb91de74d8',
      name: 'Dual Monitors',
      locationId: 'd06ddbdb-75de-4175-b997-aeacc47c5aa2',
      isAvailable: true,
      createdAt: '2021-05-27T11:05:27.124Z',
    },
  ],
  isBooked: false,
};

export const booking: BookingModel = {
  id: 'e28a03dc-fa71-490f-8592-e075a578637d',
  dateFrom: '2021-06-30T00:00:00.000Z',
  dateTo: '2021-06-30T00:00:00.000Z',
  dateFromUTC: '2021-06-30T00:00:00.000Z',
  dateToUTC: '2021-06-30T00:00:00.000Z',
  timeFrom: '6:00 PM',
  timeTo: '9:00 PM',
  userId: '5a75189b-e8c4-4df4-bb7f-f92ed36e8d8a',
  user: {
    id: '5a75189b-e8c4-4df4-bb7f-f92ed36e8d8a',
    email: 'bruno.lucena@kinesso.com',
    name: 'Bruno Lucena',
    mobilePhone: '+16472935734',
    location: '13th Street 47 W 13th St, New York, USA, North America',
    isAdmin: true,
    approvedAccommodation: false,
    userDocument: {
      approver: null,
      createdAt: "2021-12-31T14:53:44.823141+00:00",
      documentUrl: '',
      id: "8792ca63-13e7-4f3c-b944-cc92de787d2f",
      status: "expired",
      type: "negativeCovidTestResult",
      updatedAt: "2022-01-01T00:00:01.634448+00:00",
      updatedBy: null,
      userId: "96c76e01-16a9-4055-91ba-0599f6a7f572",
      vaccinationDate: "2021-12-03T00:00:00+00:00",
      vaccinationExpirationDate: "2021-12-08T00:00:00+00:00",
      verificationDate: null,
    },
  },
  parkingSpot: {
    floor: '1',
    id: '1',
    isAvailable: true,
    locationId: 'd06ddbdb-75de-4175-b997-aeacc47c5aa2',
    name: 'floor-1',
    isBooked: true,
  },
  status: 'BOOKED',
  location: location,
  floor: floor,
  desk: desk,
  index: null,
  selected: null,
  weekDay: null,
  isCancelled: false,
  type: 'custom',
  hasPassedQuestionnaire: null,
  createdByAdmin: false,
  dateFromLocal: '',
  dateToLocal: '',
  checking: true,
};

export const getBookingsSuccess: GetBookingsResponse = {
  result: {
    statusCode: 200,
    data: {
      totalCount: 4,
      page: 1,
      limit: 20,
      bookings: [
        {
          id: 'bb4bdd66-9a41-431b-9e7a-6fdafcf427ce',
          dateFrom: format(today, 'yyyy-MM-dd'),
          dateTo: format(today, 'yyyy-MM-dd'),
          dateFromUTC: today.toISOString(),
          dateToUTC: today.toISOString(),
          timeFrom: format(today, 'hh:mm aa'),
          timeTo: format(today, 'hh:mm aa'),
          userId: '5a75189b-e8c4-4df4-bb7f-f92ed36e8d8a',
          user: {
            id: '5a75189b-e8c4-4df4-bb7f-f92ed36e8d8a',
            email: 'bruno.lucena@kinesso.com',
            name: 'Bruno Lucena',
            mobilePhone: '+16472935734',
            location: '13th Street 47 W 13th St, New York, USA, North America',
            isAdmin: true,
            approvedAccommodation: false,
            userDocument: {
              approver: null,
              createdAt: "2021-12-31T14:53:44.823141+00:00",
              documentUrl: '',
              id: "8792ca63-13e7-4f3c-b944-cc92de787d2f",
              status: "expired",
              type: "negativeCovidTestResult",
              updatedAt: "2022-01-01T00:00:01.634448+00:00",
              updatedBy: null,
              userId: "96c76e01-16a9-4055-91ba-0599f6a7f572",
              vaccinationDate: "2021-12-03T00:00:00+00:00",
              vaccinationExpirationDate: "2021-12-08T00:00:00+00:00",
              verificationDate: null,
            },
          },
          status: 'BOOKED',
          location: {
            id: 'd06ddbdb-75de-4175-b997-aeacc47c5aa2',
            locationName: 'New York',
            locationAddress: '100 W 33rd St, New York, NY 10001',
            businessHours: '00:00:00 - 24:00:00',
            country: 'USA',
            city: 'NY',
            region: 'NA',
            createdAt: '2021-05-24T11:28:12.739Z',
            qrCodeUrl: null,
            timezone: 'GMT',
            active: true,
            desks: 20,
            floors: 2,
          },
          floor: {
            id: 'ea36d26e-54df-4ddc-8a76-06be81e71fbd',
            floorName: 'My Floor 4',
            locationId: 'd06ddbdb-75de-4175-b997-aeacc47c5aa2',
          },
          desk: {
            id: '6fb14988-d2a4-43a1-9530-4379cc51d053',
            name: 'Desk-1-test',
            section: 'Section 2',
            sectionId: 2,
            owner: null,
            floorId: 'ea36d26e-54df-4ddc-8a76-06be81e71fbd',
            status: DeskStatusEnum.Available,
            resources: [
              {
                id: 'd0ba1769-391c-4d6e-a368-c627fbb09465',
                name: 'Monitor',
                locationId: 'd06ddbdb-75de-4175-b997-aeacc47c5aa2',
                isAvailable: true,
                createdAt: '2021-05-27T11:05:00.806Z',
              },
              {
                id: 'a8275c5f-0e7d-44a5-a1f7-67878b15c3f9',
                name: 'Stand',
                locationId: 'd06ddbdb-75de-4175-b997-aeacc47c5aa2',
                isAvailable: true,
                createdAt: '2021-05-27T11:08:31.563Z',
              },
              {
                id: '5d46384c-167b-4946-afda-3efb91de74d8',
                name: 'Dual Monitors',
                locationId: 'd06ddbdb-75de-4175-b997-aeacc47c5aa2',
                isAvailable: true,
                createdAt: '2021-05-27T11:05:27.124Z',
              },
            ],
            isBooked: false,
          },
          parkingSpot: {
            floor: '1',
            id: '1',
            isAvailable: true,
            locationId: 'd06ddbdb-75de-4175-b997-aeacc47c5aa2',
            name: 'floor-1',
            isBooked: true,
          },
          index: null,
          selected: null,
          weekDay: null,
          isCancelled: false,
          type: 'custom',
          hasPassedQuestionnaire: null,
          createdByAdmin: false,
          dateFromLocal: '',
          dateToLocal: '',
          checking: true,
        },
        {
          id: 'bb4bdd66-9a41-431b-9e7a-6fdafcf427ce2',
          dateFrom: '2021-07-06T00:00:00.000Z',
          dateTo: '2021-07-06T00:00:00.000Z',
          dateFromUTC: '2021-07-06T00:00:00.000Z',
          dateToUTC: '2021-07-06T00:00:00.000Z',
          timeFrom: '6:00 AM',
          timeTo: '9:00 AM',
          userId: '5a75189b-e8c4-4df4-bb7f-f92ed36e8d8a',
          user: {
            id: '5a75189b-e8c4-4df4-bb7f-f92ed36e8d8a',
            email: 'bruno.lucena@kinesso.com',
            name: 'Bruno Lucena',
            mobilePhone: '+16472935734',
            location: '13th Street 47 W 13th St, New York, USA, North America',
            isAdmin: true,
            approvedAccommodation: false,
            userDocument: {
              approver: null,
              createdAt: "2021-12-31T14:53:44.823141+00:00",
              documentUrl: '',
              id: "8792ca63-13e7-4f3c-b944-cc92de787d2f",
              status: "expired",
              type: "negativeCovidTestResult",
              updatedAt: "2022-01-01T00:00:01.634448+00:00",
              updatedBy: null,
              userId: "96c76e01-16a9-4055-91ba-0599f6a7f572",
              vaccinationDate: "2021-12-03T00:00:00+00:00",
              vaccinationExpirationDate: "2021-12-08T00:00:00+00:00",
              verificationDate: null,
            },
          },
          status: 'BOOKED',
          location: {
            id: 'd06ddbdb-75de-4175-b997-aeacc47c5aa2',
            locationName: 'New York',
            locationAddress: '100 W 33rd St, New York, NY 10001',
            businessHours: '00:00:00 - 24:00:00',
            country: 'USA',
            city: 'NY',
            region: 'NA',
            createdAt: '2021-05-24T11:28:12.739Z',
            qrCodeUrl: null,
            timezone: 'GMT',
            active: true,
            desks: 20,
            floors: 2,
          },
          floor: {
            id: 'ea36d26e-54df-4ddc-8a76-06be81e71fbd',
            floorName: 'My Floor 4',
            locationId: 'd06ddbdb-75de-4175-b997-aeacc47c5aa2',
          },
          desk: {
            id: '6fb14988-d2a4-43a1-9530-4379cc51d053',
            name: 'Desk-2-test',
            section: 'Section 2',
            sectionId: 2,
            owner: null,
            floorId: 'ea36d26e-54df-4ddc-8a76-06be81e71fbd',
            status: DeskStatusEnum.Available,
            resources: [
              {
                id: 'd0ba1769-391c-4d6e-a368-c627fbb09465',
                name: 'Monitor',
                locationId: 'd06ddbdb-75de-4175-b997-aeacc47c5aa2',
                isAvailable: true,
                createdAt: '2021-05-27T11:05:00.806Z',
              },
              {
                id: 'a8275c5f-0e7d-44a5-a1f7-67878b15c3f9',
                name: 'Stand',
                locationId: 'd06ddbdb-75de-4175-b997-aeacc47c5aa2',
                isAvailable: true,
                createdAt: '2021-05-27T11:08:31.563Z',
              },
              {
                id: '5d46384c-167b-4946-afda-3efb91de74d8',
                name: 'Dual Monitors',
                locationId: 'd06ddbdb-75de-4175-b997-aeacc47c5aa2',
                isAvailable: true,
                createdAt: '2021-05-27T11:05:27.124Z',
              },
            ],
            isBooked: false,
          },
          index: null,
          selected: null,
          weekDay: null,
          isCancelled: false,
          type: 'custom',
          hasPassedQuestionnaire: null,
          createdByAdmin: false,
          dateFromLocal: '',
          dateToLocal: '',
          checking: true,
        },
        {
          id: '3cd02b2d-592b-454b-a310-d85f2632d26044',
          dateFrom: '2021-07-07T00:00:00.000Z',
          dateTo: '2021-07-07T00:00:00.000Z',
          dateFromUTC: '2021-07-07T00:00:00.000Z',
          dateToUTC: '2021-07-07T00:00:00.000Z',
          timeFrom: '9:00 AM',
          timeTo: '6:00 PM',
          userId: '5a75189b-e8c4-4df4-bb7f-f92ed36e8d8a',
          user: {
            id: '5a75189b-e8c4-4df4-bb7f-f92ed36e8d8a',
            email: 'bruno.lucena@kinesso.com',
            name: 'Bruno Lucena',
            mobilePhone: '+16472935734',
            location: '13th Street 47 W 13th St, New York, USA, North America',
            isAdmin: true,
            approvedAccommodation: false,
            userDocument: {
              approver: null,
              createdAt: "2021-12-31T14:53:44.823141+00:00",
              documentUrl: '',
              id: "8792ca63-13e7-4f3c-b944-cc92de787d2f",
              status: "expired",
              type: "negativeCovidTestResult",
              updatedAt: "2022-01-01T00:00:01.634448+00:00",
              updatedBy: null,
              userId: "96c76e01-16a9-4055-91ba-0599f6a7f572",
              vaccinationDate: "2021-12-03T00:00:00+00:00",
              vaccinationExpirationDate: "2021-12-08T00:00:00+00:00",
              verificationDate: null,
            },
          },
          status: 'BOOKED',
          location: {
            id: 'd06ddbdb-75de-4175-b997-aeacc47c5aa2',
            locationName: 'New York',
            locationAddress: '100 W 33rd St, New York, NY 10001',
            businessHours: '00:00:00 - 24:00:00',
            country: 'USA',
            city: 'NY',
            region: 'NA',
            createdAt: '2021-05-24T11:28:12.739Z',
            qrCodeUrl: null,
            timezone: 'GMT',
            active: true,
            desks: 20,
            floors: 2,
          },
          floor: {
            id: 'ea36d26e-54df-4ddc-8a76-06be81e71fbd',
            floorName: 'My Floor 4',
            locationId: 'd06ddbdb-75de-4175-b997-aeacc47c5aa2',
          },
          desk: {
            id: 'c3104ec0-878a-4d6a-b4b3-479cb7d73d47',
            name: 'Desk-3-test',
            section: 'Section 4',
            sectionId: 4,
            owner: null,
            floorId: 'ea36d26e-54df-4ddc-8a76-06be81e71fbd',
            status: DeskStatusEnum.Available,
            resources: [
              {
                id: 'd0ba1769-391c-4d6e-a368-c627fbb09465',
                name: 'Monitor',
                locationId: 'd06ddbdb-75de-4175-b997-aeacc47c5aa2',
                isAvailable: true,
                createdAt: '2021-05-27T11:05:00.806Z',
              },
            ],
            isBooked: false,
          },
          index: null,
          selected: null,
          weekDay: null,
          isCancelled: false,
          type: 'custom',
          hasPassedQuestionnaire: null,
          createdByAdmin: false,
          dateFromLocal: '',
          dateToLocal: '',
          checking: true,
        },
        {
          id: '3cd02b2d-592b-454b-a310-d85f2632d260255',
          dateFrom: '2021-07-07T00:00:00.000Z',
          dateTo: '2021-07-07T00:00:00.000Z',
          dateFromUTC: '2021-07-07T00:00:00.000Z',
          dateToUTC: '2021-07-07T00:00:00.000Z',
          timeFrom: '9:00 AM',
          timeTo: '6:00 PM',
          userId: '5a75189b-e8c4-4df4-bb7f-f92ed36e8d8a',
          user: {
            id: '5a75189b-e8c4-4df4-bb7f-f92ed36e8d8a',
            email: 'bruno.lucena@kinesso.com',
            name: 'Bruno Lucena',
            mobilePhone: '+16472935734',
            location: '13th Street 47 W 13th St, New York, USA, North America',
            isAdmin: true,
            approvedAccommodation: false,
            userDocument: {
              approver: null,
              createdAt: "2021-12-31T14:53:44.823141+00:00",
              documentUrl: '',
              id: "8792ca63-13e7-4f3c-b944-cc92de787d2f",
              status: "expired",
              type: "negativeCovidTestResult",
              updatedAt: "2022-01-01T00:00:01.634448+00:00",
              updatedBy: null,
              userId: "96c76e01-16a9-4055-91ba-0599f6a7f572",
              vaccinationDate: "2021-12-03T00:00:00+00:00",
              vaccinationExpirationDate: "2021-12-08T00:00:00+00:00",
              verificationDate: null,
            },
          },
          status: 'BOOKED',
          location: {
            id: 'd06ddbdb-75de-4175-b997-aeacc47c5aa2',
            locationName: 'New York',
            locationAddress: '100 W 33rd St, New York, NY 10001',
            businessHours: '00:00:00 - 24:00:00',
            country: 'USA',
            city: 'NY',
            region: 'NA',
            createdAt: '2021-05-24T11:28:12.739Z',
            qrCodeUrl: null,
            timezone: 'GMT',
            active: true,
            desks: 20,
            floors: 2,
          },
          floor: {
            id: 'ea36d26e-54df-4ddc-8a76-06be81e71fbd',
            floorName: 'My Floor 4',
            locationId: 'd06ddbdb-75de-4175-b997-aeacc47c5aa2',
          },
          desk: {
            id: 'c3104ec0-878a-4d6a-b4b3-479cb7d73d47',
            name: 'Desk-4-test',
            section: 'Section 4',
            sectionId: 4,
            owner: null,
            floorId: 'ea36d26e-54df-4ddc-8a76-06be81e71fbd',
            status: DeskStatusEnum.Available,
            resources: [
              {
                id: 'd0ba1769-391c-4d6e-a368-c627fbb09465',
                name: 'Monitor',
                locationId: 'd06ddbdb-75de-4175-b997-aeacc47c5aa2',
                isAvailable: true,
                createdAt: '2021-05-27T11:05:00.806Z',
              },
            ],
            isBooked: false,
          },
          index: null,
          selected: null,
          weekDay: null,
          isCancelled: false,
          type: 'custom',
          hasPassedQuestionnaire: null,
          createdByAdmin: false,
          dateFromLocal: '',
          dateToLocal: '',
          checking: true,
        },
        {
          id: '3cd02b2d-592b-454b-a310-d85f2632d26066',
          dateFrom: '2021-07-05T00:00:00.000Z',
          dateTo: '2021-07-05T00:00:00.000Z',
          dateFromUTC: '2021-07-05T00:00:00.000Z',
          dateToUTC: '2021-07-05T00:00:00.000Z',
          timeFrom: '9:00 AM',
          timeTo: '6:00 PM',
          userId: '5a75189b-e8c4-4df4-bb7f-f92ed36e8d8a',
          user: {
            id: '5a75189b-e8c4-4df4-bb7f-f92ed36e8d8a',
            email: 'bruno.lucena@kinesso.com',
            name: 'Bruno Lucena',
            mobilePhone: '+16472935734',
            location: '13th Street 47 W 13th St, New York, USA, North America',
            isAdmin: true,
            approvedAccommodation: false,
            userDocument: {
              approver: null,
              createdAt: "2021-12-31T14:53:44.823141+00:00",
              documentUrl: '',
              id: "8792ca63-13e7-4f3c-b944-cc92de787d2f",
              status: "expired",
              type: "negativeCovidTestResult",
              updatedAt: "2022-01-01T00:00:01.634448+00:00",
              updatedBy: null,
              userId: "96c76e01-16a9-4055-91ba-0599f6a7f572",
              vaccinationDate: "2021-12-03T00:00:00+00:00",
              vaccinationExpirationDate: "2021-12-08T00:00:00+00:00",
              verificationDate: null,
            },
          },
          status: 'BOOKED',
          location: {
            id: 'd06ddbdb-75de-4175-b997-aeacc47c5aa2',
            locationName: 'New York',
            locationAddress: '100 W 33rd St, New York, NY 10001',
            businessHours: '00:00:00 - 24:00:00',
            country: 'USA',
            city: 'NY',
            region: 'NA',
            createdAt: '2021-05-24T11:28:12.739Z',
            qrCodeUrl: null,
            timezone: 'GMT',
            active: true,
            desks: 20,
            floors: 2,
          },
          floor: {
            id: 'ea36d26e-54df-4ddc-8a76-06be81e71fbd',
            floorName: 'My Floor 4',
            locationId: 'd06ddbdb-75de-4175-b997-aeacc47c5aa2',
          },
          desk: {
            id: 'c3104ec0-878a-4d6a-b4b3-479cb7d73d47',
            name: 'Desk-5-test',
            section: 'Section 5',
            sectionId: 5,
            owner: null,
            floorId: 'ea36d26e-54df-4ddc-8a76-06be81e71fbd',
            status: DeskStatusEnum.Available,
            resources: [
              {
                id: 'd0ba1769-391c-4d6e-a368-c627fbb09465',
                name: 'Monitor',
                locationId: 'd06ddbdb-75de-4175-b997-aeacc47c5aa2',
                isAvailable: true,
                createdAt: '2021-05-27T11:05:00.806Z',
              },
            ],
            isBooked: false,
          },
          index: null,
          selected: null,
          weekDay: null,
          isCancelled: false,
          type: 'custom',
          hasPassedQuestionnaire: null,
          createdByAdmin: false,
          dateFromLocal: '',
          dateToLocal: '',
          checking: true,
        },
        {
          id: '3cd02b2d-592b-454b-a310-d85f2632d260277',
          dateFrom: '2021-07-05T00:00:00.000Z',
          dateTo: '2021-07-05T00:00:00.000Z',
          dateFromUTC: '2021-07-05T00:00:00.000Z',
          dateToUTC: '2021-07-05T00:00:00.000Z',
          timeFrom: '9:00 AM',
          timeTo: '6:00 PM',
          userId: '5a75189b-e8c4-4df4-bb7f-f92ed36e8d8a',
          user: {
            id: '5a75189b-e8c4-4df4-bb7f-f92ed36e8d8a',
            email: 'bruno.lucena@kinesso.com',
            name: 'Bruno Lucena',
            mobilePhone: '+16472935734',
            location: '13th Street 47 W 13th St, New York, USA, North America',
            isAdmin: true,
            approvedAccommodation: false,
            userDocument: {
              approver: null,
              createdAt: "2021-12-31T14:53:44.823141+00:00",
              documentUrl: '',
              id: "8792ca63-13e7-4f3c-b944-cc92de787d2f",
              status: "expired",
              type: "negativeCovidTestResult",
              updatedAt: "2022-01-01T00:00:01.634448+00:00",
              updatedBy: null,
              userId: "96c76e01-16a9-4055-91ba-0599f6a7f572",
              vaccinationDate: "2021-12-03T00:00:00+00:00",
              vaccinationExpirationDate: "2021-12-08T00:00:00+00:00",
              verificationDate: null,
            },
          },
          status: 'BOOKED',
          location: {
            id: 'd06ddbdb-75de-4175-b997-aeacc47c5aa2',
            locationName: 'New York',
            locationAddress: '100 W 33rd St, New York, NY 10001',
            businessHours: '00:00:00 - 24:00:00',
            country: 'USA',
            city: 'NY',
            region: 'NA',
            createdAt: '2021-05-24T11:28:12.739Z',
            qrCodeUrl: null,
            timezone: 'GMT',
            active: true,
            desks: 20,
            floors: 2,
          },
          floor: {
            id: 'ea36d26e-54df-4ddc-8a76-06be81e71fbd',
            floorName: 'My Floor 4',
            locationId: 'd06ddbdb-75de-4175-b997-aeacc47c5aa2',
          },
          desk: {
            id: 'c3104ec0-878a-4d6a-b4b3-479cb7d73d47',
            name: 'Desk-6-test',
            section: 'Section 5',
            sectionId: 5,
            owner: null,
            floorId: 'ea36d26e-54df-4ddc-8a76-06be81e71fbd',
            status: DeskStatusEnum.Available,
            resources: [
              {
                id: 'd0ba1769-391c-4d6e-a368-c627fbb09465',
                name: 'Monitor',
                locationId: 'd06ddbdb-75de-4175-b997-aeacc47c5aa2',
                isAvailable: true,
                createdAt: '2021-05-27T11:05:00.806Z',
              },
            ],
            isBooked: false,
          },
          index: null,
          selected: null,
          weekDay: null,
          isCancelled: false,
          type: 'custom',
          hasPassedQuestionnaire: null,
          createdByAdmin: false,
          dateFromLocal: '',
          dateToLocal: '',
          checking: true,
        },
        {
          id: '3cd02b2d-592b-454b-a310-d85f2632d2602778',
          dateFrom: '2021-07-05T00:00:00.000Z',
          dateTo: '2021-07-05T00:00:00.000Z',
          dateFromUTC: '2021-07-05T00:00:00.000Z',
          dateToUTC: '2021-07-05T00:00:00.000Z',
          timeFrom: '9:00 AM',
          timeTo: '6:00 PM',
          userId: '5a75189b-e8c4-4df4-bb7f-f92ed36e8d8a',
          user: {
            id: '5a75189b-e8c4-4df4-bb7f-f92ed36e8d8a',
            email: 'bruno.lucena@kinesso.com',
            name: 'Bruno Lucena',
            mobilePhone: '+16472935734',
            location: '13th Street 47 W 13th St, New York, USA, North America',
            isAdmin: true,
            approvedAccommodation: false,
            userDocument: {
              approver: null,
              createdAt: "2021-12-31T14:53:44.823141+00:00",
              documentUrl: '',
              id: "8792ca63-13e7-4f3c-b944-cc92de787d2f",
              status: "expired",
              type: "negativeCovidTestResult",
              updatedAt: "2022-01-01T00:00:01.634448+00:00",
              updatedBy: null,
              userId: "96c76e01-16a9-4055-91ba-0599f6a7f572",
              vaccinationDate: "2021-12-03T00:00:00+00:00",
              vaccinationExpirationDate: "2021-12-08T00:00:00+00:00",
              verificationDate: null,
            },
          },
          status: 'PENDING',
          location: {
            id: 'd06ddbdb-75de-4175-b997-aeacc47c5aa2',
            locationName: 'New York',
            locationAddress: '100 W 33rd St, New York, NY 10001',
            businessHours: '00:00:00 - 24:00:00',
            country: 'USA',
            city: 'NY',
            region: 'NA',
            createdAt: '2021-05-24T11:28:12.739Z',
            qrCodeUrl: null,
            timezone: 'GMT',
            active: true,
            desks: 20,
            floors: 2,
          },
          floor: {
            id: 'ea36d26e-54df-4ddc-8a76-06be81e71fbd',
            floorName: 'My Floor 4',
            locationId: 'd06ddbdb-75de-4175-b997-aeacc47c5aa2',
          },
          desk: {
            id: 'c3104ec0-878a-4d6a-b4b3-479cb7d73d47',
            name: 'Desk-6-test',
            section: 'Section 5',
            sectionId: 5,
            owner: null,
            floorId: 'ea36d26e-54df-4ddc-8a76-06be81e71fbd',
            status: DeskStatusEnum.Available,
            resources: [
              {
                id: 'd0ba1769-391c-4d6e-a368-c627fbb09465',
                name: 'Monitor',
                locationId: 'd06ddbdb-75de-4175-b997-aeacc47c5aa2',
                isAvailable: true,
                createdAt: '2021-05-27T11:05:00.806Z',
              },
            ],
            isBooked: false,
          },
          index: null,
          selected: null,
          weekDay: null,
          isCancelled: false,
          type: 'custom',
          hasPassedQuestionnaire: null,
          createdByAdmin: false,
          dateFromLocal: '',
          dateToLocal: '',
          checking: true,
        },
        {
          id: '3cd02b2d-592b-454b-a310-d85f2632d26027782',
          dateFrom: '2021-07-05T00:00:00.000Z',
          dateTo: '2021-07-05T00:00:00.000Z',
          dateFromUTC: '2021-07-05T00:00:00.000Z',
          dateToUTC: '2021-07-05T00:00:00.000Z',
          timeFrom: '9:00 AM',
          timeTo: '6:00 PM',
          userId: '5a75189b-e8c4-4df4-bb7f-f92ed36e8d8a',
          user: {
            id: '5a75189b-e8c4-4df4-bb7f-f92ed36e8d8a',
            email: 'bruno.lucena@kinesso.com',
            name: 'Bruno Lucena',
            mobilePhone: '+16472935734',
            location: '13th Street 47 W 13th St, New York, USA, North America',
            isAdmin: true,
            approvedAccommodation: false,
            userDocument: {
              approver: null,
              createdAt: "2021-12-31T14:53:44.823141+00:00",
              documentUrl: '',
              id: "8792ca63-13e7-4f3c-b944-cc92de787d2f",
              status: "expired",
              type: "negativeCovidTestResult",
              updatedAt: "2022-01-01T00:00:01.634448+00:00",
              updatedBy: null,
              userId: "96c76e01-16a9-4055-91ba-0599f6a7f572",
              vaccinationDate: "2021-12-03T00:00:00+00:00",
              vaccinationExpirationDate: "2021-12-08T00:00:00+00:00",
              verificationDate: null,
            },
          },
          status: 'CANCELED',
          location: {
            id: 'd06ddbdb-75de-4175-b997-aeacc47c5aa2',
            locationName: 'New York',
            locationAddress: '100 W 33rd St, New York, NY 10001',
            businessHours: '00:00:00 - 24:00:00',
            country: 'USA',
            city: 'NY',
            region: 'NA',
            createdAt: '2021-05-24T11:28:12.739Z',
            qrCodeUrl: null,
            timezone: 'GMT',
            active: true,
            desks: 20,
            floors: 2,
          },
          floor: {
            id: 'ea36d26e-54df-4ddc-8a76-06be81e71fbd',
            floorName: 'My Floor 4',
            locationId: 'd06ddbdb-75de-4175-b997-aeacc47c5aa2',
          },
          desk: {
            id: 'c3104ec0-878a-4d6a-b4b3-479cb7d73d47',
            name: 'Desk-6-test',
            section: 'Section 5',
            sectionId: 5,
            owner: null,
            floorId: 'ea36d26e-54df-4ddc-8a76-06be81e71fbd',
            status: DeskStatusEnum.Available,
            resources: [
              {
                id: 'd0ba1769-391c-4d6e-a368-c627fbb09465',
                name: 'Monitor',
                locationId: 'd06ddbdb-75de-4175-b997-aeacc47c5aa2',
                isAvailable: true,
                createdAt: '2021-05-27T11:05:00.806Z',
              },
            ],
            isBooked: false,
          },
          index: null,
          selected: null,
          weekDay: null,
          isCancelled: false,
          type: 'custom',
          hasPassedQuestionnaire: null,
          createdByAdmin: false,
          dateFromLocal: '',
          dateToLocal: '',
          checking: true,
        },
      ],
    },
  },
  error: null,
};