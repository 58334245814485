import {  t } from "@lingui/macro";
import { Box } from "@material-ui/core";
import HtmlEditor from "components/_legacy/HtmlEditor";

interface Props {
  value?: string;
  onChange?: (value: string) => void;
}

export default function Description(props: Props) {
  const { value, onChange } = props;

  return (
    <Box>
      <label>{t`Room description:`}</label>
      <HtmlEditor
        initialText={value || ""}
        onChangeText={(text) => onChange?.(text)}
      />
    </Box>
  );
}
