import Api from 'store/_legacy/Services/Api';
import { BaseResponse } from 'store/_legacy/Models/ReduxModels';
import { call, put, takeLatest } from 'redux-saga/effects';
import { matchPath } from 'react-router-dom';
import {
  AddLocationParkingSpotResponse,
  DeleteParkingSpotResponse,
  EditParkingSpotResponse,
  GetLocationAvailableParkingSpotsResponse,
  GetLocationParkingSpotsResponse,
  GetParkingSpotResponse,
} from './models';
import {
  ADD_LOCATION_PARKING_SPOT,
  ADD_LOCATION_PARKING_SPOT_FAIL,
  ADD_LOCATION_PARKING_SPOT_SUCCESS,
  AddLocationParkingSpot,
  AddLocationParkingSpotFail,
  AddLocationParkingSpotSuccess,
  DELETE_PARKING_SPOT,
  DELETE_PARKING_SPOT_FAIL,
  DELETE_PARKING_SPOT_SUCCESS,
  DeleteParkingSpot,
  DeleteParkingSpotFail,
  DeleteParkingSpotSuccess,
  EDIT_PARKING_SPOT,
  EDIT_PARKING_SPOT_FAIL,
  EDIT_PARKING_SPOT_SUCCESS,
  EditParkingSpot,
  EditParkingSpotFail,
  EditParkingSpotSuccess,
  GET_LOCATION_AVAILABLE_PARKING_SPOTS,
  GET_LOCATION_AVAILABLE_PARKING_SPOTS_FAIL,
  GET_LOCATION_AVAILABLE_PARKING_SPOTS_SUCCESS,
  GET_LOCATION_PARKING_SPOTS,
  GET_LOCATION_PARKING_SPOTS_FAIL,
  GET_LOCATION_PARKING_SPOTS_SUCCESS,
  GET_PARKING_SPOT,
  GET_PARKING_SPOT_FAIL,
  GET_PARKING_SPOT_SUCCESS,
  GetLocationAvailableParkingSpots,
  GetLocationAvailableParkingSpotsFail,
  GetLocationAvailableParkingSpotsSuccess,
  GetLocationParkingSpots,
  GetLocationParkingSpotsFail,
  GetLocationParkingSpotsSuccess,
  GetParkingSpot,
  GetParkingSpotFail,
  GetParkingSpotSuccess,
} from './index';

function* addLocationParkingSpotSaga(action: AddLocationParkingSpot): any {
  try {
    const payload: BaseResponse<AddLocationParkingSpotResponse> = yield call(Api, action);
    const success: AddLocationParkingSpotSuccess = { type: ADD_LOCATION_PARKING_SPOT_SUCCESS, payload };

    yield put(success);
  } catch (e: any) {
    const failure: AddLocationParkingSpotFail = { type: ADD_LOCATION_PARKING_SPOT_FAIL, payload: e };

    yield put(failure);
  }
}

function* deleteParkingSpotSaga(action: DeleteParkingSpot): any {
  try {
    const payload: BaseResponse<DeleteParkingSpotResponse> = yield call(Api, action);

    const match = matchPath<{ parkingSpotId: string }>(action.payload.request.url, {
      path: "/api/parking/:parkingSpotId",
    });

    const success: DeleteParkingSpotSuccess = {
      type: DELETE_PARKING_SPOT_SUCCESS,
      payload: {
        ...payload,
        data: {
          ...payload.data,
          result: {
            ...payload.data.result,
            parkingSpotId: match?.params.parkingSpotId ?? '',
          },
        },
      },
    };

    yield put(success);
  } catch (e: any) {
    const failure: DeleteParkingSpotFail = { type: DELETE_PARKING_SPOT_FAIL, payload: e };

    yield put(failure);
  }
}

function* editParkingSpotSaga(action: EditParkingSpot): any {
  try {
    const payload: BaseResponse<EditParkingSpotResponse> = yield call(Api, action);
    const success: EditParkingSpotSuccess = { type: EDIT_PARKING_SPOT_SUCCESS, payload };

    yield put(success);
  } catch (e: any) {
    const failure: EditParkingSpotFail = { type: EDIT_PARKING_SPOT_FAIL, payload: e };

    yield put(failure);
  }
}

function* getLocationAvailableParkingSpotsSaga(action: GetLocationAvailableParkingSpots): any {
  try {
    const payload: BaseResponse<GetLocationAvailableParkingSpotsResponse> = yield call(Api, action);
    const success: GetLocationAvailableParkingSpotsSuccess = {
      type: GET_LOCATION_AVAILABLE_PARKING_SPOTS_SUCCESS,
      payload,
      locationId: action.locationId,
    };

    yield put(success);
  } catch (e: any) {
    const failure: GetLocationAvailableParkingSpotsFail = { type: GET_LOCATION_AVAILABLE_PARKING_SPOTS_FAIL, payload: e };

    yield put(failure);
  }
}

function* getLocationParkingSpotsSaga(action: GetLocationParkingSpots): any {
  try {
    const payload: BaseResponse<GetLocationParkingSpotsResponse> = yield call(Api, action);

    const match = matchPath<{ locationId: string }>(action.payload.request.url, {
      path: "/api/parking/location/:locationId",
    });

    const success: GetLocationParkingSpotsSuccess = {
      type: GET_LOCATION_PARKING_SPOTS_SUCCESS,
      payload,
      locationId: match?.params.locationId ?? '',
     };

    yield put(success);
  } catch (e: any) {
    const failure: GetLocationParkingSpotsFail = { type: GET_LOCATION_PARKING_SPOTS_FAIL, payload: e };

    yield put(failure);
  }
}

function* getParkingSpotSaga(action: GetParkingSpot): any {
  try {
    const payload: BaseResponse<GetParkingSpotResponse> = yield call(Api, action);
    const success: GetParkingSpotSuccess = { type: GET_PARKING_SPOT_SUCCESS, payload };

    yield put(success);
  } catch (e: any) {
    const failure: GetParkingSpotFail = { type: GET_PARKING_SPOT_FAIL, payload: e };

    yield put(failure);
  }
}

export default [
  takeLatest(ADD_LOCATION_PARKING_SPOT, addLocationParkingSpotSaga),
  takeLatest(DELETE_PARKING_SPOT, deleteParkingSpotSaga),
  takeLatest(EDIT_PARKING_SPOT, editParkingSpotSaga),
  takeLatest(GET_LOCATION_AVAILABLE_PARKING_SPOTS, getLocationAvailableParkingSpotsSaga),
  takeLatest(GET_LOCATION_PARKING_SPOTS, getLocationParkingSpotsSaga),
  takeLatest(GET_PARKING_SPOT, getParkingSpotSaga),
];
