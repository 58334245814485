import {useDispatch} from "react-redux";
import {useTypedSelector} from "store/_legacy/Redux/store";
import {setFloorMapData} from "Admin/Store/floorMapDuck";
import Box from "components/_legacy/Box";
import Text from "components/_legacy/Text";
import {Trans} from "@lingui/macro";
import styles from "../../../styles.module.scss";
import Button from "components/_legacy/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "components/_legacy/Select";
import { DESK_STATUS_TO_EDIT } from "Admin/Store/floorMapDuck/desk";

interface MassActionsEditStatusProps {
  onUpdateAllDesks: Function;
  onClosePopupEdit: Function;
}

export default function MassActionsEditStatus(props: MassActionsEditStatusProps) {
  const dispatch = useDispatch();
  const { adminFloorMap } = useTypedSelector(state => state);
  const { onUpdateAllDesks, onClosePopupEdit } = props;
  const { editDeskList } = adminFloorMap;

  const onStatusUpdate = (event: any) => {
    dispatch(setFloorMapData({
      editDeskList: {
        selectAllStatus: event.target.value,
      },
    }));
  };

  return (
    <Box>
      <Box margin="9px 0 18px">
        <Text color="gray" size="md">
          <Trans>
            Select satus in order to apply for selected desks
          </Trans>
        </Text>
      </Box>

      <Box marginBottom={20}>
        <Box marginBottom={9} paddingLeft={8} position="relative">
          <InputLabel className={styles.inputLabel} htmlFor="status">
            <Box left={0} position="absolute" top={-1}>
              <Text color="orange" inline weight="semi-bold">* </Text>
            </Box>

            <Text color="black" size="md">
              <Trans>
                Status
              </Trans>
            </Text>
          </InputLabel>
        </Box>

        <Select
          classes={{
            input: styles.input,
            wrapper: styles.select,
            materialSelect: styles.materialInput,
          }}
          id="status"
          items={DESK_STATUS_TO_EDIT}
          labelColor="gray"
          onChange={onStatusUpdate}
          value={editDeskList.selectAllStatus}
        />
      </Box>

      <Box alignItems="center" display="flex" justifyContent="end">
        <Button
          onClick={() => onClosePopupEdit()}
          size="sm"
          type="clear"
          withShadow={false}
        >
          <Trans>
            Cancel
          </Trans>
        </Button>

        <Button
          onClick={() => onUpdateAllDesks({ status: editDeskList.selectAllStatus })}
          size="sm"
        >
          <Trans>
            Apply Changes
          </Trans>
        </Button>
      </Box>
    </Box>
  );
}
