import { useHistory } from 'react-router';
import Box from 'components/_legacy/Box';
import OverflowToolTip from 'components/_legacy/OverflowTooltip';
import styles from '../../styles.module.scss';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { ReservationByFloorModel } from '../../../../Store/reservations/models';

interface ReservationBodyItemProps {
  reservationsByFloors: ReservationByFloorModel;
  key: number;
}

export default function MapViewBodyItem(props: ReservationBodyItemProps) {
  const history = useHistory();
  const { reservationsByFloors, key } = props;

  const onNavigateToMapViewDetails = (floorId: string, locationId: string) => {
    history.push({
      pathname: `/admin/reservations/${locationId}/map-view/${floorId}`,
      state: {
        route: 'mapViewDetails', // set history state for filters reset logic - Reservations.ts onResetFiltersData()
      },
    });
  };

  return (
    <TableRow
      classes={{root: `${styles.tableRow} ${styles.pointerRow}`}}
      data-testid="reservation-map-view-row"
      hover
      key={key}
      onClick={() => onNavigateToMapViewDetails(reservationsByFloors.floorId, reservationsByFloors.locationId)}
      tabIndex={-1}
    >
      <TableCell classes={{root: styles.tableCell}} data-testid="reservation-map-view-locationName">
        <Box alignItems="center" display="flex">
          <OverflowToolTip text={reservationsByFloors.locationName} />
        </Box>
      </TableCell>
      <TableCell classes={{root: styles.tableCell}} data-testid="reservation-map-view-floorName">
        <OverflowToolTip text={reservationsByFloors.floorName} />
      </TableCell>
      <TableCell classes={{root: styles.tableCell}} data-testid="reservation-map-view-desks">
        <OverflowToolTip text={reservationsByFloors.desks} />
      </TableCell>
      <TableCell classes={{root: styles.tableCell}} data-testid="reservation-map-view-availableDesks">
        <OverflowToolTip text={reservationsByFloors.availableDesks} />
      </TableCell>
      <TableCell classes={{root: styles.tableCell}} data-testid="reservation-map-view-bookedDesks">
        <OverflowToolTip text={reservationsByFloors.bookedDesks} />
      </TableCell>
    </TableRow>
  );
}
