import React, { useMemo, useRef, useState } from "react";
import { MonthlyCalendarProps } from "./types";
import { Badge, Box, ButtonBase, IconButton, Typography, useTheme } from "@mui/material";
import { addDays, addMonths, format, getISODay, isAfter, isBefore, isSameDay, startOfMonth } from "date-fns";
import { KeyboardArrowLeftRounded, KeyboardArrowRightRounded } from "@mui/icons-material";
import { t } from "@lingui/macro";
import { matchArray } from "utils";
import { useSize } from "hooks";

const labels = [t`Sun`, t`Mon`, t`Tue`, t`Wed`, t`Thu`, t`Fri`, t`Sat`];

const resolveIsSelected = (value?: Date[], target?: Date): boolean => {
  if (!value?.length || !target) {
    return false;
  }

  if (value.length === 1) {
    return isSameDay(target, value[0]);
  }

  return (isSameDay(target, value[0]) || isAfter(target, value[0])) && (isSameDay(target, value[1]) || isBefore(target, value[1]));
};

export const MonthlyCalendar: React.FC<MonthlyCalendarProps> = (props) => {
  const {
    value,
    min,
    max,
    lock,
    onChange,
    selectionType = "single",
    width = 378,
  } = props;
  const { palette, background } = useTheme();
  const [month, setMonth] = useState(startOfMonth(new Date()));
  const [focused, setFocused] = useState<Date>(); 
  const calendarRef = useRef<HTMLDivElement | null>(null);
  const calendarSize = useSize(calendarRef);
  const days = useMemo(() => {
    const start = addDays(month, getISODay(month) * -1);
    const days: Date[] = [];

    for (let i = 0; i < 35; i++) {
      days.push(addDays(start, i));
    }

    return days;
  }, [month.getTime()]);

  const handleDayClick = (day: Date) => {
    if (selectionType === "single") {
      onChange?.([day]);
    } else if (selectionType === "range") {
      if (!value?.length || isBefore(day, value[0]) || value?.length === 2) {
        onChange?.([day]);
      } else {
        onChange?.([...value, day]);
      }
    } else if (selectionType === "multiple") {
      if (value) {
        const newValue = value?.filter((date) => !isSameDay(date, day));

        if (value.length === newValue.length) {
          onChange?.([...value, day]);
        } else {
          onChange?.(newValue);
        }
      } else {
        onChange?.([day]);
      }
    }
  };

  const size = width === "auto" ? 100 / 7 + "%" : width / 7;

  return (
    <Box width={width === "auto" ? "100%" : width}>
      <Box alignItems="center" display="flex" justifyContent="space-between" mb={1}>
        <Typography fontSize={14} fontWeight="600" ml={1}>{format(month, "MMM yyyy")}</Typography>
        <Box alignItems="center" display="flex">
          <IconButton color="primary" data-cid="next-month-button" onClick={() => setMonth(addMonths(month, -1))} size="small">
            <KeyboardArrowLeftRounded />
          </IconButton>
          <IconButton color="primary" data-cid="previous-month-button" onClick={() => setMonth(addMonths(month, 1))} size="small">
            <KeyboardArrowRightRounded />
          </IconButton>
        </Box>
      </Box>
      <Box display="flex" mb={2}>
        {labels.map((label) => (
          <Typography
            color={palette.grey[700]}
            fontSize={13}
            key={label}
            textAlign="center"
            textTransform="uppercase"
            width={size}
          >
            {label}
          </Typography>
        ))}
      </Box>
      <Box display="flex" flexWrap="wrap" ref={width === "auto" ? calendarRef : undefined}>
        {days.map((day) => {
          const isSelected = resolveIsSelected(value, day);
          const isDisabled = (min && isBefore(day, min)) || (max && isAfter(day, max)) || (lock && !lock.some((date) => isSameDay(day, date)));
          const isLocked = lock && lock.some((date) => isSameDay(day, date));
          const color = matchArray([[isSelected || isLocked, palette.primary.main], [isDisabled, palette.grey[400]]]);
          const bgcolor = matchArray([
            [isSelected, background.blue.light],
            [value?.length === 1 && focused && selectionType === "range" && isAfter(day, value[0]) && isBefore(day, focused), palette.grey[100]],
          ]);

          return (
            <Box height={width === "auto" && calendarSize ? calendarSize.width / 7 : size} key={day.getTime()} p={1} width={size}>
              <ButtonBase
                data-cid="select-day-button"
                disabled={isDisabled}
                onClick={() => handleDayClick(day)}
                onMouseEnter={() => setFocused(day)}
                onMouseLeave={() => setFocused(undefined)}
                sx={{
                  bgcolor,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 2,
                  "&:hover": isSelected ? undefined : { bgcolor: palette.grey[100] },
                }}
              >
                {isLocked ? (
                  <Badge color="primary" variant="dot">
                    <Typography color={color} fontSize={16} fontWeight="600" width={22}>{format(day, "d")}</Typography>
                  </Badge>
                ) : (
                  <Typography color={color} fontSize={16} fontWeight="400">{format(day, "d")}</Typography>
                )}
              </ButtonBase>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
