import sanitizeHTML from "sanitize-html";

export const sanitizeEditorHTML = (html = ""): string => sanitizeHTML(html, {
  allowedTags: sanitizeHTML.defaults.allowedTags.concat(["img", "style"]),
  allowedAttributes: {
    "*": ["style", "class"],
    img: ["src", "srcset", "alt", "title", "width", "height", "loading", "style", "class"],
    a: ["href", "name", "target", "style", "class"],
    table: ["border", "cellspacing", "cellpadding", "width", "height", "style", "class"],
    td: ["width", "height", "valign", "style", "class"],
  },
  parseStyleAttributes: false,
});
