import { Box, BoxProps } from "@mui/material";
import React, { forwardRef, useState } from "react";

export const IFrameBox = forwardRef<HTMLIFrameElement, BoxProps<"iframe"> & { autoHeight?: boolean }>((props, ref) => {
  const { autoHeight, ...rest } = props;
  const [height, setHeight] = useState<number>();

  const handleLoad: BoxProps<"iframe">["onLoad"] = (event) => {
    const { body } = event.currentTarget.contentWindow?.document || {};

    if (!body) {
      return;
    }

    body.style.margin = "0px";

    if (autoHeight) {
      setHeight(body.scrollHeight);
    }
  };

  return <Box border="none" component="iframe" height={height} onLoad={handleLoad} ref={ref} {...rest} />;
});
