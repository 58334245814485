import { t } from "@lingui/macro";
import { ReservationStatus } from "store";

export const resolveReservationStatusLabel = (type?: ReservationStatus): string | undefined => {
  switch (type) {
    case ReservationStatus.BOOKED:
      return t`booked`;
    case ReservationStatus.FAILED:
      return t`failed`;
    case ReservationStatus.PENDING:
      return t`pending`;
    case ReservationStatus.PROCESSING:
      return t`processing`;
    case ReservationStatus.CANCELED:
      return t`canceled`;
    default:
      return undefined;
  }
};
