import Box from 'components/_legacy/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import clsx from 'clsx';
import { LocationOutlinedIcon } from 'components/_legacy/Icons/LocationOutlinedIcon';
import Skeleton from '@material-ui/lab/Skeleton';
import styles from './styles.module.scss';
import Text from 'components/_legacy/Text';
import { format, utcToZonedTime } from 'date-fns-tz';
import { Location as LocationModel } from 'App/Store/Locations/locationsDuck/models';
import { Trans, defineMessage, t } from '@lingui/macro';
import { useTypedSelector } from 'store/_legacy/Redux/store';
import { Link } from 'react-router-dom';

interface LocationItemProps {
  location: LocationModel;
  type?: string;
  isDefault?: boolean;
  onClick: (locationId: string) => any;
  selected?: boolean;
}

/**
 * Loading state for Locations.
 * Renders a few placeholders.
 */
export function LocationItemLoading() {
  const item = (
    <Box borderRadius={14} height={90}>
      <Skeleton classes={{ root: styles.skeletonRoot }} height="100%" variant="rect" />
    </Box>
  );

  return (
    <Box display="grid" gap={15}>
      {item}
      {item}
      {item}
      {item}
      {item}
    </Box>
  );
}

/**
 * Renders the location UI as a dumb component.
 */
export default function LocationItem({
  location: {
    id,
    locationAddress,
    locationName,
    timezone,
    region,
  },
  type = "desk",
  onClick,
  isDefault,
  selected,
}: LocationItemProps) {
  const classes = clsx(styles.container, selected && styles.selected);
  const { config } = useTypedSelector(state => state);

  // Creates an arbitrary date but with correct location timezone
  const dateWithTimezone = utcToZonedTime(new Date(), timezone ? timezone : 'Europe/London');
  // Format timezone to GMT: GMT-03:00
  const timezoneFormated = 'GMT' + format(dateWithTimezone, 'XXX', { timeZone: timezone ? timezone : 'Europe/London' });

  const selectTextRegion = defineMessage({ message: region });
  const regionText = t({id: selectTextRegion.id});
  const bookingPath = type === 'desk' ? 'create-new-booking' : 'create-new-booking-support';

  return (
    <Link onClick={() => onClick(id)} to={`/${bookingPath}/select-floor`}>
      <ButtonBase
        aria-label={t`LocationItem`}
        classes={{ root: `${styles.buttonBase} ${styles.locationItem}` }}
        disableRipple
        name={t`LocationItem`}
      >
        <Box className={classes}>
          <Box>
            <Text color={selected ? 'blue' : 'black'} size="md" weight="semi-bold">
              {locationName}
            </Text>

            <Box
              alignItems="center"
              display="flex"
              left={-1}
              marginTop={11}
              position="relative"
            >
              <LocationOutlinedIcon color={ selected ? config.theme.primary : 'var(--grey)' } />
              <Text color={selected ? 'blue' : 'gray'} size="md">
                {locationAddress}
              </Text>
            </Box>
          </Box>

          <Box alignItems="start" display="flex">
            <Box alignItems="center" display="flex">
              <Text color={selected ? 'blue' : 'gray'} size="md">
                {regionText} ({timezoneFormated})
              </Text>

              {isDefault && (
                <Box className={`${styles.badge} ${config.themeName === 'green' ? styles.badgeThemeGreen : ''}`} marginStart={10}>
                  <Text color="white" size="md">
                    <Trans>Default</Trans>
                  </Text>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </ButtonBase>
    </Link>
  );
}
