import Box from 'components/_legacy/Box';
import FromToTimeUI from '../FromToTimeUI';
import hoursMinutesToNumber from 'Functions/hoursMinutesToNumber.ts';
import numberToHoursMinutes from 'Functions/numberToHoursMinutes';
import Text from 'components/_legacy/Text';
import { Calendar } from 'react-date-range';
import { setCreateNewMeetingData } from 'App/Store/Meetings/createNewMeetingDuck';
import { Trans } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTypedSelector } from 'store/_legacy/Redux/store';
import {endOfMoment, startOfMoment} from '../../../../Functions/Helpers';
import HourSlider from '../HourSlider';
import moment from 'moment';

export default function DailyTimeBooking() {
  const dispatch = useDispatch();
  const { createNewMeeting, config } = useTypedSelector(state => state);
  const { dateFrom, timeFrom, timeTo } = createNewMeeting;   
  const timeFromInNumber = timeFrom ? hoursMinutesToNumber(timeFrom) : 9;
  const timeToInNumber = timeTo ? hoursMinutesToNumber(timeTo) : 18;
  const [hours, setHours] = useState([timeFromInNumber, timeToInNumber]);
  const initialDate = dateFrom ? new Date(dateFrom): new Date();
  const [selectedDate, setSelectedDate] = useState(initialDate);

  const handleSelectDate = (date: Date) => {
    const selectedDate = moment(date);    

    setSelectedDate(date);

    dispatch(setCreateNewMeetingData({
      dateFrom: startOfMoment(selectedDate),
      dateTo: endOfMoment(selectedDate),
    }));
  };  
  
  useEffect(() => {  
    const { hours: timeFromHours, minutes: timeFromMinutes } = numberToHoursMinutes(hours[0]);
    const { hours: timeToHours, minutes: timeToMinutes } = numberToHoursMinutes(hours[1]);
    
    // These dates are arbitrary, the only parameters needed are the hour and minute, so we can format properly like this: 09:00
    const timeFrom = new Date(2021, 3, 14, timeFromHours, timeFromMinutes);
    const timeTo = new Date(2021, 3, 14, timeToHours, timeToMinutes);

    dispatch(setCreateNewMeetingData({
      timeFrom,
      timeTo,
    }));
  }, [hours]);

  return (
    <Box>
      <Calendar
        className='custom-calendar'
        date={selectedDate}        
        locale={config.calendarTranslations}
        minDate={new Date()}
        onChange={handleSelectDate}
        showDateDisplay={false}
        showMonthAndYearPickers={false}
      >
      </Calendar>

      <Box alignItems="center" display="flex" justifyContent="between" marginBottom={14} marginTop={10}>
        <Text size="md" weight="semi-bold">
          <Trans>Time</Trans>
        </Text>

        <Box>
          <FromToTimeUI timeFrom={hours[0]} timeTo={hours[1]} />
        </Box>
      </Box>
        
      <HourSlider hours={hours} setHours={setHours} step={0.5} />
    </Box>
  );
}
