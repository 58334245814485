import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useClickOutside } from "../../../../../../Functions/hooks";
import styles from "./styles.module.scss";

import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import UserSearch from "./UserSearch";
import { clearUserSelectionFromSearchData, setGroupDetailsData } from "../../../../../Store/groupDetails";
import GroupUsersList from "./GroupUsersList";
import { UserManagementUser } from "../../../../../Store/userManagement/models";
import { useTypedSelector } from "store/_legacy/Redux/store";

export default function UsersMultiSelect() {
  const dispatch = useDispatch();
  const { infoDataToEdit } = useTypedSelector(state => state.groupDetails);
  const deskOwnersWrapperRef = useRef<any>(null);
  const [isOpenUserList, setIsOpenUserList] = useState(false);

  const onResetSearchState = () => {
    dispatch(setGroupDetailsData({
      users: [],
    }));
  };

  const onResetUsersState = () => {
    dispatch(clearUserSelectionFromSearchData());
    dispatch(setGroupDetailsData({
      infoDataToEdit: {
        ...infoDataToEdit,
        addAll: false,
        addUserSearch: '',
      },
    }));
  };

  const onCloseOwnerList = () => {
    setIsOpenUserList(false);
    onResetSearchState(); // clear user list on close dropdown
  };

  const onUsersToAddUpdate = (usersToAdd: UserManagementUser[]) => {
    dispatch(setGroupDetailsData({ usersToAdd }));
  };

  useEffect(() => {
    onResetUsersState(); // clear user list on load
  }, []);

  // close list by outside click
  useClickOutside(deskOwnersWrapperRef, onCloseOwnerList);

  return (
    <div
      className={styles.deskOwners}
      ref={deskOwnersWrapperRef}
    >
      <UserSearch
        isOpenUserList={isOpenUserList}
        setIsOpenUserList={setIsOpenUserList}
      />

      <Accordion
        classes={{expanded: styles.accordionExpanded, root: styles.accordion}}
        expanded={isOpenUserList}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          classes={{
            content: styles.accordionSummaryContent,
            expandIcon: styles.accordionSummaryIcon,
            root: styles.accordionSummary,
          }}
        >
        </AccordionSummary>
        <AccordionDetails
          classes={{
            root: styles.accordionDetails,
          }}
        >
          <GroupUsersList onUsersUpdate={onUsersToAddUpdate} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
