import {useDispatch} from "react-redux";
import {Trans} from "@lingui/macro";
import styles from "../styles.module.scss";
import TextField from "components/_legacy/TextField";
import {IGroup} from "../../../../Store/groups/models";
import {useTypedSelector} from "store/_legacy/Redux/store";
import {useEffect, useState} from "react";
import {setGroupDetailsData} from "../../../../Store/groupDetails";

interface Props {
  group: IGroup;
}

export default function GroupName(props: Props) {
  const dispatch = useDispatch();
  const { infoEditState, infoDataToEdit } = useTypedSelector(state => state.groupDetails);
  const { group } = props;
  const [editGroupName, setEditGroupName] = useState(group.name);

  const isDisabled = !infoEditState;
  const disabledClass = isDisabled ? styles.disabledField : '';

  const onChangeGroupName = (name: string) => {
    setEditGroupName(name);
    dispatch(setGroupDetailsData({ infoDataToEdit: {...infoDataToEdit, name } }));
  };

  // set default group name on load component
  useEffect(() => {
    onChangeGroupName(group.name);
  }, []);

  // turn back default name after cancelling changes
  useEffect(() => {
    if (infoDataToEdit.name === group.name) {
      onChangeGroupName(group.name);
    }
  }, [infoDataToEdit.name]);

  return (
    <div className={styles.detailsInfoBlock}>
      <div className={styles.fieldLabel}>
        <span className={styles.requiredSign}>*</span>
        <Trans>Brand name</Trans>
      </div>
      <TextField
        className={disabledClass}
        disabled={isDisabled}
        id="group-name"
        onChange={(event) => onChangeGroupName(event.target.value)}
        value={editGroupName}
      />
    </div>
  );
}
