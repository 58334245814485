import { FloorMapObject } from "../../../../../../Store/floorMapDuck/models";
import { useDispatch } from "react-redux";
import { updateMeeting } from "../../../../../../Store/floorMapDuck";
import { Box, TextField, createStyles, makeStyles } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Group, useGetGroupsQuery } from "store";
import { useEffect, useState } from "react";
import { useDebouncedCallback } from "hooks";
import { t } from "@lingui/macro";
import Text from "components/_legacy/Text";
import ChevronDown from "components/_legacy/Icons/ChevronDown";
import { isObject } from "class-validator";
import { darkBlue } from "theme/_legacy/variables";

interface Props {
  meeting: FloorMapObject;
}

const useAutocompleteInputStyles = makeStyles(() => createStyles({
  root: {
    backgroundColor: "#F4F4F4",
    borderRadius: 4,
    fontSize: 14,
    paddingLeft: 6,
    paddingTop: 8,
    paddingBottom: "8px !important",
    '& .mui4-MuiAutocomplete-input': {
      padding: '2px 0 !important',
    },
    '& .mui4-MuiChip-root': {
      fontSize: 14,
      maxWidth: 320,
      marginLeft: 0,
    },
    '& .mui4-MuiAutocomplete-endAdornment': {
      top: `calc(50% - 8px)`,
      right: 16,
    },
  },
}));

export default function MeetingDetailsGroups(props: Props) {
  const { meeting } = props;
  const dispatch = useDispatch();
  const autocompleteInputClasses = useAutocompleteInputStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState<string>();
  const debouncedSetSearch = useDebouncedCallback(setSearch, [setSearch], 700);
  const getGroupsQuery = useGetGroupsQuery({ search });
  const { data: getGroupsResponse, isLoading: groupsAreLoading, isFetching: groupsAreFetching } = getGroupsQuery;
  const groups = getGroupsResponse?.result?.data?.groups || [];

  useEffect(() => {
    if (!isLoading && (groupsAreLoading || groupsAreFetching)) {
      setIsLoading(true);
    } else if (isLoading && !groupsAreLoading && !groupsAreFetching) {
      setIsLoading(false);
    }
  }, [isLoading, groupsAreLoading, groupsAreFetching]);

  const handleGroupsChange = (groups: Array<Group | string> = []) => {
    dispatch(updateMeeting({
      meetingId: typeof meeting.id === "string" ? parseInt(meeting.id) : meeting.id,
      meeting: {
        groups: groups.filter<Group>(isObject).map(({ id, name }) => ({ id, name })),
      },
    }));
  };

  const handleSearchChange = (search?: string) => {
    setIsLoading(true);
    debouncedSetSearch(search);
  };

  return (
    <Box display="flex" marginTop={1}>
      <Box alignItems="center" display="flex" flexBasis={150} height={36} width={150}>
        <Text color="gray" size="md">{t`Access for groups:`}</Text>
      </Box>
      <Box flex={1}>
        <Autocomplete
          disableClearable
          forcePopupIcon
          freeSolo
          getOptionLabel={(group) => group.name}
          loading={isLoading}
          loadingText={t`Loading groups...`}
          multiple
          noOptionsText={t`No groups found.`}
          onChange={(_, value) => handleGroupsChange(value)}
          onInputChange={(_, value) => handleSearchChange(value || undefined)}
          options={isLoading ? [] : groups}
          popupIcon={<ChevronDown color={darkBlue} /> }
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                classes: autocompleteInputClasses,
              }}
              placeholder={!meeting.groups?.length ? t`Not selected` : undefined}
            />
          )}
          value={meeting.groups}
        />
      </Box>
    </Box>
  );
}
