import adminAttributes, { State as adminAttributesState } from 'Admin/Store/attributes';
import adminCalendar, { State as adminCalendarState } from 'Admin/Store/calendar';
import adminCovidQuestions, { State as adminCovidQuestionsState } from 'Admin/Store/covidQuestions';
import adminFloorMap, { State as AdminFloorMap } from 'Admin/Store/floorMapDuck';
import adminFloorMapApiRequests, { State as AdminFloorMapApiRequests } from 'Admin/Store/floorMapApiRequestsDuck';
import adminNotifications, { State as adminNotificationsState } from 'Admin/Store/notifications';
import adminReservationJustInTime, { State as adminReservationJustInTimeState } from 'Admin/Store/reservationJustInTime';
import adminReservations, { State as adminReservationsState } from 'Admin/Store/reservations';
import adminUserManagement, { State as adminUserManagementState } from 'Admin/Store/userManagement';
import adminSettingsMobile, { State as adminSettingsMobileState } from 'Admin/Store/settingsMobile';
import adminVisits, { State as AdminVisitsState } from 'Admin/Store/visits';
import availableDesks, { State as AvailableDesksState } from 'App/Store/Bookings/availableDesksDuck';
import booking, { State as BookingState } from 'App/Store/Bookings/bookingDuck';
import bookingApproval, { State as BookingApprovalState } from 'BookingApproval/Store/bookingApproval';
import bookingCovidConfirmation, { State as BookingCovidConfirmationState } from 'App/Store/Bookings/covidConfirmationDuck';
import room, { State as RoomState } from 'App/Store/Meetings/meetingRooms';
import roomSchedule, { State as RoomScheduleState } from 'App/Store/Meetings/meetingSchedule';
import roomsManagement, { State as RoomsManagementState } from 'Admin/Store/roomsManagement';
import roomsImages, { State as RoomsImagesState } from 'Admin/Store/roomsImages';
import meetingReservations, { State as MeetingReservationsState } from 'App/Store/Meetings/meetingReservations';
import meetingAttendees, { State as MeetingAttendeesState } from 'App/Store/Meetings/meetingAttendees';
import checkin, { State as CheckinState } from 'App/Store/Bookings/checkinDuck';
import config, { State as ConfigState } from './Ducks/configDuck';
import covidCertificate, { State as CovidCertificateState } from 'App/Store/CovidSertificate';
import createNewBooking, { State as CreateNewBookingState } from 'App/Store/Bookings/createNewBookingDuck';
import createNewVisit, { State as CreateNewVisitState } from 'App/Store/Bookings/createNewVisitDuck';
import createNewMeeting, { State as CreateNewMeetingState } from 'App/Store/Meetings/createNewMeetingDuck';
import groups, { State as groupsState } from 'Admin/Store/groups';
import groupCreateEditForm, { State as groupCreateEditFormState } from 'Admin/Store/groups/forms/createEditForm';
import groupDetails, { State as groupDetailsState } from 'Admin/Store/groupDetails';
import desk, { State as DeskState } from 'App/Store/Desk/deskDuck';
import emailTemplates, { State as EmailTemplatesState } from 'Admin/Store/emailTemplatesDuck';
import globalNotifications, { State as GlobalNotificationsState } from 'Admin/Store/globalNotifications';
import locationDashboard, { State as LocationDashboardState } from 'Admin/Store/locationDashboardDuck';
import locations, { State as LocationsState } from 'App/Store/Locations/locationsDuck';
import locationsDetails, { State as LocationsDetailsState } from 'App/Store/Locations/locationsDetailsDuck';
import locationsResources, { State as LocationsResourcesState } from 'App/Store/Resources/locationsResourcesDuck';
import login, { State as LoginState } from 'App/Store/Auth/loginDuck';
import parking, { State as ParkingState } from 'Admin/Store/parking';
import profile, { State as ProfileState } from 'App/Store/Users/profileDuck';
import executiveAssistant, { State as ExecutiveAssistantState } from 'App/Store/Users/executiveAssistant';
import pushNotifications, { State as PushNotificationsState } from 'App/Store/Notifications';
import questionnaire, { State as QuestionnaireState } from 'App/Store/Questionnaire';
import sectionNeighbors, { State as SectionNeighborsState } from 'App/Store/Bookings/sectionNeighbors';
import { adminSlice, bookingsSlice, commApi, floorsSlice, mediaApi, reservationsSlice, roomsSlice, togetherApi } from 'store';

export type ReduxStore = {
  adminAttributes: adminAttributesState;
  adminCalendar: adminCalendarState;
  adminCovidQuestions: adminCovidQuestionsState;
  adminFloorMap: AdminFloorMap;
  adminFloorMapApiRequests: AdminFloorMapApiRequests;
  adminNotifications: adminNotificationsState;
  adminReservations: adminReservationsState;
  adminReservationJustInTime: adminReservationJustInTimeState;
  adminSettingsMobile: adminSettingsMobileState;
  adminUserManagement: adminUserManagementState;
  adminVisits: AdminVisitsState;
  availableDesks: AvailableDesksState;
  booking: BookingState;
  bookingApproval: BookingApprovalState;
  bookingCovidConfirmation: BookingCovidConfirmationState;
  checkin: CheckinState;
  config: ConfigState;
  createNewBooking: CreateNewBookingState;
  createNewVisit: CreateNewVisitState;
  createNewMeeting: CreateNewMeetingState;
  desk: DeskState;
  groups: groupsState;
  groupCreateEditForm: groupCreateEditFormState;
  groupDetails: groupDetailsState;
  emailTemplates: EmailTemplatesState;
  globalNotifications: GlobalNotificationsState;
  locationDashboard: LocationDashboardState;
  locations: LocationsState;
  locationsDetails: LocationsDetailsState;
  locationsResources: LocationsResourcesState;
  login: LoginState;
  parking: ParkingState;
  profile: ProfileState;
  executiveAssistant: ExecutiveAssistantState;
  covidCertificate: CovidCertificateState;
  pushNotifications: PushNotificationsState;
  questionnaire: QuestionnaireState;
  sectionNeighbors: SectionNeighborsState;
  room: RoomState; 
  roomSchedule: RoomScheduleState;
  roomsManagement: RoomsManagementState;
  roomsImages: RoomsImagesState;
  meetingReservations: MeetingReservationsState;
  meetingAttendees: MeetingAttendeesState;
  [reservationsSlice.name]: NonNullable<Parameters<typeof reservationsSlice.reducer>[0]>;
  [roomsSlice.name]: NonNullable<Parameters<typeof roomsSlice.reducer>[0]>;
  [floorsSlice.name]: NonNullable<Parameters<typeof floorsSlice.reducer>[0]>;
  [bookingsSlice.name]: NonNullable<Parameters<typeof bookingsSlice.reducer>[0]>;
  [togetherApi.reducerPath]: NonNullable<Parameters<typeof togetherApi.reducer>[0]>;
  [adminSlice.name]: NonNullable<Parameters<typeof adminSlice.reducer>[0]>;
  [mediaApi.reducerPath]: NonNullable<Parameters<typeof mediaApi.reducer>[0]>;
  [commApi.reducerPath]: NonNullable<Parameters<typeof commApi.reducer>[0]>;
}

const reducers = {
  adminAttributes,
  adminCalendar,
  adminCovidQuestions,
  adminFloorMap,
  adminFloorMapApiRequests,
  adminNotifications,
  adminReservations,
  adminReservationJustInTime,
  adminSettingsMobile,
  adminUserManagement,
  adminVisits,
  availableDesks,
  booking,
  bookingApproval,
  bookingCovidConfirmation,
  checkin,
  config,
  createNewBooking,
  createNewVisit,
  createNewMeeting,
  desk,
  groups,
  groupCreateEditForm,
  groupDetails,
  emailTemplates,
  globalNotifications,
  locationDashboard,
  locations,
  locationsDetails,
  locationsResources,
  login,
  parking,
  profile,
  executiveAssistant,
  covidCertificate,
  pushNotifications,
  questionnaire,
  sectionNeighbors,
  room,
  roomSchedule,
  roomsManagement,
  roomsImages,
  meetingReservations,
  meetingAttendees,
  [reservationsSlice.name]: reservationsSlice.reducer,
  [roomsSlice.name]: roomsSlice.reducer,
  [floorsSlice.name]: floorsSlice.reducer,
  [bookingsSlice.name]: bookingsSlice.reducer,
  [togetherApi.reducerPath]: togetherApi.reducer,
  [adminSlice.name]: adminSlice.reducer,
  [mediaApi.reducerPath]: mediaApi.reducer,
  [commApi.reducerPath]: commApi.reducer,
};

export default reducers;
