import React from "react";
import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import { t } from "@lingui/macro";
import { TimeZoneDisclaimerProps } from "./types";

export const TimeZoneDisclaimer: React.FC<TimeZoneDisclaimerProps> = (props) => {
  const { timeZone, tooltip } = props;
  const { palette } = useTheme();

  return (
    <Typography color={palette.grey[700]} fontSize={12} fontWeight={500}>
      {"* "}
      {t`All times shown are in`}
      {" "}
      <Tooltip disableInteractive title={tooltip}>
        <Box bgcolor={palette.grey[100]} borderRadius={1} color={palette.grey[800]} component="span" display="inline-block" px={0.5} py={0.25}>
          {timeZone} 
        </Box>
      </Tooltip>
      {" "}
      {t`time zone.`}
    </Typography>
  );
};
