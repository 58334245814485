import { ActionPayload, BaseErrorResponse, BaseResponse } from 'store/_legacy/Models/ReduxModels';
import { t } from '@lingui/macro';
import {
  AddLocationParkingSpotRequest,
  AddLocationParkingSpotRequestData,
  AddLocationParkingSpotResponse,
  DeleteParkingSpotRequest,
  DeleteParkingSpotResponse,
  EditParkingSpotRequest,
  EditParkingSpotRequestData,
  EditParkingSpotResponse,
  GetLocationAvailableParkingSpotsRequest,
  GetLocationAvailableParkingSpotsRequestData,
  GetLocationAvailableParkingSpotsResponse,
  GetLocationParkingSpotsRequest,
  GetLocationParkingSpotsResponse,
  GetParkingSpotRequest,
  GetParkingSpotResponse,
  ParkingSpot,
} from './models';

const SET_LOADING = "parking.SET_LOADING";

export const ADD_LOCATION_PARKING_SPOT = 'ADD_LOCATION_PARKING_SPOT';
export const ADD_LOCATION_PARKING_SPOT_FAIL = 'ADD_LOCATION_PARKING_SPOT_FAIL';
export const ADD_LOCATION_PARKING_SPOT_SUCCESS = 'ADD_LOCATION_PARKING_SPOT_SUCCESS';

export const DELETE_PARKING_SPOT = 'DELETE_PARKING_SPOT';
export const DELETE_PARKING_SPOT_FAIL = 'DELETE_PARKING_SPOT_FAIL';
export const DELETE_PARKING_SPOT_SUCCESS = 'DELETE_PARKING_SPOT_SUCCESS';

export const EDIT_PARKING_SPOT = 'EDIT_PARKING_SPOT';
export const EDIT_PARKING_SPOT_FAIL = 'EDIT_PARKING_SPOT_FAIL';
export const EDIT_PARKING_SPOT_SUCCESS = 'EDIT_PARKING_SPOT_SUCCESS';

export const GET_LOCATION_AVAILABLE_PARKING_SPOTS = 'GET_LOCATION_AVAILABLE_PARKING_SPOTS';
export const GET_LOCATION_AVAILABLE_PARKING_SPOTS_FAIL = 'GET_LOCATION_AVAILABLE_PARKING_SPOTS_FAIL';
export const GET_LOCATION_AVAILABLE_PARKING_SPOTS_SUCCESS = 'GET_LOCATION_AVAILABLE_PARKING_SPOTS_SUCCESS';

export const GET_LOCATION_PARKING_SPOTS = 'GET_LOCATION_PARKING_SPOTS';
export const GET_LOCATION_PARKING_SPOTS_FAIL = 'GET_LOCATION_PARKING_SPOTS_FAIL';
export const GET_LOCATION_PARKING_SPOTS_SUCCESS = 'GET_LOCATION_PARKING_SPOTS_SUCCESS';

export const GET_PARKING_SPOT = 'GET_PARKING_SPOT';
export const GET_PARKING_SPOT_FAIL = 'GET_PARKING_SPOT_FAIL';
export const GET_PARKING_SPOT_SUCCESS = 'GET_PARKING_SPOT_SUCCESS';

interface SetLoading {
  type: typeof SET_LOADING;
  payload: boolean;
}

export interface AddLocationParkingSpot {
  type: typeof ADD_LOCATION_PARKING_SPOT;
  payload: ActionPayload<AddLocationParkingSpotRequestData>
}
export interface AddLocationParkingSpotFail {
  type: typeof ADD_LOCATION_PARKING_SPOT_FAIL;
  payload: BaseErrorResponse;
}
export interface AddLocationParkingSpotSuccess {
  type: typeof ADD_LOCATION_PARKING_SPOT_SUCCESS;
  payload: BaseResponse<AddLocationParkingSpotResponse>
}

export interface DeleteParkingSpot {
  type: typeof DELETE_PARKING_SPOT;
  payload: ActionPayload<DeleteParkingSpotRequest>
}
export interface DeleteParkingSpotFail {
  type: typeof DELETE_PARKING_SPOT_FAIL;
  payload: BaseErrorResponse;
}
export interface DeleteParkingSpotSuccess {
  type: typeof DELETE_PARKING_SPOT_SUCCESS;
  payload: BaseResponse<DeleteParkingSpotResponse>
}

export interface EditParkingSpot {
  type: typeof EDIT_PARKING_SPOT;
  payload: ActionPayload<EditParkingSpotRequestData>
}
export interface EditParkingSpotFail {
  type: typeof EDIT_PARKING_SPOT_FAIL;
  payload: BaseErrorResponse;
}
export interface EditParkingSpotSuccess {
  type: typeof EDIT_PARKING_SPOT_SUCCESS;
  payload: BaseResponse<EditParkingSpotResponse>
}

export interface GetLocationAvailableParkingSpots {
  type: typeof GET_LOCATION_AVAILABLE_PARKING_SPOTS;
  payload: ActionPayload<GetLocationAvailableParkingSpotsRequestData>;
  locationId: string;
}
export interface GetLocationAvailableParkingSpotsFail {
  type: typeof GET_LOCATION_AVAILABLE_PARKING_SPOTS_FAIL;
  payload: BaseErrorResponse;
}
export interface GetLocationAvailableParkingSpotsSuccess {
  type: typeof GET_LOCATION_AVAILABLE_PARKING_SPOTS_SUCCESS;
  payload: BaseResponse<GetLocationAvailableParkingSpotsResponse>;
  locationId: string;
}

export interface GetLocationParkingSpots {
  type: typeof GET_LOCATION_PARKING_SPOTS;
  payload: ActionPayload<GetLocationParkingSpotsRequest>
}
export interface GetLocationParkingSpotsFail {
  type: typeof GET_LOCATION_PARKING_SPOTS_FAIL;
  payload: BaseErrorResponse;
}
export interface GetLocationParkingSpotsSuccess {
  type: typeof GET_LOCATION_PARKING_SPOTS_SUCCESS;
  payload: BaseResponse<GetLocationParkingSpotsResponse>
  locationId: string;
}

export interface GetParkingSpot {
  type: typeof GET_PARKING_SPOT;
  payload: ActionPayload<GetParkingSpotRequest>
}
export interface GetParkingSpotFail {
  type: typeof GET_PARKING_SPOT_FAIL;
  payload: BaseErrorResponse;
}
export interface GetParkingSpotSuccess {
  type: typeof GET_PARKING_SPOT_SUCCESS;
  payload: BaseResponse<GetParkingSpotResponse>
}

type Actions =
  | SetLoading
  | AddLocationParkingSpot
  | AddLocationParkingSpotFail
  | AddLocationParkingSpotSuccess
  | DeleteParkingSpot
  | DeleteParkingSpotFail
  | DeleteParkingSpotSuccess
  | EditParkingSpot
  | EditParkingSpotFail
  | EditParkingSpotSuccess
  | GetLocationAvailableParkingSpots
  | GetLocationAvailableParkingSpotsFail
  | GetLocationAvailableParkingSpotsSuccess
  | GetLocationParkingSpots
  | GetLocationParkingSpotsFail
  | GetLocationParkingSpotsSuccess
  | GetParkingSpot
  | GetParkingSpotFail
  | GetParkingSpotSuccess;

export interface State {
  loading: boolean;
  addMessages: {
    error: string;
    success: string;
  };
  deleteMessages: {
    error: string;
    success: string;
  };
  editMessages: {
    error: string;
    success: string;
  };
  getMessages: {
    error: string;
    success: string;
  };
  locationParkingSpots: { [locationId: string]: ParkingSpot[] };
  locationAvailableParkingSpots: { [locationId: string]: ParkingSpot[] };
  parkingSpots: { [parkingSpotId: string]: ParkingSpot };
}

const initialState: State = {
  loading: false,
  addMessages: {
    error: '',
    success: '',
  },
  deleteMessages: {
    error: '',
    success: '',
  },
  editMessages: {
    error: '',
    success: '',
  },
  getMessages: {
    error: '',
    success: '',
  },
  locationParkingSpots: {},
  locationAvailableParkingSpots: {},
  parkingSpots: {},
};

export default function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case SET_LOADING: {
      return { ...state, loading: action.payload };
    }
    case ADD_LOCATION_PARKING_SPOT: {
      return {
        ...state,
        loading: true,
        addMessages: {
          ...state.addMessages,
          error: '',
          success: '',
        },
      };
    }
    case ADD_LOCATION_PARKING_SPOT_FAIL: {
      return {
        ...state,
        loading: false,
        addMessages: {
          ...state.addMessages,
          error: t`There was an error adding the parking spot. Please try again.`,
        },
      };
    }
    case ADD_LOCATION_PARKING_SPOT_SUCCESS: {
      const newParkingSpot = action.payload.data.result.data;
      const locationParkingSpots = { ...state.locationParkingSpots };
      const parkingSpots = { ...state.parkingSpots };

      if (newParkingSpot) {
        locationParkingSpots[newParkingSpot.locationId] = [
          ...locationParkingSpots[newParkingSpot.locationId],
          action.payload.data.result.data,
        ];
        parkingSpots[newParkingSpot.id] = newParkingSpot;
      }

      return {
        ...state,
        loading: false,
        addMessages: {
          ...state.addMessages,
          success: t`Parking spot ${newParkingSpot.name ? `"${newParkingSpot.name}"` : ''} added`,
        },
        locationParkingSpots,
        parkingSpots,
      };
    }

    case DELETE_PARKING_SPOT: {
      return {
        ...state,
        loading: true,
        deleteMessages: {
          ...state.deleteMessages,
          error: '',
          success: '',
        },
      };
    }
    case DELETE_PARKING_SPOT_FAIL: {
      const error = action.payload?.error?.code === 1_005_001
        ? t`Can't delete this parking spot, there are future reservations for it.`
        : t`There was an error deleting the parking spot. Please try again.`;

      return {
        ...state,
        loading: false,
        deleteMessages: {
          ...state.deleteMessages,
          error,
        },
      };
    }
    case DELETE_PARKING_SPOT_SUCCESS: {
      const locationAvailableParkingSpots = { ...state.locationAvailableParkingSpots };
      const locationAvailableParkingSpotsArray = Object.keys(locationAvailableParkingSpots).map(locationId => locationAvailableParkingSpots[locationId]);

      const locationParkingSpots = { ...state.locationParkingSpots };
      const locationParkingSpotsArray = Object.keys(locationParkingSpots).map(key => locationParkingSpots[key]);

      locationAvailableParkingSpotsArray.forEach(lparkingSpots => {
        const locationId = lparkingSpots[0]?.locationId;

        lparkingSpots = lparkingSpots.filter(parkingSpot => parkingSpot.id !== action.payload.data.result.parkingSpotId);

        if (locationId) {
          locationAvailableParkingSpots[locationId] = lparkingSpots;
        }
      });

      locationParkingSpotsArray.forEach(lparkingSpots => {
        const locationId = lparkingSpots[0]?.locationId;

        lparkingSpots = lparkingSpots.filter(parkingSpot => parkingSpot.id !== action.payload.data.result.parkingSpotId);

        if (locationId) {
          locationParkingSpots[locationId] = lparkingSpots;
        }
      });

      const parkingSpots = { ...state.parkingSpots };
      delete parkingSpots[action.payload.data.result.parkingSpotId];

      return {
        ...state,
        loading: false,
        deleteMessages: {
          ...state.deleteMessages,
          success: t`Parking spot deleted`,
        },
        locationAvailableParkingSpots,
        locationParkingSpots,
        parkingSpots,
      };
    }

    case EDIT_PARKING_SPOT: {
      return {
        ...state,
        loading: true,
        editMessages: {
          ...state.editMessages,
          error: '',
          success: '',
        },
      };
    }
    case EDIT_PARKING_SPOT_FAIL: {
      return {
        ...state,
        loading: false,
        editMessages: {
          ...state.editMessages,
          error: t`There was an error editing the parking spot. Please try again.`,
        },
      };
    }
    case EDIT_PARKING_SPOT_SUCCESS: {
      const editedParkingSpot = action.payload.data.result.data;
      const locationParkingSpots = { ...state.locationParkingSpots };
      const parkingSpots = { ...state.parkingSpots };

      if (editedParkingSpot) {
        // Updates current parkingSpot with edited data from API
        locationParkingSpots[editedParkingSpot.locationId] = locationParkingSpots[editedParkingSpot.locationId].map(parkingSpot => {
          if (parkingSpot.id === editedParkingSpot.id) {
            parkingSpot = {
              ...parkingSpot,
              ...editedParkingSpot,
            };
          }

          return parkingSpot;
        });

        parkingSpots[editedParkingSpot.id] = editedParkingSpot;
      }

      return {
        ...state,
        loading: false,
        editMessages: {
          ...state.editMessages,
          success: t`Parking spot ${editedParkingSpot.name ? `"${editedParkingSpot.name}"` : ''} edited`,
        },
        locationParkingSpots,
        parkingSpots,
      };
    }

    case GET_LOCATION_AVAILABLE_PARKING_SPOTS: {
      return {
        ...state,
        loading: true,
        getMessages: {
          ...state.getMessages,
          error: '',
          success: '',
        },
      };
    }
    case GET_LOCATION_AVAILABLE_PARKING_SPOTS_FAIL: {
      return {
        ...state,
        loading: false,
        getMessages: {
          ...state.getMessages,
          error: t`There was an error getting the location available parking spots. Please try again.`,
        },
      };
    }
    case GET_LOCATION_AVAILABLE_PARKING_SPOTS_SUCCESS: {
      const locationAvailableParkingSpots = { ...state.locationAvailableParkingSpots };

      locationAvailableParkingSpots[action.locationId] = action.payload.data.result.data;

      return {
        ...state,
        loading: false,
        getMessages: {
          ...state.getMessages,
          success: t`Location available parking spots fetched`,
        },
        locationAvailableParkingSpots,
      };
    }

    case GET_LOCATION_PARKING_SPOTS: {
      return {
        ...state,
        loading: true,
        getMessages: {
          ...state.getMessages,
          error: '',
          success: '',
        },
      };
    }
    case GET_LOCATION_PARKING_SPOTS_FAIL: {
      return {
        ...state,
        loading: false,
        getMessages: {
          ...state.getMessages,
          error: t`There was an error getting the location parking spots. Please try again.`,
        },
      };
    }
    case GET_LOCATION_PARKING_SPOTS_SUCCESS: {
      const locationParkingSpots = { ...state.locationParkingSpots };

      locationParkingSpots[action.locationId] = action.payload.data.result.data;

      return {
        ...state,
        loading: false,
        getMessages: {
          ...state.getMessages,
          success: t`Location parking spots fetched`,
        },
        locationParkingSpots,
      };
    }

    case GET_PARKING_SPOT: {
      return {
        ...state,
        loading: true,
        getMessages: {
          ...state.getMessages,
          error: '',
          success: '',
        },
      };
    }
    case GET_PARKING_SPOT_FAIL: {
      return {
        ...state,
        loading: false,
        getMessages: {
          ...state.getMessages,
          error: t`There was an error getting the parking spot. Please try again.`,
        },
      };
    }
    case GET_PARKING_SPOT_SUCCESS: {
      const parkingSpots = { ...state.parkingSpots };

      parkingSpots[action.payload.data.result.data.id] = action.payload.data.result.data;

      return {
        ...state,
        loading: false,
        getMessages: {
          ...state.getMessages,
          success: t`Parking spot fetched`,
        },
        parkingSpots,
      };
    }

    default:
      return state;
  }
}

export const setLoading = (payload: boolean): SetLoading => ({ type: SET_LOADING, payload });

// Actions
export function addLocationParkingSpot(data: AddLocationParkingSpotRequest): AddLocationParkingSpot {
  return {
    type: ADD_LOCATION_PARKING_SPOT,
    payload: {
      request: {
        method: 'POST',
        url: `/api/parking/${data.locationId}`,
        data: data.data,
      },
    },
  };
}

export function deleteParkingSpot(data: DeleteParkingSpotRequest): DeleteParkingSpot {
  return {
    type: DELETE_PARKING_SPOT,
    payload: {
      request: {
        method: 'DELETE',
        url: `/api/parking/${data.parkingSpotId}`,
      },
    },
  };
}

export function editParkingSpot(data: EditParkingSpotRequest): EditParkingSpot {
  return {
    type: EDIT_PARKING_SPOT,
    payload: {
      request: {
        method: 'PUT',
        url: `/api/parking/${data.parkingSpotId}`,
        data: data.data,
      },
    },
  };
}

export function getLocationAvailableParkingSpots(data: GetLocationAvailableParkingSpotsRequest): GetLocationAvailableParkingSpots {
  return {
    type: GET_LOCATION_AVAILABLE_PARKING_SPOTS,
    payload: {
      request: {
        method: 'GET',
        url: `/api/parking/${data.locationId}/parkingSpots`,
        data: data.data,
      },
    },
    locationId: data.locationId,
  };
}

export function getLocationParkingSpots(data: GetLocationParkingSpotsRequest): GetLocationParkingSpots {
  return {
    type: GET_LOCATION_PARKING_SPOTS,
    payload: {
      request: {
        method: 'GET',
        url: `/api/parking/location/${data.locationId}`,
      },
    },
  };
}

export function selectLocationAvailableParkingSpotsSorted(state: State, locationId: string): ParkingSpot[] {
  let parkingSpots = state.locationAvailableParkingSpots[locationId] ?? [];

  // exclude isBooked parking from the select and sort the data
  parkingSpots = parkingSpots.filter(parking => !parking.isBooked).sort((a, b) => {
    const aFloor = a.floor && typeof parseInt(a.floor) === 'number' ? parseInt(a.floor) : a.floor;
    const bFloor = b.floor && typeof parseInt(b.floor) === 'number' ? parseInt(b.floor) : b.floor;

    if ((aFloor ?? 0) > (bFloor ?? 0)) {
      return 1;
    } else if ((aFloor ?? 0) < (bFloor ?? 0)) {
      return -1;
    } else {
      if (a.name > b.name) {
        return 1;
      } else if (a.name < b.name) {
        return -1;
      } else {
        return 0;
      }
    }
  });

  return parkingSpots;
}

export function selectLocationParkingSpots(state: State, locationId: string): ParkingSpot[] {
  return state.locationParkingSpots[locationId] ?? [];
}

/**
 * Returns the ParkingSpot with the specified parkingSpotName in the location with specified locationId.
 * If it doesn't exists, returns undefined.
 */
export function selectLocationParkingSpotByName({
  locationId,
  parkingSpotName,
  state,
}: {
  state: State,
  locationId: string,
  parkingSpotName: string;
}): ParkingSpot | undefined {
  return state.locationParkingSpots[locationId].find(parkingSpot => parkingSpot.name.toLowerCase() === parkingSpotName.toLowerCase());
}
