import AddFloorHeader from 'components/_legacy/Header/AddFloorHeader';
import Box from 'components/_legacy/Box';
import qs from 'qs';
import styles from './styles.module.scss';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import useSnackbar from 'components/_legacy/Snackbar/useSnackbar';
import { t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTypedSelector } from 'store/_legacy/Redux/store';
import {
  setFloorMapData,
} from 'Admin/Store/floorMapDuck';
import DeskListTableHeader from "./DeskListTableHeader";
import DeskListTableBody from "./DeskListTableBody";
import AddFloorDataUpdate from "components/_legacy/Header/AddFloorHeader/Components/AddFloorDataUpdate";
import AddFloorActionMenu from "components/_legacy/Header/AddFloorHeader/Components/AddFloorActionMenu";
import AddFloorDeskListMassActions from "components/_legacy/Header/AddFloorHeader/Components/AddFloorDeskListMassActions";

export default function DeskList() {
  const history = useHistory();
  const { page: pageQuery } = qs.parse(history.location.search.replace('?', ''));

  const dispatch = useDispatch();
  const [openSnackbar] = useSnackbar();
  const [page, setPage] = useState(typeof pageQuery === 'string' ? parseInt(pageQuery) - 1 : 0);
  const [rowsPerPage] = useState(10);
  const { adminFloorMap } = useTypedSelector(state => state);
  const { desks } = adminFloorMap;

  const desksArray = Object.keys(desks).map(deskId => desks[deskId]);
  const desksPerPage = desksArray.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  useEffect(() => {
    history.push({ search: `page=${String(page + 1)}` });
  }, [page]);

  useEffect(() => {
    if (typeof pageQuery === 'string') {
      const pageQueryInt = parseInt(pageQuery);

      if (pageQueryInt !== page + 1) {
        setPage(pageQueryInt);
      }
    }
  }, [history.location.search]);

  useEffect(() => {
    if (adminFloorMap.errorMessage) {
      const handleClose = () => {
        dispatch(setFloorMapData({ errorMessage: '' }));
      };

      openSnackbar({
        onClose: handleClose,
        text: adminFloorMap.errorMessage,
        type: 'error',
      });
    }

    if (adminFloorMap.successMessage) {
      const handleClose = () => {
        dispatch(setFloorMapData({ successMessage: '' }));
      };

      openSnackbar({
        onClose: handleClose,
        text: adminFloorMap.successMessage,
        type: 'success',
      });
    }
  }, [adminFloorMap.errorMessage, adminFloorMap.successMessage]);

  const handleChangePage = (_: any, newPage: number) => {
    setPage(newPage);
  };

  return (
    <>
      <AddFloorHeader>
        <AddFloorDataUpdate />
        <AddFloorDeskListMassActions />
        <AddFloorActionMenu />
      </AddFloorHeader>

      <Box className={styles.deskList}>
        <TableContainer>
          <Table
            aria-label={t`floors listed by location`}
            aria-labelledby={t`floors list`}
          >
            <DeskListTableHeader />
            <DeskListTableBody desksArray={desksPerPage} />
          </Table>

          <Box display="flex" justifyContent="end">
            <TablePagination
              classes={{ root: styles.tablePagination }}
              colSpan={3}
              count={desksArray.length}
              onPageChange={handleChangePage}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[]}
            />
          </Box>
        </TableContainer>
      </Box >
    </>
  );
}
