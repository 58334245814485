import {useTypedSelector} from "store/_legacy/Redux/store";
import Box from "components/_legacy/Box";
import {FormControlLabel} from "@material-ui/core";
import CheckboxCustom from "components/_legacy/CheckBoxCustom";
import styles from "./styles.module.scss";
import NoUsersText from "./NoUsersText";
import {UserManagementUser} from "../../../../../../Store/userManagement/models";
import AllSelectedText from "./AllSelectedText";

interface Props {
  onUsersUpdate: (usersToAdd: UserManagementUser[]) => void;
}

export default function GroupUsersList(props: Props) {
  const { groupDetails } = useTypedSelector(state => state);
  const { usersToAdd, users, infoDataToEdit } = groupDetails;
  const { onUsersUpdate } = props;

  const usersFromSearch = users.filter(user => !usersToAdd.some(u => u.id === user.id)); // all users that not includes users from usersToAdd

  const onUpdateUsers = (checked: boolean, user: UserManagementUser) => {
    if (checked) {
      const updatedUsersToAdd = [...usersToAdd, user];
      return onUsersUpdate(updatedUsersToAdd);
    }

    const updatedUsersToAdd = usersToAdd.filter(usersToAdd => usersToAdd.id !== user.id);
    return onUsersUpdate(updatedUsersToAdd);
  };

  return (
    <Box className={styles.filtersFormControlWrapper} display="grid">
      {
        // show placeholder if select addAll - all users by search criteria
        infoDataToEdit.addAll ?
          <AllSelectedText searchText={infoDataToEdit.addUserSearch}/> :
          // show select list to add users manually
          <>
            {
              usersToAdd.length ?
                usersToAdd.map(((userToAdd, key) => {
                  return (
                    <FormControlLabel
                      className={styles.checkedLabel}
                      control={
                        <CheckboxCustom
                          checked={true} // always checked array
                          classes={{ checked: styles.checked }} // always checked array
                          name={userToAdd.email}
                          onChange={(event: any, checked: boolean) => onUpdateUsers(checked, userToAdd)}
                        />
                      }
                      key={key}
                      label={userToAdd.email}
                    />
                  );
                })) : null
            }
            {
              usersFromSearch.length ?
                usersFromSearch.map(((user, key) => {
                  return (
                    <FormControlLabel
                      control={
                        <CheckboxCustom
                          checked={false}
                          classes={{ checked: styles.checked }}
                          name={user.email}
                          onChange={(event: any, checked: boolean) => onUpdateUsers(checked, user)}
                        />
                      }
                      key={key}
                      label={user.email}
                    />
                  );
                })) : <NoUsersText allUsers={users} searchText={infoDataToEdit.addUserSearch} />
            }
          </>
      }
    </Box>
  );
}
