import {useDispatch} from "react-redux";
import {useTypedSelector} from "store/_legacy/Redux/store";
import {useEffect, useState} from "react";
import DocumentPendingModal from "./Modals/DocumentPendingModal";
import DocumentRequiredModal from "./Modals/DocumentRequiredModal";
import {isLocationRequiredCovidDocument} from "../../../Functions/Helpers";
import {getActualDocument, setProfileData} from "../../../Store/Users/profileDuck";
import {setLocationsData} from "../../../Store/Locations/locationsDuck";
import {setBookingCovidConfirmationData} from "../../../Store/Bookings/covidConfirmationDuck";
import {getUserCovidDocRestrictions, getUserIdForBooking} from "../../../Store/Users/executiveAssistant/helpers";
import {getSelectedUserActualDocument, setExecutiveAssistantData} from "../../../Store/Users/executiveAssistant";

interface Props {
  locationId?: string;
}

export default function CovidDocConfirmation(props: Props) {
  const dispatch = useDispatch();
  const { profile, executiveAssistant, covidCertificate, locations, bookingCovidConfirmation } = useTypedSelector(state => state);
  const { id, actualCovidDocumentLoaded } = profile;
  const { locations: locationsDuck, locationsLoaded } = locations;
  const { openCovidModal } = bookingCovidConfirmation;
  const { certificateLoaded } = covidCertificate;
  const { selectedUser, actualCovidDocumentSelectedUserLoaded } = executiveAssistant;
  const { locationId } = props;
  const [documentPendingModalOpen, setDocumentPendingModalOpen] = useState(false);
  const [documentRequiredModalOpen, setDocumentRequiredModalOpen] = useState(false);

  const { actualCovidDocument, approvedAccommodation } = getUserCovidDocRestrictions(profile, executiveAssistant);
  const selectedUserId = getUserIdForBooking(profile, executiveAssistant);
  const docStatus = actualCovidDocument ? actualCovidDocument.status : null;

  const onOpenModal = () => {
    switch (docStatus) {
      case "needsApproval":
        return setDocumentPendingModalOpen(true);
      case "approved":
        return null; // no popup if doc is approved
      default:
        return setDocumentRequiredModalOpen(true);
    }
  };

  const onResetLoadState = () => {
    dispatch(setLocationsData({ locationsLoaded: false }));
    dispatch(setProfileData({ actualCovidDocumentLoaded: false }));
    dispatch(setExecutiveAssistantData({ actualCovidDocumentSelectedUserLoaded: false }));
  };

  useEffect(() => {
    // load actual document for initial load + if user load a new certificate
    if (selectedUser) { // if another user selected by executiveAssistant
      dispatch(getSelectedUserActualDocument({ userId: selectedUserId }));
    }

    if (!selectedUser) { // if profile user is selected
      dispatch(getActualDocument({ userId: id }));
    }
  }, [certificateLoaded]);

  useEffect(() => {
    // set default load state on component unmount
    return () => {
      onResetLoadState();
    };
  }, []);

  useEffect(() => {
    if (openCovidModal) {
      onOpenModal(); // check popup open after floor page link click
    }
  }, [openCovidModal]);

  useEffect(() => {
    const isEndLoading = (actualCovidDocumentLoaded || actualCovidDocumentSelectedUserLoaded) && locationsLoaded; // wait for locations and profile covid doc loading end

    if (isEndLoading) {
      const isRequired = isLocationRequiredCovidDocument(locationsDuck, locationId, actualCovidDocument, approvedAccommodation);
      // check disable next button on each location update
      if (isRequired) {
        dispatch(setBookingCovidConfirmationData({ disableFloorPage: true }));
      } else {
        dispatch(setBookingCovidConfirmationData({ disableFloorPage: false }));
      }
    }
  }, [locationId, actualCovidDocumentLoaded, actualCovidDocumentSelectedUserLoaded, locationsLoaded]);

  const onCloseDocumentModal = (modalType: 'pending' | 'required') => {
    if (modalType === 'pending') {
      setDocumentPendingModalOpen(false);
    } else {
      setDocumentRequiredModalOpen(false);
    }

    dispatch(setBookingCovidConfirmationData({ openCovidModal: false }));
  };

  return (
    <>
      <DocumentPendingModal
        closeModal={() => onCloseDocumentModal('pending')}
        open={documentPendingModalOpen}
      />
      <DocumentRequiredModal
        closeModal={() => onCloseDocumentModal('required')}
        open={documentRequiredModalOpen}
      />
    </>
  );
}
