import Box from 'components/_legacy/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import clsx from 'clsx';
import Skeleton from '@material-ui/lab/Skeleton';
import styles from './styles.module.scss';
import Text from 'components/_legacy/Text';
import { Floor as FloorModel } from 'App/Store/Locations/locationsDuck/models';
import { t } from '@lingui/macro';
import { Link } from 'react-router-dom';

interface VisitFloorItemProps extends FloorModel {
  onClick: (floorId: string) => any;
  selected?: boolean;
}

/**
 * Loading state for floors.
 * Renders a few placeholders.
 */
export function VisitFloorItemLoading() {
  const item = (
    <Box borderRadius={14} height={90}>
      <Skeleton classes={{ root: styles.skeletonRoot }} height="100%" variant="rect" />
    </Box>
  );

  return (
    <Box display="grid" gap={15}>
      {item}
      {item}
      {item}
      {item}
      {item}
    </Box>
  );
}

/**
 * Renders the floor UI as a dumb component.
 */
export default function VisitFloorItem({
  id,
  floorName,
  onClick,
  selected,
}: VisitFloorItemProps) {
  const classes = clsx(styles.container, selected && styles.selected);

  return (
    <Link onClick={() => onClick(id)} to="/create-new-visit/onsite/select-date">
      <ButtonBase
        aria-label={t`FloorItem`}
        classes={{ root: `${styles.buttonBase} ${styles.floorItem}` }}
        disableRipple
        name={t`FloorItem`}
      >
        <Box className={classes}>
          <Text color={selected ? 'blue' : 'gray'} size="md" weight="semi-bold">
            {floorName}
          </Text>
        </Box>
      </ButtonBase>
    </Link>
  );
}
