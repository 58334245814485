interface Props {
  size?: number;
  color?: string;
  className?: string;
}

export default function ArrowLeft({ className = "", color = "#1E1F7B" }: React.PropsWithChildren<Props>) {
  return (
    <svg className={className} height="18px" viewBox="0 0 10 18" width="10px">
        <title>B5D4023F-8A68-44C9-A92D-90BC246D647D</title>
        <g fill="none" fillRule="evenodd" id="Rooms-booking" stroke="none" strokeWidth="1">
            <g fill={color} id="1.3.0-Book-a-room-select-room-calendar" transform="translate(-245.000000, -176.000000)">
                <g id="Header" transform="translate(140.000000, 171.000000)">
                    <g id="icons/arrow-left" transform="translate(105.000000, 5.000000)">
                        <path d="M7.36396103,1.29289322 C7.75448532,0.902368927 8.3876503,0.902368927 8.77817459,1.29289322 C9.16869888,1.68341751 9.16869888,2.31658249 8.77817459,2.70710678 L2.41421356,9.07106781 L8.77817459,15.4350288 C9.16869888,15.8255531 9.16869888,16.4587181 8.77817459,16.8492424 C8.3876503,17.2397667 7.75448532,17.2397667 7.36396103,16.8492424 L0.292893219,9.77817459 C-0.0976310729,9.3876503 -0.0976310729,8.75448532 0.292893219,8.36396103 L7.36396103,1.29289322 Z"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>    
  );
}
