import { Autocomplete, AutocompleteInputChangeReason } from "@material-ui/lab";
import styles from "../../../Locations/LocationForm/styles.module.scss";
import { Trans, t } from "@lingui/macro";
import Box from "components/_legacy/Box";
import arrowIcon from "../../../Locations/LocationForm/assets/arrow-down.svg";
import { AutocompleteRenderInputParams } from "@material-ui/lab/Autocomplete/Autocomplete";
import { TextField } from "@material-ui/core";
import { AdminRoomStatusType, IAdminRoomStatus } from "../../../../Store/roomsManagement/models";
import { roomStatusList } from "../../../../Store/roomsManagement";

interface Props {
  selectedValue: AdminRoomStatusType;
  setErrorClass: Function;
  setSelectedValue: Function;
  errorClass: string;
}

export default function Status(props: Props) {
  const { selectedValue, setSelectedValue, errorClass, setErrorClass } = props;

  const onResetSearchState = () => {
    setSelectedValue('');
  };

  const onStatusChange = (event: any, status: IAdminRoomStatus) => {
    setSelectedValue(status?.value);

    if (status?.value) {
      setErrorClass(false);
    }
  };

  const onSearchChange = (event: any, value: string, reason: AutocompleteInputChangeReason) => {
    if (reason !== 'reset' && reason !== 'clear') { // 'reset' fires on select from list, no need to handle api
      onResetSearchState();
    }
  };

  return (
    <>
      <span className={styles.requiredSign}>*</span><label htmlFor="room-status"><Trans>Status</Trans></label>
      <Box display="flex" justifyContent="between">
        <Box direction="column" display="flex" width="100%">
          <Autocomplete
            className={`input input--default input--inline`}
            filterOptions={(x) => x} // fix load throttling
            getOptionLabel={(option) => option.name}
            id="address"
            onChange={(event: any, user: any) => onStatusChange(event, user)}
            onInputChange={(event, value, reason) => onSearchChange(event, value, reason)}
            options={roomStatusList}
            popupIcon={
              <img height={10} src={arrowIcon} width={14} />
            }
            renderInput={(params: AutocompleteRenderInputParams) => {
              return (
                <TextField
                  {...params}
                  className={`input input--default ${errorClass}`}
                  placeholder={t`Not selected`}
                  variant="outlined"
                />
              );
            }}
            value={roomStatusList.find(status => status.value === selectedValue)}
          />
        </Box>
      </Box>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      {errorClass ? <div className={styles.formErrors}><Trans>This field can't be empty</Trans></div> : null}
    </>
  );
}
