import { RoomServiceType } from "App/Store/Meetings/meetingRooms/models";

export enum MeetingStatusEnum {
  Available = "AVAILABLE",
  Unavailable = "UNAVAILABLE",
  // ApprovalRequired = "APPROVAL_REQUIRED", // not implemented in BE yet
}

export type MeetingStatus =
  | MeetingStatusEnum.Available
  | MeetingStatusEnum.Unavailable
  // | MeetingStatusEnum.ApprovalRequired // not implemented in BE yet

export interface ResourceAvailableService {
  type: RoomServiceType;
  emails?: string[];
}
