import { useRef, useState } from "react";
import L from 'leaflet';
import { FloorMapObject } from 'Admin/Store/floorMapDuck/models';
import { Polygon } from 'react-leaflet';
import NeighborsTooltip, {INeighborsTooltipFilterData} from '../../../../../../Admin/Pages/FloorMap/Map/MapDrawing/Components/NeighborsTooltip';
import DrawingNameLabel from "Admin/Pages/FloorMap/Map/MapDrawing/Components/DrawingNameLabel";
import {useTypedSelector} from "store/_legacy/Redux/store";
import {useParams} from "react-router-dom";
import { resolvePersistedDate } from "store/_legacy/Utils";
import { Neighbor } from "App/Store/Bookings/sectionNeighbors/models";

type neighborsDataType = 'createNewBooking' | 'adminReservationJustInTime' | 'reservationsMapViewDetails'

interface Props {
  section: FloorMapObject;
  zoomLevel: number;
  showNeighbors?: boolean;
  neighborsData?: neighborsDataType; // used with showNeighbors: true for neighbors filters
  neighbors?: Neighbor[];
}

/**
 * Section Drawing
 *
 * This component handles deletion and edition internally.
 * When adding desks, it sends the state to the <Map /> component so it can
 * enable add desk on <CreateDesk />, associating that creation with this section id.
 *
 * React Leaflet Draw handles it's own states, so this component syncs them with internal states.
 */
export default function SectionObject({ section, zoomLevel, showNeighbors, neighbors, neighborsData = 'createNewBooking'}: Props) {
  const { createNewBooking, adminReservationJustInTime, adminReservations } = useTypedSelector(state => state);
  const { coordinatesInLatLngBounds } = section;
  const ref = useRef<any>(null);
  const arrayOfCoordinatesInLatLng = coordinatesInLatLngBounds.map(coordinate => L.latLng(coordinate.lat, coordinate.lng));
  const [iconMarkupOpacity, setIconMarkupOpacity] = useState(1);

  const getNeighborsTooltip = (showNeighbors: boolean | undefined, neighborsData: neighborsDataType) => {
    if (!showNeighbors) {
      return null;
    }

    if (neighbors) {
      return (
      <NeighborsTooltip
        hasFilters
        neighbors={neighbors}
        section={section}
        sectionRef={ref.current}
        setIconMarkupOpacity={setIconMarkupOpacity}
        zoomLevel={zoomLevel}
      />
    );
    }

    let neighborsFloorId = createNewBooking.floorId;
    let neighboursFilters: INeighborsTooltipFilterData = {
      bookingType: createNewBooking.bookingType,
      dateFrom: createNewBooking.dateFrom,
      dateTo: createNewBooking.dateTo,
      timeFrom: resolvePersistedDate(createNewBooking.timeFrom),
      timeTo: resolvePersistedDate(createNewBooking.timeTo),
      weekDays: createNewBooking.weekDays,
    };

    switch (neighborsData) {
      case "createNewBooking": {
        neighborsFloorId = createNewBooking.floorId;
        neighboursFilters = {
          bookingType: createNewBooking.bookingType,
          dateFrom: createNewBooking.dateFrom,
          dateTo: createNewBooking.dateTo,
          timeFrom: resolvePersistedDate(createNewBooking.timeFrom),
          timeTo: resolvePersistedDate(createNewBooking.timeTo),
          weekDays: createNewBooking.weekDays,
        };
        break;
      }
      case "adminReservationJustInTime": {
        neighborsFloorId = adminReservationJustInTime.selectedData.floor?.id as string;
        neighboursFilters = {
          bookingType: adminReservationJustInTime.bookingType,
          dateFrom: adminReservationJustInTime.dateFrom,
          dateTo: adminReservationJustInTime.dateTo,
          timeFrom: adminReservationJustInTime.timeFrom,
          timeTo: adminReservationJustInTime.timeTo,
          weekDays: adminReservationJustInTime.weekDays,
        };
        break;
      }
      case "reservationsMapViewDetails": { // this page has only custom bookings with different dates for filtering | floorId is given from URL - see ReservationsMapViewDetails component
        const { floorId } = useParams<{ floorId: string; }>();

        neighborsFloorId = floorId;
        neighboursFilters = {
          bookingType: 'custom',
          dateFrom: adminReservations.filters.selectedStartDate,
          dateTo: adminReservations.filters.selectedEndDate,
        };
        break;
      }
    }

    return (
      <NeighborsTooltip
        floorId={neighborsFloorId}
        hasFilters
        neighboursFilters={neighboursFilters}
        section={section}
        sectionRef={ref.current}
        setIconMarkupOpacity={setIconMarkupOpacity}
        zoomLevel={zoomLevel}
      />
    );
  };

  const neighborsTooltip = getNeighborsTooltip(showNeighbors, neighborsData);

  const detectActiveElements = (e: any) => {
    const classList = e.originalEvent?.toElement?.classList || {};
    const isInnerDesk = Object.values(classList).includes('leaflet-interactive');
    const isPopup = Object.values(classList).includes('neighbors-popup');

    return { isInnerDesk, isPopup };
  };

  const toggleIconMarkupOpacity = (set: boolean, e: any) => {
    const { isInnerDesk, isPopup } = detectActiveElements(e);
    return set || isInnerDesk || isPopup ?
      setIconMarkupOpacity(0) : setIconMarkupOpacity(1);
  };

  const toggleNeighboursPopup = (set: boolean, e: any) => {
    const { isInnerDesk, isPopup } = detectActiveElements(e);

    if (set) {
      e.target.openPopup();
    }

    if (!set && !isPopup && !isInnerDesk) {
      e.target.closePopup();
    }
  };

  return (
    <Polygon
      eventHandlers={{
        mouseover: (e) => {
          toggleIconMarkupOpacity(true, e);
          toggleNeighboursPopup(true, e);
        },
        mouseout:(e: any) => {
          toggleIconMarkupOpacity(false, e);
          toggleNeighboursPopup(false, e);
        },
      }}
      pathOptions={{
        color: section.line,
        fillColor: section.line,
      }}
      positions={arrayOfCoordinatesInLatLng}
      ref={ref}
    >
      <DrawingNameLabel
        drawing={section}
        iconMarkupOpacity={iconMarkupOpacity}
        sectionLabelKey={1} // use 1 by default / it triggered only with section coordinate updates in admin panel
        sectionRef={ref.current}
      />
      {neighborsTooltip}
    </Polygon>
  );
}
