import { useDispatch } from 'react-redux';
import { useHistory } from "react-router";
import { useTypedSelector } from 'store/_legacy/Redux/store';
import _ from 'underscore';
import moment from 'moment';

import styles from '../LocationDetailsHeader/styles.module.scss';
import TextField from '@material-ui/core/TextField';
import Box from 'components/_legacy/Box';
import Text from 'components/_legacy/Text';
import Heading from 'components/_legacy/Heading';
import Button from 'components/_legacy/Button';
import ReservationFilters from 'Admin/Pages/Reservations/ReservationFilters';
import ReservationExportCsv from 'Admin/Pages/Reservations/ReservationExportCsv';
import ReservationHeaderCalendar from 'Admin/Pages/Reservations/ReservationHeaderCalendar';
import {
  bulkCancelReservationsBooking,
  resetReservationMassActionsIds,
  setReservationsData,
  updateReservationMassActions,
} from 'Admin/Store/reservations';
import { Trans, t } from '@lingui/macro';
import Popup from "reactjs-popup";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ButtonBase from "@material-ui/core/ButtonBase";
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import {
  getAllowedLocationsByRoles,
  getLocationIdsByRoles,
} from "Admin/Pages/Locations/Helpers";

export default function ReservationHeader() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { adminReservations, config, profile, locations } = useTypedSelector(state => state);
  const { reservationMassActions, reservationPageType, reservationMassActionsIds, filters } = adminReservations;
  const { selectedStartDate, selectedEndDate, statuses, search } = filters;
  const { roleAccess, locationIds: profileLocationIds } = profile;

  const showMassActionCounter = adminReservations.reservationMassActions[reservationPageType];
  // set restrictions for local admin role
  const allowedLocations = getAllowedLocationsByRoles(roleAccess, locations.adminLocations, profileLocationIds);
  const restrictedLocationIds = getLocationIdsByRoles(roleAccess, filters, allowedLocations);

  const onSearchChange = _.debounce((event: any) => {
    dispatch(setReservationsData({
      filters: {
        ...filters,
        search: event.target.value,
      },
    }));
  },300);

  const onMassActionChange = () => {
    const updatedMassActions = {
      ...reservationMassActions,
      [reservationPageType]: !reservationMassActions[reservationPageType],
    };

    // clear mass action selection after hide mass actions
    if (!reservationMassActions[reservationPageType]) {
      dispatch(resetReservationMassActionsIds());
    }

    dispatch(updateReservationMassActions(updatedMassActions));
  };

  const onResetAllSelected = () => {
    dispatch(setReservationsData({
      filters: {
        ...filters,
        allSelected: false,
      },
    }));
  };

  const onUnselectDesks = (event: any) => {
    event.stopPropagation();
    dispatch(resetReservationMassActionsIds());
  };

  const onBulkCancelReservations = () => {
    dispatch(bulkCancelReservationsBooking({
      bookingIds: reservationMassActionsIds,
      cancelAll: filters.allSelected,
      statuses,
      locationIds: restrictedLocationIds,
      search,
      dateFrom: moment(selectedStartDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss'), // start of day in iso format
      dateTo: moment(selectedEndDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss'), // end of day in iso format
    }));
  };

  const onOpenCreateReservationPage = () => {
    history.push(`/admin/create-reservation`);
  };

  return (
    <Box className={styles.header}>
      <Box alignItems="center" display="flex" justifyContent="between" marginBottom={15}>
        <Box>
          <Heading size="sm">
            <Trans>Reservations</Trans>
          </Heading>

        </Box>

        <Box alignItems="center" display="flex" justifyContent="between">
          <Box alignItems="center" display="flex" gap={20}>
            {reservationPageType === 'list' && <ReservationExportCsv />}
            <ReservationHeaderCalendar />
            <TextField
              InputProps={{
                startAdornment: (
                  <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px">
                    <g fill="none" fillRule="evenodd" id="Symbols" stroke="none" strokeWidth="1">
                      <g fill="#5E5E5E" fillRule="nonzero" id="icons/search">
                        <g id="delete-white-copy-5">
                          <path d="M6.05025253,6.05025253 C8.78392257,3.31658249 13.2160774,3.31658249 15.9497475,6.05025253 C18.4434716,8.54397661 18.6623564,12.4511046 16.6064021,15.1929497 L20.1923882,18.7781746 C20.5829124,19.1686989 20.5829124,19.8018639 20.1923882,20.1923882 C19.8018639,20.5829124 19.1686989,20.5829124 18.7781746,20.1923882 L15.1929497,16.6064021 C12.4511046,18.6623564 8.54397661,18.4434716 6.05025253,15.9497475 C3.31658249,13.2160774 3.31658249,8.78392257 6.05025253,6.05025253 Z M14.5355339,7.46446609 C12.5829124,5.51184464 9.41708755,5.51184464 7.46446609,7.46446609 C5.51184464,9.41708755 5.51184464,12.5829124 7.46446609,14.5355339 C9.41708755,16.4881554 12.5829124,16.4881554 14.5355339,14.5355339 C16.4881554,12.5829124 16.4881554,9.41708755 14.5355339,7.46446609 Z" id="Combined-Shape"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                ),
              }}
              className={`input input--default input--search ${styles.searchInput}`}
              data-testid="reservation-search"
              defaultValue={''}
              fullWidth
              onChange={onSearchChange}
              placeholder={t`Search`}
              variant="outlined"
            />
            {
              reservationPageType === 'list' &&
              <div data-testid="reservation-mass-actions">
                <Button onClick={onMassActionChange} size="xs">
                  <Box alignItems="center" display="flex">
                    <span style={{marginTop: '1px'}}>Mass Actions</span>
                    {
                      showMassActionCounter && !filters.allSelected ?
                        <span className={styles.massActionCounter}>
                          <span>{reservationMassActionsIds.length}</span>
                          <div
                            className={styles.massActionCountClose}
                            onClick={onUnselectDesks}
                          >
                            <svg height="12px" viewBox="0 0 12 12" width="12px">
                                  <g fill="none" fillRule="evenodd" id="UI" stroke="none" strokeWidth="1">
                                      <g fill="#1E1F7B" id="UI-guidelines" transform="translate(-1101.000000, -2780.000000)">
                                          <g id="Group" transform="translate(478.000000, 2647.000000)">
                                              <g id="icons/close" transform="translate(623.000000, 133.000000)">
                                                  <path d="M11.704633,0.295367042 C12.0984557,0.689189766 12.0984557,1.32770256 11.704633,1.72152528 L7.42615824,6 L11.704633,10.2784747 C12.0984557,10.6722974 12.0984557,11.3108102 11.704633,11.704633 C11.3108102,12.0984557 10.6722974,12.0984557 10.2784747,11.704633 L6,7.42615824 L1.72152528,11.704633 C1.32770256,12.0984557 0.689189766,12.0984557 0.295367042,11.704633 C-0.0984556808,11.3108102 -0.0984556808,10.6722974 0.295367042,10.2784747 L4.57384176,6 L0.295367042,1.72152528 C-0.0984556808,1.32770256 -0.0984556808,0.689189766 0.295367042,0.295367042 C0.689189766,-0.0984556808 1.32770256,-0.0984556808 1.72152528,0.295367042 L6,4.57384176 L10.2784747,0.295367042 C10.6722974,-0.0984556808 11.3108102,-0.0984556808 11.704633,0.295367042 Z"></path>
                                              </g>
                                          </g>
                                      </g>
                                  </g>
                              </svg>
                          </div>
                        </span> : null
                    }
                    {
                      filters.allSelected ?
                        <span className={styles.removeSelection} onClick={onResetAllSelected}>
                      <svg className="modal-header__close" height="20px" viewBox="5 5 20 20" width="20px">
                        <path
                          d="M20.704633,9.29536704 C21.0984557,9.68918977 21.0984557,10.3277026 20.704633,10.7215253 L16.4261582,15 L20.704633,19.2784747 C21.0984557,19.6722974 21.0984557,20.3108102 20.704633,20.704633 C20.3108102,21.0984557 19.6722974,21.0984557 19.2784747,20.704633 L15,16.4261582 L10.7215253,20.704633 C10.3277026,21.0984557 9.68918977,21.0984557 9.29536704,20.704633 C8.90154432,20.3108102 8.90154432,19.6722974 9.29536704,19.2784747 L13.5738418,15 L9.29536704,10.7215253 C8.90154432,10.3277026 8.90154432,9.68918977 9.29536704,9.29536704 C9.68918977,8.90154432 10.3277026,8.90154432 10.7215253,9.29536704 L15,13.5738418 L19.2784747,9.29536704 C19.6722974,8.90154432 20.3108102,8.90154432 20.704633,9.29536704 Z"
                          fill={config.theme.primary}
                        ></path>
                      </svg>
                    </span> : null
                    }
                    {
                      showMassActionCounter && reservationMassActionsIds.length ?
                        <Popup
                          arrow={false}
                          closeOnDocumentClick
                          contentStyle={{ marginLeft: 10, width: 230 }}
                          offsetY={10}
                          position="bottom right"
                          trigger={
                            <IconButton
                              aria-label="cancel reservations"
                              name="cancel reservations"
                              style={{ padding: 0, width: '20px', height: '30px' }}
                            >
                              <MoreVertIcon style={{ color: '#fff', fontSize: 24 }} />
                            </IconButton>
                          }
                        >
                          {(close: any) => (
                            <Box borderStyle="shadow" className="popover-content" dataTestId="floor-item-options-popup">
                              <ButtonBase
                                className="popover-item-button"
                                onClick={() => {
                                  onBulkCancelReservations();
                                  onMassActionChange();
                                  close();
                                }}
                              >
                                <CreateOutlinedIcon style={{ color: config.theme.primary, fontSize: 20 }} />

                                <Box marginStart={5}>
                                  <Text color="blue" size="md">
                                    Cancel reservations
                                  </Text>
                                </Box>
                              </ButtonBase>

                            </Box>
                          )}
                        </Popup> : null
                    }
                  </Box>
                </Button>
              </div>
            }
            <ReservationFilters />
            <Button data-testid="reservation-create-reservation" onClick={onOpenCreateReservationPage} size="xs">
              <Trans>Create Reservation</Trans>
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
