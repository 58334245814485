import { Box, Divider, MenuItem, SelectProps, Typography, useTheme } from "@mui/material";
import React from "react";
import { EditorFormatMarkButton } from "./editor-format-mark-button";
import { CodeRounded, FormatAlignCenterRounded, FormatAlignJustifyRounded, FormatAlignLeftRounded, FormatAlignRightRounded, FormatBoldRounded, FormatClearRounded, FormatItalicRounded, FormatListBulletedRounded, FormatListNumberedRounded, FormatStrikethroughRounded, FormatUnderlinedRounded, InsertPhotoOutlined } from "@mui/icons-material";
import { StandardSelect } from "components/select";
import { t } from "@lingui/macro";
import { useSlate } from "slate-react";
import { editorElementToReactElementType, getActiveEditorElement, reactElementTypeToEditorElement, toggleEditorAlignment, toggleEditorElement } from "./utils";
import { EditorElementButton } from "./editor-element-button";
import { EditorElementType } from "./enums";
import { EditorAlignmentButton } from "./editor-alignment-button";
import { EditorFormatMark } from "./types";
import { EditorToolbarButton } from "./editor-toolbar-button";
import { EditorLinkButton } from "./editor-link-button";
import { EditorBoxButton } from "./editor-box-button";

export const EditorToolbar: React.FC = () =>  {
  const { palette } = useTheme();
  const editor = useSlate();
  const element = getActiveEditorElement(editor);

  const handleTextElementSelectChange: SelectProps["onChange"] = (event) => {
    const value = event.target.value as React.ElementType;
    const element = reactElementTypeToEditorElement(value);

    if (element) {
      toggleEditorElement(editor, element);
    }
  };

  const handleFormatClearClick = () => {
    const marks: EditorFormatMark[] = ["bold", "italic", "line-through", "underline"];

    toggleEditorElement(editor, { type: EditorElementType.PARAGRAPH });
    toggleEditorAlignment(editor, "left");
    
    for (const mark of marks) {
      editor.removeMark(mark);
    }
  };

  const handleInsertImageClick = () => {
    editor.insertNode({ type: EditorElementType.IMAGE, children: [{ text: "" }] });
    editor.insertNode({ type: EditorElementType.PARAGRAPH, children: [{ text: "" }] });
  };

  const handleInsertHTMLClick = () => {
    editor.insertNode({ type: EditorElementType.HTML, children: [{ text: "" }] });
  };

  return (
    <Box bgcolor="#fff" borderRadius={2} display="flex" height={28}>
      <EditorFormatMarkButton
        Icon={FormatBoldRounded}
        format="bold"
        sx={{ borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }}
        title={t`Format bold`}
      />
      <EditorFormatMarkButton Icon={FormatItalicRounded} format="italic" title={t`Format italic`} />
      <EditorFormatMarkButton Icon={FormatUnderlinedRounded} format="underline" title={t`Format underline`} />
      <EditorFormatMarkButton Icon={FormatStrikethroughRounded} format="line-through" title={t`Format line through`} />
      <Divider orientation="vertical" sx={{ height: 20, my: "auto" }} />
      <StandardSelect
        onChange={handleTextElementSelectChange}
        sx={{ width: 120, "& .MuiSelect-select": { py: 0, fontWeight: 400, pl: 1, pt: "3px", pb: "2px" }, "& .MuiSvgIcon-root": { mt: "3px" } }}
        title={t`Select text element`}
        value={element ? editorElementToReactElementType(element) || "p" : "p"}
      >
        <MenuItem value="p">{t`Paragraph`}</MenuItem>
        <MenuItem value="h1">{t`Heading 1`}</MenuItem>
        <MenuItem value="h2">{t`Heading 2`}</MenuItem>
        <MenuItem value="h3">{t`Heading 3`}</MenuItem>
        <MenuItem value="h4">{t`Heading 4`}</MenuItem>
        <MenuItem value="h5">{t`Heading 5`}</MenuItem>  
        <MenuItem value="h6">{t`Heading 6`}</MenuItem>
      </StandardSelect>
      <Divider orientation="vertical" sx={{ height: 20, my: "auto" }} />
      <EditorElementButton
        Icon={FormatListNumberedRounded}
        element={{ type: EditorElementType.LIST, ordering: "ordered" }}
        title={t`Numbered list`}
      />
      <EditorElementButton
        Icon={FormatListBulletedRounded}
        element={{ type: EditorElementType.LIST, ordering: "unordered" }}
        title={t`Bullet list`}
      />
      <Divider orientation="vertical" sx={{ height: 20, my: "auto" }} />
      <EditorAlignmentButton Icon={FormatAlignLeftRounded} alignment="left" title={t`Align left`} />
      <EditorAlignmentButton Icon={FormatAlignRightRounded} alignment="right" title={t`Align right`} />
      <EditorAlignmentButton Icon={FormatAlignJustifyRounded} alignment="justify" title={t`Justify`} />
      <EditorAlignmentButton Icon={FormatAlignCenterRounded} alignment="center" title={t`Align center`} />
      <Divider orientation="vertical" sx={{ height: 20, my: "auto" }} />
      <EditorToolbarButton Icon={FormatClearRounded} onMouseDown={() => handleFormatClearClick()} title={t`Clear format`} />
      <EditorLinkButton />
      <EditorToolbarButton
        Icon={InsertPhotoOutlined}
        onMouseDown={() => handleInsertImageClick()}
        title={t`Insert image`}
      />
      <Divider orientation="vertical" sx={{ height: 20, my: "auto" }} />
      <EditorBoxButton />
      <Divider orientation="vertical" sx={{ height: 20, my: "auto" }} />
      <EditorToolbarButton Icon={CodeRounded} onClick={() => handleInsertHTMLClick()} title={t`Insert html`}>
        <Typography color={palette.grey[700]} fontSize={14} lineHeight={1} ml={0.5} textTransform="lowercase">html</Typography>
      </EditorToolbarButton>
    </Box>
  );
};
