import {useDispatch} from "react-redux";
import {useTypedSelector} from "store/_legacy/Redux/store";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { t } from "@lingui/macro";
import { UsersSelect } from "components";
import { useState } from "react";
import { User } from "store";
import { updateDesk } from "Admin/Store/floorMapDuck";
import { MapDrawingOwner } from "Admin/Store/floorMapDuck/models";

type MassActionsEditOwnersProps = {
  isOpen: boolean;
  onClose: () => void;
};

export default function MassActionsEditOwners(props: MassActionsEditOwnersProps) {
  const { isOpen, onClose } = props;
  const dispatch = useDispatch();
  const desksIds = useTypedSelector((state) => Object.keys(state.adminFloorMap.editDeskList.selectedDesks));
  const [owners, setOwners] = useState<User[]>([]);

  const handleSave = () => {
    for (const deskId of desksIds) {
      dispatch(updateDesk({
        deskId,
        updateDeskProperties: {
          owners: owners as MapDrawingOwner[],
        },
      }));
    }
    
    onClose();
  };

  return (
    <Dialog onClose={onClose} open={isOpen}>
      <DialogTitle>{t`Select desk owners`}</DialogTitle>
      <DialogContent>
        <Box width={360}>
          <UsersSelect onChange={setOwners} value={owners} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave}>{t`Save`}</Button>
      </DialogActions>
    </Dialog>
  );
}
