import {useDispatch} from "react-redux";
import {useTypedSelector} from "store/_legacy/Redux/store";
import {setReservationsData} from "../../../../Store/reservations";
import Box from "components/_legacy/Box";
import styles from "../StatusFilter/styles.module.scss";
import {FormControlLabel} from "@material-ui/core";
import CheckboxCustom from "components/_legacy/CheckBoxCustom";
import {checkingType} from "../../../../Store/reservations/models";
import Text from "components/_legacy/Text";
import FilterAccordion from "components/_legacy/Filters/FilterAccordion";

interface CheckinFilterProps {
  initialFiltersState: { [key in checkingType]: boolean },
  filters: { [key in checkingType]: boolean },
  setFilters: Function,
}

export default function CheckinFilter({
  filters,
  initialFiltersState,
  setFilters,
}: CheckinFilterProps) {
  const dispatch = useDispatch();
  const { adminReservations } = useTypedSelector(state => state);

  const { filters: reduxFilters } = adminReservations;

  const localCounter = Object.keys(filters).map((key: any) => key)
    .filter((key: checkingType) => filters[key]).length;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({ ...filters, [event.target.name]: event.target.checked });
  };

  const onResetFilter = () => {
    dispatch(setReservationsData({
      filters: {
        ...reduxFilters,
        checking: undefined,
      },
    }));

    setFilters(initialFiltersState);
  };

  return (
    <Box display="flex" justifyContent="between" marginTop={10}>
      <FilterAccordion headerName="Check-in" localCounter={localCounter} resetFilter={onResetFilter}>
        <Box className={styles.filtersFormControlWrapper} display="grid">
          <FormControlLabel
            className={`${styles.docStatusStyle}`}
            control={(
              <CheckboxCustom
                checked={filters.yes}
                classes={{ checked: styles.checked }}
                name="yes"
                onChange={handleChange}
              />
            )}
            label={(
              <Box alignItems='center' display='flex' gap={5} marginStart={3}>
                <Text size="md" weight='regular'>
                  Yes
                </Text>
              </Box>
            )}
          />

          <FormControlLabel
            className={`${styles.docStatusStyle}`}
            control={(
              <CheckboxCustom
                checked={filters.no}
                classes={{ checked: styles.checked }}
                name="no"
                onChange={handleChange}
              />
            )}
            label={(
              <Box alignItems='center' display='flex' gap={5} marginStart={3}>
                <Text size="md" weight='regular'>
                  No
                </Text>
              </Box>
            )}
          />
        </Box>
      </FilterAccordion>
    </Box>
  );
}