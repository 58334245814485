import Box from 'components/_legacy/Box';
import DeleteNotification from './DeleteModal';
import moment from 'moment';
import NotificationForm from '../NotificationForm';
import styles from './styles.module.scss';
import { NotificationModel } from 'Admin/Store/notifications/models';
import { Trans } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useTypedSelector } from 'store/_legacy/Redux/store';
import {
  deleteNotification,
  resetDateRangeDate,
  resetWhenToSendDate, sendNotification,
} from '../../../Store/notifications';

interface NotificationProps {
  notification: NotificationModel;
  index: number
}

export default function Notification(props: NotificationProps) {
  const dispatch = useDispatch();
  const { config } = useTypedSelector(state => state);
  const [editState, setEditState] = useState(false);

  const {
    notification,
    index,
  } = props;

  const onSendNotification = () => {
    const notificationEmailData = {
      recipientType: notification.recipients,
      startDate: notification.startDate,
      endDate: notification.endDate,
    };

    dispatch(sendNotification({ notificationId: notification.id, notificationEmailData }));
  };

  const onEditNotification = () => {
    setEditState(!editState);
    dispatch(resetWhenToSendDate());
    dispatch(resetDateRangeDate());
  };

  const onCancelNotificationForm = () => {
    setEditState(false);
    dispatch(resetWhenToSendDate());
    dispatch(resetDateRangeDate());
  };

  const onDeleteNotification = () => {
    dispatch(deleteNotification({ notificationId: notification.id }));
  };

  const notifWhenDate = moment(notification.sendDate).utc().format('MMM DD, h:mma');
  const notifStatusDate = moment(notification.sendDate).utc().format('MM/DD/YYYY');
  const notificationStatusClass = notification.status === 'pending' ? styles.notificationStatusPending : '';

  return (
    <Box alignItems="start" className={styles.notification} display="flex" justifyContent="between">
      {editState ? (
        <NotificationForm
          actionState={'edit'}
          notification={notification}
          onCancelAction={onCancelNotificationForm}
        />
      )
        : <Box>
          <Box className={styles.notificationHeader} display="flex" marginBottom={5} position="relative">
            <h3>{notification.subject}</h3>
            <svg className={styles.notificationAction} height="24px" onClick={onSendNotification} version="1.1" viewBox="0 0 24 24" width="24px">
              <g fill="none" fillRule="evenodd" id="A-locations" stroke="none" strokeWidth="1">
                <g className={styles.fillColor} fill={config.theme.primary} id="A-Location-notifications" transform="translate(-409.000000, -375.000000)">
                  <g id="icons/sendmessage" transform="translate(409.000000, 375.000000)">
                    <path d="M6.48796073,3.12922004 L20.4845789,11.1037845 C20.9681095,11.3792757 21.142397,12.004737 20.8738609,12.5007913 C20.7830628,12.6685184 20.6482998,12.8068122 20.4848336,12.9000108 L6.50504246,20.870449 C6.02158998,21.1460846 5.41186877,20.9674649 5.14319201,20.4714908 C4.99646365,20.200632 4.97673748,19.8763018 5.08950758,19.5888318 L7.8904086,12.4488625 C7.9864183,12.2041176 7.98697634,11.9308618 7.89196711,11.6857063 L5.07112207,4.40698535 C4.86677865,3.87971119 5.11777435,3.28232746 5.63173667,3.07269145 C5.9109583,2.95880196 6.22527181,2.97955319 6.48796073,3.12922004 Z M16.27,12.999 L9.815,12.999 L9.75227406,13.1792439 L7.965,17.735 L16.27,12.999 Z M7.931,6.253 L9.75682063,10.9629883 L9.769,10.999 L16.262,10.999 L7.931,6.253 Z" id="Combined-Shape"></path>
                  </g>
                </g>
              </g>
            </svg>
            <svg className={styles.notificationAction} height="24px" onClick={onEditNotification} version="1.1" viewBox="0 0 24 24" width="24px">
              <g fill="none" fillRule="evenodd" id="A-locations" stroke="none" strokeWidth="1">
                <g className={styles.fillColor} fill={config.theme.primary} fillRule="nonzero" id="A-Location-notifications" transform="translate(-329.000000, -185.000000)">
                  <g id="icons/editpencilgrey" transform="translate(329.000000, 185.000000)">
                    <path d="M17.2077147,4.29289322 L19.329035,6.41421356 C19.7195593,6.80473785 19.7195593,7.43790283 19.329035,7.82842712 L9.42954011,17.7279221 L5.89400621,14.1923882 L15.7935011,4.29289322 C16.1840254,3.90236893 16.8171904,3.90236893 17.2077147,4.29289322 Z M16.5006079,6.41421356 L8.72243333,14.1923882 L9.42954011,14.8994949 L17.2077147,7.12132034 L16.5006079,6.41421356 Z M5.31649907,19.5703403 C5.11123226,19.6387625 4.88931034,19.6387625 4.68404354,19.5703403 C4.16010022,19.3956925 3.87694023,18.8293725 4.051588,18.3054292 L5.18689943,14.8994949 L8.72243333,18.4350288 L5.31649907,19.5703403 Z" id="pen"></path>
                  </g>
                </g>
              </g>
            </svg>
            <DeleteNotification deleteNotification={onDeleteNotification} index={index} />
          </Box>
          <div className={styles.notificationTextBlock}>
            <h4>
              <Trans>Message</Trans>
            </h4>

            <div className={styles.notificationText}>{notification.message}</div>
          </div>
          <div className={styles.notificationTextBlock}>
            <h4>
              <Trans>When:</Trans>
            </h4>

            <div className={styles.notificationText}>{notifWhenDate}</div>
          </div>
        </Box>
      }
      {!editState ? (
        <Box display="flex" justifyContent='end' marginLeft={20} width={200}>
          <Box alignItems="center" display="flex">
            <div className={styles.notificationStatusDate}>{notifStatusDate}</div>
            <div className={`${styles.notificationStatus} ${notificationStatusClass}`}>
              {notification.status}
            </div>
          </Box>
        </Box>
      ) : null
      }
    </Box>
  );
}
