import moment from "moment";
import {useTypedSelector} from "store/_legacy/Redux/store";
import Box from "components/_legacy/Box";
import styles from "./styles.module.scss";
import Popup from "reactjs-popup";
import CalendarIcon from "components/_legacy/Icons/Calendar";
import AppointmentCalendar from "../AppointmentCalendar";

const getReservationDateRangeDateFormat = (
  startDate: Date | string | null | undefined,
  endDate: Date | string | null | undefined,
) => {
  const isSameDay = moment(startDate).isSame(endDate);

  if (isSameDay) { // return data on first open (in case of edit state)
    return `${moment(startDate).format('MMM DD, YYYY')}`;
  }

  return `${moment(startDate).format('MMM DD')}-${moment(endDate).format('MMM DD, YYYY')}`;
};

export default function AppointmentsHeaderCalendar() {
  const { config, adminVisits } = useTypedSelector(state => state);
  const { filters } = adminVisits;
  const { selectedStartDate, selectedEndDate } = filters;
  const calendarSelectedDate = getReservationDateRangeDateFormat(selectedStartDate, selectedEndDate);

  return (
    <Box className={styles.reservationHeaderCalendar}>
      <Box className={styles.calendarDate}>
        {calendarSelectedDate}
      </Box>
      <Popup
        className={'modal'}
        modal
        nested
        trigger={(
          <div style={{display: 'flex'}}>
            <CalendarIcon
              backgroundColor={config.theme.primaryLight}
              className={styles.calendarDateTrigger}
              color={config.theme.primary}
              size={30}
            />
          </div>
        )}
      >
        <AppointmentCalendar />
      </Popup>
    </Box>
  );
}
