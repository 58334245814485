import { SvgIcon, SvgIconProps } from "@mui/material";

export const TogetherDateAndTime: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path
        d="M12 0a2 2 0 0 1 1.995 1.85L14 2v3.529A6 6 0 1 1 5.529 14L2 14a2 2 0 0 1-1.995-1.85L0 12V2A2 2 0 0 1 1.85.005L2 0Zm-2 6a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm2-4H2v10h2.342A6 6 0 0 1 12 4.342zm-2 5a1 1 0 0 1 1 1v1h1a1 1 0 1 1 0 2h-2a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1ZM5 3a1 1 0 1 1 0 2H4a1 1 0 1 1 0-2Z"
        fill={props.fill}
        fillOpacity="1"
        stroke="none"
        strokeWidth="1"
      />
    </SvgIcon>
  );
};
