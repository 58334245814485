import Box from 'components/_legacy/Box';
import CancelCircleIcon from 'components/_legacy/Icons/CancelCircle';
import DashboardItem from '../DashboardItem';
import MonitorIcon from 'components/_legacy/Icons/Monitor';
import styles from './styles.module.scss';
import UserIcon from 'components/_legacy/Icons/User';
import { getLocationDashboardsTotalCount } from 'Admin/Store/locationDashboardDuck';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useTypedSelector } from 'store/_legacy/Redux/store';

enum DashboardItemSelector {
  Total = 1,
  Unique = 2,
  Cancelled = 3,
}

/**
 * Location dashboard items group
 *
 * OwnedBy: Bruno
 */
export default function DashboardItems() {
  const [activeItem, setActiveItem] = useState<DashboardItemSelector>(DashboardItemSelector.Total);
  const { config, locationDashboard } = useTypedSelector(state => state);

  const { totalCounts } = locationDashboard;

  const dispatch = useDispatch();
  const { locationId } = useParams<{ locationId: string }>();

  useEffect(() => {
    dispatch(getLocationDashboardsTotalCount({ locationId }));
  }, [locationId]);

  return (
    <Box className={styles.dashboardItemsContainer}>
      <DashboardItem
        active={DashboardItemSelector.Total === activeItem}
        onClick={() => setActiveItem(DashboardItemSelector.Total)}
      >
        <DashboardItem.Top>
          <DashboardItem.Icon>
            <MonitorIcon
              color={DashboardItemSelector.Total === activeItem ? config.theme.primary : config.theme.grey}
              size={20}
            />
          </DashboardItem.Icon>

          <DashboardItem.Text>
            Total Reservations
          </DashboardItem.Text>
        </DashboardItem.Top>

        <DashboardItem.Value>
          <div data-testid="dashboard-item-total">{totalCounts.total}</div>
        </DashboardItem.Value>
      </DashboardItem>

      <DashboardItem
        active={DashboardItemSelector.Unique === activeItem}
        onClick={() => setActiveItem(DashboardItemSelector.Unique)}
      >
        <DashboardItem.Top>
          <DashboardItem.Icon>
            <UserIcon
              color={DashboardItemSelector.Unique === activeItem ? config.theme.primary : config.theme.grey}
              size={20}
            />
          </DashboardItem.Icon>

          <DashboardItem.Text>
            Unique Reservations
          </DashboardItem.Text>
        </DashboardItem.Top>

        <DashboardItem.Value>
          <div data-testid="dashboard-item-unique">{totalCounts.unique}</div>
        </DashboardItem.Value>
      </DashboardItem>

      <DashboardItem
        active={DashboardItemSelector.Cancelled === activeItem}
        onClick={() => setActiveItem(DashboardItemSelector.Cancelled)}
      >
        <DashboardItem.Top>
          <DashboardItem.Icon>
            <CancelCircleIcon
              color={DashboardItemSelector.Cancelled === activeItem ? config.theme.primary : config.theme.grey}
              size={20}
            />
          </DashboardItem.Icon>

          <DashboardItem.Text>
            Cancelation
          </DashboardItem.Text>
        </DashboardItem.Top>

        <DashboardItem.Value>
          <div data-testid="dashboard-item-cancelled">{totalCounts.cancelled}</div>
        </DashboardItem.Value>
      </DashboardItem>
    </Box>
  );
}
