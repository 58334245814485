import {useDispatch} from "react-redux";
import {useTypedSelector} from "store/_legacy/Redux/store";
import {useState} from "react";
import {Trans, t} from "@lingui/macro";
import TextField from '@material-ui/core/TextField';
import styles from './styles.module.scss';
import Box from "components/_legacy/Box";
import Button from "components/_legacy/Button";
import { SettingsMobileType, SettingsMobileVersion } from "../../../../Store/settingsMobile/models";
import { updateSettingsMobileData } from "../../../../Store/settingsMobile";

interface SettingsMobileFormProps {
  label: string;
  type: SettingsMobileType;
}

export default function SettingsMobileForm(props: SettingsMobileFormProps) {
  const dispatch = useDispatch();
  const { adminSettingsMobile, config } = useTypedSelector(state => state);
  const { versions } = adminSettingsMobile;
  const { label, type } = props;

  const version = versions && versions.find(v => v.type === type);
  const initValue = version?.version || 0;

  const [editState, setEditState] = useState(false);
  const [editedValue, setEditedValue] = useState<number>(initValue);

  const disableConfirmButton = editedValue.toString().length;

  const onUpdateVersion = (version: SettingsMobileVersion | undefined, versionNumber: number) => {
    const updatedVersions = [...versions].map(v => {
      if (version && v.id === version.id) {
        return { id: v.id, version: versionNumber };
      }

      return { id: v.id, version: v.version };
    });

    dispatch(updateSettingsMobileData(updatedVersions));
  };

  const openEditState = () => {
    setEditState(true);
    setEditedValue(initValue); // reset state value if no redux update
  };

  const onCancel = () => {
    setEditState(false);
  };

  const onUpdate = () => {
    setEditState(false);
    onUpdateVersion(version, editedValue);
  };

  return (
    <Box alignItems="center" className={styles.settingForm} display="flex" justifyContent="between">
      <Box alignItems="center" className={styles.settingFormText} display="flex">
        <div className={styles.settingFormLabel}>{label}:</div>
        {
          editState ?
            <TextField
              InputProps={{ inputProps: { min: 0 } }}
              className={`input input--default ${styles.settingFormInput}`}
              id="settingMobileForm"
              onChange={(event) => setEditedValue(parseInt(event.target.value))}
              placeholder={t`Type here`}
              type="number"
              value={editedValue}
            /> :
            <div className={styles.settingFormValue}>{initValue}</div>
        }
      </Box>
      <Box alignItems="center" display="flex">
        {
          editState ?
            <Box alignItems="center" display="flex" justifyContent="end">
              <Button
                aria-label={t`Cancel`}
                name={t`Cancel`}
                onClick={onCancel}
                size="sm"
                type="clear"
              >
                <Trans>Cancel</Trans>
              </Button>

              <Button
                aria-label={t`Update Email Content`}
                disabled={!disableConfirmButton}
                name={t`Update Email Content`}
                onClick={onUpdate}
                size="sm"
              >
                <Trans>Update</Trans>
              </Button>
            </Box> :
            <div className={styles.editSetting} onClick={openEditState}>
              <svg height="24px"  version="1.1" viewBox="0 0 24 24" width="24px">
                <g fill="none" fillRule="evenodd" id="A-locations" stroke="none" strokeWidth="1">
                  <g className={styles.fillColor} fill={config.theme.primary} fillRule="nonzero" id="A-Location-notifications" transform="translate(-329.000000, -185.000000)">
                    <g id="icons/editpencilgrey" transform="translate(329.000000, 185.000000)">
                      <path d="M17.2077147,4.29289322 L19.329035,6.41421356 C19.7195593,6.80473785 19.7195593,7.43790283 19.329035,7.82842712 L9.42954011,17.7279221 L5.89400621,14.1923882 L15.7935011,4.29289322 C16.1840254,3.90236893 16.8171904,3.90236893 17.2077147,4.29289322 Z M16.5006079,6.41421356 L8.72243333,14.1923882 L9.42954011,14.8994949 L17.2077147,7.12132034 L16.5006079,6.41421356 Z M5.31649907,19.5703403 C5.11123226,19.6387625 4.88931034,19.6387625 4.68404354,19.5703403 C4.16010022,19.3956925 3.87694023,18.8293725 4.051588,18.3054292 L5.18689943,14.8994949 L8.72243333,18.4350288 L5.31649907,19.5703403 Z" id="pen"></path>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
        }
      </Box>
    </Box>
  );
}
