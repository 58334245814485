import { isAfter, isBefore, isEqual } from "date-fns";

export const isOverlapping = (range: [Date, Date], rangeToCompare: [Date, Date]): boolean => {
  const startOverlaps = (isEqual(range[0], rangeToCompare[0]) || isAfter(range[0], rangeToCompare[0]))
    && isBefore(range[0], rangeToCompare[1]);
  const endOverlaps = (isEqual(range[1], rangeToCompare[1]) || isBefore(range[1], rangeToCompare[1]))
    && isAfter(range[1], rangeToCompare[0]);

  return startOverlaps || endOverlaps;
};
