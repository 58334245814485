import {useDispatch} from "react-redux";
import Box from "components/_legacy/Box";
import Popup from "reactjs-popup";
import Text from "components/_legacy/Text";
import {Trans, t} from "@lingui/macro";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Button from "components/_legacy/Button";
import {setBookingsData} from "../../../../App/Store/Bookings/bookingDuck";
import {useTypedSelector} from "store/_legacy/Redux/store";
import {setJustInTimeReservationData} from "../../../Store/reservationJustInTime";
import {onLoadDesks} from "../ReservationJustIntimeDetails/Floor/helpers";

export default function ReservationJustInTimeCreated() {
  const dispatch = useDispatch();
  const { booking, config, profile, adminReservationJustInTime } = useTypedSelector(state => state);
  const { selectedData } = adminReservationJustInTime;
  const { roleAccess } = profile;

  const handleClosePopup = () => {
    dispatch(setBookingsData({ bookingCreated: false }));
  };

  const onBookDeskAgain = () => {
    handleClosePopup();
    // update booked desks on map after making reservation
    onLoadDesks(selectedData?.floor?.id, adminReservationJustInTime, dispatch);
    dispatch(setJustInTimeReservationData({
      selectedData: {
        ...selectedData,
        desk: null,
        parkingSpot: null,
      },
    }));
  };
  
  const onMakeNewBooking = () => {
    handleClosePopup();
    // update booked desks on map after making reservation
    onLoadDesks(selectedData?.floor?.id, adminReservationJustInTime, dispatch);
    dispatch(setJustInTimeReservationData({
      selectedData: {
        ...selectedData,
        user: null,
        location: null,
        dateTime: '',
        floor: null,
        desk: null,
        parkingSpot: null,
      },
    }));
  };

  return (
    <Popup
      className="modal"
      contentStyle={{
        maxWidth: 570,
        width: '100%',
      }}
      modal
      onClose={() => {
        handleClosePopup();
      }}
      open={booking.bookingCreated}
    >
      <Box padding="2px 1px">
        <Box alignItems="center" display="flex" justifyContent="between">
          <Text size="xlg" weight="semi-bold">
            <Trans>Desk was successfully booked</Trans>
          </Text>

          <IconButton
            onClick={handleClosePopup}
            size="small"
            style={{
              backgroundColor: config.theme.primaryLight,
              borderRadius: 8,
              height: 30,
              width: 30,
            }}
          >
            <CloseIcon style={{ color: config.theme.primary, fontSize: 21 }} />
          </IconButton>
        </Box>

        <Box margin="9px 0 18px">
        </Box>

        <Box alignItems="center" display="flex" justifyContent="end">
          <Button
            aria-label={t`book desk again`}
            name={t`book desk again`}
            onClick={onBookDeskAgain}
            size="sm"
            type="clear"
            withShadow={false}
          >
            <Trans>Book Desk Again</Trans>
          </Button>

          <Button
            aria-label={t`make a new booking`}
            name={t`make a new booking`}
            onClick={onMakeNewBooking}
            size="sm"
            type="clear"
            withShadow={false}
          >
            <Trans>Make a new booking</Trans>
          </Button>

          {!roleAccess.delegate &&
            <Button
              aria-label={t`back to reservation list`}
              name="back to reservation list"
              onClick={handleClosePopup}
              size="sm"
              to="/admin/reservations"
            >
              <Trans>Back to Reservation List</Trans>
            </Button>
          }
        </Box>
      </Box>
    </Popup>
  );
}
