import { useDispatch } from 'react-redux';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTypedSelector } from 'store/_legacy/Redux/store';
import styles from './styles.module.scss';

import Box from 'components/_legacy/Box';
import { setFloorMapApiRequestsData } from 'Admin/Store/floorMapApiRequestsDuck';
import FloorHeaderTitle from "./FloorHeaderTitle";
import FloorHeaderNavigation from "./FloorHeaderNavigation";
import { ErrorAdminLocationsSnackbar, SuccessAdminLocationsSnackbar } from "Admin/Pages/Locations/SnackBars";

interface Props {
  children?: React.PropsWithChildren<any>;
}

export default function AddFloorHeader(props: Props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { locationId } = useParams<{ floorId: string; locationId: string; }>();
  const { adminFloorMapApiRequests, locations } = useTypedSelector(state => state);
  const { children } = props;

  // turn back to floors page after delete single floor
  useEffect(() => {
    if (locations.floorDeleted || locations.floorDeleteError) {
      history.push(`/admin/location/${locationId}/floors`);
    }
  }, [locations.floorDeleted, locations.floorDeleteError]);

  useEffect(() => {
    if (adminFloorMapApiRequests.mapSectionSaved) {
      dispatch(setFloorMapApiRequestsData({ mapSectionSaved: false })); // enable button after action has processed
    }
  }, [adminFloorMapApiRequests.mapSectionSaved]);

  return (
    <>
      <SuccessAdminLocationsSnackbar />
      <ErrorAdminLocationsSnackbar />

      <Box className={styles.header} dataTestId="floor-map-details-header">
        <Box alignItems="center" display="flex" justifyContent="between" marginBottom={15}>
          <Box>
            <Box alignItems="center" dataTestId="floor-map-details-header-title" display="flex">
              <FloorHeaderTitle />
            </Box>
          </Box>

          <Box alignItems="center" display="flex" gap={10}>
            {children}
          </Box>
        </Box>

        <FloorHeaderNavigation />
      </Box >
    </>
  );
}
