import styles from "../styles.module.scss";
import {Trans, plural} from "@lingui/macro";
import Box from "components/_legacy/Box";
import Select from "components/_legacy/Select";

interface Props {
  value: number;
  onChange: (value: number) => void;
}

const resolveOptionName = (value: number): string => {
  return plural(value, { one: "# week", other: "# weeks" });
};

export default function DefaultWeekRecurrenceSelect(props: Props) {
  const { value, onChange } = props;

  const options: Array<{ name: string; value: number }> = [
    {
      name: resolveOptionName(1),
      value: 1,
    },
    {
      name: resolveOptionName(2),
      value: 2,
    },
    {
      name: resolveOptionName(4),
      value: 4,
    },
  ];

  return (
    <Box className={styles.dayListContainer}>
      <Box alignItems='center' className={styles.dayListContainerLabel} display='flex' gap={10}>
        <div>
          <Trans>Default week recurrence (daily/weekly calendar)</Trans>:
        </div>
      </Box>

      <Select
        classes={{
          input: styles.input,
          wrapper: styles.select,
        }}
        iconColor="#000000"
        id="status"
        items={options}
        labelColor="gray"
        onChange={(event) => onChange(event.target.value as number)}
        value={value}
      />
    </Box>
  );
}
