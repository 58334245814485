import Api from 'store/_legacy/Services/Api';
import FileSaver from 'file-saver';
import moment from 'moment';
import { exportVisits, setVisitsData } from 'Admin/Store/visits';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'store/_legacy/Redux/store';
import DownloadExcelButton from "components/_legacy/Buttons/DownloadExcelButton";
import { SuccessAppointmentsSnackbar } from "../SnackBars";
import {getAllowedLocationsByRoles, getLocationIdsByRoles} from "../../Locations/Helpers";

export default function AppointmentExportCsv() {
  const dispatch = useDispatch();
  const { adminVisits, profile, locations } = useTypedSelector(state => state);
  const { filters } = adminVisits;
  const { roleAccess, locationIds: profileLocationIds } = profile;
  const { selectedStartDate, selectedEndDate, statuses, documentStatuses, checking, search } = filters;

  // set restrictions for local admin role
  const allowedLocations = getAllowedLocationsByRoles(roleAccess, locations.adminLocations, profileLocationIds);
  const restrictedLocationIds = getLocationIdsByRoles(roleAccess, filters, allowedLocations);

  const onExportVisits = async () => {
    try {
      const action = dispatch(exportVisits({
        statuses,
        documentStatuses,
        locationIds: restrictedLocationIds,
        search,
        checking,
        dateFrom: moment(selectedStartDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss'), // start of day in iso format
        dateTo: moment(selectedEndDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss'), // end of day in iso format
      }));
      dispatch(setVisitsData({ successMessage: 'The file is being generated in background. Please do not refresh or close this page.' }));

      const payload = await Api(action);

      if (payload.status === 200 || payload.status === 201) {
        const csv = payload.data;
        const blob = new Blob([csv], {});

        return FileSaver.saveAs(blob, `visits-${moment().format('YYYY-MM-DDTHH:mm:ss')}.xlsx`);
      }
    } catch (e) {
      console.log('error with exporting visits');
    }
  };

  return (
    <>
      <DownloadExcelButton onSubmit={onExportVisits} />
      <SuccessAppointmentsSnackbar />
    </>
  );
}
