import Box from 'components/_legacy/Box';
import format from 'date-fns/format';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from 'components/_legacy/Select';
import styles from './styles.module.scss';
import Switch from '@material-ui/core/Switch';
import { setCreateNewBookingData } from 'App/Store/Bookings/createNewBookingDuck';
import { t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { useTypedSelector } from 'store/_legacy/Redux/store';
import {
  getLocationAvailableParkingSpots,
  selectLocationAvailableParkingSpotsSorted,
  selectLocationParkingSpots,
  setLoading,
} from 'Admin/Store/parking';
import { ReduxStore } from 'store/_legacy/Redux';
import { weeklySlotsToWeeklyBooking } from 'App/Store/Utils';
import { debounce } from 'underscore';
import { getLocationParkingSpots } from 'Admin/Store/parking';
import { Tooltip } from '@material-ui/core';

export default function ParkingSpot() {
  const dispatch = useDispatch();
  const [enableParkingSpot, setEnableParkingSpot] = useState(false);
  const createNewBooking = useTypedSelector(state => state.createNewBooking);
  const { parkingSpotId, locationId = "" } = createNewBooking;

  const resetParkingBookingData = () => {
    dispatch(setCreateNewBookingData({ parkingSpotId: undefined }));
  };

  useEffect(() => {
    // When parking spot is disabled, clear it from redux to avoid passing a parking spot to bookings that don't need it
    if (!enableParkingSpot && parkingSpotId) {
      resetParkingBookingData();
    }
  }, [enableParkingSpot]);

  useEffect(() => {
    if (locationId) {
      dispatch(getLocationParkingSpots({ locationId }));
    }
  }, [locationId, enableParkingSpot]);

  const handleParkingSpotsRequest = (
    {
      bookingType,
      dateFrom,
      dateTo,
      locationId,
      timeFrom,
      timeTo,
      weeklySlots,
    }: Partial<ReduxStore["createNewBooking"]>,
  ) => {
    switch (bookingType) {
      case "custom": {
        if (locationId && dateFrom && dateTo && timeFrom && timeTo) {
          const timeFromFormatted = timeFrom ? format(new Date(timeFrom), 'HH:mm') : '';
          const timeToFormatted = timeTo ? format(new Date(timeTo), 'HH:mm') : '';

          dispatch(getLocationAvailableParkingSpots({
            locationId,
            data: {
              custom: bookingType === 'custom'
                ? {
                  dateFrom: dateFrom ? format(new Date(dateFrom), 'yyyy-MM-dd') : '',
                  dateTo: dateTo ? format(new Date(dateTo), 'yyyy-MM-dd') : '',
                  timeFrom: timeFromFormatted,
                  timeTo: timeToFormatted,
                }
                : undefined,
            },
          }));
        }
        break;
      }
      case "daily": {
        if (locationId && timeFrom && timeTo) {
          const timeFromFormatted = timeFrom ? format(new Date(timeFrom), 'HH:mm') : '';
          const timeToFormatted = timeTo ? format(new Date(timeTo), 'HH:mm') : '';

          dispatch(getLocationAvailableParkingSpots({
            locationId,
            data: {
              daily: { timeFrom: timeFromFormatted, timeTo: timeToFormatted },
            },
          }));
        }
        break;
      }
      case "weekly": {
        if (locationId && dateFrom && dateTo && timeFrom && timeTo && weeklySlots) {
          dispatch(getLocationAvailableParkingSpots({
            locationId,
            data: {
              weekly: weeklySlotsToWeeklyBooking(weeklySlots),
            },
          }));
        }
        break;
      }
      default:
        break;
    }
  };
  const debouncedHandleParkingSpotsRequest = useCallback(debounce(handleParkingSpotsRequest, 900), []);

  const parking = useTypedSelector(state => state.parking);
  const {
    bookingType,
    dateFrom,
    dateTo,
    timeFrom,
    timeTo,
    weeklySlots,
  } = createNewBooking;
  const parkingSpots = selectLocationAvailableParkingSpotsSorted(parking, locationId);

  useEffect(() => {
    if (enableParkingSpot) {
      const nonAvailableParking = !parkingSpots.some(parking => parking.id === parkingSpotId);

      if (parkingSpotId && nonAvailableParking || !parkingSpots?.length) {
        resetParkingBookingData();
      }

      dispatch(setLoading(true));
      debouncedHandleParkingSpotsRequest({
        bookingType,
        dateFrom,
        dateTo,
        locationId,
        timeFrom,
        timeTo,
        weeklySlots,
      });
    }
  }, [enableParkingSpot, dateFrom, dateTo, timeFrom, timeTo, locationId, bookingType, weeklySlots]);

  const handleChangeParkingSpot = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    dispatch(setCreateNewBookingData({ parkingSpotId: event.target.value as string }));
  };

  const allParkingSpotsFromLocation = selectLocationParkingSpots(parking, locationId).filter(({ isAvailable }) => isAvailable);

  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);

  const handleTooltipOpen = () => {
    if (enableParkingSpot && parkingSpots.length === 0) {
      setTooltipIsOpen(true);
    }
  };

  return allParkingSpotsFromLocation.length > 0 ? (
    <Box className={styles.container}>
      <Tooltip
        onClose={() => setTooltipIsOpen(false)}
        onOpen={() => handleTooltipOpen()}
        open={tooltipIsOpen}
        title={t`No available parking spots for the period selected`}
      >
        <span>
          <FormControlLabel
            classes={{ label: styles.switchLabel }}
            control={
              <Switch
                checked={enableParkingSpot}
                className="switcher switcher--default"
                name="Need Parking"
                onChange={() => setEnableParkingSpot(current => !current)}
              />
            }
            label={t`I need parking`}
          />
          { enableParkingSpot ? (
            <Select
              classes={{
                input: styles.input,
                wrapper: styles.selectContainer,
              }}
              disabled={parking.loading || parkingSpots.length === 0}
              displayEmpty
              fullWidth
              id="parking-spot"
              items={[
                {
                  name: parking.loading ? 'Loading parking data' : t`Select parking place`,
                  value: '',
                },
                ...parkingSpots.map(parkingSpot => ({
                  name: t`${parkingSpot.floor ? `Floor ${parkingSpot.floor}, ` : ''}${parkingSpot.name}`,
                  value: parkingSpot.id,
                })),
              ]}
              labelColor="gray"
              onChange={handleChangeParkingSpot}
              value={(!parking.loading && parkingSpotId) || ""}
            />
          ) : undefined}
        </span>
      </Tooltip>
    </Box>
  ) : null;
}