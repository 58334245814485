import Box from 'components/_legacy/Box';
import Button from 'components/_legacy/Button';
import Popup from 'reactjs-popup';
import styles from './styles.module.scss';
import Text from 'components/_legacy/Text';
import { Trans } from '@lingui/macro';
import { useTypedSelector } from 'store/_legacy/Redux/store';

interface DeleteNotificationProps {
  deleteNotification: Function;
  index: number
}

export default function DeleteNotification(props: DeleteNotificationProps) {
  const {
    deleteNotification,
  } = props;

  const { config } = useTypedSelector(state => state);

  return (
    <Popup
      className={'modal'}
      modal
      nested
      trigger={
        <svg className={styles.notificationAction} height="24px" version="1.1" viewBox="0 0 24 24" width="24px">
          <g fill="none" fillRule="evenodd" id="A-locations" stroke="none" strokeWidth="1">
            <g className={styles.fillColor} fill={config.theme.primary} id="A-Location-notifications" transform="translate(-358.000000, -185.000000)">
              <g id="delete-white-copy-5" transform="translate(358.000000, 185.000000)">
                <path d="M14,4 C14.5522847,4 15,4.44771525 15,5 C15,5.55228475 14.5522847,6 14,6 L19,6 C19.5522847,6 20,6.44771525 20,7 C20,7.55228475 19.5522847,8 19,8 L18,8 L17,18 C17,19.1045695 16.1045695,20 15,20 L9,20 C7.8954305,20 7,19.1045695 7,18 L6,8 L5,8 C4.44771525,8 4,7.55228475 4,7 C4,6.44771525 4.44771525,6 5,6 L10,6 C9.44771525,6 9,5.55228475 9,5 C9,4.44771525 9.44771525,4 10,4 L14,4 Z M15.6,12 L8.4,12 L9,18 L15,18 L15.6,12 Z M16,8 L8,8 L8.2,10 L15.8,10 L16,8 Z" id="Combined-Shape"></path>
              </g>
            </g>
          </g>
        </svg>
      }
    >
      {(close: any) => (
        <div className="modal-inner">
          <div className="modal-header">
            <h2>
              <Trans>Delete notification</Trans>
            </h2>

            <svg className="modal-header__close" height="30px" onClick={close} viewBox="0 0 30 30" width="30px">
              <g fill="none" fillRule="evenodd" id="Booking" stroke="none" strokeWidth="1">
                <g id="Booking-savedall" transform="translate(-875.000000, -132.000000)">
                  <g id="Group-8" transform="translate(515.000000, 112.000000)">
                    <g id="icons/close" transform="translate(360.000000, 20.000000)">
                      <rect fill={config.theme.primaryLight} height="30" id="Rectangle" rx="8" width="30" x="0" y="0"></rect>
                      <path d="M20.704633,9.29536704 C21.0984557,9.68918977 21.0984557,10.3277026 20.704633,10.7215253 L16.4261582,15 L20.704633,19.2784747 C21.0984557,19.6722974 21.0984557,20.3108102 20.704633,20.704633 C20.3108102,21.0984557 19.6722974,21.0984557 19.2784747,20.704633 L15,16.4261582 L10.7215253,20.704633 C10.3277026,21.0984557 9.68918977,21.0984557 9.29536704,20.704633 C8.90154432,20.3108102 8.90154432,19.6722974 9.29536704,19.2784747 L13.5738418,15 L9.29536704,10.7215253 C8.90154432,10.3277026 8.90154432,9.68918977 9.29536704,9.29536704 C9.68918977,8.90154432 10.3277026,8.90154432 10.7215253,9.29536704 L15,13.5738418 L19.2784747,9.29536704 C19.6722974,8.90154432 20.3108102,8.90154432 20.704633,9.29536704 Z" fill={config.theme.primary}></path>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>

          <div className={'modal-inner-content'}>
            <Box marginBottom={15} marginTop={20}>
              <Text color="lightGray" size="md">
                <Trans>Are you sure that you want to delete notification?</Trans>
              </Text>
            </Box>

            <Box display="flex" justifyContent="end">
              <Button onClick={close} size="sm" type="clear">
                <Trans>Cancel</Trans>
              </Button>

              <Button
                onClick={() => {
                  deleteNotification();
                  close();
                }}
                size="sm"
              >
                <Trans>Delete</Trans>
              </Button>
            </Box>
          </div>
        </div>
      )}
    </Popup>
  );
}
