import React, { useEffect, useRef, useState } from "react";
import { ReservationResourcesListProps } from "./types";
import { Box, Button, CircularProgress, Fade, Skeleton, Typography, useTheme } from "@mui/material";
import { t } from "@lingui/macro";
import { TogetherFloor, TogetherLocation, TogetherUser } from "../icons";
import { useLazyLoadScrollHandler } from "hooks";
import { ReservationResourceDetails } from "../reservation-resource-details";
import { ReservationType } from "store";
import { BasicImageCarousel } from "../image-carousel";
import { resolveHeightInRatio } from "utils";

const LoadingPlaceholder: React.FC<{ isOnHighLoad?: boolean }> = ({ isOnHighLoad }) => {
  const theme = useTheme();

  return (
    <Box position="relative">
      <Skeleton
        height={128}
        sx={{ borderRadius: 2, bgcolor: theme.palette.grey[100] }}
        variant="rectangular"
      />
      {isOnHighLoad ? (
        <Fade in={isOnHighLoad}>
          <Box
            alignItems="center"
            bottom={0}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            left={0}
            position="absolute"
            right={0}
            top={0}
          >
            <CircularProgress size={32} sx={{ mb: 2 }} />
            <Typography color={theme.palette.grey[700]} fontSize={14} textAlign="center">
              {t`Please, wait while we fetch rooms availability from Outlook`}
            </Typography>
          </Box>
        </Fade>
      ) : undefined}
    </Box>
  );
};

// * Right now this expects rooms only, but changes can be made to support other types of reservation resources (desk, etc)

export const ReservationResourcesList: React.FC<ReservationResourcesListProps> = (props) => {
  const { height, isLoading, isFirstLoad, hasMore, items, selectedItemId, onItemSelect, onLoadMore } = props;
  const theme = useTheme();
  const viewRef = useRef<HTMLDivElement>(null);
  const [isOnHighLoad, setIsOnHighLoad] = useState(false);
  const debouncedHandleScroll = useLazyLoadScrollHandler(viewRef, onLoadMore, 300);

  useEffect(() => {
    const timeout = isLoading ? setTimeout(() => setIsOnHighLoad(true), 5000) : undefined;

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }

      setIsOnHighLoad(false);
    };
  }, [isLoading, setIsOnHighLoad]);
  
  if (isLoading && isFirstLoad) {
    return <LoadingPlaceholder isOnHighLoad={isOnHighLoad} />;
  }

  const imageWidth = 260;
  const imageHeight = resolveHeightInRatio(imageWidth, "16:9");

  return (
    <Box height={height} onScroll={debouncedHandleScroll} paddingRight={2} ref={viewRef} sx={{ overflowY: "auto", overflowX: "hidden" }}>
      {items?.map((item) => {
        const{ id, name, capacity, floorName, locationAddress } = item;
        const isSelected = id === selectedItemId;

        return (
          <Box bgcolor={isSelected ? theme.background.blue.light : theme.palette.grey[100]} borderRadius={2} display="flex" key={id} marginBottom={2} padding={1.5}>
            <Box display="flex" paddingRight={2}>
              {item.images?.length ? (
                <Box borderRadius={2} height={imageHeight} overflow="hidden" width={imageWidth}>
                  <BasicImageCarousel height={imageHeight} images={item.images.map(({ url }) => url)} width={imageWidth} />
                </Box>
              ) : (
                <Box
                  alignItems="center"
                  bgcolor={theme.palette.grey[300]}
                  borderRadius={2}
                  display="flex"
                  height={imageHeight}
                  justifyContent="center"
                  paddingX={2}
                  width={imageWidth}
                >
                  <Typography color={theme.palette.grey[800]} fontSize={13} fontWeight="400" textAlign="center" width={135}>{t`Sorry, no pictures for this room yet`}</Typography>
                </Box>
              )}
            </Box>
            <Box display="flex" flex={1} flexDirection="column" justifyContent="space-between">
              <Box>
                <Box display="flex" justifyContent="space-between" marginBottom={1}>
                  <Typography color={isSelected ? "primary" : undefined} fontWeight="600" noWrap>{name}</Typography>
                  {capacity ? (
                    <Box alignItems="flex-end" display="flex">
                      <TogetherUser
                        fill={isSelected ? theme.palette.primary.main : theme.palette.grey[600]}
                        stroke={isSelected ? theme.palette.primary.main : theme.palette.grey[600]}
                        sx={{ width: 16, marginRight: 1 }}
                      />
                      <Typography color={isSelected ? "primary" : theme.palette.grey[600]} fontSize={14}>{capacity} {t`Capacity`}</Typography>
                    </Box>
                  ) : undefined}
                </Box>
                <Box display="flex">
                  {floorName ? (
                    <Box alignItems="center" display="flex" marginRight={2}>
                      <TogetherFloor fill={isSelected ? theme.palette.primary.main : theme.palette.grey[600]} sx={{ width: 16, marginRight: 1 }} />
                      <Typography
                        color={isSelected ? "primary" : theme.palette.grey[600]}
                        maxWidth={100}
                        noWrap
                        title={floorName}
                      >
                        {floorName}
                      </Typography>
                    </Box>
                  ) : undefined}
                  {locationAddress ? (
                    <Box alignItems="center" display="flex">
                      <TogetherLocation fill={isSelected ? theme.palette.primary.main : theme.palette.grey[600]} sx={{ width: 16, marginRight: 1 }} />
                      <Typography
                        color={isSelected ? "primary" : theme.palette.grey[600]}
                        maxWidth={280}
                        noWrap
                        title={locationAddress}
                      >
                        {locationAddress}
                      </Typography>
                    </Box>
                  ) : undefined}
                </Box>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <ReservationResourceDetails
                  Trigger={({ onClick }) => (
                    <Button data-cid="open-resource-details-button" disableRipple onClick={onClick} sx={{ padding: 0, ":hover": { bgcolor: "transparent" } }}>
                      {t`View details`}
                    </Button>
                  )}
                  floorId={item.floorId || ""}
                  isSelected={isSelected}
                  locationId={item.locationId || ""}
                  onSelect={onItemSelect ? () => onItemSelect(item) : undefined}
                  roomId={item.id}
                  type={ReservationType.ROOM}
                />
                {onItemSelect ? (
                  <Button
                    data-cid="select-resource-button"
                    disableRipple
                    onClick={() => onItemSelect(item)}
                    sx={{ padding: 0, ":hover": { bgcolor: "transparent" } }}
                  >
                    {isSelected ? t`Deselect room` : t`Select room`}
                  </Button>
                ) : undefined}
              </Box>
            </Box>
          </Box>
        );
      })}
      {hasMore ? <LoadingPlaceholder isOnHighLoad={isOnHighLoad} /> : undefined}
    </Box>
  );
};
