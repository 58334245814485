import React, { useEffect, useState } from "react";
import { UsersSelectProps } from "./types";
import { User, useLazySearchUsersQuery } from "store";
import { Autocomplete, Box, TextField, Typography, useTheme } from "@mui/material";
import { t } from "@lingui/macro";
import { useDebouncedCallback } from "hooks";

export const UsersSelect: React.FC<UsersSelectProps> = (props) => {
  const { value, placeholder, disabledIds, onChange } = props;
  const { palette } = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState<User[]>([]);
  const [search, setSearch] = useState("");
  const debouncedSetSearch = useDebouncedCallback(setSearch, [setSearch], 700);
  const [triggerSearchUsersQuery, searchUsersQuery] = useLazySearchUsersQuery();
  const { data: searchUsersQueryResponse, isLoading: usersAreLoading, isFetching: usersAreFetching } = searchUsersQuery;
  const { users = [] } = searchUsersQueryResponse?.result?.data || {};

  useEffect(() => {
    if (isOpen) {
      triggerSearchUsersQuery(search);
    }
  }, [isOpen, search, triggerSearchUsersQuery]);

  useEffect(() => {
    setOptions(users);
  }, [JSON.stringify(users)]);

  return (
    <Autocomplete
      autoComplete
      filterOptions={(x) => x}
      filterSelectedOptions
      getOptionLabel={(option) => option.name || option.email || option.id}
      loading={usersAreLoading || usersAreFetching}
      multiple
      noOptionsText={t`No users found`}
      onChange={(_, value) => onChange?.(value)}
      onClose={() => setIsOpen(false)}
      onInputChange={(_, value) => debouncedSetSearch(value)}
      onOpen={() => setIsOpen(true)}
      options={options}
      renderInput={(props) => (
        <TextField
          {...props}
          fullWidth
          placeholder={placeholder}
          sx={{
            "& .MuiFilledInput-root.MuiInputBase-sizeSmall": {
              paddingTop: 0.7,
              paddingBottom: 0.7,
              paddingLeft: 1,
            },
            "& .MuiFilledInput-input": {
              fontSize: 14,
            },
          }}
          variant="filled"
        />
      )}
      renderOption={(props, option) => {
        const isDisabled = disabledIds?.includes(option.id);

        return (
          <Box {...(isDisabled ? undefined : props)} bgcolor={isDisabled ? palette.grey[100] : undefined} component="li" px={2} py={0.75}>
            <Box>
              <Typography color={isDisabled ? palette.grey[700] : undefined} fontSize={14}>{option.name}</Typography>
              <Typography color={isDisabled ? palette.grey[700] : undefined} fontSize={12} fontWeight="500">{option.email}</Typography>
            </Box>
          </Box>
        );
      }}
      size="small"
      value={value}
    />
  );
};
