import { createApi } from "@reduxjs/toolkit/query/react";
import { TogetherApiTag } from "./enums";
import { baseQuery } from "../utils";

export const togetherApi = createApi({
  reducerPath: "togetherApi",
  tagTypes: [
    TogetherApiTag.BOOKING,
    TogetherApiTag.FLOOR,
    TogetherApiTag.GROUP,
    TogetherApiTag.LOCATION,
    TogetherApiTag.RESERVATION,
    TogetherApiTag.ROOM,
    TogetherApiTag.ROOM_SCHEDULE,
    TogetherApiTag.USER_SAVED_DESK,
    TogetherApiTag.USER_HOME_COUNTS,
    TogetherApiTag.RESERVATION_SERVICE_OPTION,
  ],
  baseQuery: baseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  endpoints: () => ({}),
});
