import { Editor } from "slate";
import { EditorElementType } from "../enums";

const inlineElements = [EditorElementType.ANCHOR];

const voidElements = [EditorElementType.IMAGE, EditorElementType.HTML];

export const withElements = (editor: Editor): Editor => {
  const { isInline, isVoid } = editor;

  editor.isInline = (element) => inlineElements.includes(element.type) || isInline(element);
  editor.isVoid = (element) => voidElements.includes(element.type) || isVoid(element);

  return editor;
};
