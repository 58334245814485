import Box from 'components/_legacy/Box';
import Button from 'components/_legacy/Button';
import CloseIcon from '@material-ui/icons/Close';
import format from 'date-fns/format';
import IconButton from '@material-ui/core/IconButton';
import isPast from 'date-fns/isPast';
import isSameDay from 'date-fns/isSameDay';
import Popup from 'reactjs-popup';
import Text from 'components/_legacy/Text';
import useSnackbar from 'components/_legacy/Snackbar/useSnackbar';
import { BookingModel } from 'App/Store/Bookings/bookingDuck/models';
import { getClosestAvailableDay } from 'App/Functions/Helpers';
import { Trans, t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTypedSelector } from 'store/_legacy/Redux/store';
import {
  cancelVisit,
  createVisit,
  formatBookingDateInLocalTime,
  setBookingsData,
} from 'App/Store/Bookings/bookingDuck';
import { isAnotherUserSelectedByExecutiveAssistant } from "../../../Store/Users/executiveAssistant/helpers";
import { TogetherApiTag, togetherApi } from 'store';

interface Props {
  isBooking?: boolean;
  visit?: BookingModel;
}

export default function VisitDetails({ isBooking = true, visit }: Props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [openSnackbar] = useSnackbar();
  const { booking: bookingDuck, locations: locationsDuck, config, createNewVisit, profile, executiveAssistant } = useTypedSelector(state => state);

  const [cancelTry, setCancelTry] = useState(0);
  const [isActionProcessed, setIsActionProcessed] = useState(false);
  const [popupOpened, setPopupOpened] = useState(false);

  const {
    dateFrom: reduxDateFrom,
    dateTo: reduxDateTo,
    floorId,
    locationId,
    parkingSpotId,
    timeFrom,
    timeTo,
    visitCreated,
  } = createNewVisit;
  const userId = isAnotherUserSelectedByExecutiveAssistant(profile, executiveAssistant)
    ? executiveAssistant?.selectedUser?.id
    : undefined;

  useEffect(() => {
    if (bookingDuck.error) {
      const handleClose = () => {
        dispatch(setBookingsData({ error: '' }));
      };

      openSnackbar({
        onClose: handleClose,
        text: bookingDuck.error,
        type: 'error',
      });
    }
  }, [bookingDuck.error]);

  useEffect(() => {
    if (visitCreated || bookingDuck.error) {
      setIsActionProcessed(false); // enable book button after action has processed
    }
  }, [visitCreated, bookingDuck.error]);

  const dateFrom = getClosestAvailableDay(reduxDateFrom, locationsDuck.locationDisabledDays);
  const dateTo = getClosestAvailableDay(reduxDateTo, locationsDuck.locationDisabledDays);

  let dateFromDay;
  let dateToDay;
  let dateFromMonth;
  let dateToMonth;

  if (dateFrom && dateTo) {
    dateFromDay = format(new Date(dateFrom), 'dd');
    dateToDay = format(new Date(dateTo), 'dd');
    dateFromMonth = format(new Date(dateFrom), 'LLL');
    dateToMonth = format(new Date(dateTo), 'LLL');
  }

  const bookVisit = () => {
    if (dateFrom && dateTo && locationId) {
      setIsActionProcessed(true); // disable book button while action processed

      dispatch(createVisit({
        custom: {
          dateFrom: format(new Date(dateFrom), 'yyyy-MM-dd'),
          dateTo: format(new Date(dateTo), 'yyyy-MM-dd'),
          timeFrom: timeFrom ?? '',
          timeTo: timeTo ?? '',
        },
        parkingSpotId,
        floorId: floorId ?? '',
        userId,
      }, locationId));
      dispatch(togetherApi.util.invalidateTags([{ type: TogetherApiTag.RESERVATION }, { type: TogetherApiTag.USER_HOME_COUNTS }]));
    }
  };

  const hasDateAndTimeFunction = (): boolean => {
    return Boolean(dateFrom && dateTo && timeFrom && timeTo) || Boolean(visit);
  };

  const datesAreSameDay = dateFrom && dateTo ? isSameDay(new Date(dateFrom), new Date(dateTo)) : true;
  const hasDateAndTime = hasDateAndTimeFunction();
  const isOnFinalStep = location.pathname.includes('/select-date');

  const allStepsCompleted = Boolean(floorId && locationId && hasDateAndTime && isOnFinalStep);
  const boxEnabling = Boolean(locationId && hasDateAndTime);

  const disabledCancel = visit ? isPast(new Date(visit.dateFromUTC)) : true;
  
  const handleCancelBooking = () => {
    if (visit) {
      setCancelTry(current => current + 1);
      dispatch(cancelVisit({ appointmentId: visit.id }));
      dispatch(togetherApi.util.invalidateTags([{ type: TogetherApiTag.RESERVATION }, { type: TogetherApiTag.USER_HOME_COUNTS }]));
    }
  };
  
  useEffect(() => {
    if (cancelTry > 0 && !bookingDuck.loading) {
      if (bookingDuck.visitCanceled) {
        setPopupOpened(false);
        openSnackbar({
          text: t`Visit canceled`,
          type: "success",
          autoHideDuration: 2000,
        });
        setTimeout(() => {
          history.push('/');
        }, 2100);
      } else {
        openSnackbar({
          text: bookingDuck.error,
          type: 'error',
        });
      }
    }
  }, [bookingDuck.loading, bookingDuck.visitCanceled]);

  return (
    <>
      <Popup
        className="modal"
        contentStyle={{
          maxWidth: 570,
          width: '100%',
        }}
        modal
        onClose={() => setPopupOpened(false)}
        open={popupOpened}
      >
        <Box padding="2px 1px">
          <Box alignItems="center" display="flex" justifyContent="between">
            <Text size="xlg" weight="semi-bold">
              <Trans>Do you want to cancel the visit?</Trans>
            </Text>

            <IconButton
              onClick={() => setPopupOpened(false)}
              size="small"
              style={{
                backgroundColor: config.theme.primaryLight,
                borderRadius: 8,
                height: 30,
                width: 30,
              }}
            >
              <CloseIcon style={{ color: config.theme.primary, fontSize: 21 }} />
            </IconButton>
          </Box>

          <Box margin="9px 0 18px">
            <Text color="gray" size="md">
              <Trans>
                {`Please make sure that you cancel the correct visit. When you cancel it the visit can't be restored.`}
              </Trans>
            </Text>
          </Box>

          <Box alignItems="center" display="flex" justifyContent="end">
            <Button
              onClick={() => setPopupOpened(false)}
              size="sm"
              type="clear"
              withShadow={false}
            >
              <Trans>Cancel</Trans>
            </Button>

            <Button
              disabled={bookingDuck.loading}
              onClick={() => handleCancelBooking()}
              size="sm"
            >
              <Trans>Confirm</Trans>
            </Button>
          </Box>
        </Box>
      </Popup>

      <Box
        alignItems="center"
        backgroundColor={config.theme.primary}
        borderRadius={20}
        display="flex"
        justifyContent="between"
        padding={22}
        style={{
          backgroundColor: config.theme.primary,
          opacity: !boxEnabling ? '0.5' : '1',
        }}
      >
        <Box>
          {hasDateAndTime ? (
            <Box>
              <Text color="white" size="md" weight="semi-bold">
                <Trans>
                  Date and time
                </Trans>

                <br />

                {visit ? (
                  <Box>
                    {formatBookingDateInLocalTime(visit)}
                  </Box>
                ) : (
                  <Box>
                    {dateFromMonth} {dateFromDay}{!datesAreSameDay ? ` - ${dateToMonth} ${dateToDay}` : ''}
                    &nbsp;{timeFrom}-{timeTo}
                  </Box>
                )}
              </Text>
            </Box>
          ) : (
            <Text color="white" size="md" weight="semi-bold">
              <Trans>
                Date and time <br />
                Not selected
              </Trans>
            </Text>
          )}
        </Box>

        {isBooking ? (
          <Box>
            <Button
              aria-label={t`Book Visit`}
              disabled={!allStepsCompleted || isActionProcessed}
              name={t`Book Visit`}
              onClick={bookVisit}
              size="sm"
              type="white"
            >
              <Trans>
                Book Visit
              </Trans>
            </Button>
          </Box>
        ) : (
          <Button
            aria-label={t`Cancel Visit`}
            disabled={disabledCancel || bookingDuck.loading}
            name={t`Cancel Visit`}
            onClick={() => setPopupOpened(true)}
            size="sm"
            type="white"
          >
            <Trans>
              Cancel Visit
            </Trans>
          </Button>
        )}
      </Box>
    </>
  );
}
