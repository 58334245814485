import { Box, BoxProps, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

export const Container: React.FC<BoxProps> = (props) => {
  const { breakpoints } = useTheme();
  const xl = useMediaQuery(breakpoints.up("xl"));

  return (
    <Box
      margin="0 auto"
      maxWidth={xl ? 1580 : 1380}
      paddingLeft={6}
      paddingRight={6}
      sx={({ breakpoints }) => ({
        paddingBottom: 6,
        [breakpoints.up("xl")]: {
          paddingBottom: 0,
        },
      })} 
      {...props}
    />
  );
};