import { useDispatch } from "react-redux";
import Box from "components/_legacy/Box";
import { Trans } from "@lingui/macro";
import styles from "../styles.module.scss";
import TextField from "@material-ui/core/TextField";
import { setCreateNewMeetingData } from 'App/Store/Meetings/createNewMeetingDuck';
import _ from "underscore";
import { useTypedSelector } from "store/_legacy/Redux/store";

export default function MeetingTitle() {
  const dispatch = useDispatch();
  const { selectedData } = useTypedSelector(state => state.createNewMeeting);

  const { meetingTitle } = selectedData;

  const setMeetingRoomTitle = _.debounce((event: any) => {
    dispatch(setCreateNewMeetingData({
      selectedData: {
        ...selectedData,
        meetingTitle: event.target.value,
      }}));
  }, 300);

  return (
    <Box className={styles.meetingRoomTitle} marginBottom={15}>
      <Box className={styles.meetingRoomLabel}>
        <span className={styles.requiredSign}>*</span>
        <Trans>
          Meeting title
        </Trans>
      </Box>

      <TextField
        className={`input input--default input--inline`}
        defaultValue={meetingTitle}
        fullWidth
        id="meeting-title"
        onChange={setMeetingRoomTitle}
        variant="outlined"
      />
    </Box>
  );
}
