import Api from 'store/_legacy/Services/Api';
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import {
  BULK_CANCEL_VISITS_BOOKING,
  BULK_CANCEL_VISITS_BOOKING_FAIL,
  BULK_CANCEL_VISITS_BOOKING_SUCCESS,
  BulkCancelVisitsBooking,
  BulkCancelVisitsBookingSuccess,
  CANCEL_VISITS_BOOKING,
  CANCEL_VISITS_BOOKING_FAIL,
  CANCEL_VISITS_BOOKING_SUCCESS,
  CancelVisitsBooking,
  CancelVisitsBookingSuccess,
  GET_VISITS,
  GET_VISITS_FAIL,
  GET_VISITS_SUCCESS,
  GetVisits,
} from './index';

function* getVisits(action: GetVisits): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: GET_VISITS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_VISITS_FAIL, payload: e });
  }
}

function* cancelVisitsaga(action: CancelVisitsBooking): any {
  try {
    const payload = yield call(Api, action);

    const success: CancelVisitsBookingSuccess = {
      type: CANCEL_VISITS_BOOKING_SUCCESS,
      payload: payload.data,
    };

    yield put(success);
  } catch (e) {
    yield put({ type: CANCEL_VISITS_BOOKING_FAIL, payload: e });
  }
}

function* bulkCancelVisitSaga(action: BulkCancelVisitsBooking): any {
  try {
    yield call(Api, action);

    const success: BulkCancelVisitsBookingSuccess = {
      type: BULK_CANCEL_VISITS_BOOKING_SUCCESS,
      payload: {
        bookingIds: action.payload.request.data?.bookingIds ?? [],
      },
    };

    yield put(success);
  } catch (e) {
    yield put({ type: BULK_CANCEL_VISITS_BOOKING_FAIL, payload: e });
  }
}

export default [
  takeLatest(GET_VISITS, getVisits),
  takeLatest(CANCEL_VISITS_BOOKING, cancelVisitsaga),
  takeLatest(BULK_CANCEL_VISITS_BOOKING, bulkCancelVisitSaga),
];
