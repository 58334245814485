import { parsePaginationQueryParams } from "../../utils";
import { Paginated } from "../../types";
import { commApi } from "../comm-api";
import { CreatePostCommentInput, CreatePostCommentReplyInput, GetPostCommentsByPostIdInput, PostComment, UpdatePostCommentByIdInput } from "../types";
import { CommApiTag } from "../enums";

const postComments = commApi.injectEndpoints({
  endpoints: (builder) => ({
    createPostComment: builder.mutation<PostComment, CreatePostCommentInput>({
      query: ({ postId, ...body }) => ({ body, url: `/api/v1/posts/${postId}/comments`, method: "POST" }),
      invalidatesTags: (_, __, { postId }) => [{ type: CommApiTag.POST_COMMENT, id: postId }],
    }),
    getPostCommentsByPostId: builder.query<Paginated<PostComment>, GetPostCommentsByPostIdInput>({
      query: ({ postId, ...params }) => ({ url: `/api/v1/posts/${postId}/comments`, params: parsePaginationQueryParams(params) }),
      providesTags: (_, __, { postId }) => [{ type: CommApiTag.POST_COMMENT, id: postId }],
    }),
    updatePostCommentById: builder.mutation<PostComment, UpdatePostCommentByIdInput>({
      query: ({ commentId, ...body }) => ({ body, url: `/api/v1/comments/${commentId}`, method: "PATCH" }),
      invalidatesTags: [CommApiTag.POST_COMMENT],
    }),
    deletePostCommentById: builder.mutation<void, string>({
      query: (commentId) => ({ url: `/api/v1/comments/${commentId}`, method: "DELETE" }),
      invalidatesTags: [CommApiTag.POST_COMMENT],
    }),
    createPostCommentReply: builder.mutation<PostComment, CreatePostCommentReplyInput>({
      query: ({ postId, commentId, ...body }) => ({ body, url: `/api/v1/posts/${postId}/comments/${commentId}/replies`, method: "POST" }),
      invalidatesTags: (_, __, { postId }) => [{ type: CommApiTag.POST_COMMENT, id: postId }],
    }),
  }),
});

export const {
  useCreatePostCommentMutation,
  useGetPostCommentsByPostIdQuery,
  useLazyGetPostCommentsByPostIdQuery,
  useUpdatePostCommentByIdMutation,
  useDeletePostCommentByIdMutation,
  useCreatePostCommentReplyMutation,
} = postComments;
