import { RefObject, useLayoutEffect, useState } from "react";
import useResizeObserver from "@react-hook/resize-observer";

export const useSize = <T extends Element>(target: RefObject<T> | null): DOMRectReadOnly | undefined => {
  const [size, setSize] = useState<DOMRectReadOnly>();

  useLayoutEffect(() => {
    if (target && target.current) {
      setSize(target.current.getBoundingClientRect());
    }
  }, [target]);

  useResizeObserver(target, (entry) => setSize(entry.contentRect));

  return size;
};
