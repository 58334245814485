import { parseArrayQueryParam } from "../../utils";
import { togetherApi } from "../together-api";
import { APIResponseBody, CollectionAPIResponseBody, Desk, GetSavedDesksInput, RemoveSavedDeskInput, SaveDeskInput } from "../types";
import { TogetherApiTag } from "../enums";

const desks = togetherApi.injectEndpoints({
  endpoints: (builder) => ({
    getSavedDesks: builder.query<CollectionAPIResponseBody<Desk>, GetSavedDesksInput>({
      query: ({ include, userId }) => ({
        url: `/api/users/${userId || "me"}/desks/saved`,
        params: { ...parseArrayQueryParam("include", include) },
      }),
      providesTags: [{ type: TogetherApiTag.USER_SAVED_DESK }],
    }),
    getSavedDesksId: builder.query<APIResponseBody<string[]>, { userId?: string } | void>({
      query: (params) => ({ url: `/api/users/${params?.userId || "me"}/desks/saved/id` }),
      providesTags: [{ type: TogetherApiTag.USER_SAVED_DESK }],
    }),
    saveDesk: builder.mutation<APIResponseBody<void>, SaveDeskInput>({
      query: ({ userId, ...body }) => ({
        body,
        url: `/api/users/${userId || "me"}/desks/saved`,
        method: "post",
      }),
      invalidatesTags: [{ type: TogetherApiTag.USER_SAVED_DESK }, { type: TogetherApiTag.USER_HOME_COUNTS }],
    }),
    removeSavedDesk: builder.mutation<APIResponseBody<void>, RemoveSavedDeskInput>({
      query: ({ deskId, userId }) => ({
        url: `/api/users/${userId || "me"}/desks/saved/${deskId}`,
        method: "delete",
      }),
      invalidatesTags: [{ type: TogetherApiTag.USER_SAVED_DESK }, { type: TogetherApiTag.USER_HOME_COUNTS }],
    }),
  }),
});

export const {
  useGetSavedDesksQuery,
  useGetSavedDesksIdQuery,
  useSaveDeskMutation,
  useRemoveSavedDeskMutation,
} = desks;
