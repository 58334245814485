import { SvgIcon, SvgIconProps } from "@mui/material";

export const TogetherCampaignFilled: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path
        d="M12.87 2.222a1 1 0 0 1 .13.493v9.774a1 1 0 0 1-1.39.921L8 11.878v-8.05l3.508-1.984a1 1 0 0 1 1.362.378z"
        fill={props.fill}
        fillOpacity="1"
        fillRule="evenodd"
        stroke={props.stroke}
        strokeLinecap="round"
        strokeWidth="2"
        transform="matrix(.8421 0 0 .8421 -.842 -.303)"
      />
      <path
        d="M16 8h3m-3 4 2 2M16 4l2-2M4 11h3v5.5a1.5 1.5 0 0 1-3 0Zm3-6v6M3.964 5a7.462 7.462 0 0 0 3.674-.967l3.87-2.189a1 1 0 0 1 1.492.87v9.775a1 1 0 0 1-1.39.921l-3.873-1.643A9.66 9.66 0 0 0 3.964 11 1.964 1.964 0 0 1 2 9.036V6.964C2 5.88 2.88 5 3.964 5Z"
        fill="none"
        fillRule="evenodd"
        stroke={props.stroke}
        strokeLinecap="round"
        strokeOpacity="1"
        strokeWidth="2"
        transform="matrix(.8421 0 0 .8421 -.842 -.303)"
      />
    </SvgIcon>
  );
};
