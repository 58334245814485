import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Trans, t } from "@lingui/macro";
import styles from "../../styles.module.scss";

import { regexEmail } from "../../../../../../../../Functions/helpers/regex";
import Text from "components/_legacy/Text";
import Box from "components/_legacy/Box";
import Checkbox from "components/_legacy/Checkbox";
import TextField from "components/_legacy/TextField";
import { updateMeeting } from "../../../../../../../Store/floorMapDuck";
import { FloorMapObject } from "../../../../../../../Store/floorMapDuck/models";
import { RoomServiceType } from "App/Store/Meetings/meetingRooms/models";
import { ResourceAvailableService } from "Admin/Store/floorMapDuck/meeting/models";

interface Props {
  drawingObject: FloorMapObject; // TODO - in future use separate models for desk/section/meeting object
}

export default function ItSupport(props: Props) {
  const dispatch = useDispatch();
  const { drawingObject } = props;
  const { id, availableServices } = drawingObject;
  const itSupport = availableServices?.find(({ type }) => type === RoomServiceType.IT_SUPPORT);
  const itSupportInitEmails = itSupport?.emails?.[0] || "";
  const [itSupportData, setItSupportData] = useState<string | undefined>(itSupportInitEmails);
  const [itSupportError, setItSupportError] = useState(false);

  useEffect(() => {
    setItSupportData(itSupportInitEmails);
  }, [itSupportInitEmails]);

  const setMeetingError = (hasError: boolean) => {
    dispatch(updateMeeting({
      meetingId: typeof id === "string" ? parseInt(id) : id,
      meeting: {
        hasError,
      },
    }));
  };

  const onSetErrorHandlers = (hasError: boolean) => {
    setItSupportError(hasError);
    setMeetingError(hasError);
  };

  const onToggleItSupport = (available: boolean) => {
    if (!available) {
      onSetErrorHandlers(false);
    } else {
      onSetErrorHandlers(!itSupportData?.length);
    }

    if (available) {
      dispatch(updateMeeting({
        meetingId: typeof id === "string" ? parseInt(id) : id,
        meeting: {
          availableServices: [...(availableServices || []), { type: RoomServiceType.IT_SUPPORT }],
        },
      }));
      setItSupportData(undefined);
    } else {
      dispatch(updateMeeting({
        meetingId: typeof id === "string" ? parseInt(id) : id,
        meeting: {
          availableServices: availableServices?.filter(({ type }) => type !== RoomServiceType.IT_SUPPORT),
        },
      }));
    }
  };

  const onChangeItSupportData = (email: string) => {
    const isValid = new RegExp(regexEmail).test(email);
    onSetErrorHandlers(!isValid);

    setItSupportData(email);
  };

  const onSaveItSupport = (email?: string) => {
    if (itSupport && email) {
      dispatch(updateMeeting({
        meetingId: typeof id === "string" ? parseInt(id) : id,
        meeting: {
          availableServices: availableServices?.map((service): ResourceAvailableService => {
            return service.type === RoomServiceType.IT_SUPPORT ? { ...service, emails: [email] } : service; 
          }),
        },
      }));
    }
  };

  return (
    <Box className={styles.amenitiesItems}>
      <Checkbox
        checked={!!itSupport}
        classes={{checkbox: styles.checkbox}}
        key={id}
        label={<Box><Text size="md">I need IT support</Text></Box>}
        name={'it-support'}
        onChange={(value) => onToggleItSupport(value)}
      />

      {!!itSupport &&
        <Box className={styles.detailsItem}>
          <Text color="gray" size="md">
            <Trans>Email:</Trans>
          </Text>

          <TextField
            classes={{ input: styles.textFieldInput }}
            error={itSupportError}
            helperText={itSupportError ? t`This should be a valid email` : ''}
            id={'itSupport-emails'}
            onBlur={() => onSaveItSupport(itSupportData)}
            onChange={(event) => onChangeItSupportData(event.target.value)}
            placeholder={t`Type here`}
            type="email"
            value={itSupportData}
          />
        </Box>
      }
    </Box>
  );
}
