import { t } from "@lingui/macro";
import { AddCircleOutline, MoreVert, SendOutlined } from "@mui/icons-material";
import { Box, Button, ButtonGroup, ButtonProps, Divider, Grid, IconButton, IconButtonProps, List, ListItem, ListItemButton, Pagination, Popover, Skeleton, Switch, Tooltip, Typography, darken, useMediaQuery, useTheme } from "@mui/material";
import { amber } from "@mui/material/colors";
import { BasicChip, CampaignsWrapper, ConfirmationDialog, IconBox, ListHeader, ListHeaderLabel, MonthlyCalendar, TogetherDateAndTime, TogetherDelete, UsersList, useToast } from "components";
import { endOfDay, format, isSameDay, isSameMonth, startOfDay } from "date-fns";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Post, PostApproval, PostApprovalStatus, PostNotificationChannel, PostScheduleType, PostStatus, adminSlice, selectAdminCampaignsQuery, useCreatePostTemplateMutation, useDeletePostByIdMutation, useGetApproversByPostApprovalIdQuery, useLazyGetPostsQuery, useUpdatePostByIdMutation } from "store";

export const CampaignsList: React.FC = () => {
  const { palette, breakpoints } = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const xl = useMediaQuery(breakpoints.up("xl"));
  const toast = useToast();
  const query = useSelector(selectAdminCampaignsQuery);
  const [page, setPage] = useState(1);
  const [post, setPost] = useState<Post>();
  const [approval, setApproval] = useState<PostApproval>();
  const [willDelete, setWillDelete] = useState(false);
  const [moreAnchorEl, setMoreAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [approversAnchorEl, setApproversAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [triggerGetPostsQuery, getPostsQuery] = useLazyGetPostsQuery();
  const { data: getApproversResponse, isLoading: isLoadingApprovers, isFetching: isFetchingApprovers } = useGetApproversByPostApprovalIdQuery(
    { postId: post?.id || "", approvalId: approval?.id || "" },
    { skip: !post || !approval },
  );
  const [deletePostById] = useDeletePostByIdMutation();
  const [updatePostById] = useUpdatePostByIdMutation();
  const [createPostTemplate] = useCreatePostTemplateMutation();

  useEffect(() => {
    const filter: Record<string, string> = { status: PostStatus.CREATED };
    
    if (query?.range) {
      const range = query?.range?.map((value) => new Date(value)) || [new Date()];
      const startDate = startOfDay(range[0]).toISOString();
      const endDate = endOfDay(range.length === 1 ? range[0] : range[1]).toISOString(); 

      filter["schedules.type"] = PostScheduleType.PUBLISH;
      filter["schedules.runAt"] = `$btw:${startDate.toString()},${endDate.toString()}`;
    }

    triggerGetPostsQuery({
      page,
      filter,
      limit: 15,
      search: query?.search,
      include: ["schedules", "$extra.audienceCount", "$extra.testCount", "approvals"],
      orderBy: ["desc:schedules.doneAt", "desc:createdAt"],
    }, true);
  }, [page, JSON.stringify(query)]);

  const handleRangeChange = (value?: Date[]) => {
    dispatch(adminSlice.actions.setCampaignsQuery({ range: value?.map((value) => value.toISOString()) }));
  };

  const getShowApproversClickHandler: (post: Post, approval: PostApproval) => ButtonProps["onClick"] = (post, approval) => (event) => {
    event.preventDefault();
    event.stopPropagation();
    setPost(post);
    setApproval(approval);
    setApproversAnchorEl(event.currentTarget);
  };

  const getMoreClickHandler: (post: Post) => IconButtonProps["onClick"] = (post) => (event) => {
    event.preventDefault();
    event.stopPropagation();
    setPost(post);
    setMoreAnchorEl(event.currentTarget);
  };

  const deletePost = async (post?: Post): Promise<void> => {
    setWillDelete(false);
    setMoreAnchorEl(null);
    setPost(undefined);

    if (!post) {
      return;
    }

    const response = await deletePostById(post.id);

    if ("error" in response) {
      toast.showToast({ severity: "error", message: t`Failed to delete post, please try again.` });
    } else {
      toast.showToast({ severity: "success", message: t`Post was deleted.` });
    }
  };

  const launchPost = async (postId: string): Promise<void> => {
    const response = await updatePostById({ postId, status: PostStatus.PUBLISHED });

    if ("error" in response) {
      toast.showToast({ severity: "error", message: t`Failed to publish post, please try again.` });
    } else {
      toast.showToast({ severity: "success", message: t`Post was published.` });
    }

    setMoreAnchorEl(null);
  };

  const addToTemplates = async (postId: string): Promise<void> => {
    const response = await createPostTemplate(postId);

    if ("error" in response) {
      toast.showToast({ severity: "error", message: t`Failed to add post to templates, please try again.` });
    } else {
      toast.showToast({ severity: "success", message: t`Post was added to templates.` });
    }

    setMoreAnchorEl(null);
  };

  const width = {
    audience: xl ? 86 : 76,
    sendDate: xl ? 100 : 90,
    notifications: xl ? 90 : 80,
    approver: xl ? 76 : 76,
    testInternally: xl ? 102 : 92,
  };
  const fontSize = { header: xl ? 13 : 12, body: xl ? 14 : 12 };
  const range = query?.range ? query.range.map((date) => new Date(date)) : undefined;
  const { data: getPostsResponse, isLoading } = getPostsQuery;
  const { items: posts, meta } = getPostsResponse || {};
  const { items: approvers } = getApproversResponse || {};

  return (
    <>
      <CampaignsWrapper>
        <Grid columns={20} container width="100%">
          <Grid alignItems="stretch" display="flex" flexDirection="column" item xl={15} xs={14}>
            <ListHeader>
              <ListHeaderLabel sx={{ flex: 1 }}>{t`Name`}</ListHeaderLabel>
              <ListHeaderLabel sx={{ width: width.audience }}>{t`Audience`}</ListHeaderLabel>
              <ListHeaderLabel sx={{ width: width.sendDate }}>{t`Send date`}</ListHeaderLabel>
              <ListHeaderLabel sx={{ width: width.notifications }}>{t`Notifications`}</ListHeaderLabel>
              <ListHeaderLabel sx={{ width: width.approver }}>{t`Approver`}</ListHeaderLabel>
              <ListHeaderLabel sx={{ width: width.testInternally }}>{t`Test internally`}</ListHeaderLabel>
              <Box component="span" width={30} />
            </ListHeader>
            {isLoading ? (
              <Box mb={2} minHeight={xl ? 690 : 645}>
                <Skeleton height={45} sx={{ borderRadius: 0 }} variant="rectangular" />
                <Divider sx={{ opacity: 0.6 }} />
                <Skeleton height={45} sx={{ borderRadius: 0 }} variant="rectangular" />
                <Divider sx={{ opacity: 0.6 }} />
                <Skeleton height={45} sx={{ borderRadius: 0 }} variant="rectangular" />
                <Divider sx={{ opacity: 0.6 }} />
                <Skeleton height={45} sx={{ borderRadius: 0 }} variant="rectangular" />
                <Divider sx={{ opacity: 0.6 }} />
                <Skeleton height={45} sx={{ borderRadius: 0 }} variant="rectangular" />
              </Box>
            ) : posts?.length ? (
              <List disablePadding sx={{ mb: 2 }}>
                {posts?.map((post) => {
                  const audience = post.$extra?.audienceCount || 0;
                  const schedule = post.schedules?.find(({ type }) => type === PostScheduleType.PUBLISH);
                  const sendDate = schedule ? format(new Date(schedule.doneAt || schedule.runAt), "LLL, d, HH:mm") : "-";
                  const notifications = post.notifies?.length 
                    ? post.notifies.map((channel) => channel === PostNotificationChannel.EMAIL ? t`Email` : t`App`).join(", ") 
                    : "-";
                  const test = post.$extra?.testCount || 0;
                  const approval = post.approvals?.find(({ status }) => status === PostApprovalStatus.PENDING);
                  const disapproval = !approval && post.approvals?.find(({ status }) => status === PostApprovalStatus.DISAPPROVED);

                  return (
                    <ListItem disablePadding divider key={post.id} sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.05)" }}>
                      <ListItemButton onClick={() => history.push(`/admin/campaigns/${post.id}/edit`)} sx={{ px: 1, py: 1.5, gap: 1, flex: 1 }}>
                        {!approval && !disapproval ? (
                          <Typography flex={1} fontSize={fontSize.body} noWrap title={post.title}>{post.title}</Typography>
                        ) : (
                          <Box alignItems="baseline" display="flex" flex={1} gap={1}>
                            <Typography fontSize={fontSize.body} maxWidth={160} noWrap title={post.title}>{post.title}</Typography>
                            {approval ? (
                              <Tooltip disableInteractive placement="top" title={t`Click to show approvers`}>
                                <Button
                                  disableElevation
                                  onClick={getShowApproversClickHandler(post, approval)}
                                  size="small"
                                  sx={{
                                    bgcolor: amber[600],
                                    py: 0.5,
                                    px: 1,
                                    fontSize: 12,
                                    ":hover": { bgcolor: darken(amber[600], 0.16) },
                                  }}
                                  variant="contained"
                                >
                                  {t`Needs approval`}
                                </Button>
                              </Tooltip>
                            ) : undefined}
                            {disapproval ? (
                              <Tooltip disableInteractive placement="top" title={t`Your post was not approved.`}>
                                <Typography
                                  bgcolor={palette.error.main}
                                  borderRadius={2}
                                  color="#fff"
                                  fontSize={12}
                                  fontWeight={600}
                                  lineHeight={1.75}
                                  px={1}
                                  py={0.5}
                                >
                                  {t`Not approved`}
                                </Typography>
                              </Tooltip>
                            ) : undefined}
                          </Box>
                        )}
                        <Typography fontSize={fontSize.body} noWrap width={width.audience}>{audience}</Typography>
                        <Typography fontSize={fontSize.body} noWrap title={sendDate} width={width.sendDate}>{sendDate}</Typography>
                        <Typography fontSize={fontSize.body} noWrap title={notifications} width={width.notifications}>{notifications}</Typography>
                        <Typography fontSize={fontSize.body} noWrap title="-" width={width.approver}>-</Typography>
                        <Box display="flex" width={width.testInternally}>
                          <Switch checked={test > 0} disabled size="small" />
                        </Box>
                        <IconButton color="primary" onClick={getMoreClickHandler(post)} size="small">
                          <MoreVert color={"primary"} fontSize="small" />
                        </IconButton>
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            ) : (
              <Typography color={palette.grey[700]} fontSize={14} my={4} textAlign="center">
                {t`No new campaigns found.`}
              </Typography>
            )}
            {(meta?.pages || 0) > 1 ? (
              <Pagination count={meta?.pages} onChange={(_, page) => setPage(page)} page={page} shape="rounded" />
            ) : undefined}
          </Grid>
          <Grid item pl={xl ? 4 : 2} xl={5} xs={6}>
            <Box display="flex" justifyContent="space-between">
              <Box alignItems="center" display="flex" gap={1}>
                <IconBox>
                  <TogetherDateAndTime fill={palette.grey[700]} sx={{ width: 16 }} />
                </IconBox>
                <Typography color={!range ? palette.grey[700] : undefined} fontSize={16} fontWeight={600} lineHeight={1}>
                  {range ? format(range[0], "LLL, d") : ""}
                  {range?.length === 2 && !isSameDay(range[0], range[1]) && isSameMonth(range[0], range[1]) ? "-" + format(range[1], "d") : ""}
                  {range?.length === 2 && !isSameDay(range[0], range[1]) && !isSameMonth(range[0], range[1]) ? " - " + format(range[1], "LLL, d") : ""}
                  {!range ? t`Not selected` : undefined}
                </Typography>
              </Box>
              <Box display="flex">
                <Button onClick={() => handleRangeChange(undefined)} size="small" sx={{ color: palette.grey[700] }} variant="text">{t`Reset`}</Button>
                <Button onClick={() => handleRangeChange([new Date()])} size="small" variant="text">{t`Today`}</Button>
              </Box>
            </Box>
            <Divider sx={{ my: 2 }} />
            <MonthlyCalendar  onChange={handleRangeChange} selectionType="range" value={range} width="auto" />
          </Grid>
        </Grid>
      </CampaignsWrapper>
      <Popover
        anchorEl={approversAnchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={() => setApproversAnchorEl(null)}
        open={!!approversAnchorEl}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Box p={2} width={420}>
          <Typography fontWeight={600} mb={1}>{t`Approvers`}</Typography>
          <Box maxHeight={220} sx={{ overflowX: "hidden", overflowY: "auto" }}>
            <UsersList loading={isLoadingApprovers || isFetchingApprovers} value={approvers} />
          </Box>
        </Box>
      </Popover>
      <Popover
        anchorEl={moreAnchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={() => setMoreAnchorEl(null)}
        open={!!moreAnchorEl}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <ButtonGroup
          orientation="vertical"
          sx={{ 
            "& .MuiButton-root": { justifyContent: "flex-start" },
            "& .MuiButtonGroup-grouped:not(:last-of-type)": { border: "none" },
          }}
          variant="text"
        >
          <Button onClick={post ? () => void launchPost(post.id) : undefined} sx={{ gap: 1 }}>
            <SendOutlined fontSize="small" />
            <Typography fontSize={14}>{t`Launch Campaign`}</Typography>
          </Button>
          <Button onClick={post ? () => void addToTemplates(post.id) : undefined} sx={{ gap: 1 }}>
            <AddCircleOutline fontSize="small" />
            <Typography fontSize={14}>{t`Add to Templates`}</Typography>
          </Button>
          <Button onClick={post ? () => setWillDelete(true) : undefined} sx={{ gap: 1 }}>
            <TogetherDelete sx={{ width: 20, height: 20 }} />
            <Typography fontSize={14}>{t`Delete Campaign`}</Typography>
          </Button>
        </ButtonGroup>
      </Popover>
      <ConfirmationDialog
        description={t`Are you sure you want to delete "${post?.title}"?`}
        onClose={() => setWillDelete(false)}
        onConfirm={() => void deletePost(post)}
        open={!!willDelete}
        title={t`Delete post`}
      />
    </>
  );
};
