import {useDispatch} from "react-redux";
import {useTypedSelector} from "store/_legacy/Redux/store";
import _ from "underscore";
import styles from "./styles.module.scss";

import ManageLocationsModal from "components/_legacy/ManageLocationsForm";
import {UserManagementUser} from "../../../Store/userManagement/models";
import {updateUserLocations} from "../../../Store/userManagement";
import {Location} from "../../../../App/Store/Locations/locationsDuck/models";

interface AdminLocationsModalProps {
  user: UserManagementUser;
}

export default function AdminLocationsModal(props: AdminLocationsModalProps) {
  const dispatch = useDispatch();
  const { profile } = useTypedSelector(state => state);
  const { user } = props;

  const onSave = (selectedLocations: Location[]) => {
    const updatedLocationsIds = selectedLocations.map(l => l.id);
    const isDataChanged = !_.isEqual(_.sortBy(updatedLocationsIds), _.sortBy(user.locationIds));

    if (isDataChanged) {
      dispatch(updateUserLocations({ userId: user.id, locationIds: updatedLocationsIds }));
    }
  };

  return (
    <ManageLocationsModal
      contentStyle={{width: '270px'}}
      locationIds={user.locationIds}
      onSave={onSave}
      openButtonClassName={styles.openButtonClassName}
      openButtonDisabled={!profile.roleAccess.superAdmin}
    />
  );
}
