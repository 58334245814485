import Api from 'store/_legacy/Services/Api';
import {
  call,
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import {
  GET_QUESTIONNAIRE_APPOINTMENT_BY_ID,
  GET_QUESTIONNAIRE_APPOINTMENT_BY_ID_FAIL,
  GET_QUESTIONNAIRE_APPOINTMENT_BY_ID_SUCCESS,
  GET_QUESTIONNAIRE_BOOKINGS,
  GET_QUESTIONNAIRE_BOOKINGS_FAIL,
  GET_QUESTIONNAIRE_BOOKINGS_SUCCESS,
  GET_QUESTIONNAIRE_BOOKING_BY_ID,
  GET_QUESTIONNAIRE_BOOKING_BY_ID_FAIL,
  GET_QUESTIONNAIRE_BOOKING_BY_ID_SUCCESS,
  GET_QUESTIONS_QUESTIONNAIRE,
  GET_QUESTIONS_QUESTIONNAIRE_FAIL,
  GET_QUESTIONS_QUESTIONNAIRE_SUCCESS,
  GetQuestionnaireAppointmentById,
  GetQuestionnaireBookingById,
  GetQuestionnaireBookings,
  GetQuestionsQuestionnaire,
  PASS_QUESTIONNAIRE_RESULT,
  PassQuestionnaireResult,
  SAVE_ANSWERS_TO_QUESTIONNAIRE,
  SAVE_ANSWERS_TO_QUESTIONNAIRE_FAIL,
  SAVE_ANSWERS_TO_QUESTIONNAIRE_SUCCESS,
  SET_QUESTIONNAIRE_DATA,
  SaveAnswersToQuestionnaire,
} from './index';

function* getQuestionsQuestionnaire(action: GetQuestionsQuestionnaire): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: GET_QUESTIONS_QUESTIONNAIRE_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_QUESTIONS_QUESTIONNAIRE_FAIL, payload: e });
  }
}

function* passQuestionnaireResult(action: PassQuestionnaireResult): any {
  const dataObject = {
    isOpen: false,
    passedStatus: '',
    locationId: '',
    activeIndex: 0,
    answers: [],
    questions: [],
    status: 'progress',
    currentQuestionnaires: {
      bookings: [],
      appointments: [],
    },
  };

  try {
    const payload = yield call(Api, action);
    dataObject.passedStatus = action.payload.request.data?.hasPassedQuestionnaire ? 'passed' : 'cancelled';

    if (payload.status === 200 || payload.status === 201) {
      yield put({ type: SET_QUESTIONNAIRE_DATA, payload: dataObject });
    }
  } catch (e) {
    yield put({ type: GET_QUESTIONS_QUESTIONNAIRE_FAIL, payload: e });
    yield put({ type: SET_QUESTIONNAIRE_DATA, payload: dataObject });
  }
}

function* getQuestionnaireBookingByIdSaga(action: GetQuestionnaireBookingById): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: GET_QUESTIONNAIRE_BOOKING_BY_ID_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_QUESTIONNAIRE_BOOKING_BY_ID_FAIL, payload: e });
  }
}

function* getQuestionnaireAppointmentByIdSaga(action: GetQuestionnaireAppointmentById): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: GET_QUESTIONNAIRE_APPOINTMENT_BY_ID_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_QUESTIONNAIRE_APPOINTMENT_BY_ID_FAIL, payload: e });
  }
}

function* getQuestionnaireBookingsSaga(action: GetQuestionnaireBookings): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: GET_QUESTIONNAIRE_BOOKINGS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_QUESTIONNAIRE_BOOKINGS_FAIL, payload: e });
  }
}

function* saveAnswersToQuestionnaireSaga(action: SaveAnswersToQuestionnaire): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: SAVE_ANSWERS_TO_QUESTIONNAIRE_SUCCESS, payload });
  } catch (e) {
    yield put({ type: SAVE_ANSWERS_TO_QUESTIONNAIRE_FAIL, payload: e });
  }
}

export default [
  takeEvery(GET_QUESTIONS_QUESTIONNAIRE, getQuestionsQuestionnaire),
  takeLatest(PASS_QUESTIONNAIRE_RESULT, passQuestionnaireResult),
  takeLatest(GET_QUESTIONNAIRE_BOOKING_BY_ID, getQuestionnaireBookingByIdSaga),
  takeLatest(GET_QUESTIONNAIRE_APPOINTMENT_BY_ID, getQuestionnaireAppointmentByIdSaga),
  takeLatest(GET_QUESTIONNAIRE_BOOKINGS, getQuestionnaireBookingsSaga),
  takeLatest(SAVE_ANSWERS_TO_QUESTIONNAIRE, saveAnswersToQuestionnaireSaga),
];
