import { Editor } from "slate";
import { EditorFormatMark } from "../types";
import { isEditorFormatMarkActive } from "./is-editor-format-mark-active";

const conflictMapping: Partial<Record<EditorFormatMark, EditorFormatMark[]>> = {
  underline: ["line-through"],
  "line-through": ["underline"],
};

export const toggleEditorFormatMark = (editor: Editor, mark: EditorFormatMark): void => {
  const isActive = isEditorFormatMarkActive(editor, mark);

  if (isActive) {
    Editor.removeMark(editor, mark);
  } else {
    const conflicts = conflictMapping[mark] || [];

    for (const conflict of conflicts) {
      const isActive = isEditorFormatMarkActive(editor, conflict);

      if (isActive) {
        Editor.removeMark(editor, conflict);
      }
    }

    Editor.addMark(editor, mark, true);
  }
};
