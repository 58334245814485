import { createApi } from "@reduxjs/toolkit/query/react";
import { MediaApiTag } from "./enums";
import { baseQuery } from "../utils";

export const mediaApi = createApi({
  reducerPath: "mediaApi",
  tagTypes: [MediaApiTag.ATTACHMENT],
  baseQuery: baseQuery({ baseUrl: process.env.REACT_APP_MEDIA_API_URL }),
  endpoints: () => ({}),
});
