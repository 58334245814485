import visitStyles from '../styles.module.scss';
import Box from 'components/_legacy/Box';
import Divider from 'components/_legacy/Divider';
import format from 'date-fns/format';
import styles from './styles.module.scss';
import Text from 'components/_legacy/Text';
import VisitBreadcrumbs from '../VisitBreadcrumbs';
import VisitCalendar from '../Calendar/VisitCalendar';
import VisitDetails from '../VisitDetails';
import VisitFloorItem, { VisitFloorItemLoading } from './VisitFloorItem';
import { getFloors, getLocationDisabledDates } from 'App/Store/Locations/locationsDuck';
import { setCreateNewVisitData } from 'App/Store/Bookings/createNewVisitDuck';
import { StaggeredItems } from 'components/_legacy/Animations/StaggeredItems';
import { Trans, t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useTypedSelector } from 'store/_legacy/Redux/store';

export default function VisitFloor() {
  const dispatch = useDispatch();
  const { createNewVisit, locations: locationsDuck } = useTypedSelector(state => state);

  const {
    dateFrom,
    dateTo,
    floorId,
    locationId,
    timeFrom,
    timeTo,
  } = createNewVisit;
  const { floors, loading } = locationsDuck;

  useEffect(() => {
    // load disabled days only if location changed
    if (locationId) {
      dispatch(getLocationDisabledDates({ locationId }));
    }
  }, [locationId]);

  useEffect(() => {
    if (locationId && dateFrom && dateTo) {
      dispatch(getFloors({
        data: {
          custom: {
            dateFrom: dateFrom ? format(new Date(dateFrom), 'yyyy-MM-dd') : '',
            dateTo: dateTo ? format(new Date(dateTo), 'yyyy-MM-dd') : '',
            timeFrom: timeFrom ?? '',
            timeTo: timeTo ?? '',
          },
          daily: undefined,
          weekly: undefined,
          onlyAvailableDesks: false,
        },
        locationId,
      }));
    }
  }, [dateFrom, dateTo, timeFrom, timeTo, locationId]);

  const onChangeFloor = (floorId: string) => {
    dispatch(setCreateNewVisitData({ floorId }));
  };

  return (
    <Box display="grid" gap={30} gridTemplateColumns="8fr 4fr" paddingBottom={50}>
      <Box>
        <Box marginBottom={15}>
          <VisitBreadcrumbs />
        </Box>

        {loading ? (
          <VisitFloorItemLoading />
        ) : (
          <Box className={styles.floorList} dataTestId="floor-list" display="grid">
            {floors.length > 0 ? (
              <StaggeredItems className={styles.items}>
                {floors.map((floor, index) => (
                  <VisitFloorItem
                    {...floor}
                    key={floor.id + index}
                    onClick={onChangeFloor}
                    selected={floorId === floor.id}
                  />
                ))}
              </StaggeredItems>
            ) : (
              <Box
                alignItems="center"
                display="flex"
                height="100%"
                justifyContent="center"
                margin="20px 0 100px"
              >
                <Text align="center" color="gray" size="md">
                  {t`There're no floors for this location`}
                </Text>
              </Box>
            )}
          </Box>
        )}
      </Box>

      <Box className={visitStyles.stickyBookingWrapper}>
        <Box marginBottom={15}>
          <Text size="xlg" weight="semi-bold">
            <Trans>Visit details</Trans>
          </Text>
        </Box>

        <VisitCalendar />

        <Box className={visitStyles.stickyBookingRow} marginTop={10}>
          <Box marginBottom={10}>
            <Divider />
          </Box>

          <VisitDetails />
        </Box>
      </Box>
    </Box >
  );
}
