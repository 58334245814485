import styles from './styles.module.scss';
import {IInputProps, ILayoutProps, IPreviewProps} from "react-dropzone-uploader/dist/Dropzone";
import Text from "../Text";
import {Trans, t} from "@lingui/macro";
import Box from "../Box";
import clsx from "clsx";
import {useTypedSelector} from "store/_legacy/Redux/store";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import LinearProgress from "@material-ui/core/LinearProgress";

/**
 * Render the input component with correct styles and functionality
 */
export function InputComponent({ accept, onFiles, getFilesFromEvent }: IInputProps) {
  return (
    <label className={styles.dropzone} htmlFor="add-image">
      <Text align="center" color="gray" size="md">
        <Trans>
          Drop file here or click Browse File
        </Trans>
      </Text>

      <Box marginTop={7}>
        <Text color="blue" size="md" weight="semi-bold">
          <Trans>
            Browse File
          </Trans>
        </Text>
      </Box>

      <input
        accept={accept}
        aria-label={t`Add Image`}
        id="add-image"
        multiple={false}
        name={t`Add Image`}
        onChange={e => {
          getFilesFromEvent(e).then((chosenFiles: any) => {
            onFiles(chosenFiles);
          });
        }}
        style={{ display: 'none' }}
        type="file"
      />
    </label>
  );
}

/**
 * Renders the react-dropzone-uploader component with all it's inner components
 */
export function LayoutComponent({
  input,
  dropzoneProps,
  previews,
  extra: {
    active,
    reject,
  },
}: ILayoutProps) {
  const classes = clsx(
    styles.dropzoneLayout,
    active && styles.active,
    reject && styles.reject,
  );

  return (
    <div className={classes}>
      <div {...dropzoneProps}>
        {input}
        {previews}
      </div>
    </div>
  );
}

/**
 * Renders a progress with file name and delete icon
 */
interface PreviewComponentProps extends IPreviewProps {
  handleRemoveFile: () => any;
  loadingState?: boolean;
}

export function PreviewComponent({
  handleRemoveFile,
  loadingState,
  meta,
}: PreviewComponentProps) {
  const { name, percent, status } = meta;
  const { theme } = useTypedSelector(state => state.config);
  const loadClass = loadingState ? styles.hide : '';

  return (
    <Box className={`${styles.previewContainer} ${loadClass}`}>
      <Box alignItems="center" display="flex" justifyContent="between">
        <Text size="md" truncate>
          {name}
        </Text>

        {status === 'done' &&
        <IconButton onClick={handleRemoveFile} size="small" style={{ padding: 0 }}>
          <DeleteOutlineIcon style={{ color: theme.primary, fontSize: 22 }} />
        </IconButton>
        }
      </Box>

      {['preparing', 'started', 'uploading'].includes(status) &&
      <Box marginTop={1}>
        <LinearProgress
          classes={{
            root: styles.linearProgress,
            barColorPrimary: styles.primary,
          }}
          value={percent}
          variant="determinate"
        />
      </Box>
      }
    </Box>
  );
}
