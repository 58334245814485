import Box from 'components/_legacy/Box';
import Button from 'components/_legacy/Button';
import Divider from 'components/_legacy/Divider';
import format from 'date-fns/format';
import isSameDay from 'date-fns/isSameDay';
import isSameMonth from 'date-fns/isSameMonth';
import Text from 'components/_legacy/Text';
import { setVisitsData } from 'Admin/Store/visits';
import { Trans } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTypedSelector } from 'store/_legacy/Redux/store';
import {
  DateRange,
  Range,
  RangeKeyDict,
} from 'react-date-range';

export default function AppointmentCalendar() {
  const dispatch = useDispatch();
  const { filters } = useTypedSelector(state => state.adminVisits);
  const { selectedStartDate, selectedEndDate } = filters;

  const initialRange = {
    startDate: selectedStartDate ? new Date(selectedStartDate) : new Date(),
    endDate: selectedEndDate ? new Date(selectedEndDate) : new Date(),
    key: 'selection',
  };
  const [selectionRange, setSelectionRange] = useState<Range>(initialRange);

  const onResetDate = () => {
    const resetData = {
      selection: {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      },
    };
    onChangeDate(resetData);
  };

  const onChangeDate = (ranges: RangeKeyDict) => {
    if (ranges.selection.startDate && ranges.selection.endDate) {
      dispatch(setVisitsData({
        filters: {
          ...filters,
          selectedStartDate: ranges.selection.startDate,
          selectedEndDate: ranges.selection.endDate,
        },
      }));
    }
  };

  // setSelectionRange by useEffect for all calendars on the page
  useEffect(() => {
    const rangesData = {
      startDate: new Date(selectedStartDate),
      endDate: new Date(selectedEndDate),
      key: 'selection',
    };

    setSelectionRange(rangesData);
  }, [selectedStartDate, selectedEndDate]);


  const formatDates = (): string => {
    const startDate = new Date(selectedStartDate);
    const endDate = new Date(selectedEndDate);

    if (isSameDay(startDate, endDate)) {
      return format(startDate, 'MMM, dd');
    }

    if (isSameMonth(startDate, endDate)) {
      return `${format(startDate, 'MMM, dd')} - ${format(endDate, 'dd')}`;
    }

    return `${format(startDate, 'MMM dd')} - ${format(endDate, 'MMM dd')}`;
  };

  return (
    <Box marginTop={20}>
      <Box alignItems="center" display="flex" justifyContent="between" marginBottom={15} marginTop={-3}>
        <Box alignItems="center" display="grid" gap={10} gridTemplateColumns="auto auto">
          <svg height="30px" version="1.1" viewBox="0 0 30 30" width="30px">
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
              <g transform="translate(-1030.000000, -112.000000)">
                <g id="icons/calendarsmall" transform="translate(1030.000000, 112.000000)">
                  <rect fill="#F4F4F4" height="30" id="Rectangle" rx="8" width="30" x="0" y="0"></rect>
                  <path d="M20,7 C20.5522847,7 21,7.44771525 21,8 L21,9 L23,9 L23,20 C23,21.6568542 21.6568542,23 20,23 L20,23 L10,23 C8.34314575,23 7,21.6568542 7,20 L7,20 L7,9 L9,9 L9,9 L9,8 C9,7.44771525 9.44771525,7 10,7 C10.5522847,7 11,7.44771525 11,8 L11,9 L19,9 L19,9 L19,8 C19,7.44771525 19.4477153,7 20,7 Z M21,14 L9,14 L9,20 C9,20.5128358 9.38604019,20.9355072 9.88337887,20.9932723 L9.88337887,20.9932723 L10,21 L20,21 C20.5522847,21 21,20.5522847 21,20 L21,20 L21,14 L21,14 Z M14,15 C14.5522847,15 15,15.4477153 15,16 L15,18 C15,18.5522847 14.5522847,19 14,19 L12,19 C11.4477153,19 11,18.5522847 11,18 L11,16 C11,15.4477153 11.4477153,15 12,15 L14,15 Z M21,11 L9,11 L9,12 L9,12 L21,12 L21,11 Z" fill="#5E5E5E" fillRule="nonzero" id="Combined-Shape"></path>
                </g>
              </g>
            </g>
          </svg>

          <Text weight="semi-bold">
            {formatDates()}
          </Text>
        </Box>

        <Box alignItems="center" display="flex">
          <Box marginRight={20}>
            <Button noPaddingX onClick={() => onResetDate()} type="clear">
              <Trans>Reset</Trans>
            </Button>
          </Box>
          <Button noPaddingX onClick={() => onResetDate()} type="clear">
            <Trans>Today</Trans>
          </Button>
        </Box>
      </Box>

      <Divider />

      <DateRange
        className="custom-calendar"
        onChange={onChangeDate}
        rangeColors={['#efeff8']}
        ranges={[selectionRange]}
        showDateDisplay={false}
        showMonthAndYearPickers={false}
      />
    </Box>
  );
}
