import Api from 'store/_legacy/Services/Api';
import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';
import {
  EDIT_CALENDAR_DATES,
  EDIT_CALENDAR_DATES_FAIL,
  EDIT_CALENDAR_DATES_SUCCESS,
  EditCalendarDates,
  GET_CALENDAR_DATES,
  GET_CALENDAR_DATES_FAIL,
  GET_CALENDAR_DATES_SUCCESS,
  GetCalendarDates,
  SET_CHANGED_CALENDAR_INDEX,
} from "./index";

function* getCalendarDates(action: GetCalendarDates): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: GET_CALENDAR_DATES_SUCCESS, payload });
    yield put({ type: SET_CHANGED_CALENDAR_INDEX, payload: new Date().getTime() });
  } catch (e) {
    yield put({ type: GET_CALENDAR_DATES_FAIL, payload: e });
    yield put({ type: SET_CHANGED_CALENDAR_INDEX, payload: new Date().getTime() });
  }
}

function* editCalendarDates(action: EditCalendarDates): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: EDIT_CALENDAR_DATES_SUCCESS, payload });
  } catch (e) {
    yield put({ type: EDIT_CALENDAR_DATES_FAIL, payload: e });
  }
}

export default [
  takeEvery(GET_CALENDAR_DATES, getCalendarDates),
  takeEvery(EDIT_CALENDAR_DATES, editCalendarDates),
];
