import React, { useState } from "react";
import { EditorToolbarButton } from "./editor-toolbar-button";
import { LinkOffRounded, LinkRounded } from "@mui/icons-material";
import { Box, Button, ButtonProps, FilledInput, Popover } from "@mui/material";
import { useSlate } from "slate-react";
import { isEditorElementActive, unwrapEditorLink, wrapEditorLink } from "./utils";
import { EditorElementType } from "./enums";
import { t } from "@lingui/macro";
import { isURL } from "class-validator";

export const EditorLinkButton: React.FC = () => {
  const editor = useSlate();
  const [href, setHref] = useState("");
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isActive = isEditorElementActive(editor, { type: EditorElementType.ANCHOR });
  
  const handleLinkClick: ButtonProps["onClick"] = (event) => {
    if (!isActive) {
      setAnchorEl(event.currentTarget);
    } else {
      unwrapEditorLink(editor);
    }
  };

  const handleAddClick = () => {
    if (isURL(href)) {
      wrapEditorLink(editor, href);
      setAnchorEl(null);
    }
  };

  const disabled = !isActive && (editor.selection ? !editor.string(editor.selection).length : true);

  return (
    <>
      <EditorToolbarButton
        Icon={isActive ? LinkOffRounded : LinkRounded}
        disabled={disabled}
        onClick={handleLinkClick}
        title={isActive ? t`Remove link` : t`Insert link`}
      />
      <Popover anchorEl={anchorEl} anchorOrigin={{ vertical: "bottom", horizontal: "left" }} onClose={() => setAnchorEl(null)} open={!!anchorEl}>
        <Box p={2}>
          <FilledInput onChange={(event) => setHref(event.currentTarget.value)} placeholder="Insert URL" sx={{ mb: 2 }} value={href} />
          <Box display="flex" justifyContent="flex-end">
            <Button onClick={() => handleAddClick()} size="small" variant="contained">{t`Add`}</Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
};
