import React, { useMemo } from "react";
import { DailyCalendarViewRowProps } from "./types";
import { Box, useTheme } from "@mui/material";
import { isCalendarViewScheduleVisible, isCalendarViewStepDisabled, resolveCalendarViewScheduleInterval, resolveCalendarViewScheduleSpan } from "./utils";
import { DailyCalendarViewStep } from "./daily-calendar-view-step";
import { DailyCalendarViewSchedule } from "./daily-calendar-view-schedule";
import { t } from "@lingui/macro";
import { addDays, isAfter, isEqual, startOfDay } from "date-fns";

export const DailyCalendarViewRow: React.FC<DailyCalendarViewRowProps> = (props) => {
  const {
    item,
    stepDates,
    selection,
    stepHeight,
    stepWidth,
    yIndex,
    step,
    firstStepDate,
    lastStepDate,
    blocks,
    disableSelection,
    handleStepClick,
    handleSelectionClick,
    onScheduleClick,
  } = props;
  const { palette, background } = useTheme();
  const maxDate = useMemo(() => addDays(startOfDay(new Date()), item.reservationDayLimit || 180), [item.reservationDayLimit]);

  return (
    <Box display="flex" position="relative">
      {stepDates.map((stepDate, xIndex) => {
        const disabled = isEqual(stepDate, addDays(maxDate, 1)) || isAfter(stepDate, addDays(maxDate, 1));
        const schedules: [startDate: Date, endDate: Date][] = [];

        if (blocks?.length) {
          schedules.push(...blocks);
        }

        if (item?.schedules?.length) {
          schedules.push(...item.schedules.map<[Date, Date]>(({ startDate, endDate }) => [startDate, endDate]));
        }

        return (
          <DailyCalendarViewStep
            disableSelection={disableSelection}
            disabled={disabled || isCalendarViewStepDisabled(stepDate, item.id, schedules, selection)}
            disabledColor={disabled ? palette.grey[100] : undefined}
            height={stepHeight}
            key={`${item.id}${stepDate.toISOString()}`}
            onClick={() => handleStepClick(item.id, stepDate)}
            tooltip={t`Click to select time`}
            width={stepWidth}
            xIndex={xIndex}
            yIndex={yIndex}
          />
        );
      })}
      {item.schedules?.map(({ startDate, endDate, summary, background, color, reservationId, entryId }) => {
        if (!isCalendarViewScheduleVisible(endDate, step, firstStepDate)) {
          return;
        }

        return (
          <DailyCalendarViewSchedule
            background={background}
            color={color}
            endDate={endDate}
            entryId={entryId}
            firstStepDate={firstStepDate}
            key={`${item.id}${startDate.toISOString()}${endDate.toISOString()}`}
            lastStepDate={lastStepDate}
            onScheduleClick={onScheduleClick}
            reservationId={reservationId}
            startDate={startDate}
            step={step}
            stepHeight={stepHeight}
            stepWidth={stepWidth}
            summary={summary}
          />
        );
      })}
      {!disableSelection && selection && selection.id === item.id && selection.start ? (
        selection.isAllDay ? (
          <Box
            bgcolor={background.blue.light}
            borderRadius={2}
            data-cid="selection"
            height={stepHeight}
            left={0}
            onClick={() => handleSelectionClick()}
            position="absolute"
            top={0}
            width={stepWidth * (1440 / step)}
            zIndex={10}
          />
        ) : (
          <Box
            bgcolor={selection.start && selection.end ? background.blue.light : palette.grey[100]}
            borderRadius={2}
            data-cid="selection"
            height={stepHeight}
            left={resolveCalendarViewScheduleSpan(selection.start, firstStepDate, step, firstStepDate) * stepWidth}
            onClick={() => handleSelectionClick()}
            position="absolute"
            top={0}
            width={
              selection.end 
                ? (resolveCalendarViewScheduleInterval(selection.start, selection.end, step, firstStepDate, lastStepDate) * stepWidth) 
                : stepWidth
            }
            zIndex={10}
          />
        )
      ) : undefined }
    </Box>
  );
};