import L from 'leaflet';

/**
 * Receives an image height and size with an already calculated proportion
 * that it must have, and returns a Latitude/Longitude expression to create
 * that image into a square starting from point 0,0.
 */
export function getImageBoundsLimited({
  height,
  width,
  proportion,
}: {
  height: number;
  width: number
  proportion: number;
}): L.LatLngBoundsExpression {
  const limitedHeight = height * proportion / 100;
  const limitedWidth = width * proportion / 100;

  const latlng = L.CRS.Simple.pointToLatLng(L.point(-limitedHeight, -limitedWidth), 0);

  return [[latlng.lng, 0], [0, latlng.lat]];
}

/**
 * Receives an image height and width
 * calculates min zoom that means that image fit 100% of block in this zoom ratio
 */
interface MapMinZoomValueProps {
  imageSize: {
    height: number;
    width: number;
  },
  containerSize: {
    height: number;
    width: number;
  },
}

export function getMapMinZoomValue(props: MapMinZoomValueProps) {
  const { imageSize, containerSize } = props;
  // get proportion of sides if imageSize > containerSize, 0 is the default lib value
  const heightRatio = imageSize.height > containerSize.height ?
    imageSize.height / containerSize.height : 0;
  const widthRatio = imageSize.width > containerSize.width ?
    imageSize.width / containerSize.width : 0;

  /**
   * reduced scale is achieved by negative values, so need to convert the number to negative
   * minZoom -1 means 2 times smaller map, -2 means 4 times, so need to divide by 2
   */

  return Math.max(heightRatio, widthRatio) / -2;
}
