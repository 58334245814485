import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { setVisitsData } from 'Admin/Store/visits';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useTypedSelector } from 'store/_legacy/Redux/store';

export function SuccessAppointmentsSnackbar() {
  const dispatch = useDispatch();
  const { successMessage } = useTypedSelector(state => state.adminVisits);

  const handleClose = () => {
    dispatch(setVisitsData({ successMessage: '' }));
  };

  useEffect(() => {
    return () => {
      dispatch(setVisitsData({ successMessage: '' }));
    };
  }, []);

  return successMessage ? (
    <Snackbar autoHideDuration={6000} onClose={handleClose} open>
      <Alert onClose={handleClose} severity="success">
        {successMessage}
      </Alert>
    </Snackbar>
  ) : null;
}
