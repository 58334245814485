import { GuardFunction } from './models';
import {
  Route,
  RouteProps,
  matchPath,
} from 'react-router-dom';

type ProtectedRouteProps = RouteProps & {
  guards: GuardFunction[];
  fallback: () => JSX.Element | null;
};

/**
 * Protect a route with guards.
 * 
 * Verifies if a Route passes all restrictions from guards array and returns
 * a fallback if it fails one guard or returns the Route if passes all of them.
 */
export const ProtectedRoute = ({
  fallback,
  guards,
  ...rest
}: ProtectedRouteProps) => {
  const {
    pathname,
  } = window.location;
  const matchResult = matchPath(pathname, rest);
  const hasMatchedRoute = !!matchResult;

  /**
   * Make sure the user is accessing the path it intends to access
   * before running any guard functions.
   * Match the route first before validating (running the guards)
   */
  if (hasMatchedRoute) {
    const guardArgs = rest;
    const canBeRendered = guards.every(guard => guard(guardArgs));

    if (guards.length && !canBeRendered) {
      return fallback();
    }
  }

  return (
    <Route
      {...rest}
    />
  );
};