import Box from 'components/_legacy/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import clsx from 'clsx';
import CreateNotificationForm from '../CreateNotificationForm';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import GlobalNotificationDeleteModal from '../GlobalNotificationDeleteModal';
import moment from 'moment';
import SendMessageIcon from 'components/_legacy/Icons/SendMessage';
import Skeleton from '@material-ui/lab/Skeleton';
import styles from './styles.module.scss';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Text from 'components/_legacy/Text';
import useSnackbar from 'components/_legacy/Snackbar/useSnackbar';
import { getGlobalNotifications, sendGlobalNotification, setGlobalNotificationsReduxData } from 'Admin/Store/globalNotifications';
import { GlobalNotificationModel, recipientsArray } from 'Admin/Store/globalNotifications/models';
import { Trans, t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTypedSelector } from 'store/_legacy/Redux/store';
import TableSubmenuButton from "components/_legacy/Buttons/TableSubmenuButton";

export default function GlobalNotificationsList() {
  const headCells = [
    { id: 'subject', label: t`Subject` },
    { id: 'message', label: t`Message` },
    { id: 'recipients', label: t`Recipients` },
    { id: 'whenToSend', label: t`When to send` },
    { id: 'dateCreated', label: t`Date created` },
    { id: 'status', label: t`Status` },
    { id: 'options', label: '' },
  ];

  const dispatch = useDispatch();
  const [open] = useSnackbar();

  const [createNotificationModalOpen, setCreateNotificationModalOpen] = useState<GlobalNotificationModel | null>(null);
  const [deleteNotificationModal, setDeleteNotificationModal] = useState<GlobalNotificationModel | null>(null);
  const [firstLoad, setFirstLoad] = useState(true);

  const { config, globalNotifications } = useTypedSelector(state => state);

  const { error, limit, loading, notifications, page, search, successSendMessage, totalPages } = globalNotifications;

  useEffect(() => {
    dispatch(getGlobalNotifications({ limit, page: firstLoad ? 0 : page, search }));

    if (firstLoad) {
      setFirstLoad(false);
    }
  }, [page, search]);

  useEffect(() => {
    if (error) {
      open({
        text: error,
        type: 'error',
      });
    }
  }, [error]);

  useEffect(() => {
    if (successSendMessage) {
      open({
        // We need to not return dispatch directly like: () => dispatch..., because this causes an error
        onClose: () => {
          dispatch(setGlobalNotificationsReduxData({ successSendMessage: '' }));
        },
        text: successSendMessage,
        type: 'success',
      });
    }
  }, [successSendMessage]);

  return (
    <>
      <Box
        className={styles.container}
        dataTestId="send-notification-list"
      >
        <TableContainer>
          <Table aria-label={t`Global notifications list`}>
            <TableHead classes={{ root: styles.tableHead }}>
              <TableRow classes={{ root: styles.tableRow }}>
                {headCells.map((headCell) => (
                  <TableCell classes={{ root: styles.tableCell }} key={headCell.id} padding="none">
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            {!loading && (
              <TableBody classes={{ root: styles.tableBody }}>
                {notifications.map((notification, index) => {
                  const labelId = notification.id;

                  const handleEditNotification = () => {
                    setCreateNotificationModalOpen(notification);
                  };

                  const handleSendNotification = () => {
                    dispatch(sendGlobalNotification({
                      notificationId: notification.id,
                      data: {
                        endDate: notification.endDate ?? '',
                        startDate: notification.startDate ?? '',
                        recipientType: notification.recipients,
                      },
                    }));
                  };

                  return (
                    <TableRow
                      aria-label={t`Global notification`}
                      className={`${notification.sendDate ? '' : styles.inactive}`}
                      classes={{ root: styles.tableRow }}
                      hover
                      key={`${notification.id} ${index}`}
                      role="row"
                      tabIndex={-1}
                    >
                      <TableCell classes={{ root: styles.tableCell }} component="th" id={labelId} padding="none" scope="row">
                        {notification.subject}
                      </TableCell>

                      <TableCell classes={{ root: styles.tableCell }}>
                        {notification.message}
                      </TableCell>

                      <TableCell classes={{ root: styles.tableCell }}>
                        {recipientsArray.find(r => r.value === notification.recipients)?.label ?? notification.recipients}
                      </TableCell>

                      <TableCell classes={{ root: styles.tableCell }}>
                        {moment(notification.sendDate).format('MMM DD, h:mma')}
                      </TableCell>

                      <TableCell classes={{ root: styles.tableCell }}>
                        {moment(notification.createdAt).format('MM/DD/YYYY')}
                      </TableCell>

                      <TableCell classes={{ root: styles.tableCell }}>
                        <Box
                          className={clsx(
                            styles.statusBadge,
                            notification.status === 'pending' && styles.pending,
                            notification.status === 'sent' && styles.sent,
                          )}
                        >
                          {notification.status}
                        </Box>
                      </TableCell>

                      <TableCell align="right" classes={{ root: styles.tableCell }}>
                        <TableSubmenuButton
                          iconButtonStyle={{ padding: 0, width: '30px', height: '30px' }}
                        >
                          <Box
                            borderStyle="shadow"
                            className="popover-content"
                            dataTestId="global-notification-item-options-popup"
                          >
                            <ButtonBase
                              className="popover-item-button"
                              onClick={() => handleSendNotification()}
                            >
                              <SendMessageIcon color={config.theme.primary} size={20} />

                              <Box marginStart={5}>
                                <Text color="blue" size="md">
                                  {notification.status === 'pending' ? t`Send Now` : t`Resend Now`}
                                </Text>
                              </Box>
                            </ButtonBase>

                            <ButtonBase
                              className="popover-item-button"
                              onClick={() => handleEditNotification()}
                            >
                              <CreateOutlinedIcon style={{ color: config.theme.primary, fontSize: 20 }} />

                              <Box marginStart={5}>
                                <Text color="blue" size="md">
                                  <Trans>
                                    Edit Notification
                                  </Trans>
                                </Text>
                              </Box>
                            </ButtonBase>

                            {notification.status === 'pending' &&
                              <ButtonBase
                                className="popover-item-button"
                                onClick={() => setDeleteNotificationModal(notification)}
                              >
                                <DeleteOutlineIcon style={{ color: config.theme.primary, fontSize: 20 }} />

                                <Box marginStart={5}>
                                  <Text color="blue" size="md">
                                    <Trans>
                                      Delete Notification
                                    </Trans>
                                  </Text>
                                </Box>
                              </ButtonBase>
                            }
                          </Box>
                        </TableSubmenuButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>

          {loading ? (
            <Box height={509}>
              <Skeleton height="100%" variant="rect" />
            </Box>
          ) : (
            !notifications.length && (
              <Box marginTop={100}>
                <Text align="center" color="lightGray" size="md">
                  <Trans>
                    {`There're no global notifications`}
                  </Trans>
                </Text>
              </Box>
            )
          )}

          <Box display="flex" justifyContent="end">
            <TablePagination
              aria-label={t`Global notifications pagination`}
              classes={{ root: styles.tablePagination }}
              colSpan={3}
              count={totalPages * limit}
              onPageChange={(_: any, newPage: number) => dispatch(setGlobalNotificationsReduxData({ page: newPage }))}
              page={page}
              role="navigation"
              rowsPerPage={10}
              rowsPerPageOptions={[]}
            />
          </Box>
        </TableContainer>
      </Box>

      <CreateNotificationForm
        notification={createNotificationModalOpen}
        onCancelAction={() => setCreateNotificationModalOpen(null)}
        open={Boolean(createNotificationModalOpen)}
      />

      <GlobalNotificationDeleteModal
        closeModal={() => setDeleteNotificationModal(null)}
        notification={deleteNotificationModal}
        open={Boolean(deleteNotificationModal)}
      />
    </>
  );
}
