import Box from 'components/_legacy/Box';
import Button from 'components/_legacy/Button';
import CloseIcon from '@material-ui/icons/Close';
import CustomPagination from 'components/_legacy/CustomPagination';
import Dialog from '@material-ui/core/Dialog';
import format from 'date-fns/format';
import Heading from 'components/_legacy/Heading';
import IconButton from '@material-ui/core/IconButton';
import Select from 'components/_legacy/Select';
import styles from './styles.module.scss';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Text from 'components/_legacy/Text';
import useSnackbar from 'components/_legacy/Snackbar/useSnackbar';
import { approveOrDenyUserDocument, setUserManagementData } from 'Admin/Store/userManagement';
import { getStatusConfig } from '../UserDocumentDisplay';
import { Trans, t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { UserDocumentType, UserManagementUser } from 'Admin/Store/userManagement/models';
import { useTypedSelector } from 'store/_legacy/Redux/store';
import { getCertificatesByUser, updateCovidCertificate } from "../../../../../../App/Store/CovidSertificate";
import UserDocumentDateRangeModal from "../UserDocumentDateRangeModal";
import UserAccommodation from "../UserAccommodation";
import moment from "moment";
import { Skeleton } from "@material-ui/lab";
import {
  ErrorUploadCovidFileSnackbar,
  SuccessUploadCovidFileSnackbar,
} from "components/_legacy/UploadCovidFile/SnackBars";

interface Props {
  open: boolean;
  toggleOpen(): any;
  user: UserManagementUser;
}

export default function UserDocumentsModal({
  open,
  toggleOpen,
  user,
}: Props) {
  const dispatch = useDispatch();
  const [openSnackbar] = useSnackbar();
  const { config, adminUserManagement, covidCertificate } = useTypedSelector(state => state);

  const [activeDocuments, setActiveDocuments] = useState<Record<string, boolean>>({});
  const [componentLoaded, setComponentLoaded] = useState(false);
  const [documentSelectOpen, setDocumentSelectOpen] = useState(false);
  const { totalUserDocumentsPages, userDocuments, loading } = covidCertificate;

  const headCells = [
    { id: 'status', label: t`Status` },
    { id: 'date', label: t`Vax/Test Date` },
    { id: 'expiry-date', label: t`Expiry Date` },
    { id: 'type', label: t`Type` },
    { id: 'verified', label: t`Verified` },
    { id: 'uploaded-date', label: t`Uploaded date` },
    { id: 'verified-date', label: t`Verified date` },
    { id: 'options', label: '' },
  ];

  const userDocumentTypesArray: Array<{ name: string; value: UserDocumentType | '' }> = [
    {
      name: t`Not selected`,
      value: '',
    },
    {
      name: t`Vaccine record`,
      value: 'vaccineRecord',
    },
    {
      name: t`Negative Covid Test Result`,
      value: 'negativeCovidTestResult',
    },
  ];

  const onChangePage = (page: number) => {
    dispatch(getCertificatesByUser({ userId: user.id, page }));
  };

  useEffect(() => {
    if (open) {
      onChangePage(1);
    }

  }, [open]);

  useEffect(() => {
    if (componentLoaded) {
      if (adminUserManagement.error) {
        openSnackbar({
          onClose: () => { dispatch(setUserManagementData({ error: '' })); },
          text: adminUserManagement.error,
          type: 'error',
        });
      } else if (adminUserManagement.successMessage) {
        openSnackbar({
          onClose: () => { dispatch(setUserManagementData({ successMessage: '' })); },
          text: adminUserManagement.successMessage,
          type: 'success',
        });
      }
    }

    setComponentLoaded(true);
  }, [adminUserManagement.error, adminUserManagement.successMessage]);

  return (
    <>
      <SuccessUploadCovidFileSnackbar />
      <ErrorUploadCovidFileSnackbar />

      <Dialog
        aria-labelledby="User management documents modal"
        className={styles.dialogContainer}
        classes={{ paper: styles.dialog }}
        fullWidth
        maxWidth="lg"
        onClose={(event, reason) => {
          if (reason !== 'backdropClick' || !documentSelectOpen) {
            toggleOpen();
          }
        }}
        open={open}
      >
        <Box>
          <Box alignItems="center" display="flex" justifyContent="between">
            <Heading size="sm">
              <Box alignItems="center" display="flex">
                <Box alignItems="center" display="flex">
                  <Trans>Documents</Trans> ({user.name})
                </Box>
                <Box alignItems="center" className={styles.accommodationSwitcher} display="flex">
                  <UserAccommodation
                    approvedAccommodation={user.approvedAccommodation}
                    labelText="Approved Accommodation"
                    userId={user.id}
                  />
                </Box>
              </Box>
            </Heading>

            <IconButton
              onClick={toggleOpen}
              size="small"
              style={{
                backgroundColor: config.theme.primaryLight,
                borderRadius: 8,
                height: 30,
                width: 30,
              }}
            >
              <CloseIcon style={{ color: config.theme.primary, fontSize: 21 }} />
            </IconButton>
          </Box>

          <Box
            className={styles.container}
            dataTestId="user-documents"
            marginTop={13}
          >
            <TableContainer style={{ minHeight: 200 }}>
              <Table aria-label={t`parking spots listed by location`}>
                <TableHead classes={{ root: styles.tableHead }}>
                  <TableRow classes={{ root: styles.tableRow }}>
                    {headCells.map((headCell) => (
                      <TableCell classes={{ root: styles.tableCell }} key={headCell.id} padding="none">
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                {!loading &&
                  <TableBody classes={{ root: styles.tableBody }}>
                    {userDocuments
                      .map((userDocument, index) => {
                        const statusConfig = getStatusConfig(userDocument.status);

                        const onChangeType = (newType: UserDocumentType) => {
                          dispatch(updateCovidCertificate({
                            params: {
                              documentId: userDocument.id,
                            },
                            body: {
                              type: newType,
                              vaccinationDate: userDocument.vaccinationDate,
                              vaccinationExpirationDate: userDocument.vaccinationExpirationDate,
                            },
                          }));
                        };

                        const onApprove = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => {
                          event?.stopPropagation();
                          const expirationTime = new Date(userDocument.vaccinationExpirationDate).getTime();
                          const todayTime = new Date(moment().utc().startOf('day').format()).getTime();
                          const isDateExpire = expirationTime < todayTime;

                          if (isDateExpire) {
                            return dispatch(setUserManagementData({
                              error: t`Unfortunately, the expire date is in the past please check the information.`,
                            }));
                          }

                          return dispatch(approveOrDenyUserDocument({
                            params: {
                              documentId: userDocument.id,
                              userId: user.id,
                            },
                            queryParams: {
                              approve: true,
                            },
                          }));
                        };

                        const onDeny = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => {
                          event?.stopPropagation();

                          dispatch(approveOrDenyUserDocument({
                            params: {
                              documentId: userDocument.id,
                              userId: user.id,
                            },
                            queryParams: {
                              approve: false,
                            },
                          }));
                        };

                        return (
                          <>
                            <TableRow
                              aria-label={t`User Document Item`}
                              classes={{ root: styles.tableRow }}
                              hover
                              id={userDocument.id}
                              key={`${userDocument.id} ${index}`}
                              onClick={() => {
                                setActiveDocuments({
                                  ...activeDocuments,
                                  [userDocument.id]: !activeDocuments[userDocument.id],
                                });
                              }}
                              role="row"
                              tabIndex={-1}
                            >
                              <TableCell classes={{ root: styles.tableCell }} component="th" id={userDocument.id} padding="none" scope="row">
                                {statusConfig ? (
                                  <Box alignItems='center' display='flex' gap={8}>
                                    {statusConfig.icon}

                                    <Text overflow='noWrap' size="md">
                                      {statusConfig.label}
                                    </Text>
                                  </Box>
                                ) : '-'}
                              </TableCell>

                              <TableCell classes={{ root: styles.tableCell }}>
                                <UserDocumentDateRangeModal type='vax' userDocument={userDocument} />
                              </TableCell>

                              <TableCell classes={{ root: styles.tableCell }}>
                                <UserDocumentDateRangeModal type='expiry' userDocument={userDocument} />
                              </TableCell>

                              <TableCell classes={{ root: styles.tableCell }}>
                                <Select
                                  classes={{
                                    wrapper: styles.typeSelectWrapper,
                                  }}
                                  displayEmpty
                                  iconColor="#000000"
                                  id="status"
                                  items={userDocument.type ? userDocumentTypesArray.filter(item => item.value) : userDocumentTypesArray}
                                  labelColor="gray"
                                  onChange={event => {
                                    event.stopPropagation();
                                    onChangeType(event.target.value as UserDocumentType);
                                  }}
                                  onClick={event => {
                                    event.stopPropagation();
                                  }}
                                  onClose={event => {
                                    event.stopPropagation();
                                    setDocumentSelectOpen(false);
                                  }}
                                  onOpen={() => setDocumentSelectOpen(true)}
                                  value={userDocument.type}
                                />
                              </TableCell>

                              <TableCell align="right" classes={{ root: styles.tableCell }}>
                                <Text color={userDocument.approver ? 'black' : 'gray'} overflow='noWrap' size="md">
                                  {userDocument.approver ?? t`Pending approval`}
                                </Text>
                              </TableCell>

                              <TableCell align="right" classes={{ root: styles.tableCell }}>
                                <Text overflow='noWrap' size="md">
                                  {userDocument.updatedAt ? format(new Date(userDocument.updatedAt), 'MM/dd/yyyy') : ''}
                                </Text>
                              </TableCell>

                              <TableCell align="right" classes={{ root: styles.tableCell }}>
                                <Text overflow='noWrap' size="md">
                                  {userDocument.verificationDate ? format(new Date(userDocument.verificationDate), 'MM/dd/yyyy') : '-'}
                                </Text>
                              </TableCell>

                              <TableCell align="right" classes={{ root: styles.tableCell }}>
                                <Box
                                  alignItems='center'
                                  display='flex'
                                  gap={20}
                                  minWidth={130}
                                >
                                  {activeDocuments[userDocument.id] &&
                                    <>
                                      <Button
                                        noPaddingX
                                        onClick={onDeny}
                                        type="clear"
                                      >
                                        Deny
                                      </Button>

                                      <Button
                                        noPaddingX
                                        onClick={onApprove}
                                        type="clear"
                                      >
                                        Approve
                                      </Button>
                                    </>
                                  }
                                </Box>
                              </TableCell>
                            </TableRow>

                            {activeDocuments[userDocument.id] &&
                              <TableRow>
                                <TableCell colSpan={7}>
                                  <img
                                    alt={`Document ${userDocument.type}`}
                                    src={userDocument.documentUrl}
                                    style={{ maxWidth: '100%' }}
                                  />
                                </TableCell>
                              </TableRow>
                            }
                          </>
                        );
                      })}
                  </TableBody>
                }
              </Table>
              {loading ? (
                <Box height={200}>
                  <Skeleton height="100%" variant="rect" />
                </Box>
              ) : (
                !userDocuments.length && (
                  <Box marginTop={50}>
                    <Text align="center" color="lightGray" size="md">
                      <Trans>No documents found</Trans>
                    </Text>
                  </Box>
                )
              )}
            </TableContainer>

            <Box padding="50px 0">
              <CustomPagination
                count={totalUserDocumentsPages}
                onChange={onChangePage}
              />
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}
