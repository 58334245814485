import React, { useCallback, useEffect, useState } from "react";
import { EditorElementType } from "./enums";
import { Box, Button, ButtonProps, CircularProgress, TextField, TextFieldProps, Tooltip, Typography, TypographyProps, useTheme } from "@mui/material";
import { EditorElementProps } from "./types";
import { t } from "@lingui/macro";
import { CloseRounded, CodeRounded, DeleteOutlined, EditRounded, InsertPhotoOutlined, VisibilityRounded } from "@mui/icons-material";
import { ReactEditor, useSlate } from "slate-react";
import { FileUploadButton } from "../button";
import { useCreateAttachmentMutation } from "store";
import { useToast } from "../toast-provider";
import { IFrameBox } from "../box";
import sanitizeHTML from "sanitize-html";
import { sanitizeEditorHTML } from "./utils";
import { AttachmentImage } from "components/attachments";

const resolveHeadingVariant = (level?: number): TypographyProps["variant"] | undefined => {
  const variants: TypographyProps["variant"][] = ["h1", "h2", "h3", "h4", "h5", "h6"];

  return level ? variants[level - 1] : undefined;
};

const ImageElement: React.FC<EditorElementProps> = (props) => {
  const { attributes, element, children } = props;
  const { palette } = useTheme();
  const toast = useToast();
  const editor = useSlate();
  const [createAttachment, { isLoading: isUploading }] = useCreateAttachmentMutation();

  const uploadImage = useCallback(async (file: File) => {
    const response = await createAttachment(file);

    if ("error" in response) {
      toast.showToast({ severity: "error", message: t`Failed to upload image, please try again.` });
    } else {
      const { id } = response?.data || {};

      editor.setNodes({ id, type: EditorElementType.IMAGE, file: undefined }, { at: ReactEditor.findPath(editor, element) });
    }
  }, [element, createAttachment, toast, editor]);

  useEffect(() => {
    if (element.type !== EditorElementType.IMAGE) {
      return;
    }

    const { id, file } = element;

    if (!id && file) {
      void uploadImage(file);
    }
  }, [element, uploadImage]);

  if (element.type !== EditorElementType.IMAGE) {
    return <></>;
  }

  const handleDeleteClick: ButtonProps["onClick"] = (event) => {
    event.preventDefault();

    editor.removeNodes({ at: ReactEditor.findPath(editor, element) });
  };

  const buttons = isUploading ? (
    <Box alignItems="center" bgcolor={palette.grey[100]} borderRadius={2} display="flex" height={64} justifyContent="center" width={64}>
      <CircularProgress />
    </Box>
  ) : (
    <>
      <FileUploadButton
        disableElevation
        sx={{ p: 0, width: 64, height: 64, bgcolor: palette.grey[300], "&:hover": { bgcolor: palette.grey[400] } }}
        title={t`Set image`}
        uploadOptions={{ accept: ["image/png", "image/jpeg", "image/webp"], onChange: ([file]) => void uploadImage(file) }}
        variant="contained"
      >
        <InsertPhotoOutlined fontSize="large" sx={{ color: palette.grey[700] }} />
      </FileUploadButton>
      <Button
        disableElevation
        onClick={handleDeleteClick}
        sx={{ p: 0, width: 64, height: 64, bgcolor: palette.grey[300], "&:hover": { bgcolor: palette.grey[400] } }}
        title={t`Remove image`}
        variant="contained"
      >
        <DeleteOutlined fontSize="large" sx={{ color: palette.grey[700] }} />
      </Button>
    </>
  );

  return element.id ? (
    <Box contentEditable={false} mb={1} position="relative" {...attributes}> 
      <AttachmentImage attachmentId={element.id} borderRadius={2} display="block" height="auto" width="100%" />
      <Box
        alignItems="center"
        bgcolor="rgba(0,0,0,0.5)"
        borderRadius={2}
        bottom={0}
        display="flex"
        gap={2}
        justifyContent="center"
        left={0}
        position="absolute"
        right={0}
        sx={!isUploading ? { opacity: 0, transition: "opacity 0.2s", ":hover": { opacity: 1 } } : undefined}
        top={0}
      >
        {buttons}
      </Box>
      {children}
    </Box>
  ) : (
    <Box
      alignItems="center"
      bgcolor={palette.grey[200]}
      borderRadius={2}
      contentEditable={false}
      display="flex"
      gap={2}
      height={180}
      justifyContent="center"
      mb={1}
      {...attributes}
    > 
      {buttons}
      {children}
    </Box>
  );
};

const HTMLElement: React.FC<EditorElementProps> = (props) => {
  const { attributes, element, children } = props;
  const { palette } = useTheme();
  const editor = useSlate();
  const [preview, togglePreview] = useState(false);

  useEffect(() => {
    if (element.type !== EditorElementType.HTML) {
      return;
    }

    if (preview) {
      editor.setNodes({ type: EditorElementType.HTML, body: sanitizeEditorHTML(element.body) }, { at: ReactEditor.findPath(editor, element) });
    }
  }, [preview, editor, element]);

  if (element.type !== EditorElementType.HTML) {
    return <></>;
  }

  const handleDeleteClick = () => {
    editor.removeNodes({ at: ReactEditor.findPath(editor, element) });
  };

  const handleTextFieldChange: TextFieldProps["onChange"] = (event) => {
    editor.setNodes({ type: EditorElementType.HTML, body: event.target.value }, { at: ReactEditor.findPath(editor, element) });
  };

  const { body } = element;

  return (
    <Box bgcolor={palette.grey[200]} borderRadius={2} contentEditable={false} mb={1} p={1} {...attributes}>
      <Box display="flex" justifyContent="space-between" mb={0.5}>
        <Box alignItems="center" display="flex">
          <CodeRounded fontSize="small" sx={{ color: palette.grey[700] }} />
          <Typography color={palette.grey[700]} fontSize={14} ml={0.5}>html</Typography>
        </Box>
        <Box display="flex" gap={1}>
          <Button
            onClick={() => togglePreview(!preview)}
            size="small"
            startIcon={preview ? <EditRounded /> : <VisibilityRounded />}
          >
            {preview ? t`Edit` : t`Preview`}
          </Button>
          <Button
            disableElevation
            onClick={() => handleDeleteClick()}
            size="small"
            sx={{ bgcolor: "transparent", minWidth: 0, p: 0.5, ":hover": { bgcolor: palette.grey[300] } }}
            variant="contained"
          >
            <CloseRounded fontSize="small" sx={{ color: palette.grey[700] }} />
          </Button>
        </Box>
      </Box>
      {preview ? (
        <IFrameBox autoHeight srcDoc={body} width="100%" /> 
      ) : (
        <TextField
          defaultValue={body}
          multiline
          onChange={handleTextFieldChange}
          spellCheck={false}
          sx={{ bgcolor: "#fff", borderRadius: 2, "& .MuiInputBase-input": { fontFamily: "monospace", fontSize: 14 } }}
        />
      )}
      {children}
    </Box>
  );
};

export const EditorElement: React.FC<EditorElementProps> = (props) => {
  const { attributes, element, children } = props;
  const { palette } = useTheme();

  switch (element.type) {
    case EditorElementType.HEADING: {
      return <Typography mb={2} textAlign={element.alignment} variant={resolveHeadingVariant(element.level)} {...attributes}>{children}</Typography>;
    }
    case EditorElementType.PARAGRAPH: {
      return (
        <Typography color={palette.grey[700]} component="p" fontSize={14} mb={1} textAlign={element.alignment} {...attributes}>
          {children}
        </Typography>
      );
    }
    case EditorElementType.LIST: {
      return <Box component={element.ordering === "ordered" ? "ol" : "ul"} {...attributes}>{children}</Box>;
    }
    case EditorElementType.LIST_ITEM: {
      return <Typography color={palette.grey[700]} component="li" fontSize={14}>{children}</Typography>;
    }
    case EditorElementType.ANCHOR: {
      return (
        <Tooltip arrow title={<a href={element.href} rel="noreferrer" target="_blank">{t`Open link`}</a>}>
          <Typography color="primary" display="inline-block" {...attributes}>
            {children}
          </Typography>
        </Tooltip>
      );
    }
    case EditorElementType.IMAGE: {
      return <ImageElement {...props} />;
    }
    case EditorElementType.HTML: {
      return <HTMLElement {...props} />;
    }
    case EditorElementType.BOX: {
      return <Box display="flex" flexWrap="nowrap" gap={2} {...attributes}>{children}</Box>;
    }
    case EditorElementType.BOX_CELL: {
      return <Box flex={1} {...attributes}>{children}</Box>;
    }
    default: {
      return <></>;
    }
  }
};
