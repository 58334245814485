import {
  GetSettingsMobileDataResponse,
  SetSettingsMobileDataRequest,
  SettingsMobileVersion,
  UpdateSettingsMobileVersionRequest,
} from "./models";
import { ActionPayload, BaseErrorResponse, BaseResponse } from "store/_legacy/Models/ReduxModels";
import {t} from "@lingui/macro";

const SET_SETTINGS_MOBILE_DATA = 'SET_SETTINGS_MOBILE_DATA';

export const GET_SETTINGS_MOBILE_DATA = 'GET_SETTINGS_MOBILE_DATA';
export const GET_SETTINGS_MOBILE_DATA_SUCCESS = 'GET_SETTINGS_MOBILE_DATA_SUCCESS';
export const GET_SETTINGS_MOBILE_DATA_FAIL = 'GET_SETTINGS_MOBILE_DATA_FAIL';

export const UPDATE_SETTINGS_MOBILE_DATA = 'UPDATE_SETTINGS_MOBILE_DATA';
export const UPDATE_SETTINGS_MOBILE_DATA_SUCCESS = 'UPDATE_SETTINGS_MOBILE_DATA_SUCCESS';
export const UPDATE_SETTINGS_MOBILE_DATA_FAIL = 'UPDATE_SETTINGS_MOBILE_DATA_FAIL';

interface SetSettingsMobileData {
  type: typeof SET_SETTINGS_MOBILE_DATA;
  payload: SetSettingsMobileDataRequest;
}

// get list of all versions
export interface GetSettingsMobileData {
  type: typeof GET_SETTINGS_MOBILE_DATA;
  payload: ActionPayload<null>
}

interface GetSettingsMobileDataSuccess {
  type: typeof GET_SETTINGS_MOBILE_DATA_SUCCESS;
  payload: BaseResponse<GetSettingsMobileDataResponse>
}

interface GetSettingsMobileDataFail {
  type: typeof GET_SETTINGS_MOBILE_DATA_FAIL;
  payload: BaseErrorResponse;
}

// update version
export interface UpdateSettingsMobileData {
  type: typeof UPDATE_SETTINGS_MOBILE_DATA;
  payload: ActionPayload<UpdateSettingsMobileVersionRequest[]>;
}

interface UpdateSettingsMobileDataSuccess {
  type: typeof UPDATE_SETTINGS_MOBILE_DATA_SUCCESS;
  payload: BaseResponse<GetSettingsMobileDataResponse>;
}

interface UpdateSettingsMobileDataFail {
  type: typeof UPDATE_SETTINGS_MOBILE_DATA_FAIL;
  payload: BaseErrorResponse;
}

type Actions =
  | SetSettingsMobileData
  | GetSettingsMobileData
  | GetSettingsMobileDataSuccess
  | GetSettingsMobileDataFail
  | UpdateSettingsMobileData
  | UpdateSettingsMobileDataSuccess
  | UpdateSettingsMobileDataFail

export interface State {
  error: string;
  successMessage: string;
  loading: boolean;
  versions: SettingsMobileVersion[];
}

const initialState: State = {
  error: '',
  successMessage: '',
  loading: false,
  versions: [
    { id: '', type: 'ios', version: 0 },
    { id: '', type: 'android', version: 0 },
  ],
};

export default function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case SET_SETTINGS_MOBILE_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case GET_SETTINGS_MOBILE_DATA:
      return {
        ...state,
        loading: true,
      };
    case GET_SETTINGS_MOBILE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        versions: action.payload.data.result.data,
      };
    case GET_SETTINGS_MOBILE_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: t`There was an error getting mobile versions. Please try again.`,
      };

    case UPDATE_SETTINGS_MOBILE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        versions: action.payload.data.result.data,
        successMessage: t`Mobile version settings was updated`,
      };
    case UPDATE_SETTINGS_MOBILE_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: t`There was an error updating mobile versions. Please try again.`,
      };

    default:
      return state;
  }
}

// actions
export function setSettingsMobileData(data: SetSettingsMobileDataRequest): SetSettingsMobileData {
  return {
    type: SET_SETTINGS_MOBILE_DATA,
    payload: data,
  };
}

export function getSettingsMobileData(): GetSettingsMobileData {
  return {
    type: GET_SETTINGS_MOBILE_DATA,
    payload: {
      request: {
        method: 'GET',
        url: 'api/settings/mobile/version',
      },
    },
  };
}

export function updateSettingsMobileData(data: UpdateSettingsMobileVersionRequest[]): UpdateSettingsMobileData {
  return {
    type: UPDATE_SETTINGS_MOBILE_DATA,
    payload: {
      request: {
        method: 'PUT',
        url: 'api/settings/mobile/version',
        data,
      },
    },
  };
}
