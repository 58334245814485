import Box from 'components/_legacy/Box';
import SelectTimeSlot from '../SelectTimeSlot';
import { compensateTimezoneOffset } from 'Admin/Pages/Calendar/AdminCalendar/Helpers/Helpers';
import { DateRange, Range, RangeKeyDict } from 'react-date-range';
import {
  getClosestAvailableDay,
  getClosestStartDate,
  getStartDisabledDaysArray,
} from 'App/Functions/Helpers';
import { setCreateNewVisitData } from 'App/Store/Bookings/createNewVisitDuck';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTypedSelector } from 'store/_legacy/Redux/store';
import { setLocationsData } from "../../../../Store/Locations/locationsDuck";

export default function VisitCustom() {
  const dispatch = useDispatch();
  const { config, createNewVisit, locations: locationsDuck } = useTypedSelector(state => state);
  const { locationId } = createNewVisit;
  const selectedLocation = locationsDuck.locations.find(location => location.id === locationId);
  const locationDisabledDays = locationsDuck.locationDisabledDays.map(d => compensateTimezoneOffset(d));
  // add today to disabled days as default
  const startDisabledDaysArray = selectedLocation ? getStartDisabledDaysArray(selectedLocation?.allowedBookingDayType) : [];
  const disabledDays = startDisabledDaysArray.length ? [...startDisabledDaysArray, ...locationDisabledDays] : [...locationDisabledDays];
  const { dateFrom: reduxDateFrom, dateTo: reduxDateTo } = createNewVisit;

  // const [hours, setHours] = useState([timeFromInNumber, timeToInNumber]);
  const [selectionRange, setSelectionRange] = useState<Range>({
    startDate: undefined,
    endDate: undefined,
    key: 'selection',
  });

  const handleSelect = (ranges: RangeKeyDict) => {
    dispatch(setCreateNewVisitData({
      dateFrom: ranges.selection.startDate,
      dateTo: ranges.selection.endDate,
    }));
  };

  // set selection range on init load after all data is ready
  useEffect(() => {
    const closestStartDay = selectedLocation ? getClosestStartDate(selectedLocation?.allowedBookingDayType) : new Date();

    if (locationsDuck.locationDisabledDaysLoaded) {
      dispatch(setCreateNewVisitData({
        dateFrom: getClosestAvailableDay(closestStartDay, locationsDuck.locationDisabledDays),
        dateTo: getClosestAvailableDay(closestStartDay, locationsDuck.locationDisabledDays),
      }));
      dispatch(setLocationsData({ locationDisabledDaysLoaded: false }));
    }
  }, [locationsDuck.locationDisabledDaysLoaded]);

  // Updates selection range when redux data changes
  useEffect(() => {
    // update start/end date here
    const dateFrom = getClosestAvailableDay(reduxDateFrom, locationsDuck.locationDisabledDays);
    const dateTo = getClosestAvailableDay(reduxDateTo, locationsDuck.locationDisabledDays);

    setSelectionRange({
      startDate: dateFrom,
      endDate: dateTo,
      key: 'selection',
    });
  }, [reduxDateFrom, reduxDateTo, locationsDuck.locationDisabledDays]);

  return (
    <Box>
      <DateRange
        className="custom-calendar"
        disabledDates={disabledDays}
        locale={config.calendarTranslations}
        minDate={new Date()}
        onChange={handleSelect}
        rangeColors={[config.theme.primaryLight]}
        ranges={[selectionRange]}
        showDateDisplay={false}
        showMonthAndYearPickers={false}
      />

      <Box marginBottom={14} marginTop={10}>
        <SelectTimeSlot />
      </Box>
    </Box>
  );
}
