import createSagaMiddleware from 'redux-saga';
import reducers from './index';
import storage from 'redux-persist/lib/storage';
import { PersistConfig, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { ReduxStore } from '.';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import {
  Action,
  Store,
  combineReducers,
} from 'redux';
import mySaga from 'store/_legacy/Sagas';
import { configureStore } from "@reduxjs/toolkit";
import { removeMeetingReservationPastDates } from 'App/Store/Meetings/createNewMeetingDuck/transformers';
import { commApi, mediaApi, togetherApi } from 'store';

const REDUX_KEY = 'ipg-13';

const persistConfig: PersistConfig<ReduxStore> = {
  key: REDUX_KEY,
  storage,
  stateReconciler: autoMergeLevel2, // this setting means merge two-levels deep // https://blog.reactnativecoach.com/the-definitive-guide-to-redux-persist-84738167975
  transforms: [removeMeetingReservationPastDates],
  blacklist: [
    togetherApi.reducerPath,
    commApi.reducerPath,
    mediaApi.reducerPath,
  ],
};

const rootReducer = (state: ReduxStore | undefined, action: Action<any>) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'LOGOUT') {
    storage.removeItem(`persist:${REDUX_KEY}`);
    state = undefined;
  }

  return combineReducers(reducers)(state, action);
};

export const sagaMiddleware = createSagaMiddleware();

export const useTypedSelector: TypedUseSelectorHook<ReduxStore> = useSelector;

const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    })
    .concat(sagaMiddleware)
    .concat(togetherApi.middleware)
    .concat(mediaApi.middleware)
    .concat(commApi.middleware),
});

/**
 * This store is used in tests.
 * We don't initialize persist because we don't want to persist our state between tests.
 * We have to initialize the saga everytime or the store will not have the sagas.
 */
export const createStoreWithoutPersist = (): Store<ReduxStore> => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => 
      getDefaultMiddleware({
        serializableCheck: false,
      })
      .concat(sagaMiddleware)
      .concat(togetherApi.middleware)
      .concat(mediaApi.middleware)
      .concat(commApi.middleware),
  });

  sagaMiddleware.run(mySaga);

  return (store as unknown as Store<ReduxStore>);
};

export default store;
