import { PaginationQueryParams } from "../../types";
import { parseArrayQueryParam, parseObjectQueryParam } from "../../utils";
import { togetherApi } from "../together-api";
import { APIResponseBody, Floor, GetFloorByIdInput, GetFloorsByLocationIdInput, PaginatedAPIResponseBody, UpdateLocationFloorsInput } from "../types";
import { parsePaginationQueryParams } from "../../utils";

const floors = togetherApi.injectEndpoints({
  endpoints: (builder) => ({
    getFloorsByLocationId: builder.query<PaginatedAPIResponseBody<Floor>, GetFloorsByLocationIdInput>({
      query: ({ locationId, limit, include, page, custom }) => ({
        url: `/api/v2/locations/${locationId}/floors`,
        params: { 
          limit,
          page,
          ...parseArrayQueryParam("include", include),
          ...parseObjectQueryParam("custom", custom),
        },
      }),
    }),
    getFloorById: builder.query<APIResponseBody<Floor>, GetFloorByIdInput>({
      query: ({ locationId, floorId, include }) => ({
        url: `/api/locations/${locationId}/floors/${floorId}`,
        params: {
          ...parseArrayQueryParam("include", include),
        },
      }),
    }),
    updateLocationFloors: builder.mutation<APIResponseBody<void>, UpdateLocationFloorsInput>({
      query: ({ locationId, floors }) => ({
        url: `/api/locations/${locationId}/floors`,
        method: "PATCH",
        body: { entities: floors },
      }),
    }),
    getFloors: builder.query<PaginatedAPIResponseBody<Floor>, PaginationQueryParams>({
      query: (params) => ({
        url: "/api/floors",
        params: parsePaginationQueryParams(params),
      }),
    }),
  }),
});

export const {
  useGetFloorByIdQuery,
  useLazyGetFloorByIdQuery,
  useGetFloorsByLocationIdQuery,
  useLazyGetFloorsByLocationIdQuery,
  useUpdateLocationFloorsMutation,
  useGetFloorsQuery,
  useLazyGetFloorsQuery,
} = floors;
