import axios, { AxiosRequestConfig } from 'axios';
import qs from 'qs';
import store from 'store/_legacy/Redux/store';
import { Action } from 'store/_legacy/Models/ReduxModels';

async function Api(action: Action<any>, noAuth?: boolean, useGraphApi?: boolean) {
  const state = store.getState();
  const { data, isUploadFile, method, url } = action.payload.request;
  const client = axios.create({ baseURL: process.env.REACT_APP_API_URL, responseType: isUploadFile ? "blob" : "json" });

  client.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      const { response } = error;

      return Promise.reject(response?.data || response || error);
    },
  );

  let config: AxiosRequestConfig = {};

  // If it needs authorization, include bearer token to headers
  if (!noAuth) {
    const { token, tokenGraphApi } = state.login;
    const headers = {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    if (useGraphApi) {
      // @ts-ignore ts not corresponding this prop with axios, its correct
      headers['graph-token'] = tokenGraphApi;
    }

    config = {
      headers,
    };
  }

  if (method === 'POST') {
    return client.post(url, data, config);
  } else if (method === 'PUT') {
    return client.put(url, data, config);
  } else if (method === 'PATCH') {
    return client.patch(url, data, config);
  } else if (method === 'DELETE') {
    let deleteConfig = config;
    // set data to config due to axios method interface (do not support data param as POST)
    if (data) {
      deleteConfig = {
        ...config,
        data,
      };
    }
    return client.delete(url, deleteConfig);
  } else {
    return client.get(url, {
      params: {
        ...data,
      },
      paramsSerializer: params => qs.stringify(params),
      ...config,
    });
  }
}

export default Api;
