import { t } from "@lingui/macro";
import { Box, Button, IconButton, Skeleton, Tooltip, Typography, useTheme } from "@mui/material";
import { Tab, Tabs } from "../tabs";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { ArrowBackRounded } from "@mui/icons-material";
import { CreatePostInput, Post, PostScheduleType, PostStatus, UpdatePostByIdInput, adminSlice, selectAdminCurrentCampaign, useCreatePostMutation, useCreatePostTemplateMutation, useGetMeQuery, useGetPostByIdQuery, useUpdatePostByIdMutation } from "store";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "components/toast-provider";
import { isUUID } from "class-validator";

export const CampaignWrapper: React.FC<React.PropsWithChildren<{}>> = (props) => {
  const { children } = props;
  const dispatch = useDispatch();
  const { background } = useTheme();
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { campaignId } = useParams<{ campaignId?: string }>();
  const toast = useToast();
  const currentCampaign = useSelector(selectAdminCurrentCampaign);
  const [isSaving, setIsSaving] = useState(false);
  const [isLaunching, setIsLaunching] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [submitTooltip, setSubmitTooltip] = useState("");
  const [templateId, setTemplateId] = useState<string>();
  const [createPost] = useCreatePostMutation();
  const [updatePostById] = useUpdatePostByIdMutation();
  const [createPostTemplate] = useCreatePostTemplateMutation();
  const getMeQuery = useGetMeQuery();
  const getPostByIdQuery = useGetPostByIdQuery(
    {
      postId: campaignId || templateId || "",
      include: [
        "thumbnail",
        "author",
        "schedules",
        "recipients",
        "recipients.user",
        "recipients.group",
        "approvals",
        "approvals.author",
      ],
    },
    { skip: !campaignId && !templateId },
  );

  useEffect(() => {
    if (!campaignId || currentCampaign?.id === campaignId) {
      return;
    }

    if (!isLoading) {
      setIsLoading(true);
    }

    dispatch(adminSlice.actions.resetCurrentCampaign({ id: campaignId }));
  }, [campaignId, currentCampaign]);

  const { data: author } = getMeQuery?.data?.result || {};

  useEffect(() => {
    if (!author) {
      return;
    }

    if (!search) {
      if (isLoading && !campaignId && !templateId) {
        setIsLoading(false);
      }

      return;
    }

    if (!isLoading) {
      setIsLoading(true);
    }

    const params = new URLSearchParams(search);
    const templateIdParam = params.get("templateId");

    if (params.get("reset") === "true") {
      dispatch(adminSlice.actions.resetCurrentCampaign({ author }));
      
      if (isLoading) {
        setIsLoading(false);
      }
    } else if (templateIdParam && isUUID(templateIdParam, "4")) {
      dispatch(adminSlice.actions.resetCurrentCampaign({ author }));
      setTemplateId(templateIdParam);
    }

    params.delete("reset");
    params.delete("templateId");
    history.push(params.size > 0 ? `${pathname}?${params.toString()}` : pathname);
  }, [author, search, campaignId, templateId]);

  const { data: getPostByIdResponse } = getPostByIdQuery;
  const post = campaignId || templateId ? getPostByIdResponse : undefined;

  useEffect(() => {
    if (!post) {
      return;
    }

    const { body, title, thumbnail, status, recipients, author, allows, tracks, schedules, notifies, disableCommentsAt, approvals } = post;
    const publishAt = schedules?.find(({ type }) => type === PostScheduleType.PUBLISH)?.runAt;
    const sendReminder = schedules?.some(({ type }) => type === PostScheduleType.SEND_REMINDER);
      
    dispatch(adminSlice.actions.setCurrentCampaign({
      title,
      recipients,
      author,
      allows,
      tracks,
      notifies,
      disableCommentsAt,
      publishAt,
      sendReminder,
      approvals,
      status: templateId ? PostStatus.CREATED : status,
      body: JSON.parse(body),
      thumbnailId: thumbnail?.id,
    }));

    if (isLoading) {
      setIsLoading(false);
    }
  }, [post]);

  useEffect(() => {
    const { title, body } = currentCampaign || {};

    if (!title) {
      setSubmitTooltip(t`Post need a title`);
    } else if (!body) {
      setSubmitTooltip(t`Post need a content`);
    } else {
      setSubmitTooltip("");
    }
  }, [JSON.stringify(currentCampaign)]);

  const savePost = async (post?: Partial<CreatePostInput | UpdatePostByIdInput>): Promise<Post | undefined> => {
    const {
      title,
      thumbnailId,
      body,
      status,
      recipients,
      publishAt,
      notifies,
      tracks,
      allows,
      disableCommentsAt,
      author,
      sendReminder,
    } = currentCampaign || {};

    if (!title || !body) {
      return;
    }
    
    const input: CreatePostInput | UpdatePostByIdInput = {
      title,
      thumbnailId,
      publishAt,
      notifies,
      tracks,
      allows,
      disableCommentsAt,
      sendReminder,
      authorId: author?.id,
      status: status || PostStatus.CREATED,
      body: JSON.stringify(body),
      recipients: recipients?.map(({ type, user, group }) => ({ type, userId: user?.id, groupId: group?.id })),
      ...post,
    };
    const response = currentCampaign?.id 
      ? await updatePostById({ ...input, postId: currentCampaign.id, sendInstantly: !publishAt })
      : await createPost(input);

    if ("error" in response) {
      throw response.error;
    }

    return response.data;
  };

  const handleSaveClick = () => {
    void (async () => {
      setIsSaving(true);
      
      try {
        const post = await savePost();

        if (currentCampaign?.id !== post?.id) {
          dispatch(adminSlice.actions.setCurrentCampaign({ id: post?.id }));
        }

        if (currentCampaign?.isTemplate && post?.id) {
          createPostTemplate(post.id);
        }

        toast.showToast({ severity: "success", message: t`Post was saved.` });
      } catch {
        toast.showToast({ severity: "error", message: t`Failed to save post, please try again.` });
      }

      setIsSaving(false);
    })();
  };

  const handleLaunchClick = () => {
    void (async () => {
      setIsLaunching(true);
      
      try {
        const post = await savePost({ status: PostStatus.PUBLISHED });

        if (currentCampaign?.id !== post?.id) {
          dispatch(adminSlice.actions.setCurrentCampaign({ id: post?.id, status: PostStatus.PUBLISHED }));
        }

        if (post?.status === PostStatus.PUBLISHED) {
          toast.showToast({ severity: "success", message: t`Post was published.` });
        } else {
          toast.showToast({ severity: "warning", message: t`Post needs to be approved before you can publish it.` });
        }
      } catch {
        toast.showToast({ severity: "error", message: t`Failed to publish post, please try again.` });
      }

      setIsLaunching(false);
    })();
  };

  const resolveTabsValue = () => {
    if (/^\/admin\/campaigns\/(.*)\/settings\/?$/.test(pathname)) {
      return 3;
    } else if (/^\/admin\/campaigns\/(.*)\/test\/?$/.test(pathname)) {
      return 2;
    } else if (/^\/admin\/campaigns\/(.*)\/audience\/?$/.test(pathname)) {
      return 1;
    }

    return 0;
  };

  return (
    <Box py={2}>
      <Box alignItems="center" display="flex" justifyContent="space-between" mb={2}>
        <Box display="flex" gap={1}>
          <IconButton color="primary" component={Link} size="small" to="/admin/campaigns"><ArrowBackRounded fontSize="small" /></IconButton>
          <Typography fontSize={22} fontWeight={600}>{t`Campaign`}</Typography>
        </Box>
        <Box display="flex" gap={2}>
          <Button>{t`Cancel`}</Button>
          <Tooltip title={submitTooltip}>
            <span>
              <LoadingButton disabled={!!submitTooltip || isLoading || isLaunching} loading={isSaving} onClick={handleSaveClick} variant="contained">
                {t`Save Campaign`}
              </LoadingButton>
            </span>
          </Tooltip>
          {(!currentCampaign?.status || currentCampaign.status === PostStatus.CREATED) && (currentCampaign?.publishAt === undefined) ? (
            <Tooltip title={submitTooltip}>
              <span>
                <LoadingButton disabled={!!submitTooltip || isSaving || isLoading} loading={isLaunching} onClick={handleLaunchClick} variant="contained">
                  {t`Save and Launch`}
                </LoadingButton>
              </span>
            </Tooltip>
          ) : undefined}
        </Box>
      </Box>
      <Tabs inline sx={{ padding: 0, bgcolor: "#fff", mb: 2 }} value={resolveTabsValue()}>
        <Tab
          data-cid="content-button"
          disabled={isLoading}
          label={t`Content`}
          onClick={() => history.push(`/admin/campaigns/${campaignId ? campaignId + "/edit" : "new"}`)}
          sx={{ color: "#000", minWidth: 0, "&.Mui-selected": { bgcolor: background.blue.main } }}
        />
        <Tab
          data-cid="audience-button"
          disabled={isLoading}
          label={t`Audience`}
          onClick={() => history.push(`/admin/campaigns/${campaignId ? campaignId + "/edit" : "new"}/audience`)}
          sx={{ color: "#000", minWidth: 0, "&.Mui-selected": { bgcolor: background.blue.main } }}
        />
        <Tab
          data-cid="test-button"
          disabled={isLoading}
          label={t`Test`}
          onClick={() => history.push(`/admin/campaigns/${campaignId ? campaignId + "/edit" : "new"}/test`)}
          sx={{ color: "#000", minWidth: 0, "&.Mui-selected": { bgcolor: background.blue.main } }}
        />
        <Tab
          data-cid="settings-button"
          disabled={isLoading}
          label={t`Settings`}
          onClick={() => history.push(`/admin/campaigns/${campaignId ? campaignId + "/edit" : "new"}/settings`)}
          sx={{ color: "#000", minWidth: 0, "&.Mui-selected": { bgcolor: background.blue.main } }}
        />
      </Tabs>
      {isLoading ? (
        <>
          <Skeleton height={18} sx={{ mb: 1 }} variant="rectangular" width={220} />
          <Skeleton height={36} sx={{ mb: 2 }} variant="rectangular" width="100%" />
          <Skeleton height={18} sx={{ mb: 1 }} variant="rectangular" width={280} />
          <Skeleton height={108} sx={{ mb: 2 }} variant="rectangular" width="100%" />
          <Skeleton height={18} sx={{ mb: 1 }} variant="rectangular" width={280} />
          <Skeleton height={216} sx={{ mb: 2 }} variant="rectangular" width="100%" />
        </>
      ) : children}
    </Box>
  );
};  
