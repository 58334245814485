import { useTypedSelector } from 'store/_legacy/Redux/store';
import styles from '../styles.module.scss';

import Box from "components/_legacy/Box";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import { Trans, t } from "@lingui/macro";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { Skeleton } from "@material-ui/lab";
import Text from "components/_legacy/Text";
import { UserManagementUser } from "../../../Store/userManagement/models";
import AllUsersTableBody from "./AllUsersTableBody";
import OverflowToolTip from "components/_legacy/OverflowTooltip";

interface AllUsersTableProps {
  allUsers: UserManagementUser[];
}

export default function AllUsersTable(props: AllUsersTableProps) {
  const { adminUserManagement } = useTypedSelector(state => state);
  const { loading } = adminUserManagement;
  const { allUsers } = props;

  return (
    <Box className={styles.listView}>
      <TableContainer>
        <Table
          aria-label={t`Users management user list`}
          aria-labelledby={t`user list`}
          className={styles.userManagementTable}
        >
          <TableHead classes={{ root: styles.tableHead }}>
            <TableRow classes={{ root: styles.tableRow }}>
              <TableCell classes={{ root: styles.tableCell }} padding="none">
                <Box alignItems="center" display="flex">
                  <Trans>
                    Full name
                  </Trans>
                </Box>
              </TableCell>

              <TableCell classes={{ root: styles.tableCell }} padding="none">
                <Trans>
                  Email
                </Trans>
              </TableCell>

              <TableCell classes={{ root: styles.tableCell }} padding="none">
                <Trans>
                  Role
                </Trans>
              </TableCell>

              <TableCell classes={{ root: styles.tableCell }} padding="none">
                <Trans>
                  Access to locations
                </Trans>
              </TableCell>

              <TableCell classes={{ root: styles.tableCell }} padding="none">
                <Trans>
                  Country
                </Trans>
              </TableCell>

              <TableCell classes={{ root: styles.tableCell }} padding="none">
                <Trans>
                  City
                </Trans>
              </TableCell>

              <TableCell classes={{ root: styles.tableCell }} padding="none">
                <Trans>
                  Documents
                </Trans>
              </TableCell>

              <TableCell classes={{ root: styles.tableCell }} padding="none">
                <Trans>
                  <OverflowToolTip text="Approved Accommodation" />
                </Trans>
              </TableCell>

              <TableCell classes={{ root: styles.tableCell }} padding="none">
                <Trans>
                  Uploaded date
                </Trans>
              </TableCell>

              <TableCell classes={{ root: styles.tableCell }} padding="none"></TableCell>
            </TableRow>
          </TableHead>

          {!loading && (
            <TableBody classes={{ root: styles.tableBody }}>
              {
                allUsers.map((user, index) => {
                  return (<AllUsersTableBody index={index} key={index} user={user} />);
                })
              }
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {loading ? (
        <Box height={200}>
          <Skeleton height="100%" variant="rect" />
        </Box>
      ) : (
        !allUsers.length && (
          <Box marginTop={100}>
            <Text align="center" color="lightGray" size="md">
              No users found
            </Text>
          </Box>
        )
      )}
    </Box>
  );
}
