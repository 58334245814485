import Api from 'store/_legacy/Services/Api';
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import {
  CHECKIN,
  CHECKIN_FAIL,
  CHECKIN_SUCCESS,
  Checkin,
} from '.';

function* checkinSaga(action: Checkin): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: CHECKIN_SUCCESS, payload });
  } catch (e) {
    yield put({ type: CHECKIN_FAIL, payload: e });
  }
}

export default [
  takeLatest(CHECKIN, checkinSaga),
];
