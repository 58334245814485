import React from "react";
import { EditorMarkButtonProps } from "./types/editor-mark-button-props.type";
import { useSlate } from "slate-react";
import { isEditorFormatMarkActive, toggleEditorFormatMark } from "./utils";
import { EditorToolbarButton } from "./editor-toolbar-button";

export const EditorFormatMarkButton: React.FC<EditorMarkButtonProps> = (props) => {
  const { format, Icon, ...rest } = props;
  const editor = useSlate();

  return (
    <EditorToolbarButton
      Icon={Icon}
      active={isEditorFormatMarkActive(editor, format)}
      onMouseDown={() => toggleEditorFormatMark(editor, format)}
      {...rest}
    />
  );
};
