import { Element, Transforms } from "slate";
import { Editor } from "slate";
import { EditorElementType } from "../enums";
import { isEditorElementActive } from "./is-editor-element-active";
import { wrapEditorLink } from "./wrap-editor-link";

const nestedElements = [EditorElementType.LIST];

export const toggleEditorElement = (editor: Editor, element: Partial<Element>): void => {
  if (element.type === EditorElementType.ANCHOR) {
    wrapEditorLink(editor, element.href || "");
  } else {
    const isActive = isEditorElementActive(editor, element);
    const isNested = element.type && nestedElements.includes(element.type);
    
    Transforms.unwrapNodes(editor, {
      match: (node) => !Editor.isEditor(node) && Element.isElement(node) && nestedElements.includes(node.type),
    });

    let newProperties = element;

    if (isActive) {
      newProperties = { type: EditorElementType.PARAGRAPH };
    } else if (isNested) {
      newProperties = { type: EditorElementType.LIST_ITEM };
    }

    Transforms.setNodes<Element>(editor, newProperties as Partial<Element>);

    if (!isActive && isNested) {
      if (element.type === EditorElementType.LIST) {
        const { ordering } = element;

        Transforms.wrapNodes(editor, { type: EditorElementType.LIST, ordering, children: [] });
      }
    }
  }
};
