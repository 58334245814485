import Box from 'components/_legacy/Box';
import CovidQuestion from './CovidQuestion';
import CovidSchedule from './CovidSchedule';
import OpenQuestionForm from './OpenQuestionForm';
import styles from './styles.module.scss';
import Text from 'components/_legacy/Text';
import { ErrorAdminCovidQuestionsSnackbar, SuccessCovidQuestionsSnackbar } from './SnackBars';
import { getCovidQuestions, updateToggleQuestionForm } from 'Admin/Store/covidQuestions';
import { Trans } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTypedSelector } from 'store/_legacy/Redux/store';

export default function CovidQuestions() {
  const dispatch = useDispatch();
  const { locationId } = useParams<{ locationId: string }>();
  const [firstLoad, setFirstLoad] = useState(true);
  const { adminCovidQuestions } = useTypedSelector(state => state);
  const { covidQuestions, loading } = adminCovidQuestions;
  const openFormClass = adminCovidQuestions.isOpenQuestionForm ? styles.actionButtonActive : '';

  useEffect(() => {
    dispatch(getCovidQuestions({ locationId }));
    setFirstLoad(false);
  }, [locationId]);

  function onOpenQuestionForm() {
    dispatch(updateToggleQuestionForm(true));
  }

  function onCancelQuestionForm() {
    dispatch(updateToggleQuestionForm(false));
  }

  return (
    <>
      {(!loading && !firstLoad) && (
        <Box dataTestId="covid-questions" display="flex" justifyContent="between">
          <div className={styles.covidQuestionsList}>
            <div className={styles.covidQuestionsAction}>
              <div
                className={`${styles.covidQuestionsActionButton} ${openFormClass}`}
                data-testid="add-covid-question-button"
                onClick={onOpenQuestionForm}
              >
                <Trans>Add question</Trans>
              </div>
            </div>
            {adminCovidQuestions.isOpenQuestionForm ? (
              <OpenQuestionForm
                actionState={'add'}
                newQuestionIndex={covidQuestions.length + 1}
                onCancelAction={onCancelQuestionForm}
              />
            ) : null}

            {
              covidQuestions.length ? (
                covidQuestions.map((question, index) => (
                  <CovidQuestion index={index} key={index} question={question} />
                ))
              ) : (
                <Box dataTestId="covid-questions-no-data">
                  <Text align="center" color="lightGray" size="md">
                    <Trans>No questions</Trans>
                  </Text>
                </Box>
              )
            }
          </div>
          <Box marginLeft={30} width={370}>
            <CovidSchedule />
          </Box>
        </Box>
      )}

      <SuccessCovidQuestionsSnackbar />
      <ErrorAdminCovidQuestionsSnackbar />
    </>
  );
}
