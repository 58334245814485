import Box from 'components/_legacy/Box';
import Button from 'components/_legacy/Button';
import moment from 'moment';
import Popup from 'reactjs-popup';
import styles from './styles.module.scss';
import Text from 'components/_legacy/Text';
import { Calendar } from 'react-date-range';
import { Trans } from '@lingui/macro';
import { updateCovidCertificate } from '../../../../../../App/Store/CovidSertificate';
import { useDispatch } from 'react-redux';
import { UserDocument } from '../../../../../Store/userManagement/models';
import { useEffect, useState } from 'react';

interface DateRangeCalendarProps {
  type: 'vax' | 'expiry';
  userDocument: UserDocument; // data used for edit state
}

export default function UserDocumentDateRangeModal(props: DateRangeCalendarProps) {
  const dispatch = useDispatch();
  const { userDocument } = props;
  const [selectedDateStart, setSelectedDateStart] = useState<Date>(new Date(moment(new Date(props.userDocument.vaccinationDate)).utc(false).format('MMM DD yyyy')));
  const [selectedDateExpiration, setSelectedDateExpiration] = useState<Date>(new Date(moment(new Date(props.userDocument.vaccinationExpirationDate)).utc(false).format('MMM DD yyyy')));

  useEffect(() => {
    setSelectedDateStart(new Date(moment(new Date(props.userDocument.vaccinationDate)).utc(false).format('MMM DD yyyy')));
  }, [props.userDocument.vaccinationDate]);

  useEffect(() => {
    setSelectedDateExpiration(new Date(moment(new Date(props.userDocument.vaccinationExpirationDate)).utc(false).format('MMM DD yyyy')));
  }, [props.userDocument.vaccinationExpirationDate]);

  const onResetDateRangeData = () => {
    setSelectedDateStart(new Date(userDocument.vaccinationDate));
    setSelectedDateExpiration(new Date(userDocument.vaccinationExpirationDate));
  };

  const onSetTodayDateRangeData = () => {
    setSelectedDateStart(new Date(moment().utc().format()));
    setSelectedDateExpiration(new Date(moment().utc().format()));
  };

  const onSubmitDate = () => {
    const startDate = moment(selectedDateStart).utc(true).format();
    const endDate = moment(selectedDateExpiration).utc(true).format();

    dispatch(updateCovidCertificate({
      params: {
        documentId: userDocument.id,
      },
      body: {
        type: userDocument.type,
        vaccinationDate: startDate,
        vaccinationExpirationDate: endDate,
      },
    }));
  };

  return (
    <Box alignItems='center' display='flex'>
      {props.type === 'vax' &&
        <Popup
          closeOnDocumentClick
          position="top right"
          trigger={(
            <div>
              <Text overflow='noWrap' size="md">
                {userDocument.vaccinationDate ? moment(new Date(userDocument.vaccinationDate)).utc(false).format('MMM DD yyyy') : '-'}
              </Text>
            </div>
          )}
        >
          {(close: any) => (
            <Box className={styles.calendar}>
              <Box alignItems="center" className={styles.buttons} display="flex" justifyContent="between" marginBottom={8}>
                <Button className={styles.buttonToday} noPaddingX onClick={onSetTodayDateRangeData} type="clear">
                  <Trans>Today</Trans>
                </Button>

                <Box>
                  <Button noPaddingX onClick={onResetDateRangeData} type="clear">
                    <Trans>Reset</Trans>
                  </Button>

                  <Button
                    noPaddingX
                    onClick={() => {
                      onSubmitDate();
                      close();
                    }}
                    type="primary"
                  >
                    <Trans>Done</Trans>
                  </Button>
                </Box>
              </Box>

              <Box>
                <Calendar
                  // minDate={new Date()}
                  date={selectedDateStart}
                  onChange={setSelectedDateStart}
                  showDateDisplay={false}
                  showMonthAndYearPickers={false}
                />
              </Box>
            </Box>
          )}
        </Popup>
      }

      {props.type === 'expiry' &&
        <Popup
          closeOnDocumentClick
          position="top right"
          trigger={(
            <div>
              <Text overflow='noWrap' size="md">
                {userDocument.vaccinationExpirationDate ? moment(new Date(userDocument.vaccinationExpirationDate)).utc(false).format('MMM DD yyyy') : '-'}
              </Text>
            </div>
          )}
        >
          {(close: any) => (
            <Box className={styles.calendar}>
              <Box alignItems="center" className={styles.buttons} display="flex" justifyContent="between" marginBottom={8}>
                <Button className={styles.buttonToday} noPaddingX onClick={onSetTodayDateRangeData} type="clear">
                  <Trans>Today</Trans>
                </Button>

                <Box>
                  <Button noPaddingX onClick={onResetDateRangeData} type="clear">
                    <Trans>Reset</Trans>
                  </Button>

                  <Button
                    noPaddingX
                    onClick={() => {
                      onSubmitDate();
                      close();
                    }}
                    type="primary"
                  >
                    <Trans>Done</Trans>
                  </Button>
                </Box>
              </Box>

              <Box>
                <Calendar
                  date={selectedDateExpiration}
                  onChange={setSelectedDateExpiration}
                  showDateDisplay={false}
                  showMonthAndYearPickers={false}
                />
              </Box>
            </Box>
          )}
        </Popup>
      }
    </Box>
  );
}
