import Box from 'components/_legacy/Box';
import Container from 'components/_legacy/Container';
import Divider from 'components/_legacy/Divider';
import getDisplayedResourceName from 'Functions/resourseNameParsing';
import Heading from 'components/_legacy/Heading';
import Map from 'App/Pages/CreateNewBooking/Desk/Map';
import qs from 'qs';
import styles from './styles.module.scss';
import Text from 'components/_legacy/Text';
import useSnackbar from 'components/_legacy/Snackbar/useSnackbar';
import { checkin, setCheckinData } from 'App/Store/Bookings/checkinDuck';
import { formatBookingDateInLocalTime } from 'App/Store/Bookings/bookingDuck';
import { getFloorSchema } from 'Admin/Store/floorMapApiRequestsDuck';
import { LocationOutlinedIcon } from 'components/_legacy/Icons/LocationOutlinedIcon';
import { setCreateNewBookingData } from 'App/Store/Bookings/createNewBookingDuck';
import { Trans } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useTypedSelector } from 'store/_legacy/Redux/store';

export default function Checkin() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [firstLoad, setFirstLoad] = useState(true);
  const [redirect, setRedirect] = useState(false);

  const { locationId } = qs.parse(history.location.search.replace('?', ''));
  const [open] = useSnackbar();
  const { adminFloorMap, checkin: checkinDuck, config } = useTypedSelector(state => state);

  const { mapSize, name, previewUrl, mapImageBounds } = adminFloorMap;
  const { bookingCheckin, error, loading, message } = checkinDuck;

  const clearCheckin = () => {
    dispatch(setCheckinData({ error: '', message: '' }));
  };

  useEffect(() => {
    if (error && !firstLoad) {
      open({
        autoHideDuration: 20000,
        onClose: clearCheckin,
        text: error,
        type: 'error',
      });
    }
  }, [error]);

  useEffect(() => {
    if (message && !firstLoad) {
      open({
        autoHideDuration: 20000,
        onClose: clearCheckin,
        text: message,
        type: 'success',
      });
    }
  }, [message]);

  useEffect(() => {
    if (bookingCheckin) {
      const deskId = bookingCheckin.desk.id;
      const floorId = bookingCheckin.floor.id;

      if (deskId) {
        dispatch(setCreateNewBookingData({ deskId }));
      }

      if (floorId) {
        dispatch(getFloorSchema({ floorId }));
      }
    }
  }, [bookingCheckin]);

  // Check if endpoint returned data, and if there's no data, redirect to home page
  useEffect(() => {
    if (!firstLoad && !loading) {
      if (!bookingCheckin && (error || message)) {
        setRedirect(true);
      }
    }
  }, [bookingCheckin, error, loading, message]);

  useEffect(() => {
    if (locationId && typeof locationId === 'string') {
      dispatch(checkin({ locationId }));
    }

    setFirstLoad(false);
  }, []);

  if (redirect) {
    return <Redirect to="/" />;
  }

  return (!loading && bookingCheckin) ? (
    <Box className={styles.container}>
      <Container>
        <Box className={styles.content}>
          <Box>
            <Box marginBottom={24}>
              <Text weight="semi-bold">
                <Trans>Floor map</Trans>
              </Text>
            </Box>

            <Box display="grid" gap={15}>
              <Text weight="semi-bold">
                {(previewUrl) && (
                  <Map
                    mapImageBounds={mapImageBounds}
                    mapSize={mapSize}
                    name={name}
                    previewUrl={previewUrl}
                    showOnlyBookedDesk
                  />
                )}
              </Text>
            </Box>
          </Box>

          <Box>
            <Box marginBottom={10}>
              <Box alignItems="center" display="flex" justifyContent="between" marginTop={7}>
                <Text weight="semi-bold">
                  <Trans>Details</Trans>
                </Text>
              </Box>

              <Box className={styles.items}>
                {bookingCheckin?.desk &&
                  <Box className={styles.item}>
                    <Text color="gray" size="md">
                      <Trans>Section {bookingCheckin.desk.section}</Trans>
                    </Text>
                  </Box>
                }

                {bookingCheckin?.floor &&
                  <Box className={styles.item}>
                    <Text color="gray" size="md">
                      {bookingCheckin.floor.floorName}
                    </Text>
                  </Box>
                }
              </Box>

              <Box
                display="flex"
                left={-1}
                marginTop={11}
                position="relative"
                style={{ whiteSpace: 'initial' }}
              >
                <LocationOutlinedIcon />

                <Text color='gray' size="md">
                  {bookingCheckin?.location.locationAddress} | {bookingCheckin?.location.region}
                </Text>
              </Box>

              <Box marginTop={27}>
                <Text weight="semi-bold">
                  <Trans>Amenities</Trans>
                </Text>

                <Box className={styles.items}>
                  {bookingCheckin?.desk.resources?.length ? (
                    bookingCheckin.desk.resources.map((resource, index) => {
                      return (
                        <Box className={styles.item} key={resource.name + index}>
                          <Text color="gray" size="md">
                            {getDisplayedResourceName(resource.name)}
                          </Text>
                        </Box>
                      );
                    })
                  ) : (
                    <Box margin={5}>
                      <Text>
                        -
                      </Text>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>

            <Divider />

            <Box className={styles.infoContainer}>
              <Box
                alignItems="center"
                backgroundColor={config.theme.primary}
                borderRadius={20}
                display="flex"
                justifyContent="between"
                padding={22}
              >
                <Box>
                  {bookingCheckin &&
                    <Box marginEnd={20}>
                      <Text color="white" size="md" weight="semi-bold">
                        <Trans>
                          Date and time
                        </Trans>

                        <br />

                        {formatBookingDateInLocalTime(bookingCheckin)}
                      </Text>
                    </Box>
                  }
                </Box>
              </Box>
            </Box>

            <Box marginTop={30}>
              <Heading size="sm">
                <Trans>
                  Checked In Successfully
                </Trans>
              </Heading>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  ) : null;
}