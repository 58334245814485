import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import styles from "../styles.module.scss";
import { Trans, t } from "@lingui/macro";

import Box from "components/_legacy/Box";
import Text from "components/_legacy/Text";
import TextField from "components/_legacy/TextField";
import { updateMeeting } from "../../../../../../Store/floorMapDuck";
import { FloorMapObject } from "../../../../../../Store/floorMapDuck/models";

interface Props {
  meeting: FloorMapObject;
}

export default function MeetingDetailsName(props: Props) {
  const dispatch = useDispatch();
  const { meeting } = props;
  const { id, name } = meeting;

  const [meetingName, setMeetingName] = useState(name);
  const [meetingNameError, setMeetingNameError] = useState(false);

  const checkNameValidation = (name: string) => {
    dispatch(updateMeeting({
      meetingId: typeof id === "string" ? parseInt(id) : id,
      meeting: {
        hasError: !name?.length,
      },
    }));
  };

  /**
   * Update desk name on redux state
   */
  const handleUpdateMeetingName = () => {
    if (!meetingNameError && name !== meetingName) {
      dispatch(updateMeeting({
        meetingId: typeof id === "string" ? parseInt(id) : id,
        meeting: {
          name: meetingName,
        },
      }));
    }
  };

  // Updates desk name on state and check if it's valid
  const handleChangeMeetingName = (event: any) => {
    const name = event.target.value;

    setMeetingName(name);
    setMeetingNameError(!name?.length);
    checkNameValidation(name);
  };

  // Sync desk name on redux and on state
  useEffect(() => {
    setMeetingName(name);
    checkNameValidation(name);
  }, [name]);

  return (
    <Box className={styles.detailsItem}>
      <Text color="gray" size="md">
        <Trans>Room name:</Trans>
      </Text>

      <Text color="gray" size="md">
        <TextField
          classes={{ input: styles.textFieldInput }}
          error={meetingNameError}
          helperText={meetingNameError ? t`This field should not be empty` : ''}
          id="meeting-name"
          onBlur={handleUpdateMeetingName}
          onChange={handleChangeMeetingName}
          value={meetingName}
        />
      </Text>
    </Box>
  );
}
