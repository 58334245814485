import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import L from "leaflet";
import { useTypedSelector } from "store/_legacy/Redux/store";
import { FeatureGroup, useMapEvents } from "react-leaflet";
import {
  DrawState,
  FloorMapObjectTypeEnum,
  MapDrawingGroup,
} from "../../../../../Store/floorMapDuck/models";
import {
  addMeeting,
  setDrawMeetingState,
} from "../../../../../Store/floorMapDuck";
import { EditControl } from "react-leaflet-draw";
import CreateMeeting from "./CreateMeeting";
import MeetingObject from "./MeetingObject";
import { DeskStatusEnum } from "../../../../../Store/floorMapDuck/desk/models";

export default function MapDrawingMeetings() {
  const dispatch = useDispatch();
  const { floorId } = useParams<{ floorId: string; locationId: string }>();
  const [center, setCenter] = useState<L.LatLngExpression>([0, 0]);
  const [zoomLevel, setZoomLevel] = useState<number>(1); // variable to handle zoom event
  const [drawMeetingPopupOpen, setDrawMeetingPopupOpen] = useState(false);
  const [isDrawingMeeting, setIsDrawingMeeting] = useState(false);

  const initialMeetingData: MapDrawingGroup = { id: '', name: ''};
  const [meetingData, setMeetingData] = useState<MapDrawingGroup>(initialMeetingData);
  const { adminFloorMap } = useTypedSelector(state => state);

  const {
    drawingStates,
    meetings,
  } = adminFloorMap;
  const { drawMeeting } = drawingStates;

  // Transform the objects with meetings into arrays | do now show deleted desk with active reservations
  const meetingsArray = Object.keys(meetings).map(key => meetings[parseInt(key)]);

  // Drawing section is handled by redux and internally in this component. This effect syncs both when necessary
  useEffect(() => {
    if (!drawMeeting.active) {
      setIsDrawingMeeting(false);
    }
  }, [drawMeeting.active]);

  const map = useMapEvents({
    'zoom': (e) => {
      setZoomLevel(e.target._zoom);
    },
    'click': (e) => {
      const mapElement = document.querySelector('#mapid > div');
      const isClickOnEmptyMapArea = e.originalEvent.target === mapElement;

      if (isClickOnEmptyMapArea) {
        const clickPosition = e.latlng;

        setCenter(clickPosition);

        if (drawMeeting.active && !isDrawingMeeting) {
          // If there's no drawing active, toggle popup opened
          setDrawMeetingPopupOpen(!drawMeetingPopupOpen);
        }
      }
    },
  });

  // Updates redux to draw section (this action also inactivates draw desk)
  function setDrawMeeting(drawMeeting: DrawState) {
    dispatch(setDrawMeetingState(drawMeeting));
  }

  /**
   * React Leaflet Draw component onCreated function.
   * This function executes everytime a drawing is done.
   * For some reason, this function lives in a scope that doesn't have access to the correct external
   * React states when it's running, so we have to access the data that we pass to the draw layer
   * on the options.attribution property as a JSON string.
   * Also, some workarounds are necessary to sync everyhing, like a setTimeout to tell Leaflet Draw
   * that there is nothing being drawn.
   */
  function onCreated(e: any) {
    const { attribution, color } = e.layer.options;
    const { _latlngs, _leaflet_id } = e.layer;
    // There's not a proper way to pass data to a draw, so we use this property with a JSON string as a workaround
    const drawOptions = JSON.parse(attribution);

    const { meetingData, type } = drawOptions;

    const coordinatesInLatLngBounds: L.LatLng[] = _latlngs.length ? _latlngs[0] : [];
    const coordinatesInPoints = coordinatesInLatLngBounds.map(coordinate => L.CRS.Simple.latLngToPoint(coordinate, 1));

    // The layer is removed because the drawings are handled by the redux inside <Map />
    map.removeLayer(e.layer);

    if (type === FloorMapObjectTypeEnum.Meeting) {
      if (_latlngs && _leaflet_id) {
        // Add section to redux
        dispatch(addMeeting({
          coordinatesInLatLngBounds,
          coordinatesInPoints,
          fill: color,
          latlngs: _latlngs,
          line: color,
          id: _leaflet_id,
          roomId: meetingData.id,
          name: meetingData.name,
          owners: [],
          groups: [],
          provisory: true,
          hasError: false,
          resourceIds: {},
          saved: false,
          status: DeskStatusEnum.Available,
          type: FloorMapObjectTypeEnum.Meeting,
        }));
      }

      setDrawMeeting({ active: false });

      // This timeout is necessary to prevent leaflet draw from triggering actions before changes on internal states
      setTimeout(() => {
        setIsDrawingMeeting(false);
        setMeetingData(initialMeetingData);
      }, 1000);
    }
  }

  return (
    <>
      <CreateMeeting
        center={center}
        meetingData={meetingData}
        popupOpened={drawMeetingPopupOpen}
        setCenter={setCenter}
        setDrawMeetingActive={() => {
          setDrawMeeting({ active: true });
          setIsDrawingMeeting(true);
        }}
        setMeetingData={setMeetingData}
        setPopupOpened={setDrawMeetingPopupOpen}
      />

      <FeatureGroup>
        <EditControl
          draw={{
            circle: false,
            circlemarker: false,
            marker: false,
            polygon: false,
            polyline: false,
            rectangle: true,
          }}
          onCreated={onCreated}
          position="topright"
        />
      </FeatureGroup>

      {meetingsArray.map((meeting) => {
        return (
          <FeatureGroup key={`${meeting.id}`}>
            <MeetingObject
              editable
              floorId={floorId}
              meeting={meeting}
              zoomLevel={zoomLevel}
            />
          </FeatureGroup>
        );
      })}
    </>
  );
}
