import { useDispatch } from "react-redux";
import { useTypedSelector } from "store/_legacy/Redux/store";
import { Trans, t } from "@lingui/macro";
import styles from "../styles.module.scss";
import { Tooltip } from "@material-ui/core";
import Box from "components/_legacy/Box";
import Button from "components/_legacy/Button";
import { useEffect, useState } from "react";
import { meetingHasMinimumDuration } from "../../Helpers";
import { CreateMeetingReservationRequest } from "../../../../../Store/Meetings/meetingReservations/models";
import { createMeetingReservation } from "../../../../../Store/Meetings/meetingReservations";
import moment from "moment";
import { getDelegatedUserId } from "App/Store/Users/executiveAssistant/helpers";

export default function BookMeetingButton() {
  const dispatch = useDispatch();
  const {
    config,
    createNewMeeting,
    meetingReservations,
    profile,
    executiveAssistant,
  } = useTypedSelector(state => state);
  const {
    meetingFrom,
    meetingTo,
    selectedData,    
  } = createNewMeeting;
  const {
    attendees,
    externalAttendees,
    requestedServices,
    isTeamsMeeting,
    meetingTitle,
    roomId,
  } = selectedData;
  const { reservationCreationLoading } = meetingReservations;
  const [allStepsCompleted, setAllStepCompleted] = useState(false);
  const delegatedUserId = getDelegatedUserId(profile.roleAccess, executiveAssistant.selectedUser || undefined);

  const handleBookClick = () => {
    if (!roomId || !meetingFrom || !meetingTo) return;

    const meetingFromFormatted = moment(meetingFrom)
      .set('second', 0).set('millisecond', 0).toDate();

    const meetingToFormatted = moment(meetingTo)
      .set('second', 0).set('millisecond', 0).toDate();

    const payload: CreateMeetingReservationRequest = {
      type: 'room',
      roomId,
      startDate: meetingFromFormatted,
      endDate: meetingToFormatted,
      attendees: attendees.filter(({ id }) => !!id).map(({ id }) => id) as string[],
      externalAttendees: externalAttendees,
      requestedServices,
      summary: meetingTitle,
      isTeamsMeeting,
    };

    dispatch(createMeetingReservation(payload, delegatedUserId));
  };

  useEffect(() => {
    const hasMinimumDuration = meetingHasMinimumDuration(meetingFrom, meetingTo);

    const allStepsCompleted = Boolean(roomId && meetingTitle.length > 0
      && hasMinimumDuration);

    setAllStepCompleted(allStepsCompleted);
  }, [meetingFrom, meetingTo, attendees, meetingTitle]);

  return (
    <Tooltip title={!allStepsCompleted ? t`Please, fill the all steps: at least 30 minutes long meeting duration, capacity, meeting title`: ''}>
      <div>
        <Box alignItems='center' backgroundColor={config.theme.primary} borderRadius={20} display='flex' justifyContent='center' padding={22}>
          <Button
            aria-label={t`Book meeting room`}
            className={styles.btnMeetingSchedule}
            disabled={!allStepsCompleted || reservationCreationLoading}
            name={t`Book meeting room`}
            onClick={handleBookClick}
            size="sm"
            type="white"
          >
            <Trans>Book meeting room</Trans>
          </Button>
        </Box>
      </div>
    </Tooltip>
  );
}
