import {useTypedSelector} from "store/_legacy/Redux/store";
import CheckCircleIcon from "../Icons/CheckCircle";
import DeniedCircleIcon from "../Icons/DeniedCircle";
import ClockIcon from "../Icons/Clock";
import NoneCircleIcon from "../Icons/NoneCircle";
import Box from "../Box";
import Text from "../Text";
import {UserDocumentStatus} from "Admin/Store/userManagement/models";

interface DocStatusLabelProps {
  docStatus: UserDocumentStatus;
  text: string;
}

export default function DocStatusLabel(props: DocStatusLabelProps) {
  const { config } = useTypedSelector(state => state);
  const { docStatus, text } = props;

  const getIcon = (status: UserDocumentStatus) => {
    switch (status) {
      case 'approved': return <CheckCircleIcon color="#5BC535" />;
      case 'denied': return <DeniedCircleIcon color={config.theme.greyMedium} />;
      case 'expired': return <ClockIcon color={config.theme.error} />;
      case 'needsApproval': return <ClockIcon color="#FFBD0E" />;
      case 'notUploaded': return <NoneCircleIcon color={config.theme.greyMedium} />;
      default: return;
    }
  };

  return (
    <Box alignItems='center' display='flex' gap={5} marginStart={3}>
      {getIcon(docStatus)}

      <Text size="md" weight='regular'>
        <div>{text}</div>
      </Text>
    </Box>
  );
}
