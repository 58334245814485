import Api from 'store/_legacy/Services/Api';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  GET_LOCATION_RESOURCES,
  GET_LOCATION_RESOURCES_FAIL,
  GET_LOCATION_RESOURCES_SUCCESS,
  GetLocationResources,
} from '.';

function* getLocationResourcesSaga(action: GetLocationResources): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: GET_LOCATION_RESOURCES_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_LOCATION_RESOURCES_FAIL, payload: e });
  }
}

export default [
  takeEvery(GET_LOCATION_RESOURCES, getLocationResourcesSaga),
];
