import { GetMeetingReservationsResponse } from './models';

export const getReservationsResponse: GetMeetingReservationsResponse = {
  result: {
    statusCode: 200,
    data: {
      items: [],
      meta: {
        total: 10,
        pages: 1,
      },
      links: {
        first: '',
        next: '',
        previous: '',
        last: '',
      },      
    },
  },
};