import { t } from "@lingui/macro";
import { AddCircleOutline, AddReactionOutlined, ArrowBackRounded, ChatOutlined, EditOutlined, InfoOutlined, InsertLinkRounded, LaunchRounded, MoreVertRounded, VisibilityOutlined } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Dialog, Divider, IconButton, IconButtonProps, List, ListItem, MenuItem, Popover, Skeleton, Switch, Tooltip, Typography, useTheme } from "@mui/material";
import { LineChart, LineChartProps } from "@mui/x-charts";
import { DesktopDateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ConfirmationDialog, CountBadge, ListHeader, ListHeaderLabel, MenuTab, MenuTabs, NewsFeedItemContent, RequiredMark, StandardSelect, TogetherDelete, UserSelect, useToast } from "components";
import { AttachmentImage } from "components/attachments";
import { format } from "date-fns";
import React, { useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { GetPostMetricsInput, PostInteractionType, PostMetricType, PostNotificationChannel, PostRecipientStatus, PostRecipientType, PostScheduleType, TrackingEventType, useCreatePostTemplateMutation, useDeletePostByIdMutation, useGetLinkClicksByPostIdQuery, useGetPostByIdQuery, useGetPostMetricsQuery } from "store";
import { Dictionary } from "ts-essentials";
import { toggleItem } from "utils";

const METRICS_ORDER = [PostMetricType.VIEWS, PostMetricType.SHARES, PostMetricType.REACTIONS, PostMetricType.COMMENTS, PostMetricType.LINK_CLICKS];

export const CampaignDetails: React.FC = () => {
  const { campaignId } = useParams<{ campaignId: string }>();
  const history = useHistory();
  const { palette, background } = useTheme();
  const toast = useToast();
  const [moreAnchorEl, setMoreAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [willDelete, setWillDelete] = useState(false);
  const [showLinkClicks, setShowLinkClicks] = useState(false);
  const [granularity, setGranularity] = useState<GetPostMetricsInput["granularity"]>("week");
  const [metricTypes, setMetricTypes] = useState<PostMetricType[]>([PostMetricType.VIEWS]);
  const [audienceTab, setAudienceTab] = useState(0);
  const [deletePostById] = useDeletePostByIdMutation();
  const [createPostTemplate] = useCreatePostTemplateMutation();
  const { data: getPostMetricsResponse, isLoading: isLoadingMetrics } = useGetPostMetricsQuery({ postId: campaignId });
  const { data: getPostGranularMetricsResponse, isLoading: isLoadingGranularMetrics } = useGetPostMetricsQuery({
    granularity,
    types: metricTypes,
    postId: campaignId,
    granularityTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
  const { data: post, isLoading: isLoadingPost } = useGetPostByIdQuery({
    postId: campaignId,
    include: ["thumbnail", "author", "recipients", "schedules", "recipients.user", "recipients.group"],
  });
  const { data: getLinkClicksResponse, isLoading: isLoadingLinkClicks } = useGetLinkClicksByPostIdQuery(campaignId, { skip: !showLinkClicks });

  const handleAddToTemplatesClick = () => {
    void (async () => {
      const response = await createPostTemplate(campaignId);
  
      if ("error" in response) {
        toast.showToast({ severity: "error", message: t`Failed to add post to templates, please try again.` });
      } else {
        toast.showToast({ severity: "success", message: t`Post was added to templates.` });
      }
  
      setMoreAnchorEl(null);
    })();
  };

  const handlePostDeleteConfirmation = () => {
    void (async (): Promise<void> => {
      setWillDelete(false);
      setMoreAnchorEl(null);
  
      if (!post) {
        return;
      }
  
      const response = await deletePostById(post.id);
  
      if ("error" in response) {
        toast.showToast({ severity: "error", message: t`Failed to delete post, please try again.` });
      } else {
        history.push("/admin/campaigns/active");
        toast.showToast({ severity: "success", message: t`Post was deleted.` });
      }
    })();
  };

  const handleShowLinkClicksClick: IconButtonProps["onClick"] = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setShowLinkClicks(true);
  };

  const metrics = getPostMetricsResponse?.items 
    ? METRICS_ORDER.map((type) => getPostMetricsResponse.items.find((metric) => metric.type === type)) 
    : [];
  const points: string[] = [];
  const metricLabels: Dictionary<Dictionary<string, "title" | "color">, PostMetricType> = {
    [PostMetricType.VIEWS]: { title: t`Views`, color: palette.primary.main },
    [PostMetricType.SHARES]: { title: t`Shares`, color: "#5FB3DF" },
    [PostMetricType.REACTIONS]: { title: t`Reactions`, color: "#5BC535" },
    [PostMetricType.COMMENTS]: { title: t`Comments`, color: "#FF4C0E" },
    [PostMetricType.LINK_CLICKS]: { title: t`Link Clicks`, color: "#FFBD0E" },
  };
  const series: LineChartProps["series"] = [];

  for (const metric of getPostGranularMetricsResponse?.items || []) {
    if (metric) {
      const { type, value, reference } = metric;
      const label = reference ? format(new Date(reference), "d LLL, yyyy") : undefined;

      if (label && !points.includes(label)) {
        points.push(label);
      }

      const typeSeries = series.find(({ id }) => id === type);

      if (typeSeries) {
        typeSeries.data?.push(value);
      } else {
        const { color } = metricLabels[type];

        series.push({
          color,
          id: type,
          curve: "linear",
          data: [value],
          showMark: false,
        });
      }
    }
  }

  const audience = post?.recipients?.filter(({ type }) => type === PostRecipientType.AUDIENCE);
  const users = audience?.filter(({ user, group }) => !group && !!user);
  const groups = audience?.filter(({ group }) => !!group);
  const { items: linkClicks } = getLinkClicksResponse || {};
  
  return (
    <>
      <Box py={2}>
        <Box alignItems="center" display="flex" justifyContent="space-between" mb={2}>
          <Box display="flex" gap={1}>
            <IconButton color="primary" component={Link} size="small" to="/admin/campaigns/active"><ArrowBackRounded fontSize="small" /></IconButton>
            <Typography fontSize={22} fontWeight={600}>{t`Details`}</Typography>
          </Box>
          <Box display="flex" gap={2}>
            <IconButton color="primary" onClick={(event) => setMoreAnchorEl(event.currentTarget)} size="small">
              <MoreVertRounded fontSize="small" />
            </IconButton>
          </Box>
        </Box>
        {isLoadingPost || isLoadingMetrics || isLoadingGranularMetrics ? (
          <>
            <Skeleton height={22} sx={{ mb: 1 }} variant="rectangular" width={120} />
            <Skeleton height={74} sx={{ mb: 2 }} variant="rectangular" width={900} />
            <Box display="flex" gap={2} mb={1}>
              <Skeleton height={22} variant="rectangular" width={80} />
              <Skeleton height={22} variant="rectangular" width={60} />
            </Box>
            <Skeleton height={300} sx={{ mb: 4 }} variant="rectangular" width="100%" />
            <Skeleton height={22} sx={{ mb: 2 }} variant="rectangular" width={180} />
            <Skeleton height={18} sx={{ mb: 1 }} variant="rectangular" width={420} />
            <Skeleton height={16} sx={{ mb: 1 }} variant="rectangular" width="100%" />
            <Skeleton height={16} sx={{ mb: 1 }} variant="rectangular" width="100%" />
            <Skeleton height={16} sx={{ mb: 1 }} variant="rectangular" width="100%" />
            <Skeleton height={16} sx={{ mb: 1 }} variant="rectangular" width="100%" />
            <Skeleton height={16} sx={{ mb: 1 }} variant="rectangular" width="40%" />
          </>
        ) : (
          <>
            <Typography fontSize={16} fontWeight={600} mb={1}>{t`Analytics`}</Typography>
            <ButtonGroup sx={{ mb: 2, "& .MuiButtonGroup-grouped:not(:last-of-type)": { borderColor: palette.grey[200] } }} variant="text">
              {metrics?.map((metric) => {
                if (!metric) {
                  return <></>;
                }

                const { type, value } = metric;
                const isSelected = metricTypes.includes(type);

                return (
                  <Button
                    key={type}
                    onClick={() => setMetricTypes(toggleItem(metricTypes, type))}
                    sx={{
                      flexDirection: "column",
                      alignItems: "stretch",
                      pl: 2,
                      py: 1,
                      width: 180,
                      bgcolor: isSelected ? background.blue.main : undefined,
                      "&.MuiButtonGroup-grouped:not(:last-of-type)": { borderColor: isSelected ? background.blue.main : undefined },
                    }}
                  >
                    <Box alignItems="center" display="flex" gap={1} mb={1}>
                      {type === PostMetricType.VIEWS ? (
                        <VisibilityOutlined fontSize="small" sx={{ color: isSelected ? palette.primary.main: palette.grey[700] }} /> 
                      ) : undefined}
                      {type === PostMetricType.SHARES ? (
                        <LaunchRounded fontSize="small" sx={{ color: isSelected ? palette.primary.main: palette.grey[700] }} />
                      ) : undefined}
                      {type === PostMetricType.REACTIONS ? (
                        <AddReactionOutlined fontSize="small" sx={{ color: isSelected ? palette.primary.main: palette.grey[700] }} />
                      ) : undefined}
                      {type === PostMetricType.COMMENTS ? (
                        <ChatOutlined fontSize="small" sx={{ color: isSelected ? palette.primary.main: palette.grey[700] }} />
                      ) : undefined}
                      {type === PostMetricType.LINK_CLICKS ? (
                        <InsertLinkRounded fontSize="small" sx={{ color: isSelected ? palette.primary.main: palette.grey[700] }} />
                      ) : undefined}
                      <Typography color={isSelected ? "primary" : palette.grey[700]} fontSize={14} lineHeight={1}>
                        {metricLabels[type].title}
                      </Typography>
                    </Box>
                    <Box alignItems="flex-end" display="flex" justifyContent="space-between">
                      <Typography
                        color={isSelected ? "primary" : palette.grey[700]}
                        fontSize={30}
                        fontWeight={600}
                        lineHeight={1}
                      >
                        {value}
                      </Typography>
                      {type === PostMetricType.LINK_CLICKS ? (
                        <Tooltip title={t`Click to show link clicks`}>
                          <IconButton onClick={handleShowLinkClicksClick} size="small">
                            <InfoOutlined fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      ) : undefined}
                    </Box>
                  </Button>
                );
              })}
            </ButtonGroup>
            <Box display="flex" justifyContent="space-between" mb={1}>
              <Box display="flex">
                <Typography fontWeight={600}>{t`Total by`}</Typography>
                <Divider flexItem orientation="vertical" sx={{ mx: 1 }} variant="middle" />
                <StandardSelect onChange={(event) => setGranularity(event.target.value as GetPostMetricsInput["granularity"])} value={granularity}>
                  <MenuItem value="week">{t`Week`}</MenuItem>
                  <MenuItem value="month">{t`Month`}</MenuItem>
                  <MenuItem value="quarter">{t`Quarter`}</MenuItem>
                </StandardSelect>
              </Box>
              <Box display="flex" gap={1}>
                {(metricTypes?.length ? metricTypes : Object.keys(metricLabels) as PostMetricType[]).map((type) => (
                  <Box alignItems="center" display="flex" gap={0.5} key={type}>
                    <Box bgcolor={metricLabels[type].color} borderRadius={0.5} height={10} width={10} />
                    <Typography color={palette.grey[700]} fontSize={12} lineHeight={1}>{metricLabels[type].title}</Typography>
                  </Box>
                ))}
              </Box>
            </Box>
            <Box mb={4}>
              <LineChart
                bottomAxis={{ disableTicks: true }}
                height={300}
                leftAxis={{ disableTicks: true, tickLabelPlacement: "middle" }}
                margin={{ right: 0, top: 8, bottom: 20 }}
                series={series}
                sx={{
                  "& .MuiChartsAxis-root": {
                    "& .MuiChartsAxis-line": { stroke: palette.grey[100] },
                    "& .MuiChartsAxis-tickLabel": { fill: palette.grey[700] },
                  },
                  "& .MuiChartsAxis-left": {
                    "& .MuiChartsAxis-tickLabel": { transform: "translateX(-28px)" },
                  },
                  "& .MuiChartsAxis-bottom": {
                    "& .MuiChartsAxis-tickContainer": {
                      "&:first-of-type": { transform: "translate(86px,0)" },
                      "&:last-of-type": { "& .MuiChartsAxis-tickLabel": { transform: "translateX(-30px)" } },
                    },
                  },
                }}
                xAxis={[{ scaleType: "point", data: points, tickLabelPlacement: "tick" }]}
              />
            </Box>
            <Typography fontWeight={600} mb={2}>{t`Campaign's content`}</Typography>
            <Box mb={4}>
              <Typography fontSize={14} fontWeight={600} mb={1}>{post?.title}</Typography>
              {post?.thumbnail?.id ? <AttachmentImage attachmentId={post.thumbnail.id} borderRadius={2} height="auto" mb={2} width="100%" /> : undefined}
              <NewsFeedItemContent content={post?.body} disableTracking postId={campaignId}  />
            </Box>
            <Box mb={4}>
              <Box alignItems="baseline" display="flex" gap={1} mb={1}>
                <Typography fontWeight={600} lineHeight={1}>{t`Audience`}</Typography>
                <CountBadge>{audience?.length || 0}</CountBadge>
              </Box>
              <MenuTabs onChange={(_, value) => setAudienceTab(value)} sx={{ mb: 2 }} value={audienceTab}>
                <MenuTab count={users?.length} index={0} label={t`Users`} sx={{ fontSize: 14 }} />
                <MenuTab count={groups?.length} index={1} label={t`Groups`} sx={{ fontSize: 14 }} />
              </MenuTabs>
              {audienceTab === 0 ? (
                <>
                  <ListHeader>
                    <ListHeaderLabel sx={{ flex: 1 }}>{t`Name`}</ListHeaderLabel>
                    <ListHeaderLabel sx={{ width: 360 }}>{t`Email`}</ListHeaderLabel>
                  </ListHeader>
                  {users?.length ? (
                    <List disablePadding sx={{ maxHeight: 380, overflowY: "auto", overflowX: "hidden" }}>
                      {users?.map(({ user }) => (
                        <ListItem disablePadding key={user?.id} sx={{ p: 1, gap: 1, borderBottom: "1px solid rgba(0, 0, 0, 0.05)" }}>
                          <Typography flex={1} fontSize={14} noWrap>{user?.name}</Typography>
                          <Typography fontSize={14} noWrap width={360}>{user?.email}</Typography>
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <Typography color={palette.grey[700]} fontSize={14} my={3} textAlign="center">{t`No user selected`}</Typography>
                  )}
                </>
              ) : undefined}
              {audienceTab === 1 ? (
                <>
                  <ListHeader>
                    <ListHeaderLabel sx={{ flex: 1 }}>{t`Name`}</ListHeaderLabel>
                    <Box component="span" width={32} />
                  </ListHeader>
                  {groups?.length ? (
                    <List disablePadding>
                      {groups?.map(({ group }) => (
                        <ListItem disablePadding key={group?.id} sx={{ p: 1, gap: 1, borderBottom: "1px solid rgba(0, 0, 0, 0.05)" }}>
                          <Typography flex={1} fontSize={14} noWrap>{group?.name}</Typography>
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <Typography color={palette.grey[700]} fontSize={14} my={3} textAlign="center">{t`No group selected`}</Typography>
                  )}
                </>
              ) : undefined}
            </Box>
            <Box maxWidth={420} mb={4}>
              <Typography fontWeight={600} mb={2}>{t`Settings`}</Typography>
              <Box alignItems="center" display="flex" justifyContent="space-between" mb={2}>
                <Typography color={palette.grey[700]} fontSize={14}>{t`Send via email`}</Typography>
                <Switch checked={post?.notifies?.includes(PostNotificationChannel.EMAIL)} disabled />
              </Box>
              <Box alignItems="center" display="flex" justifyContent="space-between" mb={2}>
                <Typography color={palette.grey[700]} fontSize={14}>{t`Send via apps`}</Typography>
                <Switch checked={post?.notifies?.includes(PostNotificationChannel.PUSH)} disabled />
              </Box>
              <Box alignItems="center" display="flex" justifyContent="space-between" mb={2}>
                <Typography alignItems="center" color={palette.grey[700]} display="flex" fontSize={14} gap={1}>
                  {t`Send reminder`}
                  <InfoOutlined fontSize="small" />
                </Typography>
                <Switch checked={post?.schedules?.some(({ type }) => type === PostScheduleType.SEND_REMINDER)} disabled />
              </Box>
              <Box alignItems="center" display="flex" justifyContent="space-between" mb={2}>
                <Typography color={palette.grey[700]} fontSize={14}>{t`Allow reactions`}</Typography>
                <Switch checked={post?.allows?.includes(PostInteractionType.REACT)} disabled />
              </Box>
              <Box alignItems="center" display="flex" justifyContent="space-between" mb={2}>
                <Typography color={palette.grey[700]} fontSize={14}>{t`Allow comments`}</Typography>
                <Switch checked={post?.allows?.includes(PostInteractionType.COMMENT)} disabled />
              </Box>
              <Box alignItems="center" display="flex" justifyContent="space-between" mb={2}>
                <Typography color={palette.grey[700]} fontSize={14}>{t`Allow post share`}</Typography>
                <Switch checked={post?.allows?.includes(PostInteractionType.SHARE)} disabled />
              </Box>
              <Box alignItems="center" display="flex" justifyContent="space-between" mb={2}>
                <Typography color={palette.grey[700]} fontSize={14}>{t`Track clicks`}</Typography>
                <Switch checked={post?.tracks?.includes(TrackingEventType.CLICK)} disabled />
              </Box>
              <Box alignItems="center" display="flex" justifyContent="space-between" mb={2}>
                <Typography color={palette.grey[700]} fontSize={14}>{t`Date to disable comments`}</Typography>
                <Switch checked={post?.disableCommentsAt !== undefined} disabled />
              </Box>
              {post?.disableCommentsAt !== undefined ? (
                <Box mb={2}>
                  <Typography fontSize={14} mb={1}><RequiredMark />{t`Send date and time`}</Typography>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDateTimePicker
                      ampm={false}
                      defaultValue={post.disableCommentsAt ? new Date(post.disableCommentsAt) : undefined}
                      disabled
                      slotProps={{ textField: { variant: "filled" }, openPickerButton: { color: "primary" } }}
                      sx={{ "& .MuiInputBase-input": { fontSize: 14 } }}
                    />
                  </LocalizationProvider>
                </Box>
              ) : undefined}
              <Typography fontSize={14} mb={1}><RequiredMark />{t`From (indicated in post)`}</Typography>
              <UserSelect disableClearable disabled value={post?.author || null} />
            </Box>
          </>
        )}
      </Box>
      <Popover
        anchorEl={moreAnchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={() => setMoreAnchorEl(null)}
        open={!!moreAnchorEl}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <ButtonGroup
          orientation="vertical"
          sx={{ 
            "& .MuiButton-root": { justifyContent: "flex-start" },
            "& .MuiButtonGroup-grouped:not(:last-of-type)": { border: "none" },
          }}
          variant="text"
        >
          <Button component={Link} sx={{ gap: 1 }} to={`/admin/campaigns/${campaignId}/edit`}>
            <EditOutlined fontSize="small" />
            <Typography fontSize={14}>{t`Edit campaign`}</Typography>
          </Button>
          <Button onClick={handleAddToTemplatesClick} sx={{ gap: 1 }}>
            <AddCircleOutline fontSize="small" />
            <Typography fontSize={14}>{t`Add to Templates`}</Typography>
          </Button>
          <Button onClick={() => setWillDelete(true)} sx={{ gap: 1 }}>
            <TogetherDelete sx={{ width: 20, height: 20 }} />
            <Typography fontSize={14}>{t`Delete Campaign`}</Typography>
          </Button>
        </ButtonGroup>
      </Popover>
      <ConfirmationDialog
        description={t`Are you sure you want to delete "${post?.title}"?`}
        onClose={() => setWillDelete(false)}
        onConfirm={handlePostDeleteConfirmation}
        open={!!willDelete}
        title={t`Delete post`}
      />
      <Dialog onClose={() => setShowLinkClicks(false)} open={showLinkClicks}>
        <Box p={2} width={600}>
          <Typography fontWeight={600} mb={1}>{t`Link clicks`}</Typography>
          <ListHeader>
            <ListHeaderLabel sx={{ flex: 1 }}>{t`Url`}</ListHeaderLabel>
            <ListHeaderLabel sx={{ width: 100 }}>{t`Clicks`}</ListHeaderLabel>
          </ListHeader>
          {isLoadingLinkClicks ? (
            <Box>
              <Box alignItems="center" display="flex" height={45} justifyContent="space-between" px={1}>
                <Skeleton height={14} variant="rectangular" width={280} />  
                <Skeleton height={14} variant="rectangular" width={100} />  
              </Box>
              <Divider sx={{ opacity: 0.6 }} />
              <Box alignItems="center" display="flex" height={45} justifyContent="space-between" px={1}>
                <Skeleton height={14} variant="rectangular" width={280} />  
                <Skeleton height={14} variant="rectangular" width={100} />  
              </Box>
              <Divider sx={{ opacity: 0.6 }} />
              <Box alignItems="center" display="flex" height={45} justifyContent="space-between" px={1}>
                <Skeleton height={14} variant="rectangular" width={280} />  
                <Skeleton height={14} variant="rectangular" width={100} />  
              </Box>
            </Box>
          ) : linkClicks?.length ? (
            <List disablePadding sx={{ mb: 2 }}>
              {linkClicks?.map(({ url, count }) => (
                <ListItem
                  disablePadding
                  divider
                  key={url}
                  sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.05)", px: 1, py: 1.5, gap: 1, flex: 1 }}
                >
                  <Typography flex={1} fontSize={14} noWrap title={url}>{url}</Typography>
                  <Typography fontSize={14} noWrap width={100}>{count}</Typography>
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography color={palette.grey[700]} fontSize={14} my={4} textAlign="center">
              {t`No link clicks yet.`}
            </Typography>
          )}
        </Box>
      </Dialog>
    </>
  );
};
