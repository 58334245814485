import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {useTypedSelector} from "store/_legacy/Redux/store";
import {useParams} from "react-router-dom";
import styles from './styles.module.scss';

import Box from "components/_legacy/Box";
import {Skeleton} from "@material-ui/lab";
import { ErrorAdminGroupDetailsSnackbar, SuccessAdminGroupDetailsSnackbar } from "./SnackBars/SnackBars";
import { ErrorAdminGroupsSnackbar, SuccessAdminGroupsSnackbar } from "../Groups/SnackBars";
import GroupDetailsHeader from "./GroupDetailsHeader";
import GroupInfo from "./GroupInfo";
import GroupUserTable from "./GroupUserTable";
import { getGroups } from "../../Store/groups";
import CustomPagination from "components/_legacy/CustomPagination";
import { initFiltersData, setGroupDetailsData } from "../../Store/groupDetails";
import { getAllUsers } from "../../Store/userManagement";

export default function GroupDetails() {
  const dispatch = useDispatch();
  const { groups, groupDetails, adminUserManagement } = useTypedSelector(state => state);
  const { groupsList } = groups;
  const { usersUpdated, filters } = groupDetails;
  const { allUsers, limit, totalPages } = adminUserManagement;
  const { search } = filters;
  const { groupId } = useParams<{ groupId: string; }>();
  const group = groupsList.find(d => d.id === groupId) || null;

  const resetData = () => {
    dispatch(setGroupDetailsData({
      filters: initFiltersData,
      infoEditState: false,
    }));
  };

  function getAllUsersData(pageNumber?: number) {
    const page = pageNumber ? pageNumber : 1;

    dispatch(getAllUsers({
      page,
      search,
      groupId: group?.id,
    }));
  }

  const onChangePage = (page: number) => {
    getAllUsersData(page);
  };

  useEffect(() => {
    if (!groupsList.length) {
      dispatch(getGroups({
        page: 1,
        limit,
        search,
      })); // call load groups if no group data
    }
  }, [groupsList]);

  // reset data on component load
  useEffect(() => {
    resetData();
  }, []);

  // update users data if group is changed | after update users list | on filters
  useEffect(() => {
    getAllUsersData();
  }, [groupId, search]);

  useEffect(() => {
    if (usersUpdated) { // update if id is existed and updated is true
      getAllUsersData();
    }
  }, [usersUpdated]);

  return (
    <>
      <SuccessAdminGroupDetailsSnackbar />
      <ErrorAdminGroupDetailsSnackbar />

      {/* notifications for edit group name/locations */}
      <SuccessAdminGroupsSnackbar />
      <ErrorAdminGroupsSnackbar />

      <GroupDetailsHeader group={group}/>
      {group ?
        <Box className={styles.groupDetails} display="flex" justifyContent="between" marginTop={20}>
          <Box marginRight={30} width={270}>
            <GroupInfo group={group}/>
          </Box>
          <div className={styles.documentsWrapper}>
            <GroupUserTable allUsers={allUsers} isAdGroup={!!group?.adGroup?.id} />
            {allUsers.length ?
              <Box padding="50px 0 50px">
                <CustomPagination
                  count={totalPages}
                  key={totalPages}
                  onChange={onChangePage}
                />
              </Box> : null
            }
          </div>
        </Box> :
        <Box height={200}>
          <Skeleton height="100%" variant="rect" />
        </Box>
      }
    </>
  );
}