import { KeyboardArrowDown } from "@mui/icons-material";
import { PaletteOptions, SimplePaletteColorOptions, createTheme } from "@mui/material";
import { grey } from "@mui/material/colors";

const palette = {
  primary: {
    main: "#1E1F7B",
  },
  mode: "light",
  secondary: {
    main: '#f1f8ff',
    contrastText: '#1e1f7b',
  },
  error: {
    main: "#d32f2f",
    light: "#ffbebe",
  },
};

declare module '@mui/material/styles' {
  interface Theme {
    background: {
      blue: SimplePaletteColorOptions;
    };
  }

  interface ThemeOptions {
    background: {
      blue: SimplePaletteColorOptions;
    };
  }
}

export const theme = createTheme({
  palette: palette as PaletteOptions,
  background: {
    blue: {
      main: "#E3E3F5",
      light: "#EFEFF8",
    },
    
  },
  typography: {
    fontFamily: "Poppins",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: "capitalize",
          fontWeight: "600",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 8,
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: grey[100],
          borderRadius: 8,
        },
      },
      defaultProps: {
        animation: "wave",
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: grey[100],
          borderRadius: 8,
          ":after": {
            display: "none",
          },
          ":before": {
            display: "none",
          },
          "&.Mui-focused": {
            backgroundColor: "#EFEFF8",
          },
          "& .MuiInputAdornment-root": {
            marginTop: "0px !important",
          },
        },
        input: {
          padding: "6px 8px",
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: ({ ownerState: { size } }) => {
          const height = size === "small" ? 12 : 14;
          const width = size === "small" ? 26 : 28;
          const thumb = size === "small" ? 10 : 12;

          return {
            height,
            width,
            padding: 0,
            "& .MuiSwitch-thumb": {
              width: thumb,
              height: thumb,
            },
          };
        },
        switchBase: ({ ownerState: { size } }) => {
          const height = size === "small" ? 12 : 14;
          const width = size === "small" ? 10 : 14;

          return {
            height,
            width,
            padding: "0 !important",
            "&.Mui-checked": {
              transform: `translateX(${width}px)`,
            },
            "&.Mui-checked+.MuiSwitch-track": {
              opacity: 1,
            },
            "&.Mui-disabled+.MuiSwitch-track": {
              opacity: 0.4,
            },
          };
        },
        track: ({ ownerState: { size } }) => {
          const height = size === "small" ? 12 : 14;
          const width = size === "small" ? 26 : 28;

          return {
            height,
            width,
            borderRadius: 4,
            backgroundColor: grey[400],
          };
        },
        thumb: ({ ownerState: { size } }) => {
          const borderRadius = size === "small" ? 3 : 4;

          return {
            borderRadius,
            backgroundColor: "#fff",
          };
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          position: "relative",
          transform: "none",
          fontSize: 14,
          color: "#000",
          marginBottom: 4,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: 8,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: grey[100],
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: KeyboardArrowDown,
      },
      variants: [
        {
          props: { variant: "filled" },
          style: {
            "& .MuiSelect-icon": {
              color: palette.primary.main,
            },
            "& .MuiFilledInput-input:focus": {
              backgroundColor: "transparent",
            },
          },
        },
      ],
    },
  },
});
