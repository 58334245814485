import { ActionPayload, BaseErrorResponse, BaseResponse } from 'store/_legacy/Models/ReduxModels';
import { t } from '@lingui/macro';
import {
  DashboardsTotalCount,
  DashboardsTotalCountByPeriodPeriods,
  GetLocationDashboardTotalByDayRequest,
  GetLocationDashboardTotalByDayResponse,
  GetLocationDashboardTotalByDomainRequest,
  GetLocationDashboardTotalByDomainResponse,
  GetLocationDashboardsTotalCountByPeriodRequest,
  GetLocationDashboardsTotalCountByPeriodResponse,
  GetLocationDashboardsTotalCountRequest,
  GetLocationDashboardsTotalCountResponse,
  SetLocationDashboardDataRequest,
  TotalCountByDayPeriod,
  TotalCountByDomainPeriod,
  TotalCountByPeriod,
} from './models';

export const GET_LOCATION_DASHBOARD_TOTAL_BY_DAY = 'GET_LOCATION_DASHBOARD_TOTAL_BY_DAY';
export const GET_LOCATION_DASHBOARD_TOTAL_BY_DAY_FAIL = 'GET_LOCATION_DASHBOARD_TOTAL_BY_DAY_FAIL';
export const GET_LOCATION_DASHBOARD_TOTAL_BY_DAY_SUCCESS = 'GET_LOCATION_DASHBOARD_TOTAL_BY_DAY_SUCCESS';

export const GET_LOCATION_DASHBOARD_TOTAL_BY_DOMAIN = 'GET_LOCATION_DASHBOARD_TOTAL_BY_DOMAIN';
export const GET_LOCATION_DASHBOARD_TOTAL_BY_DOMAIN_FAIL = 'GET_LOCATION_DASHBOARD_TOTAL_BY_DOMAIN_FAIL';
export const GET_LOCATION_DASHBOARD_TOTAL_BY_DOMAIN_SUCCESS = 'GET_LOCATION_DASHBOARD_TOTAL_BY_DOMAIN_SUCCESS';

export const GET_LOCATION_DASHBOARDS_TOTAL_COUNT = 'GET_LOCATION_DASHBOARDS_TOTAL_COUNT';
export const GET_LOCATION_DASHBOARDS_TOTAL_COUNT_FAIL = 'GET_LOCATION_DASHBOARDS_TOTAL_COUNT_FAIL';
export const GET_LOCATION_DASHBOARDS_TOTAL_COUNT_SUCCESS = 'GET_LOCATION_DASHBOARDS_TOTAL_COUNT_SUCCESS';

export const GET_LOCATION_DASHBOARDS_TOTAL_COUNT_BY_PERIOD = 'GET_LOCATION_DASHBOARDS_TOTAL_COUNT_BY_PERIOD';
export const GET_LOCATION_DASHBOARDS_TOTAL_COUNT_BY_PERIOD_FAIL = 'GET_LOCATION_DASHBOARDS_TOTAL_COUNT_BY_PERIOD_FAIL';
export const GET_LOCATION_DASHBOARDS_TOTAL_COUNT_BY_PERIOD_SUCCESS = 'GET_LOCATION_DASHBOARDS_TOTAL_COUNT_BY_PERIOD_SUCCESS';

const SET_LOCATION_DASHBOARD_DATA = 'SET_LOCATION_DASHBOARD_DATA';

export interface GetLocationDashboardTotalByDay {
  type: typeof GET_LOCATION_DASHBOARD_TOTAL_BY_DAY;
  payload: ActionPayload<GetLocationDashboardTotalByDayRequest>;
}
export interface GetLocationDashboardTotalByDayFail {
  type: typeof GET_LOCATION_DASHBOARD_TOTAL_BY_DAY_FAIL;
  payload: BaseErrorResponse;
}
export interface GetLocationDashboardTotalByDaySuccess {
  type: typeof GET_LOCATION_DASHBOARD_TOTAL_BY_DAY_SUCCESS;
  payload: { locationId: string; response: BaseResponse<GetLocationDashboardTotalByDayResponse> };
}

export interface GetLocationDashboardTotalByDomain {
  type: typeof GET_LOCATION_DASHBOARD_TOTAL_BY_DOMAIN;
  payload: ActionPayload<GetLocationDashboardTotalByDomainRequest>;
}
export interface GetLocationDashboardTotalByDomainFail {
  type: typeof GET_LOCATION_DASHBOARD_TOTAL_BY_DOMAIN_FAIL;
  payload: BaseErrorResponse;
}
export interface GetLocationDashboardTotalByDomainSuccess {
  type: typeof GET_LOCATION_DASHBOARD_TOTAL_BY_DOMAIN_SUCCESS;
  payload: { locationId: string; response: BaseResponse<GetLocationDashboardTotalByDomainResponse> };
}

export interface GetLocationDashboardsTotalCount {
  type: typeof GET_LOCATION_DASHBOARDS_TOTAL_COUNT;
  payload: ActionPayload<GetLocationDashboardsTotalCountRequest>;
}
interface GetLocationDashboardsTotalCountFail {
  type: typeof GET_LOCATION_DASHBOARDS_TOTAL_COUNT_FAIL;
  payload: BaseErrorResponse;
}
interface GetLocationDashboardsTotalCountSuccess {
  type: typeof GET_LOCATION_DASHBOARDS_TOTAL_COUNT_SUCCESS;
  payload: BaseResponse<GetLocationDashboardsTotalCountResponse>;
}

export interface GetLocationDashboardsTotalCountByPeriod {
  type: typeof GET_LOCATION_DASHBOARDS_TOTAL_COUNT_BY_PERIOD;
  payload: ActionPayload<GetLocationDashboardsTotalCountByPeriodRequest>;
}
export interface GetLocationDashboardsTotalCountByPeriodFail {
  type: typeof GET_LOCATION_DASHBOARDS_TOTAL_COUNT_BY_PERIOD_FAIL;
  payload: BaseErrorResponse;
}
export interface GetLocationDashboardsTotalCountByPeriodSuccess {
  type: typeof GET_LOCATION_DASHBOARDS_TOTAL_COUNT_BY_PERIOD_SUCCESS;
  payload: { locationId: string; response: BaseResponse<GetLocationDashboardsTotalCountByPeriodResponse> };
}

interface SetLocationDashboardData {
  type: typeof SET_LOCATION_DASHBOARD_DATA;
  payload: SetLocationDashboardDataRequest;
}

type Actions =
  | GetLocationDashboardTotalByDay
  | GetLocationDashboardTotalByDayFail
  | GetLocationDashboardTotalByDaySuccess
  | GetLocationDashboardTotalByDomain
  | GetLocationDashboardTotalByDomainFail
  | GetLocationDashboardTotalByDomainSuccess
  | GetLocationDashboardsTotalCount
  | GetLocationDashboardsTotalCountFail
  | GetLocationDashboardsTotalCountSuccess
  | GetLocationDashboardsTotalCountByPeriod
  | GetLocationDashboardsTotalCountByPeriodFail
  | GetLocationDashboardsTotalCountByPeriodSuccess
  | SetLocationDashboardData;

export interface State {
  error: string;
  loading: boolean;
  selectedPeriod: DashboardsTotalCountByPeriodPeriods;
  totalCounts: DashboardsTotalCount;
  totalCountByDay: { [locationId: string]: Array<TotalCountByDayPeriod> };
  totalCountByDomain: { [locationId: string]: Array<TotalCountByDomainPeriod> };
  totalCountByPeriod: { [locationId: string]: Array<TotalCountByPeriod> };
}

const initialState: State = {
  error: '',
  loading: false,
  selectedPeriod: 'month',
  totalCounts: {
    cancelled: 0,
    total: 0,
    unique: 0,
  },
  totalCountByDay: {},
  totalCountByDomain: {},
  totalCountByPeriod: {},
};

export default function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case GET_LOCATION_DASHBOARD_TOTAL_BY_DAY: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }
    case GET_LOCATION_DASHBOARD_TOTAL_BY_DAY_FAIL: {
      return {
        ...state,
        error: t`There was an error getting the location dashboards total by day. Please try again.`,
        loading: false,
      };
    }
    case GET_LOCATION_DASHBOARD_TOTAL_BY_DAY_SUCCESS: {
      return {
        ...state,
        error: '',
        loading: false,
        totalCountByDay: {
          ...state.totalCountByDay,
          [action.payload.locationId]: action.payload.response.data.result.data,
        },
      };
    }

    case GET_LOCATION_DASHBOARD_TOTAL_BY_DOMAIN: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }
    case GET_LOCATION_DASHBOARD_TOTAL_BY_DOMAIN_FAIL: {
      return {
        ...state,
        error: t`There was an error getting the location dashboards total by domain. Please try again.`,
        loading: false,
      };
    }
    case GET_LOCATION_DASHBOARD_TOTAL_BY_DOMAIN_SUCCESS: {
      return {
        ...state,
        error: '',
        loading: false,
        totalCountByDomain: {
          ...state.totalCountByDomain,
          [action.payload.locationId]: action.payload.response.data.result.data,
        },
      };
    }

    case GET_LOCATION_DASHBOARDS_TOTAL_COUNT: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }
    case GET_LOCATION_DASHBOARDS_TOTAL_COUNT_FAIL: {
      return {
        ...state,
        error: t`There was an error getting the location dashboards total count. Please try again.`,
        loading: false,
      };
    }
    case GET_LOCATION_DASHBOARDS_TOTAL_COUNT_SUCCESS: {
      return {
        ...state,
        error: '',
        loading: false,
        totalCounts: {
          ...action.payload.data.result.data,
        },
      };
    }

    case GET_LOCATION_DASHBOARDS_TOTAL_COUNT_BY_PERIOD: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }
    case GET_LOCATION_DASHBOARDS_TOTAL_COUNT_BY_PERIOD_FAIL: {
      return {
        ...state,
        error: t`There was an error getting the location dashboards total count by period. Please try again.`,
        loading: false,
      };
    }
    case GET_LOCATION_DASHBOARDS_TOTAL_COUNT_BY_PERIOD_SUCCESS: {
      return {
        ...state,
        error: '',
        loading: false,
        totalCountByPeriod: {
          ...state.totalCountByPeriod,
          [action.payload.locationId]: action.payload.response.data.result.data,
        },
      };
    }

    case SET_LOCATION_DASHBOARD_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
}

// Actions
export function getLocationDashboardTotalByDay(data: GetLocationDashboardTotalByDayRequest): GetLocationDashboardTotalByDay {
  return {
    type: GET_LOCATION_DASHBOARD_TOTAL_BY_DAY,
    payload: {
      request: {
        method: 'GET',
        url: `/api/bookings/${data.locationId}/period/${data.period}/dashboards/totalByDay`,
      },
    },
  };
}

export function getLocationDashboardTotalByDomain(data: GetLocationDashboardTotalByDomainRequest): GetLocationDashboardTotalByDomain {
  return {
    type: GET_LOCATION_DASHBOARD_TOTAL_BY_DOMAIN,
    payload: {
      request: {
        method: 'GET',
        url: `/api/bookings/${data.locationId}/period/${data.period}/dashboards/totalByDomain`,
      },
    },
  };
}

export function getLocationDashboardsTotalCount(data: GetLocationDashboardsTotalCountRequest): GetLocationDashboardsTotalCount {
  return {
    type: GET_LOCATION_DASHBOARDS_TOTAL_COUNT,
    payload: {
      request: {
        method: 'GET',
        url: `/api/bookings/${data.locationId}/dashboards/totalCount`,
      },
    },
  };
}

export function getLocationDashboardsTotalCountByPeriod(data: GetLocationDashboardsTotalCountByPeriodRequest): GetLocationDashboardsTotalCountByPeriod {
  return {
    type: GET_LOCATION_DASHBOARDS_TOTAL_COUNT_BY_PERIOD,
    payload: {
      request: {
        method: 'GET',
        url: `/api/bookings/${data.locationId}/period/${data.period}/dashboards/totalCountByPeriod`,
      },
    },
  };
}

export function setLocationDashboardData(data: SetLocationDashboardDataRequest): SetLocationDashboardData {
  return {
    type: SET_LOCATION_DASHBOARD_DATA,
    payload: data,
  };
}

// selectors
/**
 * Select domains sorted by domain name length, longest to shortest
 */
export function selectDomainsSortedByDomainNameLength(state: State, locationId: string): Array<TotalCountByDomainPeriod> {
  const domains = state.totalCountByDomain[locationId] ? [...state.totalCountByDomain[locationId]] : [];

  return domains.sort((a, b) => {
    if (a.domain.length > b.domain.length) {
      return -1;
    } else if (a.domain.length < b.domain.length) {
      return 1;
    } else {
      return 0;
    }
  });
}

/**
 * Returns an array of 7 days in the week
 * 
 * API doesn't return all days, it returns only days that have reservations,
 * so we compensate this by creating empty days for the ones that API doesn't return.
 */
export function selectTotalByDayPeriod(state: State, locationId: string): Array<TotalCountByDayPeriod> {
  const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  const locationCounts = state.totalCountByDay[locationId];

  const counts: Array<TotalCountByDayPeriod> = days.map(day => {
    const locationCount = locationCounts?.find(lc => lc.day === day);

    return {
      day: locationCount?.day.substring(0, 3) ?? day.substring(0, 3),
      dayDate: locationCount?.dayDate ?? null,
      booked: locationCount?.booked ?? 0,
      cancelled: locationCount?.cancelled ?? 0,
    };
  });

  return counts;
}
