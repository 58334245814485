import React, { useEffect, useState } from "react";
import { NewsFeedItemProps } from "./types";
import { Box, Button, ButtonProps, Dialog, Divider, FilledInput, Skeleton, Typography, useTheme } from "@mui/material";
import { formatDistance, parseEmoji } from "utils";
import { t } from "@lingui/macro";
import { AddReactionOutlined, ChatOutlined, KeyboardArrowRightRounded, KeyboardArrowUpRounded, SendOutlined } from "@mui/icons-material";
import { PostInteractionType, PostScheduleType, TrackingEventType, User, useDeletePostReactionByIdMutation, useGetPostSharesQuery, useLazyGetPostByIdQuery, useSavePostSharesMutation, useSetPostReactionMutation, useTrackMutation } from "store";
import { NewsFeedItemContent } from "./news-feed-item-content";
import { NewsFeedItemComments } from "./news-feed-item-comments";
import { EmojiPopover, EmojiPopoverProps } from "components/popover";
import { useToast } from "components/toast-provider";
import { Link } from "react-router-dom";
import { isBefore } from "date-fns";
import { UsersSelect } from "components/select";
import { LoadingButton } from "@mui/lab";
import { AttachmentImage } from "components/attachments";

export const NewsFeedItem: React.FC<NewsFeedItemProps> = (props) => {
  const { post, mb } = props;
  const { palette } = useTheme();
  const { author } = post;
  const toast = useToast();
  const [readMore, setReadMore] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [emojiPopoverAnchorEl, setEmojiPopoverAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [showShareDialog, setShowShareDialog] = useState(false);
  const [sharedUsers, setSharedUsers] = useState<User[]>([]);
  const [triggerGetPostByIdQuery, getPostByIdQuery] = useLazyGetPostByIdQuery();
  const [setPostReaction, { isLoading: isSettingReaction }] = useSetPostReactionMutation();
  const [deletePostReaction, { isLoading: isDeletingReaction }] = useDeletePostReactionByIdMutation();
  const [savePostShares, { isLoading: isSavingPostShares }] = useSavePostSharesMutation();
  const [track] = useTrackMutation();
  const { data: getPostSharesResponse, isLoading: isLoadingPostShares } = useGetPostSharesQuery(
    { postId: post.id, include: ["sharer", "user"] },
    { skip: !showShareDialog },
  );

  useEffect(() => {
    if (readMore) {
      triggerGetPostByIdQuery({ postId: post.id }, true);
      track({ type: TrackingEventType.VIEW, postId: post.id });
    }
  }, [readMore]);

  const { items: shares } = getPostSharesResponse || {};

  useEffect(() => {
    if (!shares) {
      return;
    }

    const sharedUsers: User[] = [];

    for (const { user } of shares) {
      if (user) {
        sharedUsers.push(user);
      }
    }

    setSharedUsers(sharedUsers);
  }, [JSON.stringify(shares)]);

  const myReaction = post.$extra?.myReaction;

  const handleEmojiPopoverChange: EmojiPopoverProps["onChange"] = (_, code) => {
    setEmojiPopoverAnchorEl(null);

    if (isSettingReaction || isDeletingReaction) {
      return;
    }

    void (async () => {
      const response = myReaction?.code === code
        ? await deletePostReaction({ postId: post.id, postReactionId: myReaction.id })
        : await setPostReaction({ postId: post.id, code });

      if ("error" in response) {
        toast.showToast({ severity: "error", message: t`Failed to set reaction, please try again` });
      }
    })();
  };

  const handleShareSaveClick: ButtonProps["onClick"] = (event) => {
    event.preventDefault();

    if (isSavingPostShares) {
      return;
    }

    void (async () => {
      const response = await savePostShares({ postId: post.id, usersIds: sharedUsers.map(({ id }) => id) });

      if ("error" in response) {
        toast.showToast({ severity: "error", message: t`Failed to share post, please try again.` });
      } else {
        toast.showToast({ severity: "success", message: t`Post was shared.` });
      }
    })();
  };

  const postUrl = `${window.location.origin}/posts/${post.id}`;

  const handleURLCopyClick: ButtonProps["onClick"] = (event) => {
    event.preventDefault();

    void (async () => {
      await navigator.clipboard.writeText(postUrl);

      toast.showToast({ severity: "success", message: t`Share link copied.` });
    })();
  };

  const { data: getPostByIdResponse, isLoading } = getPostByIdQuery;
  const { body: content } = getPostByIdResponse || {};
  const publishSchedule = post.schedules?.find(({ type }) => type === PostScheduleType.PUBLISH);

  return (
    <>
      <Box bgcolor={palette.grey[100]} borderRadius={2} mb={mb} p={2}>
        <Box display="flex" gap={1} mb={1}>
          <Typography fontSize={14} fontWeight={600}>{author?.name}</Typography>
          <Typography color={palette.grey[700]} fontSize={14}>{formatDistance(new Date(publishSchedule?.doneAt || post.createdAt))}</Typography>
        </Box>
        {post.thumbnail?.id ? <AttachmentImage attachmentId={post.thumbnail.id} borderRadius={2} height="auto" mb={2} width="100%" /> : undefined}
        <Typography component={Link} fontWeight={600} mb={1} to={`/posts/${post.id}`}>{post.title}</Typography>
        {readMore ? (
          isLoading ? (
            <Box mb={2}>
              <Skeleton height={16} sx={{ bgcolor: palette.grey[200], mb: 0.5 }} variant="rectangular" width="100%" />
              <Skeleton height={16} sx={{ bgcolor: palette.grey[200], mb: 0.5 }} variant="rectangular" width="100%" />
              <Skeleton height={16} sx={{ bgcolor: palette.grey[200] }} variant="rectangular" width="100%" />
            </Box>
          ) : (
            <Box mb={2}>
              <NewsFeedItemContent content={content} disableTracking={!post?.tracks?.includes(TrackingEventType.CLICK)} postId={post.id} />
              {post.contentUpdatedAt ? (
                <Typography color={palette.grey[700]} fontSize={14} fontStyle="italic" mt={1}>
                  {t`Last updated at ${formatDistance(new Date(post.contentUpdatedAt))}.`}
                </Typography>
              ) : undefined}
            </Box>
          )
        ) : (
          <Typography color={palette.grey[700]} fontSize={14} mb={2}>{post.body}</Typography>
        )}
        <Box alignItems="center" display="flex" justifyContent="space-between">
          <Box alignItems="center" display="flex" gap={2}>
            {post.$extra?.reactionsCount ? (
              <Box alignItems="center" display="flex" gap={1}>
                {post?.$extra?.reactionsCodes?.map((code) => <Typography component="span" fontSize={14} key={code}>{parseEmoji(code)}</Typography>)}
                <Typography color={palette.grey[700]} fontSize={14}>{post.$extra.reactionsCount}</Typography>
              </Box>
            ) : undefined}
            {post.$extra?.commentsCount ? (
              <Typography color={palette.grey[700]} fontSize={14}>{t`Comments`}{` (${post.$extra.commentsCount})`}</Typography>
            ) : undefined}
          </Box>
          {readMore ? (
            <Button endIcon={<KeyboardArrowUpRounded />} onClick={() => setReadMore(false)} size="small">{t`Show less`}</Button>
          ) : (
            <Button endIcon={<KeyboardArrowRightRounded />} onClick={() => setReadMore(true)} size="small">{t`Read more`}</Button>
          )}
        </Box>
        {!!post.allows?.length ? (
          <>
            <Divider sx={{ my: 1, borderColor: palette.grey[200] }} />
            <Box display="flex" gap={2}>
              {post.allows?.includes(PostInteractionType.REACT) ? (
                <Button
                  onClick={(event) => setEmojiPopoverAnchorEl(event.currentTarget)}
                  size="small"
                  startIcon={myReaction?.code ? parseEmoji(myReaction.code) : <AddReactionOutlined />}
                >
                  {t`Like`}
                </Button>  
              ) : undefined}
              {post.allows?.includes(PostInteractionType.COMMENT) ? (
                <Button onClick={() => setShowComments(true)} size="small" startIcon={<ChatOutlined />}>{t`Comment`}</Button>
              ) : undefined}
              {post.allows?.includes(PostInteractionType.SHARE) ? (
                <Button onClick={() => setShowShareDialog(true)} size="small" startIcon={<SendOutlined />}>{t`Share`}</Button>
              ) : undefined}
            </Box>
            <NewsFeedItemComments
              disableCommenting={!post.allows?.includes(PostInteractionType.COMMENT) || (!!post.disableCommentsAt && isBefore(new Date(post.disableCommentsAt), new Date()))}
              open={showComments}
              postId={post.id}
            />
          </>
        ) : undefined}
      </Box>
      <EmojiPopover
        anchorEl={emojiPopoverAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        onChange={handleEmojiPopoverChange}
        onClose={() => setEmojiPopoverAnchorEl(null)}
        open={!!emojiPopoverAnchorEl}
        transformOrigin={{ vertical: "bottom", horizontal: "left" }}
        value={myReaction?.code}
      />
      <Dialog onClose={() => setShowShareDialog(false)} open={showShareDialog}>
        <Box p={2} width={480}>
          <Typography fontSize={18} fontWeight={600} mb={1}>{t`Share post`}</Typography>
          <Typography fontSize={14} mb={1}>{t`Authorized users`}</Typography>
          {isLoadingPostShares ? (
            <Skeleton height={42} variant="rectangular" width="100%" />
          ) : (
            <UsersSelect onChange={setSharedUsers} placeholder={!sharedUsers?.length ? t`Search by name, email` : undefined} value={sharedUsers} />
          )}
          <Box display="flex" justifyContent="flex-end" mb={2} mt={1}>
            <LoadingButton disabled={isLoadingPostShares} loading={isSavingPostShares} onClick={handleShareSaveClick} variant="contained">
              {t`Submit`}
            </LoadingButton>
          </Box>
          <Typography fontSize={14} mb={1}>{t`Share link (only authorized users can see)`}</Typography>
          <FilledInput
            endAdornment={<Button onClick={handleURLCopyClick}>{t`Copy`}</Button>}
            fullWidth
            readOnly
            sx={{ pr: 0, "& .MuiInputBase-input": { color: palette.grey[700], fontSize: 14 } }}
            value={postUrl}
          />
        </Box>
      </Dialog>
    </>
  );
};
