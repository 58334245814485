import { t } from "@lingui/macro";
import { Box, Button, FilledInput, Typography, useTheme } from "@mui/material";
import { Tab, Tabs } from "../tabs";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Search } from "@mui/icons-material";
import React from "react";
import { useDispatch } from "react-redux";
import { useDebouncedCallback } from "hooks";
import { AdminStateCampaignsQuery, adminSlice } from "store";

export const CampaignsWrapper: React.FC<React.PropsWithChildren<{}>> = (props) => {
  const { children } = props;
  const dispatch = useDispatch();
  const { palette, background } = useTheme();
  const history = useHistory();
  const { pathname } = useLocation();
  const debouncedSetAdminCampaignsQuery = useDebouncedCallback(
    (payload: Partial<AdminStateCampaignsQuery>) => dispatch(adminSlice.actions.setCampaignsQuery(payload)),
    [dispatch],
    700,
  );

  const resolveTabsValue = () => {
    if (/^\/admin\/campaigns\/templates\/?$/.test(pathname)) {
      return 3;
    } else if (/^\/admin\/campaigns\/active\/?$/.test(pathname)) {
      return 2;
    } else if (/^\/admin\/campaigns\/pending\/?$/.test(pathname)) {
      return 1;
    }

    return 0;
  };

  return (
    <Box py={2}>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Typography fontSize={22} fontWeight={600}>{t`Campaigns`}</Typography>
        <Box display="flex" gap={2}>
          <FilledInput
            onChange={(event) => debouncedSetAdminCampaignsQuery({ search: event.currentTarget.value })}
            placeholder={t`Search`}
            startAdornment={<Search sx={{ color: palette.grey[700] }} />}
          />
          <Button component={Link} to="/admin/campaigns/policies" variant="contained">{t`Policy settings`}</Button>
          <Button component={Link} to="/admin/campaigns/new?reset=true" variant="contained">{t`Create Campaign`}</Button>
        </Box>
      </Box>
      <Tabs inline sx={{ padding: 0, bgcolor: "#fff", mb: 2 }} value={resolveTabsValue()}>
        <Tab
          data-cid="new-button"
          label={t`New`}
          onClick={() => history.push("/admin/campaigns")}
          sx={{ color: "#000", "&.Mui-selected": { bgcolor: background.blue.main } }}
        />
        <Tab
          data-cid="needs-approval-button"
          label={t`Needs approval`}
          onClick={() => history.push("/admin/campaigns/pending")}
          sx={{ color: "#000", "&.Mui-selected": { bgcolor: background.blue.main } }}
        />
        <Tab
          data-cid="active-button"
          label={t`Active`}
          onClick={() => history.push("/admin/campaigns/active")}
          sx={{ color: "#000", "&.Mui-selected": { bgcolor: background.blue.main } }}
        />
        <Tab
          data-cid="templates-button"
          label={t`Templates`}
          onClick={() => history.push("/admin/campaigns/templates")}
          sx={{ color: "#000", "&.Mui-selected": { bgcolor: background.blue.main } }}
        />
      </Tabs>
      {children}
    </Box>
  );
};
