import { TextField } from "@material-ui/core";
import { Trans, t } from "@lingui/macro";
import styles from "../../../Locations/LocationForm/styles.module.scss";

interface Props {
  selectedValue: string;
  setErrorClass: Function;
  setSelectedValue: Function;
  errorClass: string;
}

export default function Name(props: Props) {
  const { selectedValue, setSelectedValue, errorClass, setErrorClass } = props;

  const onNameChange = (event: any) => {
    setSelectedValue(event.target.value);

    if (event.target.value) {
      setErrorClass(false);
    }
  };

  return (
    <>
      <span className={styles.requiredSign}>*</span><label htmlFor="room-name"><Trans>Room name</Trans></label>
      <TextField
        className={`input input--default input--inline ${errorClass}`}
        defaultValue={selectedValue}
        fullWidth
        id="room-name"
        onChange={onNameChange}
        placeholder={t`Type here`}
        variant="outlined"
      />
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      {errorClass ? <div className={styles.formErrors}><Trans>This field can't be empty</Trans></div> : null}
    </>
  );
}
