import { t } from "@lingui/macro";
import { DownloadRounded } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Divider, Grid, List, ListItem, ListItemButton, Pagination, Skeleton, Switch, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTypedSelector } from "store/_legacy/Redux/store";
import axios from "axios";
import { IconBox, MeetingsWrapper, MonthlyCalendar, OrderButton, TogetherDateAndTime, useToast } from "components";
import { ReservationCateringDetailsDialog } from "components/reservation-details";
import { endOfDay, format, isSameDay, isSameMonth, startOfDay } from "date-fns";
import { UserRole } from "enums";
import FileSaver from "file-saver";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetReservationsInput, ReservationServiceType, ReservationType, adminSlice, parseObjectQueryParam, parsePaginationQueryParams, selectAdminMeetingsQuery, useGetMeQuery, useLazyGetReservationsQuery } from "store";
import { Order } from "types";
import { resolveReservationStatusColor } from "utils/resolve-reservation-status-color";
import { resolveReservationStatusLabel } from "utils/resolve-reservation-status-label";

export const MeetingsListView: React.FC = () => {
  const { palette, breakpoints } = useTheme();
  const dispatch = useDispatch();
  const xl = useMediaQuery(breakpoints.up("xl"));
  const query = useSelector(selectAdminMeetingsQuery);
  const authorization = useTypedSelector(({ login }) => login.token);
  const toast = useToast();
  const [detailsDialogIsOpen, setDetailsDialogIsOpen] = useState(false);
  const [reservationId, setReservationId] = useState<string>();
  const [entryId, setEntryId] = useState<string>();
  const [page, setPage] = useState(1);
  const [columnOrder, setColumnOrder] = useState<string>();
  const [dateOrder, setDateOrder] = useState<Order>("desc");
  const [isExporting, setIsExporting] = useState(false);
  const [triggerGetReservationsQuery, getReservationsQuery] = useLazyGetReservationsQuery();
  const getMeQuery = useGetMeQuery();
  const { data: user } = getMeQuery?.data?.result || {};

  const resolveGetReservationsInput = (): GetReservationsInput | undefined => {
    if (!user) {
      return;
    }

    const range = query?.range?.map((value) => new Date(value)) || [new Date()];
    const startDate = startOfDay(range[0]).toISOString();
    const endDate = endOfDay(range.length === 1 ? range[0] : range[1]).toISOString();
    const filter: Record<string, string> = {
      type: ReservationType.ROOM,
      "schedule.entries.startDate": `$btw:${startDate},${endDate}`,
    };

    if (query?.requestedServices?.length) {
      filter.requestedServices = `$cts:${query.requestedServices.join(",")}`;
    }

    if (query?.statuses?.length) {
      filter.status = `$in:${query?.statuses?.join(",")}`;
    }

    if (query?.roomIds?.length) {
      filter["roomId"] = `$in:${query.roomIds.map(([, roomId]) => roomId).join(",")}`;
    } else if (query?.floorIds?.length) {
      filter["floor.id"] = `$in:${query.floorIds.map(([, floorId]) => floorId).join(",")}`;
    } else if (query?.locationIds?.length) {
      filter["floor.location.id"] = `$in:${query.locationIds.join(",")}`;
    } else if (user.role && [UserRole.LOCAL_ADMIN, UserRole.CATERING_ADMIN].includes(user.role)) {
      filter["floor.location.id"] = `$in:${user.locationIds?.join(",")}`;
    }

    const orderBy: string[] = [];

    if (columnOrder) {
      orderBy.push(columnOrder);
    }

    orderBy.push(`${dateOrder}:schedule.entries.startDate`);

    return {
      filter,
      page,
      orderBy: orderBy.length ? orderBy : undefined,
      limit: 15,
      search: query?.search,
      singleEntryListing: true,
      include: ["schedule", "schedule.entries", "user", "room", "floor", "floor.location", "attendees"],
    };
  };

  useEffect(() => {
    const input = resolveGetReservationsInput();

    if (!input) {
      return;
    }

    triggerGetReservationsQuery(input, true);
  }, [page, JSON.stringify(query), JSON.stringify(user), columnOrder, dateOrder]);

  const {
    data: getReservationsResponse,
    isLoading: reservationsAreLoading,
    isFetching: reservationsAreFetching,
    isUninitialized,
  } = getReservationsQuery;
  const { items: reservations, meta } = getReservationsResponse?.result?.data || {};
  const isLoading = reservationsAreLoading || reservationsAreFetching || isUninitialized;
  
  const handleListItemClick = (reservationId: string, entryId: string) => {
    setReservationId(reservationId);
    setEntryId(entryId);
    setDetailsDialogIsOpen(true);
  };

  const handleRangeChange = (value?: Date[]) => {
    dispatch(adminSlice.actions.setMeetingsQuery({ range: value?.map((value) => value.toISOString()) }));
  };

  const handleColumnOrderChange = (order: Order, column: string) => {
    const [currentOrder, currentColumn] = columnOrder?.split(":") || [];
    
    if (order === currentOrder && column === currentColumn) {
      setColumnOrder(undefined);
    } else {
      setColumnOrder(`${order}:${column}`);
    }
  };

  const handleExportClick = () => {
    const input = resolveGetReservationsInput();

    if (!authorization || !input) {
      return;
    }

    setIsExporting(true);

    void (async () => {
      const { singleEntryListing, ...params } = input;
      
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/reservations/export`, {
          headers: { authorization: `Bearer ${authorization}` },
          responseType: "blob",
          params: { ...parsePaginationQueryParams(params), ...parseObjectQueryParam("custom", { singleEntryListing }) },
        });

        FileSaver.saveAs(response.data, `meetings-${format(new Date(), "yyyyMMddHHmmssSSS")}.xlsx`);
      } catch {
        toast.showToast({ severity: "error", message: t`Failed to export meetings, please try again.` });
      }

      setIsExporting(false);
    })();
  };

  const range = query?.range?.map((value) => new Date(value)) || [new Date()];
  const column = {
    location: { title: t`Location`, width: xl ? 120 : 76 },
    floor: { title: t`Floor`, width: xl ? 88 : 64 },
    owner: { title: t`Owner`, width: xl ? 124 : 88 },
    room: { title: t`Room` },
    date: { title: t`Date`, width: xl ? 118 : 78 },
    start: { title: t`Start`, width: xl ? 78 : 54 },
    finish: { title: t`Finish`, width: xl ? 78 : 54 },
    status: { title: t`Status`, width: xl ? 86 : 62 },
    attendees: { title: t`Attendees`, width: xl ? 78 : 64 },
    catering: { title: t`Catering`, width: xl ? 80 : 54 },
    itSupport: { title: t`IT Support`, width: xl ? 80 : 64 },
  };
  const fontSize = { header: xl ? 13 : 12, body: xl ? 14 : 12 };

  const resolveColumnOrder = (column: string): Order | undefined => {
    if (!columnOrder) {
      return;
    }

    if (!columnOrder.includes(column)) {
      return;
    }

    return columnOrder.startsWith("asc") ? "asc" : "desc";
  };

  return (
    <>
      <MeetingsWrapper>
        <Grid columns={20} container width="100%">
          <Grid alignItems="stretch" display="flex" flexDirection="column" item xl={15} xs={14}>
            <Box alignItems="center" bgcolor={palette.grey[100]} borderRadius={1} display="flex" gap={1} px={1} py={0.5}>
              <OrderButton
                onOrderChange={(order) => handleColumnOrderChange(order, "floor.location.locationName")}
                order={resolveColumnOrder("floor.location.locationName")}
                sx={{ width: column.location.width, justifyContent: "space-between" }}
              >
                <Typography
                  color={palette.grey[700]}
                  fontSize={fontSize.header}
                  title={column.location.title}
                >
                  {column.location.title}
                </Typography>
              </OrderButton>
              <OrderButton
                onOrderChange={(order) => handleColumnOrderChange(order, "floor.floorName")}
                order={resolveColumnOrder("floor.floorName")}
                sx={{ width: column.floor.width, justifyContent: "space-between" }}
              >
                <Typography
                  color={palette.grey[700]}
                  fontSize={fontSize.header}
                  title={column.floor.title}
                >
                  {column.floor.title}
                </Typography>
              </OrderButton>
              <OrderButton
                onOrderChange={(order) => handleColumnOrderChange(order, "user.name")}
                order={resolveColumnOrder("user.name")}
                sx={{ width: column.owner.width, justifyContent: "space-between" }}
              >
                <Typography
                  color={palette.grey[700]}
                  fontSize={fontSize.header}
                  title={column.owner.title}
                >
                  {column.owner.title}
                </Typography>
              </OrderButton>
              <OrderButton
                onOrderChange={(order) => handleColumnOrderChange(order, "roomId")}
                order={resolveColumnOrder("roomId")}
                sx={{ flex: 1.5, justifyContent: "space-between" }}
              >
                <Typography
                  color={palette.grey[700]}
                  fontSize={fontSize.header}
                  title={column.room.title}
                >
                  {column.room.title}
                </Typography>
              </OrderButton>
              <OrderButton onOrderChange={setDateOrder} order={dateOrder} sx={{ width: column.date.width, justifyContent: "space-between" }}>
                <Typography
                  color={palette.grey[700]}
                  fontSize={fontSize.header}
                  title={column.date.title}
                >
                  {column.date.title}
                </Typography>
              </OrderButton>
              <Typography
                color={palette.grey[700]}
                fontSize={fontSize.header}
                title={column.start.title}
                width={column.start.width}
              >
                {column.start.title}
              </Typography>
              <Typography
                color={palette.grey[700]}
                fontSize={fontSize.header}
                title={column.finish.title}
                width={column.finish.width}
              >
                {column.finish.title}
              </Typography>
              <OrderButton
                onOrderChange={(order) => handleColumnOrderChange(order, "status")}
                order={resolveColumnOrder("status")}
                sx={{ width: column.status.width, justifyContent: "space-between" }}
              >
                <Typography
                  color={palette.grey[700]}
                  fontSize={fontSize.header}
                  title={column.status.title}
                >
                  {column.status.title}
                </Typography>
              </OrderButton>
              <Typography
                color={palette.grey[700]}
                fontSize={fontSize.header}
                title={column.attendees.title}
                width={column.attendees.width}
              >
                {column.attendees.title}
              </Typography>
              <Typography
                color={palette.grey[700]}
                fontSize={fontSize.header}
                title={column.catering.title}
                width={column.catering.width}
              >
                {column.catering.title}
              </Typography>
              <Typography
                color={palette.grey[700]}
                fontSize={fontSize.header}
                title={column.itSupport.title}
                width={column.itSupport.width}
              >
                {column.itSupport.title}
              </Typography>
            </Box>
            {isLoading ? (
              <Box mb={2} minHeight={xl ? 690 : 645}>
                <Skeleton height={45} sx={{ borderRadius: 0 }} variant="rectangular" />
                <Divider sx={{ opacity: 0.6 }} />
                <Skeleton height={45} sx={{ borderRadius: 0 }} variant="rectangular" />
                <Divider sx={{ opacity: 0.6 }} />
                <Skeleton height={45} sx={{ borderRadius: 0 }} variant="rectangular" />
                <Divider sx={{ opacity: 0.6 }} />
                <Skeleton height={45} sx={{ borderRadius: 0 }} variant="rectangular" />
                <Divider sx={{ opacity: 0.6 }} />
                <Skeleton height={45} sx={{ borderRadius: 0 }} variant="rectangular" />
              </Box>
            ) : reservations?.length ? (
              <List disablePadding sx={{ mb: 2 }}>
                {reservations?.map(({ id, status, schedule, floor, user, room, attendees, externalAttendees, requestedServices }) => {
                  const [entry] = schedule?.entries || [];
                  const attendeesCount = (attendees?.length || 0) + (externalAttendees?.length || 0);

                  return (
                    <ListItem disablePadding divider key={`${id}:${entry.id}`} sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.05)" }}>
                      <ListItemButton onClick={() => handleListItemClick(id, entry.id)} sx={{ px: 1, py: 1.5, gap: 1, flex: 1 }}>
                        <Typography fontSize={fontSize.body} noWrap title={floor?.location?.name} width={column.location.width}>{floor?.location?.name}</Typography>
                        <Typography fontSize={fontSize.body} noWrap title={floor?.name} width={column.floor.width}>{floor?.name}</Typography>
                        <Typography fontSize={fontSize.body} noWrap title={user?.name} width={column.owner.width}>{user?.name}</Typography>
                        <Typography flex={1.5} fontSize={fontSize.body} noWrap title={room?.name}>{room?.name}</Typography>
                        <Typography fontSize={fontSize.body} noWrap width={column.date.width}>
                          {format(new Date(entry.startDate), "LLL dd, yyyy")}
                        </Typography>
                        <Typography fontSize={fontSize.body} noWrap width={column.start.width}>
                          {format(new Date(entry.startDate), "HH:mm")}
                        </Typography>
                        <Typography fontSize={fontSize.body} noWrap width={column.finish.width}>
                          {format(new Date(entry.endDate), "HH:mm")}
                        </Typography>
                        <Typography
                          color={resolveReservationStatusColor(status)}
                          fontSize={fontSize.body}
                          noWrap
                          textTransform="capitalize"
                          width={column.status.width}
                        >
                          {resolveReservationStatusLabel(status)}
                        </Typography>
                        <Typography fontSize={fontSize.body} noWrap width={column.attendees.width}>{attendeesCount}{room?.capacity ? `/${room.capacity}` : ""}</Typography>
                        <Box display="flex" width={column.catering.width}>
                          <Switch checked={!!requestedServices?.includes(ReservationServiceType.CATERING)} disabled size="small" />
                        </Box>
                        <Box display="flex" width={column.itSupport.width}>
                          <Switch checked={!!requestedServices?.includes(ReservationServiceType.IT_SUPPORT)} disabled size="small" />
                        </Box>
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            ) : (
              <Typography color={palette.grey[700]} fontSize={14} my={4} textAlign="center">
                {t`No reservations found.`}
              </Typography>
            )}
            {(meta?.pages || 0) > 1 ? (
              <Pagination count={meta?.pages} onChange={(_, page) => setPage(page)} page={page} shape="rounded" />
            ) : undefined}
          </Grid>
          <Grid item pl={xl ? 4 : 2} xl={5} xs={6}>
            <Box display="flex" justifyContent="space-between">
              <Box alignItems="center" display="flex" gap={1}>
                <IconBox>
                  <TogetherDateAndTime fill={palette.grey[700]} sx={{ width: 16 }} />
                </IconBox>
                <Typography fontSize={16} fontWeight={600} lineHeight={1}>
                  {range ? format(range[0], "LLL, d") : ""}
                  {range?.length === 2 && !isSameDay(range[0], range[1]) && isSameMonth(range[0], range[1]) ? "-" + format(range[1], "d") : ""}
                  {range?.length === 2 && !isSameDay(range[0], range[1]) && !isSameMonth(range[0], range[1]) ? " - " + format(range[1], "LLL, d") : ""}
                </Typography>
              </Box>
              <Button onClick={() => handleRangeChange([new Date()])} size="small" variant="text">{t`Today`}</Button>
            </Box>
            <Divider sx={{ my: 2 }} />
            <MonthlyCalendar  onChange={handleRangeChange} selectionType="range" value={range} width="auto" />
            <LoadingButton
              disableElevation
              endIcon={<DownloadRounded />}
              loading={isExporting}
              onClick={() => handleExportClick()}
              sx={{ width: "100%", justifyContent: "space-between", mt: 2 }}
            >
              {t`Export meetings to Excel`}
            </LoadingButton>
          </Grid>
        </Grid>
      </MeetingsWrapper>
      <ReservationCateringDetailsDialog
        entryId={entryId}
        onClose={() => setDetailsDialogIsOpen(false)}
        open={detailsDialogIsOpen}
        reservationId={reservationId}
      />
    </>
  );
};
