import {useDispatch} from "react-redux";
import {useTypedSelector} from "store/_legacy/Redux/store";
import {Autocomplete} from "@material-ui/lab";
import {t} from "@lingui/macro";
import styles from "./styles.module.scss";
import arrowIcon from 'Admin/Pages/Locations/LocationForm/assets/arrow-down.svg';
import Box from "components/_legacy/Box";
import {TextField} from "@material-ui/core";
import {getUsersFromAD, setUserManagementData} from "Admin/Store/userManagement";
import {AutocompleteInputChangeReason} from "@material-ui/lab/useAutocomplete/useAutocomplete";
import {UserAdModel} from "Admin/Store/userManagement/models";

interface EmailSearchingProps {
  selectedUserError: boolean;
  setSelectedUser: Function,
  setSelectedUserError: Function,
}

export default function EmailSearching(props: EmailSearchingProps) {
  const dispatch = useDispatch();
  const { setSelectedUser, selectedUserError, setSelectedUserError } = props;
  const { availableUsersAd } = useTypedSelector(state => state.adminUserManagement);

  const selectedUsersErrorClass = selectedUserError ? styles.formErrorShow : '';

  const onUserChange = (event: any, user: UserAdModel) => {
    if (user !== null) {
      setSelectedUserError(false);
    }

    return setSelectedUser(user);
  };

  const onResetSearchState = () => {
    dispatch(setUserManagementData({
      availableUsersAd: [],
      availableUsersAdTotalCount: 0,
    }));
  };

  const onSearchChange = (event: any, value: string, reason: AutocompleteInputChangeReason) => {
    const search = event.target.value;

    if (reason !== 'reset' && reason !== 'clear') { // 'reset' fires on select from list, no need to handle api
      dispatch(getUsersFromAD({ limit: 10, search }));
    }
  };

  return (
    <Box display="flex" justifyContent="between" marginTop={10}>
        <Box direction="column" display="flex" width="100%">
          <Autocomplete
            className={`input input--default input--inline ${selectedUsersErrorClass}`}
            filterOptions={(x) => x} // fix load throttling
            getOptionLabel={(option) => option.mail}
            id="address"
            onBlur={() => onResetSearchState()}
            onChange={(event: any, user: any) => onUserChange(event, user)}
            onInputChange={(event, value, reason) => onSearchChange(event, value, reason)}
            options={availableUsersAd}
            popupIcon={
              <img height={10} src={arrowIcon} width={14} />
            }
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <svg height="24px" version="1.1" viewBox="0 0 24 24" width="21px">
                      <g fill="none" fillRule="evenodd" id="Symbols" stroke="none" strokeWidth="1">
                        <g fill="#5E5E5E" fillRule="nonzero" id="icons/search">
                          <g id="delete-white-copy-5">
                            <path d="M6.05025253,6.05025253 C8.78392257,3.31658249 13.2160774,3.31658249 15.9497475,6.05025253 C18.4434716,8.54397661 18.6623564,12.4511046 16.6064021,15.1929497 L20.1923882,18.7781746 C20.5829124,19.1686989 20.5829124,19.8018639 20.1923882,20.1923882 C19.8018639,20.5829124 19.1686989,20.5829124 18.7781746,20.1923882 L15.1929497,16.6064021 C12.4511046,18.6623564 8.54397661,18.4434716 6.05025253,15.9497475 C3.31658249,13.2160774 3.31658249,8.78392257 6.05025253,6.05025253 Z M14.5355339,7.46446609 C12.5829124,5.51184464 9.41708755,5.51184464 7.46446609,7.46446609 C5.51184464,9.41708755 5.51184464,12.5829124 7.46446609,14.5355339 C9.41708755,16.4881554 12.5829124,16.4881554 14.5355339,14.5355339 C16.4881554,12.5829124 16.4881554,9.41708755 14.5355339,7.46446609 Z" id="Combined-Shape"></path>
                          </g>
                        </g>
                      </g>
                    </svg>
                  ),
                }}
                className={`input input--default input--search ${styles.searchInput}`}
                placeholder={t`Search by email`}
                variant="outlined"
              />
            )}
          />
        </Box>
    </Box>
  );
}
