import appointment from './assets/appointment.png';
import floor from './assets/floor.png';
import location from './assets/location.png';
import { useTypedSelector } from 'store/_legacy/Redux/store';

export default function AppointmentConfirmed() {
  const { config } = useTypedSelector(state => state);
  
  return (
    <html>
      <head>
        <link href="https://fonts.gstatic.com" rel="preconnect" />
        <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap" rel="stylesheet" />
      </head>

      <body style={{ fontFamily: `'Poppins', sans-serif` }}>
        <div style={{ maxWidth: 500, margin: '0 auto', padding: 20 }}>
          <table style={{ borderCollapse: 'collapse' }}>
            <tr>
              <td>
                <span style={{ fontSize: 18, fontWeight: 600 }}>
                  Hello Alex Osiichuk
                </span>
              </td>
            </tr>

            <tr>
              <td>
                <div style={{ padding: '34px 0 9px', borderBottom: '1px solid #F4F4F4' }}>
                  <table>
                    <tr>
                      <td>
                        <img alt="visit" src={appointment} style={{ height: 44 }} />
                      </td>

                      <td>
                        <div style={{ marginLeft: 11 }}>
                          <span style={{ fontWeight: 600 }}>
                            Your visit is confirmed
                          </span>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div style={{ marginTop: 20 }}>
                  <span style={{ fontSize: 18, fontWeight: 600 }}>
                    Details
                  </span>

                  <br />

                  <table style={{ borderCollapse: 'collapse', marginTop: 10 }}>
                    <tr>
                      <td valign="top">
                        <span style={{ color: "#5E5E5E", fontSize: 14, whiteSpace: 'nowrap' }}>
                          <span style={{ padding: '4px 9px', borderRadius: 8, backgroundColor: "#f4f4f4" }}>
                            <img alt="floor" src={floor} style={{ position: 'relative', top: 4, height: 17 }} /> Floor 2
                          </span>
                        </span>
                      </td>

                      <td>
                        <div style={{ marginLeft: 7 }}>
                          <span style={{ color: "#5E5E5E", fontSize: 14 }}>
                            <img alt="location" src={location} style={{ position: 'relative', top: 4, height: 17 }} /> Flatiron Building, 175 5th Ave, New York, NY 10010, United States
                          </span>
                        </div>
                      </td>
                    </tr>
                  </table>

                  <div style={{ marginTop: 9, fontSize: 14, fontWeight: 600 }}>
                    Mar 5, 9am-12pm
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div style={{ marginTop: 21 }}>
                  <span
                    style={{
                      padding: '2px 8px',
                      borderRadius: 8,
                      backgroundColor: config.theme.warn,
                      fontSize: 14,
                      color: "#ffffff",
                    }}
                  >
                    Mask is required
                  </span>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </body>
    </html>
  );
}
