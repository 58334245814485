import { ActionPayload, BaseErrorResponse, BaseResponse } from 'store/_legacy/Models/ReduxModels';
import { FloorMapObject } from 'Admin/Store/floorMapDuck/models';
import { State as FloorMapState } from 'Admin/Store/floorMapDuck';
import { t } from '@lingui/macro';
import {
  AvailableDeskResponse,
  GetAvailableDesksRequest,
  GetAvailableDesksResponse,
  SetAvailableDesksDataRequest,
} from './models';

const SET_LOADING = "availableDesksDuck.SET_LOADING";
export const GET_AVAILABLE_DESKS = 'GET_AVAILABLE_DESKS';
export const GET_AVAILABLE_DESKS_FAIL = 'GET_AVAILABLE_DESKS_FAIL';
export const GET_AVAILABLE_DESKS_SUCCESS = 'GET_AVAILABLE_DESKS_SUCCESS';

const SET_AVAILABLE_DESKS_DATA = 'SET_AVAILABLE_DESKS_DATA';

interface SetLoading {
  type: typeof SET_LOADING,
  payload: boolean;
}

export interface GetAvailableDesks {
  type: typeof GET_AVAILABLE_DESKS;
  payload: ActionPayload<GetAvailableDesksRequest>;
}
interface GetAvailableDesksFail {
  type: typeof GET_AVAILABLE_DESKS_FAIL;
  payload: BaseErrorResponse;
}
interface GetAvailableDesksSuccess {
  type: typeof GET_AVAILABLE_DESKS_SUCCESS;
  payload: BaseResponse<GetAvailableDesksResponse>;
}

interface SetAvailableDesksData {
  type: typeof SET_AVAILABLE_DESKS_DATA;
  payload: SetAvailableDesksDataRequest;
}

type Actions =
  | SetLoading
  | GetAvailableDesks
  | GetAvailableDesksFail
  | GetAvailableDesksSuccess
  | SetAvailableDesksData;

export interface State {
  error: string;
  loading: boolean;
  availableDesks: {
    [deskId: string]: AvailableDeskResponse;
  };
  highlightNeighbourDeskId: string;
}

const initialState: State = {
  error: '',
  loading: false,
  availableDesks: {},
  highlightNeighbourDeskId: '',
};

export default function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, loading: action.payload };
    case GET_AVAILABLE_DESKS:
      return {
        ...state,
        error: '',
        loading: true,
      };
    case GET_AVAILABLE_DESKS_FAIL:
      return {
        ...state,
        error: t`There was an error loading available desks. Please try again.`,
        loading: false,
      };
    case GET_AVAILABLE_DESKS_SUCCESS: {
      const availableDesks: { [deskId: string]: AvailableDeskResponse } = {};

      action.payload.data.result.data.forEach(desk => {
        availableDesks[desk.id] = desk;
      });

      return {
        ...state,
        error: '',
        loading: false,
        availableDesks,
      };
    }

    case SET_AVAILABLE_DESKS_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
}

// Actions
export const setLoading = (payload: boolean): SetLoading => ({ type: SET_LOADING, payload });

export function getAvailableDesks({
  data,
  floorId,
}: {
  data: GetAvailableDesksRequest,
  floorId: string,
}): GetAvailableDesks {
  return {
    type: GET_AVAILABLE_DESKS,
    payload: {
      request: {
        method: 'GET',
        url: `/api/bookings/${floorId}/desks`,
        data,
      },
    },
  };
}

export function setAvailableDesksData(data: SetAvailableDesksDataRequest): SetAvailableDesksData {
  return {
    type: SET_AVAILABLE_DESKS_DATA,
    payload: data,
  };
}

// Selectors
/**
 * Merges info from floor map schema, available desks and filters, and select only desks
 * that are available and matches desired filters
 */
 export function selectDesksFiltered({
  availableDesksState,
  filters,
  floorMapState,
}: {
  availableDesksState: State;
  filters: { [key: string]: boolean };
  floorMapState: FloorMapState;
}): FloorMapObject[] {
  // Get all desks from floor map schema
  let desks = Object.keys(floorMapState.desks).map(deskId => floorMapState.desks[deskId]);

  // Apply filters
  desks = desks.filter(desk => {
    // Create array of flters with selected filters from object
    const filtersArray = Object.keys(filters).filter(filterName => filters[filterName]);

    // Check if current desk matches all filters criteria
    const applyAllFilters = filtersArray.every(filterName => {
      const deskHasResource = Boolean(availableDesksState.availableDesks[desk.id]?.resources?.find(resource => resource.name === filterName));

      return deskHasResource;
    });

    return applyAllFilters;
  });

  return desks;
}