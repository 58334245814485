import { Calendar } from "react-date-range";
import React from "react";
import { DatePickerCalendarProps } from "./types";
import { shiftTimeZone } from "utils";

export const DatePickerCalendar: React.FC<DatePickerCalendarProps> = (props) => {
  const { date, minDate, maxDate, timeZone, onChange } = props;

  const handleChange = (date: Date) => {
    if (timeZone) {
      onChange?.(shiftTimeZone(date, timeZone));
    } else {
      onChange?.(date);
    }
  };

  return (
    <Calendar
      className="custom-calendar"
      date={date}
      maxDate={maxDate}
      minDate={minDate}
      onChange={handleChange}
      showMonthAndYearPickers={false}
    />
  );
};
