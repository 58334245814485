import Autocomplete from '@material-ui/lab/Autocomplete';
import format from 'date-fns/format';
import numberToHoursMinutes from 'Functions/numberToHoursMinutes';
import TextField from '@material-ui/core/TextField';
import { getLocationAvailableParkingSpots, selectLocationAvailableParkingSpotsSorted } from 'Admin/Store/parking';
import { ParkingSpot } from 'Admin/Store/parking/models';
import { setJustInTimeReservationData } from 'Admin/Store/reservationJustInTime';
import { t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTypedSelector } from 'store/_legacy/Redux/store';
import { WeekDays } from 'App/Store/Bookings/bookingDuck/models';

interface ReservationJustInTimeParkingSpotProps {
  isDisable: boolean;
}

export default function ReservationJustInTimeParkingSpot({
  isDisable,
}: ReservationJustInTimeParkingSpotProps) {
  const dispatch = useDispatch();

  const [search, setSearch] = useState('');

  const { adminReservationJustInTime, parking } = useTypedSelector(state => state);

  const { selectedData } = adminReservationJustInTime;

  const { bookingType, dateFrom, dateTo, timeFrom, timeTo, weekDays } = adminReservationJustInTime;
  const { location } = selectedData;

  const parkingSpots = (location ? selectLocationAvailableParkingSpotsSorted(parking, location.id) : []).filter(parkingSpot => {
    // make search only for next fields
    const fieldsToSearch = ['name', 'floor'];

    return Object.keys(parkingSpot).some(key => {
      // Check if current key should be used on search
      if (fieldsToSearch.includes(key)) {
        // Check if parkingSpot property matches the search
        if (parkingSpot[key as keyof typeof parkingSpot]?.toString().toLowerCase().includes(search.toLowerCase())) {
          return true;
        }
      }
    });
  });


  useEffect(() => {
    if (!location) {
      return;
    }

    const weeklyBooking: Array<{ weekDay: WeekDays; timeFrom: string; timeTo: string }> = [];

    Object.keys(weekDays).forEach(weekDayNumber => {
      const weekDay = weekDays[parseInt(weekDayNumber)];

      if (weekDay.selected) {
        const { hours: timeFromHours, minutes: timeFromMinutes } = numberToHoursMinutes(weekDay.timeFrom);
        const { hours: timeToHours, minutes: timeToMinutes } = numberToHoursMinutes(weekDay.timeTo);

        weeklyBooking.push({
          timeFrom: `${timeFromHours}:${String(timeFromMinutes).length === 1 ? timeFromMinutes + '0' : timeFromMinutes}`,
          timeTo: `${timeToHours}:${String(timeToMinutes).length === 1 ? timeToMinutes + '0' : timeToMinutes}`,
          weekDay: weekDay.label,
        });
      }
    });

    const timeFromFormated = timeFrom ? format(new Date(timeFrom), 'HH:mm') : '';
    const timeToFormated = timeTo ? format(new Date(timeTo), 'HH:mm') : '';

    dispatch(getLocationAvailableParkingSpots({
      locationId: location.id,
      data: {
        custom: bookingType === 'custom'
          ? {
            dateFrom: adminReservationJustInTime.dateFrom ? format(new Date(adminReservationJustInTime.dateFrom), 'yyyy-MM-dd') : '',
            dateTo: adminReservationJustInTime.dateTo ? format(new Date(adminReservationJustInTime.dateTo), 'yyyy-MM-dd') : '',
            timeFrom: timeFromFormated,
            timeTo: timeToFormated,
          }
          : undefined,
        daily: bookingType === 'daily' ? { timeFrom: timeFromFormated, timeTo: timeToFormated } : undefined,
        weekly: bookingType === 'weekly' ? weeklyBooking : undefined,
      },
    }));
  }, [bookingType, dispatch, dateFrom, dateTo, location, timeFrom, timeTo, weekDays]);

  const onParkingSpotChange = (event: any, parkingSpot: ParkingSpot) => {
    if (!parkingSpot) {
      return dispatch(setJustInTimeReservationData({
        selectedData: {
          ...selectedData,
          parkingSpot: null,
        },
      }));
    }

    return dispatch(setJustInTimeReservationData({
      selectedData: {
        ...selectedData,
        parkingSpot,
      },
    }));
  };

  return (
    <Autocomplete
      className={`input input--default input--inline`}
      filterOptions={(x) => x} // fix load throttling
      getOptionLabel={(option) => t`${option.floor ? `Floor ${option.floor}, ` : ''}${option.name}`}
      getOptionSelected={(option, value) => option.id === value.id}
      id="parkingSpot"
      onBlur={() => setSearch('')}
      onChange={(event: any, floor: any) => onParkingSpotChange(event, floor)}
      options={parkingSpots}
      popupIcon={
        <svg height="10px" version="1.1" viewBox="0 0 14 10" width="14px">
          <g fill="none" fillRule="evenodd" id="A-locations" stroke="none" strokeWidth="1">
            <g id="A-Location-notifications-createnew" transform="translate(-384.000000, -32.000000)">
              <rect fill="transparent" height="900" width="1440" x="0" y="0"></rect>
              <g fill={isDisable ? "#ABABAB" : "#1E1F7B"} id="icons/arrow-down" transform="translate(384.000000, 32.000000)">
                <g id="icons/arrow-down-w" transform="translate(7.000000, 5.000000) rotate(-90.000000) translate(-7.000000, -5.000000) translate(2.000000, -2.000000)">
                  <path d="M7.15685425,0.692893219 C7.54737854,0.302368927 8.18054352,0.302368927 8.57106781,0.692893219 C8.9615921,1.08341751 8.9615921,1.71658249 8.57106781,2.10710678 L3.62132034,7.05685425 L8.57106781,12.0066017 C8.9615921,12.397126 8.9615921,13.030291 8.57106781,13.4208153 C8.18054352,13.8113396 7.54737854,13.8113396 7.15685425,13.4208153 L1.5,7.76396103 C1.10947571,7.37343674 1.10947571,6.74027176 1.5,6.34974747 L7.15685425,0.692893219 Z" id="icons/arrow-down" transform="translate(5.035534, 7.056854) rotate(-360.000000) translate(-5.035534, -7.056854) "></path>
                </g>
              </g>
            </g>
          </g>
        </svg>
      }
      renderInput={(params) => (
        <TextField
          {...params}
          className={`input input--default`}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={t`Not selected`}
          variant="outlined"
        />
      )}
      value={selectedData.parkingSpot}
    />
  );
}
