import { commApi } from "../comm-api";
import { DeletePostReactionInput, PostReaction, SetPostReactionInput } from "../types";
import { CommApiTag } from "../enums";

const postReactions = commApi.injectEndpoints({
  endpoints: (builder) => ({
    setPostReaction: builder.mutation<PostReaction, SetPostReactionInput>({
      query: ({ postId, ...body }) => ({ body, url: `/api/v1/posts/${postId}/reactions`, method: "PUT" }),
      invalidatesTags: [CommApiTag.POST],
    }),
    deletePostReactionById: builder.mutation<void, DeletePostReactionInput>({
      query: ({ postId, postReactionId }) => ({ url: `/api/v1/posts/${postId}/reactions/${postReactionId}`, method: "DELETE" }),
      invalidatesTags: [CommApiTag.POST],
    }),
  }),
});

export const { useDeletePostReactionByIdMutation, useSetPostReactionMutation } = postReactions;
