import styles from "../styles.module.scss";
import { Trans } from "@lingui/macro";

import Box from "components/_legacy/Box";
import Text from "components/_legacy/Text";
import TextField from "components/_legacy/TextField";
import { FloorMapObject } from "../../../../../../Store/floorMapDuck/models";
import { useState } from "react";
import { isNumberString } from "class-validator";
import { useDispatch } from "react-redux";
import { updateMeeting } from "Admin/Store/floorMapDuck";

interface Props {
  meeting: FloorMapObject;
}

export default function MeetingDetailsCapacity(props: Props) {
  const { meeting } = props;
  const { id, capacity } = meeting;
  const [capacityValue, setCapacityValue] = useState(capacity ? `${capacity}` : "");
  const dispatch = useDispatch();
  const handleCapacityChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    const { value = "" } = event.target;

    if (!value) {
      setCapacityValue("");
    }

    if (isNumberString(value)) {
      const capacityIntValue = Number.parseInt(value);

      if (capacityIntValue > 0) {
        setCapacityValue(`${capacityIntValue}`);
      }
    }
  };
  const handleCapacityBlur = () => {
    if (!capacityValue) {
      dispatch(updateMeeting({
        meetingId: typeof id === "string" ? parseInt(id) : id,
        meeting: { capacity: null },
      }));
    } else {
      const capacityIntValue = Number.parseInt(capacityValue);

      if (capacityIntValue !== capacity) {
        dispatch(updateMeeting({
          meetingId: typeof id === "string" ? parseInt(id) : id,
          meeting: { capacity: capacityIntValue },
        }));
      }
    }
  };

  return (
    <Box className={styles.detailsItem}>
      <Text color="gray" size="md">
        <Trans>Capacity:</Trans>
      </Text>

      <Text color="gray" size="md">
        <TextField
          classes={{ input: styles.textFieldInput }}
          id="meeting-capacity"
          onBlur={handleCapacityBlur}
          onChange={handleCapacityChange}
          placeholder="Not set"
          value={capacityValue}
        />
      </Text>
    </Box>
  );
}
