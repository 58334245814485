import React, { useState } from "react";
import { NewsFeedItemCommentsProps } from "./types";
import { Box, FilledInput, Skeleton, Typography, useTheme } from "@mui/material";
import { t } from "@lingui/macro";
import { useCreatePostCommentMutation, useGetPostCommentsByPostIdQuery } from "store";
import { LoadingButton } from "@mui/lab";
import { useToast } from "../toast-provider";
import { NewsFeedItemComment } from "./news-feed-item-comment";

export const NewsFeedItemComments: React.FC<NewsFeedItemCommentsProps> = (props) => {
  const { postId, open, disableCommenting } = props;
  const { palette } =  useTheme();
  const toast = useToast();
  const [body, setBody] = useState("");
  const getPostCommentsByPostIdQuery = useGetPostCommentsByPostIdQuery(
    {
      postId,
      limit: -1,
      filter: { "parent.id": "NULL" },
      include: [
        "author",
        "parent",
        "$extra.reactionsCount",
        "$extra.reactionsCodes",
        "$extra.myReaction",
        "children",
        "children.author",
        "children.children",
        "children.children.author",
      ],
      orderBy: ["asc:createdAt", "asc:children.createdAt", "asc:children.children.createdAt"],
    },
    { skip: !open },
  );
  const [createPostComment, { isLoading: isPosting }] = useCreatePostCommentMutation();

  const handlePostClick = () => {
    void (async () => {
      const response = await createPostComment({ postId, body });

      if ("error" in response) {
        toast.showToast({ severity: "error", message: t`Failed to post comment, please try again.` });
      } else {
        toast.showToast({ severity: "success", message: t`Comment posted.` });
        setBody("");
      }
    })();
  };

  if (!open) {
    return <></>;
  }

  const { data: getPostCommentsByPostIdResponse, isLoading } = getPostCommentsByPostIdQuery;
  const { items: comments } = getPostCommentsByPostIdResponse || {};

  return (
    <Box pt={2}>
      {!disableCommenting ? (
        <Box display="flex" gap={2}>
          <FilledInput
            fullWidth
            onChange={(event) => setBody(event.currentTarget.value)}
            placeholder={t`Add comment`}
            sx={{ bgcolor: "#fff" }}
            value={body}
          />
          <LoadingButton disabled={!body.length} loading={isPosting} onClick={handlePostClick} variant="contained">{t`Post`}</LoadingButton>
        </Box>
      ) : undefined}
      <Box pt={2}>
        {isLoading ? (
          <>
            <Box display="flex" gap={1} mb={2}>
              <Skeleton height={18} sx={{ bgcolor: palette.grey[200] }} variant="rectangular" width={180} />
              <Skeleton height={18} sx={{ bgcolor: palette.grey[200] }} variant="rectangular" width={80} />
            </Box>
            <Skeleton height={18} sx={{ bgcolor: palette.grey[200], ml: 1, mb: 0.5 }} variant="rectangular" width="100%"  />
            <Skeleton height={18} sx={{ bgcolor: palette.grey[200], ml: 1 }} variant="rectangular"  width="75%" />
          </>
        ) : undefined}
        {!isLoading && !comments?.length ? (
          <Typography color={palette.grey[700]} fontSize={14} mt={1} textAlign="center">{t`No comments yet.`}</Typography>
        ) : undefined}
        {!isLoading && comments?.length ? comments.map((comment, index) => (
          <NewsFeedItemComment comment={comment} disableCommenting={disableCommenting} key={comment.id} mb={index + 1 === comments.length ? 0 : 2} postId={postId} />
        )) : undefined}
      </Box>
    </Box>
  );
};
