import Box from "components/_legacy/Box";
import {Trans, t} from "@lingui/macro";
import Button from "components/_legacy/Button";
import {useDispatch} from "react-redux";
import {useTypedSelector} from "store/_legacy/Redux/store";
import {editGroup} from "../../../../Store/groups";
import {IGroup} from "../../../../Store/groups/models";
import {setGroupDetailsData} from "../../../../Store/groupDetails";

interface Props {
  group: IGroup;
}

export default function GroupUpdateControls(props: Props) {
  const dispatch = useDispatch();
  const { infoEditState, infoDataToEdit } = useTypedSelector(state => state.groupDetails);
  const { group } = props;

  const openEditState = () => {
    dispatch(setGroupDetailsData({ infoEditState: true }));
  };

  const onCancel = () => {
    // reset data to default
    dispatch(setGroupDetailsData({
      infoEditState: false,
      infoDataToEdit: {
        ...infoDataToEdit,
        name: group.name,
        locationIds: group.locationIds,
      },
    }));
  };

  const onUpdate = () => {
    const groupData = { name: infoDataToEdit.name, locationIds: infoDataToEdit.locationIds, adGroupId: group.adGroup?.id };

    dispatch(setGroupDetailsData({ infoEditState: false }));
    dispatch(editGroup({ group: groupData, groupId: group.id }));
  };

  return (
    <Box alignItems="center" display="flex">
      {
        infoEditState ?
          <Box alignItems="center" display="flex" justifyContent="end">
            <Button
              aria-label={t`Cancel`}
              name={t`Cancel`}
              onClick={onCancel}
              size="sm"
              type="clear"
            >
              <Trans>Cancel</Trans>
            </Button>

            <Button
              aria-label={t`Update Group Info`}
              disabled={!infoDataToEdit.name} // cant be empty name to save
              name={t`Update Email Content`}
              onClick={onUpdate}
              size="sm"
            >
              <Trans>Update</Trans>
            </Button>
          </Box> :
          <Button
            aria-label={t`Open Edit Group Info`}
            name={t`Open Edit Group Info`}
            onClick={openEditState}
            size="sm"
          >
            <Trans>Edit</Trans>
          </Button>
      }
    </Box>
  );
}
