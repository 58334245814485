import { booking } from '../Bookings/bookingDuck/fixtures';
import {
  GetQuestionnaireBookingByIdResponse,
  GetQuestionnaireBookingsResponse,
  QuestionnaireModel,
} from './models';

export const getQuestionnaireBookingsSuccess: GetQuestionnaireBookingsResponse = {
  result: {
    statusCode: 200,
    data: {
      bookings: [],
      appointments: [],
    },
  },
};

export const getQuestionnaireBookingByIdSuccess: GetQuestionnaireBookingByIdResponse = {
  result: {
    data: booking,
    statusCode: 200,
  },
};

export const getQuestionQuestionnaireSuccessEmpty: { result: { data: QuestionnaireModel[] } } = {
  result: {
    data: [],
  },
};
