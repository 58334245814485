import { t } from "@lingui/macro";
import { Box } from "@mui/material";
import { FloorSelect, InputLabel, ReservationResourcesList, ReservationResourcesListItem, ReservationResourcesListProps, Tab, Tabs, useToast } from "components";
import React, { useEffect, useState } from "react";
import { floorsSlice, reservationsSlice, roomsSlice, selectCurrentFloor, selectCurrentReservation, useLazyGetFloorRoomsQuery } from "store";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";

export const ReservationLocationFloorRoomsRoute: React.FC = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const { locationId, floorId } = useParams<{ locationId: string; floorId: string }>();
  const currentReservation = useSelector(selectCurrentReservation);
  const currentFloor = useSelector(selectCurrentFloor);
  const [page, setPage] = useState(1);
  const [triggerGetFloorRoomsQuery, getFloorRoomsQuery] = useLazyGetFloorRoomsQuery();
  const [items, setItems] = useState<ReservationResourcesListItem[]>([]);
  const { data: roomsResponse, isLoading: roomsAreLoading, isFetching: roomsAreFetching, isError: failedToGetRooms } = getFloorRoomsQuery;
  const hasMore = !!roomsResponse?.result?.data?.links?.next;
  const rooms = roomsResponse?.result?.data?.items;

  useEffect(() => {
    if (failedToGetRooms) {
      toast.showToast({ severity: "error", message: t`Sorry, we couldn't load the rooms list. Try again later.` });
    }
  }, [failedToGetRooms]);

  useEffect(() => {
    if (currentReservation?.floorId !== floorId) {
      dispatch(reservationsSlice.actions.setCurrent({ floorId, locationId }));
      dispatch(floorsSlice.actions.resetCurrent({ id: floorId }));
      dispatch(roomsSlice.actions.resetCurrent());
    }
  }, [floorId]);

  useEffect(() => {
    setPage(1);
    setItems([]);
    triggerGetFloorRoomsQuery({ locationId, floorId, page: 1 }, true);
  }, [currentFloor?.id]);

  useEffect(() => {
    triggerGetFloorRoomsQuery({ locationId, floorId, page }, true);
  }, [page]);

  useEffect(() => {
    const newItems: ReservationResourcesListItem[] = [];

    for (const room of rooms || []) {
      if (!items.some(({ id }) => id === room.id)) {
        newItems.push({
          id: room.id,
          name: room.name,
          floorName: room.floor?.name,
          floorId: room.floor?.id,
          locationId: room?.floor?.location?.id,
          locationAddress: room.floor?.location?.address,
          capacity: room.capacity,
          images: room.images,
        });
      }
    }

    if (newItems.length) {
      setItems([...items, ...newItems]);
    }
  }, [rooms]);

  const handleItemSelect: ReservationResourcesListProps["onItemSelect"] = (item) => {
    const { id: roomId, name: roomName, floorId } = item;
    
    if (roomId === currentReservation?.roomId) {
      dispatch(reservationsSlice.actions.setCurrent({
        roomId: undefined,
        floorId: undefined,
        startDate: undefined,
        endDate: undefined,
        requestedServices: undefined,
      }));
      dispatch(roomsSlice.actions.resetCurrent());
    } else {
      dispatch(reservationsSlice.actions.setCurrent({
        roomId,
        floorId,
        startDate: undefined,
        endDate: undefined,
        requestedServices: [],
        isAllDay: undefined,
      }));
      dispatch(roomsSlice.actions.resetCurrent({ id: roomId, name: roomName }));
    }
  };

  const handleLoadMore = () => {
    if (hasMore) {
      setPage(page + 1);
    }
  };

  const isListView = /^\/reservations\/locations\/(\w|-)+\/floors\/(\w|-)+\/rooms\/?$/.test(pathname);
  const isCalendarView = /^\/reservations\/locations\/(\w|-)+\/floors\/(\w|-)+\/rooms\/calendar-view\/?$/.test(pathname);

  const handleTabClick = (tab: number) => {
    if (tab === 0 && !isListView) {
      history.push(`/reservations/locations/${locationId}/floors/${floorId}/rooms`);
    } else if (tab === 1 && !isCalendarView) {
      history.push(`/reservations/locations/${locationId}/floors/${floorId}/rooms/calendar-view`);
    }
  };

  const handleFloorSelectChange = (floorId: string) => {
    history.push(`/reservations/locations/${locationId}/floors/${floorId}/rooms`);
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box alignItems="flex-end" display="flex" justifyContent="space-between" marginBottom={2}>
        <Tabs inline value={0}>
          <Tab data-cid="list-view-button" label={t`List View`} onClick={() => handleTabClick(0)} />
          <Tab data-cid="calendar-view-button" label={t`Calendar View`} onClick={() => handleTabClick(1)} />
        </Tabs>
        <Box alignItems="flex-end" display="flex">
          <InputLabel disableAnimation id="floor-select-label" sx={{ fontSize: 16, fontWeight: "600", marginRight: 1, marginBottom: 0 }}>
            {t`Floor`},
          </InputLabel>
          <FloorSelect
            getOptionDisabled={({ extra }) => !extra?.availableRooms}
            include={["extra.availableRooms"]}
            labelId="floor-select-label"
            locationId={locationId || currentReservation?.locationId || ""}
            onChange={handleFloorSelectChange}
            value={floorId || currentReservation?.floorId || ""}
          />
        </Box>
      </Box>
      <ReservationResourcesList
        hasMore={hasMore}
        height={710}
        isFirstLoad={!items?.length}
        isLoading={roomsAreLoading || roomsAreFetching}
        items={items}
        onItemSelect={handleItemSelect}
        onLoadMore={hasMore ? handleLoadMore : undefined}
        selectedItemId={currentReservation?.roomId}
      />
    </Box>
  );
};
