import { ActionPayload, BaseErrorResponse, BaseResponse } from 'store/_legacy/Models/ReduxModels';
import { t } from '@lingui/macro';
import {
  BookingModelCheckin,
  CheckinRequest,
  CheckinResponse,
  SetCheckinDataRequest,
} from "./models";
import getErrorMessageFromPayload from 'store/_legacy/Redux/Helpers/getErrorMessageFromPayload';

export const CHECKIN = 'CHECKIN';
export const CHECKIN_FAIL = 'CHECKIN_FAIL';
export const CHECKIN_SUCCESS = 'CHECKIN_SUCCESS';

const SET_CHECKIN_DATA = 'SET_CHECKIN_DATA';

export interface Checkin {
  type: typeof CHECKIN;
  payload: ActionPayload<CheckinRequest>;
}
interface CheckinFailure {
  type: typeof CHECKIN_FAIL;
  payload: BaseErrorResponse;
}
interface CheckinSuccess {
  type: typeof CHECKIN_SUCCESS;
  payload: BaseResponse<CheckinResponse>;
}

interface SetCheckinData {
  type: typeof SET_CHECKIN_DATA;
  payload: SetCheckinDataRequest;
}

type Actions =
  | Checkin
  | CheckinFailure
  | CheckinSuccess
  | SetCheckinData;

export interface State {
  error: string;
  loading: boolean;
  bookingCheckin: BookingModelCheckin | undefined;
  message: string;
}

const initialState: State = {
  error: '',
  loading: false,
  bookingCheckin: undefined,
  message: '',
};

export default function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case CHECKIN:
      return {
        ...state,
        error: '',
        loading: true,
      };
    case CHECKIN_FAIL:
      return {
        ...state,
        error: getErrorMessageFromPayload({ payload: action.payload, fallbackMessage: t`There was an error with checkin. Please try again.` }),
        loading: false,
      };
    case CHECKIN_SUCCESS: {
      const { booking, message } = action.payload.data.result.data;

      return {
        ...state,
        loading: false,
        error: '',
        bookingCheckin: booking,
        message,
      };
    }

    case SET_CHECKIN_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
}

// Actions
export function checkin(data: CheckinRequest): Checkin {
  return {
    type: CHECKIN,
    payload: {
      request: {
        method: 'GET',
        url: `/api/bookings/qrcode/checking`,
        data,
      },
    },
  };
}

export function setCheckinData(data: SetCheckinDataRequest): SetCheckinData {
  return {
    type: SET_CHECKIN_DATA,
    payload: data,
  };
}
