import { addWeeks, getISODay } from "date-fns";
import { WeeklyRecurrenceOptions } from "types";

export const resolveDefaultWeeklyRecurrenceOptions = (options?: Partial<WeeklyRecurrenceOptions>): WeeklyRecurrenceOptions => {
  const start = options?.start || new Date();

  return {
    start,
    type: "weekly",
    end: options?.end || addWeeks(start, 4),
    interval: options?.interval || 1,
    weekdays: [getISODay(start) - 1],
  };
};
