import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'store/_legacy/Redux/store';

// @ts-ignore
import DoubleScrollbar from 'react-double-scrollbar';
import Box from 'components/_legacy/Box';
import Text from 'components/_legacy/Text';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { Skeleton } from '@material-ui/lab';
import { Trans, t } from '@lingui/macro';
import styles from '../styles.module.scss';
import OverflowToolTip from "components/_legacy/OverflowTooltip";
import ReservationBodyItem from './ListViewBodyItem';
import CheckboxCustom from 'components/_legacy/CheckBoxCustom';
import {
  setReservationsData,
  updateBulkReservationMassActionsIds,
} from '../../../Store/reservations';
import { BookingModel } from '../../../../App/Store/Bookings/bookingDuck/models';

interface ReservationListViewProps {
  reservations: BookingModel[];
}

export default function ReservationListView(props: ReservationListViewProps) {
  const dispatch = useDispatch();
  const { adminReservations } = useTypedSelector(state => state);
  const { reservations } = props;
  const { reservationPageType, reservationMassActionsIds, filters, totalCount, limit, loading } = adminReservations;

  const isMassActiveActive = adminReservations.reservationMassActions[reservationPageType];
  const isAllOnPageSelected = reservationMassActionsIds.length === reservations.length;

  const onBulkMassActionUpdate = () => {
    let ids: string[] = [];
    if (!isAllOnPageSelected) {
      ids = reservations.map(reservation => reservation.id);
    }

    dispatch(updateBulkReservationMassActionsIds(ids));
  };

  const onSetAllSelected = () => {
    dispatch(setReservationsData({
      filters: {
        ...filters,
        allSelected: true,
      },
    }));
  };

  return (
    <Box className={styles.listView} dataTestId="reservation-list-view">
      {
        isMassActiveActive && isAllOnPageSelected && totalCount > limit ?
          <Box className={styles.allSelect} dataTestId="reservation-list-view-all-select">
            {
              filters.allSelected ?
                <div>You selected all reservations from all pages</div> :
                <div>
                  <span>You selected all reservations from current page</span>
                  <span
                    className={styles.allSelectActivate}
                    onClick={onSetAllSelected}
                  >
                    Select all {totalCount} reservations
                  </span>
                  <span>from rest of pages</span>
                </div>
            }

          </Box> : null
      }

      <TableContainer data-testid="reservation-list-view-content">
        <DoubleScrollbar>
          <Table
            aria-label={t`Reservation list view`}
            aria-labelledby={t`list view`}
            className={styles.reservationTable}
          >
            <TableHead classes={{ root: styles.tableHead }}>
              <TableRow classes={{ root: styles.tableRow }}>
                <TableCell classes={{ root: styles.tableCell }} padding="none">
                  <Box alignItems="center" display="flex">
                    {
                      isMassActiveActive ?
                        <CheckboxCustom
                          checked={isAllOnPageSelected}
                          name={'listViewAll'}
                          onChange={onBulkMassActionUpdate}
                        /> : null
                    }
                    <OverflowToolTip text={t`Location name`} />
                  </Box>
                </TableCell>

                <TableCell classes={{ root: styles.tableCell }} padding="none">
                  <Trans>
                    Floor
                  </Trans>
                </TableCell>

                <TableCell classes={{ root: styles.tableCell }} padding="none">
                  <Trans>
                    User
                  </Trans>
                </TableCell>

                <TableCell classes={{ root: styles.tableCell }} padding="none">
                  <Trans>
                    Desk
                  </Trans>
                </TableCell>

                <TableCell classes={{ root: styles.tableCell }} padding="none">
                  <OverflowToolTip text={t`Date and time`} />
                </TableCell>

                <TableCell classes={{ root: styles.tableCell }} padding="none">
                  <OverflowToolTip text={t`Parking spot`} />
                </TableCell>

                <TableCell classes={{ root: styles.tableCell }} padding="none">
                  <Trans>
                    Status
                  </Trans>
                </TableCell>

                <TableCell classes={{ root: styles.tableCell }} padding="none">
                  <OverflowToolTip text={t`Accommodation`} />
                </TableCell>

                <TableCell classes={{ root: styles.tableCell }} padding="none">
                  <OverflowToolTip text={t`Check-in`} />
                </TableCell>

                <TableCell classes={{ root: styles.tableCell }} padding="none">
                  <OverflowToolTip text={t`Doc status`} />
                </TableCell>

                <TableCell classes={{ root: styles.tableCell }} padding="none"></TableCell>
              </TableRow>
            </TableHead>

            {!loading && (
              <TableBody classes={{ root: styles.tableBody }}>
                {
                  reservations.map((reservation, index) => {
                    return (<ReservationBodyItem index={index} key={index} reservation={reservation} />);
                  })
                }
              </TableBody>
            )}
          </Table>
        </DoubleScrollbar>
      </TableContainer>
      {loading ? (
        <Box dataTestId="reservation-list-view-loading" height={200}>
          <Skeleton height="100%" variant="rect" />
        </Box>
      ) : (
        !reservations.length && (
          <Box dataTestId="reservation-list-view-no-data" marginTop={100}>
            <Text align="center" color="lightGray" size="md">
              No reservations found
            </Text>
          </Box>
        )
      )}
    </Box>
  );
}
