import React from "react";
import { ListHeaderLabelProps } from "./types";
import { OrderButton } from "../button";
import { Typography, useMediaQuery, useTheme } from "@mui/material";

export const ListHeaderLabel: React.FC<ListHeaderLabelProps> = (props) => {
  const { orderable, order, sx, children, onOrderChange } = props;
  const { palette, breakpoints } = useTheme();
  const xl = useMediaQuery(breakpoints.up("xl"));

  return orderable ? (
    <OrderButton
      onOrderChange={onOrderChange}
      order={order}
      sx={{ justifyContent: "space-between", ...sx }}
    >
      <Typography color={palette.grey[700]} fontSize={xl ? 13 : 12} noWrap title={typeof children === "string" ? children : undefined}>
        {children}
      </Typography>
    </OrderButton>
  ) : (
    <Typography color={palette.grey[700]} fontSize={xl ? 13 : 12} noWrap sx={sx} title={typeof children === "string" ? children : undefined}>
      {children}
    </Typography>
  );
};
