import React from "react";
import { FloorSelectProps } from "./types";
import { useGetFloorsByLocationIdQuery } from "store";
import { MenuItem, Select, SelectProps, Skeleton } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import { t } from "@lingui/macro";

export const FloorSelect: React.FC<FloorSelectProps> = (props) => {
  const { locationId, value, showAllOption, maxWidth, include, labelId, getOptionDisabled, onChange } = props;
  const getFloorsByLocationIdQuery = useGetFloorsByLocationIdQuery({ locationId, include });
  const { data: floorsResponse, isLoading: areFloorsLoading, isFetching: areFloorsFetching } = getFloorsByLocationIdQuery;
  const floors = floorsResponse?.result?.data?.items;

  const handleChange: SelectProps["onChange"] = (event) => {
    const { value } = event.target;

    if (typeof value === "string") {
      onChange?.(value);
    }
  };

  return (
    <>
      {areFloorsLoading || areFloorsFetching || !floors ? (
        <Skeleton sx={{ width: 120, height: 24, borderRadius: 2 }} variant="rectangular" />
      ) : (
        <Select
          IconComponent={KeyboardArrowDown}
          data-cid="floor-select"
          disableUnderline
          labelId={labelId}
          onChange={handleChange}
          size="small"
          sx={({ palette }) => ({
            maxWidth,
            "& .MuiSelect-select": {
              padding: 0,
              fontSize: 16,
              fontWeight: "600",
            },
            "& .MuiSelect-icon": {
              color: palette.primary.main,
              top: 0,
            },
          })}
          value={showAllOption ? value || "all" : value}
          variant="standard"
        >
          {showAllOption ? <MenuItem data-cid="floor-option" key="none" value="all">{t`All floors`}</MenuItem> : undefined}
          {floors.map((floor) => (
            <MenuItem data-cid="floor-option" disabled={getOptionDisabled?.(floor)} key={floor.id} value={floor.id}>{floor.name}</MenuItem>
          ))}
        </Select>
      )}
    </>
  );
};
