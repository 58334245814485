import { isURL } from "class-validator";
import { Editor } from "slate";
import { wrapEditorLink } from "./wrap-editor-link";
import { EditorElementType } from "../enums";

export const withCopyPaste = (editor: Editor): Editor => {
  const { insertData, insertText, insertNodes } = editor;

  editor.insertText = (text) => {
    if (text && isURL(text)) {
      wrapEditorLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = (data) => {
    const file = data.files.item(0);

    if (file) {
      insertNodes({ type: EditorElementType.IMAGE, file, children: [{ text: "" }] });

      return;
    }

    const fragment = data.getData("application/x-slate-fragment");
    const html = data.getData("text/html");

    if (!fragment && html) {
      insertNodes({ type: EditorElementType.HTML, body: html, children: [{ text: "" }] });

      return;
    }

    const text = data.getData("text/plain");

    if (text && isURL(text)) {
      wrapEditorLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};
