import Box from 'components/_legacy/Box';
import styles from './styles.module.scss';
import { Trans } from '@lingui/macro';
import { useTypedSelector } from 'store/_legacy/Redux/store';

export default function SupportLinks() {
  const { config } = useTypedSelector(state => state);

  return (
    <Box className={styles.profileSupportLinks} marginTop={25}>
      <Box alignItems="center" display="flex" justifyContent="between">
        <h3>
          <Trans>
            Support links
          </Trans>
        </h3>
        <div>
          <Box className={styles.line} />
        </div>
      </Box>
      <Box className={styles.profileSupportBlock} marginTop={12}>
        <a href="https://help.mbww.com" rel="noreferrer" target="_blank">
          <Trans>
            IT Help Page
          </Trans>
          <svg height="14px" version="1.1" viewBox="0 0 14 14" width="14px">
            <g fill="none" fillRule="evenodd" id="Booking" stroke="none" strokeWidth="1">
              <g id="p-profile" transform="translate(-296.000000, -476.000000)">
                <g id="Group-3" transform="translate(30.000000, 25.000000)">
                  <g id="Group" transform="translate(20.000000, 441.000000)">
                    <rect fill="transparent" height="34" id="Rectangle" rx="6" width="270" x="0" y="0"></rect>
                    <g fill={config.theme.primary} fillRule="nonzero" id="icons/spaces/shared" transform="translate(246.000000, 10.000000)">
                      <path d="M1.92588386,6.27512627 C2.32622057,5.87478956 2.97529462,5.87478956 3.37563133,6.27512627 C3.77596804,6.67546297 3.77596804,7.32453703 3.37563133,7.72487373 L2.6507576,8.44974747 C1.85008418,9.25042089 1.85008418,10.548569 2.6507576,11.3492424 C3.45143101,12.1499158 4.74957911,12.1499158 5.55025253,11.3492424 L7.72487373,9.1746212 C8.48340645,8.41608849 8.52332922,7.21106881 7.84464206,6.40553747 L7.72487373,6.27512627 C7.32453703,5.87478956 7.32453703,5.22571551 7.72487373,4.8253788 C8.12521044,4.42504209 8.77428449,4.42504209 9.1746212,4.8253788 C10.775968,6.42672563 10.775968,9.02302184 9.1746212,10.6243687 L7,12.7989899 C5.39865316,14.4003367 2.80235696,14.4003367 1.20101013,12.7989899 C-0.400336709,11.197643 -0.400336709,8.60134684 1.20101013,7 L1.92588386,6.27512627 Z M7,1.20101013 C8.60134684,-0.400336709 11.197643,-0.400336709 12.7989899,1.20101013 C14.4003367,2.80235696 14.4003367,5.39865316 12.7989899,7 C12.3986532,7.40033671 11.7495791,7.40033671 11.3492424,7 C10.9489057,6.59966329 10.9489057,5.95058924 11.3492424,5.55025253 C12.1499158,4.74957911 12.1499158,3.45143101 11.3492424,2.6507576 C10.548569,1.85008418 9.25042089,1.85008418 8.44974747,2.6507576 L7,4.10050506 C6.23572083,4.86478423 6.20098087,6.08234096 6.89578011,6.88782718 L7,7 C7.40033671,7.40033671 7.40033671,8.04941076 7,8.44974747 C6.59966329,8.85008418 5.95058924,8.85008418 5.55025253,8.44974747 C3.9489057,6.84840063 3.9489057,4.25210443 5.55025253,2.6507576 L7,1.20101013 Z" id="Shape" transform="translate(7.000000, 7.000000) scale(-1, -1) translate(-7.000000, -7.000000) "></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </a>
        <a href="https://fiori.interpublic.com" rel="noreferrer" target="_blank">
          <Trans>
            Fiori (Timesheets)
          </Trans>
          <svg height="14px" version="1.1" viewBox="0 0 14 14" width="14px">
            <g fill="none" fillRule="evenodd" id="Booking" stroke="none" strokeWidth="1">
              <g id="p-profile" transform="translate(-296.000000, -476.000000)">
                <g id="Group-3" transform="translate(30.000000, 25.000000)">
                  <g id="Group" transform="translate(20.000000, 441.000000)">
                    <rect fill="transparent" height="34" id="Rectangle" rx="6" width="270" x="0" y="0"></rect>
                    <g fill={config.theme.primary} fillRule="nonzero" id="icons/spaces/shared" transform="translate(246.000000, 10.000000)">
                      <path d="M1.92588386,6.27512627 C2.32622057,5.87478956 2.97529462,5.87478956 3.37563133,6.27512627 C3.77596804,6.67546297 3.77596804,7.32453703 3.37563133,7.72487373 L2.6507576,8.44974747 C1.85008418,9.25042089 1.85008418,10.548569 2.6507576,11.3492424 C3.45143101,12.1499158 4.74957911,12.1499158 5.55025253,11.3492424 L7.72487373,9.1746212 C8.48340645,8.41608849 8.52332922,7.21106881 7.84464206,6.40553747 L7.72487373,6.27512627 C7.32453703,5.87478956 7.32453703,5.22571551 7.72487373,4.8253788 C8.12521044,4.42504209 8.77428449,4.42504209 9.1746212,4.8253788 C10.775968,6.42672563 10.775968,9.02302184 9.1746212,10.6243687 L7,12.7989899 C5.39865316,14.4003367 2.80235696,14.4003367 1.20101013,12.7989899 C-0.400336709,11.197643 -0.400336709,8.60134684 1.20101013,7 L1.92588386,6.27512627 Z M7,1.20101013 C8.60134684,-0.400336709 11.197643,-0.400336709 12.7989899,1.20101013 C14.4003367,2.80235696 14.4003367,5.39865316 12.7989899,7 C12.3986532,7.40033671 11.7495791,7.40033671 11.3492424,7 C10.9489057,6.59966329 10.9489057,5.95058924 11.3492424,5.55025253 C12.1499158,4.74957911 12.1499158,3.45143101 11.3492424,2.6507576 C10.548569,1.85008418 9.25042089,1.85008418 8.44974747,2.6507576 L7,4.10050506 C6.23572083,4.86478423 6.20098087,6.08234096 6.89578011,6.88782718 L7,7 C7.40033671,7.40033671 7.40033671,8.04941076 7,8.44974747 C6.59966329,8.85008418 5.95058924,8.85008418 5.55025253,8.44974747 C3.9489057,6.84840063 3.9489057,4.25210443 5.55025253,2.6507576 L7,1.20101013 Z" id="Shape" transform="translate(7.000000, 7.000000) scale(-1, -1) translate(-7.000000, -7.000000) "></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </a>
        <a href="https://hrlink.interpublic.com" rel="noreferrer" target="_blank">
          <Trans>
            Hrlink (Vacation Tracking)
          </Trans>
          <svg height="14px" version="1.1" viewBox="0 0 14 14" width="14px">
            <g fill="none" fillRule="evenodd" id="Booking" stroke="none" strokeWidth="1">
              <g id="p-profile" transform="translate(-296.000000, -476.000000)">
                <g id="Group-3" transform="translate(30.000000, 25.000000)">
                  <g id="Group" transform="translate(20.000000, 441.000000)">
                    <rect fill="transparent" height="34" id="Rectangle" rx="6" width="270" x="0" y="0"></rect>
                    <g fill={config.theme.primary} fillRule="nonzero" id="icons/spaces/shared" transform="translate(246.000000, 10.000000)">
                      <path d="M1.92588386,6.27512627 C2.32622057,5.87478956 2.97529462,5.87478956 3.37563133,6.27512627 C3.77596804,6.67546297 3.77596804,7.32453703 3.37563133,7.72487373 L2.6507576,8.44974747 C1.85008418,9.25042089 1.85008418,10.548569 2.6507576,11.3492424 C3.45143101,12.1499158 4.74957911,12.1499158 5.55025253,11.3492424 L7.72487373,9.1746212 C8.48340645,8.41608849 8.52332922,7.21106881 7.84464206,6.40553747 L7.72487373,6.27512627 C7.32453703,5.87478956 7.32453703,5.22571551 7.72487373,4.8253788 C8.12521044,4.42504209 8.77428449,4.42504209 9.1746212,4.8253788 C10.775968,6.42672563 10.775968,9.02302184 9.1746212,10.6243687 L7,12.7989899 C5.39865316,14.4003367 2.80235696,14.4003367 1.20101013,12.7989899 C-0.400336709,11.197643 -0.400336709,8.60134684 1.20101013,7 L1.92588386,6.27512627 Z M7,1.20101013 C8.60134684,-0.400336709 11.197643,-0.400336709 12.7989899,1.20101013 C14.4003367,2.80235696 14.4003367,5.39865316 12.7989899,7 C12.3986532,7.40033671 11.7495791,7.40033671 11.3492424,7 C10.9489057,6.59966329 10.9489057,5.95058924 11.3492424,5.55025253 C12.1499158,4.74957911 12.1499158,3.45143101 11.3492424,2.6507576 C10.548569,1.85008418 9.25042089,1.85008418 8.44974747,2.6507576 L7,4.10050506 C6.23572083,4.86478423 6.20098087,6.08234096 6.89578011,6.88782718 L7,7 C7.40033671,7.40033671 7.40033671,8.04941076 7,8.44974747 C6.59966329,8.85008418 5.95058924,8.85008418 5.55025253,8.44974747 C3.9489057,6.84840063 3.9489057,4.25210443 5.55025253,2.6507576 L7,1.20101013 Z" id="Shape" transform="translate(7.000000, 7.000000) scale(-1, -1) translate(-7.000000, -7.000000) "></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </a>
        <a href="https://pm.interpublic.com" rel="noreferrer" target="_blank">
          <Trans>
            Profile Manager (change pass.)
          </Trans>
          <svg height="14px" version="1.1" viewBox="0 0 14 14" width="14px">
            <g fill="none" fillRule="evenodd" id="Booking" stroke="none" strokeWidth="1">
              <g id="p-profile" transform="translate(-296.000000, -476.000000)">
                <g id="Group-3" transform="translate(30.000000, 25.000000)">
                  <g id="Group" transform="translate(20.000000, 441.000000)">
                    <rect fill="transparent" height="34" id="Rectangle" rx="6" width="270" x="0" y="0"></rect>
                    <g fill={config.theme.primary} fillRule="nonzero" id="icons/spaces/shared" transform="translate(246.000000, 10.000000)">
                      <path d="M1.92588386,6.27512627 C2.32622057,5.87478956 2.97529462,5.87478956 3.37563133,6.27512627 C3.77596804,6.67546297 3.77596804,7.32453703 3.37563133,7.72487373 L2.6507576,8.44974747 C1.85008418,9.25042089 1.85008418,10.548569 2.6507576,11.3492424 C3.45143101,12.1499158 4.74957911,12.1499158 5.55025253,11.3492424 L7.72487373,9.1746212 C8.48340645,8.41608849 8.52332922,7.21106881 7.84464206,6.40553747 L7.72487373,6.27512627 C7.32453703,5.87478956 7.32453703,5.22571551 7.72487373,4.8253788 C8.12521044,4.42504209 8.77428449,4.42504209 9.1746212,4.8253788 C10.775968,6.42672563 10.775968,9.02302184 9.1746212,10.6243687 L7,12.7989899 C5.39865316,14.4003367 2.80235696,14.4003367 1.20101013,12.7989899 C-0.400336709,11.197643 -0.400336709,8.60134684 1.20101013,7 L1.92588386,6.27512627 Z M7,1.20101013 C8.60134684,-0.400336709 11.197643,-0.400336709 12.7989899,1.20101013 C14.4003367,2.80235696 14.4003367,5.39865316 12.7989899,7 C12.3986532,7.40033671 11.7495791,7.40033671 11.3492424,7 C10.9489057,6.59966329 10.9489057,5.95058924 11.3492424,5.55025253 C12.1499158,4.74957911 12.1499158,3.45143101 11.3492424,2.6507576 C10.548569,1.85008418 9.25042089,1.85008418 8.44974747,2.6507576 L7,4.10050506 C6.23572083,4.86478423 6.20098087,6.08234096 6.89578011,6.88782718 L7,7 C7.40033671,7.40033671 7.40033671,8.04941076 7,8.44974747 C6.59966329,8.85008418 5.95058924,8.85008418 5.55025253,8.44974747 C3.9489057,6.84840063 3.9489057,4.25210443 5.55025253,2.6507576 L7,1.20101013 Z" id="Shape" transform="translate(7.000000, 7.000000) scale(-1, -1) translate(-7.000000, -7.000000) "></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </a>
        <a href="https://interpublic.sharepoint.com/sites/IPGMediabrandsKinessoInformationTechnology" rel="noreferrer" target="_blank">
          <Trans>
            MBW/KSO IT News & Information
          </Trans>
          <svg height="14px" version="1.1" viewBox="0 0 14 14" width="14px">
            <g fill="none" fillRule="evenodd" id="Booking" stroke="none" strokeWidth="1">
              <g id="p-profile" transform="translate(-296.000000, -476.000000)">
                <g id="Group-3" transform="translate(30.000000, 25.000000)">
                  <g id="Group" transform="translate(20.000000, 441.000000)">
                    <rect fill="transparent" height="34" id="Rectangle" rx="6" width="270" x="0" y="0"></rect>
                    <g fill={config.theme.primary} fillRule="nonzero" id="icons/spaces/shared" transform="translate(246.000000, 10.000000)">
                      <path d="M1.92588386,6.27512627 C2.32622057,5.87478956 2.97529462,5.87478956 3.37563133,6.27512627 C3.77596804,6.67546297 3.77596804,7.32453703 3.37563133,7.72487373 L2.6507576,8.44974747 C1.85008418,9.25042089 1.85008418,10.548569 2.6507576,11.3492424 C3.45143101,12.1499158 4.74957911,12.1499158 5.55025253,11.3492424 L7.72487373,9.1746212 C8.48340645,8.41608849 8.52332922,7.21106881 7.84464206,6.40553747 L7.72487373,6.27512627 C7.32453703,5.87478956 7.32453703,5.22571551 7.72487373,4.8253788 C8.12521044,4.42504209 8.77428449,4.42504209 9.1746212,4.8253788 C10.775968,6.42672563 10.775968,9.02302184 9.1746212,10.6243687 L7,12.7989899 C5.39865316,14.4003367 2.80235696,14.4003367 1.20101013,12.7989899 C-0.400336709,11.197643 -0.400336709,8.60134684 1.20101013,7 L1.92588386,6.27512627 Z M7,1.20101013 C8.60134684,-0.400336709 11.197643,-0.400336709 12.7989899,1.20101013 C14.4003367,2.80235696 14.4003367,5.39865316 12.7989899,7 C12.3986532,7.40033671 11.7495791,7.40033671 11.3492424,7 C10.9489057,6.59966329 10.9489057,5.95058924 11.3492424,5.55025253 C12.1499158,4.74957911 12.1499158,3.45143101 11.3492424,2.6507576 C10.548569,1.85008418 9.25042089,1.85008418 8.44974747,2.6507576 L7,4.10050506 C6.23572083,4.86478423 6.20098087,6.08234096 6.89578011,6.88782718 L7,7 C7.40033671,7.40033671 7.40033671,8.04941076 7,8.44974747 C6.59966329,8.85008418 5.95058924,8.85008418 5.55025253,8.44974747 C3.9489057,6.84840063 3.9489057,4.25210443 5.55025253,2.6507576 L7,1.20101013 Z" id="Shape" transform="translate(7.000000, 7.000000) scale(-1, -1) translate(-7.000000, -7.000000) "></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </a>
      </Box>
    </Box>
  );
}
