import { CalendarViewSchedule, DailyCalendarView, DailyCalendarViewItem, MeetingsWrapper } from "components";
import { ReservationCateringDetailsDialog } from "components/reservation-details";
import { endOfDay, isSameDay, startOfDay } from "date-fns";
import { UserRole } from "enums";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReservationStatus, ReservationType, adminSlice, selectAdminMeetingsQuery, useGetMeQuery, useLazyGetReservationsQuery } from "store";
import { matchArray } from "utils";

export const MeetingsCalendarView: React.FC = () => {
  const dispatch = useDispatch();
  const query = useSelector(selectAdminMeetingsQuery);
  const [detailsDialogIsOpen, setDetailsDialogIsOpen] = useState(false);
  const [reservationId, setReservationId] = useState<string>();
  const [entryId, setEntryId] = useState<string>();
  const [triggerGetReservationsQuery, getReservationsQuery] = useLazyGetReservationsQuery();
  const getMeQuery = useGetMeQuery();
  const { data: user } = getMeQuery?.data?.result || {};

  useEffect(() => {
    if (user) {
      const selectedDay = query?.range?.length ? new Date(query?.range[0]) : new Date();
      const filter: Record<string, string> = {
        type: ReservationType.ROOM,
        "schedule.entries.startDate": `$btw:${startOfDay(selectedDay).toISOString()},${endOfDay(selectedDay).toISOString()}`,
      };

      if (query?.requestedServices?.length) {
        filter.requestedServices = `$cts:${query.requestedServices.join(",")}`;
      }

      if (query?.statuses?.length) {
        filter.status = `$in:${query?.statuses?.join(",")}`;
      }

      if (query?.roomIds?.length) {
        filter["roomId"] = `$in:${query.roomIds.map(([, roomId]) => roomId).join(",")}`;
      } else if (query?.floorIds?.length) {
        filter["floor.id"] = `$in:${query.floorIds.map(([, floorId]) => floorId).join(",")}`;
      } else if (query?.locationIds?.length) {
        filter["floor.location.id"] = `$in:${query.locationIds.join(",")}`;
      } else if (user.role && [UserRole.LOCAL_ADMIN, UserRole.CATERING_ADMIN].includes(user.role)) {
        filter["floor.location.id"] = `$in:${user.locationIds?.join(",")}`;
      }

      triggerGetReservationsQuery({
        filter,
        page: 1,
        limit: -1,
        search: query?.search,
        singleEntryListing: true,
        orderBy: "desc:schedule.entries.startDate",
        include: ["schedule", "schedule.entries", "user", "room", "floor", "floor.location", "attendees"],
      });
    }
  }, [query?.search, JSON.stringify(query), JSON.stringify(user)]);

  const handleSelectDayChange = (date: Date) => {
    if (!isSameDay(date, selectedDay)) {
      dispatch(adminSlice.actions.setMeetingsQuery({ range: [date.toISOString()] }));
    }
  };

  const handleScheduleClick = (reservationId: string, entryId: string) => {
    setReservationId(reservationId);
    setEntryId(entryId);
    setDetailsDialogIsOpen(true);
  };

  const {
    data: getReservationsResponse,
    isLoading: reservationsAreLoading ,
    isFetching: reservationsAreFetching,
    isUninitialized,
  } = getReservationsQuery;
  const { items: reservations = [] } = getReservationsResponse?.result?.data || {};
  const isLoading = reservationsAreLoading || reservationsAreFetching || isUninitialized;
  const selectedDay = query?.range?.length === 1 ? new Date(query.range[0]) : new Date();
  const items: DailyCalendarViewItem[] = [];

  for (const reservation of reservations) {
    const { id, status, room, user, schedule, floor } = reservation;
    const [entry] = schedule?.entries || [];

    if (room && user && entry) {
      const background = matchArray([
        [status === ReservationStatus.BOOKED, "#DEF3D7"],
        [status === ReservationStatus.CANCELED, "#FFE4DB"],
        [status === ReservationStatus.FAILED, "#E0BFBF"],
        [status === ReservationStatus.PENDING, "#FFF2CF"],
        [status === ReservationStatus.PROCESSING, "#CFF0FF"],
      ]);
      const schedule: CalendarViewSchedule = {
        background,
        startDate: new Date(entry.startDate),
        endDate: new Date(entry.endDate),
        organizer: { displayName: user.name },
        summary: user.name,
        reservationId: id,
        entryId: entry.id,
      };
      const item = items.find(({ id }) => id === room.id);

      if (item) {
        item.schedules?.push(schedule);
      } else {
        items.push({
          id: room.id,
          name: room.name,
          capacity: room.capacity,
          floorId: floor?.id,
          locationId: floor?.location?.id,
          schedules: [schedule],
        });
      }
    }
  }

  return (
    <>
      <MeetingsWrapper>
        <DailyCalendarView
          disableSelection
          isFirstLoad={isLoading}
          isLoading={isLoading}
          items={items}
          onScheduleClick={handleScheduleClick}
          onSelectedDayChange={handleSelectDayChange}
          selectedDay={selectedDay}
          timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}
        />
      </MeetingsWrapper>
      <ReservationCateringDetailsDialog
        entryId={entryId}
        onClose={() => setDetailsDialogIsOpen(false)}
        open={detailsDialogIsOpen}
        reservationId={reservationId}
      />
    </>
  );
};
