import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useTypedSelector } from "store/_legacy/Redux/store";
import { Trans, t } from "@lingui/macro";
import styles from "./styles.module.scss";

import Box from "components/_legacy/Box";
import Button from "components/_legacy/Button";
import EmailSearching from "./EmailSearching";
import ReservationJustInTimeLocation from "./Location";
import ReservationJustInTimeDate from "./DateTime";
import ReservationJustInTimeFloor from "./Floor";
import ReservationJustInTimeDesk from "./Desk";
import ReservationJustInTimeParkingSpot from "./ParkingSpot";
import BookDesk from "./CreateBooking";
import AddUserButton from "components/_legacy/Header/AddUserButton";
import { setJustInTimeReservationData } from "../../../Store/reservationJustInTime";
import { getClosestAvailableDay } from "../../../../App/Functions/Helpers";

export default function ReservationJustInTimeDetails() {
  const dispatch = useDispatch();
  const { locations } = useTypedSelector(state => state);
  const { selectedData, weeklySlots } = useTypedSelector(state => state.adminReservationJustInTime);
  const { user, dateTime, location, floor, desk, parkingSpot } = selectedData;

  const isDataChanged = !!(user || location || dateTime || floor || desk || parkingSpot);

  // disable fields classes
  const disableDateTimeClass = location ? '' : styles.disabledBlock;
  const disableFloorClass = location && dateTime ? '' : styles.disabledBlock;
  const disableDeskClass = location && dateTime && floor ? '' : styles.disabledBlock;
  const disableParkingSpotClass = location && dateTime ? '' : styles.disabledBlock;

  const onResetData = () => {
    const closestDay = getClosestAvailableDay(new Date(), locations.locationDisabledDays);

    dispatch(setJustInTimeReservationData({
      bookingType: "custom",
      dateFrom: closestDay,
      dateTo: closestDay,
      timeFrom: new Date(1970, 0 , 1, 9),
      timeTo: new Date(1970, 0 , 1, 18),
      weeklySlots: weeklySlots?.map((weeklySlot) => ({
        ...weeklySlot,
        isSelected: false,
        timeFrom: new Date(1970, 0 , 1, 9),
        timeTo: new Date(1970, 0 , 1, 18),
      })),
      selectedData: {
        ...selectedData,
        user: null,
        location: null,
        dateTime: '',
        floor: null,
        desk: null,
        parkingSpot: null,
      },
    }));
  };

  useEffect(() => {
    onResetData();
  }, []);

  return (
    <Box className={styles.bookingDetails}>
      <Box alignItems="center" display="flex" justifyContent="between" marginBottom={15}>
        <h3>Booking details</h3>
        <Button
          aria-label={t`reset reservation data`}
          disabled={!isDataChanged}
          name={t`reset reservation data`}
          onClick={onResetData}
          size="xs"
        >
          <Trans>Reset</Trans>
        </Button>
      </Box>
      <Box marginBottom={15}>
        <Box alignItems="center" display="flex" justifyContent="between">
          <div><span className={styles.requiredSign}>*</span><label htmlFor="full-name"><Trans>User</Trans></label></div>
          <AddUserButton buttonType="thin" />
        </Box>
        <EmailSearching />
      </Box>
      <Box marginBottom={15}>
        <span className={styles.requiredSign}>*</span><label htmlFor="full-name"><Trans>Location</Trans></label>
        <ReservationJustInTimeLocation />
      </Box>
      <Box className={disableDateTimeClass} marginBottom={15}>
        <span className={styles.requiredSign}>*</span><label htmlFor="full-name"><Trans>Date and time</Trans></label>
        <ReservationJustInTimeDate dateTime={dateTime} />
      </Box>
      <Box className={disableFloorClass} marginBottom={15}>
        <span className={styles.requiredSign}>*</span><label htmlFor="full-name"><Trans>Floor</Trans></label>
        <ReservationJustInTimeFloor
          isDisable={!!disableFloorClass}
        />
      </Box>
      <Box className={disableDeskClass} marginBottom={20}>
        <span className={styles.requiredSign}>*</span><label htmlFor="full-name"><Trans>Desk</Trans></label>
        <ReservationJustInTimeDesk
          isDisable={!!disableDeskClass}
        />
      </Box>
      <Box className={disableParkingSpotClass} marginBottom={20}>
        <label htmlFor="parkingSpot">
          <Trans>
            Parking Spot
          </Trans>
        </label>

        <ReservationJustInTimeParkingSpot
          isDisable={!!disableParkingSpotClass}
        />
      </Box>

      <BookDesk />
    </Box>
  );
}
