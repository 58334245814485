import { t } from "@lingui/macro";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, FilledInput, InputProps, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover, Skeleton, Typography, useTheme } from "@mui/material";
import { Tab, Tabs } from "../tabs";
import { useHistory, useLocation } from "react-router-dom";
import { FilterList, KeyboardArrowDown, Search, SearchRounded } from "@mui/icons-material";
import { useDebouncedCallback } from "hooks";
import { useDispatch, useSelector } from "react-redux";
import { ReservationServiceType, ReservationStatus, adminSlice, selectAdminMeetingsQuery, useGetMeQuery, useLazyGetFloorsQuery, useLazyGetLocationsQuery, useLazyGetRoomsQuery } from "store";
import React, { useEffect, useState } from "react";
import { CountBadge, IconBox, TimeZoneDisclaimer } from "components";
import { UserRole } from "enums";
import { resolveReservationStatusLabel } from "utils/resolve-reservation-status-label";
import { resolveReservationStatusColor } from "utils/resolve-reservation-status-color";

const statuses = [
  ReservationStatus.BOOKED,
  ReservationStatus.CANCELED,
  ReservationStatus.PENDING,
  ReservationStatus.FAILED,
  ReservationStatus.PROCESSING,
];

const FiltersPopover: React.FC<{ anchorEl: HTMLButtonElement | null; open: boolean; onClose: () => void }> = (props) => {
  const { anchorEl, open, onClose } = props;
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const query = useSelector(selectAdminMeetingsQuery);
  const [locationIsExpanded, setLocationIsExpanded] = useState(false);
  const [locationSearch, setLocationSearch] = useState("");
  const [floorIsExpanded, setFloorIsExpanded] = useState(false);
  const [floorSearch, setFloorSearch] = useState("");
  const [roomIsExpanded, setRoomIsExpanded] = useState(false);
  const [roomSearch, setRoomSearch] = useState("");
  const getMeQuery = useGetMeQuery();
  const [triggerGetLocationsQuery, getLocationsQuery] = useLazyGetLocationsQuery();
  const [triggerGetFloorsQuery, getFloorsQuery] = useLazyGetFloorsQuery();
  const [triggerGetRoomsQuery, getRoomsQuery] = useLazyGetRoomsQuery();
  const { data: user } = getMeQuery?.data?.result || {};

  useEffect(() => {
    if (!user) {
      return;
    }

    if (locationIsExpanded) {
      const filter = user.role && [UserRole.CATERING_ADMIN, UserRole.LOCAL_ADMIN].includes(user.role)
        ? { "id": `$in:${user.locationIds?.join(",")}` }
        : undefined;
      const trigger = () => triggerGetLocationsQuery(
        { filter, limit: -1, search: locationSearch || undefined, custom: { withRoomsOnly: true } },
        true,
      );

      if (locationSearch) {
        const timeout = setTimeout(trigger, 700);

        return () => clearTimeout(timeout);
      } else {
        trigger();
      }
    }
  }, [JSON.stringify(user), locationIsExpanded, locationSearch]);

  useEffect(() => {
    if (!floorIsExpanded) {
      return;
    }

    if (query?.locationIds?.length) {
      const filter = { "location.id": `$in:${query.locationIds.join(",")}` };
      const trigger = () => triggerGetFloorsQuery({ filter, include: ["location"],  limit: -1, search: floorSearch || undefined }, true);

      if (floorSearch) {
        const timeout = setTimeout(trigger, 700);

        return () => clearTimeout(timeout);
      } else {
        trigger();
      }
    } else {
      setFloorIsExpanded(false);
      setRoomIsExpanded(false);
    }
  }, [floorIsExpanded, floorSearch, JSON.stringify(query?.locationIds)]);

  useEffect(() => {
    if (!roomIsExpanded) {
      return;
    }

    if (query?.floorIds?.length) {
      const filter = { "floor.id": `$in:${query.floorIds.join(",")}` };
      const trigger = () => triggerGetRoomsQuery({ filter, include: ["floor"], limit: -1, search: roomSearch || undefined }, true);

      if (roomSearch) {
        const timeout = setTimeout(trigger, 700);

        return () => clearTimeout(timeout);
      } else {
        trigger();
      }
    } else {
      setRoomIsExpanded(false);
    }
  }, [roomIsExpanded, roomSearch, JSON.stringify(query?.floorIds)]);

  const handleResetClick = () => {
    dispatch(adminSlice.actions.setMeetingsQuery({
      locationIds: undefined,
      floorIds: undefined,
      roomIds: undefined,
      requestedServices: undefined,
      statuses: undefined,
    }));
  };

  const handleLocationClick = (locationId: string) => {
    if (query?.locationIds?.includes(locationId)) {
      const locationIds = query?.locationIds.filter((id) => id !== locationId);
      const floorIds = query?.floorIds?.filter(([id]) => id !== locationId);
      const roomIds = query?.roomIds?.filter(([floorId]) => floorIds?.some(([, id]) => id === floorId));

      dispatch(adminSlice.actions.setMeetingsQuery({ locationIds, floorIds, roomIds }));
    } else {
      dispatch(adminSlice.actions.setMeetingsQuery({ locationIds: [...(query?.locationIds || []), locationId] }));
    }
  };

  const handleFloorClick = (locationId: string, floorId: string) => {
    if (query?.floorIds?.some(([, id]) => id === floorId)) {
      const floorIds = query?.floorIds?.filter(([, id]) => id !== floorId);
      const roomIds = query?.roomIds?.filter(([floorId]) => floorIds?.some(([, id]) => id === floorId));

      dispatch(adminSlice.actions.setMeetingsQuery({ floorIds, roomIds }));
    } else {
      dispatch(adminSlice.actions.setMeetingsQuery({ floorIds: [...(query?.floorIds || []), [locationId, floorId]] }));
    }
  };

  const handleRoomClick = (floorId: string, roomId: string) => {
    if (query?.roomIds?.some(([, id]) => id === roomId)) {
      const roomIds = query?.roomIds?.filter(([, id]) => id === roomId);

      dispatch(adminSlice.actions.setMeetingsQuery({ roomIds }));
    } else {
      dispatch(adminSlice.actions.setMeetingsQuery({ roomIds: [...(query?.roomIds || []), [floorId, roomId]] }));
    }
  };

  const handleReservationServiceClick = (type: ReservationServiceType) => {
    if (query?.requestedServices?.includes(type)) {
      dispatch(adminSlice.actions.setMeetingsQuery({ requestedServices: query?.requestedServices?.filter((value) => value !== type) }));
    } else {
      dispatch(adminSlice.actions.setMeetingsQuery({ requestedServices: [...(query?.requestedServices || []), type] }));
    }
  };

  const handleStatusClick = (status: ReservationStatus) => {
    if (query?.statuses?.includes(status)) {
      dispatch(adminSlice.actions.setMeetingsQuery({ statuses: query?.statuses?.filter((value) => value !== status) }));
    } else {
      dispatch(adminSlice.actions.setMeetingsQuery({ statuses: [...(query?.statuses || []), status] }));
    }
  };

  const { data: getLocationsResponse, isLoading: locationsAreLoading } = getLocationsQuery;
  const { items: locations } = getLocationsResponse?.result?.data || {};
  const { data: getFloorsResponse, isLoading: floorsAreLoading } = getFloorsQuery;
  const { items: floors } = getFloorsResponse?.result?.data || {};
  const { data: getRoomsResponse, isLoading: roomsAreLoading } = getRoomsQuery;
  const { items: rooms } = getRoomsResponse?.result?.data || {};
  const count = (query?.statuses?.length || 0)
    + (query?.requestedServices?.length || 0)
    + (query?.locationIds?.length || 0)
    + (query?.floorIds?.length || 0)
    + (query?.roomIds?.length || 0);
  const skeleton = (
    <>
      <Box alignItems="center" display="flex" gap={1} mb={1}>
        <Skeleton height={24} variant="rectangular" width={24} />
        <Skeleton height={21} variant="rectangular" width={220} />
      </Box>
      <Box alignItems="center" display="flex" gap={1}>
        <Skeleton height={24} variant="rectangular" width={24} />
        <Skeleton height={21} variant="rectangular" width={220} />
      </Box>
    </>
  );

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      onClose={onClose}
      open={open}
      sx={{ mt: 1 }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Box p={2} width={380}>
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Box alignItems="center" display="flex" gap={1}>
            <IconBox>
              <FilterList sx={{ color: palette.grey[700] }} />
            </IconBox>
            <Typography fontWeight={600}>{t`Filters`}</Typography>
            <CountBadge>{count}</CountBadge>
          </Box>
          <Button onClick={() => handleResetClick()}>{t`Reset`}</Button>
        </Box>
        <Accordion
          disableGutters
          elevation={0}
          sx={{ "&::before": { display: "none" }, mb: 1 }}
        >
          <AccordionSummary
            expandIcon={<KeyboardArrowDown color="primary" />}
            sx={{ p: 0, minHeight: 0, "& .MuiAccordionSummary-content": { margin: 0 } }}
          >
            <Typography fontSize={14} fontWeight={600}>{t`Status`}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0, pt: 1 }}>
            <List disablePadding sx={{ maxHeight: 145, overflowY: "auto", overflowX: "hidden" }}>
              {statuses.map((status) => (
                <ListItem disablePadding key={status}>
                  <ListItemButton onClick={() => handleStatusClick(status)} sx={{ p: 0 }}>
                    <ListItemIcon sx={{ minWidth: 0 }}>
                      <Checkbox
                        checked={!!query?.statuses?.includes(status)}
                        disableRipple
                        sx={{ p: 0, pr: 1 }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={resolveReservationStatusLabel(status)}
                      sx={{
                        "& .MuiListItemText-primary": {
                          fontSize: 14,
                          color: resolveReservationStatusColor(status),
                          textTransform: "capitalize",
                        },
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
        <Accordion
          disableGutters
          elevation={0}
          sx={{ "&::before": { display: "none" }, mb: 1 }}
        >
          <AccordionSummary
            expandIcon={<KeyboardArrowDown color="primary" />}
            sx={{ p: 0, minHeight: 0, "& .MuiAccordionSummary-content": { margin: 0 } }}
          >
            <Typography fontSize={14} fontWeight={600}>{t`Requested services`}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0, pt: 1 }}>
            <List disablePadding sx={{ maxHeight: 145, overflowY: "auto", overflowX: "hidden" }}>
              <ListItem disablePadding>
                <ListItemButton onClick={() => handleReservationServiceClick(ReservationServiceType.CATERING)} sx={{ p: 0 }}>
                  <ListItemIcon sx={{ minWidth: 0 }}>
                    <Checkbox
                      checked={!!query?.requestedServices?.includes(ReservationServiceType.CATERING)}
                      disableRipple
                      sx={{ p: 0, pr: 1 }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={t`Catering`} sx={{ "& .MuiListItemText-primary": { fontSize: 14 } }} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={() => handleReservationServiceClick(ReservationServiceType.IT_SUPPORT)} sx={{ p: 0 }}>
                  <ListItemIcon sx={{ minWidth: 0 }}>
                    <Checkbox
                      checked={!!query?.requestedServices?.includes(ReservationServiceType.IT_SUPPORT)}
                      disableRipple
                      sx={{ p: 0, pr: 1 }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={t`IT Support`} sx={{ "& .MuiListItemText-primary": { fontSize: 14 } }} />
                </ListItemButton>
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>
        <Accordion
          disableGutters
          elevation={0}
          expanded={locationIsExpanded}
          onChange={(_, expanded) => setLocationIsExpanded(expanded)}
          sx={{ "&::before": { display: "none" }, mb: 1 }}
        >
          <AccordionSummary
            expandIcon={<KeyboardArrowDown color="primary" />}
            sx={{ p: 0, minHeight: 0, "& .MuiAccordionSummary-content": { margin: 0 } }}
          >
            <Typography fontSize={14} fontWeight={600}>{t`Location`}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0, pt: 1 }}>
            <FilledInput
              fullWidth
              onChange={(event) => setLocationSearch(event.currentTarget.value)}
              placeholder={t`Search`}
              size="small"
              startAdornment={<SearchRounded sx={{ color: palette.grey[700] }} />}
              sx={{ mb: 1, "& .MuiFilledInput-input": { fontSize: 14, lineHeight: 1 } }}
              value={locationSearch}
            />
            {locationsAreLoading ? skeleton : (
              <List disablePadding sx={{ maxHeight: 145, overflowY: "auto", overflowX: "hidden" }}>
                {locations?.map(({ id, name }) => {
                  console.log(id);
                  console.log(name);
                  console.log(query?.locationIds);

                  return (
                    <ListItem disablePadding key={id}>
                      <ListItemButton onClick={() => handleLocationClick(id)} sx={{ p: 0 }}>
                        <ListItemIcon sx={{ minWidth: 0 }}>
                          <Checkbox checked={!!query?.locationIds?.includes(id)} disableRipple sx={{ p: 0, pr: 1 }} />
                        </ListItemIcon>
                        <ListItemText primary={name} sx={{ "& .MuiListItemText-primary": { fontSize: 14 } }} />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          disableGutters
          disabled={!query?.locationIds?.length}
          elevation={0}
          expanded={floorIsExpanded}
          onChange={(_, expanded) => setFloorIsExpanded(expanded)}
          sx={{ "&::before": { display: "none" }, mb: 1, "&.Mui-disabled": { bgcolor: "unset" } }}
        >
          <AccordionSummary
            expandIcon={<KeyboardArrowDown color="primary" />}
            sx={{ p: 0, minHeight: 0, "& .MuiAccordionSummary-content": { margin: 0 } }}
          >
            <Typography fontSize={14} fontWeight={600}>{t`Floor`}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0, pt: 1 }}>
            <FilledInput
              fullWidth
              onChange={(event) => setFloorSearch(event.currentTarget.value)}
              placeholder={t`Search`}
              size="small"
              startAdornment={<SearchRounded sx={{ color: palette.grey[700] }} />}
              sx={{ mb: 1, "& .MuiFilledInput-input": { fontSize: 14, lineHeight: 1 } }}
              value={floorSearch}
            />
            {floorsAreLoading ? skeleton : (
              <List disablePadding sx={{ maxHeight: 145, overflowY: "auto", overflowX: "hidden" }}>
                {floors?.map(({ id, name, locationId }) => (
                  <ListItem disablePadding key={id}>
                    <ListItemButton onClick={() => handleFloorClick(locationId || "", id)} sx={{ p: 0 }}>
                      <ListItemIcon sx={{ minWidth: 0 }}>
                        <Checkbox checked={!!query?.floorIds?.some(([, floorId]) => floorId === id)} disableRipple sx={{ p: 0, pr: 1 }} />
                      </ListItemIcon>
                      <ListItemText primary={name} sx={{ "& .MuiListItemText-primary": { fontSize: 14 } }} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          disableGutters
          disabled={!query?.floorIds?.length || !query?.locationIds?.length}
          elevation={0}
          expanded={roomIsExpanded}
          onChange={(_, expanded) => setRoomIsExpanded(expanded)}
          sx={{ "&::before": { display: "none" }, "&.Mui-disabled": { bgcolor: "unset" } }}
        >
          <AccordionSummary
            expandIcon={<KeyboardArrowDown color="primary" />}
            sx={{ p: 0, minHeight: 0, "& .MuiAccordionSummary-content": { margin: 0 } }}
          >
            <Typography fontSize={14} fontWeight={600}>{t`Room`}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0, pt: 1 }}>
            <FilledInput
              fullWidth
              onChange={(event) => setRoomSearch(event.currentTarget.value)}
              placeholder={t`Search`}
              size="small"
              startAdornment={<SearchRounded sx={{ color: palette.grey[700] }} />}
              sx={{ mb: 1, "& .MuiFilledInput-input": { fontSize: 14, lineHeight: 1 } }}
              value={roomSearch}
            />
            {roomsAreLoading ? skeleton : (
              <List disablePadding sx={{ maxHeight: 145, overflowY: "auto", overflowX: "hidden" }}>
                {rooms?.map(({ id, name, floor }) => (
                  <ListItem disablePadding key={id}>
                    <ListItemButton onClick={() => handleRoomClick(floor?.id || "", id)} sx={{ p: 0 }}>
                      <ListItemIcon sx={{ minWidth: 0 }}>
                        <Checkbox checked={!!query?.roomIds?.some(([, roomId]) => roomId === id)} disableRipple sx={{ p: 0, pr: 1 }} />
                      </ListItemIcon>
                      <ListItemText primary={name} sx={{ "& .MuiListItemText-primary": { fontSize: 14 } }} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
    </Popover>
  );
};

export const MeetingsWrapper: React.FC<React.PropsWithChildren<{}>> = (props) => {
  const { children } = props;
  const { palette, background } = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useSelector(selectAdminMeetingsQuery);
  const [filtersAnchorEl, setFiltersAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { pathname } = useLocation();
  const isListView = /^\/admin\/meetings\/?$/.test(pathname);

  const setMeetingsQuerySearch = useDebouncedCallback<(search: string) => void>((search) => {
    dispatch(adminSlice.actions.setMeetingsQuery({ search }));
  }, []);

  const handleSearchChange: InputProps["onChange"] = (event) => {
    setMeetingsQuerySearch(event.currentTarget.value);
  };

  return (
    <Box py={2}>
      <Box mb={2}>
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Typography fontSize={22} fontWeight={600}>{t`Meetings`}</Typography>
          <Box display="flex" gap={2}>
            <FilledInput
              defaultValue={query?.search}
              onChange={handleSearchChange}
              placeholder={t`Search`}
              startAdornment={<Search sx={{ color: palette.grey[700] }} />}
            />
            <Button onClick={(event) => setFiltersAnchorEl(event.currentTarget)} startIcon={<FilterList />} variant="contained">{t`Filters`}</Button>
            <FiltersPopover anchorEl={filtersAnchorEl} onClose={() => setFiltersAnchorEl(null)} open={!!filtersAnchorEl} />
          </Box>
        </Box>
        <Tabs inline sx={{ padding: 0, bgcolor: "#fff", mb: 1 }} value={isListView ? 0 : 1}>
          <Tab
            data-cid="list-view-button"
            label={t`List View`}
            onClick={() => history.push("/admin/meetings")}
            sx={{ color: "#000", "&.Mui-selected": { bgcolor: background.blue.main } }}
          />
          <Tab
            data-cid="calendar-view-button"
            label={t`Calendar View`}
            onClick={() => history.push("/admin/meetings/calendar-view")}
            sx={{ color: "#000", "&.Mui-selected": { bgcolor: background.blue.main } }}
          />
        </Tabs>
        <TimeZoneDisclaimer timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone} tooltip={t`Derived from device time zone.`} />
      </Box>
      {children}
    </Box>
  );
};
