import { CaseReducer, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AdminState, AdminStateCampaignsCurrent, AdminStateCampaignsQuery, AdminStateMeetingsQuery } from "./types";
import { AdminStateCampaignsPolicies } from "./types/admin-state-campaigns-policies.type";

const initialState: AdminState = { meetings: {}, campaigns: { policies: {} } };

const setMeetingsQuery: CaseReducer<AdminState, PayloadAction<Partial<AdminStateMeetingsQuery>>> = (state, action) => ({
  ...state,
  meetings: { query: { ...state.meetings.query, ...action.payload } },
});

const setCurrentCampaign: CaseReducer<AdminState, PayloadAction<Partial<AdminStateCampaignsCurrent>>> = (state, action) => ({
  ...state,
  campaigns: { ...state.campaigns, current: { ...state.campaigns.current, ...action.payload } },
});

const setCampaignsQuery: CaseReducer<AdminState, PayloadAction<Partial<AdminStateCampaignsQuery>>> = (state, action) => ({
  ...state,
  campaigns: { ...state.campaigns, query: { ...state.campaigns.query, ...action.payload } },
});

const resetCurrentCampaign: CaseReducer<AdminState, PayloadAction<Partial<AdminStateCampaignsCurrent> | undefined>> = (state, action) => ({
  ...state,
  campaigns: { ...state.campaigns, current: action.payload },
});

const setCurrentCampaignPolicy: CaseReducer<AdminState, PayloadAction<Partial<AdminStateCampaignsPolicies["current"]>>> = (state, action) => ({
  ...state,
  campaigns: { ...state.campaigns, policies: { ...state.campaigns.policies, current: { ...state.campaigns.policies.current, ...action.payload } } },
});

const resetCurrentCampaignPolicy: CaseReducer<AdminState, PayloadAction<Partial<AdminStateCampaignsPolicies["current"]> | undefined>> = (
  state,
  action,
) => ({
  ...state,
  campaigns: { ...state.campaigns, policies: { ...state.campaigns.policies, current: action.payload } },
});

export const adminSlice = createSlice({
  initialState,
  name: "admin",
  reducers: { setMeetingsQuery, setCurrentCampaign, setCampaignsQuery, resetCurrentCampaign, setCurrentCampaignPolicy, resetCurrentCampaignPolicy },
});
