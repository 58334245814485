import React from "react";

interface IconProps {
  color?: string;
  margin?: string;
}

export function FilterIcon({ color = '#fff', margin = '0' }: IconProps) {
  return (
    <svg height="20px" style={{ margin }} viewBox="0 0 20 20" width="20px">
      <g fill="none" fillRule="evenodd" id="Booking" stroke="none" strokeWidth="1">
        <g fill={color} id="Createbooking2" transform="translate(-790.000000, -93.000000)">
          <g id="buttons/white" transform="translate(783.000000, 88.000000)">
            <g id="icons/datetimewhite" transform="translate(7.000000, 5.000000)">
              <path d="M12,15 C12.5522847,15 13,15.4477153 13,16 C13,16.5522847 12.5522847,17 12,17 L8,17 C7.44771525,17 7,16.5522847 7,16 C7,15.4477153 7.44771525,15 8,15 L12,15 Z M16,9 C16.5522847,9 17,9.44771525 17,10 C17,10.5522847 16.5522847,11 16,11 L4,11 C3.44771525,11 3,10.5522847 3,10 C3,9.44771525 3.44771525,9 4,9 L16,9 Z M18,3 C18.5522847,3 19,3.44771525 19,4 C19,4.55228475 18.5522847,5 18,5 L2,5 C1.44771525,5 1,4.55228475 1,4 C1,3.44771525 1.44771525,3 2,3 L18,3 Z" id="Combined-Shape"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
