import { ActionPayload, BaseErrorResponse, BaseResponse } from 'store/_legacy/Models/ReduxModels';
import { t } from '@lingui/macro';
import {
  GetPushNotificationsRequest,
  GetPushNotificationsResponse,
  PushNotificationModel,
  UpdatePushNotificationStatusRequest,
  UpdatePushNotificationStatusResponse,
} from './models';

export const GET_PUSH_NOTIFICATIONS = 'GET_PUSH_NOTIFICATIONS';
export const GET_PUSH_NOTIFICATIONS_FAIL = 'GET_PUSH_NOTIFICATIONS_FAIL';
export const GET_PUSH_NOTIFICATIONS_SUCCESS = 'GET_PUSH_NOTIFICATIONS_SUCCESS';

export const UPDATE_PUSH_NOTIFICATION_STATUS = 'UPDATE_PUSH_NOTIFICATION_STATUS';
export const UPDATE_PUSH_NOTIFICATION_STATUS_FAIL = 'UPDATE_PUSH_NOTIFICATION_STATUS_FAIL';
export const UPDATE_PUSH_NOTIFICATION_STATUS_SUCCESS = 'UPDATE_PUSH_NOTIFICATION_STATUS_SUCCESS';

// get list of all notifications
export interface GetPushNotifications {
  type: typeof GET_PUSH_NOTIFICATIONS;
  payload: ActionPayload<GetPushNotificationsRequest>;
}
interface GetPushNotificationsFail {
  type: typeof GET_PUSH_NOTIFICATIONS_FAIL;
  payload: BaseErrorResponse;
}
interface GetPushNotificationsSuccess {
  type: typeof GET_PUSH_NOTIFICATIONS_SUCCESS;
  payload: BaseResponse<GetPushNotificationsResponse>;
}

// update isWatched status
export interface UpdatePushNotificationStatus {
  type: typeof UPDATE_PUSH_NOTIFICATION_STATUS;
  payload: ActionPayload<UpdatePushNotificationStatusRequest>;
}
interface UpdatePushNotificationStatusFail {
  type: typeof UPDATE_PUSH_NOTIFICATION_STATUS_FAIL;
  payload: BaseErrorResponse;
}
interface UpdatePushNotificationStatusSuccess {
  type: typeof UPDATE_PUSH_NOTIFICATION_STATUS_SUCCESS;
  payload: BaseResponse<UpdatePushNotificationStatusResponse>;
}

type Actions = GetPushNotifications
  | GetPushNotificationsFail
  | GetPushNotificationsSuccess
  | UpdatePushNotificationStatus
  | UpdatePushNotificationStatusFail
  | UpdatePushNotificationStatusSuccess;

export interface State {
  error: string;
  pushNotifications: PushNotificationModel[];
  totalPages: number;
  page: number;
}

const initialState: State = {
  error: '',
  pushNotifications: [],
  totalPages: 1,
  page: 1,
};

export default function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case GET_PUSH_NOTIFICATIONS:
      return {
        ...state,
        error: '',
      };
    case GET_PUSH_NOTIFICATIONS_FAIL:
      return {
        ...state,
        error: t`There was an error loading locations.`,
      };
    case GET_PUSH_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        error: '',
        pushNotifications: action.payload.data.result.data.notifications,
        totalPages: action.payload.data.result.data.totalPages,
        page: action.payload.data.result.data.page,
      };
    }

    default:
      return state;
  }
}

// Actions
export function getPushNotifications(data: GetPushNotificationsRequest): GetPushNotifications {
  return {
    type: GET_PUSH_NOTIFICATIONS,
    payload: {
      request: {
        method: 'GET',
        url: `/api/users/${data.userId}/notifications-history?page=${data.page}`,
      },
    },
  };
}

export function updatePushNotificationStatus(data: UpdatePushNotificationStatusRequest): UpdatePushNotificationStatus {
  return {
    type: UPDATE_PUSH_NOTIFICATION_STATUS,
    payload: {
      request: {
        method: 'DELETE',
        url: `/api/notifications-history/${data.notificationId}`,
      },
    },
  };
}
