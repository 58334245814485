import DeskSupport from './Desk/DeskSupport';
import Error404 from '../Error404';
import FloorSupport from './Floor/FloorSupport';
import Layout from './Layout';
import Location from './Location';
import { AnimatePresence } from 'framer-motion';
import { DeskGuard, FloorGuard } from './guards';
import { ProtectedRoute } from 'components/_legacy/ProtectedRoute';
import { Redirect, Route, Switch } from 'react-router-dom';

export default function CreateNewBookingSupportRoutes() {
  return (
    <Layout title="Book an appointment">
      <AnimatePresence exitBeforeEnter initial={true}>
        <Switch>
          <Route
            exact
            path='/create-new-booking-support/select-location'
            render={() => <Location type={'support'} />}
          />

          <ProtectedRoute
            exact
            fallback={() => <Redirect to="/create-new-booking-support/select-location" />}
            guards={[FloorGuard]}
            path='/create-new-booking-support/select-floor'
            render={() => <FloorSupport type={'support'} />}
          />

          <ProtectedRoute
            exact
            fallback={() => <Redirect to="/create-new-booking-support/select-floor" />}
            guards={[DeskGuard]}
            path='/create-new-booking-support/select-desk'
            render={() => <DeskSupport type={'support'} />}
          />

          <Route path='*' render={() => <Error404 />} />
        </Switch>
      </AnimatePresence >
    </Layout >
  );
}
