import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'store/_legacy/Redux/store';
import { setCovidQuestionsData } from '../../../Store/covidQuestions';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

export function SuccessCovidQuestionsSnackbar() {
  const dispatch = useDispatch();
  const { successMessage } = useTypedSelector(state => state.adminCovidQuestions);

  const handleClose = () => {
    dispatch(setCovidQuestionsData({ successMessage: '' }));
  };

  useEffect(() => {
    return () => {
      dispatch(setCovidQuestionsData({ successMessage: '' }));
    };
  }, []);

  return successMessage ? (
    <Snackbar autoHideDuration={6000} onClose={handleClose} open>
      <Alert onClose={handleClose} severity="success">
        {successMessage}
      </Alert>
    </Snackbar>
  ) : null;
}

export function ErrorAdminCovidQuestionsSnackbar() {
  const dispatch = useDispatch();
  const { error } = useTypedSelector(state => state.adminCovidQuestions);

  const handleClose = () => {
    dispatch(setCovidQuestionsData({ error: '' }));
  };

  useEffect(() => {
    return () => {
      dispatch(setCovidQuestionsData({ error: '' }));
    };
  }, []);

  return error ? (
    <Snackbar autoHideDuration={6000} onClose={handleClose} open>
      <Alert onClose={handleClose} severity="error">
        {error}
      </Alert>
    </Snackbar>
  ) : null;
}