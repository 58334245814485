import {FloorMapObject} from "../../../../Store/floorMapDuck/models";
import {useDispatch} from "react-redux";
import {useRef} from "react";
import {useTypedSelector} from "store/_legacy/Redux/store";
import {selectDesksFiltered} from "../../../../../App/Store/Bookings/availableDesksDuck";
import L from "leaflet";
import {Rectangle, Tooltip} from "react-leaflet";
import {setJustInTimeReservationData} from "../../../../Store/reservationJustInTime";
import { DeskStatusEnum } from "../../../../Store/floorMapDuck/desk/models";

interface Props {
  desk: FloorMapObject;
}

/**
 * Desk Drawing
 *
 * This component handles deletion and edition internally.
 *
 * React Leaflet Draw handles it's own states, so this component syncs them with internal states.
 */
export default function AdminBookingDesk({ desk }: Props) {
  const dispatch = useDispatch();
  const ref = useRef<any>(null);
  const { coordinatesInLatLngBounds } = desk;
  const { adminFloorMap, availableDesks, adminReservationJustInTime } = useTypedSelector(state => state);
  const { selectedData, filters } = adminReservationJustInTime;

  const deskId = selectedData.desk?.id;
  const { id, name, status } = desk;

  const isActive = deskId === id;
  const isBooked = availableDesks.availableDesks[id]?.isBooked;
  const isHighLighted = availableDesks.highlightNeighbourDeskId === id;

  const desksFiltered = selectDesksFiltered({
    availableDesksState: availableDesks,
    filters,
    floorMapState: adminFloorMap,
  });

  const isDeskOnFiltered = Boolean(desksFiltered.find(desk => desk.id === id));

  // Returns desk color depending on it's status and active state
  const getDeskColor = (): string => {
    let color = isActive ? '#3f8627' : desk.line;

    if (status === DeskStatusEnum.ApprovalRequired) {
      if (isActive) {
        color = '#de5e33';
      } else {
        color = '#fd855c';
      }
    } else if (status === DeskStatusEnum.Unavailable || DeskStatusEnum.DeleteInProgress) {
      if (isActive) {
        color = '#a50707';
      } else {
        color = '#ef0000';
      }
    }

    if (isBooked) {
      if (isActive) {
        color = '#a50707';
      } else {
        color = '#ef0000';
      }
    }

    return `${color}${!isDeskOnFiltered ? '38' : ''}`;
  };

  // Set as selectedDesk
  const onClick = () => {
    console.log('DeskStatusEnum.Available', DeskStatusEnum.Available);
    console.log('DeskStatusEnum.ApprovalRequired', DeskStatusEnum.ApprovalRequired);
    console.log('status', status);
    console.log('!isBooked', !isBooked);

    if ([DeskStatusEnum.Available, DeskStatusEnum.ApprovalRequired].includes(status) && !isBooked) {
      if (typeof desk.id === 'string') {
        dispatch(setJustInTimeReservationData({
          selectedData: {
            ...selectedData,
            desk,
          },
        }));
      }
    }
  };

  const arrayOfCoordinatesInLatLng = coordinatesInLatLngBounds.map(coordinate => L.latLng(coordinate.lat, coordinate.lng));
  const bounds: L.LatLngBoundsExpression = L.latLngBounds(arrayOfCoordinatesInLatLng);
  const color = getDeskColor();

  return (
    <Rectangle
      bounds={bounds}
      eventHandlers={{
        click: onClick,
      }}
      pathOptions={{
        color,
        fillColor: color,
        fillOpacity: isHighLighted ? 1 : 0.2,
      }}
      ref={ref}
    >
      <Tooltip sticky>
        {name}
      </Tooltip>
    </Rectangle>
  );
}
