import { mediaApi } from "../media-api";
import { Attachment } from "../types";

const attachments = mediaApi.injectEndpoints({
  endpoints: (builder) => ({
    getAttachmentById: builder.query<Attachment, string>({
      query: (attachmentId) => ({
        url: `/api/v1/attachments/${attachmentId}`,
      }),
    }),
    deleteAttachmentById: builder.mutation<void, string>({
      query: (attachmentId) => ({
        url: `/api/v1/attachments/${attachmentId}`,
        method: "DELETE",
      }),
    }),
    createAttachment: builder.mutation<Attachment, File>({
      query: (file) => {
        const body = new FormData();

        body.append("file", file);

        return { body, url: "/api/v1/attachments", method: "POST" };
      },
    }),
  }),
});

export const {
  useGetAttachmentByIdQuery,
  useLazyGetAttachmentByIdQuery,
  useDeleteAttachmentByIdMutation,
  useCreateAttachmentMutation,
} = attachments;
