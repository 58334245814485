import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'store/_legacy/Redux/store';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { setReservationsData } from '../../../Store/reservations';

export function SuccessReservationsSnackbar() {
  const dispatch = useDispatch();
  const { successMessage } = useTypedSelector(state => state.adminReservations);

  const handleClose = () => {
    dispatch(setReservationsData({ successMessage: '' }));
  };

  useEffect(() => {
    return () => {
      dispatch(setReservationsData({ successMessage: '' }));
    };
  }, []);

  return successMessage ? (
    <Snackbar autoHideDuration={6000} onClose={handleClose} open>
      <Alert onClose={handleClose} severity="success">
        {successMessage}
      </Alert>
    </Snackbar>
  ) : null;
}
