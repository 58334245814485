import {useDispatch} from "react-redux";
import _ from "underscore";
import styles from "../styles.module.scss";
import {Trans} from "@lingui/macro";

import {IGroup} from "../../../../Store/groups/models";
import ManageLocationsModal from "components/_legacy/ManageLocationsForm";
import {useTypedSelector} from "store/_legacy/Redux/store";
import {Location} from "../../../../../App/Store/Locations/locationsDuck/models";
import {setGroupDetailsData} from "../../../../Store/groupDetails";

interface LocationsGroupModalProps {
  group: IGroup;
}

export default function LocationsGroupDetailsModal(props: LocationsGroupModalProps) {
  const dispatch = useDispatch();
  const { infoEditState, infoDataToEdit } = useTypedSelector(state => state.groupDetails);
  const { group } = props;

  const isDisabled = !infoEditState;
  const disabledClass = isDisabled ? styles.disabledField : '';

  const onSave = (selectedLocations: Location[]) => {
    const updatedLocationsIds = selectedLocations.map(l => l.id);
    const isDataChanged = !_.isEqual(_.sortBy(updatedLocationsIds), _.sortBy(group.locationIds));

    if (isDataChanged) {
      dispatch(setGroupDetailsData({
        infoDataToEdit: {
          ...infoDataToEdit,
          locationIds: updatedLocationsIds,
        },
      }));
    }
  };

  return (
    <div className={styles.detailsInfoBlock}>
      <div className={styles.fieldLabel}>
        <Trans>Locations</Trans>
      </div>
      {/* add extra style for not selected */}
      <ManageLocationsModal
        contentStyle={{width: '270px'}}
        locationIds={group.locationIds}
        onSave={onSave}
        openButtonClassName={`${styles.openButtonClassName} ${disabledClass}`}
        openButtonDisabled={isDisabled}
      />
    </div>
  );
}
