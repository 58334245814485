import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from 'components/_legacy/Box';
import Button from 'components/_legacy/Button';
import HtmlEditor from 'components/_legacy/HtmlEditor';
import PenIcon from 'components/_legacy/Icons/Pen';
import styles from './styles.module.scss';
import Text from 'components/_legacy/Text';
import TextField from 'components/_legacy/TextField';
import useSnackbar from 'components/_legacy/Snackbar/useSnackbar';
import { createOrUpdateEmailTemplate, setEmailTemplatesData } from 'Admin/Store/emailTemplatesDuck';
import { EmailTemplate as EmailTemplateModel, EmailTemplateTypesNames } from 'Admin/Store/emailTemplatesDuck/models';
import { Trans, t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTypedSelector } from 'store/_legacy/Redux/store';

interface Props extends EmailTemplateModel {
  locationId: string;
}

/**
 * Configure Email Templates
 *
 * OwnedBy: Bruno
 */
export default function EmailTemplate({
  emailNotificationText,
  emailNotificationTitle,
  locationId,
  pushNotificationText,
  pushNotificationTitle,
  type: templateType,
}: Props) {
  const dispatch = useDispatch();
  const [open] = useSnackbar();
  const [error, setError] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [text, setText] = useState('');
  const [title, setTitle] = useState(emailNotificationTitle ? emailNotificationTitle : EmailTemplateTypesNames[templateType]);
  const [plainText, setPlainText] = useState('');
  const { config, emailTemplates } = useTypedSelector(state => state);

  const { success } = emailTemplates;

  const isEmpty = !plainText;

  useEffect(() => {
    if (success) {
      open({
        onClose: () => { dispatch(setEmailTemplatesData({ success: '' })); },
        text: success,
        type: 'success',
      });

      setExpanded(false);
    }
  }, [success]);

  // Cancel edition
  const onCancel = () => {
    setExpanded(false);
  };

  // Saves the new content
  const onUpdateEmailContent = () => {
    dispatch(createOrUpdateEmailTemplate({
      locationId,
      emailTemplateData: {
        emailNotificationText: text,
        emailNotificationTitle: title,
        pushNotificationText,
        pushNotificationTitle,
        templateType,
      },
    }));
  };

  return (
    <Box className={styles.emailTemplate} dataTestId="email-template">
      <Accordion
        classes={{ root: styles.accordion }}
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
      >
        <AccordionSummary
          aria-controls={`email ${templateType}`}
          classes={{
            expanded: styles.expanded,
            root: styles.summary,
          }}
          expandIcon={(
            <div aria-label={t`Icon to expand Email Template`} role="button">
              <PenIcon color={config.theme.primary} />
            </div>
          )}
        >
          <Text weight="semi-bold">
            {title}
          </Text>
        </AccordionSummary>

        <AccordionDetails classes={{ root: styles.details }}>
          <Box dataTestId="email-template-details">
            <Box marginTop={20}>
              <Box dataTestId="email-title-label" marginBottom={8} paddingLeft={8} position="relative">
                <Box left={0} position="absolute" top={-1}>
                  <Text color="orange" inline weight="regular">* </Text>
                </Box>

                <Text size="md" weight="regular">
                  <Trans>
                    Email title
                  </Trans>
                </Text>
              </Box>

              <TextField
                id="emailTitle"
                onChange={(event) => setTitle(event.target.value)}
                placeholder={t`Type here`}
                required
                value={title}
              />
            </Box>

            <Box marginTop={20}>
              <Box dataTestId="email-content-label" marginBottom={8} paddingLeft={8} position="relative">
                <Box left={0} position="absolute" top={-1}>
                  <Text color="orange" inline weight="regular">* </Text>
                </Box>

                <Text size="md" weight="regular">
                  <Trans>
                    Email content
                  </Trans>
                </Text>
              </Box>

              <HtmlEditor
                ariaLabel={t`Booking Email Editor`}
                classes={{ editorWrapper: styles.editorWrapper }}
                errorMessage={error}
                initialText={emailNotificationText}
                onChangeText={(text, plainText) => {
                  setText(text);
                  setPlainText(plainText);

                  if (plainText && error) {
                    setError('');
                  }
                }}
              />
            </Box>

            <Box
              alignItems="center"
              display="flex"
              justifyContent="end"
              marginTop={10}
            >
              <Button
                aria-label={t`Cancel`}
                name={t`Cancel`}
                onClick={onCancel}
                size="sm"
                type="clear"
              >
                <Trans>
                  Cancel
                </Trans>
              </Button>

              <Button
                aria-label={t`Update Email Content`}
                disabled={templateType !== "attendeesInvitation" ? isEmpty : false}
                name={t`Update Email Content`}
                onClick={onUpdateEmailContent}
                size="sm"
              >
                <Trans>
                  Update Email Content
                </Trans>
              </Button>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box >
  );
}
