import {Floor} from "../../../../../App/Store/Locations/locationsDuck/models";
import {t} from "@lingui/macro";
import TextField from "@material-ui/core/TextField";
import {Autocomplete} from "@material-ui/lab";
import {useTypedSelector} from "store/_legacy/Redux/store";
import {useDispatch} from "react-redux";
import {setJustInTimeReservationData} from "../../../../Store/reservationJustInTime";
import {useEffect, useState} from "react";
import {getFloorSchema} from "../../../../Store/floorMapApiRequestsDuck";
import {onLoadDesks} from "./helpers";

interface ReservationJustInTimeFloorProps {
  isDisable: boolean;
}

export default function ReservationJustInTimeFloor(props: ReservationJustInTimeFloorProps) {
  const dispatch = useDispatch();
  const { locations, adminReservationJustInTime } = useTypedSelector(state => state);
  const { bookingType, timeFrom, timeTo, dateFrom, dateTo, weekDays, selectedData } = adminReservationJustInTime;

  const { isDisable } = props;
  const [floorArray, setFloorArray] = useState<Floor[]>(locations.floors);

  const onLoadMapSchema = (floorId: string) => {
    // call admin desks data for restricted access desks (need to handle desks with another owners in MapDrawing)
    return dispatch(getFloorSchema({ floorId, showOwners: true }));
  };

  const onFloorChange = (event: any, floor: Floor | null) => {
    if (floor !== null) {
      onLoadDesks(floor.id, adminReservationJustInTime, dispatch);
      onLoadMapSchema(floor.id);
    }

    if (floor === null) {
      return dispatch(setJustInTimeReservationData({
        selectedData: {
          ...selectedData,
          floor: null,
          desk: null,
        },
      }));
    }

    return dispatch(setJustInTimeReservationData({
      selectedData: {
        ...selectedData,
        floor,
      },
    }));
  };

  const onFloorSearchChange = (event: any) => {
    // make search only for next fields
    const fieldsToSearch = ['floorName'];
    const search = event.target.value;

    const filteredLocations = locations.floors
      .filter(item => {
        // @ts-ignore
        return Object.keys(item).some(key => fieldsToSearch.includes(key) && item[key].toLowerCase().includes(search.toLowerCase()));
      });

    setFloorArray(filteredLocations);
  };

  // rerender floors array after location change
  useEffect(() => {
    setFloorArray(locations.floors);
  }, [locations.floors]);

  useEffect(() => {
    if (selectedData.floor) {
      onLoadDesks(selectedData.floor.id, adminReservationJustInTime, dispatch);
      dispatch(setJustInTimeReservationData({
        selectedData: {
          ...selectedData,
          desk: null,
        },
      }));
    }
  }, [bookingType, timeFrom, timeTo, dateFrom, dateTo, weekDays]);

  return (
    <Autocomplete
      className={`input input--default input--inline`}
      filterOptions={(x) => x} // fix load throttling
      getOptionLabel={(option) => option.floorName}
      id="address"
      onBlur={() => setFloorArray(locations.floors)}
      onChange={(event: any, floor: any) => onFloorChange(event, floor)}
      options={floorArray}
      popupIcon={
        <svg height="10px" version="1.1" viewBox="0 0 14 10" width="14px">
          <g fill="none" fillRule="evenodd" id="A-locations" stroke="none" strokeWidth="1">
            <g id="A-Location-notifications-createnew" transform="translate(-384.000000, -32.000000)">
              <rect fill="transparent" height="900" width="1440" x="0" y="0"></rect>
              <g fill={isDisable ? "#ABABAB" : "#1E1F7B"} id="icons/arrow-down" transform="translate(384.000000, 32.000000)">
                <g id="icons/arrow-down-w" transform="translate(7.000000, 5.000000) rotate(-90.000000) translate(-7.000000, -5.000000) translate(2.000000, -2.000000)">
                  <path d="M7.15685425,0.692893219 C7.54737854,0.302368927 8.18054352,0.302368927 8.57106781,0.692893219 C8.9615921,1.08341751 8.9615921,1.71658249 8.57106781,2.10710678 L3.62132034,7.05685425 L8.57106781,12.0066017 C8.9615921,12.397126 8.9615921,13.030291 8.57106781,13.4208153 C8.18054352,13.8113396 7.54737854,13.8113396 7.15685425,13.4208153 L1.5,7.76396103 C1.10947571,7.37343674 1.10947571,6.74027176 1.5,6.34974747 L7.15685425,0.692893219 Z" id="icons/arrow-down" transform="translate(5.035534, 7.056854) rotate(-360.000000) translate(-5.035534, -7.056854) "></path>
                </g>
              </g>
            </g>
          </g>
        </svg>
      }
      renderInput={(params) => (
        <TextField
          {...params}
          className={`input input--default`}
          onChange={(e) => onFloorSearchChange(e)}
          placeholder={t`Not selected`}
          variant="outlined"
        />
      )}
      value={selectedData.floor}
    />
  );
}
