import differenceInHours from 'date-fns/differenceInHours';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import getErrorMessageFromPayload from 'store/_legacy/Redux/Helpers/getErrorMessageFromPayload';
import { ActionPayload, BaseError, BaseErrorResponse, BaseResponse } from 'store/_legacy/Models/ReduxModels';
import { format, zonedTimeToUtc } from 'date-fns-tz';
import moment from "moment-timezone";
import { t } from '@lingui/macro';
import {
  ADD_DESK_TO_SAVED_LIST_SUCCESS,
  AddDeskToSavedListSuccess,
  REMOVE_DESK_FROM_SAVED_LIST_SUCCESS,
  RemoveDeskFromSavedListSuccess,
} from 'App/Store/Desk/deskDuck';
import {
  BookingLocation,
  BookingModel,
  CancelBookingRequest,
  CancelBookingResponseFake,
  CancelVisitFakeResponse,
  CancelVisitRequest,
  CreateBookingRequest,
  CreateBookingResponse,
  CreateVisitRequest,
  CreateVisitResponse,
  GetBookingByIdRequest,
  GetBookingByIdResponse,
  GetBookingsModel,
  GetBookingsRequest,
  GetBookingsResponse,
  GetVisitsModel,
  GetVisitsRequest,
  GetVisitsResponse,
  SetBookingsDataRequest,
} from './models';
import _ from 'lodash';
import { isUUID } from 'class-validator';

export const CANCEL_BOOKING = 'CANCEL_BOOKING';
export const CANCEL_BOOKING_FAIL = 'CANCEL_BOOKING_FAIL';
export const CANCEL_BOOKING_SUCCESS = 'CANCEL_BOOKING_SUCCESS';

export const CREATE_BOOKING = 'CREATE_BOOKING';
export const CREATE_BOOKING_FAIL = 'CREATE_BOOKING_FAIL';
export const CREATE_BOOKING_SUCCESS = 'CREATE_BOOKING_SUCCESS';

export const CANCEL_VISIT = 'CANCEL_VISIT';
export const CANCEL_VISIT_FAIL = 'CANCEL_VISIT_FAIL';
export const CANCEL_VISIT_SUCCESS = 'CANCEL_VISIT_SUCCESS';

export const CREATE_VISIT = 'CREATE_VISIT';
export const CREATE_VISIT_FAIL = 'CREATE_VISIT_FAIL';
export const CREATE_VISIT_SUCCESS = 'CREATE_VISIT_SUCCESS';

export const GET_BOOKINGS = 'GET_BOOKINGS';
export const GET_BOOKINGS_FAIL = 'GET_BOOKINGS_FAIL';
export const GET_BOOKINGS_SUCCESS = 'GET_BOOKINGS_SUCCESS';

export const GET_PREVIOUS_BOOKINGS = 'GET_PREVIOUS_BOOKINGS';
export const GET_PREVIOUS_BOOKINGS_FAIL = 'GET_PREVIOUS_BOOKINGS_FAIL';
export const GET_PREVIOUS_BOOKINGS_SUCCESS = 'GET_PREVIOUS_BOOKINGS_SUCCESS';

export const GET_HOME_VISITS = 'GET_HOME_VISITS';
export const GET_HOME_VISITS_FAIL = 'GET_HOME_VISITS_FAIL';
export const GET_HOME_VISITS_SUCCESS = 'GET_HOME_VISITS_SUCCESS';

export const GET_PREVIOUS_VISITS = 'GET_PREVIOUS_VISITS';
export const GET_PREVIOUS_VISITS_FAIL = 'GET_PREVIOUS_VISITS_FAIL';
export const GET_PREVIOUS_VISITS_SUCCESS = 'GET_PREVIOUS_VISITS_SUCCESS';

export const GET_BOOKING_BY_ID = 'GET_BOOKING_BY_ID';
export const GET_BOOKING_BY_ID_FAIL = 'GET_BOOKING_BY_ID_FAIL';
export const GET_BOOKING_BY_ID_SUCCESS = 'GET_BOOKING_BY_ID_SUCCESS';

export const GET_UPCOMING_BOOKINGS_AND_VISITS = "GET_UPCOMING_BOOKINGS_AND_VISITS";
export const GET_UPCOMING_BOOKINGS_AND_VISITS_FAIL = "GET_UPCOMING_BOOKINGS_AND_VISITS_FAIL";
export const GET_UPCOMING_BOOKINGS_AND_VISITS_SUCCESS = "GET_UPCOMING_BOOKINGS_AND_VISITS_SUCCESS";

export const GET_PAST_BOOKINGS_AND_VISITS = "GET_PAST_BOOKINGS_AND_VISITS";
export const GET_PAST_BOOKINGS_AND_VISITS_FAIL = "GET_PAST_BOOKINGS_AND_VISITS_FAIL";
export const GET_PAST_BOOKINGS_AND_VISITS_SUCCESS = "GET_PAST_BOOKINGS_AND_VISITS_SUCCESS";

export const SET_BOOKINGS_DATA = 'SET_BOOKINGS_DATA';

export interface CancelBooking {
  type: typeof CANCEL_BOOKING;
  payload: ActionPayload<CancelBookingRequest>;
}
interface CancelBookingFail {
  type: typeof CANCEL_BOOKING_FAIL;
  payload: BaseErrorResponse;
}
export interface CancelBookingSuccess {
  type: typeof CANCEL_BOOKING_SUCCESS;
  payload: CancelBookingResponseFake;
}

export interface CreateBooking {
  type: typeof CREATE_BOOKING;
  payload: ActionPayload<CreateBookingRequest>;
}
interface CreateBookingFail {
  type: typeof CREATE_BOOKING_FAIL;
  payload: BaseErrorResponse;
}
export interface CreateBookingSuccess {
  type: typeof CREATE_BOOKING_SUCCESS;
  payload: BaseResponse<CreateBookingResponse>;
}

export interface CancelVisit {
  type: typeof CANCEL_VISIT;
  payload: ActionPayload<CancelVisitRequest>;
}
interface CancelVisitFail {
  type: typeof CANCEL_VISIT_FAIL;
  payload: BaseErrorResponse;
}
interface CancelVisitSuccess {
  type: typeof CANCEL_VISIT_SUCCESS;
  payload: CancelVisitFakeResponse;
}

export interface CreateVisit {
  type: typeof CREATE_VISIT;
  payload: ActionPayload<CreateVisitRequest>;
}
interface CreateVisitFail {
  type: typeof CREATE_VISIT_FAIL;
  payload: BaseErrorResponse;
}
export interface CreateVisitSuccess {
  type: typeof CREATE_VISIT_SUCCESS;
  payload: BaseResponse<CreateVisitResponse>;
}

export interface GetBookingById {
  type: typeof GET_BOOKING_BY_ID;
  payload: ActionPayload<GetBookingByIdRequest>;
  clearData?: boolean;
}
interface GetBookingByIdFail {
  type: typeof GET_BOOKING_BY_ID_FAIL;
  payload: BaseErrorResponse;
}
export interface GetBookingByIdSuccess {
  type: typeof GET_BOOKING_BY_ID_SUCCESS;
  payload: BaseResponse<GetBookingByIdResponse>;
}

export interface GetBookings {
  type: typeof GET_BOOKINGS;
  payload: ActionPayload<GetBookingsRequest>;
  clearData?: boolean;
}
interface GetBookingsFail {
  type: typeof GET_BOOKINGS_FAIL;
  payload: BaseErrorResponse;
}
interface GetBookingsSuccess {
  type: typeof GET_BOOKINGS_SUCCESS;
  payload: BaseResponse<GetBookingsResponse>;
}

// load separate prev bookings data for infinite scroll & calendar
export interface GetPreviousBookings {
  type: typeof GET_PREVIOUS_BOOKINGS;
  payload: ActionPayload<GetBookingsRequest>;
  clearData?: boolean;
  noLoadState?: boolean;
}
interface GetPreviousBookingsFail {
  type: typeof GET_PREVIOUS_BOOKINGS_FAIL;
  payload: BaseErrorResponse;
}
interface GetPreviousBookingsSuccess {
  type: typeof GET_PREVIOUS_BOOKINGS_SUCCESS;
  payload: BaseResponse<GetBookingsResponse>;
}

export interface GetVisits {
  type: typeof GET_HOME_VISITS;
  payload: ActionPayload<GetVisitsRequest>;
  clearData?: boolean;
}
interface GetVisitsFail {
  type: typeof GET_HOME_VISITS_FAIL;
  payload: BaseErrorResponse;
}
interface GetVisitsSuccess {
  type: typeof GET_HOME_VISITS_SUCCESS;
  payload: BaseResponse<GetVisitsResponse>;
}

// load separate prev visits data for infinite scroll & calendar
export interface GetPreviousVisits {
  type: typeof GET_PREVIOUS_VISITS;
  payload: ActionPayload<GetVisitsRequest>;
  clearData?: boolean;
  noLoadState?: boolean;
}
interface GetPreviousVisitsFail {
  type: typeof GET_PREVIOUS_VISITS_FAIL;
  payload: BaseErrorResponse;
}
interface GetPreviousVisitsSuccess {
  type: typeof GET_PREVIOUS_VISITS_SUCCESS;
  payload: BaseResponse<GetVisitsResponse>;
}

export interface SetBookingsData {
  type: typeof SET_BOOKINGS_DATA;
  payload: SetBookingsDataRequest;
}

export interface GetUpcomingBookingsAndVisits {
  type: typeof GET_UPCOMING_BOOKINGS_AND_VISITS;
  payload: { userId?: string, date: Date };
  clearData?: boolean;
}
interface GetUpcomingBookingsAndVisitsFail {
  type: typeof GET_UPCOMING_BOOKINGS_AND_VISITS_FAIL;
  payload: BaseErrorResponse;
}
interface GetUpcomingBookingsAndVisitsSuccess {
  type: typeof GET_UPCOMING_BOOKINGS_AND_VISITS_SUCCESS;
  payload: { bookings: BaseResponse<GetBookingsResponse>, visits: BaseResponse<GetVisitsResponse> };
}

interface GetPastBookingsAndVisits {
  type: typeof GET_PAST_BOOKINGS_AND_VISITS;
  payload: { userId?: string, date: Date };
  clearData?: boolean;
}
interface GetPastBookingsAndVisitsFail {
  type: typeof GET_PAST_BOOKINGS_AND_VISITS_FAIL;
  payload: BaseErrorResponse;
}
interface GetPastBookingsAndVisitsSuccess {
  type: typeof GET_PAST_BOOKINGS_AND_VISITS_SUCCESS;
  payload: { bookings: BaseResponse<GetBookingsResponse>, visits: BaseResponse<GetVisitsResponse> };
}

type Actions =
  | AddDeskToSavedListSuccess
  | CancelBooking
  | CancelBookingFail
  | CancelBookingSuccess
  | CreateBooking
  | CreateBookingFail
  | CreateBookingSuccess
  | GetPreviousBookings
  | GetPreviousBookingsFail
  | GetPreviousBookingsSuccess
  | CancelVisit
  | CancelVisitFail
  | CancelVisitSuccess
  | CreateVisit
  | CreateVisitFail
  | CreateVisitSuccess
  | GetBookingById
  | GetBookingByIdFail
  | GetBookingByIdSuccess
  | GetBookings
  | GetBookingsFail
  | GetBookingsSuccess
  | GetVisits
  | GetVisitsFail
  | GetVisitsSuccess
  | GetPreviousVisits
  | GetPreviousVisitsFail
  | GetPreviousVisitsSuccess
  | RemoveDeskFromSavedListSuccess
  | SetBookingsData
  | GetUpcomingBookingsAndVisits
  | GetUpcomingBookingsAndVisitsFail
  | GetUpcomingBookingsAndVisitsSuccess
  | GetPastBookingsAndVisits
  | GetPastBookingsAndVisitsFail
  | GetPastBookingsAndVisitsSuccess;

export interface State {
  activeBooking: BookingModel | undefined;
  bookingCanceled: boolean;
  bookingCreated: boolean;
  visitCanceled: boolean;
  error: string;
  errorObject?: BaseError;
  loading: boolean;
  previousBookingsLoading: boolean;
  previousVisitsLoading: boolean;
  bookings: {
    [key: string]: BookingModel;
  };
  previousBookings: {
    [key: string]: BookingModel;
  };
  visits: {
    [key: string]: BookingModel;
  };
  previousVisits: {
    [key: string]: BookingModel;
  };
  limit: number;
  previousLimit: number;
  page: number;
  selectedDate: Date | string;
  totalCount: number;
  previousTotalCount: number;
  previousBookingCount: number;
  previousVisitsCount: number;
}

const initialState: State = {
  activeBooking: undefined,
  bookingCanceled: false,
  bookingCreated: false,
  visitCanceled: false,
  error: '',
  loading: false,
  previousBookingsLoading: false,
  previousVisitsLoading: false,
  bookings: {},
  previousBookings: {},
  visits: {},
  previousVisits: {},
  limit: 20,
  previousLimit: 70,
  page: 1,
  selectedDate: moment().startOf('day').format(),
  totalCount: 0,
  previousTotalCount: 0,
  previousBookingCount: 0,
  previousVisitsCount: 0,
};

export default function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ADD_DESK_TO_SAVED_LIST_SUCCESS: {
      const activeBooking = state.activeBooking ? { ...state.activeBooking } : undefined;

      return {
        ...state,
        activeBooking,
      };
    }
    case CANCEL_BOOKING:
      return {
        ...state,
        error: '',
        loading: true,
        bookingCanceled: false,
      };
    case CANCEL_BOOKING_FAIL:
      return {
        ...state,
        error: t`There was an error cancelling the booking. Please try again. ${action.payload?.error?.message}`,
        loading: false,
      };
    case CANCEL_BOOKING_SUCCESS: {
      const bookings = { ...state.bookings };

      delete bookings[action.payload.bookingId];

      return {
        ...state,
        error: '',
        loading: false,
        bookingCanceled: true,
        bookings,
      };
    }
    case CREATE_BOOKING:
      return {
        ...state,
        error: '',
        loading: true,
      };
    case CREATE_BOOKING_FAIL:
      return {
        ...state,
        error: t`There was an error creating a new booking.`,
        errorObject: action.payload.error,
        loading: false,
      };
    case CREATE_BOOKING_SUCCESS: {
      return {
        ...state,
        error: '',
        loading: false,
        bookingCreated: true,
      };
    }

    case CANCEL_VISIT:
      return {
        ...state,
        error: '',
        loading: true,
        visitCanceled: false,
      };
    case CANCEL_VISIT_FAIL:
      return {
        ...state,
        error: getErrorMessageFromPayload({ fallbackMessage: t`There was an error cancelling the visit. Please try again.`, payload: action.payload }),
        loading: false,
      };
    case CANCEL_VISIT_SUCCESS: {
      const visits = { ...state.visits };

      delete visits[action.payload.appointmentId];

      return {
        ...state,
        error: '',
        loading: false,
        visitCanceled: true,
        visits,
      };
    }
    case CREATE_VISIT:
      return {
        ...state,
        error: '',
        loading: true,
      };
    case CREATE_VISIT_FAIL:
      return {
        ...state,
        error: t`There was an error creating a new visit. Please try again. ${action.payload?.error?.message}`,
        loading: false,
      };
    case CREATE_VISIT_SUCCESS: {
      return {
        ...state,
        error: '',
        loading: false,
        bookingCreated: true,
      };
    }
    case GET_BOOKING_BY_ID:
      return {
        ...state,
        error: '',
        loading: true,
      };
    case GET_BOOKING_BY_ID_FAIL:
      return {
        ...state,
        error: getErrorMessageFromPayload({ fallbackMessage: t`There was an error getting booking details. Please try again.`, payload: action.payload }),
        loading: false,
      };
    case GET_BOOKING_BY_ID_SUCCESS: {
      const booking = action.payload.data.result.data;

      return {
        ...state,
        error: '',
        loading: false,
        activeBooking: booking,
      };
    }
    case GET_BOOKINGS:
      return {
        ...state,
        error: '',
        loading: true,
        bookings: action.clearData ? {} : state.bookings,
      };
    case GET_BOOKINGS_FAIL:
      return {
        ...state,
        error: getErrorMessageFromPayload({ fallbackMessage: t`There was an error. Please try again.`, payload: action.payload }),
        loading: false,
      };
    case GET_BOOKINGS_SUCCESS: {
      const { bookings, page, totalCount } = action.payload.data.result.data;

      const newBookings = { ...state.bookings };

      bookings.forEach(booking => {
        newBookings[booking.id] = booking;
      });

      return {
        ...state,
        error: '',
        loading: false,
        bookings: newBookings,
        page,
        totalCount,
      };
    }
    case GET_PREVIOUS_BOOKINGS:
      return {
        ...state,
        error: '',
        previousBookingsLoading: action.noLoadState ? state.previousBookingsLoading : true,
        previousBookings: action.clearData ? {} : state.previousBookings,
      };
    case GET_PREVIOUS_BOOKINGS_FAIL:
      return {
        ...state,
        error: getErrorMessageFromPayload({ fallbackMessage: t`There was an error. Please try again.`, payload: action.payload }),
        previousBookingsLoading: false,
      };
    case GET_PREVIOUS_BOOKINGS_SUCCESS: {
      const { bookings, page, totalCount } = action.payload.data.result.data;
      const newBookings = { ...state.previousBookings };

      bookings.forEach(booking => {
        newBookings[booking.id] = booking;
      });
      // sort bookings and visits by dateFromUTC
      const newSortedBookings: { [key: string]: BookingModel; } = {};
      const sortedBookings = _.orderBy(newBookings, item => item.dateFromUTC, ['desc']);
      sortedBookings.forEach(b => newSortedBookings[b.id] = b);

      return {
        ...state,
        error: '',
        previousBookingsLoading: false,
        previousBookings: newSortedBookings,
        page,
        previousTotalCount: totalCount + state.previousVisitsCount,
        previousBookingCount: totalCount,
      };
    }
    case GET_HOME_VISITS:
      return {
        ...state,
        error: '',
        loading: true,
        bookings: action.clearData ? {} : state.bookings,
      };
    case GET_HOME_VISITS_FAIL:
      return {
        ...state,
        error: getErrorMessageFromPayload({ fallbackMessage: t`There was an error. Please try again.`, payload: action.payload }),
        loading: false,
      };
    case GET_HOME_VISITS_SUCCESS: {
      const { appointments } = action.payload.data.result.data;

      const newBookings = { ...state.bookings };
      const newVisits = { ...state.visits };

      appointments.forEach(appointment => {
        newBookings[appointment.id] = appointment;
        newVisits[appointment.id] = appointment;
      });

      return {
        ...state,
        error: '',
        loading: false,
        bookings: newBookings,
        visits: newVisits,
      };
    }
    case GET_PREVIOUS_VISITS:
      return {
        ...state,
        error: '',
        previousVisitsLoading: action.noLoadState ? state.previousBookingsLoading : false,
        previousBookings: action.clearData ? {} : state.previousBookings,
      };
    case GET_PREVIOUS_VISITS_FAIL:
      return {
        ...state,
        error: getErrorMessageFromPayload({ fallbackMessage: t`There was an error. Please try again.`, payload: action.payload }),
        previousVisitsLoading: false,
      };
    case GET_PREVIOUS_VISITS_SUCCESS: {
      const { appointments, totalCount } = action.payload.data.result.data;

      const newBookings = { ...state.previousBookings };
      const newVisits = { ...state.previousVisits };

      appointments.forEach(appointment => {
        newBookings[appointment.id] = appointment;
        newVisits[appointment.id] = appointment;
      });

      const newSortedBookings: { [key: string]: BookingModel; } = {};
      const newSortedVisits: { [key: string]: BookingModel; } = {};
      // sort bookings and visits by dateFromUTC
      const sortedBookings = _.orderBy(newBookings, item => item.dateFromUTC, ['desc']);
      sortedBookings.forEach(b => newSortedBookings[b.id] = b);
      const sortedVisits = _.orderBy(newVisits, item => item.dateFromUTC, ['desc']);
      sortedVisits.forEach(b => newSortedVisits[b.id] = b);

      return {
        ...state,
        error: '',
        previousVisitsLoading: false,
        previousBookings: newSortedBookings,
        previousVisits: newSortedVisits,
        previousTotalCount: state.previousBookingCount + totalCount,
        previousVisitsCount: totalCount,
      };
    }
    case REMOVE_DESK_FROM_SAVED_LIST_SUCCESS: {
      const activeBooking = state.activeBooking ? { ...state.activeBooking } : undefined;

      return {
        ...state,
        activeBooking,
      };
    }
    case SET_BOOKINGS_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case GET_UPCOMING_BOOKINGS_AND_VISITS:
      return {
        ...state,
        error: '',
        loading: true,
        bookings: action.clearData ? {} : state.bookings,
        visits: action.clearData ? {} : state.visits,
      };
    case GET_UPCOMING_BOOKINGS_AND_VISITS_FAIL:
      return {
        ...state,
        error: "There was an error getting your upcoming reservations, please try again.",
        loading: false,
      };
    case GET_UPCOMING_BOOKINGS_AND_VISITS_SUCCESS: {
      const bookingsResponse = action.payload.bookings;
      const visitsResponse = action.payload.visits;
      const { bookings, page, totalCount: bookingsTotalCount } = bookingsResponse.data.result.data;
      const { appointments: visits } = visitsResponse.data.result.data;

      const newBookings = { ...state.bookings };
      const newVisits = { ...state.visits };

      for (const booking of bookings) {
        newBookings[booking.id] = booking;
      }

      for (const visit of visits) {
        newBookings[visit.id] = visit;
        newVisits[visit.id] = visit;
      }

      const sortedNewBookings = _
          .orderBy(newBookings, ({ dateFromUTC }) => dateFromUTC, "desc")
          .reduce((carry, current) => ({ ...carry, [current.id]: current }), {} as { [id: string]: BookingModel });

      return {
        ...state,
        error: '',
        loading: false,
        bookings: sortedNewBookings,
        visits: newVisits,
        page,
        totalCount: bookingsTotalCount,
      };
    }
    case GET_PAST_BOOKINGS_AND_VISITS:
      return {
        ...state,
        error: '',
        previousBookingsLoading: true,
        previousVisitsLoading: true,
        previousBookings: action.clearData ? {} : state.previousBookings,
        previousVisits: action.clearData ? {} : state.previousVisits,
      };
    case GET_PAST_BOOKINGS_AND_VISITS_FAIL:
      return {
        ...state,
        error: "There was an error getting your past reservations, please try again.",
        previousBookingsLoading: false,
        previousVisitsLoading: false,
      };
    case GET_PAST_BOOKINGS_AND_VISITS_SUCCESS: {
      const bookingsResponse = action.payload.bookings;
      const visitsResponse = action.payload.visits;
      const { bookings, totalCount: bookingsTotalCount } = bookingsResponse.data.result.data;
      const { appointments: visits, totalCount: visitsTotalCount } = visitsResponse.data.result.data;
      const newPreviousBookings = { ...state.previousBookings };
      const newPreviousVisits = { ...state.previousVisits };

      for (const booking of bookings) {
        newPreviousBookings[booking.id] = booking;
      }

      for (const visit of visits) {
        newPreviousBookings[visit.id] = visit;
        newPreviousVisits[visit.id] = visit;
      }

      const sortedNewPreviousBookings = _
        .orderBy(newPreviousBookings, ({ dateFromUTC }) => dateFromUTC, "desc")
        .reduce((carry, current) => ({ ...carry, [current.id]: current }), {} as { [id: string]: BookingModel });

      return {
        ...state,
        error: '',
        previousBookingsLoading: false,
        previousVisitsLoading: false,
        previousBookings: sortedNewPreviousBookings,
        previousVisits: newPreviousVisits,
        previousBookingCount: bookingsTotalCount,
        previousVisitsCount: visitsTotalCount,
        previousTotalCount: bookingsTotalCount + visitsTotalCount,
      };
    }
    default:
      return state;
  }
}

// Actions
export function cancelBooking(data: CancelBookingRequest): CancelBooking {
  return {
    type: CANCEL_BOOKING,
    payload: {
      request: {
        method: 'DELETE',
        url: `/api/bookings/${data.bookingId}`,
      },
    },
  };
}

export function createBooking(data: CreateBookingRequest, locationId: string, force?: boolean): CreateBooking {
  return {
    type: CREATE_BOOKING,
    payload: {
      request: {
        method: 'POST',
        url: `/api/v2/bookings/${locationId}${force ? "/force" : ""}`,
        data,
      },
    },
  };
}

export function cancelVisit(data: CancelVisitRequest): CancelVisit {
  return {
    type: CANCEL_VISIT,
    payload: {
      request: {
        method: 'DELETE',
        url: `/api/bookings/appointments/${data.appointmentId}`,
      },
    },
  };
}

export function createVisit(data: CreateVisitRequest, locationId: string): CreateVisit {
  return {
    type: CREATE_VISIT,
    payload: {
      request: {
        method: 'POST',
        url: `/api/bookings/appointments/${locationId}`,
        data,
      },
    },
  };
}

export function getBookings({
  data,
  clearData,
}: GetBookingsModel): GetBookings {
  if (!data.selectedUserId || !isUUID(data.selectedUserId)) {
    delete data.selectedUserId;
  }

  return {
    type: GET_BOOKINGS,
    payload: {
      request: {
        method: 'GET',
        url: '/api/bookings',
        data: {
          ...data,
          dateFrom: format(new Date(data.dateFrom), 'yyyy-MM-dd'),
        },
      },
    },
    clearData,
  };
}

export function getVisits({
  data,
  clearData,
}: GetVisitsModel): GetVisits {
  const apiData = {
    ...data,
    dateFrom: format(new Date(data.dateFrom), 'yyyy-MM-dd'),
  };

  if (data.dateTo) {
    apiData.dateTo = format(new Date(data.dateTo), 'yyyy-MM-dd');
  }

  if (!data.selectedUserId || !isUUID(data.selectedUserId)) {
    delete data.selectedUserId;
  }

  return {
    type: GET_HOME_VISITS,
    payload: {
      request: {
        method: 'GET',
        url: '/api/bookings/appointments',
        data: apiData,
      },
    },
    clearData,
  };
}

export function getBookingById(data: GetBookingByIdRequest): GetBookingById {
  return {
    type: GET_BOOKING_BY_ID,
    payload: {
      request: {
        method: 'GET',
        url: `/api/bookings/${data.bookingId}`,
      },
    },
  };
}

export function setBookingsData(data: SetBookingsDataRequest): SetBookingsData {
  return {
    type: SET_BOOKINGS_DATA,
    payload: data,
  };
}

// Selectors and functions
/**
 * Takes a Booking and returns date object in UTC time
 *
 * The data comes in the following format
 * 
 * dateFrom: "2021-04-12" (yyyy-MM-d)
 * timeFrom: "9:45 AM"
 * location.timezone: "America/New_York" (GMT-04:00)
 *
 * This fuction concatenates all data and return a date object from it in UTC time
 *
 * Concatenation ex: "2021-04-12 09:45"
 *
 * @returns Date
 */
export function getBookingDate({ dateFromUTC }: BookingModel): Date {
  return new Date(dateFromUTC);
}

/**
 * Takes a Booking and returns date object in UTC time
 *
 * The data comes in the following format
 * 
 * dateFrom: "2021-04-12" (yyyy-MM-d)
 * timeFrom: "9:45 AM"
 * location.timezone: "America/New_York" (GMT-04:00)
 *
 * This fuction concatenates all data and return a date object from it in UTC time
 *
 * Concatenation ex: "2021-04-12 09:45"
 *
 * @returns Date
 */
export function getBookingEndDate({ dateToUTC, location, timeTo }: BookingModel): Date {
  try {
    // Splits time string by empty space (' '). Ex: "9:45 PM" => ['9:45', 'PM']
    const timeSplitted = (timeTo ?? "6:00 PM").split(' ');
    // Splits time by ':'. Ex: "9:45" => ['9', '45']
    const hourSplitted = timeSplitted[0].split(':');
    // Convert hours to 24h format depending on AM/PM. Ex: '9' => 21
    let hour = String(parseInt(hourSplitted[0]) + (timeSplitted[1] === 'PM' ? 12 : 0));
    // Check if hour has only one number and add a '0' if necessary. Ex: 9 => '09'
    hour = hour.length === 1 ? `0${hour}` : hour;
    const minutes = hourSplitted[1];

    dateToUTC = typeof dateToUTC === 'string' ? dateToUTC : new Date(dateToUTC).toISOString();

    // Return Date object with correct timezone, but in UTC.
    // Ex: utcDate.toISOString() = '2021-04-13T02:45:00.000Z' (GMT-04:00 in UTC time will advance the day for 21:45h).
    const utcDate = zonedTimeToUtc(`${dateToUTC.split('T')[0]} ${hour}:${minutes}`, location.timezone ? location.timezone : 'Europe/London');

    return utcDate;
  } catch (e) {
    console.error({ e });

    return dateToUTC ? new Date(dateToUTC) : new Date();
  }
}

/**
 * Receives a booking and optionally a date to compare (defaults to now),
 * and returns the difference in hours and minutes.
 * 
 * Ex: How many hours are between 8 July 2021 11:10:00 UTC and 8 July 2021 17:50:00 UTC?
 * Returns: { hours: 6, minutes: 40; formated: '06:40h' }
 */
export function getBookingDifferenceInHours({
  booking,
  dateCompare = new Date(),
}: {
  booking: BookingModel;
  dateCompare: Date;
}): { hours: number; minutes: number; formated: string } {
  // Get booking date in UTC time
  const bookingDate = getBookingDate(booking);

  // Get hours. Ex: 6
  const hours = differenceInHours(bookingDate, dateCompare);
  // Get minutes. Ex: 370 (this means, 6 hours and 10 minutes)
  let minutes = differenceInMinutes(bookingDate, dateCompare);
  // Get only minutes remaining from hours
  minutes = minutes % 60;

  // Formats hours and minutes. Ex: 06:10
  const alwaysPositiveHours = Math.abs(hours);
  const alwaysPositiveMinutes = Math.abs(minutes);

  let formated = '';

  if (hours !== 0) {
    formated = `${String(alwaysPositiveHours).length === 1 ? '0' + alwaysPositiveHours : alwaysPositiveHours}:${String(alwaysPositiveMinutes).length === 1 ? '0' + alwaysPositiveMinutes : alwaysPositiveMinutes}h`;
  } else {
    formated = `${String(alwaysPositiveMinutes).length === 1 ? '0' + alwaysPositiveMinutes : alwaysPositiveMinutes}m`;
  }

  return {
    hours,
    minutes,
    formated,
  };
}

/**
 * Receives a booking and return it formated in it's own local time
 * 
 * Ex:
 * 
 * dateFrom: "2021-04-12" (yyyy-MM-d)
 * timeFrom: "9:15 PM"
 * timeTo: "10:45 PM"
 * location.timezone: "America/New_York" (GMT-04:00)
 * 
 * Jul 7, 9:15PM - 10:45PM GMT-04:00
 */
export function formatBookingDateInLocalTime(booking: BookingModel): string {
  // Creates a copy of booking but with dateTo/timeTo in the place of dateFrom/timeFrom
  // This is needed to concatenate both hours, timeFrom/timeTo
  const utcDateFrom = getBookingDate(booking);
  const utcDateTo = getBookingDate({ ...booking, dateFromUTC: booking.dateToUTC });

  const timezone = booking.location.timezone ? booking.location.timezone : 'Europe/London';

  // First part is dateFrom/timeFrom: Jul 7, 9:15PM
  const firstPart = moment(utcDateFrom).tz(timezone).format('MMM D, h:mmA');
  // Second part is timeTo: 10:45PM
  const secondPart = moment(utcDateTo).tz(timezone).format('h:mmA');
  // Timezone part is: GMT-04:00
  const timezonePart = moment(utcDateFrom).tz(timezone).format('Z');

  return `${firstPart} - ${secondPart} GMT${timezonePart}`;
}

/**
 * Counts all bookings locations and returns the one that appears more often
 */
export function selectMoreOftenLocation(state: State): BookingLocation | undefined {
  const bookingsArray = Object.keys(state.bookings).map(bookingId => state.bookings[bookingId]);
  const locationsCount: { [locationId: string]: { location: BookingLocation; count: number } } = {};
  let moreOftenLocation: { location: BookingLocation; count: number } | undefined;

  bookingsArray.forEach(booking => {
    const locationCount = locationsCount[booking.location.id];

    if (!locationCount) {
      locationsCount[booking.location.id] = {
        count: 0,
        location: booking.location,
      };
    } else {
      locationsCount[booking.location.id] = {
        count: locationCount.count + 1,
        location: locationCount.location,
      };
    }
  });

  Object.keys(locationsCount).forEach(locationId => {
    const locationCount = locationsCount[locationId];

    if (!moreOftenLocation) {
      moreOftenLocation = locationCount;
    } else {
      if (locationCount.count > moreOftenLocation.count) {
        moreOftenLocation = locationCount;
      }
    }
  });

  return moreOftenLocation?.location;
}
