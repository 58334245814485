import { get, omit, toPairs } from "lodash";
import { parseArrayQueryParam, parsePaginationQueryParams } from "../../utils";
import { Paginated } from "../../types";
import { commApi } from "../comm-api";
import { CreatePostInput, GetPostByIdInput, GetPostMetricsInput, GetPostsInput, Post, PostLinkClick, PostMetricSample, UpdatePostByIdInput, VerifyPostInput } from "../types";
import { CommApiTag, PostVerificationStatus } from "../enums";
import { Dictionary } from "ts-essentials";
import { User } from "store/apis/together";

const posts = commApi.injectEndpoints({
  endpoints: (builder) => ({
    createPost: builder.mutation<Post, CreatePostInput>({
      query: (body) => ({ body, url: "/api/v1/posts", method: "POST" }),
      invalidatesTags: [CommApiTag.POST],
    }),
    getPosts: builder.query<Paginated<Post>, GetPostsInput>({
      query: (params) => ({ url: "/api/v1/posts", params: parsePaginationQueryParams(params) }),
      providesTags: [CommApiTag.POST],
    }),
    getPostById: builder.query<Post, GetPostByIdInput>({
      query: ({ postId, include }) => ({ url: `/api/v1/posts/${postId}`, params: parseArrayQueryParam("include", include) }),
      providesTags: [CommApiTag.POST],
    }),
    updatePostById: builder.mutation<Post, UpdatePostByIdInput>({
      query: ({ postId, ...body }) => ({ body, url: `/api/v1/posts/${postId}`, method: "PATCH" }),
      invalidatesTags: [CommApiTag.POST],
    }),
    deletePostById: builder.mutation<void, string>({
      query: (postId) => ({ url: `/api/v1/posts/${postId}`, method: "DELETE" }),
      invalidatesTags: [CommApiTag.POST],
    }),
    getFeed: builder.query<Paginated<Post>, GetPostsInput>({
      query: (params) => ({ url: "/api/v1/feed", params: parsePaginationQueryParams(params) }),
      providesTags: [CommApiTag.POST],
      serializeQueryArgs: (parameters) => {
        if (parameters.queryArgs.page) {
          parameters.queryArgs = { ...omit(parameters.queryArgs, "page") };
        }
        
        return parameters;
      },
      merge: (current, next, { arg: parameters }) => {
        if (parameters.page === 1 || parameters.limit === -1) {
          return next;
        }
        
        return { ...next, items: [ ...current.items, ...next.items ] };
      },
      forceRefetch: (parameters) => {
        if (parameters.previousArg) {
          return false;
        }

        const previousArguments = omit(parameters.previousArg, "page");
        const currentArguments = omit(parameters.currentArg, "page");

        if (previousArguments && currentArguments) {
          for (const [key, value] of toPairs(currentArguments)) {
            if (value !== get(previousArguments, key)) {
              return true;
            }
          }
        }

        return false;
      },
    }),
    getPostMetrics: builder.query<{ items: PostMetricSample[] }, GetPostMetricsInput>({
      query: ({ postId, granularity, granularityTimeZone, types }) => ({
        url: `/api/v1/posts/${postId}/metrics`,
        params: { granularity, granularityTimeZone, ...parseArrayQueryParam("types", types) },
      }),
    }),
    verifyPost: builder.mutation<Dictionary<PostVerificationStatus, "author" | "recipients">, VerifyPostInput>({
      query: ({ postId, ...body }) => ({
        body,
        url: postId ? `/api/v1/posts/${postId}/verify` : "/api/v1/posts/verify",
        method: "POST",
      }),
    }),
    getPendingPosts: builder.query<Paginated<Post>, GetPostsInput>({
      query: (params) => ({ url: "/api/v1/posts/pending", params: parsePaginationQueryParams(params) }),
      providesTags: [CommApiTag.POST],
    }),
    approvePostById: builder.mutation<void, string>({
      query: (postId) => ({ url: `/api/v1/posts/${postId}/approve`, method: "POST" }),
      invalidatesTags: [CommApiTag.POST],
    }),
    getApproversByPostApprovalId: builder.query<{ items: User[] }, Dictionary<string, "postId" | "approvalId">>({
      query: ({ postId, approvalId }) => ({ url: `/api/v1/posts/${postId}/approvals/${approvalId}/approvers` }),
    }),
    disapprovePostById: builder.mutation<void, string>({
      query: (postId) => ({ url: `/api/v1/posts/${postId}/disapprove`, method: "POST" }),
      invalidatesTags: [CommApiTag.POST],
    }),
    getLinkClicksByPostId: builder.query<{ items: PostLinkClick[] }, string>({
      query: (postId: string) => ({ url: `/api/v1/posts/${postId}/link-clicks` }),
    }),
  }),
});

export const {
  useCreatePostMutation,
  useGetPostsQuery,
  useLazyGetPostsQuery,
  useGetPostByIdQuery,
  useLazyGetPostByIdQuery,
  useUpdatePostByIdMutation,
  useDeletePostByIdMutation,
  useGetFeedQuery,
  useLazyGetFeedQuery,
  useGetPostMetricsQuery,
  useLazyGetPostMetricsQuery,
  useVerifyPostMutation,
  useGetPendingPostsQuery,
  useLazyGetPendingPostsQuery,
  useApprovePostByIdMutation,
  useGetApproversByPostApprovalIdQuery,
  useLazyGetApproversByPostApprovalIdQuery,
  useDisapprovePostByIdMutation,
  useGetLinkClicksByPostIdQuery,
  useLazyGetLinkClicksByPostIdQuery,
} = posts;
