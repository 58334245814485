import React from "react";
import { UsersListProps } from "./types";
import { Box, Skeleton, Typography } from "@mui/material";
import { TogetherUser } from "components/icons";
import { t } from "@lingui/macro";

export const UsersList: React.FC<UsersListProps> = (props) => {
  const { value = [], loading, labels } = props;

  if (loading) {
    return (
      <Box>
        <Box alignItems="center" display="flex" gap={1} height={44}>
          <Skeleton height={30} variant="rectangular" width={30} />
          <Box maxWidth={280}>
            <Skeleton height={14} sx={{ mb: 0.5 }} variant="rectangular" width={150} />
            <Skeleton height={12} variant="rectangular" width={220} />
          </Box>
        </Box>
        <Box alignItems="center" display="flex" gap={1} height={44}>
          <Skeleton height={30} variant="rectangular" width={30} />
          <Box maxWidth={280}>
            <Skeleton height={14} sx={{ mb: 0.5 }} variant="rectangular" width={150} />
            <Skeleton height={12} variant="rectangular" width={220} />
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      {value.map(({ name, email }) => (
        <Box alignItems="center" display="flex" height={44} key={email}>
          <Box
            alignItems="center"
            bgcolor={({ palette }) => !name ? palette.grey[400] : palette.primary.main}
            borderRadius={2}
            display="flex"
            height={30}
            justifyContent="center"
            marginRight={1}
            width={30}
          >
            <TogetherUser fill="#fff" sx={{ width: 14 }} />
          </Box>
          <Box maxWidth={280}>
            <Typography fontSize={14} fontWeight="600" noWrap>{name || labels?.external || t`Invited user`}</Typography>
            <Typography color={({ palette }) => palette.grey[700]} fontSize={12} noWrap>{email}</Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};
