import styles from './styles.module.scss';

import Checkbox from "components/_legacy/Checkbox";
import Box from "components/_legacy/Box";
import Text from "components/_legacy/Text";
import { AttributeModel } from "../../../../../../Store/attributes/models";
import { Trans } from "@lingui/macro";

interface Props {
  attributes: AttributeModel[];
  selected?: { [key: string]: string }
  onUpdate: (value: boolean, attribute: AttributeModel) => void;
}

export default function Amenities(props: Props) {
  const { attributes, selected, onUpdate } = props;

  return (
    <>
      <Box marginBottom={4}>
        <Text color="black" size="md">
          <Trans>Amenities:</Trans>
        </Text>
      </Box>
      <Box className={styles.amenitiesItems}>
        {attributes.map((attribute) => {
          const resource = selected?.[attribute.id];

          return (
            <Checkbox
              checked={!!resource}
              classes={{ checkbox: styles.checkbox }}
              key={attribute.id}
              label={<Box><Text size="md">{attribute.name}</Text></Box>}
              name={attribute.name}
              onChange={(value) => onUpdate(value, attribute)}
            />
          );
        })}
      </Box>
    </>
  );
}
