import styles from "./styles.module.scss";
import NumberCounter from "components/_legacy/NumberCounter";

interface Props {
  onClick: Function;
  count: number;
}

export default function FiltersCounter(props: Props) {
  const { count, onClick } = props;

  return (
    <div onClick={(e) => onClick(e)}>
      <NumberCounter className={styles.filtersCounter} count={count} />
    </div>
  );
}
