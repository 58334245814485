import Box from 'components/_legacy/Box';
import Button from 'components/_legacy/Button';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Popup from 'reactjs-popup';
import useSnackbar from 'components/_legacy/Snackbar/useSnackbar';
import { deleteParkingSpot } from 'Admin/Store/parking';
import { ParkingSpot } from 'Admin/Store/parking/models';
import { Trans, t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTypedSelector } from 'store/_legacy/Redux/store';

interface ParkingSpotDeleteModalProps {
  closeModal(): void;
  open: boolean;
  parkingSpot: ParkingSpot | null;
}

export default function ParkingSpotDeleteModal({
  closeModal,
  open,
  parkingSpot,
}: ParkingSpotDeleteModalProps) {
  const dispatch = useDispatch();
  const [openSnackbar] = useSnackbar();
  const { config, parking } = useTypedSelector(state => state);

  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    if (!firstLoad) {
      if (parking.deleteMessages.error) {
        openSnackbar({
          text: parking.deleteMessages.error,
          type: 'error',
        });
      } else if (parking.deleteMessages.success) {
        openSnackbar({
          text: parking.deleteMessages.success,
          type: 'success',
        });

        closeModal();
      }
    }

    setFirstLoad(false);
  }, [parking.deleteMessages]);

  if (!parkingSpot) {
    return null;
  }

  const handleDeleteParkingSpot = () => {
    dispatch(deleteParkingSpot({ parkingSpotId: parkingSpot.id }));
  };

  return (
    <Popup
      className='modal'
      closeOnDocumentClick
      onClose={closeModal}
      open={open}
    >
      <div className="modal-inner">
        <div className="modal-header">
          <h2>
            <Trans>
              Delete Parking Spot
            </Trans>
          </h2>

          <IconButton
            onClick={closeModal}
            size="small"
            style={{
              backgroundColor: config.theme.primaryLight,
              borderRadius: 8,
              height: 30,
              width: 30,
            }}
          >
            <CloseIcon style={{ color: config.theme.primary, fontSize: 21 }} />
          </IconButton>
        </div>

        <div className={'modal-inner-content'}>
          <Box marginBottom={20}>
            <Trans>
              Are you sure to delete parking spot {parkingSpot.name}?
            </Trans>
          </Box>

          <Box display="flex" justifyContent="end">
            <Button
              aria-label={t`cancel delete parking spot`}
              name={t`cancel delete parking spot`}
              onClick={() => closeModal()}
              size="sm"
              type="clear"
            >
              <Trans>
                Cancel
              </Trans>
            </Button>

            <Button
              aria-label={t`confirm delete parking spot`}
              name={t`confirm delete parking spot`}
              onClick={handleDeleteParkingSpot}
              size="sm"
            >
              <Trans>
                Delete
              </Trans>
            </Button>
          </Box>
        </div>
      </div>
    </Popup>
  );
}
