import {useDispatch} from "react-redux";
import {useTypedSelector} from "store/_legacy/Redux/store";
import Popup from "reactjs-popup";
import {Trans, t} from "@lingui/macro";
import styles from "./styles.module.scss";
import Text from "components/_legacy/Text";
import Box from "components/_legacy/Box";
import { TextField } from "@material-ui/core";
import Search from "components/_legacy/Icons/Search";
import Button from "components/_legacy/Button";
import { useEffect, useState } from "react";
import { getAttendees } from "App/Store/Meetings/meetingAttendees";
import InfiniteScroll from "react-infinite-scroll-component";
import _ from 'underscore';
import { Skeleton } from "@material-ui/lab";
import Neighbors from "components/_legacy/Icons/Neighbors";
import CheckboxCustom from "components/_legacy/CheckBoxCustom";
import { setCreateNewMeetingData } from 'App/Store/Meetings/createNewMeetingDuck';
import { IAttendees } from "App/Store/Meetings/meetingAttendees/models";
import { isEmail } from "class-validator";
import { darkBlue, grey, greyMedium } from "theme/_legacy/variables";

interface CapacityPopupProps {
  open: boolean;  
  setOpen: (open: boolean) => void;
}

type AttendeeCheckboxProps = {
  attendee: IAttendees;
  handleAttendeeSelection: (attendee: IAttendees) => void;
  isSelected: boolean;
};

const AttendeeCheckbox = ({ attendee, isSelected, handleAttendeeSelection }: AttendeeCheckboxProps): JSX.Element => {
  return (
    <Box
      alignItems="center"
      direction="row"
      display="flex"
      paddingBottom={5}
      paddingTop={5}
    >                    
        <CheckboxCustom
          checked={isSelected}
          name="attendeeCheckbox"
          onChange={() => handleAttendeeSelection(attendee)}
        />                    
      <Box
        alignItems="center"
        backgroundColor={attendee.id ? "#1E1F7B" : greyMedium}
        borderRadius={8}
        display="flex"
        height={30}
        justifyContent="center"
        marginRight={10}
        style={{ flex: "0 0 30px" }}
        width={30}
      >
        <Neighbors color="white" size={16} />
      </Box>
      <Box direction="column" display="flex">
        <Text
          size="md"
          style={{ color: isSelected ? darkBlue : undefined }}
          weight="semi-bold"
        >
          {attendee.displayName}
        </Text>
        <Text
          size="sm"
          style={{ width: "260px", color: isSelected ? darkBlue : grey }}
          truncate
          weight="normal"
        >
          {attendee.email}
        </Text>
      </Box>
    </Box>
  );
};

export default function CapacityPopup(props: CapacityPopupProps) {
  const dispatch = useDispatch();
  const [selectedAttendees, setSelectedAttendees] = useState<IAttendees[]>([]);
  const [selectedExternalAttendees, setSelectedExternalAttendees] = useState<string[]>([]);
  const [showSelectedAttendees, setShowSelectedAttendees] = useState<boolean>(true);
  const { meetingAttendees, createNewMeeting } = useTypedSelector(state => state);
  const { open, setOpen } = props;  
  const { loading, attendees, nextLink } = meetingAttendees;  
  const { selectedData } = createNewMeeting;  
  const { attendees: bookingAttendees, externalAttendees: bookingExternalAttendees } = selectedData;
  const attendeesToList: IAttendees[] = [];

  if (showSelectedAttendees) {
    if (selectedAttendees?.length) {
      attendeesToList.push(...selectedAttendees);
    }

    if (selectedExternalAttendees?.length) {
      attendeesToList.push(...selectedExternalAttendees.map((email) => ({ email, displayName: t`Invited user` })));
    }
  }
  
  if (attendees?.length) {
    if (showSelectedAttendees) {
      const nonSelectedAttendees = attendees.filter(({ id }) => !selectedAttendees.some((selectedAttendee) => selectedAttendee.id === id));

      attendeesToList.push(...nonSelectedAttendees);
    } else {
      attendeesToList.push(...attendees);
    }
  }
  
  const handleAttendeeSelection = (attendee: IAttendees) => {
    const isRemoval = (attendee.id && selectedAttendees.some(({ id }) => id === attendee.id))
      || (attendee.email && selectedExternalAttendees.some((email) => email === attendee.email));

    if (isRemoval) {
      if (attendee.id) {
        setSelectedAttendees(selectedAttendees.filter(({ id }) => id !== attendee.id));
      } else {
        setSelectedExternalAttendees(selectedExternalAttendees.filter((email) => email !== attendee.email));
      }
    } else {
      if (attendee.id) {
        const selectedAttendee = attendees.find(({ id }) => id === attendee.id);

        if (selectedAttendee) {
          setSelectedAttendees([...selectedAttendees, selectedAttendee]);
        }
      } else {
        setSelectedExternalAttendees([...selectedExternalAttendees, attendee.email]);
      }

      dispatch(getAttendees(undefined, { limit: 20 }));
    }

    setShowSelectedAttendees(true);
  };

  const handleLoadMore = () => {      
    if (nextLink) {
      dispatch(getAttendees(nextLink));
    }
  };

  const handleReset = () => {
    setSelectedAttendees([]);
    setSelectedExternalAttendees([]);
  };

  const handleDone = () => {
    const attendees: IAttendees[] = [];

    for (const attendee of selectedAttendees) {
      if (attendee.id) {
        attendees.push(attendee);
      }
    }

    dispatch(setCreateNewMeetingData({ 
      selectedData: {
        ...selectedData,
        attendees,
        externalAttendees: selectedExternalAttendees,       
      },      
    }));
    setOpen(false);
  };

  const [searchValue, setSearchValue] = useState("");

  const onSearchChange = _.debounce((event: any) => {
    const value = event.target.value;

    if (!value) {
      setShowSelectedAttendees(true);
      dispatch(getAttendees(undefined, { limit: 20 }));
      setSearchValue("");
    } else {
      setShowSelectedAttendees(false);
      dispatch(getAttendees(undefined, { limit: 20, search: value }));
      setSearchValue(value);
    }
  }, 300);

  const isAttendeeSelected = (attendee: IAttendees) => {
    if (attendee.id) {
      return selectedAttendees.some(({ id }) => attendee.id === id);
    } else {
      return selectedExternalAttendees.some((email) => attendee.email === email);
    }
  };

  useEffect(() => {
    if (open) {
      dispatch(getAttendees(undefined, { limit: 20 }));
    }
  }, [open]);

  useEffect(() => {
    if (bookingAttendees && bookingAttendees.length > 0) {
      setSelectedAttendees(bookingAttendees);
    }

    if (bookingExternalAttendees?.length) {
      setSelectedExternalAttendees(bookingExternalAttendees);
    }
  }, []);

  return (
    <>
      <Popup                
        contentStyle={{ maxWidth: 420, width: '100%' }}
        modal
        onClose={() => setOpen(false)}
        open={open}
        position="bottom right"        
      >
        <Box
          className={styles.popoverContent}
          direction="column"
          display='flex' 
          height={422}
          padding={"16px 20px"}
        >
          <Box
            alignItems="center"
            direction="row"
            display="flex"
            justifyContent="between"
            marginBottom={16}
          >
            <Box display="flex" gap={10}>
              <Box
                alignItems="center"
                backgroundColor="#F4F4F4"
                borderRadius={8}
                display="flex"
                height={30}
                justifyContent="center"
                width={30}
              >
                <Neighbors size={16} />
              </Box>              
              <Text className={styles.text} size="md">
                <Trans>Attendees</Trans>
                <span>:{(selectedAttendees?.length || 0) + (selectedExternalAttendees?.length || 0)}</span>
              </Text>
            </Box>            
            <Box>
              <Button onClick={handleReset} size="xs" type="clear">
                <Trans>Reset</Trans>
              </Button>
              <Button onClick={handleDone} size="xs">
                <Trans>Done</Trans>                
              </Button>
            </Box>
          </Box>          
          <Box alignItems="center" direction="row" display="flex">
            <TextField
              InputProps={{ startAdornment: (<Search />) }} 
              className={`input input--default input--search`}
              onChange={onSearchChange}
              placeholder={t`Search user or invite via e-mail`}
              style={{ height: "32px" }}
              variant="outlined"
            />
            <Button
              disabled={loading || attendees.length > 0 || !isEmail(searchValue)}
              onClick={() => handleAttendeeSelection({ email: searchValue })}
              size="xs"
              style={{ width: "60px", flex: "0 0 60px", marginLeft: "8px" }}
              type="clear"
            >
              <Trans>Invite</Trans>
            </Button>
          </Box>      
          {
            loading ? (
              <Box className={styles.notificationInfiniteScroll} height="auto" marginTop={5}>
                {
                  attendeesToList.map((attendee: IAttendees, index: number) => (
                    <AttendeeCheckbox
                      attendee={attendee}
                      handleAttendeeSelection={(attendee) => handleAttendeeSelection(attendee)}
                      isSelected={isAttendeeSelected(attendee)}
                      key={index}
                    />
                  ))
                }
                <Box alignItems="center" display="flex" height={52} justifyContent="center" width="100%">
                  <Skeleton animation="wave" height={76} width="100%" />
                </Box>
                <Box alignItems="center" display="flex" height={52} justifyContent="center" width="100%">
                  <Skeleton animation="wave" height={76} width="100%" />
                </Box>
              </Box>
            ) : (
              attendeesToList.length ? (
                <InfiniteScroll
                  className={styles.notificationInfiniteScroll}
                  dataLength={attendeesToList.length}
                  hasMore={!!nextLink}
                  height={'auto'}
                  loader={<h5 style={{ textAlign: 'center' }}>Loading...</h5>}
                  next={handleLoadMore}
                  style={{ overflowX: "hidden" }}
                >
                  {
                    attendeesToList.map((attendee: IAttendees, index: number) => (
                      <AttendeeCheckbox
                        attendee={attendee}
                        handleAttendeeSelection={(attendee) => handleAttendeeSelection(attendee)}
                        isSelected={isAttendeeSelected(attendee)}
                        key={index}
                      />
                    ))
                  }
                </InfiniteScroll>
              ) : (
                <Text align="center" className={styles.noNotifications} color="lightGray" size="md">
                  <Trans>
                    No users
                  </Trans>
                </Text>
              )
            )                    
          }                        
        </Box>
      </Popup>      
    </>
  );
}
