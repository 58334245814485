import { t } from "@lingui/macro";
import { East, KeyboardArrowDown } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress, Dialog, FormControl, IconButton, InputLabel, Popover, Skeleton, Switch, SxProps, TextField, Tooltip, Typography, TypographyProps, lighten, useTheme } from "@mui/material";
import { TogetherDateAndTime, TogetherRoom, TogetherUser } from "../icons";
import { LinkButton, TextButton } from "../button";
import { addDays, addWeeks, differenceInDays, differenceInMinutes, isToday, parse, set, startOfDay } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Attendee, ReservationServiceRequestSetting, ReservationServiceRequestSettingType, ReservationServiceType, ReservationType, isSuccessAPIResponse, reservationsSlice, roomsSlice, selectCurrentReservation, selectCurrentRoom, useCreateReservationForMeMutation, useGetRoomByIdQuery, useGetRoomScheduleQuery, useLazyGetRoomScheduleConflictsQuery, useReservationsAvailabilityQuery } from "store";
import { DatePickerCalendar } from "../date-picker-calendar";
import { Link, useLocation, useParams } from "react-router-dom";
import { CalendarViewSelection, WeeklyCalendarView, WeeklyCalendarViewItem, WeeklyCalendarViewProps, ceiledCalendarViewDate } from "../calendar-view";
import { useDebouncedCallback, useDefaultTimeZone, useExceptionTracker } from "hooks";
import { AttendeesSearch } from "../attendees-search";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { useToast } from "../toast-provider";
import { theme } from "theme";
import { InteractiveTypography } from "../typography";
import { FromToTimeInput, FromToTimeInputProps } from "../input";
import { format, toDate, utcToZonedTime } from "date-fns-tz";
import { getNowInTimeZone, getZeroDayInTimeZone, isSameDayInTimeZone, localZeroDay, recurrenceOptionsToRRule, safeParseISO, shiftTimeZone, unshiftTimeZone } from "utils";
import { RecurrenceOptionsSelect, RecurrenceOptionsSelectProps } from "components/recurrence-options";
import { RecurrenceOptions } from "types";
import { CateringDialog, CateringDialogProps } from "components/catering";
import { MarkRequired } from "ts-essentials";
import { TimeZoneDisclaimer } from "components/disclaimer";

const ReservationDetailsDateAndTime: React.FC = () => {
  const dispatch = useDispatch();
  const { startDate } = useReservationsAvailabilityQuery();
  const [defaultTimeZone] = useDefaultTimeZone();
  const now = shiftTimeZone(new Date(), defaultTimeZone);
  const selectedDay = parse(startDate, "yyyy-MM-dd", localZeroDay);

  const handleDatePickerCalendarChange = (selectedDay: Date) => {
    const startDate = format(selectedDay, "yyyy-MM-dd");
    const endDate = startDate;

    dispatch(reservationsSlice.actions.setAvailabilityQuery({ startDate, endDate }));
  };

  return (
    <Box width="100%">
      <Box marginBottom={2}>
        <DatePickerCalendar date={selectedDay} minDate={now} onChange={handleDatePickerCalendarChange} />
      </Box>
    </Box>
  );
};

const ReservationDetailsRoomSchedule: React.FC = () => {
  const dispatch = useDispatch();
  const { locationId, floorId } = useParams<{ locationId?: string; floorId?: string }>();
  const currentReservation = useSelector(selectCurrentReservation);
  const [interval, setInterval] = useState<[Date, Date]>();
  const [defaultTimeZone] = useDefaultTimeZone();
  const { startDate } = useReservationsAvailabilityQuery();
  const getRoomByIdQuery = useGetRoomByIdQuery({
    locationId: locationId || currentReservation?.locationId || "",
    floorId: floorId || currentReservation?.floorId || "",
    roomId: currentReservation?.roomId || "",
    include: ["extra.mapDrawing"],
  });
  const { data: roomResponse, isLoading: roomIsLoading, isFetching: roomIsFetching } = getRoomByIdQuery;
  const room = roomResponse?.result?.data;
  const getRoomScheduleQuery = useGetRoomScheduleQuery(
    {
      locationId: locationId || currentReservation?.locationId || "",
      floorId: floorId || currentReservation?.floorId || "",
      roomId: currentReservation?.roomId || "",
      startDate: interval?.[0] || getNowInTimeZone(defaultTimeZone),
      endDate: interval?.[1] || getNowInTimeZone(defaultTimeZone),
    },
    { skip: !interval },
  );
  const { data: roomScheduleResponse, isLoading: roomScheduleIsLoading, isFetching: roomScheduleIsFetching } = getRoomScheduleQuery;
  const roomSchedule = roomScheduleResponse?.result?.data;
  const maxDate = addDays(getNowInTimeZone(defaultTimeZone), room?.reservationDayLimit || 7);

  useEffect(() => {
    if (roomSchedule) {
      const { schedules } = roomSchedule;

      dispatch(roomsSlice.actions.setCurrent({ schedules }));
    }
  }, [roomSchedule]);

  const handleWeeklyCalendarViewChange: WeeklyCalendarViewProps["onChange"] = (selection) => {
    if (selection) {
      const { start, end, isAllDay } = selection;
      const startDate = start ? shiftTimeZone(start, defaultTimeZone).toISOString() : undefined;
      const endDate = end ? shiftTimeZone(end, defaultTimeZone).toISOString() : undefined;

      dispatch(reservationsSlice.actions.setCurrent({ startDate, endDate, isAllDay }));
    } else {
      dispatch(reservationsSlice.actions.setCurrent({ startDate: undefined, endDate: undefined, isAllDay: undefined }));
    }
  };

  let item: WeeklyCalendarViewItem | undefined = undefined;
  
  if (roomSchedule) {
    const { schedules } = roomSchedule;

    item = {
      id: roomSchedule.roomId,
      schedules: [],
    };

    for (const schedule of schedules) {
      const { startDate: startDateString, endDate: endDateString, organizer, summary } = schedule;
      const startDate = unshiftTimeZone(new Date(startDateString), defaultTimeZone);
      const endDate = unshiftTimeZone(new Date(endDateString), defaultTimeZone);

      item.schedules?.push({ startDate, endDate, organizer, summary });
    }
  }

  let defaultSelection: CalendarViewSelection | undefined = undefined;

  if (currentReservation) {
    const { roomId, startDate, endDate, recurrence, isAllDay } = currentReservation;

    if (roomId && startDate) {
      defaultSelection = {
        isAllDay,
        id: roomId,
        start: startDate ? unshiftTimeZone(new Date(startDate), defaultTimeZone) : undefined,
        end: endDate ? unshiftTimeZone(new Date(endDate), defaultTimeZone) : undefined,
        recurrence: recurrence ? { ...recurrence, start: new Date(recurrence.start), end: new Date(recurrence.end) } : undefined,
      };
    }
  }
    
  return (
    <WeeklyCalendarView
      defaultSelection={defaultSelection}
      height={362}
      isLoading={roomScheduleIsLoading || roomScheduleIsFetching || roomIsLoading || roomIsFetching}
      item={item}
      maxDate={maxDate}
      minDate={getNowInTimeZone(defaultTimeZone)}
      onChange={handleWeeklyCalendarViewChange}
      onStartDayChange={(interval) => setInterval(interval)}
      selectedDay={parse(startDate, "yyyy-MM-dd", localZeroDay)}
      timeZone={defaultTimeZone}
    />
  );
};

const ReservationDetailsRoomDetails: React.FC = () => {
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const { locationId, floorId } = useParams<{ locationId?: string; floorId?: string }>();
  const currentReservation = useSelector(selectCurrentReservation);
  const [summary, setSummary] = useState(currentReservation?.summary);
  const debouncedSetCurrentReservationSummary = useDebouncedCallback((summary?: string) => {
    dispatch(reservationsSlice.actions.setCurrent({ summary }));
  }, [dispatch], 500);
  const getRoomByIdQuery = useGetRoomByIdQuery({
    locationId: locationId || currentReservation?.locationId || "",
    floorId: floorId || currentReservation?.floorId || "",
    roomId: currentReservation?.roomId || "",
    include: ["extra.mapDrawing"],
  });
  const { data: roomResponse, isLoading: roomIsLoading, isFetching: roomIsFetching } = getRoomByIdQuery;
  const room = roomResponse?.result?.data;
  const attendeesCount = currentReservation?.attendees?.length || 0;
  const availableServices = room?.availableServices?.map?.(({ type }) => type);

  useEffect(() => {
    if (summary !== currentReservation?.summary) {
      setSummary(currentReservation?.summary);
    }
  }, [currentReservation?.summary]);

  useEffect(() => {
    debouncedSetCurrentReservationSummary(summary);
  }, [summary, debouncedSetCurrentReservationSummary]);

  const handleAttendeesSearchChange = (attendees: Attendee[]) => {
    dispatch(reservationsSlice.actions.setCurrent({ attendees }));
  };

  const handleReservationServiceChange = (type: ReservationServiceType, checked: boolean) => {
    const requestedServices = currentReservation?.requestedServices || [];
    const isSelected = requestedServices.includes(type);

    if (checked && !isSelected) {
      dispatch(reservationsSlice.actions.setCurrent({
        requestedServices: [...requestedServices, type],
        serviceRequestSettings: [{
          type: ReservationServiceRequestSettingType.ATTENDEES_NUMBER,
          serviceType: ReservationServiceType.CATERING,
          value: `${attendeesCount}`,
        }],
      }));
    } else if (!checked && isSelected) {
      dispatch(reservationsSlice.actions.setCurrent({ requestedServices: requestedServices.filter((value) => value !== type) }));

      if (type === ReservationServiceType.CATERING) {
        dispatch(reservationsSlice.actions.setCurrent({
          serviceOptionRequests: currentReservation?.serviceOptionRequests?.filter(({ serviceType, option }) => {
            return serviceType !== ReservationServiceType.CATERING && option?.serviceType !== ReservationServiceType.CATERING;
          }),
          serviceRequestSettings: currentReservation?.serviceRequestSettings?.filter(({ serviceType }) => {
            return serviceType !== ReservationServiceType.CATERING;
          }),
        }));
      }
    }
  };

  const handleCateringChange: CateringDialogProps["onChange"] = (serviceOptionRequests, serviceRequestSettings) => {
    dispatch(reservationsSlice.actions.setCurrent({ serviceOptionRequests, serviceRequestSettings }));
  };

  if (roomIsLoading || roomIsFetching) {
    return (
      <Box>
        <Skeleton height={32} sx={({ palette }) => ({ bgcolor: palette.grey[100], borderRadius: 2, marginBottom: 2 })} variant="rectangular" />
        <Skeleton height={32} sx={({ palette }) => ({ bgcolor: palette.grey[100], borderRadius: 2, marginBottom: 2 })} variant="rectangular" />
        <Skeleton height={32} sx={({ palette }) => ({ bgcolor: palette.grey[100], borderRadius: 2, marginBottom: 2 })} variant="rectangular" />
      </Box>
    );
  }

  return (
    <Box marginBottom={2}>
      <Box alignItems="center" display="flex" justifyContent="space-between" marginBottom={2}>
        <Box alignItems="center" display="flex">
          <Box
            alignItems="center"
            bgcolor={theme.palette.grey[100]}
            borderRadius={2}
            display="flex"
            height={32}
            justifyContent="center"
            marginRight={1}
            width={32}
          >
            <TogetherUser fill={theme.palette.grey[700]} stroke={theme.palette.grey[700]} sx={{ width: 16 }} />
          </Box>
          <Typography fontWeight="600">
            {t`Attendees`}{" "}{room?.capacity ? `(${attendeesCount}/${room?.capacity})` : `(${attendeesCount})`}
          </Typography>
        </Box>
        <AttendeesSearch
          Trigger={({ onClick }) => (
            <TextButton data-cid="select-attendees-button" onClick={onClick} sx={{ fontSize: 14, color: palette.primary.main }}>
              {t`Select`}
            </TextButton>
          )}
          capacity={room?.capacity}
          onChange={handleAttendeesSearchChange}
          value={currentReservation?.attendees}
        />
      </Box>
      {availableServices?.includes?.(ReservationServiceType.CATERING) ? (
        <Box alignItems="center" display="flex" justifyContent="space-between" marginBottom={2}>
          <Box alignItems="center" display="flex">
            <Box alignItems="center" display="flex" height={32} justifyContent="center" marginRight={1} width={32} >
              <Switch
                checked={!!currentReservation?.requestedServices?.includes(ReservationServiceType.CATERING)}
                onChange={(_, checked) => handleReservationServiceChange(ReservationServiceType.CATERING, checked)}
              />
            </Box>
            <Typography fontWeight="600">{t`I need catering`}</Typography>
          </Box>
          <CateringDialog
            Trigger={({ onClick }) => (
              <LinkButton disabled={!currentReservation?.requestedServices?.includes(ReservationServiceType.CATERING)} onClick={onClick}>
                {t`Select`}
              </LinkButton>
            )}
            locationId={locationId || currentReservation?.locationId || ""}
            onChange={handleCateringChange}
            requests={currentReservation?.serviceOptionRequests}
            settings={currentReservation?.serviceRequestSettings}
          />
        </Box>
      ) : undefined}
      {availableServices?.includes?.(ReservationServiceType.IT_SUPPORT) ? (
        <Box alignItems="center" display="flex" marginBottom={2}>
          <Box alignItems="center" display="flex" height={32} justifyContent="center" marginRight={1} width={32} >
            <Switch
              checked={!!currentReservation?.requestedServices?.includes(ReservationServiceType.IT_SUPPORT)}
              onChange={(_, checked) => handleReservationServiceChange(ReservationServiceType.IT_SUPPORT, checked)}
            />
          </Box>
          <Typography fontWeight="600">{t`I need IT support`}</Typography>
        </Box>
      ) : undefined}
      <FormControl sx={{ marginBottom: 2 }} variant="filled">
        <InputLabel htmlFor="summary" required>{t`Meeting title`}</InputLabel>
        <TextField
          id="summary"
          onChange={(event) => setSummary(event.currentTarget.value)}
          value={summary}
          variant="filled"
        />
      </FormControl>
      <Box alignItems="center" display="flex">
        <Box alignItems="center" display="flex" height={32} justifyContent="center" marginRight={1} width={32} >
          <Switch
            checked={!!currentReservation?.isTeamsMeeting}
            onChange={(_, isTeamsMeeting) => dispatch(reservationsSlice.actions.setCurrent({ isTeamsMeeting }))}
          />
        </Box>
        <Typography fontWeight="600">{t`Create Teams meeting`}</Typography>
      </Box>
    </Box>
  );
};

const ReservationDetails: React.FC = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { locationId, floorId } = useParams<{ locationId?: string; floorId?: string }>();
  const availabilityQuery = useReservationsAvailabilityQuery();
  const currentReservation = useSelector(selectCurrentReservation);
  const currentRoom = useSelector(selectCurrentRoom);
  const { pathname } = useLocation();
  const [expanded, setExpanded] = useState(true);
  const [calendarAnchorEl, setCalendarAnchorEl] = useState<HTMLSpanElement>();
  const [defaultTimeZone, defaultWindowsTimeZone] = useDefaultTimeZone();
  const zeroDayInDefaultTimezone = getZeroDayInTimeZone(defaultTimeZone);
  const [selectedDay, setSelectedDay] = useState(parse(availabilityQuery.startDate, "yyyy-MM-dd", zeroDayInDefaultTimezone));
  const recurrenceStart = useMemo(() => safeParseISO(currentReservation?.startDate), [currentReservation?.startDate]);
  const getRoomByIdQuery = useGetRoomByIdQuery({
    locationId: locationId || currentReservation?.locationId || "",
    floorId: floorId || currentReservation?.floorId || "",
    roomId: currentReservation?.roomId || "",
    include: ["extra.mapDrawing"],
  }, { 
    skip: (!locationId && !currentReservation?.locationId) || (!floorId && !currentReservation?.floorId),
  });
  const { data: roomResponse, isLoading: roomIsLoading, isFetching: roomIsFetching } = getRoomByIdQuery;
  const room = roomResponse?.result?.data;
  const { roomId, startDate, endDate } = currentReservation || {};
  const now = getNowInTimeZone(defaultTimeZone);
  const maxDate = addDays(now, room?.reservationDayLimit || 1);
  const isListView = /^\/reservations\/locations\/(\w|-)+\/floors\/(\w|-)+\/rooms\/?$/.test(pathname);
  const isCalendarView = /^\/reservations\/locations\/(\w|-)+\/floors\/(\w|-)+\/rooms\/calendar-view\/?$/.test(pathname);
  const step = 15;

  useEffect(() => {
    if (isCalendarView && expanded) {
      setExpanded(false);
    }
  }, [isCalendarView, setExpanded]);

  useEffect(() => {
    const startDate = safeParseISO(currentReservation?.startDate);

    if (startDate && !isSameDayInTimeZone(startDate, selectedDay, defaultTimeZone)) {
      setSelectedDay(set(startDate, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }));
    }
  }, [currentReservation?.startDate]);

  useEffect(() => {
    if (isCalendarView) {
      const startDate = parse(availabilityQuery.startDate, "yyyy-MM-dd", zeroDayInDefaultTimezone);

      if (!isSameDayInTimeZone(selectedDay, startDate, defaultTimeZone)) {
        setSelectedDay(startDate);
      }
    }
  }, [isCalendarView, availabilityQuery.startDate]);

  const unavailable = useMemo<FromToTimeInputProps["unavailable"]>(() => {
    const unavailable: FromToTimeInputProps["unavailable"] = [];

    if (currentRoom?.schedules) {
      if (currentRoom?.schedules?.length) {
        const { schedules } = currentRoom;

        for (const schedule of schedules) {
          const startDate = utcToZonedTime(toDate(schedule.startDate), defaultTimeZone);

          if (isSameDayInTimeZone(selectedDay, startDate, defaultTimeZone)) {
            const endDate = utcToZonedTime(toDate(schedule.endDate), defaultTimeZone);

            unavailable.push([startDate, endDate]);
          }
        }
      }

      return unavailable.sort(([startA], [startB]) => differenceInMinutes(startA, startB));
    }
  }, [JSON.stringify(currentRoom?.schedules), selectedDay?.toISOString()]);
  
  const handleDeselect = () => {
    dispatch(reservationsSlice.actions.setCurrent({
      roomId: undefined,
      floorId: undefined,
      startDate: undefined,
      endDate: undefined,
      requestedServices: undefined,
      isAllDay: undefined,
    }));
    dispatch(roomsSlice.actions.resetCurrent());
  };

  const handleSelectedDayClick: TypographyProps["onClick"] = (event) => {
    setCalendarAnchorEl(event.currentTarget);
  };

  const handleSelectedDayChange = (value: Date) => {
    dispatch(reservationsSlice.actions.setCurrent({ startDate: undefined, endDate: undefined, isAllDay: undefined }));

    if (isListView) {
      const availabilityQueryStartDate = parse(availabilityQuery.startDate, "yyyy-MM-dd", now);
      const daysDifference = differenceInDays(value, availabilityQueryStartDate);

      if (daysDifference > 6) {
        const startDate = format(addWeeks(availabilityQueryStartDate, 1), "yyyy-MM-dd", { timeZone: defaultTimeZone });
        const endDate = startDate;

        dispatch(roomsSlice.actions.setCurrent({ schedules: undefined }));
        dispatch(reservationsSlice.actions.setAvailabilityQuery({ ...availabilityQuery, startDate, endDate }));
      } else if (daysDifference < 0) {
        const startDate = format(addWeeks(availabilityQueryStartDate, -1), "yyyy-MM-dd", { timeZone: defaultTimeZone });
        const endDate = startDate;

        dispatch(roomsSlice.actions.setCurrent({ schedules: undefined }));
        dispatch(reservationsSlice.actions.setAvailabilityQuery({ ...availabilityQuery, startDate, endDate }));
      }

      setSelectedDay(value);
      setCalendarAnchorEl(undefined);
    }

    if (isCalendarView) {
      const startDate = format(value, "yyyy-MM-dd", { timeZone: defaultTimeZone });
      const endDate = startDate;

      dispatch(roomsSlice.actions.setCurrent({ schedules: undefined }));
      dispatch(reservationsSlice.actions.setAvailabilityQuery({ startDate, endDate }));
    }
  };

  const handleFromToTimeInputChange: FromToTimeInputProps["onChange"] = ({ from, to }) => {
    const year = selectedDay.getFullYear();
    const date = selectedDay.getDate();
    const month = selectedDay.getMonth();
    const startDate = shiftTimeZone(set(from, { year, date, month }), defaultTimeZone).toISOString();
    const endDate = shiftTimeZone(set(to, { year, date, month }), defaultTimeZone).toISOString();

    dispatch(reservationsSlice.actions.setCurrent({ startDate, endDate, isAllDay: undefined }));
  };

  const handleRecurrenceOptionsChange: RecurrenceOptionsSelectProps["onChange"] = (value) => {
    const recurrence: RecurrenceOptions<string> | undefined = value
      ? { ...value, start: value.start.toISOString(), end: value.end.toISOString() }
      : undefined;

    dispatch(reservationsSlice.actions.setCurrent({ recurrence }));
  };

  const recurrence: RecurrenceOptions | undefined = currentReservation?.recurrence
    ? { ...currentReservation.recurrence, start: new Date(currentReservation.recurrence.start), end: new Date(currentReservation.recurrence.end) }
    : undefined;

  return (
    <Box>
      <Box marginBottom={3}>
        <Accordion disableGutters elevation={0} expanded={expanded}>
          <AccordionSummary
            expandIcon={undefined}
            onClick={undefined}
            sx={{
              padding: 0,
              cursor: "default",
              borderBottomStyle: "solid",
              borderBottomWidth: 0.5,
              borderBottomColor: theme.palette.grey[100],
              ":hover:not(.Mui-disabled)": {
                cursor: "default",
              },
              "&.Mui-focusVisible": {
                backgroundColor: "unset",
              },
            }}
          >
            <Box flex={1}>
              <Box alignItems="center" display="flex" justifyContent="space-between" width="100%">
                <Box alignItems="center" display="flex">
                  <Box
                    alignItems="center"
                    bgcolor={theme.palette.grey[100]}
                    borderRadius={2}
                    display="flex"
                    height={32}
                    justifyContent="center"
                    width={32}
                  >
                    <TogetherDateAndTime fill={theme.palette.grey[700]} sx={{ width: 20 }} />
                  </Box>
                  {roomId && selectedDay ? (
                    <Box alignItems="center" display="flex">
                      {roomIsLoading || roomIsFetching ? (
                        <Skeleton height={20} sx={{ marginX: 1 }} variant="rectangular" width={86} />
                      ) : (
                        <>
                          <InteractiveTypography fontSize={14} fontWeight="500" onClick={handleSelectedDayClick}>
                            {format(selectedDay, "d LLL, yyyy.", { timeZone: defaultTimeZone })}
                          </InteractiveTypography>
                          <Popover
                            anchorEl={calendarAnchorEl}
                            anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                            elevation={4}
                            onClose={() => setCalendarAnchorEl(undefined)}
                            open={!!calendarAnchorEl}
                            sx={{ mt: 0.5 }}
                            transformOrigin={{ vertical: "top", horizontal: "center" }}
                          >
                            <Box padding={2} width={360}>
                              <DatePickerCalendar date={selectedDay} maxDate={maxDate} minDate={now} onChange={handleSelectedDayChange} />
                            </Box>
                          </Popover>
                        </>
                      )}
                      <FromToTimeInput
                        isLoading={!currentRoom?.schedules}
                        min={isToday(selectedDay) ? ceiledCalendarViewDate(now, step) : undefined}
                        onChange={handleFromToTimeInputChange}
                        step={step}
                        unavailable={unavailable}
                        value={{
                          from: startDate ? unshiftTimeZone(new Date(startDate), defaultTimeZone) : undefined,
                          to: endDate ? unshiftTimeZone(new Date(endDate), defaultTimeZone) : undefined,
                        }}
                      />
                    </Box>
                  ) : (
                    <Typography fontWeight="600" ml={1}>{t`Select a room`}</Typography>
                  )}
                </Box>
                {isListView ? (
                  <IconButton color="primary" onClick={() => setExpanded(!expanded)} size="medium">
                    <KeyboardArrowDown
                      fontSize="medium"
                      sx={{ transform: expanded ? "rotate(180deg)" : "rotate(0deg)", transition: `transform 0.15s` }}
                    />
                  </IconButton>
                ) : undefined}
              </Box>
              {isListView && roomId && selectedDay ? (
                <Box mt={1}>
                  <TimeZoneDisclaimer
                    timeZone={defaultTimeZone}
                    tooltip={defaultWindowsTimeZone ? t`Derived from "${defaultWindowsTimeZone}" time zone.` : undefined}
                  />
                </Box>
              ) : undefined}
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            {roomId && isListView ? (
              <ReservationDetailsRoomSchedule />
            ) : (
              <ReservationDetailsDateAndTime />
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
      {currentRoom?.id ? (
        <Box mb={2}>
          <RecurrenceOptionsSelect
            conflicts={currentRoom?.conflicts?.map((conflict) => ({
              startDate: new Date(conflict.startDate),
              endDate: new Date(conflict.endDate),
              schedule: {
                ...conflict.schedule,
                startDate: new Date(conflict.schedule.startDate),
                endDate: new Date(conflict.schedule.endDate),
              },
            }))}
            maxDate={maxDate}
            onChange={handleRecurrenceOptionsChange}
            start={recurrenceStart}
            timeZone={defaultTimeZone}
            value={recurrence}
          />
        </Box>
      ) : undefined}
      {isListView ? (
        <Box alignItems="center" display="flex" justifyContent="space-between" marginBottom={2}>
          <Box alignItems="center" display="flex">
            <Box
              alignItems="center"
              bgcolor={theme.palette.grey[100]}
              borderRadius={2}
              display="flex"
              height={32}
              justifyContent="center"
              marginRight={1}
              width={32}
            >
              <TogetherRoom stroke={theme.palette.grey[700]} sx={{ width: 16 }} />
            </Box>
            <Typography fontWeight="600">
              {currentRoom?.name ? currentRoom.name : t`Room is not selected`}
            </Typography>
          </Box>
          {roomId && isListView ? (
            <LinkButton onClick={handleDeselect}>{t`Deselect`}</LinkButton>
          ) : undefined}
        </Box>
      ) : undefined}
      {roomId ? <ReservationDetailsRoomDetails /> : undefined}
    </Box>
  );
};

const ReservationsBreadcrumbs: React.FC = () => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const currentReservation = useSelector(selectCurrentReservation);
  const isLocationsPage = /^\/reservations\/locations\/?$/.test(pathname);
  const isLocationFloorsPage = /^\/reservations\/locations\/(\w|-)+\/floors\/?$/.test(pathname);
  const isFloorRoomsPage = /^\/reservations\/locations\/(\w|-)+\/floors\/(\w|-)+\/rooms/.test(pathname);

  const resolveColor = (item: number): string | undefined => {
    if ((item === 1 && isLocationsPage) || (item === 2 && isLocationFloorsPage) || (item === 3 && isFloorRoomsPage)) {
      return theme.palette.primary.main; 
    }

    if ((item === 2 && isLocationsPage) || (item === 3 && (isLocationsPage || isLocationFloorsPage))) {
      return theme.palette.grey[500];
    }

    return undefined;
  };

  const resolveBGColor = (item: number): string => {
    if ((item === 2 && isLocationsPage) || (item === 3 && (isLocationsPage || isLocationFloorsPage))) {
      return theme.palette.grey[100];
    }

    return theme.palette.secondary.main;
  };

  const resolveBoxSX = (item: number): SxProps<typeof theme> => {
    if ((item === 1 && (isLocationFloorsPage || isFloorRoomsPage)) || (item == 2 && isFloorRoomsPage)) {
      return {};
    }

    return { cursor: "auto", pointerEvents: "none" };
  };

  return (
    <Box display="flex">
      <Box alignItems="center" component={Link} data-cid="select-location-link" display="flex" mr={1} sx={resolveBoxSX(1)} to={`/reservations/locations`}>
        <Box
          alignItems="center"
          bgcolor={resolveBGColor(1)}
          borderRadius={2}
          display="flex"
          height={22}
          justifyContent="center"
          mr={1}
          width={22}
        >
          <Typography borderRadius={2} color={resolveColor(1)} fontSize={14} fontWeight="600">1</Typography>
        </Box>
        <Typography color={resolveColor(1)} fontSize={18} fontWeight="600" mr={1}>{t`Select location`}</Typography>
        <East sx={{ width: 20, color: resolveColor(1) }} />
      </Box>
      <Box alignItems="center" component={Link} data-cid="select-floor-link" display="flex" mr={1} sx={resolveBoxSX(2)} to={`/reservations/locations/${currentReservation?.locationId}/floors`}>
        <Box
          alignItems="center"
          bgcolor={resolveBGColor(2)}
          borderRadius={2}
          display="flex"
          height={22}
          justifyContent="center"
          mr={1}
          width={22}
        >
          <Typography borderRadius={2} color={resolveColor(2)} fontSize={14} fontWeight="600">2</Typography>
        </Box>
        <Typography color={resolveColor(2)} fontSize={18} fontWeight="600" mr={1}>{t`Select floor`}</Typography>
        <East sx={{ width: 20, color: resolveColor(2) }} />
      </Box>
      <Box alignItems="center" display="flex">
        <Box
          alignItems="center"
          bgcolor={resolveBGColor(3)}
          borderRadius={2}
          display="flex"
          height={22}
          justifyContent="center"
          mr={1}
          width={22}
        >
          <Typography borderRadius={2} color={resolveColor(3)} fontSize={14} fontWeight="600">3</Typography>
        </Box>
        <Typography color={resolveColor(3)} fontSize={18} fontWeight="600" mr={1}>{t`Select room`}</Typography>
      </Box>
    </Box>
  );
};

export const ReservationsWrapper: React.FC = (props) => {
  const { children } = props;
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const trackException = useExceptionTracker();
  const currentReservation = useSelector(selectCurrentReservation);
  const [loading, setLoading] = useState(false);
  const [isValidInput, setIsValidInput] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLButtonElement>();
  const [submitTooltip, setSubmitTooltip] = useState<string>();
  const [isCheckingConflicts, setIsCheckingConflicts] = useState<boolean>(false);
  const defaultTimeZones = useDefaultTimeZone();
  const [createReservationForMe] = useCreateReservationForMeMutation();
  const [triggerGetRoomScheduleConflictsQuery, getRoomScheduleConflictsQuery] = useLazyGetRoomScheduleConflictsQuery();
  const { data: getRoomScheduleConflictsResponse, isLoading: conflictsAreLoading, isFetching: conflictsAreFetching } = getRoomScheduleConflictsQuery;
  const { conflicts } = getRoomScheduleConflictsResponse?.result?.data || {};
  const toast = useToast();
  
  useEffect(() => {
    const requiredValuesAreSet = !!currentReservation?.roomId 
      && !!currentReservation?.summary
      && !!currentReservation?.startDate
      && !!currentReservation?.endDate;

    if (!requiredValuesAreSet) {
      if (!currentReservation?.locationId) {
        setSubmitTooltip(t`Location is not selected`);
      } else if (!currentReservation?.floorId) {
        setSubmitTooltip(t`Floor is not selected`);
      } else if (!currentReservation?.roomId) {
        setSubmitTooltip(t`Room is not selected`);
      } else if (!currentReservation?.startDate || !currentReservation?.endDate) {
        setSubmitTooltip(t`Select date and time`);
      } else if (!currentReservation?.summary) {
        setSubmitTooltip(t`Meeting title must be set`);
      }

      if (isValidInput) {
        setIsValidInput(false);
      }
    } else {
      if (!isValidInput) {
        setIsValidInput(true);
      }
      
      if (conflicts?.length) {
        setSubmitTooltip(t`Conflicts must be fixed`);
      } else {
        setSubmitTooltip(undefined);
      }
    }
  }, [currentReservation, isValidInput, conflicts?.length, setIsValidInput]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const { startDate, endDate, recurrence, roomId, locationId, floorId } = currentReservation || {};

      if (locationId && floorId && roomId && startDate && endDate && recurrence) {
        const rrule = recurrence
          ? recurrenceOptionsToRRule({ ...recurrence, start: new Date(recurrence.start), end: new Date(recurrence.end) })?.toString()
          : undefined;

        if (rrule) {
          triggerGetRoomScheduleConflictsQuery({
            locationId,
            floorId,
            roomId,
            startDate: new Date(startDate),
            endDate: new Date(endDate),
            rrule,
          });
        }
      }

      if (isCheckingConflicts) {
        setIsCheckingConflicts(false);
      }
    }, 700);

    if (!isCheckingConflicts) {
      setIsCheckingConflicts(true);
    }

    dispatch(roomsSlice.actions.setCurrent({ conflicts: undefined }));

    return () => clearTimeout(timeout);
  }, [
    currentReservation?.locationId,
    currentReservation?.floorId,
    currentReservation?.roomId,
    currentReservation?.startDate,
    currentReservation?.endDate,
    currentReservation?.recurrence,
    setIsCheckingConflicts,
    dispatch,
  ]);

  useEffect(() => {
    if (conflictsAreFetching || conflictsAreLoading) {
      dispatch(roomsSlice.actions.setCurrent({ conflicts: undefined }));
    } else {
      dispatch(roomsSlice.actions.setCurrent({ conflicts }));
    }
  }, [conflicts, conflictsAreLoading, conflictsAreFetching, currentReservation?.recurrence, dispatch]);

  const handleSubmitClick: LoadingButtonProps["onClick"] = (event) => {
    const target = event.currentTarget;
    const timeout = setTimeout(() => setPopoverAnchorEl(target), 5000);

    void (async () => {
      const {
        roomId,
        startDate,
        endDate,
        recurrence,
        attendees,
        isTeamsMeeting,
        requestedServices,
        summary,
        isAllDay,
        serviceOptionRequests,
        serviceRequestSettings,
      } = currentReservation || {};
  
      if (roomId && startDate && endDate) {
        setLoading(true);
        
        const rrule = recurrence
          ? recurrenceOptionsToRRule({ ...recurrence, start: new Date(recurrence.start), end: new Date(recurrence.end) })?.toString()
          : undefined;
        const [defaultTimeZone] = defaultTimeZones;
        const response = await createReservationForMe({
          roomId,
          isTeamsMeeting,
          requestedServices,
          summary,
          startDate: isAllDay ? shiftTimeZone(startOfDay(new Date(startDate)), defaultTimeZone).toISOString() : startDate,
          endDate: isAllDay ? shiftTimeZone(addDays(startOfDay(new Date(startDate)), 1), defaultTimeZone).toISOString() : endDate,
          rrule,
          isAllDay,
          type: ReservationType.ROOM,
          attendees: attendees ? attendees.filter(({ id }) => !!id).map(({ id }) => id as string) : undefined,
          externalAttendees: attendees ? attendees.filter(({ id }) => !id).map(({ email }) => email) : undefined,
          timeZone: defaultTimeZones.join(";"),
          serviceOptionRequests: serviceOptionRequests
            ?.filter(({ option, description }) => option || description)
            ?.map(({ option, description, quantity, type, serviceType }) => ({
              description,
              quantity,
              type,
              serviceType,
              optionId: option?.id,
            })),
          serviceRequestSettings: serviceRequestSettings?.filter(({ value }) => !!value) as MarkRequired<ReservationServiceRequestSetting, "value">[],
        });
  
        if (isSuccessAPIResponse(response)) {
          setHasSubmitted(true);
        } else {
          toast.showToast({ message: t`There was an error while creating your reservation.`, severity: "error" });
          trackException(response.error, { endpointName: createReservationForMe.name });
        }

        clearTimeout(timeout);
        setPopoverAnchorEl(undefined);
        setLoading(false);
      }
    })();
  };

  const isSubmitDisabled = !isValidInput || (currentReservation?.recurrence && !!conflicts?.length) || conflictsAreLoading || conflictsAreFetching;
  const submitButton = (
    <LoadingButton
      disabled={isSubmitDisabled}
      loading={loading}
      loadingIndicator={<CircularProgress color="primary" size={22} />}
      onClick={handleSubmitClick}
      sx={{ bgcolor: "#fff", ":hover": { bgcolor: "#fff" } }}
      variant="text"
    >
      {t`Book meeting room`}
    </LoadingButton>
  );

  return (
    <>
      <Box display="flex" pb={4}>
        <Box
          sx={({ breakpoints }) => ({
            flex: "1 0 65%",
            maxWidth: "65%",
            [breakpoints.up("xl")]: {
              flex: "1 0 70%",
              maxWidth: "70%",
            },
          })}
        >
          <Box mb={2}>
            <ReservationsBreadcrumbs />
          </Box>
          {children}
        </Box>
        <Box
          sx={({ breakpoints }) => ({
            flex: "1 0 35%",
            maxWidth: "35%",
            paddingLeft: 2,
            [breakpoints.up("xl")]: {
              flex: "1 0 30%",
              maxWidth: "30%",
              paddingLeft: 3,
            },
          })}
        >
          <Typography fontWeight="600" marginBottom={1}>{t`Reservation details`}</Typography>
          {pathname === "/reservations/locations" ? undefined : <ReservationDetails /> }
          <Box
            alignItems="center"
            bgcolor={({ palette }) => isSubmitDisabled ? lighten(palette.primary.main, 0.4) : palette.primary.main}
            borderRadius={2}
            display="flex"
            justifyContent="flex-end"
            padding={2.5}
          >
            {isSubmitDisabled ? <Tooltip title={submitTooltip}><span>{submitButton}</span></Tooltip> : submitButton}
          </Box>
        </Box>
      </Box>
      <Dialog open={hasSubmitted}>
        <Box padding={2}>
          <Typography fontSize={18} fontWeight="600" marginBottom={1}>{t`Room reservation was created successfully!`}</Typography>
          <Typography marginBottom={2}>{t`You can find all reservations details at your home page.`}</Typography>
          <Box alignItems="center" display="flex" justifyContent="flex-end">
            <Button component={Link} data-cid="new-reservation-link" sx={{ marginRight: 2 }} to="/reservations/locations?reset=true" variant="text">{t`Create other reservation`}</Button>
            <Button component={Link} data-cid="home-page-link" to="/" variant="contained">{t`Go to home page`}</Button>
          </Box>
        </Box>
      </Dialog>
      <Popover
        anchorEl={popoverAnchorEl}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        elevation={4}
        open={!!popoverAnchorEl}
        sx={{ mt: -0.5 }}
        transformOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Box padding={2} width={280}>
          <Typography color={theme.palette.grey[700]} fontSize={14} textAlign="center">
            {t`Please, wait while we send your reservation to Outlook`}
          </Typography>
        </Box>
      </Popover>
    </>
  );
};
