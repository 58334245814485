import { SvgIcon, SvgIconProps } from "@mui/material";

export const TogetherDelete: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path
        d="M10 0a1 1 0 1 1 0 2h5a1 1 0 1 1 0 2h-1l-1 10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2L2 4H1a1 1 0 1 1 0-2h5a1 1 0 0 1 0-2zm1.6 8H4.4l.6 6h6Zm.4-4H4l.2 2h7.6Z"
        fill={props.fill}
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
      />
    </SvgIcon>
  );
};
