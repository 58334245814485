import React, { useEffect, useMemo, useRef } from "react";
import { NewsFeedItemContentProps } from "./types";
import { Box, Typography, TypographyProps, useMediaQuery, useTheme } from "@mui/material";
import { Descendant, Node, Text } from "slate";
import { EditorElementType, EditorFormatMark } from "../editor";
import { Element } from "slate";
import { matchArray } from "utils";
import { TrackingEventType, useTrackMutation } from "store";
import { IFrameBox } from "../box";
import { AttachmentImage } from "components/attachments";

const textTagMapping: Record<keyof Omit<Text, "text">, React.ElementType> = {
  bold: "b",
  italic: "i",
  underline: "u",
  "line-through": "s",
};

const resolveTextTags = (text: Text): React.ElementType[] => {
  const tags: React.ElementType[] = [];

  for (const key of Object.keys(text)) {
    const leafMark = textTagMapping[key as EditorFormatMark];

    if (leafMark) {
      tags.push(leafMark);
    }
  }

  return tags;
};

const resolveHeadingVariant = (level?: number): TypographyProps["variant"] | undefined => {
  const variants: TypographyProps["variant"][] = ["h1", "h2", "h3", "h4", "h5", "h6"];

  return level ? variants[level - 1] : undefined;
};

const TextRenderer: React.FC<{ text: Text }> = (props) => {
  const { text } = props;
  const tags = resolveTextTags(text);
  let actualChildren = <>{text.text}</>;

  for (const tag of tags) {
    const textDecoration = matchArray([[tag === "u", "underline"], [tag === "s", "line-through"]]);
    const sx: TypographyProps["sx"] = {
      textDecoration,
      fontWeight: tag === "b" ? 600 : "inherit",
      fontStyle: tag === "i" ? "italic" : undefined,
    };

    actualChildren = <Typography component={tag} display="inline" fontSize="inherit" sx={sx}>{actualChildren}</Typography>;
  }

  return <Typography component="span" fontSize="inherit">{actualChildren}</Typography>;
};

const ElementRenderer: React.FC<{ postId: string; element: Element; disableTracking?: boolean }> = (props): React.ReactElement => {
  const { postId, element, disableTracking } = props;
  const { palette, breakpoints } = useTheme();
  const xl = useMediaQuery(breakpoints.up("xl"));
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [track] = useTrackMutation();

  const handleIFrameAnchorMouseDown = (event: MouseEvent) => {
    if (disableTracking) {
      return;
    }

    event.preventDefault();
    
    if (![0, 1].includes(event.button)) {
      return;
    }

    const target = event.target as HTMLAnchorElement | null;

    if (!target) {
      return;
    }

    track({ postId, type: TrackingEventType.CLICK, url: target.href });
  };

  useEffect(() => {
    if (iframeRef?.current) {
      const iframe = iframeRef.current;
      const anchors: HTMLAnchorElement[] = [];
      const loader = () => {
        const elements = iframe.contentDocument?.querySelectorAll("a");

        console.log(elements);

        for (const anchor of elements || []) {
          anchors.push(anchor);
          anchor.addEventListener("mousedown", handleIFrameAnchorMouseDown);
        }
      };

      iframe.addEventListener("load", loader);

      return () => {
        iframe.removeEventListener("load", loader);
      };
    }
  }, [iframeRef]);

  const handleAnchorMouseDown: TypographyProps<"a">["onMouseDown"] = (event) => {
    if (disableTracking) {
      return;
    }

    event.preventDefault();
    
    if (![0, 1].includes(event.button)) {
      return;
    }

    track({ postId, type: TrackingEventType.CLICK, url: event.currentTarget.href });
    open(event.currentTarget.href, "_blank");
  };

  const children = element.children?.map((node, index) => <NodeRenderer key={index} node={node} postId={postId} />);

  switch (element.type) {
    case EditorElementType.HEADING: {
      return <Typography mb={xl ? 3 : 2} textAlign={element.alignment} variant={resolveHeadingVariant(element.level)}>{children}</Typography>;
    }
    case EditorElementType.PARAGRAPH: {
      return <Typography color={palette.grey[700]} component="p" fontSize={14} mb={xl ? 2 : 1} textAlign={element.alignment}>{children}</Typography>;
    }
    case EditorElementType.LIST: {
      return <Box component={element.ordering === "ordered" ? "ol" : "ul"} mb={1}>{children}</Box>;
    }
    case EditorElementType.LIST_ITEM: {
      return <Typography color={palette.grey[700]} component="li" fontSize={14}>{children}</Typography>;
    }
    case EditorElementType.ANCHOR: {
      return (
        <Typography
          color="primary"
          component="a"
          display="inline-block"
          fontSize="inherit"
          href={element.href}
          onContextMenu={(event) => event.preventDefault()}
          onMouseDown={handleAnchorMouseDown}
          target="_blank"
        >
          {children}
        </Typography>
      );
    }
    case EditorElementType.IMAGE: {
      return element.id ? <AttachmentImage attachmentId={element.id} borderRadius={2} height="auto" mb={xl ? 2 : 1} width="100%" /> : <></>;
    }
    case EditorElementType.HTML: {
      return <IFrameBox autoHeight ref={iframeRef} srcDoc={element.body} width="100%" />;
    }
    case EditorElementType.BOX: {
      return <Box display="flex" flexWrap="nowrap" gap={2}>{children}</Box>;
    }
    case EditorElementType.BOX_CELL: {
      return <Box flex={1}>{children}</Box>;
    }
    default: {
      return <></>;
    }
  }
};

const NodeRenderer: React.FC<{ postId: string; node: Node; disableTracking?: boolean }> = (props) => {
  const { postId, node, disableTracking } = props;

  if (Text.isText(node)) {
    return <TextRenderer text={node} />;
  }

  if (Element.isElement(node)) {
    return <ElementRenderer disableTracking={disableTracking} element={node} postId={postId} />;
  }

  return <></>;
};

export const NewsFeedItemContent: React.FC<NewsFeedItemContentProps> = (props) => {
  const { content, postId, disableTracking } = props;
  const { palette } =  useTheme();
  const parsed = useMemo(() => {
    if (content) {
      try {
        return JSON.parse(content) as Descendant[];
      } catch {
        return content;
      }
    }
  }, [content]);

  if (!parsed) {
    return <></>;
  }

  return typeof parsed === "string" ? (
    <Typography color={palette.grey[100]} fontSize={14}>{parsed}</Typography>
  ) : (
    <>{parsed.map((node, index) => <NodeRenderer disableTracking={disableTracking} key={index} node={node} postId={postId} />)}</>
  );
};
