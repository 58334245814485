import capitalizeFirstLetter from 'Functions/capitalizeFirstLetter';
import format from 'date-fns/format';
import styles from './styles.module.scss';
import Text from 'components/_legacy/Text';

export default function CustomTooltip(props: any) {
  const { active } = props;

  if (active) {
    return (
      <div className={styles.container}>
        {props.payload?.map((payload: any, i: number) => {
          // Convert array of string dates to date objects
          const weekRange = payload.payload.weekRange ? payload.payload.weekRange.map((wr: any) => new Date(`${wr} 12:00`)) : [new Date(), new Date()];
          // Format it to display
          const weekRangeString = `${format(weekRange[0], 'dd')}-${format(weekRange[1], 'dd MMM, yyyy')}`;

          return (
            <div key={i}>
              {(payload.name === 'booked' && weekRange && weekRangeString) ? (
                <Text color="white" size="md">
                  {weekRangeString}
                </Text>
              ) : null}

              <Text>
                <Text
                  color="white"
                  inline
                  size="md"
                  weight="bold"
                >
                  {payload.value}
                </Text>{' '}

                <Text
                  color="white"
                  inline
                  size="md"
                >
                  {capitalizeFirstLetter(payload.name)}
                </Text>
              </Text>
            </div>
          );
        })}
      </div>
    );
  }

  return null;
}