import { useDispatch } from "react-redux";
import {useState} from "react";
import { useTypedSelector } from 'store/_legacy/Redux/store';
import { setReservationsData } from '../../../Store/reservations';
import StatusFilter from "./StatusFilter";
import LocationsFilter from "./LocationsFilter";
import DocStatusFilter from "./DocStatusFilter";
import {
  ReservationFiltersModel,
  checkingType,
  filterStatusType,
  locationIdsType,
} from "../../../Store/reservations/models";
import { UserDocumentStatus } from "../../../Store/userManagement/models";
import CheckinFilter from "./CheckinFilter";
import FilterModal from "components/_legacy/Filters/FilterModal";

function getFilterCount(filters: ReservationFiltersModel) {
  let count = 0;

  if (filters.statuses && filters.statuses.length) {
    count++;
  }

  if (filters.locationIds && filters.locationIds.length) {
    count++;
  }

  if (filters.documentStatuses && filters.documentStatuses.length) {
    count++;
  }

  if (filters.checking !== undefined) {
    count++;
  }

  return count;
}

export default function ReservationFilters() {
  const dispatch = useDispatch();
  const { filters: reduxFilters, reservationPageType } = useTypedSelector(state => state.adminReservations);
  const initialFiltersState: { [key in filterStatusType]: boolean } = {
    BOOKED: false,
    PENDING: false,
    CANCELED: false,
    PAST: false,
  };
  const initialDocStatusFiltersState: { [key in UserDocumentStatus]: boolean } = {
    approved: false,
    denied: false,
    needsApproval: false,
    expired: false,
    notUploaded: false,
  };
  const initialCheckingFiltersState: { [key in checkingType]: boolean } = {
    yes: false,
    no: false,
  };
  const [filters, setFilters] = useState<{ [key in filterStatusType]: boolean }>(initialFiltersState);
  const [docStatusFilters, setDocStatusFilters] = useState<{ [key in UserDocumentStatus]: boolean }>(initialDocStatusFiltersState);
  const [checkingFilters, setCheckingFilters] = useState<{ [key in checkingType]: boolean }>(initialCheckingFiltersState);
  const [locationIds, setLocationIds] = useState<locationIdsType>([]);
  const [locationSearchText, setLocationSearchText] = useState('');
  const filterCount = getFilterCount(reduxFilters);

  const setSavedData = () => {
    const newStatusState = {...initialFiltersState};
    const newDocStatusState = {...initialDocStatusFiltersState};
    const newCheckingState = {...initialCheckingFiltersState};

    reduxFilters.statuses?.map(status => {
      newStatusState[status] = true;
    });
    reduxFilters.documentStatuses?.map(status => {
      newDocStatusState[status] = true;
    });
    if (reduxFilters.checking !== undefined) {
      reduxFilters.checking ? newCheckingState.yes = true : newCheckingState.no = true;
    }

    setFilters(newStatusState); // set saved state from redux
    setDocStatusFilters(newDocStatusState); // set saved state from redux
    setLocationIds(reduxFilters.locationIds); // set saved state from redux
    setLocationSearchText(''); // clear text input
    setCheckingFilters(newCheckingState); // set saved state from redux
  };

  const getCheckinValue = () => {
    const checkinArray: checkingType[] = checkingFilters && Object.keys(checkingFilters).map(key => key as checkingType).filter(key => checkingFilters[key as checkingType]);
    if (checkinArray?.length === 1) {
      return checkinArray[0] === 'yes'; // return true if approved and false if notApproved
    }

    return undefined;
  };

  const onSubmitFilters = () => {
    const filtersArray = Object.keys(filters).map((key: any) => key).filter((key: filterStatusType) => filters[key]);
    const docStatusFiltersArray = Object.keys(docStatusFilters).map((key: any) => key).filter((key: UserDocumentStatus) => docStatusFilters[key]);
    const checkinValue = getCheckinValue();

    dispatch(setReservationsData({
      filters: {
        ...reduxFilters,
        statuses: filtersArray,
        documentStatuses: docStatusFiltersArray,
        checking: checkinValue,
        locationIds,
      },
    }));
  };

  const onResetFilters = () => {
    setFilters(initialFiltersState);
    setDocStatusFilters(initialDocStatusFiltersState);
    setCheckingFilters(initialCheckingFiltersState);
    setLocationIds([]);
    dispatch(setReservationsData({
      filters: {
        ...reduxFilters,
        statuses: [],
        documentStatuses: [],
        checking: undefined,
        locationIds: [],
      },
    }));
  };

  return (
    <div data-testid="reservation-filters">
      <FilterModal
        filterCount={filterCount}
        onResetFilters={onResetFilters}
        onSubmitFilters={onSubmitFilters}
        setSavedData={setSavedData}
      >
        <LocationsFilter locationIds={locationIds} locationSearchText={locationSearchText} setLocationIds={setLocationIds} setLocationSearchText={setLocationSearchText} />
        {
          reservationPageType === 'list' &&
          <>
            <StatusFilter filters={filters} initialFiltersState={initialFiltersState} setFilters={setFilters} />
            <DocStatusFilter filters={docStatusFilters} initialFiltersState={initialDocStatusFiltersState} setFilters={setDocStatusFilters} />
            <CheckinFilter filters={checkingFilters} initialFiltersState={initialCheckingFiltersState} setFilters={setCheckingFilters} />
          </>
        }
      </FilterModal>
    </div>
  );
}
