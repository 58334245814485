import { FloorMapObject, MapDrawingGroup } from "../../../../../../Store/floorMapDuck/models";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { updateMeeting } from "../../../../../../Store/floorMapDuck";
import Box from "components/_legacy/Box";
import styles from "../styles.module.scss";
import Text from "components/_legacy/Text";
import { Trans } from "@lingui/macro";
import MeetingSearching from "../../../MapDrawing/MapDrawingMeetings/CreateMeeting/MeetingSearching";

interface Props {
  meeting: FloorMapObject;
}

export default function MeetingDetailsEmail(props: Props) {
  const dispatch = useDispatch();
  const { meeting } = props;
  const { id, name, roomId } = meeting;

  const initialMeetingGroup: MapDrawingGroup = {id: roomId || '', name};
  const [meetingData, setMeetingData] = useState<MapDrawingGroup>(initialMeetingGroup);
  const [meetingDataError, setMeetingDataError] = useState(false);

  const checkEmailValidation = (roomId: string) => {
    dispatch(updateMeeting({
      meetingId: typeof id === "string" ? parseInt(id) : id,
      meeting: {
        hasError: !roomId?.length,
      },
    }));
  };

  /**
   * Update desk name on redux state
   */
  const handleUpdateMeetingEmail = (meetingData: MapDrawingGroup) => {
    checkEmailValidation(meetingData?.id);

    if (!meetingData?.id) {
      dispatch(updateMeeting({
        meetingId: typeof id === "string" ? parseInt(id) : id,
        meeting: {
          roomId: undefined,
        },
      }));
      setMeetingDataError(true);
      return;
    }

    if (meetingData?.id && roomId !== meetingData?.id) {
      dispatch(updateMeeting({
        meetingId: typeof id === "string" ? parseInt(id) : id,
        meeting: {
          roomId: meetingData.id,
        },
      }));
    }
    setMeetingDataError(false);
    return;
  };

  // Sync desk name on redux and on state
  useEffect(() => {
    setMeetingData(initialMeetingGroup);
    checkEmailValidation(initialMeetingGroup.id);
  }, [roomId]);

  return (
    <Box className={styles.detailsItem}>
      <Text color="gray" size="md">
        <Trans>Room:</Trans>
      </Text>

      <MeetingSearching
        error={meetingDataError}
        meetingData={meetingData}
        setMeetingData={handleUpdateMeetingEmail}
      />
    </Box>
  );
}
