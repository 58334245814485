import { Element } from "slate";

export const matchesEditorElement = (element: Partial<Element>, target: Element): boolean => {
  const keys = Object.keys(element) as (keyof Element)[];
  let matches = true;

  for (const key of keys) {
    matches = matches && element[key] === target[key];

    if (!matches) {
      return false;
    }
  }

  return matches;
};
