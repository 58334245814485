import { resolvePersistedDate } from "store/_legacy/Utils";
import { GetAvailableDesksRequest } from "../Bookings/availableDesksDuck/models";
import { WeeklySlot } from "../Bookings/createNewBookingDuck/models";
import moment from "moment";

export const weeklySlotsToWeeklyBooking = (weeklySlots?: WeeklySlot[]): GetAvailableDesksRequest["weekly"] => {
  if (!weeklySlots?.length) {
    return undefined;
  }

  return weeklySlots
    .filter(({ isSelected, timeFrom, timeTo }) => (isSelected && timeFrom && timeTo))
    .map(({ day: weekDay, timeFrom, timeTo }) => {
      const timeFromDate = resolvePersistedDate(timeFrom);
      const timeToDate = resolvePersistedDate(timeTo);

      return {
        weekDay,
        timeFrom: moment(timeFromDate).format("HH:mm"),
        timeTo: moment(timeToDate).format("HH:mm"),
        selected: true,
      };
    });
};