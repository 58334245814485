import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import {useTypedSelector} from "store/_legacy/Redux/store";
import Box from "components/_legacy/Box";
import {Trans} from "@lingui/macro";
import Button from "components/_legacy/Button";
import { resetChanges, selectHasChanges, selectHasErrors } from "Admin/Store/floorMapDuck";
import {useState} from "react";
import {transformDeskSections} from "Admin/Pages/FloorMap/Helpers";
import {saveMapSectionsAndDesks} from "Admin/Store/floorMapApiRequestsDuck";
import AddFloorConfirmationPopup from "./ConfirmationPopup";
import TurnedOffNotification from "./TurnedOffNotification";

export default function AddFloorDataUpdate() {
  const dispatch = useDispatch();
  const { adminFloorMapApiRequests, adminFloorMap, adminAttributes } = useTypedSelector(state => state);
  const { floorId } = useParams<{ floorId: string; locationId: string; }>();
  const [popupOpened, setPopupOpened] = useState(false);

  const { desks, sections, meetings } = adminFloorMap;
  const actionLoaded = adminFloorMapApiRequests.loading && !adminFloorMapApiRequests.mapSectionSaved;
  const hasChanges = selectHasChanges(adminFloorMap);
  const hasErrors = selectHasErrors(adminFloorMap);
  const isEnableSave = hasChanges && !hasErrors && !actionLoaded;

  const onClosePopup = () => {
    setPopupOpened(false);
  };

  const onOpenPopup = () => {
    setPopupOpened(true);
  };

  const onSaveChanges = () => {
    // If there're changes in desks or sections, update them
    if (isEnableSave) {
      onClosePopup();

      const { saveMapDesksArray, saveMapSectionsArray, saveMapMeetingsArray } = transformDeskSections({
        desks,
        sections,
        meetings,
        attributesDesk: adminAttributes.attributesDesk,
        attributesRoom: adminAttributes.attributesRoom,
      });
      dispatch(saveMapSectionsAndDesks({
        floorId,
        data: {
          desks: saveMapDesksArray,
          sections: saveMapSectionsArray,
          rooms: saveMapMeetingsArray,
        },
      }));
    }
  };

  const onResetChanges = () => {
    onClosePopup();
    dispatch(resetChanges());
  };

  return (
    <>
      <TurnedOffNotification />
      <Button
        data-testid="floor-map-details-header-reset"
        disabled={(!hasChanges || actionLoaded)}
        onClick={onOpenPopup}
        size="sm"
        type="clear"
        withShadow={false}
      >
        <Trans>
          Reset
        </Trans>
      </Button>

      <Box>
        <Button
          data-testid="floor-map-details-header-save"
          disabled={!isEnableSave}
          onClick={onSaveChanges}
          size="sm"
        >
          <Trans>
            Save Changes
          </Trans>
        </Button>
      </Box>
      
      <AddFloorConfirmationPopup
        onApplyChanges={onSaveChanges}
        onClose={onClosePopup}
        onResetChanges={onResetChanges}
        open={popupOpened}
      />
    </>
  );
}
