import { parseArrayQueryParam, parsePaginationQueryParams } from "store/apis/utils";
import { Paginated } from "../../types";
import { commApi } from "../comm-api";
import { GetPostTemplateByIdInput, GetPostTemplatesInput, PostTemplate } from "../types";
import { CommApiTag } from "../enums";

const postsTemplates = commApi.injectEndpoints({
  endpoints: (builder) => ({
    createPostTemplate: builder.mutation<PostTemplate, string>({
      query: (postId) => ({ body: { postId }, url: "/api/v1/posts/templates", method: "POST" }),
      invalidatesTags: [CommApiTag.POST_TEMPLATE],
    }),
    getPostTemplates: builder.query<Paginated<PostTemplate>, GetPostTemplatesInput>({
      query: (params) => ({ url: "/api/v1/posts/templates", params: parsePaginationQueryParams(params) }),
      providesTags: [CommApiTag.POST_TEMPLATE],
    }),
    getPostTemplateById: builder.query<PostTemplate, GetPostTemplateByIdInput>({
      query: ({ postTemplateId, include }) => ({ url: `/api/v1/posts/templates/${postTemplateId}`, params: parseArrayQueryParam("include", include) }),
      providesTags: [CommApiTag.POST_TEMPLATE],
    }),
    deletePostTemplateById: builder.mutation<void, string>({
      query: (postId) => ({ url: `/api/v1/posts/templates/${postId}`, method: "DELETE" }),
      invalidatesTags: [CommApiTag.POST_TEMPLATE],
    }),
  }),
});

export const {
  useCreatePostTemplateMutation,
  useGetPostTemplatesQuery,
  useLazyGetPostTemplatesQuery,
  useGetPostTemplateByIdQuery,
  useLazyGetPostTemplateByIdQuery,
  useDeletePostTemplateByIdMutation,
} = postsTemplates;
