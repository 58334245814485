import Box from 'components/_legacy/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import clsx from 'clsx';
import React, { createContext, useContext } from 'react';
import styles from './styles.module.scss';
import TextComponent from 'components/_legacy/Text';

interface Props {
  active?: boolean;
  onClick: () => void;
}

interface ContextProps {
  active?: boolean;
}

const DashboardItemContext = createContext<ContextProps>({ active: false });

const useDashboardItemContext = () => {
  const context = useContext(DashboardItemContext);

  if (!context) {
    throw new Error('Child components of DashboardItem cannot be rendered outside the DashboardItem component!');
  }

  return context;
};

function Icon({ children }: React.PropsWithChildren<any>) {
  useDashboardItemContext();

  return (
    <Box alignItems="center" display="flex" justifyContent="center">
      {children}
    </Box>
  );
}

function Text({ children }: React.PropsWithChildren<any>) {
  const { active } = useDashboardItemContext();

  const classes = clsx(active && styles.colorPrimary);

  return (
    <TextComponent className={classes} size="md">
      {children}
    </TextComponent>
  );
}

function Value({ children }: React.PropsWithChildren<any>) {
  const { active } = useDashboardItemContext();

  const classes = clsx(styles.value, active && styles.colorPrimary);

  return (
    <TextComponent className={classes} weight="semi-bold">
      {children}
    </TextComponent>
  );
}

function Top({ children }: React.PropsWithChildren<any>) {
  useDashboardItemContext();

  return (
    <Box className={styles.dashboardItemTop}>
      {children}
    </Box>
  );
}

function DashboardItem({ active, children, onClick }: React.PropsWithChildren<Props>) {
  const classes = clsx(styles.dashboardItemContainer, active && styles.active);

  return (
    <DashboardItemContext.Provider value={{ active }}>
      <ButtonBase
        className={classes}
        classes={{ root: styles.dashboardItemContainerRoot }}
        disabled
        onClick={onClick}
      >
        {children}
      </ButtonBase>
    </DashboardItemContext.Provider>
  );
}

DashboardItem.Icon = Icon;
DashboardItem.Text = Text;
DashboardItem.Value = Value;
DashboardItem.Top = Top;

export default DashboardItem;
