import { useDispatch } from "react-redux";
import { Trans } from "@lingui/macro";
import { updateMeeting } from "../../../../../../Store/floorMapDuck";
import { FloorMapObject } from "../../../../../../Store/floorMapDuck/models";
import { Box } from "@material-ui/core";
import Text from "components/_legacy/Text";
import HtmlEditor from "components/_legacy/HtmlEditor";
import { useDebouncedCallback } from "hooks";

interface Props {
  meeting: FloorMapObject;
}

export default function MeetingDetailsDescription(props: Props) {
  const dispatch = useDispatch();
  const { meeting } = props;
  const { id, description } = meeting;

  const handleDescriptionChange = useDebouncedCallback((description: string) => {
    dispatch(updateMeeting({
      meetingId: typeof id === "string" ? parseInt(id) : id,
      meeting: { description },
    }));
  }, [dispatch, id], 200);

  return (
    <Box pt={1}>
      <Box mb={1}>
        <Text color="gray" size="md">
          <Trans>Room description:</Trans>
        </Text>
      </Box>
      <HtmlEditor
        initialText={description || ""}
        onChangeText={handleDescriptionChange}
      />
    </Box>
  );
}
