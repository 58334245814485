import Box from "components/_legacy/Box";
import styles from "./styles.module.scss";
import Heading from "components/_legacy/Heading";
import {Trans, t} from "@lingui/macro";
import clsx from "clsx";
import {Link} from "react-router-dom";
import Text from "components/_legacy/Text";

export default function SettingsHeader() {
  const NAVIGATION_ITEMS = [
    // {
    //   name: t`Color Schemes`,
    //   to: `/admin/settings/color-schemes`,
    // },
    {
      name: t`Mobile`,
      to: `/admin/settings/mobile`,
    },
  ];

  return (
    <>
      <Box className={styles.header} dataTestId="settings-header">
        <Heading size="sm">
          <Trans>Settings</Trans>
        </Heading>

        <nav aria-label={t`Settings Navigation Items`} className={styles.nav}>
          <ul data-testid={`settings-submenu`}>
            {NAVIGATION_ITEMS.map((item, index) => {
              const isSelected = item.to === location.pathname;
              const classes = clsx(isSelected && styles.isSelected);

              return (
                <li className={classes} data-testid={`settings-submenu-${item.name}`} key={item.name + index}>
                  <Link to={item.to}>
                    <Text color={isSelected ? 'blue' : 'black'} size="md" weight="semi-bold">
                      {item.name}
                    </Text>
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </Box>
    </>
  );
}