import { useState } from "react";
import { useTypedSelector } from "store/_legacy/Redux/store";
import format from "date-fns/format";
import { Trans } from "@lingui/macro";
import styles from "../../../UserManagement/styles.module.scss";

import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ButtonBase from "@material-ui/core/ButtonBase";
import OverflowToolTip from "components/_legacy/OverflowTooltip";
import Box from "components/_legacy/Box";
import Text from "components/_legacy/Text";
import UserDocumentDisplay from "../../../UserManagement/AllUsersTable/AllUsersTableBody/UserDocumentDisplay";
import UserAccommodation from "../../../UserManagement/AllUsersTable/AllUsersTableBody/UserAccommodation";
import { UserManagementUser } from "../../../../Store/userManagement/models";
import TableSubmenuButton from "components/_legacy/Buttons/TableSubmenuButton";
import DeleteGroupUserModal from "./DeleteGroupUserModal";

interface Props {
  user: UserManagementUser;
  index: number;
  isAdGroup: boolean;
}

export default function GroupUserTableBody(props: Props) {
  const { config } = useTypedSelector(state => state);
  const { user, index, isAdGroup } = props;
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  // delete modal
  const onOpenDeleteModal = () => {
    setIsOpenDeleteModal(o => !o);
  };

  const onCloseDeleteModal = () => {
    setIsOpenDeleteModal(false);
  };

  // TODO: [IPG-1096] - Validate logic of what documents to show
  const firstDocument = user.userDocument || undefined;

  return (
    <>
      <TableRow
        classes={{ root: styles.tableRow }}
        hover
        key={index}
        tabIndex={-1}
      >
        <TableCell classes={{ root: styles.tableCell }}>
          <OverflowToolTip text={user.name} />
        </TableCell>

        <TableCell classes={{ root: styles.tableCell }}>
          <OverflowToolTip text={user.email} />
        </TableCell>

        <TableCell classes={{ root: styles.tableCell }}>
          {firstDocument ? <UserDocumentDisplay document={firstDocument} user={user} /> : '-'}
        </TableCell>

        <TableCell classes={{ root: styles.tableCell }}>
          <UserAccommodation approvedAccommodation={user.approvedAccommodation} userId={user.id} />
        </TableCell>

        <TableCell classes={{ root: styles.tableCell }}>
          {firstDocument && firstDocument.createdAt ? format(new Date(firstDocument.createdAt), 'MM/dd/yyyy') : '-'}
        </TableCell>

        {!isAdGroup ? (
          <TableCell align="right" classes={{ root: styles.tableCell }}>
            <TableSubmenuButton
              iconButtonName={"group-item-options"}
              iconButtonStyle={{ padding: 0, width: '30px', height: '30px' }}
            >
              {
                !isOpenDeleteModal ?
                  <Box borderStyle="shadow" className="popover-content" dataTestId="group-item-options-popup">
                    <ButtonBase className="popover-item-button" onClick={onOpenDeleteModal}>
                      <DeleteOutlineIcon style={{ color: config.theme.primary, fontSize: 20 }} />

                      <Box marginStart={5}>
                        <Text color="blue" size="md">
                          <Trans>Delete User</Trans>
                        </Text>
                      </Box>
                    </ButtonBase>
                  </Box> : null
              }
            </TableSubmenuButton>
          </TableCell>
        ) : undefined}
      </TableRow>

      <DeleteGroupUserModal
        onCancelAction={onCloseDeleteModal}
        open={isOpenDeleteModal}
        user={user}
      />
    </>
  );
}