import React, { useState } from "react";
import { EditorToolbarButton } from "./editor-toolbar-button";
import { VerticalSplitRounded } from "@mui/icons-material";
import { ButtonProps } from "@mui/material";
import { useSlate } from "slate-react";
import { isEditorElementActive } from "./utils";
import { EditorElementType } from "./enums";
import { t } from "@lingui/macro";
import { Editor, Element, Transforms } from "slate";

export const EditorBoxButton: React.FC = () => {
  const editor = useSlate();
  const [href, setHref] = useState("");
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isActive = isEditorElementActive(editor, { type: EditorElementType.BOX });
  
  const handleClick: ButtonProps["onClick"] = (event) => {
    event.preventDefault();

    const { selection } = editor;

    if (!selection) {
      return;
    }

    if (isActive) {
      const [[, path]] = Editor.nodes(
        editor,
        { match: (node) => !Editor.isEditor(node) && Element.isElement(node) && node.type === EditorElementType.BOX },
      );
      
      Transforms.unwrapNodes(editor, { at: [...path, 1] });
      Transforms.unwrapNodes(editor, { at: [...path, 0] });
      Transforms.unwrapNodes(editor, { at: path });

      return;
    }

    Transforms.wrapNodes(editor, { type: EditorElementType.BOX_CELL, children: [] });
     
    const [[, path]] = Editor.nodes(
      editor,
      { match: (node) => !Editor.isEditor(node) && Element.isElement(node) && node.type === EditorElementType.BOX_CELL },
    );

    Transforms.wrapNodes(editor, { type: EditorElementType.BOX, children: [] }, { at: path });
    Transforms.insertNodes(
      editor,
      { type: EditorElementType.BOX_CELL, children: [{ type: EditorElementType.PARAGRAPH, placeholder: t`Type here`, children: [{ text: "" }] }] },
      { at: [...path, 1] },
    );
  };

  return (
    <EditorToolbarButton
      Icon={VerticalSplitRounded}
      active={isActive}
      onClick={handleClick}
      title={isActive ? t`One column layout` : t`Two colum layout`}
    />
  );
};
