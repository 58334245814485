import { t } from "@lingui/macro";
import { AddCircleOutline, CheckCircleOutline, MoreVert, NotInterestedOutlined, SendOutlined } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Divider, Grid, IconButton, IconButtonProps, List, ListItem, ListItemButton, Pagination, Popover, Skeleton, Switch, Typography, useMediaQuery, useTheme } from "@mui/material";
import { CampaignsWrapper, ConfirmationDialog, IconBox, ListHeader, ListHeaderLabel, MonthlyCalendar, TogetherDateAndTime, TogetherDelete, useToast } from "components";
import { endOfDay, format, isSameDay, isSameMonth, startOfDay } from "date-fns";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Post, PostNotificationChannel, PostScheduleType, PostStatus, adminSlice, selectAdminCampaignsQuery, useApprovePostByIdMutation, useCreatePostTemplateMutation, useDeletePostByIdMutation, useDisapprovePostByIdMutation, useLazyGetPendingPostsQuery, useLazyGetPostsQuery, useUpdatePostByIdMutation } from "store";

export const CampaignsPendingList: React.FC = () => {
  const { palette, breakpoints } = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const xl = useMediaQuery(breakpoints.up("xl"));
  const toast = useToast();
  const query = useSelector(selectAdminCampaignsQuery);
  const [page, setPage] = useState(1);
  const [post, setPost] = useState<Post>();
  const [willDelete, setWillDelete] = useState(false);
  const [willApprove, setWillApprove] = useState(false);
  const [willDisapprove, setWillDisapprove] = useState(false);
  const [moreAnchorEl, setMoreAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [triggerGetPendingPostsQuery, getPendingPostsQuery] = useLazyGetPendingPostsQuery();
  const [deletePostById] = useDeletePostByIdMutation();
  const [approvePostById] = useApprovePostByIdMutation();
  const [disapprovePostById] = useDisapprovePostByIdMutation();
  const [createPostTemplate] = useCreatePostTemplateMutation();

  useEffect(() => {
    const filter: Record<string, string> = {};
    
    if (query?.range) {
      const range = query?.range?.map((value) => new Date(value)) || [new Date()];
      const startDate = startOfDay(range[0]).toISOString();
      const endDate = endOfDay(range.length === 1 ? range[0] : range[1]).toISOString(); 

      filter["schedules.type"] = PostScheduleType.PUBLISH;
      filter["schedules.runAt"] = `$btw:${startDate.toString()},${endDate.toString()}`;
    }

    triggerGetPendingPostsQuery({
      page,
      filter,
      limit: 15,
      search: query?.search,
      include: ["schedules", "$extra.audienceCount", "$extra.testCount"],
      orderBy: ["desc:schedules.doneAt", "desc:createdAt"],
    }, true);
  }, [page, JSON.stringify(query)]);

  const handleRangeChange = (value?: Date[]) => {
    dispatch(adminSlice.actions.setCampaignsQuery({ range: value?.map((value) => value.toISOString()) }));
  };

  const getMoreClickHandler: (post: Post) => IconButtonProps["onClick"] = (post) => (event) => {
    event.preventDefault();
    event.stopPropagation();

    setPost(post);
    setMoreAnchorEl(event.currentTarget);
  };

  const deletePost = async (post?: Post): Promise<void> => {
    setWillDelete(false);
    setMoreAnchorEl(null);
    setPost(undefined);

    if (!post) {
      return;
    }

    const response = await deletePostById(post.id);

    if ("error" in response) {
      toast.showToast({ severity: "error", message: t`Failed to delete post, please try again.` });
    } else {
      toast.showToast({ severity: "success", message: t`Post was deleted.` });
    }
  };

  const approvePost = async (post?: Post): Promise<void> => {
    setWillApprove(false);
    setMoreAnchorEl(null);
    setPost(undefined);

    if (!post) {
      return;
    }

    const response = await approvePostById(post.id);

    if ("error" in response) {
      toast.showToast({ severity: "error", message: t`Failed to approve post, please try again.` });
    } else {
      toast.showToast({ severity: "success", message: t`Post was approved.` });
    }

    setMoreAnchorEl(null);
  };

  const disapprovePost = async (post?: Post): Promise<void> => {
    setWillDisapprove(false);
    setMoreAnchorEl(null);
    setPost(undefined);

    if (!post) {
      return;
    }

    const response = await disapprovePostById(post.id);

    if ("error" in response) {
      toast.showToast({ severity: "error", message: t`Failed to disapprove post, please try again.` });
    } else {
      toast.showToast({ severity: "success", message: t`Post was disapproved.` });
    }

    setMoreAnchorEl(null);
  };

  const addToTemplates = async (postId: string): Promise<void> => {
    const response = await createPostTemplate(postId);

    if ("error" in response) {
      toast.showToast({ severity: "error", message: t`Failed to add post to templates, please try again.` });
    } else {
      toast.showToast({ severity: "success", message: t`Post was added to templates.` });
    }

    setMoreAnchorEl(null);
  };

  const width = {
    audience: xl ? 86 : 76,
    sendDate: xl ? 100 : 90,
    notifications: xl ? 90 : 80,
    approver: xl ? 76 : 76,
    testInternally: xl ? 102 : 92,
  };
  const fontSize = { header: xl ? 13 : 12, body: xl ? 14 : 12 };
  const range = query?.range ? query.range.map((date) => new Date(date)) : undefined;
  const { data: getPostsResponse, isLoading } = getPendingPostsQuery;
  const { items: posts, meta } = getPostsResponse || {};

  return (
    <>
      <CampaignsWrapper>
        <Grid columns={20} container width="100%">
          <Grid alignItems="stretch" display="flex" flexDirection="column" item xl={15} xs={14}>
            <ListHeader>
              <ListHeaderLabel sx={{ flex: 1 }}>{t`Name`}</ListHeaderLabel>
              <ListHeaderLabel sx={{ width: width.audience }}>{t`Audience`}</ListHeaderLabel>
              <ListHeaderLabel sx={{ width: width.sendDate }}>{t`Send date`}</ListHeaderLabel>
              <ListHeaderLabel sx={{ width: width.notifications }}>{t`Notifications`}</ListHeaderLabel>
              <ListHeaderLabel sx={{ width: width.approver }}>{t`Approver`}</ListHeaderLabel>
              <ListHeaderLabel sx={{ width: width.testInternally }}>{t`Test internally`}</ListHeaderLabel>
              <Box component="span" width={30} />
            </ListHeader>
            {isLoading ? (
              <Box mb={2} minHeight={xl ? 690 : 645}>
                <Skeleton height={45} sx={{ borderRadius: 0 }} variant="rectangular" />
                <Divider sx={{ opacity: 0.6 }} />
                <Skeleton height={45} sx={{ borderRadius: 0 }} variant="rectangular" />
                <Divider sx={{ opacity: 0.6 }} />
                <Skeleton height={45} sx={{ borderRadius: 0 }} variant="rectangular" />
                <Divider sx={{ opacity: 0.6 }} />
                <Skeleton height={45} sx={{ borderRadius: 0 }} variant="rectangular" />
                <Divider sx={{ opacity: 0.6 }} />
                <Skeleton height={45} sx={{ borderRadius: 0 }} variant="rectangular" />
              </Box>
            ) : posts?.length ? (
              <List disablePadding sx={{ mb: 2 }}>
                {posts?.map((post) => {
                  const audience = post.$extra?.audienceCount || 0;
                  const schedule = post.schedules?.find(({ type }) => type === PostScheduleType.PUBLISH);
                  const sendDate = schedule ? format(new Date(schedule.doneAt || schedule.runAt), "LLL, d, HH:mm") : "-";
                  const notifications = post.notifies?.length 
                    ? post.notifies.map((channel) => channel === PostNotificationChannel.EMAIL ? t`Email` : t`App`).join(", ") 
                    : "-";
                  const test = post.$extra?.testCount || 0;

                  return (
                    <ListItem disablePadding divider key={post.id} sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.05)" }}>
                      <ListItemButton onClick={() => history.push(`/admin/campaigns/${post.id}/edit`)} sx={{ px: 1, py: 1.5, gap: 1, flex: 1 }}>
                        <Typography flex={1} fontSize={fontSize.body} noWrap title={post.title}>{post.title}</Typography>
                        <Typography fontSize={fontSize.body} noWrap width={width.audience}>{audience}</Typography>
                        <Typography fontSize={fontSize.body} noWrap title={sendDate} width={width.sendDate}>{sendDate}</Typography>
                        <Typography fontSize={fontSize.body} noWrap title={notifications} width={width.notifications}>{notifications}</Typography>
                        <Typography fontSize={fontSize.body} noWrap title="-" width={width.approver}>-</Typography>
                        <Box display="flex" width={width.testInternally}>
                          <Switch checked={test > 0} disabled size="small" />
                        </Box>
                        <IconButton color="primary" onClick={getMoreClickHandler(post)} size="small">
                          <MoreVert color={"primary"} fontSize="small" />
                        </IconButton>
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            ) : (
              <Typography color={palette.grey[700]} fontSize={14} my={4} textAlign="center">
                {t`No campaigns to be approved.`}
              </Typography>
            )}
            {(meta?.pages || 0) > 1 ? (
              <Pagination count={meta?.pages} onChange={(_, page) => setPage(page)} page={page} shape="rounded" />
            ) : undefined}
          </Grid>
          <Grid item pl={xl ? 4 : 2} xl={5} xs={6}>
            <Box display="flex" justifyContent="space-between">
              <Box alignItems="center" display="flex" gap={1}>
                <IconBox>
                  <TogetherDateAndTime fill={palette.grey[700]} sx={{ width: 16 }} />
                </IconBox>
                <Typography color={!range ? palette.grey[700] : undefined} fontSize={16} fontWeight={600} lineHeight={1}>
                  {range ? format(range[0], "LLL, d") : ""}
                  {range?.length === 2 && !isSameDay(range[0], range[1]) && isSameMonth(range[0], range[1]) ? "-" + format(range[1], "d") : ""}
                  {range?.length === 2 && !isSameDay(range[0], range[1]) && !isSameMonth(range[0], range[1]) ? " - " + format(range[1], "LLL, d") : ""}
                  {!range ? t`Not selected` : undefined}
                </Typography>
              </Box>
              <Box display="flex">
                <Button onClick={() => handleRangeChange(undefined)} size="small" sx={{ color: palette.grey[700] }} variant="text">{t`Reset`}</Button>
                <Button onClick={() => handleRangeChange([new Date()])} size="small" variant="text">{t`Today`}</Button>
              </Box>
            </Box>
            <Divider sx={{ my: 2 }} />
            <MonthlyCalendar  onChange={handleRangeChange} selectionType="range" value={range} width="auto" />
          </Grid>
        </Grid>
      </CampaignsWrapper>
      <Popover
        anchorEl={moreAnchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={() => setMoreAnchorEl(null)}
        open={!!moreAnchorEl}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <ButtonGroup
          orientation="vertical"
          sx={{ 
            "& .MuiButton-root": { justifyContent: "flex-start" },
            "& .MuiButtonGroup-grouped:not(:last-of-type)": { border: "none" },
          }}
          variant="text"
        >
          <Button onClick={post ? () => setWillApprove(true) : undefined} sx={{ gap: 1 }}>
            <CheckCircleOutline fontSize="small" />
            <Typography fontSize={14}>{t`Approve Campaign`}</Typography>
          </Button>
          <Button onClick={post ? () => setWillDisapprove(true) : undefined} sx={{ gap: 1 }}>
            <NotInterestedOutlined fontSize="small" />
            <Typography fontSize={14}>{t`Disapprove Campaign`}</Typography>
          </Button>
          <Button onClick={post ? () => void addToTemplates(post.id) : undefined} sx={{ gap: 1 }}>
            <AddCircleOutline fontSize="small" />
            <Typography fontSize={14}>{t`Add to Templates`}</Typography>
          </Button>
          <Button onClick={post ? () => setWillDelete(true) : undefined} sx={{ gap: 1 }}>
            <TogetherDelete sx={{ width: 20, height: 20 }} />
            <Typography fontSize={14}>{t`Delete Campaign`}</Typography>
          </Button>
        </ButtonGroup>
      </Popover>
      <ConfirmationDialog
        description={t`Are you sure you want to delete "${post?.title}"?`}
        onClose={() => setWillDelete(false)}
        onConfirm={() => void deletePost(post)}
        open={!!willDelete}
        title={t`Delete post`}
      />
      <ConfirmationDialog
        description={t`Are you sure you want to approve "${post?.title}"?`}
        onClose={() => setWillApprove(false)}
        onConfirm={() => void approvePost(post)}
        open={!!willApprove}
        title={t`Approve post`}
      />
      <ConfirmationDialog
        description={t`Are you sure you want to disapprove "${post?.title}"?`}
        onClose={() => setWillDisapprove(false)}
        onConfirm={() => void disapprovePost(post)}
        open={!!willDisapprove}
        title={t`Disapprove post`}
      />
    </>
  );
};
