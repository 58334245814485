import Box from 'components/_legacy/Box';
import Text from 'components/_legacy/Text';
import moment, { Moment } from 'moment';
import { MutableRefObject } from 'react';
import { Item } from '../../../Helpers';
import styles from './styles.module.scss';

interface Props {    
  day: Moment;
  itemSelected: MutableRefObject<Item | undefined>;
}

export default function Day({ day, itemSelected }: Props) {  
  const dayClass = (day: Moment) => {
    if (itemSelected && itemSelected.current && itemSelected.current.day?.isSame(day, 'day')) return styles.selectedDay;
    if (moment().isSame(day, 'day')) return styles.currentDay;    
  };
  
  return (   
    <Box className={styles.day} gap={2}>
      <Text className={styles.headerText}>
        {day.format('ddd')}
      </Text>
      <Text className={dayClass(day)}>
        {day.format('D')}
      </Text>
    </Box>     
  );
}
