import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import L from "leaflet";
import { useTypedSelector } from "store/_legacy/Redux/store";
import { FeatureGroup, useMapEvents } from "react-leaflet";
import { addSection, setDrawDeskState, setDrawSectionState } from "../../../../../Store/floorMapDuck";
import { addDeskToPosition } from "../helpers";
import { DrawState, FloorMapObjectTypeEnum } from "../../../../../Store/floorMapDuck/models";
import CreateSection from "./CreateSection";
import { EditControl } from "react-leaflet-draw";
import SectionObject from "./SectionObject";
import { DeskStatusEnum } from "../../../../../Store/floorMapDuck/desk/models";

export default function MapDrawingSections() {
  const dispatch = useDispatch();
  const { floorId } = useParams<{ floorId: string; locationId: string }>();
  const [center, setCenter] = useState<L.LatLngExpression>([0, 0]);
  const [zoomLevel, setZoomLevel] = useState<number>(1); // variable to handle zoom event
  const [drawSectionPopupOpen, setDrawSectionPopupOpen] = useState(false);
  const [isDrawingSection, setIsDrawingSection] = useState(false);
  const [sectionName, setSectionName] = useState('');
  const { adminFloorMap } = useTypedSelector(state => state);

  const {
    drawingStates,
    sections,
    showSections,
  } = adminFloorMap;
  const { drawDesk, drawSection } = drawingStates;

  // Transform the objects with sections into arrays | do now show deleted desk with active reservations
  const sectionsArray = Object.keys(sections).map(key => sections[parseInt(key)]);

  // Drawing section is handled by redux and internally in this component. This effect syncs both when necessary
  useEffect(() => {
    if (!drawSection.active) {
      setIsDrawingSection(false);
    }
  }, [drawSection.active]);

  const map = useMapEvents({
    'zoom': (e) => {
      setZoomLevel(e.target._zoom);
    },
    'click': (e) => {
      const mapElement = document.querySelector('#mapid > div');
      const isClickOnEmptyMapArea = e.originalEvent.target === mapElement;

      if (isClickOnEmptyMapArea) {
        const clickPosition = e.latlng;

        setCenter(clickPosition);

        if (drawSection.active && !isDrawingSection) {
          // If there's no drawing active, toggle popup opened
          setDrawSectionPopupOpen(!drawSectionPopupOpen);
        }
      }
    },
  });

  // Updates redux to draw desk (this action also inactivates draw section)
  function setDrawDesk(drawDesk: DrawState) {
    dispatch(setDrawDeskState(drawDesk));
  }

  // Updates redux to draw section (this action also inactivates draw desk)
  function setDrawSection(drawSection: DrawState) {
    dispatch(setDrawSectionState(drawSection));
  }

  /**
   * React Leaflet Draw component onCreated function.
   * This function executes everytime a drawing is done.
   * For some reason, this function lives in a scope that doesn't have access to the correct external
   * React states when it's running, so we have to access the data that we pass to the draw layer
   * on the options.attribution property as a JSON string.
   * Also, some workarounds are necessary to sync everyhing, like a setTimeout to tell Leaflet Draw
   * that there is nothing being drawn.
   */
  function onCreated(e: any) {
    const { attribution, color } = e.layer.options;
    const { _latlngs, _leaflet_id } = e.layer;
    // There's not a proper way to pass data to a draw, so we use this property with a JSON string as a workaround
    const drawOptions = JSON.parse(attribution);

    const { sectionName, type } = drawOptions;

    const coordinatesInLatLngBounds: L.LatLng[] = _latlngs.length ? _latlngs[0] : [];
    const coordinatesInPoints = coordinatesInLatLngBounds.map(coordinate => L.CRS.Simple.latLngToPoint(coordinate, 1));

    // The layer is removed because the drawings are handled by the redux inside <Map />
    map.removeLayer(e.layer);

    if (type === FloorMapObjectTypeEnum.Section) {
      if (_latlngs && _leaflet_id) {
        // Add section to redux
        dispatch(addSection({
          coordinatesInLatLngBounds,
          coordinatesInPoints,
          fill: color,
          latlngs: _latlngs,
          line: color,
          id: _leaflet_id,
          name: sectionName,
          owners: [],
          groups: [],
          provisory: true,
          hasError: false,
          resourceIds: {},
          saved: false,
          status: DeskStatusEnum.Available,
          type: FloorMapObjectTypeEnum.Section,
        }));
      }

      setDrawSection({ active: false });

      // This timeout is necessary to prevent leaflet draw from triggering actions before changes on internal states
      setTimeout(() => {
        setIsDrawingSection(false);
        setSectionName('');
      }, 1000);
    }
  }

  return (
    <>
      <CreateSection
        center={center}
        popupOpened={drawSectionPopupOpen}
        sectionName={sectionName}
        setCenter={setCenter}
        setDrawSectionActive={() => {
          setDrawSection({ active: true });
          setIsDrawingSection(true);
        }}
        setPopupOpened={setDrawSectionPopupOpen}
        setSectionName={setSectionName}
      />

      <FeatureGroup>
        <EditControl
          draw={{
            circle: false,
            circlemarker: false,
            marker: false,
            polygon: false,
            polyline: false,
            rectangle: true,
          }}
          onCreated={onCreated}
          position="topright"
        />
      </FeatureGroup>

      {showSections &&
        <>
          {sectionsArray.map((section) => {
            return (
              <FeatureGroup key={`${section.id}`}>
                <SectionObject
                  addDeskToPosition={addDeskToPosition}
                  drawDesk={drawDesk}
                  editable
                  floorId={floorId}
                  section={section}
                  setDrawDesk={setDrawDesk}
                  zoomLevel={zoomLevel}
                />
              </FeatureGroup>
            );
          })}
        </>
      }
    </>
  );
}
