import { Editor, Element } from "slate";

export const getActiveEditorElement = (editor: Editor): Element | undefined => {
  const [node] = Editor.nodes(editor, { match: (node) => !Editor.isEditor(node) && Element.isElement(node), mode: "lowest" });

  if (!node) {
    return undefined;
  }

  const [element] = node;

  return element as Element;
};
