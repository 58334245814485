import {useDispatch} from "react-redux";
import {useTypedSelector} from "store/_legacy/Redux/store";
import moment from "moment";
import {cancelReservationsByDesk, deleteDeskFromFloor} from "../../../../../../../Store/floorMapApiRequestsDuck";
import Box from "components/_legacy/Box";
import styles from "../styles.module.scss";
import {Trans, t} from "@lingui/macro";
import Button from "components/_legacy/Button";
import {useState} from "react";
import StartCancelReservationDate from "./StartCancelReservationDate";

interface Props {
  floorId: string;
  onCancelAction: Function; // close popup function
  onDelete?: Function; // call addition function with onDelete handler
}

export default function HasReservationContent(props: Props) {
  const dispatch = useDispatch();
  const { adminFloorMap } = useTypedSelector(state => state);
  const { floorId, onDelete = () => {}, onCancelAction } = props;
  const { deskToDelete } = adminFloorMap;
  const [startDate, setStartDate] = useState<Date | string | null>(null);

  const confirmButtonText = startDate ? t`Delete on Date` : t`Delete Immediately`;

  const onDeleteAction = () => {
    if (deskToDelete?.id) {
      const deskId = deskToDelete.id.toString();
      const dateFromDelete = startDate ? moment(startDate).format() : moment().format();
      const saveBookings = !!startDate;

      dispatch(deleteDeskFromFloor({ floorId, deskId, saveBookings })); // delete desk
      dispatch(cancelReservationsByDesk({ date: dateFromDelete, deskId })); // delete desk reservations
      onDelete(); // addition delete handler from props - used for cleaning parent component state
      onCancelAction(); // cancel popup action
    }
  };

  return (
    <>
      <Box className={styles.modalContentText} marginBottom={20}>
        <Box marginBottom={10}>There are existing reservations for this <span className={styles.modalContentTextBold}>{deskToDelete?.name}.</span></Box>
        <Box marginBottom={20}>You can delete the desk and all future reservations immediately or set a date from which the reservations will be deleted.</Box>
        <Box>
          <StartCancelReservationDate
            setStartDate={setStartDate}
            startDate={startDate}
          />
        </Box>
      </Box>

      <Box display="flex" justifyContent="end">
        <Button
          aria-label={t`cancel delete desk`}
          name={t`cancel delete desk`}
          onClick={() => onCancelAction()}
          size="sm"
          type="clear"
        >
          <Trans>Cancel</Trans>
        </Button>

        <Button
          aria-label={t`confirm delete desk`}
          name={t`confirm delete desk`}
          onClick={onDeleteAction}
          size="sm"
        >
          {confirmButtonText}
        </Button>
      </Box>
    </>
  );
}
