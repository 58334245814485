import { Editor, Element } from "slate";
import { EditorTextAlignment } from "../types";
import { matchesEditorAlignment } from "./matches-editor-alignment";
import { EditorElementType } from "../enums";

export const isEditorAlignmentActive = (editor: Editor, alignment: EditorTextAlignment): boolean => {
  const { selection } = editor;

  if (!selection) {
    return false;
  }

  const [match] = Editor.nodes(editor, {
    at: Editor.unhangRange(editor, selection),
    match: (node) => {
      return !Editor.isEditor(node)
        && Element.isElement(node)
        && (node.type === EditorElementType.HEADING || node.type === EditorElementType.PARAGRAPH)
        && matchesEditorAlignment(alignment, node.alignment);
    },
  });

  return !!match;
};
