export enum TogetherApiTag {
  BOOKING = "Together.Booking",
  FLOOR = "Together.Floor",
  GROUP = "Together.Group",
  LOCATION = "Together.Location",
  RESERVATION = "Together.Reservation",
  ROOM = "Together.Room",
  ROOM_SCHEDULE = "Together.Room.Schedule",
  USER_SAVED_DESK = "Together.User.Saved.Desk",
  USER_HOME_COUNTS = "Together.User.Counts.Home",
  RESERVATION_SERVICE_OPTION = "Together.Reservation.Service.Option",
}
