import {
  GetFloorSchemaResponse,
  SaveMapFileResponse,
} from './models';
import { FloorMapObjectTypeEnum } from "../floorMapDuck/models";
import { DeskStatusEnum } from "../floorMapDuck/desk/models";

export const getFloorSchemaSuccess: GetFloorSchemaResponse = {
  result: {
    statusCode: 200,
    data: {
      id: '4b273c65-65c2-43c2-b72b-7a5d47661ef8',
      floorName: 'My Floor 3',
      floorId: 'd0d37cdf-7be2-4a55-9643-6305bde441ee',
      mapImageBounds: [[0, 100], [100, 0]],
      size: {
        width: '1500',
        height: '907',
      },
      drawings: [
        {
          fill: '#1E1F7B',
          line: '#1E1F7B',
          name: 'teste',
          type: FloorMapObjectTypeEnum.Section,
          owners: [],
          groups: [],
          deskId: 'ffca888c-b0e8-4a80-9811-00b693538b55',
          status: DeskStatusEnum.Available,
          sectionId: 79,
          coordinates: [
            {
              x: 321.76624320140087,
              y: 190.02303858229698,
            },
            {
              x: 376.94944938093084,
              y: 191.3694763210799,
            },
            {
              x: 374.70622961753537,
              y: 348.9026917586825,
            },
            {
              x: 316.38251576925154,
              y: 345.3121911219281,
            },
          ],
          resourceIds: [
            '4c1d248e-87bf-4a4c-827c-029e7b312b89',
            'effdbbfa-e007-4a68-a2f3-78adc5d02fff',
            'b9d2e7c5-86be-48f5-a699-63b6d6fa5c83',
            '8b1622cb-6cf5-43a5-811d-305862971c73',
          ],
        },
        {
          fill: '#1E1F7B',
          line: '#1E1F7B',
          name: 'teste',
          type: FloorMapObjectTypeEnum.Section,
          owners: [],
          groups: [],
          deskId: 'a073334b-5f31-45ac-8dd3-02d294b16a45',
          status: DeskStatusEnum.Unavailable,
          sectionId: 79,
          coordinates: [
            {
              x: 321.76624320140087,
              y: 190.02303858229698,
            },
            {
              x: 376.94944938093084,
              y: 191.3694763210799,
            },
            {
              x: 374.70622961753537,
              y: 348.9026917586825,
            },
            {
              x: 316.38251576925154,
              y: 345.3121911219281,
            },
          ],
          resourceIds: [
            '4c1d248e-87bf-4a4c-827c-029e7b312b89',
            'effdbbfa-e007-4a68-a2f3-78adc5d02fff',
            'b9d2e7c5-86be-48f5-a699-63b6d6fa5c83',
            '8b1622cb-6cf5-43a5-811d-305862971c73',
          ],
        },
        {
          fill: '#1E1F7B',
          line: '#1E1F7B',
          name: 'teste',
          type: FloorMapObjectTypeEnum.Section,
          owners: [],
          groups: [],
          deskId: 'ad162d6d-dcc5-4f5e-9849-557afd3846ea',
          status: DeskStatusEnum.Available,
          sectionId: 79,
          coordinates: [
            {
              x: 321.76624320140087,
              y: 190.02303858229698,
            },
            {
              x: 376.94944938093084,
              y: 191.3694763210799,
            },
            {
              x: 374.70622961753537,
              y: 348.9026917586825,
            },
            {
              x: 316.38251576925154,
              y: 345.3121911219281,
            },
          ],
          resourceIds: [
            '4c1d248e-87bf-4a4c-827c-029e7b312b89',
            'effdbbfa-e007-4a68-a2f3-78adc5d02fff',
            'b9d2e7c5-86be-48f5-a699-63b6d6fa5c83',
            '8b1622cb-6cf5-43a5-811d-305862971c73',
          ],
        },
        {
          fill: '#1E1F7B',
          line: '#1E1F7B',
          name: 'teste',
          type: FloorMapObjectTypeEnum.Section,
          owners: [],
          groups: [],
          deskId: 'd71fa3e8-9445-4355-8d4d-4f20e063bb4f',
          status: DeskStatusEnum.ApprovalRequired,
          sectionId: 79,
          coordinates: [
            {
              x: 321.76624320140087,
              y: 190.02303858229698,
            },
            {
              x: 376.94944938093084,
              y: 191.3694763210799,
            },
            {
              x: 374.70622961753537,
              y: 348.9026917586825,
            },
            {
              x: 316.38251576925154,
              y: 345.3121911219281,
            },
          ],
          resourceIds: [
            '4c1d248e-87bf-4a4c-827c-029e7b312b89',
            'effdbbfa-e007-4a68-a2f3-78adc5d02fff',
            'b9d2e7c5-86be-48f5-a699-63b6d6fa5c83',
            '8b1622cb-6cf5-43a5-811d-305862971c73',
          ],
        },
        {
          fill: '#1E1F7B',
          line: '#1E1F7B',
          name: 'teste',
          type: FloorMapObjectTypeEnum.Section,
          owners: [],
          groups: [],
          deskId: 'c07bd1eb-116b-48e9-bef3-87b57b1584d8',
          status: DeskStatusEnum.Available,
          sectionId: 79,
          coordinates: [
            {
              x: 321.76624320140087,
              y: 190.02303858229698,
            },
            {
              x: 376.94944938093084,
              y: 191.3694763210799,
            },
            {
              x: 374.70622961753537,
              y: 348.9026917586825,
            },
            {
              x: 316.38251576925154,
              y: 345.3121911219281,
            },
          ],
          resourceIds: [
            '4c1d248e-87bf-4a4c-827c-029e7b312b89',
            'effdbbfa-e007-4a68-a2f3-78adc5d02fff',
            'b9d2e7c5-86be-48f5-a699-63b6d6fa5c83',
            '8b1622cb-6cf5-43a5-811d-305862971c73',
          ],
        },
        {
          fill: '#1E1F7B',
          line: '#1E1F7B',
          name: 'teste',
          type: FloorMapObjectTypeEnum.Section,
          owners: [],
          groups: [],
          deskId: 'cb391e9b-4d36-469a-9ff8-91bf1b5ca950',
          status: DeskStatusEnum.Available,
          sectionId: 79,
          coordinates: [
            {
              x: 321.76624320140087,
              y: 190.02303858229698,
            },
            {
              x: 376.94944938093084,
              y: 191.3694763210799,
            },
            {
              x: 374.70622961753537,
              y: 348.9026917586825,
            },
            {
              x: 316.38251576925154,
              y: 345.3121911219281,
            },
          ],
          resourceIds: [
            '4c1d248e-87bf-4a4c-827c-029e7b312b89',
            'effdbbfa-e007-4a68-a2f3-78adc5d02fff',
            'b9d2e7c5-86be-48f5-a699-63b6d6fa5c83',
            '8b1622cb-6cf5-43a5-811d-305862971c73',
          ],
        },
        {
          fill: '#5BC535',
          line: '#5BC535',
          name: 'Desk-1',
          type: FloorMapObjectTypeEnum.Desk,
          owners: [],
          groups: [],
          deskId: '88357989-0253-4d83-9028-345e96b02c93',
          sectionId: 79,
          status: DeskStatusEnum.Available,
          coordinates: [
            {
              x: 456.08016554950746,
              y: 208.78125,
            },
            {
              x: 476.08016554950746,
              y: 208.78125,
            },
            {
              x: 476.08016554950746,
              y: 228.78125,
            },
            {
              x: 456.08016554950746,
              y: 228.78125,
            },
          ],
          resourceIds: [
            '4c1d248e-87bf-4a4c-827c-029e7b312b89',
            'effdbbfa-e007-4a68-a2f3-78adc5d02fff',
            '8b1622cb-6cf5-43a5-811d-305862971c73',
            'b9d2e7c5-86be-48f5-a699-63b6d6fa5c83',
          ],
        },
        {
          fill: '#5BC535',
          line: '#5BC535',
          name: 'Desk-2',
          type: FloorMapObjectTypeEnum.Desk,
          owners: [],
          groups: [],
          deskId: 'b583b3ea-0e78-4bf4-9315-5c2d578b9ee6',
          status: DeskStatusEnum.Available,
          sectionId: 79,
          coordinates: [
            {
              x: 321.9946842675934,
              y: 205.34375583697997,
            },
            {
              x: 341.9946842675934,
              y: 205.34375583697997,
            },
            {
              x: 341.9946842675934,
              y: 225.34375583697997,
            },
            {
              x: 321.9946842675934,
              y: 225.34375583697997,
            },
          ],
          resourceIds: [
            '4c1d248e-87bf-4a4c-827c-029e7b312b89',
            'effdbbfa-e007-4a68-a2f3-78adc5d02fff',
            '8b1622cb-6cf5-43a5-811d-305862971c73',
            'b9d2e7c5-86be-48f5-a699-63b6d6fa5c83',
          ],
        },
        {
          fill: '#5BC535',
          line: '#5BC535',
          name: 'Desk-3',
          type: FloorMapObjectTypeEnum.Desk,
          owners: [],
          groups: [],
          deskId: '67915251-c7b5-4140-aef1-142ce9d2c7c7',
          status: DeskStatusEnum.Unavailable,
          sectionId: 79,
          coordinates: [
            {
              x: 354.56217556412827,
              y: 224.0672310026756,
            },
            {
              x: 354.56217556412827,
              y: 204.0672310026756,
            },
            {
              x: 374.56217556412827,
              y: 204.0672310026756,
            },
            {
              x: 374.56217556412827,
              y: 224.0672310026756,
            },
          ],
          resourceIds: [
            '4c1d248e-87bf-4a4c-827c-029e7b312b89',
            'effdbbfa-e007-4a68-a2f3-78adc5d02fff',
            '8b1622cb-6cf5-43a5-811d-305862971c73',
            'b9d2e7c5-86be-48f5-a699-63b6d6fa5c83',
          ],
        },
        {
          fill: '#5BC535',
          line: '#5BC535',
          name: 'Desk-4',
          type: FloorMapObjectTypeEnum.Desk,
          owners: [],
          groups: [],
          deskId: 'fbf3cabb-cafa-4c70-9991-f047a898e81e',
          status: DeskStatusEnum.Available,
          sectionId: 79,
          coordinates: [
            {
              x: 358.39364512842644,
              y: 230.23599010591442,
            },
            {
              x: 378.39364512842644,
              y: 230.23599010591442,
            },
            {
              x: 378.39364512842644,
              y: 250.23599010591442,
            },
            {
              x: 358.39364512842644,
              y: 250.23599010591442,
            },
          ],
          resourceIds: [
            '4c1d248e-87bf-4a4c-827c-029e7b312b89',
            'effdbbfa-e007-4a68-a2f3-78adc5d02fff',
            '8b1622cb-6cf5-43a5-811d-305862971c73',
            'b9d2e7c5-86be-48f5-a699-63b6d6fa5c83',
          ],
        },
        {
          fill: '#5BC535',
          line: '#5BC535',
          name: 'Desk-5',
          type: FloorMapObjectTypeEnum.Desk,
          owners: [],
          groups: [],
          deskId: '10ffbda1-3c33-4990-9fe8-6a22364046e3',
          status: DeskStatusEnum.ApprovalRequired,
          sectionId: 79,
          coordinates: [
            {
              x: 320.07894948544435,
              y: 230.23599010591442,
            },
            {
              x: 340.07894948544435,
              y: 230.23599010591442,
            },
            {
              x: 340.07894948544435,
              y: 250.23599010591442,
            },
            {
              x: 320.07894948544435,
              y: 250.23599010591442,
            },
          ],
          resourceIds: [
            '4c1d248e-87bf-4a4c-827c-029e7b312b89',
            'effdbbfa-e007-4a68-a2f3-78adc5d02fff',
            '8b1622cb-6cf5-43a5-811d-305862971c73',
            'b9d2e7c5-86be-48f5-a699-63b6d6fa5c83',
          ],
        },
        {
          fill: '#5BC535',
          line: '#5BC535',
          name: 'Desk-6',
          type: FloorMapObjectTypeEnum.Desk,
          owners: [],
          groups: [],
          deskId: '67bb6e42-59c2-40b4-b221-53240f271fc0',
          status: DeskStatusEnum.Available,
          sectionId: 79,
          coordinates: [
            {
              x: 316.8860581818625,
              y: 253.85169954054453,
            },
            {
              x: 336.8860581818625,
              y: 253.85169954054453,
            },
            {
              x: 336.8860581818625,
              y: 273.85169954054453,
            },
            {
              x: 316.8860581818625,
              y: 273.85169954054453,
            },
          ],
          resourceIds: [
            '4c1d248e-87bf-4a4c-827c-029e7b312b89',
            'effdbbfa-e007-4a68-a2f3-78adc5d02fff',
            '8b1622cb-6cf5-43a5-811d-305862971c73',
            'b9d2e7c5-86be-48f5-a699-63b6d6fa5c83',
          ],
        },
        {
          fill: '#5BC535',
          line: '#5BC535',
          name: 'Desk-7',
          type: FloorMapObjectTypeEnum.Desk,
          owners: [],
          groups: [],
          deskId: '2154bb36-ad0b-458f-86f4-c2bf5f773253',
          status: DeskStatusEnum.Available,
          sectionId: 79,
          coordinates: [
            {
              x: 354.56217556412827,
              y: 251.29864987193588,
            },
            {
              x: 374.56217556412827,
              y: 251.29864987193588,
            },
            {
              x: 374.56217556412827,
              y: 271.2986498719359,
            },
            {
              x: 354.56217556412827,
              y: 271.2986498719359,
            },
          ],
          resourceIds: [
            '4c1d248e-87bf-4a4c-827c-029e7b312b89',
            'effdbbfa-e007-4a68-a2f3-78adc5d02fff',
            '8b1622cb-6cf5-43a5-811d-305862971c73',
            'b9d2e7c5-86be-48f5-a699-63b6d6fa5c83',
          ],
        },
        {
          fill: '#5BC535',
          line: '#5BC535',
          name: 'Desk-8',
          type: FloorMapObjectTypeEnum.Desk,
          owners: [],
          groups: [],
          deskId: 'd7f38e94-66e5-434b-873a-fb23cfd6b05c',
          status: DeskStatusEnum.Available,
          sectionId: 79,
          coordinates: [
            {
              x: 354.56217556412827,
              y: 279.3821962266312,
            },
            {
              x: 374.56217556412827,
              y: 279.3821962266312,
            },
            {
              x: 374.56217556412827,
              y: 299.3821962266312,
            },
            {
              x: 354.56217556412827,
              y: 299.3821962266312,
            },
          ],
          resourceIds: [
            '4c1d248e-87bf-4a4c-827c-029e7b312b89',
            'effdbbfa-e007-4a68-a2f3-78adc5d02fff',
            '8b1622cb-6cf5-43a5-811d-305862971c73',
            'b9d2e7c5-86be-48f5-a699-63b6d6fa5c83',
          ],
        },
        {
          fill: '#5BC535',
          line: '#5BC535',
          name: 'Desk-9',
          type: FloorMapObjectTypeEnum.Desk,
          owners: [],
          groups: [],
          deskId: '6ef16ef2-dba1-4c9a-b511-c2ef7029708b',
          status: DeskStatusEnum.Available,
          sectionId: 79,
          coordinates: [
            {
              x: 320.07894948544435,
              y: 276.19088414087037,
            },
            {
              x: 340.07894948544435,
              y: 276.19088414087037,
            },
            {
              x: 340.07894948544435,
              y: 296.19088414087037,
            },
            {
              x: 320.07894948544435,
              y: 296.19088414087037,
            },
          ],
          resourceIds: [
            '4c1d248e-87bf-4a4c-827c-029e7b312b89',
            'effdbbfa-e007-4a68-a2f3-78adc5d02fff',
            '8b1622cb-6cf5-43a5-811d-305862971c73',
            'b9d2e7c5-86be-48f5-a699-63b6d6fa5c83',
          ],
        },
        {
          fill: '#5BC535',
          line: '#5BC535',
          name: 'Desk-10',
          type: FloorMapObjectTypeEnum.Desk,
          owners: [],
          groups: [],
          deskId: '5bbd8756-5bae-450a-beb3-d089ef390762',
          status: DeskStatusEnum.Available,
          sectionId: 79,
          coordinates: [
            {
              x: 317.52463644257887,
              y: 298.53006874119615,
            },
            {
              x: 337.52463644257887,
              y: 298.53006874119615,
            },
            {
              x: 337.52463644257887,
              y: 318.53006874119615,
            },
            {
              x: 317.52463644257887,
              y: 318.53006874119615,
            },
          ],
          resourceIds: [
            '4c1d248e-87bf-4a4c-827c-029e7b312b89',
            'effdbbfa-e007-4a68-a2f3-78adc5d02fff',
            '8b1622cb-6cf5-43a5-811d-305862971c73',
            'b9d2e7c5-86be-48f5-a699-63b6d6fa5c83',
          ],
        },
        {
          fill: '#5BC535',
          line: '#5BC535',
          name: 'Desk-11',
          type: FloorMapObjectTypeEnum.Desk,
          owners: [],
          groups: [],
          deskId: '09b78fd6-05f3-4195-bd06-ed61c268b4c3',
          status: DeskStatusEnum.Available,
          sectionId: 79,
          coordinates: [
            {
              x: 356.4779103462773,
              y: 299.16833115834834,
            },
            {
              x: 376.4779103462773,
              y: 299.16833115834834,
            },
            {
              x: 376.4779103462773,
              y: 319.16833115834834,
            },
            {
              x: 356.4779103462773,
              y: 319.16833115834834,
            },
          ],
          resourceIds: [
            '4c1d248e-87bf-4a4c-827c-029e7b312b89',
            'effdbbfa-e007-4a68-a2f3-78adc5d02fff',
            '8b1622cb-6cf5-43a5-811d-305862971c73',
            'b9d2e7c5-86be-48f5-a699-63b6d6fa5c83',
          ],
        },
        {
          fill: '#5BC535',
          line: '#5BC535',
          name: 'Desk-12',
          type: FloorMapObjectTypeEnum.Desk,
          owners: [],
          groups: [],
          deskId: '5a36c1a2-3725-405c-bd78-44669f71ecb1',
          status: DeskStatusEnum.Available,
          sectionId: 79,
          coordinates: [
            {
              x: 355.83933208556107,
              y: 316.4014164214568,
            },
            {
              x: 375.83933208556107,
              y: 316.4014164214568,
            },
            {
              x: 375.83933208556107,
              y: 336.4014164214568,
            },
            {
              x: 355.83933208556107,
              y: 336.4014164214568,
            },
          ],
          resourceIds: [
            '4c1d248e-87bf-4a4c-827c-029e7b312b89',
            'effdbbfa-e007-4a68-a2f3-78adc5d02fff',
            '8b1622cb-6cf5-43a5-811d-305862971c73',
            'b9d2e7c5-86be-48f5-a699-63b6d6fa5c83',
          ],
        },
        {
          fill: '#5BC535',
          line: '#5BC535',
          name: 'Desk-13',
          type: FloorMapObjectTypeEnum.Desk,
          owners: [],
          groups: [],
          deskId: 'a06d7749-fea1-48ab-b43c-9f3405d136dd',
          status: DeskStatusEnum.Available,
          sectionId: 79,
          coordinates: [
            {
              x: 309.2231190532661,
              y: 322.78404059297844,
            },
            {
              x: 329.2231190532661,
              y: 322.78404059297844,
            },
            {
              x: 329.2231190532661,
              y: 342.78404059297844,
            },
            {
              x: 309.2231190532661,
              y: 342.78404059297844,
            },
          ],
          resourceIds: [
            '4c1d248e-87bf-4a4c-827c-029e7b312b89',
            'effdbbfa-e007-4a68-a2f3-78adc5d02fff',
            '8b1622cb-6cf5-43a5-811d-305862971c73',
            'b9d2e7c5-86be-48f5-a699-63b6d6fa5c83',
          ],
        },
      ],
      mapUrl: '/api/locations/d0d37cdf-7be2-4a55-9643-6305bde441ee/floor-map',
    },
  },
  error: null,
};

export const saveMapFileSuccess: SaveMapFileResponse = {
  result: {
    statusCode: 201,
    data: {
      id: 'c6f267f9-82b1-46b9-b8a7-40ada908bc0b',
      floorName: 'My Floor 7',
      locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
    },
  },
  error: null,
};
