import React, { useState } from "react";
import { ReservationDetailsDialogProps } from "./types";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Dialog, Divider, List, ListItem, ListItemButton, Popover, Skeleton, Typography, darken, useTheme } from "@mui/material";
import { IconBox } from "../box";
import { TogetherCatering, TogetherFloor, TogetherRoom, TogetherUser } from "../icons";
import { t } from "@lingui/macro";
import { Link } from "react-router-dom";
import { BasicChip } from "../chip";
import { KeyboardArrowDown, LocationOnOutlined } from "@mui/icons-material";
import { formatInTimeZone } from "date-fns-tz";
import { useToast } from "../toast-provider";
import { Attendee, ReservationServiceOptionType, ReservationServiceType, isSuccessAPIResponse, parseResponseError, useCancelMyReservationMutation, useCancelReservationEntryMutation, useGetUserReservationByIdQuery } from "store";
import { useDelegatedId, useExceptionTracker } from "hooks";
import { resolveRRuleRecurrenceLabel } from "utils";
import { union } from "underscore";
import { ConfirmationDialog } from "../dialog";
import { ReservationCateringDetails } from "./reservation-catering-details";

export const ReservationDetailsDialog: React.FC<ReservationDetailsDialogProps> = (props) => {
  const { reservationId, entryId, open, modifiable, entryModifiable, onClose } = props;
  const { palette } = useTheme();
  const toast = useToast();
  const trackException = useExceptionTracker();
  const delegatedId = useDelegatedId();
  const [cancelPopoverAnchor, setCancelPopoverAnchor] = useState<HTMLButtonElement | null>(null);
  const [editPopoverAnchor, setEditPopoverAnchor] = useState<HTMLButtonElement | null>(null);
  const { data: getUserReservationByIdQuery, isLoading } = useGetUserReservationByIdQuery({
    reservationId,
    userId: delegatedId,
    include: [
      "attendees",
      "floor",
      "floor.location",
      "room",
      "schedule",
      "schedule.entries",
      "serviceOptionRequests",
      "serviceOptionRequests.option",
      "serviceRequestSettings",
      "schedule.entries.serviceOptionRequests",
      "schedule.entries.serviceOptionRequests.option",
      "schedule.entries.serviceRequestSettings",
    ],
  }, { skip: !open });
  const [cancelMyReservation] = useCancelMyReservationMutation();
  const [cancelReservationEntry] = useCancelReservationEntryMutation();

  const handleCancelClick = () => {
    onClose?.();
    
    void (async () => {
      const response = await cancelMyReservation(reservationId);

      if (isSuccessAPIResponse(response)) {
        toast.showToast({ severity: "success", message: t`Your reservation was canceled` });
      } else {
        const error = parseResponseError(response);

        if (error?.code === 1_001_015) {
          toast.showToast({ severity: "warning", message: t`This reservation is still being processed, please try again later.` });
        } else {
          toast.showToast({ severity: "error", message: t`There was an error while canceling your reservation` });
          trackException(error, { endpointName: cancelMyReservation.name });
        }
      }
    })();
  };

  const handleCancelEntryClick = () => {
    if (entryId) {
      onClose?.();
    
      void (async () => {
        const response = await cancelReservationEntry({ reservationId, entryId });

        if (isSuccessAPIResponse(response)) {
          toast.showToast({ severity: "success", message: t`Your meeting occurrence was canceled` });
        } else {
          toast.showToast({ severity: "error", message: t`There was an error while canceling your meeting occurrence` });
        }
      })();
    }
  };

  const { data: reservation } = getUserReservationByIdQuery?.result || {};
  const { schedule, summary, floor, room } = reservation || {};
  const timeZone = schedule?.timeZone?.split(";")?.[0] || "UTC";
  const entry = entryId ? schedule?.entries?.find(({ id }) => id === entryId) : undefined;
  const formattedDateTime = entry || schedule ? [
    formatInTimeZone(entry ? new Date(entry.startDate) : new Date(schedule?.startDate || 0), timeZone, "MMM d, h:mma"),
    formatInTimeZone(entry ? new Date(entry.endDate) : new Date(schedule?.endDate || 0), timeZone, "h:mma OOOO"),
  ].join(" - ") : "";
  const recurrenceLabel = resolveRRuleRecurrenceLabel(schedule?.rrule);
  const title = `${t`Meeting`} - ${summary}${recurrenceLabel ? ` (${recurrenceLabel})` : ""}`;
  const attendees = union<Attendee>(reservation?.attendees || [], reservation?.externalAttendees?.map((email) => ({ email })) || []);
  const requestedServices = entry?.requestedServices || reservation?.requestedServices;
  const serviceOptionRequests = entry?.requestedServices ? entry?.serviceOptionRequests : reservation?.serviceOptionRequests;
  const serviceRequestSettings = entry?.requestedServices ? entry?.serviceRequestSettings : reservation?.serviceRequestSettings;
  const additionalTags = requestedServices?.map((key) => ({
    [ReservationServiceType.CATERING]: t`I need catering`,
    [ReservationServiceType.IT_SUPPORT]: t`I need IT support`,
  })[key]);
  const isRecurrent = !!schedule?.rrule;

  return (
    <Dialog onClose={onClose} open={!!open}>
      <Box p={2} width={480}>
        {isLoading ? (
          <>
            <Box alignItems="center" display="flex" justifyContent="space-between">
              <Box alignItems="center" display="flex">
                <Skeleton height={30} sx={{ borderRadius: 2 }} variant="rectangular" width={30} />
                <Skeleton sx={{ ml: 1 }} width={200} />
              </Box>
              <Skeleton width={100} />
            </Box>
            <Divider sx={{ mt: 1, mb: 2 }} />
            <Skeleton sx={{ mb: 1 }} width={120} />
            <Box display="flex" mb={1}>
              <Skeleton height={29} sx={{ borderRadius: 2 }} variant="rectangular" width={100} />
              <Skeleton height={29} sx={{ borderRadius: 2, ml: 2 }} variant="rectangular" width={160} />
            </Box>
            <Skeleton sx={{ mb: 2 }} width={320} />
            <Skeleton sx={{ mb: 1 }} width={120} />
            <Box display="flex" mb={1}>
              <Skeleton height={29} sx={{ borderRadius: 2 }} variant="rectangular" width={120} />
              <Skeleton height={29} sx={{ borderRadius: 2, ml: 2 }} variant="rectangular" width={180} />
            </Box>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <Box alignItems="center" display="flex" mb={1}>
              <Skeleton height={30} sx={{ borderRadius: 2 }} variant="rectangular" width={30} />
              <Skeleton sx={{ borderRadius: 2, fontSize: 16, ml: 2 }} width={120} />
            </Box>
            <Box alignItems="center" display="flex" mb={1}>
              <Skeleton height={30} sx={{ borderRadius: 2 }} variant="rectangular" width={30} />
              <Box ml={2}>
                <Skeleton sx={{ borderRadius: 2, fontSize: 14 }} width={120} />
                <Skeleton sx={{ borderRadius: 2, fontSize: 12 }} width={180} />
              </Box>
            </Box>
            <Box alignItems="center" display="flex" mb={2}>
              <Skeleton height={30} sx={{ borderRadius: 2 }} variant="rectangular" width={30} />
              <Box ml={2}>
                <Skeleton sx={{ borderRadius: 2, fontSize: 14 }} width={120} />
                <Skeleton sx={{ borderRadius: 2, fontSize: 12 }} width={180} />
              </Box>
            </Box>
            <Skeleton height={74} sx={{ borderRadius: 2 }} variant="rectangular" width="100%" />
          </>
        ) : (
          <>
            <Box alignItems="center" display="flex" justifyContent="space-between">
              <Box alignItems="center" display="flex">
                <IconBox><TogetherRoom stroke={palette.grey[700]} sx={{ height: 14, width: 14 }}/></IconBox>
                <Typography fontWeight="600" maxWidth={240} ml={1} noWrap title={title}>{title}</Typography>
              </Box>
              {modifiable || entryModifiable ? (
                isRecurrent ? (
                  <>
                    <Button onClick={(event) => setEditPopoverAnchor(event.currentTarget)}>{t`Edit meeting`}</Button>
                    <Popover
                      anchorEl={editPopoverAnchor}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                      onClose={() => setEditPopoverAnchor(null)}
                      open={!!editPopoverAnchor}
                      sx={{ mt: 1 }}
                      transformOrigin={{ horizontal: "right", vertical: "top" }}
                    >
                      <List disablePadding>
                        <ListItem disablePadding>
                          <ListItemButton
                            component={Link}
                            data-cid="edit-occurrence-link"
                            disabled={!entryModifiable}
                            divider
                            to={`/reservations/${reservationId}/entries/${entryId}/edit`}
                          >
                            <Typography fontSize={14} my={0.5}>{t`Edit this occurrence`}</Typography>
                          </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                          <ListItemButton component={Link} data-cid="edit-all-occurrences-link" disabled={!modifiable} to={`/reservations/${reservationId}/edit`}>
                            <Typography fontSize={14} my={0.5}>{t`Edit all occurrences`}</Typography>
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </Popover>
                  </>
                ) : (
                  <Button component={Link} data-cid="edit-reservation-link" to={`/reservations/${reservationId}/edit`}>{t`Edit meeting`}</Button>
                )
              ) : undefined}
            </Box>
            <Divider sx={{ mt: 1, mb: 2 }} />
            <Box mb={2}>
              <Typography fontWeight="600" mb={1}>{t`Details`}</Typography>
              <Box display="flex" mb={1}>
                <BasicChip>
                  <TogetherFloor fill={palette.grey[700]} sx={{ height: 14, width: 14 }} />
                  <Typography color={palette.grey[700]} fontSize={14} ml={1}>{floor?.name}</Typography>
                </BasicChip>
                <BasicChip ml={2}>
                  <TogetherRoom stroke={palette.grey[700]} sx={{ height: 14, width: 14 }} />
                  <Typography color={palette.grey[700]} fontSize={14} ml={1}>{room?.name}</Typography>
                </BasicChip>
              </Box>
              <Box display="flex">
                <LocationOnOutlined fontSize="small" sx={{ color: palette.grey[700] }} />
                <Typography color={palette.grey[700]} fontSize={14} ml={1}>{floor?.location?.name}. {floor?.location?.address}</Typography>
              </Box>
            </Box>
            {additionalTags?.length ? (
              <Box mb={2}>
                <Typography fontWeight="600" mb={1}>{t`Additional`}</Typography>
                <Box display="flex" mb={1}>
                  {additionalTags.map((tag, index) => (
                    <BasicChip key={tag} ml={index > 0 ? 1 : 0}>
                      <Typography color={palette.grey[700]} fontSize={14}>{tag}</Typography>
                    </BasicChip>
                  ))}
                </Box>
              </Box>
            ) : undefined}
            {attendees?.length ? (
              <Box mb={1}>
                <Divider sx={{ mb: 1 }} />
                <Accordion disableGutters elevation={0}>
                  <AccordionSummary
                    expandIcon={<KeyboardArrowDown color="primary" />}
                    sx={{ p: 0, minHeight: 0, "& .MuiAccordionSummary-content": { margin: 0 } }}
                  >
                    <Box alignItems="center" display="flex">
                      <IconBox mr={1}><TogetherUser fill={palette.grey[700]} sx={{ height: 14, width: 14 }}/></IconBox>
                      <Typography fontWeight="600" lineHeight={1}>{t`Attendees`} ({attendees?.length})</Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails sx={{ px: 0, pb: 0 }}>
                    <Box maxHeight={220} sx={{ overflowY: "auto", overflowX: "hidden" }}>
                      {attendees.map(({ name, email }) => (
                        <Box alignItems="center" display="flex" height={44} key={email}>
                          <Box
                            alignItems="center"
                            bgcolor={({ palette }) => !name ? palette.grey[400] : palette.primary.main}
                            borderRadius={2}
                            display="flex"
                            height={30}
                            justifyContent="center"
                            marginRight={1}
                            width={30}
                          >
                            <TogetherUser fill="#fff" sx={{ width: 14 }} />
                          </Box>
                          <Box maxWidth={280}>
                            <Typography fontSize={14} fontWeight="600" noWrap>{name || t`Invited user`}</Typography>
                            <Typography color={({ palette }) => palette.grey[700]} fontSize={12} noWrap>{email}</Typography>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
            ) : undefined}
            {requestedServices?.includes(ReservationServiceType.CATERING) && (serviceOptionRequests?.length || serviceRequestSettings?.length) ? (
              <Box>
                <Divider sx={{ mb: 1 }} />
                <Accordion disableGutters elevation={0}>
                  <AccordionSummary
                    expandIcon={<KeyboardArrowDown color="primary" />}
                    sx={{ p: 0, minHeight: 0, "& .MuiAccordionSummary-content": { margin: 0 } }}
                  >
                    <Box alignItems="center" display="flex">
                      <IconBox mr={1}><TogetherCatering fill={palette.grey[700]} sx={{ width: 14, height: 14 }} /></IconBox>
                      <Typography fontWeight="600" lineHeight={1}>{t`Catering`} ({serviceOptionRequests?.length})</Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails sx={{ px: 0 }}>
                    <Box maxHeight={220} sx={{ overflowY: "auto", overflowX: "hidden" }}>
                      <ReservationCateringDetails
                        requests={serviceOptionRequests}
                        settings={serviceRequestSettings}
                        type={ReservationServiceOptionType.BEVERAGE}
                      />
                      <ReservationCateringDetails
                        requests={serviceOptionRequests}
                        settings={serviceRequestSettings}
                        type={ReservationServiceOptionType.BREAKFAST}
                      />
                      <ReservationCateringDetails
                        requests={serviceOptionRequests}
                        settings={serviceRequestSettings}
                        type={ReservationServiceOptionType.SNACK}
                      />
                      <ReservationCateringDetails
                        requests={serviceOptionRequests}
                        settings={serviceRequestSettings}
                        type={ReservationServiceOptionType.LUNCH}
                      />
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
            ) : undefined}
            <Box alignItems="center" bgcolor={palette.primary.main} borderRadius={2} display="flex" justifyContent="space-between" mt={2} p={2}>
              <Box>
                <Typography color="#fff" fontSize={14}>{t`Date and time`}</Typography>
                <Typography color="#fff" fontSize={14} maxWidth={modifiable ? 240 : undefined} noWrap={modifiable} title={formattedDateTime}>
                  {formattedDateTime}
                </Typography>
              </Box>
              {modifiable || entryModifiable ? (
                isRecurrent ? (
                  <>
                    <Button onClick={(event) => setCancelPopoverAnchor(event.currentTarget)} sx={{ bgcolor: "#fff", ":hover": { bgcolor: darken("#fff", 0.06) } }}>
                      {t`Cancel meeting`}
                    </Button>
                    <Popover
                      anchorEl={cancelPopoverAnchor}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                      onClose={() => setCancelPopoverAnchor(null)}
                      open={!!cancelPopoverAnchor}
                      sx={{ mt: 1 }}
                      transformOrigin={{ horizontal: "right", vertical: "top" }}
                    >
                      <List disablePadding>
                        <ListItem disablePadding>
                          <ConfirmationDialog
                            Trigger={({ onClick }) => (
                              <ListItemButton disabled={!entryModifiable} divider onClick={onClick}>
                                <Typography fontSize={14} my={0.5}>{t`Cancel this occurrence`}</Typography>
                              </ListItemButton>
                            )}
                            description={t`Are you sure you want to cancel this meeting occurrence?`}
                            onConfirm={handleCancelEntryClick}
                            title={t`Cancel meeting occurrence`}
                          />
                        </ListItem>
                        <ListItem disablePadding>
                          <ConfirmationDialog
                            Trigger={({ onClick }) => (
                              <ListItemButton disabled={!modifiable} onClick={onClick}>
                                <Typography fontSize={14} my={0.5}>{t`Cancel all occurrences`}</Typography>
                              </ListItemButton>
                            )}
                            description={t`Are you sure you want to cancel all occurrences for this meeting room reservation?`}
                            onConfirm={handleCancelClick}
                            title={t`Cancel all meeting occurrences`}
                          />
                        </ListItem>
                      </List>
                    </Popover>
                  </>
                ) : (
                  <ConfirmationDialog
                    Trigger={({ onClick }) => (
                      <Button disabled={!modifiable} onClick={onClick} sx={{ bgcolor: "#fff", ":hover": { bgcolor: darken("#fff", 0.06) } }}>
                        {t`Cancel meeting`}
                      </Button>
                    )}
                    description={t`Are you sure you want to cancel this meeting room reservation?`}
                    onConfirm={handleCancelClick}
                    title={t`Cancel meeting`}
                  />
                )
              ) : undefined}
            </Box>
          </>
        )}
      </Box>
    </Dialog>
  );
};
