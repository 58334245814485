import { parsePaginationQueryParams } from "../../utils";
import { Paginated } from "../../types";
import { commApi } from "../comm-api";
import { GetPostSharesInput, PostShare, SavePostSharesInput } from "../types";
import { CommApiTag } from "../enums";

const postShare = commApi.injectEndpoints({
  endpoints: (builder) => ({
    savePostShares: builder.mutation<PostShare, SavePostSharesInput>({
      query: ({ postId, ...body }) => ({ body, url: `/api/v1/posts/${postId}/shares/save`, method: "POST" }),
      invalidatesTags: (_, __, { postId }) => [{ type: CommApiTag.POST_SHARE, id: postId }],
    }),
    getPostShares: builder.query<Paginated<PostShare>, GetPostSharesInput>({
      query: ({ postId, ...params }) => ({ url: `/api/v1/posts/${postId}/shares`, params: parsePaginationQueryParams(params) }),
      providesTags: (_, __, { postId }) => [{ type: CommApiTag.POST_SHARE, id: postId }],
    }),
  }),
});

export const {
  useSavePostSharesMutation,
  useGetPostSharesQuery,
  useLazyGetPostSharesQuery,
} = postShare;
