import Box from 'components/_legacy/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import clsx from 'clsx';
import Skeleton from '@material-ui/lab/Skeleton';
import styles from './styles.module.scss';
import Text from 'components/_legacy/Text';
import { Floor as FloorModel } from 'App/Store/Locations/locationsDuck/models';
import { Trans, t } from '@lingui/macro';
import Room from 'components/_legacy/Icons/Room';
import { IMeetingReservationFloor } from 'App/Store/Meetings/meetingReservations/models';

interface FloorItemProps extends FloorModel {
  onClick: (floor: IMeetingReservationFloor) => any;
  selected?: boolean;
}

/**
 * Loading state for floors.
 * Renders a few placeholders.
 */
export function FloorItemLoading() {
  const item = (
    <Box borderRadius={14} height={90}>
      <Skeleton classes={{ root: styles.skeletonRoot }} height="100%" variant="rect" />
    </Box>
  );

  return (
    <Box display="grid" gap={15}>
      {item}
      {item}
      {item}
      {item}
      {item}
    </Box>
  );
}

/**
 * Renders the floor UI as a dumb component.
 */
export default function FloorItem(props: FloorItemProps) {
  const { id,
    sitDesks: desks,
    floorName,
    extra,
    onClick,
    selected,
  } = props;
    
  const classes = clsx(styles.container, selected && styles.selected);  
  const isDisabled = extra ? extra.availableRooms === 0: desks === 0;

  const handleClick = () => {
    const meetingFloor: IMeetingReservationFloor = {
      id,
      name: floorName,      
    };

    onClick(meetingFloor);
  };

  return (
    <ButtonBase
      aria-label={t`FloorItem`}
      classes={{ root: `${styles.buttonBase} ${styles.floorItem}` }}
      disableRipple
      disabled={isDisabled}
      name={t`FloorItem`}
      onClick={handleClick}
      style={{ opacity: isDisabled ? .5 : 1 }}
    >
      <Box className={classes}>
        <Box>
          <Text color={selected ? 'blue' : 'black'} size="md" weight="semi-bold">
            {floorName}
          </Text>  

          {extra && (
            <Box className={styles.itemContainer}>
              <Room />

              <Text color={selected? 'blue': 'gray'} size="md">
                {extra.availableRooms} <Trans>Rooms</Trans>
              </Text>
            </Box>
          )}
        </Box>
      </Box>
    </ButtonBase>
  );
}
