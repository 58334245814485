import { t } from '@lingui/macro';
import {
  CalendarDatesModel,
  EditCalendarDatesModel,
  EditCalendarDatesRequest,
  EditCalendarDatesResponse,
  GetCalendarDatesRequest,
  GetCalendarDatesResponse,
  WeekDaysEnum,
} from './models';
import {
  ActionPayload,
  BaseErrorResponse,
  BaseResponse,
} from "store/_legacy/Models/ReduxModels";

const SET_SELECTED_WEEK_DAYS = 'SET_SELECTED_WEEK_DAYS';
const RESET_SELECTED_WEEK_DAYS = 'RESET_SELECTED_WEEK_DAYS';

export const SET_CHANGED_CALENDAR_INDEX = 'SET_CHANGED_CALENDAR_INDEX';

export const GET_CALENDAR_DATES = 'GET_CALENDAR_DATES';
export const GET_CALENDAR_DATES_SUCCESS = 'GET_CALENDAR_DATES_SUCCESS';
export const GET_CALENDAR_DATES_FAIL = 'GET_CALENDAR_DATES_FAIL';

export const EDIT_CALENDAR_DATES = 'EDIT_CALENDAR_DATES';
export const EDIT_CALENDAR_DATES_SUCCESS = 'EDIT_CALENDAR_DATES_SUCCESS';
export const EDIT_CALENDAR_DATES_FAIL = 'EDIT_CALENDAR_DATES_FAIL';

interface SetSelectedWeekDays {
  type: typeof SET_SELECTED_WEEK_DAYS;
  payload: WeekDaysEnum[];
}

interface ResetSelectedWeekDays {
  type: typeof RESET_SELECTED_WEEK_DAYS;
}

interface SetChangedCalendarIndex {
  type: typeof SET_CHANGED_CALENDAR_INDEX;
  payload: number;
}

// get list of all calendar dates
export interface GetCalendarDates {
  type: typeof GET_CALENDAR_DATES;
  payload: ActionPayload<GetCalendarDatesRequest>
}

interface GetCalendarDatesSuccess {
  type: typeof GET_CALENDAR_DATES_SUCCESS;
  payload: BaseResponse<GetCalendarDatesResponse>
}

interface GetCalendarDatesFail {
  type: typeof GET_CALENDAR_DATES_FAIL;
  payload: BaseErrorResponse;
}

// edit calendar dates
export interface EditCalendarDates {
  type: typeof EDIT_CALENDAR_DATES;
  payload: ActionPayload<EditCalendarDatesModel>;
}

interface EditCalendarDatesSuccess {
  type: typeof EDIT_CALENDAR_DATES_SUCCESS;
  payload: BaseResponse<EditCalendarDatesResponse>;
}

interface EditCalendarDatesFail {
  type: typeof EDIT_CALENDAR_DATES_FAIL;
  payload: BaseErrorResponse;
}

type Actions =
  | SetSelectedWeekDays
  | ResetSelectedWeekDays
  | SetChangedCalendarIndex
  | GetCalendarDates
  | GetCalendarDatesSuccess
  | GetCalendarDatesFail
  | EditCalendarDates
  | EditCalendarDatesSuccess
  | EditCalendarDatesFail;

export interface State {
  error: string;
  loading: boolean;
  changedCalendarIndex: number; // define if new calendar data is loaded
  initialMonth: Date; // start from current Month
  numberOfMonths: number; // numbers of Months to show on calendar
  calendarDates: CalendarDatesModel;
}

export const weekDays: number[] = [0, 1, 2, 3, 4, 5, 6];

const initialState: State = {
  error: '',
  loading: false,
  changedCalendarIndex: 1,
  initialMonth: new Date(),
  numberOfMonths: 6,
  calendarDates: {
    disabledDates: [],
    disabledWeekDays: [],
    reservedDates: [],
  },
};

export default function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case SET_SELECTED_WEEK_DAYS: {
      return {
        ...state,
        calendarDates: {
          ...state.calendarDates,
          disabledWeekDays: action.payload,
        },
      };
    }

    case SET_CHANGED_CALENDAR_INDEX: {
      return {
        ...state,
        changedCalendarIndex: action.payload,
      };
    }

    case RESET_SELECTED_WEEK_DAYS: {
      return {
        ...state,
        calendarDates: {
          ...state.calendarDates,
          disabledWeekDays: [],
        },
      };
    }

    case GET_CALENDAR_DATES:
      return {
        ...state,
        loading: true,
      };
    case GET_CALENDAR_DATES_SUCCESS:
      return {
        ...state,
        calendarDates: action.payload.data.result.data,
        loading: false,
      };
    case GET_CALENDAR_DATES_FAIL:
      return {
        ...state,
        error: t`There was an error. Please try again.`,
        loading: false,
      };

    case EDIT_CALENDAR_DATES:
      return {
        ...state,
      };
    case EDIT_CALENDAR_DATES_SUCCESS:
      return {
        ...state,
        calendarDates: action.payload.data.result.data,
      };
    case EDIT_CALENDAR_DATES_FAIL:
      return {
        ...state,
        error: t`There was an error. Please try again.`,
      };

    default:
      return state;
  }
}

export function updateSelectedWeekDays(weekDays: WeekDaysEnum[]): SetSelectedWeekDays {
  return {
    type: SET_SELECTED_WEEK_DAYS,
    payload: weekDays,
  };
}

export function resetSelectedWeekDays(): ResetSelectedWeekDays {
  return {
    type: RESET_SELECTED_WEEK_DAYS,
  };
}

export function getCalendarDates(data: GetCalendarDatesRequest): GetCalendarDates {
  return {
    type: GET_CALENDAR_DATES,
    payload: {
      request: {
        method: 'GET',
        url: `/api/calendar/${data.locationId}`,
      },
    },
  };
}

export function editCalendarDates(data: EditCalendarDatesRequest): EditCalendarDates {
  return {
    type: EDIT_CALENDAR_DATES,
    payload: {
      request: {
        method: 'PUT',
        url: `/api/calendar/${data.locationId}/disabled-dates`,
        data,
      },
    },
  };
}
