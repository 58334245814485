import { useTypedSelector } from "store/_legacy/Redux/store";

export const useDefaultTimeZone = (): [iana: string, windows?: string] => {
  const defaultTimeZone = useTypedSelector((state) => state.profile.defaultTimeZone);

  if (!defaultTimeZone) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (timezone) {
      return [timezone];
    } else {
      return ["UTC"];
    }
  } else {
    const [iana, windows] = defaultTimeZone.split(";");

    return [iana, windows];
  }
};
