import React from "react";
import { useHistory } from "react-router-dom";

import { HomeWrapper } from "./home-wrapper";

export const Wrapper: React.FC = (props) => {
  const { children } = props;
  const { location } = useHistory();

  switch (location.pathname) {
    case "/":
    case "/pending":
    case "/saved":
    case "/previous":
    case "/feed":
      return <HomeWrapper>{children}</HomeWrapper>; 
    default:
      return <>{children}</>;
  }
};
