import { useSelector } from "react-redux";
import { selectAdminCurrentCampaignPolicy } from "../selectors";
import { useMemo } from "react";
import { CommPolicyRuleType, User } from "store/apis";

export const useCurrentCampaignPolicyRules = () => {
  const currentPolicy = useSelector(selectAdminCurrentCampaignPolicy);
  const [approvers, recipients, authors, editors, policyManagers, recipientUsersCount] = useMemo(() => {
    const approvers: User[] = [];
    const recipients: User[] = [];
    const authors: User[] = [];
    const editors: User[] = [];
    const policyManagers: User[] = [];
    let recipientUsersCount = 0;

    for (const { type, value, user } of currentPolicy?.rules || []) {
      if (user) {
        switch (type) {
          case CommPolicyRuleType.APPROVER:
            approvers.push(user);
            break;
          case CommPolicyRuleType.RECIPIENT:
            recipients.push(user);
            break;
          case CommPolicyRuleType.AUTHOR:
            authors.push(user);
            break;
          case CommPolicyRuleType.EDITOR:
            editors.push(user);
            break;
          case CommPolicyRuleType.POLICY_MANAGER:
            policyManagers.push(user);
            break;
          default:
            break;
        }
      } else {
        switch (type) {
          case CommPolicyRuleType.RECIPIENT_USERS_COUNT:
            recipientUsersCount = value !== undefined ? Number.parseInt(value) : 0;
            break;
          default:
            break;
        }
      }
    }

    return [approvers, recipients, authors, editors, policyManagers, recipientUsersCount];
  }, [currentPolicy?.rules]);

  return { approvers, recipients, authors, editors, policyManagers, recipientUsersCount };
};
