import React, { useState } from "react";
import { CateringDialogProps } from "./types";
import { Box, Button, Checkbox, Dialog, FilledInput, FormControlLabel, InputProps, List, ListItem, Skeleton, Typography, useTheme } from "@mui/material";
import { t } from "@lingui/macro";
import { Search } from "@mui/icons-material";
import { ReservationServiceOption, ReservationServiceOptionRequest, ReservationServiceOptionType, ReservationServiceRequestSetting, ReservationServiceRequestSettingType, ReservationServiceType, useGetAvailableReservationServiceOptionsQuery } from "store";
import { IconBox } from "../box";
import { TogetherCatering } from "../icons";
import { resolveReservationServiceOptionTypeLabel, searchFullText } from "utils";
import { NumberInput, TimeInput } from "../input";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format, parse } from "date-fns";

const Section: React.FC<{
  type: ReservationServiceOptionType;
  requests: ReservationServiceOptionRequest[];
  options: ReservationServiceOption[];
  settings: ReservationServiceRequestSetting[];
  isLoading: boolean;
  onRequestSave: (request: ReservationServiceOptionRequest) => void;
  onRequestRemove: (request: ReservationServiceOptionRequest) => void;
  onSettingSave: (setting: ReservationServiceRequestSetting) => void;
  onSettingRemove: (setting: ReservationServiceRequestSetting) => void;
}> = (props) => {
  const { type, requests, options, settings, isLoading, onRequestSave, onRequestRemove, onSettingSave, onSettingRemove } = props;
  const { palette, background } = useTheme();

  const handleOptionCheck = (option: ReservationServiceOption) => {
    if (requests.some((request) => request.option && request.option.id === option.id)) {
      onRequestRemove({ option });
    } else {
      onRequestSave({ option, quantity: 1 });
    }
  };

  const handleOtherCheck = () => {
    if (requests.some((request) => !request.option && request.type && request.serviceType === ReservationServiceType.CATERING)) {
      onRequestRemove({ type });
    } else {
      onRequestSave({ serviceType: ReservationServiceType.CATERING, type, description: "" });
    }
  };

  const handleServingTimeChange = (setting?: ReservationServiceRequestSetting) => {
    if (setting) {
      onSettingSave(setting);
    } else {
      onSettingRemove({
        type: ReservationServiceRequestSettingType.SERVING_TIME,
        serviceType: ReservationServiceType.CATERING,
        serviceOptionType: type,
      });
    }
  };

  const handleCostCentreChange: InputProps["onChange"] = (event) => {
    if (event.currentTarget.value) {
      onSettingSave({
        type: ReservationServiceRequestSettingType.COST_CENTRE,
        serviceType: ReservationServiceType.CATERING,
        serviceOptionType: type,
        value: event.currentTarget.value,
      });
    } else {
      onSettingRemove({
        type: ReservationServiceRequestSettingType.COST_CENTRE,
        serviceType: ReservationServiceType.CATERING,
        serviceOptionType: type,
      });
    }
  };

  const costCentre = settings.find(({ type }) => type === ReservationServiceRequestSettingType.COST_CENTRE);
  const servingTime = settings.find(({ type }) => type === ReservationServiceRequestSettingType.SERVING_TIME);
  const other = requests.find(({ option, type }) => !option && type);
  const now = new Date();

  return (
    <Box display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" justifyContent="space-between" mb={1}>
        <Typography fontSize={14} fontWeight={600} textTransform="capitalize">{resolveReservationServiceOptionTypeLabel(type)}</Typography>
        <Box alignItems="center" display="flex">
          <FormControlLabel
            checked={!!servingTime}
            control={<Checkbox size="small" sx={{ p: 0 }} />}
            label={<Typography color={palette.grey[700]} fontSize={14}>{t`Serving time`}</Typography>}
            onChange={() => {
              handleServingTimeChange(!servingTime ? {
                type: ReservationServiceRequestSettingType.SERVING_TIME,
                serviceType: ReservationServiceType.CATERING,
                serviceOptionType: type,
              } : undefined);
            }}
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimeInput
              disabled={!servingTime}
              listAlign="right"
              onChange={(value) => handleServingTimeChange({
                type: ReservationServiceRequestSettingType.SERVING_TIME,
                serviceType: ReservationServiceType.CATERING,
                serviceOptionType: type,
                value: format(value, "HH:mm"),
              })}
              value={servingTime?.value ? parse(servingTime?.value, "HH:mm", now) : undefined}
            />
          </LocalizationProvider>
        </Box>
      </Box>
      {isLoading ? (
        <Box alignItems="center" display="flex" justifyContent="space-between">
          <Box alignItems="center" display="flex" pl={1.5}>
            <Skeleton height={20} variant="rectangular" width={20} />
            <Skeleton height={16} sx={{ ml: 1.5 }} variant="rectangular" width={180} />
          </Box>
        </Box>
      ) : (
        <>
          {type === ReservationServiceOptionType.BEVERAGE ? (
            <FilledInput
              defaultValue={costCentre?.value}
              onChange={handleCostCentreChange}
              placeholder={t`Cost centre`}
              sx={{ mb: 1, height: 28, fontSize: 14 }}
            />
          ) : undefined}
          <List disablePadding>
            {options.map((option) => {
              const { id, name } = option;
              const request = requests.find(({ option }) => option && option.id === id);

              return (
                <ListItem disablePadding key={id} sx={{ width: "100%" }}>
                  <Box
                    bgcolor={request ? background.blue.main : undefined}
                    borderRadius={2}
                    display="flex"
                    justifyContent="space-between"
                    pl={1.5}
                    width="100%"
                  >
                    <FormControlLabel
                      checked={!!request}
                      control={<Checkbox size="small" />}
                      label={<Typography fontSize={14}>{name}</Typography>}
                      onChange={() => handleOptionCheck(option)}
                      sx={{ display: "flex", alignItems: "center", gap: 1 }}
                    />
                    {request ? (
                      <NumberInput
                        disabled={!request}
                        onChange={(quantity) => onRequestSave({ ...request, quantity })}
                        value={request?.quantity}
                      />
                    ) : undefined}
                  </Box>
                </ListItem>
              );
            })}
            <ListItem disablePadding>
              <Box bgcolor={other ? background.blue.main : undefined} borderRadius={2} display="flex" flexDirection="column" pl={1.5}>
                <FormControlLabel
                  checked={!!other}
                  control={<Checkbox size="small" />}
                  label={<Typography fontSize={14}>{t`Other`}</Typography>}
                  onChange={() => handleOtherCheck()}
                  sx={{ display: "flex", alignItems: "center", gap: 1 }}
                />
                {other ? (
                  <Box pb={1} pl={4} pr={1.5}>
                    <FilledInput
                      fullWidth
                      onChange={(event) => {
                        onRequestSave({ type, serviceType: ReservationServiceType.CATERING, description: event.currentTarget.value });
                      }}
                      placeholder={t`Type here`}
                      size="small"
                      value={other.description}
                    />
                  </Box>
                ) : undefined}
              </Box>
            </ListItem>
          </List>
        </>
      )}
    </Box>
  );
};

export const CateringDialog: React.FC<CateringDialogProps> = (props) => {
  const { locationId, requests, settings, onChange, Trigger } = props;
  const { palette } = useTheme();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const getAvailableOptionsQuery = useGetAvailableReservationServiceOptionsQuery({
    locationId, limit: -1, filter: { serviceType: ReservationServiceType.CATERING } },
    { skip: !open },
  );
  const { data: getAvailableOptionsResponse, isLoading } = getAvailableOptionsQuery;
  const { items: options } = getAvailableOptionsResponse?.result?.data || {};

  const getRequests = (type: ReservationServiceOptionType): ReservationServiceOptionRequest[] => {
    if (!requests) {
      return [];
    }

    return requests.filter((request) => request.type === type || request.option?.type === type);
  };

  const getOptions = (type: ReservationServiceOptionType): ReservationServiceOption[] => {
    if (!options) {
      return [];
    }

    return options.filter((option) => option.type === type && (!search || searchFullText(option.name, search)));
  };

  const getSettings = (type: ReservationServiceOptionType): ReservationServiceRequestSetting[] => {
    if (!settings) {
      return [];
    }

    return settings.filter((setting) => setting.serviceOptionType === type);
  };

  const handleRequestSave = (request: ReservationServiceOptionRequest) => {
    const newRequests = requests?.filter(({ option, type }) => {
      return !(request.option && option && request.option.id === option.id) && !(request.type && type && request.type === type);
    });

    onChange?.([...(newRequests || []), request], settings || []);
  };

  const handleRequestRemove = (request: ReservationServiceOptionRequest) => {
    const newRequests = requests?.filter(({ option, type }) => {
      return !(request.option && option && request.option.id === option.id) && !(request.type && type && request.type === type);
    });

    onChange?.(newRequests || [], settings || []);
  };

  const handleSettingSave = (setting: ReservationServiceRequestSetting) => {
    const newSettings = settings?.filter(({ type, serviceType, serviceOptionType }) => {
      return type !== setting.type || serviceType !== setting.serviceType || serviceOptionType !== setting.serviceOptionType;
    });

    onChange?.(requests || [], [...(newSettings || []), setting]);
  };

  const handleSettingRemove = (setting: ReservationServiceRequestSetting) => {
    const newSettings = settings?.filter(({ type, serviceType, serviceOptionType }) => {
      return type !== setting.type || serviceType !== setting.serviceType || serviceOptionType !== setting.serviceOptionType;
    });

    onChange?.(requests || [], newSettings || []);
  };

  const attendeesNumber = settings?.find(({ type, serviceType }) => {
    return type === ReservationServiceRequestSettingType.ATTENDEES_NUMBER && serviceType === ReservationServiceType.CATERING;
  });

  return (
    <>
      <Trigger onClick={() => setOpen(true)} />
      <Dialog onClose={() => setOpen(false)} open={open}>
        <Box p={2} width={420}>
          <Box alignItems="center" display="flex" justifyContent="space-between" mb={2}>
            <Box alignItems="center" display="flex">
              <IconBox mr={1}><TogetherCatering fill={palette.grey[700]} sx={{ width: 16, height: 16 }} /></IconBox>
              <Typography fontSize={16} fontWeight={600}>{t`Catering`}</Typography>
            </Box>
            <Button onClick={() => setOpen(false)} variant="contained">{t`Done`}</Button>
          </Box>
          <Box alignItems="center" display="flex" justifyContent="space-between" mb={1}>
            <Typography fontSize={14} fontWeight={600}>{t`Number of attendees`}</Typography>
            <NumberInput
              onChange={(value) => handleSettingSave({
                type: ReservationServiceRequestSettingType.ATTENDEES_NUMBER,
                serviceType: ReservationServiceType.CATERING,
                value: value ? `${value}` : undefined,
              })}
              value={attendeesNumber?.value ? Number.parseInt(attendeesNumber.value) : undefined}
            />
          </Box>
          <FilledInput
            fullWidth
            onChange={(event) => setSearch(event.currentTarget.value)}
            placeholder={t`Search by name`}
            startAdornment={<Search sx={{ color: palette.grey[700] }} />}
            sx={{ mb: 2 }}
            value={search}
          />
          <Box display="flex" flexDirection="column" gap={2} maxHeight={420} pb={2} pr={0.5} sx={{ overflowY: "auto", overflowX: "hidden" }}>
            <Section
              isLoading={isLoading}
              onRequestRemove={handleRequestRemove}
              onRequestSave={handleRequestSave}
              onSettingRemove={handleSettingRemove}
              onSettingSave={handleSettingSave}
              options={getOptions(ReservationServiceOptionType.BEVERAGE)}
              requests={getRequests(ReservationServiceOptionType.BEVERAGE)}
              settings={getSettings(ReservationServiceOptionType.BEVERAGE)}
              type={ReservationServiceOptionType.BEVERAGE}
            />
            <Section
              isLoading={isLoading}
              onRequestRemove={handleRequestRemove}
              onRequestSave={handleRequestSave}
              onSettingRemove={handleSettingRemove}
              onSettingSave={handleSettingSave}
              options={getOptions(ReservationServiceOptionType.BREAKFAST)}
              requests={getRequests(ReservationServiceOptionType.BREAKFAST)}
              settings={getSettings(ReservationServiceOptionType.BREAKFAST)}
              type={ReservationServiceOptionType.BREAKFAST}
            />
            <Section
              isLoading={isLoading}
              onRequestRemove={handleRequestRemove}
              onRequestSave={handleRequestSave}
              onSettingRemove={handleSettingRemove}
              onSettingSave={handleSettingSave}
              options={getOptions(ReservationServiceOptionType.SNACK)}
              requests={getRequests(ReservationServiceOptionType.SNACK)}
              settings={getSettings(ReservationServiceOptionType.SNACK)}
              type={ReservationServiceOptionType.SNACK}
            />
            <Section
              isLoading={isLoading}
              onRequestRemove={handleRequestRemove}
              onRequestSave={handleRequestSave}
              onSettingRemove={handleSettingRemove}
              onSettingSave={handleSettingSave}
              options={getOptions(ReservationServiceOptionType.LUNCH)}
              requests={getRequests(ReservationServiceOptionType.LUNCH)}
              settings={getSettings(ReservationServiceOptionType.LUNCH)}
              type={ReservationServiceOptionType.LUNCH}
            />
          </Box>
        </Box>
      </Dialog>
    </>
  );
};