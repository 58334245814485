import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTypedSelector } from 'store/_legacy/Redux/store';
import { MapContainer } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet-draw';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import styles from './styles.module.scss';
import useSnackbar from "components/_legacy/Snackbar/useSnackbar";
import { getImageBoundsLimited } from "../../FloorMap/Map/Helpers/mapFunctions";
import {MAP_HEIGHT} from "../../FloorMap/Map/Helpers/consts";
import { setFloorMapData } from "../../../Store/floorMapDuck";
import Box from "components/_legacy/Box";
import MapDrawing from "../../FloorMap/Map/MapDrawing";
import {
  ImapMarkers,
  getCornerIcon,
  getMapDataToEdit,
  repositionImage,
} from "./EditableMapHelpers";

interface Props {
  mapSize: {
    height: number;
    width: number;
  };
  previewUrl: string;
}

export default function EditableMap({ mapSize, previewUrl }: Props) {
  const { height = 100, width = 100 } = mapSize;

  const dispatch = useDispatch();
  // We use this key to set the map size correctly after it's rendered.
  const [key, setKey] = useState(0);
  const [openSnackbar] = useSnackbar();
  const [map, setMap] = useState<L.Map | null>(null);
  const [mapCentered, setMapCentered] = useState(false);
  const { adminFloorMap } = useTypedSelector(state => state);

  const mapImageBounds: L.LatLngBoundsExpression = getImageBoundsLimited({ height, proportion: 100, width });

  const onSaveMapSize = (mapImage: L.ImageOverlay) => {
    const { mapSizeToEdit, mapImageBoundsToEdit } = getMapDataToEdit(mapImage);

    // save bounds here
    dispatch(setFloorMapData({
      mapSizeToEdit,
      mapImageBoundsToEdit,
    }));
  };

  useEffect(() => {
    if (map && !mapCentered) {
      setMapCentered(true);
    }
  }, [map]);

  const initImageBounds = () => {
    // @ts-ignore
    const bounds: [number[], number[]] = mapImageBounds;

    return {
      topleft: L.latLng(bounds[0][1], bounds[1][0]),
      topright: L.latLng(bounds[1][0], bounds[1][1]),
      bottomleft: L.latLng(bounds[0][0], bounds[0][1]),
      bottomright: L.latLng(bounds[0][0], bounds[1][1]),
    };
  };

  const initCornerMarkers = (map: L.Map) => {
    const { topleft, topright, bottomleft, bottomright } = initImageBounds();

    const topleftMarkerIcon = getCornerIcon('topleft');
    const toprightMarkerIcon = getCornerIcon('topright');
    const bottomleftMarkerIcon = getCornerIcon('bottomleft');
    const bottomrightMarkerIcon = getCornerIcon('bottomright');

    // add corner icons to map
    return {
      topleftMarker: L.marker(topleft, { draggable: true, icon: topleftMarkerIcon } ).addTo(map),
      toprightMarker: L.marker(topright, { draggable: true, icon: toprightMarkerIcon } ).addTo(map),
      bottomleftMarker: L.marker(bottomleft, { draggable: true, icon: bottomleftMarkerIcon } ).addTo(map),
      bottomrightMarker: L.marker(bottomright, { draggable: true, icon: bottomrightMarkerIcon } ).addTo(map),
    };
  };

  const initMapImage = () => {
    return L.imageOverlay(previewUrl, mapImageBounds, { className: styles.mapImage });
  };

  const initTooltips = (markers: ImapMarkers) => {
    const { topleftMarker, toprightMarker, bottomleftMarker, bottomrightMarker } = markers;

    const cornerTooltipText = "Pull to resize";

    topleftMarker.bindTooltip(cornerTooltipText, { direction: "top", sticky: true });
    toprightMarker.bindTooltip(cornerTooltipText, { direction: "top", sticky: true });
    bottomleftMarker.bindTooltip(cornerTooltipText, { direction: "bottom", sticky: true });
    bottomrightMarker.bindTooltip(cornerTooltipText, { direction: "bottom", sticky: true });
  };

  const initMapEvents = (markers: ImapMarkers, mapImage: L.ImageOverlay) => {
    const { topleftMarker, toprightMarker, bottomleftMarker, bottomrightMarker } = markers;

    // set tooltips show/hide on hover
    topleftMarker.on('mouseout', () => { topleftMarker.closeTooltip(); });
    topleftMarker.on('mouseover', () => { topleftMarker.openTooltip(); });

    toprightMarker.on('mouseout', () => { toprightMarker.closeTooltip(); });
    toprightMarker.on('mouseover', () => { toprightMarker.openTooltip(); });

    bottomleftMarker.on('mouseout', () => { bottomleftMarker.closeTooltip(); });
    bottomleftMarker.on('mouseover', () => { bottomleftMarker.openTooltip(); });

    bottomrightMarker.on('mouseout', () => { bottomrightMarker.closeTooltip(); });
    bottomrightMarker.on('mouseover', () => { bottomrightMarker.openTooltip(); });

    // re-draw of map and corners on drag
    topleftMarker.on('drag', () => repositionImage('topleft', markers, mapImage));
    toprightMarker.on('drag', () => repositionImage('topright', markers, mapImage));
    bottomleftMarker.on('drag', () => repositionImage('bottomleft', markers, mapImage));
    bottomrightMarker.on('drag', () => repositionImage('bottomright', markers, mapImage));

    // get new map image size after drag is finished
    topleftMarker.on('dragend', () => onSaveMapSize(mapImage));
    toprightMarker.on('dragend', () => onSaveMapSize(mapImage));
    bottomleftMarker.on('dragend', () => onSaveMapSize(mapImage));
    bottomrightMarker.on('dragend', () => onSaveMapSize(mapImage));
  };

  useEffect(() => {
    if (map) {
      // Set the map bounds to the image uploaded. This will center the map correctly.
      map.fitBounds(mapImageBounds);

      const markers = initCornerMarkers(map);
      const mapImage = initMapImage();

      onSaveMapSize(mapImage); // save map size/bounds on init
      initTooltips(markers);
      initMapEvents(markers, mapImage);

      // add all elements to leaflet map
      map.addLayer(mapImage);
    }
  }, [map]);

  useEffect(() => {
    if (adminFloorMap.errorMessage) {
      const handleClose = () => {
        dispatch(setFloorMapData({ errorMessage: '' }));
      };

      openSnackbar({
        onClose: handleClose,
        text: adminFloorMap.errorMessage,
        type: 'error',
      });
    }

    if (adminFloorMap.successMessage) {
      const handleClose = () => {
        dispatch(setFloorMapData({ successMessage: '' }));
      };

      openSnackbar({
        onClose: handleClose,
        text: adminFloorMap.successMessage,
        type: 'success',
      });
    }
  }, [adminFloorMap.errorMessage, adminFloorMap.successMessage]);

  useEffect(() => {
    if (!mapCentered) {
      setKey(key + 1);
    }
  }, [map]);

  return (
    <Box height={MAP_HEIGHT} id="mapid">
      <MapContainer
        attributionControl={false}
        boundsOptions={{ padding: [0, 0] }}
        center={[50, 50]}
        crs={L.CRS.Simple}
        key={key}
        maxZoom={5}
        minZoom={-5}
        style={{ height: MAP_HEIGHT }}
        whenCreated={setMap}
        zoom={0}
        zoomSnap={0}
      >
        <MapDrawing />
      </MapContainer>
    </Box>
  );
}
