import Api from 'store/_legacy/Services/Api';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  GET_LOCATION_DETAILS,
  GET_LOCATION_DETAILS_FAIL,
  GET_LOCATION_DETAILS_SUCCESS,
  GetLocationDetails,
} from '.';

function* getLocationDetailsSaga(action: GetLocationDetails): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: GET_LOCATION_DETAILS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_LOCATION_DETAILS_FAIL, payload: e });
  }
}

export default [
  takeEvery(GET_LOCATION_DETAILS, getLocationDetailsSaga),
];
