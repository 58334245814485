import {useDispatch} from "react-redux";
import {useTypedSelector} from "store/_legacy/Redux/store";
import {deleteDeskFromFloor} from "../../../../../../../Store/floorMapApiRequestsDuck";
import {Trans, t} from "@lingui/macro";
import styles from "../styles.module.scss";
import Box from "components/_legacy/Box";
import Button from "components/_legacy/Button";

interface Props {
  floorId: string;
  onCancelAction: Function; // close popup function
  onDelete?: Function; // call addition function with onDelete handler
}

export default function NoReservationContent(props: Props) {
  const dispatch = useDispatch();
  const { adminFloorMap } = useTypedSelector(state => state);
  const { floorId, onDelete = () => {}, onCancelAction } = props;
  const { deskToDelete } = adminFloorMap;
  
  const onDeleteAction = () => {
    if (deskToDelete?.id) {
      const deskId = deskToDelete.id.toString();

      dispatch(deleteDeskFromFloor({ floorId, deskId })); // delete desk
      onDelete(); // addition delete handler from props - used for cleaning parent component state
      onCancelAction(); // cancel popup action
    }
  };
  
  return (
    <>
      <Box className={styles.modalContentText} marginBottom={20}>
        <span><Trans>Are you sure to delete</Trans>&nbsp;{deskToDelete?.name}?</span>
      </Box>

      <Box display="flex" justifyContent="end">
        <Button
          aria-label={t`cancel delete desk`}
          name={t`cancel delete desk`}
          onClick={() => onCancelAction()}
          size="sm"
          type="clear"
        >
          <Trans>Cancel</Trans>
        </Button>

        <Button
          aria-label={t`confirm delete desk`}
          name={t`confirm delete desk`}
          onClick={onDeleteAction}
          size="sm"
        >
          <Trans>Delete</Trans>
        </Button>
      </Box>
    </>
  );
}
