import { t } from "@lingui/macro";
import { LocationOnOutlined, PictureInPictureAltRounded, StarRounded } from "@mui/icons-material";
import { Box, IconButton, Skeleton, Typography, useTheme } from "@mui/material";
import { TogetherFloor, useToast } from "components";
import { useDelegatedId, useExceptionTracker } from "hooks";
import React from "react";
import { Link } from "react-router-dom";
import { isSuccessAPIResponse, useGetSavedDesksQuery, useRemoveSavedDeskMutation } from "store";
import { encodeResetQueryParam } from "utils";

export const HomeSavedRoute: React.FC = () => {
  const { palette } = useTheme();
  const delegatedId = useDelegatedId();
  const toast = useToast();
  const trackException = useExceptionTracker();
  const [removeSavedDesk, { isLoading: isRemovingSavedDesk }] = useRemoveSavedDeskMutation();
  const getSavedDesksQuery = useGetSavedDesksQuery({ include: ["floor", "floor.location"], userId: delegatedId });
  const { data: getSavedDesksResponse, isLoading: desksAreLoading } = getSavedDesksQuery;
  const { items: desks } = getSavedDesksResponse?.result?.data || {};

  const handleRemoveSavedDeskClick = (deskId: string) => {
    if (!isRemovingSavedDesk) {
      void (async () => {
        const response = await removeSavedDesk({ deskId, userId: delegatedId });

        if (isSuccessAPIResponse(response)) {
          toast.showToast({ severity: "success", message: t`Saved desk was removed` });
        } else {
          toast.showToast({ severity: "error", message: t`Failed to remove saved desk` });
          trackException(response.error, { endpointName: removeSavedDesk.name });
        }
      })();

    }
  };

  return (
    <Box alignItems="stretch" display="flex" flexDirection="column">
      {desksAreLoading ? (
        <>
          <Skeleton height={88} sx={{ mb: 1 }} variant="rectangular" />
          <Skeleton height={88} variant="rectangular" />
        </>
      ) : (
        desks?.length === 0 ? (
          <Typography color={palette.grey[700]} fontSize={14} fontWeight="600" mt={2} textAlign="center">
            {t`No saved desks`}
          </Typography>
        ) : (
          desks?.map(({ id, name, sectionName, floor }, index) => (
            <Box bgcolor={palette.grey[100]} borderRadius={2} key={id} mb={index + 1 === desks.length ? 0 : 1} p={2}>
              <Box alignItems="center" display="flex" justifyContent="space-between" mb={1}>
                <Typography
                  component={Link}
                  data-cid="new-saved-reservation-link"
                  fontSize={14}
                  fontWeight="600"
                  to={{
                    pathname: "/create-new-booking/select-desk",
                    search: encodeResetQueryParam({ locationId: floor?.location?.id, floorId: floor?.id, deskId: id }),
                  }}
                >
                  {name}
                </Typography>
                <IconButton data-cid="save-desk-button" onClick={() => handleRemoveSavedDeskClick(id)} sx={{ p: 0 }}>
                  <StarRounded color="primary" />
                </IconButton>
              </Box>
              <Box alignItems="center" display="flex" justifyContent="space-between">
                <Box alignItems="center" display="flex">
                  <LocationOnOutlined fontSize="small" sx={{ color: palette.grey[700] }} />
                  <Typography color={palette.grey[700]} fontSize={14} ml={1}>{floor?.location?.name}. {floor?.location?.address}</Typography>
                </Box>
                <Box alignItems="center" display="flex">
                  <Box alignItems="center" display="flex">
                    <TogetherFloor fill={palette.grey[700]} sx={{ width: 16 }} />
                    <Typography color={palette.grey[700]} fontSize={14} ml={1}>{floor?.name}</Typography>
                  </Box>
                  {sectionName ? (
                    <Box alignItems="center" display="flex" ml={2}>
                      <PictureInPictureAltRounded fontSize="small" sx={{ color: palette.grey[700] }} />
                      <Typography color={palette.grey[700]} fontSize={14} ml={1}>{sectionName}</Typography>
                    </Box>
                  ) : undefined}
                </Box>
              </Box>
            </Box>
          ))
        )
      )}
    </Box>
  );
};
