import { NewsFeed } from "components";
import { useDelegatedId } from "hooks";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

export const HomeNewsFeedRoute: React.FC = () => {
  const history = useHistory();
  const delegatedId = useDelegatedId();

  useEffect(() => {
    if (delegatedId) {
      history.push("/");
    }
  }, [delegatedId]);

  if (delegatedId) {
    return <></>;
  }

  return <NewsFeed />;
};
