import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Box from 'components/_legacy/Box';
import CheckIcon from '@material-ui/icons/Check';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import IconButton from '@material-ui/core/IconButton';
import L from 'leaflet';
import styles from './styles.module.scss';
import { deleteSection, setFloorMapData, updateSection } from 'Admin/Store/floorMapDuck';
import { DrawState, FloorMapObject } from 'Admin/Store/floorMapDuck/models';
import { Polygon, Popup } from 'react-leaflet';
import { useDispatch } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useTypedSelector } from 'store/_legacy/Redux/store';
import DrawingNameLabel from "../../Components/DrawingNameLabel";
import {deleteSectionFromFloor} from "../../../../../../Store/floorMapApiRequestsDuck";
import SectionDeleteModal from "./DeleteSectionModal";
import { addDeskToPositionProps } from "../../helpers";

interface Props {
  addDeskToPosition: (props: addDeskToPositionProps) => void;
  drawDesk: DrawState;
  setDrawDesk: (drawDesk: DrawState) => void;
  section: FloorMapObject;
  zoomLevel: number;
  floorId: string;
  editable?: boolean;
}

/**
 * Section Drawing
 *
 * This component handles deletion and edition internally.
 * When adding desks, it sends the state to the <Map /> component so it can
 * enable add desk on <CreateDesk />, associating that creation with this section id.
 *
 * React Leaflet Draw handles it's own states, so this component syncs them with internal states.
 */
export default function SectionObject({ addDeskToPosition, floorId, drawDesk, section, setDrawDesk, editable }: Props) {
  const dispatch = useDispatch();
  const { adminFloorMap } = useTypedSelector(state => state);
  const ref = useRef<any>(null);
  const { coordinatesInLatLngBounds } = section;
  const [editSectionEnabled, setEditSectionEnabled] = useState(false);
  const [sectionPopupOpened, setSectionPopupOpened] = useState(false);
  const [deleteSectionPopupOpened, setDeleteSectionPopupOpened] = useState(false);
  const { config } = useTypedSelector(state => state);
  const [iconMarkupOpacity, setIconMarkupOpacity] = useState(1);
  const id = typeof section.id === 'number' ? section.id : undefined;

  // Enable or disable edition when editSectionEnabled changes
  useEffect(() => {
    if (ref.current) {
      if (editSectionEnabled) {
        dispatch(setFloorMapData({
          activeDeskId: undefined,
          activeMeetingId: undefined,
          activeSectionId: id,
        }));
        ref.current.editing.enable();
      } else {
        dispatch(setFloorMapData({
          activeDeskId: undefined,
          activeMeetingId: undefined,
          activeSectionId: undefined,
        }));
        ref.current.editing.disable();
      }
    }
  }, [editSectionEnabled]);

  // Close popup if needed when sectionPopupOpened or addDeskEnabled changes
  // Popup has drawDesk.active as dependency because if any other section starts drawing all other will close
  useEffect(() => {
    if (ref.current && editable) {
      if (!sectionPopupOpened || drawDesk.active) {
        ref.current._popup.remove();
      }
    }
  }, [drawDesk.active, sectionPopupOpened]);

  const deactivateStates = () => {
    setEditSectionEnabled(false);
    setSectionPopupOpened(false);
  };

  const onSetIconMarkupOpacity = (set: boolean, e: any) => {
    const classList = e.originalEvent?.toElement?.classList || {};
    const isInnerDesk = Object.values(classList).includes('leaflet-interactive');
    return set || isInnerDesk ? setIconMarkupOpacity(0) : setIconMarkupOpacity(1);
  };

  // Updates the drawing on redux and deactivates all states
  const confirmEdition = () => {
    if (ref.current) {
      const coordinatesInLatLngBounds: L.LatLng[] = ref.current.editing.latlngs.length ? ref.current.editing.latlngs[0][0] : [];
      const coordinatesInPoints = coordinatesInLatLngBounds.map(coordinate => L.CRS.Simple.latLngToPoint(coordinate, 1));

      if (typeof id === 'number') {
        dispatch(updateSection({
          sectionId: id,
          section: {
            coordinatesInLatLngBounds,
            coordinatesInPoints,
            provisory: true,
          },
        }));
      }

      deactivateStates();
    }
  };

  // If the current active section is the one being clicked, add a desk to it
  // Otherwise, set draw desk state to false and clears active section
  const onClick = (e: any) => {
    if (drawDesk.active && typeof section.id === 'number') {
      addDeskToPosition({
        position: e.latlng,
        adminFloorMap,
        dispatch,
      });
    } else {
      setDrawDesk({ active: false });
    }
  };

  // Delete drawing from redux and deactivates all states
  const onDeleteSection = () => {
    if (typeof id === 'number') {
      dispatch(deleteSection(id));
    }
    deactivateStates();
  };

  const onDeleteSectionPermanently = () => {
    if (typeof id === 'number') {
      dispatch(deleteSectionFromFloor({sectionId: id, floorId }));
    }
    deactivateStates();
  };

  // Set popup state to false and if the section is being edited, saves the editions
  const onPopupClose = () => {
    if (editSectionEnabled) {
      confirmEdition();
    } else {
      setSectionPopupOpened(false);
    }
  };

  // Set popup state to true. Since leaflet draw handles states separately, this syncs both
  const onPopupOpen = () => {
    setSectionPopupOpened(true);
  };

  // open/close DeleteSectionPermanently popup
  const onOpenDeleteSectionPermanentlyPopup = () => {
    setDeleteSectionPopupOpened(true);
  };

  const onCloseDeleteSectionPermanentlyPopup = () => {
    setDeleteSectionPopupOpened(false);
  };

  // Changes addDeskEnabled to true. This triggers an effect on <CreateDesk /> that toggles add desk.
  // An effect on this component will also handle closing the popup.
  const toggleAddDeskEnabled = () => {
    setDrawDesk({ active: true });
  };

  // Toggle edit section functionality
  const toggleEditSectionEnabled = () => {
    setEditSectionEnabled(!editSectionEnabled);
  };

  const arrayOfCoordinatesInLatLng = coordinatesInLatLngBounds.map(coordinate => L.latLng(coordinate.lat, coordinate.lng));

  return (
    <Polygon
      className="floor-map-details-section"
      eventHandlers={{
        click: onClick,
        popupclose: onPopupClose,
        popupopen: onPopupOpen,
        mouseover: (e) => onSetIconMarkupOpacity(true, e),
        mouseout: (e) => onSetIconMarkupOpacity(false, e),
      }}
      pathOptions={{
        color: section.line,
        fillColor: section.line,
      }}
      positions={arrayOfCoordinatesInLatLng}
      ref={ref}
    >
      <DrawingNameLabel
        drawing={section}
        iconMarkupOpacity={iconMarkupOpacity}
        sectionLabelKey={1}
        sectionRef={ref.current}
      />

      {editable &&
        <Popup
          className={styles.popupContainer}
          closeButton={false}
        >
          {editSectionEnabled ? (
            <Box alignItems="center" display="flex">
              <IconButton
                onClick={confirmEdition}
                size="small"
                style={{ borderRadius: 6, backgroundColor: '#5BC535', padding: 0 }}
              >
                <CheckIcon
                  style={{
                    color: "#fff",
                    fontSize: 20,
                  }}
                />
              </IconButton>
            </Box>
          ) : (
            <Box alignItems="center" display="flex">
              <IconButton
                onClick={toggleAddDeskEnabled}
                size="small"
                style={{ padding: 0 }}
              >
                <AddCircleOutlineIcon
                  style={{
                    color: config.theme.primary,
                    fontSize: 20,
                  }}
                />
              </IconButton>

              <IconButton
                onClick={toggleEditSectionEnabled}
                size="small"
                style={{ marginInlineStart: 10, padding: 0 }}
              >
                <CreateOutlinedIcon
                  style={{
                    color: config.theme.primary,
                    fontSize: 20,
                  }}
                />
              </IconButton>

              {(section.saved) &&
              <IconButton
                onClick={onOpenDeleteSectionPermanentlyPopup}
                size="small"
                style={{ marginInlineStart: 10, padding: 0 }}
              >
                <DeleteOutlineIcon
                  style={{
                    color: config.theme.primary,
                    fontSize: 20,
                  }}
                />
              </IconButton>
              }

              {(!section.saved) &&
              <IconButton
                onClick={onDeleteSection}
                size="small"
                style={{ marginInlineStart: 10, padding: 0 }}
              >
                <DeleteOutlineIcon
                  style={{
                    color: config.theme.primary,
                    fontSize: 20,
                  }}
                />
              </IconButton>
              }
            </Box>
          )}
        </Popup>
      }
      <SectionDeleteModal
        close={onCloseDeleteSectionPermanentlyPopup}
        onDelete={onDeleteSectionPermanently}
        open={deleteSectionPopupOpened}
        section={section}
      />
    </Polygon>
  );
}
