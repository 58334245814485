import { useTypedSelector } from "store/_legacy/Redux/store";

import Box from "components/_legacy/Box";
import DeskDetails from "./DeskDetails";
import SectionDetails from "./SectionDetails";
import InstructionDetails from "./InstructionDetails";
import MeetingDetails from "./MeetingDetails";

export default function DrawingDetails() {
  const { adminFloorMap } = useTypedSelector(state => state);
  const {
    activeDeskId,
    activeSectionId,
    activeMeetingId,
    desks,
    meetings,
  } = adminFloorMap;
  const isActiveDrawing = activeDeskId || activeSectionId || activeMeetingId;

  const getActiveDrawingDetails = () => {
    // return desk details if active
    if (activeDeskId && desks[activeDeskId]) {
      return (
        <Box key={activeDeskId} paddingBottom={8} paddingTop={8}>
          <DeskDetails desk={desks[activeDeskId]} />
        </Box>
      );
    }

    // return meeting room details if active
    if (activeMeetingId) {
      return (
        <Box key={activeMeetingId} paddingBottom={8} paddingTop={8}>
          <MeetingDetails meeting={meetings[activeMeetingId]} />
        </Box>
      );
    }

    // return section details
    if (activeSectionId) {
      return (
        <Box key={activeSectionId} paddingBottom={8} paddingTop={8}>
          <SectionDetails />
        </Box>
      );
    }

    // return error message
    return (
      <Box paddingBottom={8} paddingTop={8}>
        The is a error rendering drawing details
      </Box>
    );
  };

  return (
    <>
      { isActiveDrawing ? getActiveDrawingDetails() : <InstructionDetails /> }
    </>
  );
}
