import { t } from "@lingui/macro";
import { Box, Typography } from "@mui/material";
import { CalendarViewSelection, DailyCalendarView, DailyCalendarViewItem, DailyCalendarViewProps, FloorSelect, InputLabel, Tab, Tabs, TimeZoneDisclaimer } from "components";
import { AllDaySwitch, AllDaySwitchProps } from "components/switch";
import { addDays, endOfDay, isAfter, parse, startOfDay } from "date-fns";
import { format } from "date-fns-tz";
import { useDefaultTimeZone } from "hooks";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { GetFloorRoomsSchedulesInput, floorsSlice, reservationsSlice, roomsSlice, selectCurrentFloor, selectCurrentReservation, useLazyGetFloorRoomsSchedulesQuery, useReservationsAvailabilityQuery } from "store";
import { getNowInTimeZone, getZeroDayInTimeZone, isSameDayInTimeZone, safeParseISO, shiftTimeZone, unshiftTimeZone } from "utils";

export const ReservationLocationFloorRoomsCalendarViewRoute: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const { locationId, floorId } = useParams<{ locationId: string; floorId: string }>();
  const currentFloor = useSelector(selectCurrentFloor);
  const currentReservation = useSelector(selectCurrentReservation);
  const availabilityQuery = useReservationsAvailabilityQuery();
  const [defaultTimeZone, defaultWindowsTimeZone] = useDefaultTimeZone();
  const [page, setPage] = useState(1);
  const zeroDayInDefaultTimezone = getZeroDayInTimeZone(defaultTimeZone);
  const selectedDay = parse(availabilityQuery.startDate, "yyyy-MM-dd", zeroDayInDefaultTimezone);
  const getFloorRoomsSchedulesInput: GetFloorRoomsSchedulesInput = {
    page,
    locationId: locationId || currentReservation?.locationId || "",
    floorId: floorId || currentReservation?.floorId || currentFloor?.id || "",
    startDate: startOfDay(selectedDay),
    endDate: endOfDay(selectedDay),
    limit: 10,
  };
  const [triggerGetFloorRoomsSchedulesQuery, getFloorRoomsSchedulesQuery] = useLazyGetFloorRoomsSchedulesQuery();
  const [items, setItems] = useState<DailyCalendarViewItem[]>([]);
  const allDayIsAvailable = useMemo(() => {
    const { roomId, startDate } = currentReservation || {};

    if (roomId && startDate) {
      const item = items.find(({ id }) => id === roomId);

      if (item?.schedules?.length) {
        return false;
      }

      return true;
    }

    return false;
  }, [JSON.stringify(items), currentReservation?.roomId, currentReservation?.startDate, currentReservation?.endDate]);
  const { data: roomsSchedulesResponse, isLoading: roomsSchedulesAreLoading, isFetching: roomsSchedulesAreFetching } = getFloorRoomsSchedulesQuery;
  const hasMore = !!roomsSchedulesResponse?.result?.data?.links?.next;
  const roomsSchedules = roomsSchedulesResponse?.result?.data?.items;

  useEffect(() => {
    const startDate = safeParseISO(currentReservation?.startDate);

    if (startDate && !isSameDayInTimeZone(selectedDay, startDate, defaultTimeZone)) {
      const value = format(startDate, "yyyy-MM-dd", { timeZone: defaultTimeZone });

      setItems([]);
      setPage(1);
      dispatch(reservationsSlice.actions.setAvailabilityQuery({ startDate: value, endDate: value }));
    }
  }, []);

  useEffect(() => {
    if (currentReservation?.floorId !== floorId) {
      dispatch(reservationsSlice.actions.resetCurrent({ floorId, locationId }));
      dispatch(floorsSlice.actions.resetCurrent({ id: floorId }));
      dispatch(roomsSlice.actions.resetCurrent());
    }
  }, [floorId]);

  useEffect(() => {
    setItems([]);
    triggerGetFloorRoomsSchedulesQuery(getFloorRoomsSchedulesInput, true);
  }, [availabilityQuery.startDate, currentFloor?.id]);

  useEffect(() => {
    triggerGetFloorRoomsSchedulesQuery(getFloorRoomsSchedulesInput, true);
  }, [page]);

  useEffect(() => {
    const newItems: DailyCalendarViewItem[] = [];

    for (const roomSchedules of roomsSchedules || []) {
      const { roomId, roomName, floorId, roomCapacity, schedules, roomReservationDayLimit } = roomSchedules;

      if (!items.some(({ id }) => id === roomId)) {
        const item: DailyCalendarViewItem = {
          floorId,
          id: roomId,
          name: roomName || roomId,
          capacity: roomCapacity,
          schedules: [],
          locationId: currentReservation?.locationId,
          reservationDayLimit: roomReservationDayLimit,
        };

        for (const schedule of schedules) {
          const { startDate: startDateString, endDate: endDateString, summary, organizer } = schedule;
          const startDate = unshiftTimeZone(new Date(startDateString), defaultTimeZone);
          const endDate = unshiftTimeZone(new Date(endDateString), defaultTimeZone);
          
          item.schedules?.push({ startDate, endDate, summary, organizer });
        }

        newItems.push(item);
      }
    }

    if (newItems.length) {
      setItems([...items, ...newItems]);
    }
  }, [roomsSchedules]);

  useEffect(() => {
    const { roomId } = currentReservation || {};

    if (roomsSchedules && roomId) {
      const { schedules = [] } = roomsSchedules.find((roomSchedule) => roomSchedule.roomId === roomId) || {};

      dispatch(roomsSlice.actions.setCurrent({ schedules }));
    }
  }, [JSON.stringify(roomsSchedules), currentReservation?.roomId]);

  const handleSelectedDayChange = (selectedDay: Date) => {
    const startDate = format(selectedDay, "yyyy-MM-dd", { timeZone: defaultTimeZone });
    const endDate = startDate;
    const roomSchedules = roomsSchedules?.find(({ roomId }) => roomId === currentReservation?.roomId);
    const maxDate = roomSchedules
      ? addDays(startOfDay(getNowInTimeZone(defaultTimeZone)), roomSchedules.roomReservationDayLimit || 180)
      : undefined;

    if (maxDate && (isSameDayInTimeZone(selectedDay, addDays(maxDate, 1), defaultTimeZone) || isAfter(selectedDay, maxDate))) {
      dispatch(roomsSlice.actions.resetCurrent(undefined));
      dispatch(reservationsSlice.actions.setCurrent({ startDate: undefined, endDate: undefined, roomId: undefined, isAllDay: undefined }));
    } else {
      dispatch(roomsSlice.actions.setCurrent({ schedules: undefined }));
      dispatch(reservationsSlice.actions.setCurrent({ startDate: undefined, endDate: undefined, isAllDay: undefined }));   
    }

    setItems([]);
    setPage(1);
    dispatch(reservationsSlice.actions.setAvailabilityQuery({ startDate, endDate }));
  };

  const handleLoadMore = () => {
    if (hasMore && !roomsSchedulesAreLoading && !roomsSchedulesAreFetching) {
      setPage(page + 1);
    }
  };

  const handleDailyCalendarViewChange: DailyCalendarViewProps["onChange"] = (selection) => {
    if (selection) {
      const { id, start, end } = selection;
      const roomSchedule = roomsSchedules?.find(({ roomId }) => roomId === id);
      const startDate = start ? shiftTimeZone(start, defaultTimeZone).toISOString() : undefined;
      const endDate = end ? shiftTimeZone(end, defaultTimeZone).toISOString() : undefined;
      
      if (roomSchedule) {
        dispatch(reservationsSlice.actions.setCurrent({ startDate, endDate, roomId: id, floorId: roomSchedule.floorId, isAllDay: undefined }));
        dispatch(roomsSlice.actions.resetCurrent({ id, name: roomSchedule.roomName, schedules: roomSchedule.schedules }));
      }
    } else {
      dispatch(reservationsSlice.actions.setCurrent({ startDate: undefined, endDate: undefined, roomId: undefined, isAllDay: undefined }));
      dispatch(roomsSlice.actions.resetCurrent(undefined));
    }
  };

  const handleTabClick = (tab: number) => {
    const isListView = /^\/reservations\/locations\/(\w|-)+\/floors\/(\w|-)+\/rooms\/?$/.test(pathname);
    const isCalendarView = /^\/reservations\/locations\/(\w|-)+\/floors\/(\w|-)+\/rooms\/calendar-view\/?$/.test(pathname);

    if (tab === 0 && !isListView) {
      history.push(`/reservations/locations/${locationId}/floors/${floorId}/rooms`);
    } else if (tab === 1 && !isCalendarView) {
      history.push(`/reservations/locations/${locationId}/floors/${floorId}/rooms/calendar-view`);
    }
  };

  const handleFloorSelectChange = (floorId: string) => {
    history.push(`/reservations/locations/${locationId}/floors/${floorId}/rooms/calendar-view`);
  };

  const handleAllDaySwitchChange: AllDaySwitchProps["onChange"] = (checked) => {
    dispatch(reservationsSlice.actions.setCurrent({ isAllDay: checked || undefined }));
  };

  let defaultSelection: CalendarViewSelection | undefined = undefined;

  if (currentReservation) {
    const { roomId, startDate, endDate, summary, isAllDay } = currentReservation;

    if (roomId) {
      defaultSelection = {
        summary,
        isAllDay,
        id: roomId,
        start: startDate ? unshiftTimeZone(new Date(startDate), defaultTimeZone) : undefined,
        end: endDate ? unshiftTimeZone(new Date(endDate), defaultTimeZone) : undefined,
      };
    }
  }

  return (
    <Box display="flex" flexDirection="column">
      <Typography fontWeight="600" marginBottom={1}>{t`Select room`}</Typography>
      <Box alignItems="flex-end" display="flex" justifyContent="space-between" marginBottom={2}>
        <Tabs inline value={1}>
          <Tab data-cid="list-view-button" label={t`List View`} onClick={() => handleTabClick(0)} />
          <Tab data-cid="calendar-view-button" label={t`Calendar View`} onClick={() => handleTabClick(1)} />
        </Tabs>
        <Box alignItems="flex-end" display="flex">
          <InputLabel disableAnimation id="floor-select-label" sx={{ fontSize: 16, fontWeight: "600", marginRight: 1, marginBottom: 0 }}>
            {t`Floor`},
          </InputLabel>
          <FloorSelect
            getOptionDisabled={({ extra }) => !extra?.availableRooms}
            include={["extra.availableRooms"]}
            labelId="floor-select-label"
            locationId={locationId || currentReservation?.locationId || ""}
            onChange={handleFloorSelectChange}
            value={floorId || currentReservation?.floorId || ""}
          />
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Box mb={1}>
          <TimeZoneDisclaimer
            timeZone={defaultTimeZone}
            tooltip={defaultWindowsTimeZone ? t`Derived from "${defaultWindowsTimeZone}" time zone.` : undefined}
          />
        </Box>
        <AllDaySwitch
          checked={!!currentReservation?.isAllDay}
          endDate={safeParseISO(currentReservation?.endDate)}
          onChange={handleAllDaySwitchChange}
          startDate={safeParseISO(currentReservation?.startDate)}
          unavailable={!allDayIsAvailable}
        />
      </Box>
      <DailyCalendarView
        defaultSelection={defaultSelection}
        hasMore={hasMore}
        height={710}
        isFirstLoad={items.length === 0}
        isLoading={roomsSchedulesAreLoading || roomsSchedulesAreFetching}
        items={items}
        minDate={getNowInTimeZone(defaultTimeZone)}
        onChange={handleDailyCalendarViewChange}
        onLoadMore={handleLoadMore}
        onSelectedDayChange={handleSelectedDayChange}
        selectedDay={selectedDay}
        timeZone={defaultTimeZone}
      />
    </Box>
  );
};
