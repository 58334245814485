import LocationDetailsHeader from 'components/_legacy/Header/LocationDetailsHeader';
import { CateringList } from './catering-list';

export default function Catering() {
  return (
    <>
      <LocationDetailsHeader />
      <CateringList />
    </>
  );
}
