import Api from 'store/_legacy/Services/Api';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ADD_ADMIN_FLOOR_ROOM,
  ADD_ADMIN_FLOOR_ROOM_FAIL,
  ADD_ADMIN_FLOOR_ROOM_SUCCESS,
  AddAdminFloorRoom,
  DELETE_ADMIN_FLOOR_ROOM,
  DELETE_ADMIN_FLOOR_ROOM_FAIL,
  DELETE_ADMIN_FLOOR_ROOM_SUCCESS,
  DeleteAdminFloorRoom,
  EDIT_ADMIN_FLOOR_ROOM,
  EDIT_ADMIN_FLOOR_ROOM_FAIL,
  EDIT_ADMIN_FLOOR_ROOM_SUCCESS,
  EditAdminFloorRoom,
  GET_ADMIN_FLOOR_ROOMS,
  GET_ADMIN_FLOOR_ROOMS_FAIL,
  GET_ADMIN_FLOOR_ROOMS_SUCCESS,
  GET_AVAILABLE_ADMIN_FLOOR_ROOMS,
  GET_AVAILABLE_ADMIN_FLOOR_ROOMS_FAIL,
  GET_AVAILABLE_ADMIN_FLOOR_ROOMS_SUCCESS,
  GET_AVAILABLE_ADMIN_ROOMS,
  GET_AVAILABLE_ADMIN_ROOMS_FAIL,
  GET_AVAILABLE_ADMIN_ROOMS_SUCCESS,
  GetAdminFloorRooms,
  GetAvailableAdminFloorRooms,
  GetAvailableAdminRooms,
} from "./index";

function* getAdminFloorRoomsSaga(action: GetAdminFloorRooms): any {
  try {
    const payload = yield call(Api, action, false, true);

    yield put({ type: GET_ADMIN_FLOOR_ROOMS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_ADMIN_FLOOR_ROOMS_FAIL, payload: e });
  }
}

function* getAvailableAdminRoomsSaga(action: GetAvailableAdminRooms): any {
  try {
    const payload = yield call(Api, action, false, true);

    yield put({ type: GET_AVAILABLE_ADMIN_ROOMS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_AVAILABLE_ADMIN_ROOMS_FAIL, payload: e });
  }
}

function* getAvailableAdminFloorRoomsSaga(action: GetAvailableAdminFloorRooms): any {
  try {
    const payload = yield call(Api, action, false, true);

    yield put({ type: GET_AVAILABLE_ADMIN_FLOOR_ROOMS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_AVAILABLE_ADMIN_FLOOR_ROOMS_FAIL, payload: e });
  }
}

function* addAdminFloorRoomSaga(action: AddAdminFloorRoom): any {
  try {
    const payload = yield call(Api, action, false, true);

    yield put({ type: ADD_ADMIN_FLOOR_ROOM_SUCCESS, payload });
  } catch (e) {
    yield put({ type: ADD_ADMIN_FLOOR_ROOM_FAIL, payload: e });
  }
}

function* editAdminFloorRoomSaga(action: EditAdminFloorRoom): any {
  try {
    const payload = yield call(Api, action, false, true);

    yield put({ type: EDIT_ADMIN_FLOOR_ROOM_SUCCESS, payload });
  } catch (e) {
    yield put({ type: EDIT_ADMIN_FLOOR_ROOM_FAIL, payload: e });
  }
}

function* deleteAdminFloorRoomSaga(action: DeleteAdminFloorRoom): any {
  try {
    const payload = yield call(Api, action, false, true);

    yield put({ type: DELETE_ADMIN_FLOOR_ROOM_SUCCESS, payload });
  } catch (e) {
    yield put({ type: DELETE_ADMIN_FLOOR_ROOM_FAIL, payload: e });
  }
}

export default [
  takeLatest(GET_ADMIN_FLOOR_ROOMS, getAdminFloorRoomsSaga),
  takeLatest(GET_AVAILABLE_ADMIN_ROOMS, getAvailableAdminRoomsSaga),
  takeLatest(GET_AVAILABLE_ADMIN_FLOOR_ROOMS, getAvailableAdminFloorRoomsSaga),
  takeLatest(ADD_ADMIN_FLOOR_ROOM, addAdminFloorRoomSaga),
  takeLatest(EDIT_ADMIN_FLOOR_ROOM, editAdminFloorRoomSaga),
  takeLatest(DELETE_ADMIN_FLOOR_ROOM, deleteAdminFloorRoomSaga),
];
