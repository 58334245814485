import {useDispatch} from "react-redux";
import {useTypedSelector} from "store/_legacy/Redux/store";
import moment from "moment";
import Api from "store/_legacy/Services/Api";
import FileSaver from "file-saver";
import DownloadExcelButton from "components/_legacy/Buttons/DownloadExcelButton";
import {exportUsers, setUserManagementData} from "../../../Store/userManagement";
import {UserDocumentStatus, userRole} from "../../../Store/userManagement/models";

export default function UserManagementExportCsv() {
  const dispatch = useDispatch();
  const { adminUserManagement } = useTypedSelector(state => state);
  const { allUsersFilters, filters } = adminUserManagement;
  const { search } = allUsersFilters;

  const statusArray = filters?.status && Object.keys(filters.status).map(key => key as UserDocumentStatus).filter(key => filters.status[key as UserDocumentStatus].value);
  const rolesArray = filters?.roles && Object.keys(filters.roles).map(key => key as userRole).filter(key => filters.roles[key as userRole].value);

  const onExportUsers = async () => {
    try {
      const action = dispatch(exportUsers({
        search: search ? search : undefined,
        city: filters.cities?.length > 0 ? filters.cities[0] : undefined,
        country: filters.countries?.length > 0 ? filters.countries[0] : undefined,
        status: statusArray?.length > 0 ? statusArray : undefined,
        role: rolesArray?.length > 0 ? rolesArray : undefined,
      }));
      dispatch(setUserManagementData({ successMessage: 'The file is being generated in background. Please do not refresh or close this page.' }));

      const payload = await Api(action);

      if (payload.status === 200 || payload.status === 201) {
        const csv = payload.data;
        const blob = new Blob([csv], {});

        return FileSaver.saveAs(blob, `users-${moment().format('YYYY-MM-DDTHH:mm:ss')}.xlsx`);
      }
    } catch (e) {
      console.log('error with exporting reservations');
    }
  };

  return (
    <DownloadExcelButton onSubmit={onExportUsers} />
  );
}
