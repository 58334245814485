import Api from 'store/_legacy/Services/Api';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  CREATE_GROUP,
  CREATE_GROUP_FAIL,
  CREATE_GROUP_SUCCESS,
  CreateGroup,
  DELETE_GROUP,
  DELETE_GROUP_FAIL,
  DELETE_GROUP_SUCCESS,
  DeleteGroup,
  EDIT_GROUP,
  EDIT_GROUP_FAIL,
  EDIT_GROUP_SUCCESS,
  EditGroup,
  GET_GROUPS,
  GET_GROUPS_FAIL,
  GET_GROUPS_SUCCESS,
  GetGroups,
  SEARCH_GROUPS_FROM_AD,
  SEARCH_GROUPS_FROM_AD_FAIL,
  SEARCH_GROUPS_FROM_AD_SUCCESS,
  SearchGroupsFromAd,
} from "./index";

function* getGroupsSaga(action: GetGroups): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: GET_GROUPS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_GROUPS_FAIL, payload: e });
  }
}

function* createGroupSaga(action: CreateGroup): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: CREATE_GROUP_SUCCESS, payload });
  } catch (e) {
    yield put({ type: CREATE_GROUP_FAIL, payload: e });
  }
}

function* editGroupSaga(action: EditGroup): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: EDIT_GROUP_SUCCESS, payload });
  } catch (e) {
    yield put({ type: EDIT_GROUP_FAIL, payload: e });
  }
}

function* deleteGroupSaga(action: DeleteGroup): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: DELETE_GROUP_SUCCESS, payload });
  } catch (e) {
    yield put({ type: DELETE_GROUP_FAIL, payload: e });
  }
}

function* searchGroupsFromAdSaga(action: SearchGroupsFromAd): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: SEARCH_GROUPS_FROM_AD_SUCCESS, payload });
  } catch (e) {
    yield put({ type: SEARCH_GROUPS_FROM_AD_FAIL, payload: e });
  }
}

export default [
  takeLatest(GET_GROUPS, getGroupsSaga),
  takeLatest(CREATE_GROUP, createGroupSaga),
  takeLatest(EDIT_GROUP, editGroupSaga),
  takeLatest(DELETE_GROUP, deleteGroupSaga),
  takeLatest(SEARCH_GROUPS_FROM_AD, searchGroupsFromAdSaga),
];
