import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useTypedSelector } from "store/_legacy/Redux/store";
import { Trans, t } from "@lingui/macro";
import Popup from "reactjs-popup";
import styles from "../../Locations/LocationForm/styles.module.scss";

import Box from "components/_legacy/Box";
import Button from "components/_legacy/Button";
import { AdminRoomStatusType, IAdminRoom, IAdminRoomAvailableService } from "../../../Store/roomsManagement/models";
import { addAdminFloorRoom, editAdminFloorRoom, roomStatusList } from "../../../Store/roomsManagement";
import EmailsList from "./EmailsList";
import Name from "./Name";
import Status from "./Status";
import Amenities from "./Amenities";
import { AttributeModel } from "Admin/Store/attributes/models";
import Capacity from "./Capacity";
import RoomServiceInput from "./RoomServiceInput";
import Text from "components/_legacy/Text";
import { RoomServiceType } from "App/Store/Meetings/meetingRooms/models";
import { isEmail } from "class-validator";
import Groups from "./Groups";
import Description from "./Description";
import { RoomImages } from "Admin/Pages/FloorMap/Map/DrawingDetails/MeetingDetails/room-images";
import ReservationDayLimit from "./ReservationDayLimit";

interface Props {
  actionState: 'add' | 'edit';
  open: boolean;
  onCancelAction: any;
  room?: IAdminRoom; // data used for edit state
}

export default function RoomManagementForm(props: Props) {
  const dispatch = useDispatch();
  const { locationId, floorId } = useParams<{ locationId: string ; floorId: string;}>();
  const { config, adminAttributes: { attributesRoom } } = useTypedSelector(state => state);
  const {
    room,
    actionState,
    open,
    onCancelAction,
  } = props;

  const actionTypeName = actionState === 'add' ? t`Add` : t`Edit`;

  // default values
  const roomEmail = room?.id || "";
  const roomName = room?.name || "";
  const roomStatus = room?.status ? room.status : roomStatusList[0].value;
  const roomAmenityIds = room?.amenities?.map(({ id }) => id) || [];
  const roomCapacity = room?.capacity || null;
  const roomAvailableServices = room?.availableServices;
  const roomDescription = room?.description;
  const roomGroups = room?.groups;
  const roomReservationDayLimit = room?.reservationDayLimit;
  
  const [roomEmailValue, setRoomEmailValue] = useState<string>(roomEmail);
  const [roomNameValue, setRoomNameValue] = useState<string>(roomName);
  const [roomCapacityValue, setRoomCapacityValue] = useState<number | null>(roomCapacity);
  const [roomStatusValue, setRoomStatusValue] = useState<AdminRoomStatusType>(roomStatus);
  const [roomAmenityIdsValue, setRoomAmenityIdsValue] = useState<string[]>(roomAmenityIds);
  const [roomAvailableServicesValue, setRoomAvailableServicesValue] = useState(roomAvailableServices);
  const [roomDescriptionValue, setRoomDescriptionValue] = useState(roomDescription);
  const [roomGroupsValue, setRoomGroupsValue] = useState(roomGroups);
  const [roomReservationDayLimitValue, setRoomReservationDayLimitValue] = useState(roomReservationDayLimit);
  const [roomNameError, setRoomNameError] = useState(false);
  const [roomEmailError, setRoomEmailError] = useState(false);
  const [roomStatusError, setRoomStatusError] = useState(false);
  const [roomServiceErrors, setRoomServiceErrors] = useState<Partial<Record<RoomServiceType, string>>>({});

  // update default data after open modal
  useEffect(() => {
    if (open) {
      setRoomEmailValue(roomEmail);
      setRoomNameValue(roomName);
      setRoomCapacityValue(roomCapacity);
      setRoomStatusValue(roomStatus);
      setRoomAmenityIdsValue(roomAmenityIds);
      setRoomAvailableServicesValue(roomAvailableServices);
      setRoomDescriptionValue(roomDescription);
      setRoomGroupsValue(roomGroups);
      setRoomReservationDayLimitValue(roomReservationDayLimit);
    }
  }, [open]);

  const roomNameErrorClass = roomNameError ? styles.formErrorShow : '';
  const roomEmailErrorClass = roomEmailError ? styles.formErrorShow : '';
  const roomStatusErrorClass = roomStatusError ? styles.formErrorShow : '';

  const validateRoomServiceType = (): Partial<Record<RoomServiceType, string>> => {
    const roomServicesErrors: Partial<Record<RoomServiceType, string>> = {};

    if (roomAvailableServicesValue?.length) {
      for (const { type, emails } of roomAvailableServicesValue) {
        const email = emails?.[0];
        
        if (!isEmail(email)) {
          roomServicesErrors[type] = t`This should be a valid email`;
        }
      }
    }

    return roomServicesErrors;
  };

  const validateFields = () => {
    let validate = true;

    const roomServicesErrors = validateRoomServiceType();
    const hasRoomServiceTypeErrors = !!Object.keys(roomServicesErrors)?.length;

    if ((!roomEmailValue?.length && actionState === 'add') || !roomNameValue?.length || !roomStatusValue?.length || hasRoomServiceTypeErrors) {
      validate = false;
    }

    // set errors messages
    if (!roomEmailValue?.length && actionState === 'add') {
      setRoomEmailError(true);
    }

    if (!roomNameValue?.length) {
      setRoomNameError(true);
    }

    if (!roomStatusValue?.length) {
      setRoomStatusError(true);
    }

    if (hasRoomServiceTypeErrors) {
      setRoomServiceErrors(roomServicesErrors);
    }

    return validate;
  };

  const onRoomFormSubmit = () => {
    if (!validateFields()) {
      return;
    }

    // submit logic
    if (actionState === 'add') {
      dispatch(addAdminFloorRoom({
        floorId,
        locationId,
        data: {
          roomId: roomEmailValue,
          name: roomNameValue,
          status: roomStatusValue,
          amenityIds: roomAmenityIdsValue,
          capacity: roomCapacityValue,
          availableServices: roomAvailableServicesValue,
          description: roomDescriptionValue,
          groupIds: roomGroupsValue?.map(({ id }) => id),
          reservationDayLimit: roomReservationDayLimitValue || 180,
        }}));
    }
    if (actionState === 'edit') {
      if (room) {
        dispatch(editAdminFloorRoom({
          locationId,
          floorId,
          roomId: room.id,
          data: {
            name: roomNameValue,
            status: roomStatusValue,
            amenityIds: roomAmenityIdsValue,
            capacity: roomCapacityValue,
            availableServices: roomAvailableServicesValue,
            description: roomDescriptionValue,
            groupIds: roomGroupsValue?.map(({ id }) => id),
            reservationDayLimit: roomReservationDayLimitValue || undefined,
          },
        }));
      }
    }
    onCancelAction();
  };

  const handleAmenitiesChange = (value: boolean, attribute: AttributeModel): void => {
    if (value) {
      setRoomAmenityIdsValue([...roomAmenityIdsValue, attribute.id]);
    } else {
      setRoomAmenityIdsValue(roomAmenityIdsValue.filter((id) => id !== attribute.id));
    }
  };

  const handleRoomServiceInputChange = (serviceType: RoomServiceType, value?: IAdminRoomAvailableService): void => {
    const current = roomAvailableServicesValue?.find(({ type }) => value?.type === type);

    if (value) {
      if (current) {
        const newRoomAvailableServicesValue = roomAvailableServicesValue?.filter(({ type }) => serviceType !== type);

        setRoomAvailableServicesValue([...(newRoomAvailableServicesValue || []), value]);
      } else {
        setRoomAvailableServicesValue([...(roomAvailableServicesValue || []), value]);
      }
    } else {
      setRoomAvailableServicesValue(roomAvailableServicesValue?.filter(({ type }) => serviceType !== type));
    }
  };

  const submitButtonDisabled = (!roomEmailValue?.length && actionState === 'add') || !roomNameValue?.length || !roomStatusValue?.length;
  const selectedRoomAmenities = roomAmenityIdsValue.reduce(((carry, id) => ({ ...carry, [id]: id })), {} as Record<string, string>);

  return (
    <Popup
      className={'modal'}
      closeOnDocumentClick
      modal
      nested
      onClose={onCancelAction}
      open={open}
    >
      <div
        className="modal-inner"
        style={{ maxWidth: 512, width: 512 }}
      >
        <div className="modal-header">
          <h2>
            {actionTypeName} room
          </h2>

          <svg className="modal-header__close" height="30px" onClick={() => onCancelAction()} viewBox="0 0 30 30" width="30px">
            <g fill="none" fillRule="evenodd" id="Booking" stroke="none" strokeWidth="1">
              <g id="Booking-savedall" transform="translate(-875.000000, -132.000000)">
                <g id="Group-8" transform="translate(515.000000, 112.000000)">
                  <g id="icons/close" transform="translate(360.000000, 20.000000)">
                    <rect fill={config.theme.primaryLight} height="30" id="Rectangle" rx="8" width="30" x="0" y="0"></rect>
                    <path d="M20.704633,9.29536704 C21.0984557,9.68918977 21.0984557,10.3277026 20.704633,10.7215253 L16.4261582,15 L20.704633,19.2784747 C21.0984557,19.6722974 21.0984557,20.3108102 20.704633,20.704633 C20.3108102,21.0984557 19.6722974,21.0984557 19.2784747,20.704633 L15,16.4261582 L10.7215253,20.704633 C10.3277026,21.0984557 9.68918977,21.0984557 9.29536704,20.704633 C8.90154432,20.3108102 8.90154432,19.6722974 9.29536704,19.2784747 L13.5738418,15 L9.29536704,10.7215253 C8.90154432,10.3277026 8.90154432,9.68918977 9.29536704,9.29536704 C9.68918977,8.90154432 10.3277026,8.90154432 10.7215253,9.29536704 L15,13.5738418 L19.2784747,9.29536704 C19.6722974,8.90154432 20.3108102,8.90154432 20.704633,9.29536704 Z" fill={config.theme.primary}></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div
          className={`modal-inner-content ${styles.modalInnerContent}`}
          style={{ maxHeight: "74vh", overflowX: "hidden", overflowY: "auto", paddingRight: 4 }}
        >
          {/* unique email user as id only for adding new room */}
          {actionState == 'add' &&
            <Box className={''} marginBottom={15}>
              <EmailsList
                errorClass={roomEmailErrorClass}
                setErrorClass={setRoomEmailError}
                setSelectedValue={setRoomEmailValue}
              />
            </Box>
          }
          <Box className={''} marginBottom={15}>
            <Name
              errorClass={roomNameErrorClass}
              selectedValue={roomNameValue}
              setErrorClass={setRoomNameError}
              setSelectedValue={setRoomNameValue}
            />
          </Box>
          <Box className={''} marginBottom={15}>
            <Status
              errorClass={roomStatusErrorClass}
              selectedValue={roomStatusValue}
              setErrorClass={setRoomStatusError}
              setSelectedValue={setRoomStatusValue}
            />
          </Box>
          <Box className={''} marginBottom={15}>
            <Groups onChange={setRoomGroupsValue} value={roomGroupsValue} />
          </Box>
          <Box className="" marginBottom={15}>
            <Capacity setValue={setRoomCapacityValue} value={roomCapacityValue} />
          </Box>
          <Box className="" marginBottom={15}>
            <ReservationDayLimit setValue={setRoomReservationDayLimitValue} value={roomReservationDayLimitValue} />
          </Box>
          <Box className="" marginBottom={15}>
            <Description onChange={setRoomDescriptionValue} value={roomDescriptionValue} />
          </Box>
          <Box className={''} marginBottom={15}>
          <Box marginBottom={4}>
              <Text color="black" size="md" weight="regular">
                <Trans>Amenities:</Trans>
              </Text>
            </Box>
            <Amenities attributes={attributesRoom} onUpdate={handleAmenitiesChange} selected={selectedRoomAmenities} />
          </Box>
          <Box>
            <Box marginBottom={4}>
              <Text color="black" size="md" weight="regular">
                <Trans>Additional services:</Trans>
              </Text>
            </Box>
            <RoomServiceInput
              error={roomServiceErrors.catering}
              label={t`Catering`}
              onChange={(value) => handleRoomServiceInputChange(RoomServiceType.CATERING, value)}
              type={RoomServiceType.CATERING}
              value={roomAvailableServicesValue?.find(({ type }) => type === RoomServiceType.CATERING)}
            />
            <RoomServiceInput
              error={roomServiceErrors["it-support"]}
              label={t`IT Support`}
              onChange={(value) => handleRoomServiceInputChange(RoomServiceType.IT_SUPPORT, value)}
              type={RoomServiceType.IT_SUPPORT}
              value={roomAvailableServicesValue?.find(({ type }) => type === RoomServiceType.IT_SUPPORT)}
            />
          </Box>
          {actionState !== "add" && room ? (
            <Box marginTop={16}>
              <RoomImages floorId={floorId} locationId={locationId} roomId={room.id} />
            </Box>
          ): undefined}
        </div>
        <Box display="flex" justifyContent="end" marginTop={8}>
          <Button
            aria-label={t`cancel room edition`}
            name={t`cancel room edition`}
            onClick={() => onCancelAction()}
            size="sm"
            type="clear"
          >
            <Trans>Cancel</Trans>
          </Button>
          <Button
            aria-label={t`confirm room edition`}
            disabledStyle={submitButtonDisabled}
            name={t`confirm room edition`}
            onClick={() => onRoomFormSubmit()}
            size="sm"
          >
            {actionTypeName} room
          </Button>
        </Box>
      </div>
    </Popup >
  );
}
