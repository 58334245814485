import {t} from "@lingui/macro";
import {
  ActionPayload,
  BaseErrorResponse,
  BaseResponse,
} from "store/_legacy/Models/ReduxModels";
import {
  CreateGroupRequest,
  CreateGroupResponse,
  DeleteGroupRequest,
  DeleteGroupResponse,
  EditGroupModel,
  EditGroupRequest,
  EditGroupResponse,
  GetGroupsRequest,
  GetGroupsResponse,
  IFiltersGroups,
  IGroup,
  SearchGroupsFromAdRequest,
  SearchGroupsFromAdResponse,
  SetGroupsDataRequest,
} from "./models";

const SET_GROUPS_DATA = 'SET_GROUPS_DATA';

export const GET_GROUPS = 'GET_GROUPS';
export const GET_GROUPS_FAIL = 'GET_GROUPS_FAIL';
export const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS';

export const CREATE_GROUP = 'CREATE_GROUP';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
export const CREATE_GROUP_FAIL = 'CREATE_GROUP_FAIL';

export const EDIT_GROUP = 'EDIT_GROUP';
export const EDIT_GROUP_SUCCESS = 'EDIT_GROUP_SUCCESS';
export const EDIT_GROUP_FAIL = 'EDIT_GROUP_FAIL';

export const DELETE_GROUP = 'DELETE_GROUP';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_FAIL = 'DELETE_GROUP_FAIL';

export const SEARCH_GROUPS_FROM_AD = 'SEARCH_GROUPS_FROM_AD';
export const SEARCH_GROUPS_FROM_AD_SUCCESS = 'SEARCH_GROUPS_FROM_AD_SUCCESS';
export const SEARCH_GROUPS_FROM_AD_FAIL = 'SEARCH_GROUPS_FROM_AD_FAIL';

interface SetGroupsData {
  type: typeof SET_GROUPS_DATA;
  payload: SetGroupsDataRequest;
}

// get list of all groups
export interface GetGroups {
  type: typeof GET_GROUPS;
  payload: ActionPayload<GetGroupsRequest>;
}
interface GetGroupsFail {
  type: typeof GET_GROUPS_FAIL;
  payload: BaseErrorResponse;
}
interface GetGroupsSuccess {
  type: typeof GET_GROUPS_SUCCESS;
  payload: BaseResponse<GetGroupsResponse>;
}

// create new group
export interface CreateGroup {
  type: typeof CREATE_GROUP;
  payload: ActionPayload<CreateGroupRequest>;
}
interface CreateGroupSuccess {
  type: typeof CREATE_GROUP_SUCCESS;
  payload: BaseResponse<CreateGroupResponse>;
}
interface CreateGroupFail {
  type: typeof CREATE_GROUP_FAIL;
  payload: BaseErrorResponse;
}

// edit group name
export interface EditGroup {
  type: typeof EDIT_GROUP;
  payload: ActionPayload<EditGroupModel>;
}

interface EditGroupSuccess {
  type: typeof EDIT_GROUP_SUCCESS;
  payload: BaseResponse<EditGroupResponse>;
}

interface EditGroupFail {
  type: typeof EDIT_GROUP_FAIL;
  payload: BaseErrorResponse;
}

// delete group
export interface DeleteGroup {
  type: typeof DELETE_GROUP;
  payload: ActionPayload<DeleteGroupRequest>
}

interface DeleteGroupSuccess {
  type: typeof DELETE_GROUP_SUCCESS;
  payload: BaseResponse<DeleteGroupResponse>;
}

// get list of AD groups to add
export interface SearchGroupsFromAd {
  type: typeof SEARCH_GROUPS_FROM_AD;
  payload: ActionPayload<SearchGroupsFromAdRequest>;
}
export interface SearchGroupsFromAdFail {
  type: typeof SEARCH_GROUPS_FROM_AD_FAIL;
  payload: BaseErrorResponse;
}
export interface SearchGroupsFromAdSuccess {
  type: typeof SEARCH_GROUPS_FROM_AD_SUCCESS;
  payload: BaseResponse<SearchGroupsFromAdResponse>;
}

interface DeleteGroupFail {
  type: typeof DELETE_GROUP_FAIL;
  payload: BaseErrorResponse;
}

type Actions =
  | SetGroupsData
  | GetGroups
  | GetGroupsFail
  | GetGroupsSuccess
  | CreateGroup
  | CreateGroupSuccess
  | CreateGroupFail
  | EditGroup
  | EditGroupSuccess
  | EditGroupFail
  | DeleteGroup
  | DeleteGroupSuccess
  | DeleteGroupFail

export interface State {
  error: string;
  successMessage: string,
  loading: boolean;
  totalPages: number;
  limit: number;
  groupsList: IGroup[];
  filters: IFiltersGroups;
}

export const initFiltersData: IFiltersGroups = {
  search: '',
};
const initialState: State = {
  error: '',
  successMessage: '',
  loading: false,
  totalPages: 0,
  limit: 10,
  groupsList: [],
  filters: initFiltersData,
};

export default function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case GET_GROUPS:
      return {
        ...state,
        error: '',
        loading: true,
        groupsList: [],
      };
    case GET_GROUPS_FAIL:
      return {
        ...state,
        error: t`There was an error loading groups.`,
        loading: false,
      };
    case GET_GROUPS_SUCCESS: {
      return {
        ...state,
        error: '',
        loading: false,
        groupsList: action.payload.data.result.data.groups,
        totalPages: action.payload.data.result.data.totalPages,
      };
    }

    case CREATE_GROUP_FAIL:
      return {
        ...state,
        error: t`There was an error creating group.`,
      };
    case CREATE_GROUP_SUCCESS: {
      const group = action.payload.data.result.data;

      return {
        ...state,
        error: '',
        successMessage: 'Group was created',
        groupsList: [group, ...state.groupsList],
      };
    }

    case EDIT_GROUP_SUCCESS: {
      const group = action.payload.data.result.data;
      const newGroupsList = [...state.groupsList];
      const index = newGroupsList.findIndex(q => q.id === group.id);

      newGroupsList[index] = {
        ...newGroupsList[index],
        ...group,
      };

      return {
        ...state,
        error: '',
        successMessage: 'Group was edited',
        groupsList: newGroupsList,
      };
    }
    case EDIT_GROUP_FAIL:
      return {
        ...state,
        error: t`There was an error editing group.`,
      };

    case DELETE_GROUP_SUCCESS: {

      const { id } = action.payload.data.result.data;
      const newGroupsList = [...state.groupsList].filter(q => q.id !== id);

      return {
        ...state,
        successMessage: 'Group was deleted',
        groupsList: newGroupsList,
      };
    }
    case DELETE_GROUP_FAIL:
      return {
        ...state,
        error: t`There was an error deleting group.`,
      };

    case SET_GROUPS_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
}

// Actions
export function setGroupsData(data: SetGroupsDataRequest): SetGroupsData {
  return {
    type: SET_GROUPS_DATA,
    payload: data,
  };
}

export function getGroups(data: GetGroupsRequest): GetGroups {
  let url = `/api/groups?page=${data.page}&limit=${data.limit}`;

  if (data.search) {
    url += `&search=${data.search}`;
  }

  return {
    type: GET_GROUPS,
    payload: {
      request: {
        method: 'GET',
        url,
      },
    },
  };
}

export function createGroup(data: CreateGroupRequest): CreateGroup {
  return {
    type: CREATE_GROUP,
    payload: {
      request: {
        method: 'POST',
        url: `/api/groups`,
        data,
      },
    },
  };
}

export function editGroup(data: EditGroupRequest): EditGroup {
  return {
    type: EDIT_GROUP,
    payload: {
      request: {
        method: 'PUT',
        url: `/api/groups/${data.groupId}`,
        data: data.group,
      },
    },
  };
}

export function deleteGroup(data: DeleteGroupRequest): DeleteGroup {
  return {
    type: DELETE_GROUP,
    payload: {
      request: {
        method: 'DELETE',
        url: `/api/groups/${data.groupId}`,
      },
    },
  };
}

export function searchGroupsFromAd(data: SearchGroupsFromAdRequest): SearchGroupsFromAd {
  let url = `/api/groups/search-ad?limit=${data.limit}`;

  if (data.search) {
    url += `&search=${data.search}`;
  }

  return {
    type: SEARCH_GROUPS_FROM_AD,
    payload: {
      request: {
        method: 'GET',
        url,
      },
    },
  };
}
