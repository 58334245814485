import { t } from "@lingui/macro";
import { KeyboardArrowDown } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress, Dialog, IconButton, Popover, Skeleton, Switch, Tooltip, Typography, TypographyProps, useTheme } from "@mui/material";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { CalendarViewSelection, CateringDialog, CateringDialogProps, Container, DailyCalendarView, DailyCalendarViewItem, DailyCalendarViewProps, DatePickerCalendar, FromToTimeInput, FromToTimeInputProps, InteractiveTypography, LinkButton, PageHeader, ReservationResourcesList, ReservationResourcesListItem, Tab, TabPanel, Tabs, TimeZoneDisclaimer, ToastProvider, WeeklyCalendarView, WeeklyCalendarViewItem, WeeklyCalendarViewProps, ceiledCalendarViewDate, useToast } from "components";
import { TogetherDateAndTime, TogetherRoom } from "components/icons";
import { addDays, addWeeks, differenceInDays, differenceInMinutes, endOfDay, isAfter, isEqual, isToday, parse, set, startOfDay } from "date-fns";
import { useDefaultTimeZone } from "hooks";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Attendee, GetRoomScheduleInput, ReservationServiceRequestSetting, ReservationServiceType, ReservationStatus, floorsSlice, isSuccessAPIResponse, parseResponseError, selectCurrentFloor, selectCurrentRoom, useGetRoomByIdQuery, useGetRoomImagesQuery, useLazyGetRoomScheduleQuery, useUpdateReservationEntryMutation } from "store";
import { Reservation, reservationsSlice, roomsSlice, selectCurrentReservation, useGetMyReservationByIdQuery, useGetRoomScheduleQuery, useReservationsAvailabilityQuery } from "store";
import { format, formatInTimeZone, toDate, utcToZonedTime } from "date-fns-tz";
import { getNowInTimeZone, getZeroDayInTimeZone, isSameDayInTimeZone, localZeroDay, rruleToRecurrenceOptions, safeParseISO, shiftTimeZone, unshiftTimeZone } from "utils";
import { MarkRequired } from "ts-essentials";

const EditReservationEntryRouteListView: React.FC<{ reservation: Reservation }> = (props) => {
  const { reservation } = props;
  const { room, floor } = reservation;
  const locationId = floor?.location?.id || "";
  const floorId = floor?.id || "";
  const roomId = room?.id || "";
  const selectedItem: ReservationResourcesListItem = {
    locationId,
    floorId,
    id: roomId,
    name: room?.name || "",
    capacity: room?.capacity,
    floorName: floor?.name,
    locationAddress: floor?.location?.address,
  };
  const currentReservation = useSelector(selectCurrentReservation);
  const currentFloor = useSelector(selectCurrentFloor);
  const [page, setPage] = useState(1);
  const getRoomImagesQuery = useGetRoomImagesQuery({ locationId, floorId, roomId, limit: -1 });
  const [items, setItems] = useState<ReservationResourcesListItem[]>(selectedItem ? [selectedItem] : []);
  const { data: roomImagesResponse, isLoading: imagesAreLoading, isFetching: imagesAreFetching } = getRoomImagesQuery;
  const { items: selectedItemImages = [] } = roomImagesResponse?.result?.data || {};

  useEffect(() => {
    setPage(1);
    setItems(selectedItem ? [{ ...selectedItem, images: selectedItemImages }] : []);
  }, [currentFloor?.id]);

  useEffect(() => {
    setItems((items) => items.map((item) => {
      if (item.id === selectedItem.id) {
        return { ...item, images: selectedItemImages };
      }

      return item;
    }));
  }, [selectedItemImages]);
  
  return (
    <ReservationResourcesList
      height={710}
      isFirstLoad={page === 1}
      isLoading={imagesAreLoading || imagesAreFetching}
      items={items}
      selectedItemId={currentReservation?.roomId}
    />
  );
};

const EditReservationEntryRouteCalendarView: React.FC<{reservation: Reservation }> = (props) => {
  const { reservation } = props;
  const dispatch = useDispatch();
  const { entryId } = useParams<{ entryId: string }>();
  const currentFloor = useSelector(selectCurrentFloor);
  const availabilityQuery = useReservationsAvailabilityQuery();
  const [defaultTimeZone] = useDefaultTimeZone();
  const timeZone = reservation?.schedule?.timeZone?.split(";")?.[0] || defaultTimeZone;
  const zeroDay = getZeroDayInTimeZone(timeZone);
  const selectedDay = parse(availabilityQuery.startDate, "yyyy-MM-dd", zeroDay);
  const locationId = reservation.floor?.location?.id || "";
  const currentReservation = useSelector(selectCurrentReservation);
  const getRoomScheduleInput: GetRoomScheduleInput = {
    locationId,
    roomId: currentReservation?.roomId || "",
    floorId: currentFloor?.id || reservation?.floor?.id || "",
    startDate: startOfDay(selectedDay),
    endDate: endOfDay(selectedDay),
  };
  const [triggerGetRoomScheduleQuery, getRoomScheduleQuery] = useLazyGetRoomScheduleQuery();
  const [items, setItems] = useState<DailyCalendarViewItem[]>([]);
  const { data: roomsSchedulesResponse, isLoading: roomsSchedulesAreLoading, isFetching: roomsSchedulesAreFetching } = getRoomScheduleQuery;
  const roomSchedule = roomsSchedulesResponse?.result?.data;

  useEffect(() => {
    const entry = currentReservation?.entries?.find(({ id }) => id === entryId);

    if (entry?.startDate && !isSameDayInTimeZone(selectedDay, new Date(entry.startDate), defaultTimeZone)) {
      const startDate = format(new Date(entry.startDate), "yyyy-MM-dd", { timeZone });
      const endDate = startDate;

      setItems([]);
      dispatch(reservationsSlice.actions.setAvailabilityQuery({ startDate, endDate }));
    }
  }, []);

  useEffect(() => {
    setItems([]);
    triggerGetRoomScheduleQuery(getRoomScheduleInput, true);
  }, [availabilityQuery.startDate, currentFloor?.id]);

  useEffect(() => {
    if (roomSchedule) {
      const { roomId, roomName, floorId, roomCapacity, schedules, roomReservationDayLimit } = roomSchedule;
      const item: DailyCalendarViewItem = {
        floorId,
        id: roomId,
        name: roomName || roomId,
        capacity: roomCapacity,
        schedules: [],
        locationId: currentReservation?.locationId,
        reservationDayLimit: roomReservationDayLimit,
      };

      for (const schedule of schedules) {
        const { startDate: startDateString, endDate: endDateString, summary, organizer } = schedule;
        const startDate = utcToZonedTime(toDate(startDateString), timeZone);
        const endDate = utcToZonedTime(toDate(endDateString), timeZone);
        const isCurrentReservationSchedule = reservation.room
          && reservation.room.id === roomSchedule.roomId
          && reservation?.schedule?.entries?.some((entry) => {
            return isEqual(startDate, new Date(entry.startDate)) && isEqual(endDate, new Date(entry.endDate));
          });

        if (!isCurrentReservationSchedule) {
          item.schedules?.push({ startDate, endDate, summary, organizer });
        }
      }

      setItems([item]);
    }
  }, [JSON.stringify(roomSchedule)]);

  useEffect(() => {
    const { schedules = [] } = roomSchedule || {};

    dispatch(roomsSlice.actions.setCurrent({ schedules }));
  }, [JSON.stringify(roomSchedule), currentReservation?.roomId]);

  const handleSelectedDayChange = (selectedDay: Date) => {
    const startDate = format(selectedDay, "yyyy-MM-dd", { timeZone });
    const endDate = startDate;
    const maxDate = roomSchedule ? addDays(startOfDay(getNowInTimeZone(timeZone)), roomSchedule.roomReservationDayLimit || 180) : undefined;

    if (maxDate && (isSameDayInTimeZone(selectedDay, addDays(maxDate, 1), defaultTimeZone) || isAfter(selectedDay, maxDate))) {
      dispatch(roomsSlice.actions.resetCurrent(undefined));
      dispatch(reservationsSlice.actions.setCurrent({ startDate: undefined, endDate: undefined, roomId: undefined, isAllDay: undefined }));
    } else {
      dispatch(roomsSlice.actions.setCurrent({ schedules: undefined }));
      dispatch(reservationsSlice.actions.setCurrent({ startDate: undefined, endDate: undefined, isAllDay: undefined }));   
    }

    setItems([]);
    dispatch(reservationsSlice.actions.setAvailabilityQuery({ startDate, endDate }));
  };

  const handleDailyCalendarViewChange: DailyCalendarViewProps["onChange"] = (selection) => {
    const entries = currentReservation?.entries;

    if (entries) {
      if (selection) {
        const { start, end } = selection;
        const startDate = start ? shiftTimeZone(start, defaultTimeZone) : undefined;
        const endDate = end ? shiftTimeZone(end, defaultTimeZone) : undefined;
  
        dispatch(reservationsSlice.actions.setCurrent({
          entries: [
            ...(entries?.filter(({ id }) => id !== entryId) || []),
            { id: entryId, startDate: startDate?.toISOString(), endDate: endDate?.toISOString() },
          ],
        }));
      } else {
        dispatch(reservationsSlice.actions.setCurrent({
          entries: [
            ...(entries?.filter(({ id }) => id !== entryId) || []),
            { id: entryId, startDate: undefined, endDate: undefined },
          ],
        }));
      }
    }
  };

  let defaultSelection: CalendarViewSelection | undefined = undefined;

  if (currentReservation) {
    const { roomId } = currentReservation;
    const entry = currentReservation.entries?.find(({ id }) => id === entryId);

    if (roomId) {
      defaultSelection = {
        id: roomId,
        start: entry?.startDate ? unshiftTimeZone(new Date(entry.startDate), defaultTimeZone) : undefined,
        end: entry?.endDate ? unshiftTimeZone(new Date(entry.endDate), defaultTimeZone) : undefined,
      };
    }
  }

  const blocks = currentReservation?.entries
    ?.filter(({ id, startDate, endDate }) => id !== entryId && startDate && endDate)
    ?.map<[Date, Date]>(({ startDate, endDate }) => [new Date(startDate as string), new Date(endDate as string)]);

  return (
    <DailyCalendarView
      blocks={blocks}
      defaultSelection={defaultSelection}
      height={710}
      isFirstLoad={items.length === 0}
      isLoading={roomsSchedulesAreLoading || roomsSchedulesAreFetching}
      items={items}
      minDate={getNowInTimeZone(defaultTimeZone)}
      onChange={handleDailyCalendarViewChange}
      onSelectedDayChange={handleSelectedDayChange}
      selectedDay={selectedDay}
      timeZone={defaultTimeZone}
    />
  );
};

const EditReservationEntryRouteDetailsRoomSchedule: React.FC<{ reservation: Reservation }> = (props) => {
  const { reservation } = props;
  const { schedule, floor } = reservation;
  const dispatch = useDispatch();
  const { entryId } = useParams<{ entryId: string }>();
  const currentReservation = useSelector(selectCurrentReservation);
  const [defaultTimeZone] = useDefaultTimeZone();
  const { startDate } = useReservationsAvailabilityQuery();
  const [interval, setInterval] = useState<[Date, Date]>();
  const [selectedDay, setSelectedDay] = useState<Date>(new Date(startDate));
  const locationId = floor?.location?.id || "";
  const floorId = floor?.id || "";
  const getRoomByIdQuery = useGetRoomByIdQuery({
    locationId: locationId || currentReservation?.locationId || "",
    floorId: floorId || currentReservation?.floorId || "",
    roomId: currentReservation?.roomId || "",
    include: ["extra.mapDrawing"],
  });
  const { data: roomResponse, isLoading: roomIsLoading, isFetching: roomIsFetching } = getRoomByIdQuery;
  const room = roomResponse?.result?.data;
  const timeZone = schedule?.timeZone?.split(";")?.[0] || defaultTimeZone;
  const getRoomScheduleQuery = useGetRoomScheduleQuery(
    {
      locationId,
      floorId: currentReservation?.floorId || floor?.id || "",
      roomId: currentReservation?.roomId || reservation?.room?.id || "",
      startDate: interval?.[0] || getNowInTimeZone(timeZone),
      endDate: interval?.[1] || getNowInTimeZone(timeZone),
    },
    { skip: !interval },
    );
  const { data: roomScheduleResponse, isLoading: roomScheduleIsLoading, isFetching: roomScheduleIsFetching } = getRoomScheduleQuery;
  const roomSchedule = roomScheduleResponse?.result?.data;
  const maxDate = addDays(startOfDay(getNowInTimeZone(timeZone)), room?.reservationDayLimit || 1);

  useEffect(() => {
    if (roomSchedule) {
      const { schedules } = roomSchedule;

      dispatch(roomsSlice.actions.setCurrent({ schedules }));
    }
  }, [roomSchedule]);

  const entry = currentReservation?.entries?.find(({ id }) => id === entryId);

  useEffect(() => {
    if (entry?.startDate) {
      setSelectedDay(parse(formatInTimeZone(entry.startDate, defaultTimeZone, "yyyy-MM-dd"), "yyyy-MM-dd", localZeroDay));
    }
  }, [JSON.stringify(entry)]);

  const handleWeeklyCalendarViewChange: WeeklyCalendarViewProps["onChange"] = (selection) => {
    const entries = currentReservation?.entries;

    if (entries) {
      if (selection) {
        const { start, end } = selection;
        const startDate = start ? shiftTimeZone(start, defaultTimeZone) : undefined;
        const endDate = end ? shiftTimeZone(end, defaultTimeZone) : undefined;
  
        dispatch(reservationsSlice.actions.setCurrent({
          entries: [
            ...(entries?.filter(({ id }) => id !== entryId) || []),
            { id: entryId, startDate: startDate?.toISOString(), endDate: endDate?.toISOString() },
          ],
        }));
      } else {
        dispatch(reservationsSlice.actions.setCurrent({
          entries: [
            ...(entries?.filter(({ id }) => id !== entryId) || []),
            { id: entryId, startDate: undefined, endDate: undefined },
          ],
        }));
      }
    }
  };

  let item: WeeklyCalendarViewItem | undefined = undefined;
  
  if (roomSchedule) {
    const { schedules } = roomSchedule;

    item = {
      id: roomSchedule.roomId,
      schedules: [],
    };

    for (const { startDate: startDateString, endDate: endDateString, organizer, summary } of schedules) {
      const startDate = new Date(startDateString);
      const endDate = new Date(endDateString);
      const skip = reservation.room?.id === roomSchedule.roomId &&
        schedule?.entries?.some((entry) => isEqual(startDate, new Date(entry.startDate)) && isEqual(endDate, new Date(entry.endDate)));

      if (!skip) {
        item.schedules?.push({ startDate, endDate, organizer, summary });
      }
    }
  }

  let defaultSelection: CalendarViewSelection | undefined = undefined;

  if (currentReservation) {
    const { roomId, entries } = currentReservation;
    const entry = entries?.find(({ id }) => id === entryId);
    const { startDate, endDate } = entry || {};

    if (roomId && startDate) {
      defaultSelection = {
        id: roomId,
        start: startDate ? unshiftTimeZone(new Date(startDate), defaultTimeZone) : undefined,
        end: endDate ? unshiftTimeZone(new Date(endDate), defaultTimeZone) : undefined,
      };
    }
  }

  const blocks = currentReservation?.entries
    ?.filter(({ id, startDate, endDate }) => id !== entryId && startDate && endDate)
    ?.map<[Date, Date]>(({ startDate, endDate }) => [new Date(startDate as string), new Date(endDate as string)]);
    
  return (
    <WeeklyCalendarView
      blocks={blocks}
      defaultSelection={defaultSelection}
      height={362}
      isLoading={roomScheduleIsLoading || roomScheduleIsFetching || roomIsLoading || roomIsFetching}
      item={item}
      maxDate={maxDate}
      minDate={getNowInTimeZone(defaultTimeZone)}
      onChange={handleWeeklyCalendarViewChange}
      onStartDayChange={(interval) => setInterval(interval)}
      selectedDay={selectedDay}
      timeZone={defaultTimeZone}
    />
  );
};

const EditReservationEntryRouteDetailsDateAndTime: React.FC<{ timeZone: string }> = (props) => {
  const { timeZone } = props;
  const dispatch = useDispatch();
  const { startDate } = useReservationsAvailabilityQuery();
  const now = getNowInTimeZone(timeZone);
  const selectedDay = parse(startDate, "yyyy-MM-dd", now);

  const handleDatePickerCalendarChange = (selectedDay: Date) => {
    const startDate = format(selectedDay, "yyyy-MM-dd", { timeZone });
    const endDate = startDate;

    dispatch(reservationsSlice.actions.setAvailabilityQuery({ startDate, endDate }));
  };

  return (
    <Box width="100%">
      <Box marginBottom={2}>
        <DatePickerCalendar date={selectedDay} minDate={now} onChange={handleDatePickerCalendarChange} />
      </Box>
    </Box>
  );
};

const EditReservationEntryRouteDetails: React.FC<{ reservation: Reservation, currentTab: number, onUpdated?: () => void }> = (props) => {
  const { reservation, currentTab, onUpdated } = props;
  const dispatch = useDispatch();
  const theme = useTheme();
  const { entryId } = useParams<{ entryId: string }>();
  const availabilityQuery = useReservationsAvailabilityQuery();
  const [expanded, setExpanded] = useState(true);
  const [loading, setLoading] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [updateTooltip, setUpdateTooltip] = useState<string>();
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLButtonElement>();
  const currentReservation = useSelector(selectCurrentReservation);
  const currentRoom = useSelector(selectCurrentRoom);
  const [defaultTimeZone, defaultWindowsTimeZone] = useDefaultTimeZone();
  const [updateReservationEntry] = useUpdateReservationEntryMutation();
  const toast = useToast();
  const [calendarAnchorEl, setCalendarAnchorEl] = useState<HTMLSpanElement>();
  const timeZone = reservation?.schedule?.timeZone?.split(";")?.[0] || defaultTimeZone;
  const windowsTimeZone = reservation?.schedule?.timeZone?.split(";")?.[0]
    ? reservation?.schedule?.timeZone?.split(";")?.[1] || defaultWindowsTimeZone
    : defaultWindowsTimeZone;
  const zeroDay = getZeroDayInTimeZone(timeZone);
  const [selectedDay, setSelectedDay] = useState(parse(availabilityQuery.startDate, "yyyy-MM-dd", zeroDay));
  const locationId = reservation?.floor?.location?.id || "";
  const floorId = reservation?.floor?.id || "";
  const getRoomByIdQuery = useGetRoomByIdQuery({
    locationId: currentReservation?.locationId || locationId || "",
    floorId: currentReservation?.floorId || floorId || "",
    roomId: currentReservation?.roomId || "",
    include: ["extra.mapDrawing"],
  }, {
    skip: (!locationId && !currentReservation?.locationId) || (!floorId && !currentReservation?.floorId),
  });
  const { data: roomResponse, isLoading: roomIsLoading, isFetching: roomIsFetching } = getRoomByIdQuery;
  const room = roomResponse?.result?.data;
  const availableServices = room?.availableServices?.map?.(({ type }) => type);
  const { roomId } = currentReservation || {};
  const now = getNowInTimeZone(timeZone);
  const maxDate = addDays(startOfDay(now), room?.reservationDayLimit || 1);
  const step = 15;

  useEffect(() => {
    if (currentTab === 1 && expanded) {
      setExpanded(false);
    }
  }, [currentTab, setExpanded]);

  const entry = currentReservation?.entries?.find(({ id }) => id === entryId);

  useEffect(() => {
    const startDate = safeParseISO(entry?.startDate);

    if (startDate && !isSameDayInTimeZone(startDate, selectedDay, defaultTimeZone)) {
      setSelectedDay(set(startDate, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }));
    }
  }, [JSON.stringify(entry), entryId]);

  useEffect(() => {
    if (currentTab === 1) {
      const startDate = parse(availabilityQuery.startDate, "yyyy-MM-dd", zeroDay);

      if (!isSameDayInTimeZone(selectedDay, startDate, defaultTimeZone)) {
        setSelectedDay(startDate);
      }
    }
  }, [currentTab, availabilityQuery.startDate]);

  useEffect(() => {
    const requiredValuesAreSet = !!entry?.startDate && !!entry?.endDate;

    if (!requiredValuesAreSet) {
      if (!entry?.startDate || !entry?.endDate) {
        setUpdateTooltip(t`Select date and time`);
      }

      if (canUpdate) {
        setCanUpdate(false);
      }
    } else if (!canUpdate && requiredValuesAreSet) {
      setCanUpdate(true);
      setUpdateTooltip(undefined);
    }
  }, [JSON.stringify(entry), canUpdate, setCanUpdate]);

  const unavailable = useMemo<FromToTimeInputProps["unavailable"]>(() => {
    const unavailable: FromToTimeInputProps["unavailable"] = [];

    if (currentRoom?.schedules) {
      if (currentRoom?.schedules?.length) {
        for (const schedule of currentRoom.schedules) {
          const startDate = new Date(schedule.startDate);

          if (isSameDayInTimeZone(selectedDay, startDate, defaultTimeZone)) {
            const endDate = new Date(schedule.endDate);
            const isReservationSchedule = currentRoom.id === reservation.room?.id
              && reservation.schedule?.entries?.some((entry) => {
                return isEqual(startDate, new Date(entry.startDate)) && isEqual(endDate, new Date(entry.endDate));
              });

            if (!isReservationSchedule) {
              unavailable.push([startDate, endDate]);
            }
          }
        }
      }

      if (currentReservation?.entries?.length) {
        for (const { id, startDate, endDate } of currentReservation.entries) {
          if (id !== entryId && startDate && endDate) {
            unavailable.push([new Date(startDate), new Date(endDate)]);
          }
        }
      }

      return unavailable.sort(([startA], [startB]) => differenceInMinutes(startA, startB));
    }
  }, [JSON.stringify(currentRoom?.schedules), JSON.stringify(currentReservation?.entries), selectedDay?.toISOString()]);

  const handleChangesReset = () => {
    const startDate = reservation.schedule?.startDate
      ? utcToZonedTime(toDate(reservation.schedule.startDate), defaultTimeZone)?.toISOString()
      : undefined;
    const endDate = reservation.schedule?.endDate
      ? utcToZonedTime(toDate(reservation.schedule.endDate), defaultTimeZone)?.toISOString()
      : undefined;
    const recurrence = rruleToRecurrenceOptions(reservation.schedule?.rrule);

    dispatch(reservationsSlice.actions.resetCurrent({
      startDate,
      endDate,
      locationId: reservation?.floor?.location?.id,
      roomId: reservation.room?.id,
      floorId: reservation.floor?.id,
      attendees: reservation.attendees,
      isTeamsMeeting: reservation.isTeamsMeeting,
      requestedServices: reservation.requestedServices,
      summary: reservation.summary,
      recurrence: recurrence ? { ...recurrence, start: recurrence.start.toISOString(), end: recurrence.end.toISOString() } : undefined,
      entries: reservation?.schedule?.entries?.map(({ id, startDate, endDate }) => ({ id, startDate, endDate })),
    }));
    dispatch(roomsSlice.actions.resetCurrent({
      id: reservation.room?.id,
      name: reservation.room?.name,
    }));
  };

  const handleUpdateClick: LoadingButtonProps["onClick"] = (event) => {
    const target = event.currentTarget;
    const timeout = setTimeout(() => setPopoverAnchorEl(target), 5000);

    void (async () => {
      if (currentReservation) {
        const entry = currentReservation.entries?.find(({ id }) => id === entryId);

        if (entry) {
          setLoading(true);

          const { startDate, endDate } = entry;
          const requestedServices = entry?.requestedServices || reservation?.requestedServices;
          const serviceOptionRequests = entry?.requestedServices ? entry?.serviceOptionRequests : reservation?.serviceOptionRequests;
          const serviceRequestSettings = entry?.requestedServices ? entry?.serviceRequestSettings : reservation?.serviceRequestSettings;
          const response = await updateReservationEntry({
            entryId,
            startDate,
            endDate,
            requestedServices,
            reservationId: reservation?.id,
            serviceOptionRequests: serviceOptionRequests
              ?.filter(({ option, description }) => option || description)
              ?.map(({ option, description, quantity, type, serviceType }) => ({
                description,
                quantity,
                type,
                serviceType,
                optionId: option?.id,
              })),
            serviceRequestSettings: serviceRequestSettings
              ?.filter(({ value }) => !!value) as MarkRequired<ReservationServiceRequestSetting, "value">[],
          });
  
          if (isSuccessAPIResponse(response)) {
            onUpdated?.();
          } else {
            const error = parseResponseError(response);

            if (error?.code === 1_001_015) {
              toast.showToast({ severity: "warning", message: t`This reservation is still being processed, please try again later.` });
            } else {
              toast.showToast({ message: t`There was an error while updating your meeting occurrence.`, severity: "error" });
            }
          }
    
          clearTimeout(timeout);
          setPopoverAnchorEl(undefined);
          setLoading(false);
        }
      }
    })();
  };

  const handleSelectedDayClick: TypographyProps["onClick"] = (event) => {
    setCalendarAnchorEl(event.currentTarget);
  };

  const handleSelectedDayChange = (value: Date) => {
    dispatch(reservationsSlice.actions.setCurrent({ startDate: undefined, endDate: undefined }));

    if (currentTab === 0) {
      const availabilityQueryStartDate = parse(availabilityQuery.startDate, "yyyy-MM-dd", now);
      const daysDifference = differenceInDays(value, availabilityQueryStartDate);

      if (daysDifference > 6) {
        const startDate = format(addWeeks(availabilityQueryStartDate, 1), "yyyy-MM-dd", { timeZone: defaultTimeZone });
        const endDate = startDate;

        dispatch(roomsSlice.actions.setCurrent({ schedules: undefined }));
        dispatch(reservationsSlice.actions.setAvailabilityQuery({ ...availabilityQuery, startDate, endDate }));
      } else if (daysDifference < 0) {
        const startDate = format(addWeeks(availabilityQueryStartDate, -1), "yyyy-MM-dd", { timeZone: defaultTimeZone });
        const endDate = startDate;

        dispatch(roomsSlice.actions.setCurrent({ schedules: undefined }));
        dispatch(reservationsSlice.actions.setAvailabilityQuery({ ...availabilityQuery, startDate, endDate }));
      }

      setSelectedDay(value);
      setCalendarAnchorEl(undefined);
    }

    if (currentTab === 1) {
      const startDate = format(value, "yyyy-MM-dd", { timeZone: defaultTimeZone });
      const endDate = startDate;

      dispatch(roomsSlice.actions.setCurrent({ schedules: undefined }));
      dispatch(reservationsSlice.actions.setAvailabilityQuery({ startDate, endDate }));
    }
  };

  const handleFromToTimeInputChange: FromToTimeInputProps["onChange"] = ({ from, to }) => {
    const year = selectedDay.getFullYear();
    const date = selectedDay.getDate();
    const month = selectedDay.getMonth();
    const startDate = set(from, { year, date, month });
    const endDate = set(to, { year, date, month });
    const entries = currentReservation?.entries?.filter(({ id }) => id !== entryId) || [];

    dispatch(reservationsSlice.actions.setCurrent({
      entries: [...entries, { id: entryId, startDate: startDate.toISOString(), endDate: endDate.toISOString() }],
    }));
  };

  const handleReservationServiceChange = (type: ReservationServiceType, checked: boolean) => {
    const requestedServices = entry?.requestedServices || reservation?.requestedServices || [];
    const isSelected = requestedServices.includes(type);
    const entries = currentReservation?.entries?.filter(({ id }) => id !== entryId) || [];

    if (checked && !isSelected) {
      dispatch(reservationsSlice.actions.setCurrent({
        entries: [...entries, { ...entry, requestedServices: [...requestedServices, type] }],
      }));
    } else if (!checked && isSelected) {
      dispatch(reservationsSlice.actions.setCurrent({
        entries: [...entries, { ...entry, requestedServices: requestedServices.filter((value) => value !== type) }],
      }));
    }
  };

  const handleCateringChange: CateringDialogProps["onChange"] = (serviceOptionRequests, serviceRequestSettings) => {
    const entries = currentReservation?.entries?.filter(({ id }) => id !== entryId) || [];
    const requestedServices = entry?.requestedServices || reservation?.requestedServices;

    dispatch(reservationsSlice.actions.setCurrent({
      entries: [...entries, { ...entry, serviceOptionRequests, serviceRequestSettings, requestedServices }],
    }));
  };

  const updateButton = (
    <LoadingButton
      data-cid="update-occurrence-button"
      disabled={!canUpdate} 
      loading={loading}
      loadingIndicator={<CircularProgress color="primary" size={22} />}
      onClick={handleUpdateClick}
      sx={{ bgcolor: "#fff", ":hover": { bgcolor: "#fff" } }}
      variant="text"
    >
      {t`Update occurrence`}
    </LoadingButton>
  );

  const requestedServices = entry?.requestedServices || reservation?.requestedServices;
  const serviceOptionRequests = entry?.requestedServices ? entry?.serviceOptionRequests : reservation?.serviceOptionRequests;
  const serviceRequestSettings = entry?.requestedServices ? entry?.serviceRequestSettings : reservation?.serviceRequestSettings;

  return (
    <Box>
      {reservation?.schedule ? (
        <Box marginBottom={3}>
          <Accordion disableGutters elevation={0} expanded={expanded}>
            <AccordionSummary
              expandIcon={undefined}
              onClick={undefined}
              sx={{
                padding: 0,
                cursor: "default",
                borderBottomStyle: "solid",
                borderBottomWidth: 0.5,
                borderBottomColor: theme.palette.grey[100],
                ":hover:not(.Mui-disabled)": {
                  cursor: "default",
                },
                "&.Mui-focusVisible": {
                  backgroundColor: "unset",
                },
              }}
            >
              <Box>
                <Box alignItems="center" display="flex" justifyContent="space-between" width="100%">
                  <Box alignItems="center" display="flex">
                    <Box
                      bgcolor={theme.palette.grey[100]}
                      borderRadius={2}
                      display="flex"
                      height={32}
                      justifyContent="center"
                      width={32}
                    >
                      <TogetherDateAndTime fill={theme.palette.grey[700]} sx={{ width: 32 }} />
                    </Box>
                    {roomId && selectedDay ? (
                      <Box alignItems="center" display="flex">
                        {roomIsLoading || roomIsFetching ? (
                          <Skeleton height={20} sx={{ marginX: 1 }} variant="rectangular" width={86} />
                        ) : (
                          <>
                            <InteractiveTypography data-cid="select-day-button" fontSize={14} fontWeight="500" onClick={handleSelectedDayClick}>
                              {format(selectedDay, "d LLL, yyyy.", { timeZone: defaultTimeZone })}
                            </InteractiveTypography>
                            <Popover
                              anchorEl={calendarAnchorEl}
                              anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                              elevation={4}
                              onClose={() => setCalendarAnchorEl(undefined)}
                              open={!!calendarAnchorEl}
                              sx={{ mt: 0.5 }}
                              transformOrigin={{ vertical: "top", horizontal: "center" }}
                            >
                              <Box padding={2} width={360}>
                                <DatePickerCalendar date={selectedDay} maxDate={maxDate} minDate={now} onChange={handleSelectedDayChange} />
                              </Box>
                            </Popover>
                          </>
                        )}
                        <FromToTimeInput
                          isLoading={!currentRoom?.schedules}
                          min={isToday(selectedDay) ? ceiledCalendarViewDate(now, step) : undefined}
                          onChange={handleFromToTimeInputChange}
                          step={step}
                          unavailable={unavailable}
                          value={{
                            from: entry?.startDate ? unshiftTimeZone(new Date(entry.startDate), defaultTimeZone) : undefined,
                            to: entry?.endDate ? unshiftTimeZone(new Date(entry.endDate), defaultTimeZone) : undefined,
                          }}
                        />
                      </Box>
                    ) : (
                      <Typography fontWeight="600">{t`Select a room`}</Typography>
                    )}
                  </Box>
                  {currentTab === 0 ? (
                    <IconButton color="primary" data-cid="expand-occurrence-details-button" onClick={() => setExpanded(!expanded)} size="medium">
                      <KeyboardArrowDown
                        fontSize="medium"
                        sx={{ transform: expanded ? "rotate(180deg)" : "rotate(0deg)", transition: `transform 0.15s` }}
                      />
                    </IconButton>
                  ) : undefined}
                </Box>
                {currentTab === 0 && roomId ? (
                  <Box mt={1}>
                    <TimeZoneDisclaimer timeZone={timeZone} tooltip={windowsTimeZone ? t`Derived from "${windowsTimeZone}" time zone.` : undefined} />
                  </Box>
                ) : undefined}
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              {currentReservation?.roomId && currentTab === 0 ? (
                <EditReservationEntryRouteDetailsRoomSchedule reservation={reservation} />
              ) : (
                <EditReservationEntryRouteDetailsDateAndTime timeZone={timeZone} />
              )}
            </AccordionDetails>
          </Accordion>
        </Box>
      ) : undefined}
      <Box alignItems="center" display="flex" justifyContent="space-between" marginBottom={2}>
        <Box alignItems="center" display="flex">
          <Box
            bgcolor={theme.palette.grey[100]}
            borderRadius={2}
            display="flex"
            height={32}
            justifyContent="center"
            marginRight={1}
            width={32}
          >
            <TogetherRoom stroke={theme.palette.grey[700]} sx={{ width: 16 }} />
          </Box>
          <Typography fontWeight="600">
            {currentRoom?.name ? currentRoom.name : t`Room is not selected`}
          </Typography>
        </Box>
      </Box>
      {availableServices?.includes?.(ReservationServiceType.CATERING) ? (
        <Box alignItems="center" display="flex" justifyContent="space-between" marginBottom={2}>
          <Box alignItems="center" display="flex">
            <Box alignItems="center" display="flex" height={32} justifyContent="center" marginRight={1} width={32} >
              <Switch
                checked={requestedServices?.includes(ReservationServiceType.CATERING) || false}
                onChange={(_, checked) => handleReservationServiceChange(ReservationServiceType.CATERING, checked)}
              />
            </Box>
            <Typography fontWeight="600">{t`I need catering`}</Typography>
          </Box>
          <CateringDialog
            Trigger={({ onClick }) => (
              <LinkButton disabled={!requestedServices?.includes(ReservationServiceType.CATERING)} onClick={onClick}>
                {t`Select`}
              </LinkButton>
            )}
            locationId={currentReservation?.locationId || reservation?.floor?.location?.id || ""}
            onChange={handleCateringChange}
            requests={serviceOptionRequests}
            settings={serviceRequestSettings}
          />
        </Box>
      ) : undefined}
      <Box
        alignItems="center"
        bgcolor={({ palette }) => palette.primary.main}
        borderRadius={2}
        display="flex"
        justifyContent="space-between"
        padding={2}
      >
        <LinkButton data-cid="reset-occurrence-button" onClick={handleChangesReset} sx={{ color: "rgba(255, 255, 255, 0.6)" }}>{t`Reset changes`}</LinkButton>
        {canUpdate ? updateButton : <Tooltip title={updateTooltip}><span>{updateButton}</span></Tooltip>}
      </Box>
      <Popover
        anchorEl={popoverAnchorEl}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        elevation={4}
        open={!!popoverAnchorEl}
        sx={{ mt: -0.5 }}
        transformOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Box padding={2} width={280}>
          <Typography color={theme.palette.grey[700]} fontSize={14} textAlign="center">
            {t`Please, wait while we send your reservation to Outlook`}
          </Typography>
        </Box>
      </Popover>
    </Box>
  );
};

export const EditReservationEntryRoute: React.FC = () => {
  const dispatch = useDispatch();
  const { reservationId } = useParams<{ reservationId: string }>();
  const [hasUpdated, setHasUpdated] = useState(false);
  const { data: reservationResponse, isLoading: reservationIsLoading, isFetching: reservationIsFetching } = useGetMyReservationByIdQuery({
    reservationId,
    include: [
      "schedule",
      "schedule.entries",
      "attendees",
      "floor",
      "floor.location",
      "room",
      "serviceOptionRequests",
      "serviceOptionRequests.option",
      "serviceRequestSettings",
      "schedule.entries.serviceOptionRequests",
      "schedule.entries.serviceOptionRequests.option",
      "schedule.entries.serviceRequestSettings",
    ],
  });
  const reservation = reservationResponse?.result?.data;
  const { room, floor, schedule, status } = reservation || {};
  const [currentTab, setCurrentTab] = useState(0);
  const { startDate, endDate, startTime, endTime } = useReservationsAvailabilityQuery();
  const [defaultTimeZone, defaultWindowsTimeZone] = useDefaultTimeZone();
  const timeZone = schedule?.timeZone?.split(";")?.[0] || defaultTimeZone;
  const windowsTimeZone = schedule?.timeZone?.split(";")?.[0]
    ? schedule?.timeZone?.split(";")?.[1] || defaultWindowsTimeZone
    : defaultWindowsTimeZone;

  useEffect(() => {
    dispatch(reservationsSlice.actions.setAvailabilityQuery({ startDate, endDate, startTime, endTime }));
  }, []);

  useEffect(() => {
    const attendees: Attendee[] = [];

    if (reservation?.attendees?.length) {
      attendees.push(...reservation.attendees);
    }

    if (reservation?.externalAttendees?.length) {
      attendees.push(...reservation.externalAttendees.map((email) => ({ email })));
    }

    const recurrence = rruleToRecurrenceOptions(reservation?.schedule?.rrule);

    dispatch(reservationsSlice.actions.resetCurrent({
      attendees,
      roomId: room?.id,
      floorId: floor?.id,
      locationId: floor?.location?.id,
      startDate: status === ReservationStatus.BOOKED && schedule?.startDate ? schedule.startDate : undefined,
      endDate: status === ReservationStatus.BOOKED && schedule?.endDate ? schedule.endDate : undefined,
      summary: reservation?.summary,
      isTeamsMeeting: reservation?.isTeamsMeeting,
      requestedServices: reservation?.requestedServices,
      recurrence: recurrence ? { ...recurrence, start: recurrence.start.toISOString(), end: recurrence.end.toISOString() } : undefined,
      entries: schedule?.entries?.map(({ id, startDate, endDate, requestedServices, serviceOptionRequests, serviceRequestSettings }) => {
        return { id, startDate, endDate, requestedServices, serviceOptionRequests, serviceRequestSettings };
      }),
    }));
    dispatch(roomsSlice.actions.resetCurrent({ id: room?.id, name: room?.name }));
    dispatch(floorsSlice.actions.resetCurrent({ id: floor?.id }));
  }, [reservation]);

  return (
    <ToastProvider>
      <Container>
        <PageHeader href="/" title={t`Edit meeting occurrence`} />
        <Box display="flex">
          <Box
            sx={({ breakpoints }) => ({
              flex: "1 0 65%",
              maxWidth: "65%",
              [breakpoints.up("xl")]: {
                flex: "1 0 70%",
                maxWidth: "70%",
              },
            })}
          >
            <Typography fontWeight="600" marginBottom={1}>{t`Select room`}</Typography>
            <Box mb={2}>
              <Tabs inline onChange={(_, tab) => setCurrentTab(tab)} value={currentTab}>
                <Tab label={t`List View`} />
                <Tab label={t`Calendar View`} />
              </Tabs>
            </Box>
            {currentTab === 1 ? (
              <Box mb={1}>
                <TimeZoneDisclaimer timeZone={timeZone} tooltip={windowsTimeZone ? t`Derived from "${windowsTimeZone}" time zone.` : undefined} />
              </Box>
            ) : undefined}
            <TabPanel index={0} value={currentTab}>
              {reservationIsLoading || reservationIsFetching || !reservation ? (
                <Skeleton
                  animation="wave"
                  height={128}
                  sx={({ palette }) => ({ borderRadius: 2, bgcolor: palette.grey[100] })}
                  variant="rectangular"
                />
              ) : (
                <EditReservationEntryRouteListView reservation={reservation} />
              )}
            </TabPanel>
            <TabPanel index={1} value={currentTab}>
              {reservationIsLoading || reservationIsFetching || !reservation ? (
                <Skeleton
                  animation="wave"
                  height={128}
                  sx={({ palette }) => ({ borderRadius: 2, bgcolor: palette.grey[100] })}
                  variant="rectangular"
                />
              ) : (
                <EditReservationEntryRouteCalendarView reservation={reservation} />
              )}
            </TabPanel>
          </Box>
          <Box
            sx={({ breakpoints }) => ({
              flex: "1 0 35%",
              maxWidth: "35%",
              paddingLeft: 2,
              [breakpoints.up("xl")]: {
                flex: "1 0 30%",
                maxWidth: "30%",
                paddingLeft: 3,
              },
            })}
          >
            <Typography fontWeight="600" marginBottom={1}>{t`Occurrence details`}</Typography>
            {reservationIsLoading || reservationIsFetching || !reservation ? (
              <Box>
                <Box marginBottom={2} paddingBottom={2.5} paddingTop={2.5}>
                  <Skeleton height={32} sx={({ palette }) => ({ bgcolor: palette.grey[100], borderRadius: 2 })} variant="rectangular" />
                </Box>
                <Skeleton
                  height={32}
                  sx={({ palette }) => ({ bgcolor: palette.grey[100], borderRadius: 2, marginBottom: 2 })}
                  variant="rectangular"
                />
                <Skeleton
                  height={32}
                  sx={({ palette }) => ({ bgcolor: palette.grey[100], borderRadius: 2, marginBottom: 2 })}
                  variant="rectangular"
                />
                <Skeleton
                  height={32}
                  sx={({ palette }) => ({ bgcolor: palette.grey[100], borderRadius: 2, marginBottom: 2 })}
                  variant="rectangular"
                />
                <Skeleton height={68} sx={({ palette }) => ({ bgcolor: palette.grey[100], borderRadius: 2 })} variant="rectangular" />
              </Box>  
            ) : (
              <EditReservationEntryRouteDetails currentTab={currentTab} onUpdated={() => setHasUpdated(true)} reservation={reservation} />
            )}
          </Box>
        </Box>
      </Container>
      <Dialog open={hasUpdated}>
        <Box padding={2}>
          <Typography fontSize={18} fontWeight="600" marginBottom={1}>{t`Reservation was successfully updated!`}</Typography>
          <Typography marginBottom={2}>{t`You can find all reservations details at your home page.`}</Typography>
          <Box alignItems="center" display="flex" justifyContent="flex-end">
            <Button data-cid="make-more-changes-button" onClick={() => setHasUpdated(false)} sx={{ marginRight: 2 }} variant="text">{t`Make more changes`}</Button>
            <Link to="/">
              <Button variant="contained">{t`Go to home page`}</Button>
            </Link>
          </Box>
        </Box>
      </Dialog>
    </ToastProvider>
  );
};