import { t } from "@lingui/macro";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Typography } from "@mui/material";
import { CampaignPolicyWrapper, NumberListInput, NumberListInputProps, RequiredMark, UserSelect, UsersListSelect, useToast } from "components";
import { UserRole } from "enums";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CommPolicyRule, CommPolicyRuleType, CommPolicyType, User, adminSlice, selectAdminCurrentCampaignPolicy, useCurrentCampaignPolicyRules, useGetCommPolicyByIdQuery, useGetMeQuery } from "store";
import { MarkOptional } from "ts-essentials";

export const CampaignUserPolicy: React.FC = () => {
  const dispatch = useDispatch();
  const { parentId, policyId } = useParams<{ parentId: string; policyId?: string }>();
  const toast = useToast();
  const currentPolicy = useSelector(selectAdminCurrentCampaignPolicy);
  const [copyParent, setCopyParent] = useState(false);
  const { data: getMeResponse } = useGetMeQuery();
  const getParentByIdQuery = useGetCommPolicyByIdQuery(
    { policyId: parentId, include: ["rules", "rules.user"] },
    { skip: !copyParent },
  );
  const { approvers, recipientUsersCount, recipients, authors, editors, policyManagers } = useCurrentCampaignPolicyRules();
  const { data: parent, isLoading: isLoadingParent, isError: parentLoadFailed } = getParentByIdQuery;

  useEffect(() => {
    if (!copyParent) {
      return;
    }

    if (parentLoadFailed) {
      toast.showToast({ severity: "error", message: t`Failed to load brand settings, please try again.` });
      setCopyParent(false);

      return;
    }

    if (!parent) {
      return;
    }

    dispatch(adminSlice.actions.setCurrentCampaignPolicy({ rules: parent.rules }));
    setCopyParent(false);
    toast.showToast({ severity: "success", message: t`Brand settings were loaded.` });
  }, [copyParent, parent, parentLoadFailed, setCopyParent, toast, dispatch]);

  const updateUserRules = useCallback((type: CommPolicyRuleType, value: User[]) => {
    const newRules: MarkOptional<CommPolicyRule, "id" | "createdAt">[] = [];

    for (const rule of currentPolicy?.rules || []) {
      if (rule.type !== type || value.some(({ id }) => rule.user?.id === id)) {
        newRules.push(rule);
      }
    }

    for (const user of value) {
      if (!newRules.some((rule) => rule.type === type && rule.user?.id === user.id)) {
        newRules.push({ type, user });
      }
    }

    dispatch(adminSlice.actions.setCurrentCampaignPolicy({ rules: newRules }));
  }, [currentPolicy?.rules, dispatch]);

  const handleNumberListInputChange: NumberListInputProps["onChange"] = (value) => {
    const ruleExists = currentPolicy?.rules?.some(({ type }) => type === CommPolicyRuleType.RECIPIENT_USERS_COUNT);

    if (ruleExists) {
      dispatch(adminSlice.actions.setCurrentCampaignPolicy({
        rules: currentPolicy?.rules?.map((rule) => rule.type === CommPolicyRuleType.RECIPIENT_USERS_COUNT ? { ...rule, value: `${value}` } : rule ),
      }));
    } else {
      dispatch(adminSlice.actions.setCurrentCampaignPolicy({
        rules: [...(currentPolicy?.rules || []), { type: CommPolicyRuleType.RECIPIENT_USERS_COUNT, value: `${value}` }],
      }));
    }
  };

  const { data: me } = getMeResponse?.result || {};

  return (
    <CampaignPolicyWrapper parentId={parentId} policyId={policyId} type={CommPolicyType.USER}>
      <Box display="flex" flexDirection="column" gap={2}>
        <Box>
          <Typography fontSize={14} mb={1}><RequiredMark />{t`User`}</Typography>
          <UserSelect
            onChange={(user) => dispatch(adminSlice.actions.setCurrentCampaignPolicy({ user: user || undefined }))}
            readOnly={me?.role !== UserRole.SUPER_ADMIN}
            value={currentPolicy?.user || null}
          />
        </Box>
        {currentPolicy?.isEditable ? (
          <Box>
            <LoadingButton loading={isLoadingParent} onClick={() => setCopyParent(true)}>{t`Copy Brand Settings to This Profile`}</LoadingButton>
          </Box>
        ) : undefined}
        <UsersListSelect
          labels={{ title: t`Approver`, disclaimer: t`(any of the following names can approve)` }}
          onChange={(value) => updateUserRules(CommPolicyRuleType.APPROVER, value || [])}
          readOnly={!currentPolicy?.isEditable}
          value={approvers}
        />
        <NumberListInput
          labels={{ title: t`Recipients count`, disclaimer: t`(require approval If attendee user count is greater than)` }}
          min={0}
          onChange={handleNumberListInputChange}
          readOnly={!currentPolicy?.isEditable}
          value={recipientUsersCount}
        />
        <UsersListSelect
          labels={{ title: t`Recipients list`, disclaimer: t`(require approval if any of the following names are in the recipients list)` }}
          onChange={(value) => updateUserRules(CommPolicyRuleType.RECIPIENT, value || [])}
          readOnly={!currentPolicy?.isEditable}
          value={recipients}
        />
        <UsersListSelect
          labels={{
            title: t`Message is sent from`,
            disclaimer: t`(require approval if any of the following names are who the message is sent from)`,
          }}
          onChange={(value) => updateUserRules(CommPolicyRuleType.AUTHOR, value || [])}
          readOnly={!currentPolicy?.isEditable}
          value={authors}
        />
        <UsersListSelect
          labels={{
            title: t`Default access`,
            disclaimer: t`(who by default can see this campaign)`,
          }}
          onChange={(value) => updateUserRules(CommPolicyRuleType.EDITOR, value || [])}
          readOnly={!currentPolicy?.isEditable}
          value={editors}
        />
        <UsersListSelect
          labels={{
            title: t`Profile manager`,
            disclaimer: t`(these users will be able to change the above settings) `,
          }}
          onChange={(value) => updateUserRules(CommPolicyRuleType.POLICY_MANAGER, value || [])}
          readOnly={me?.role !== UserRole.SUPER_ADMIN}
          value={policyManagers}
        />
      </Box>
    </CampaignPolicyWrapper>
  );
};
