import React, { useEffect, useMemo, useState } from "react";
import { FromTo, FromToTimeInputProps } from "./types";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Box, Typography } from "@mui/material";
import { t } from "@lingui/macro";
import { TimeInput } from "./time-input";
import { addMinutes, differenceInMinutes, eachMinuteOfInterval, endOfDay, isAfter, isBefore, isEqual, isSameHour, isSameMinute, parse, set } from "date-fns";
import { flooredCalendarViewDate } from "../calendar-view";
import { format } from "date-fns-tz";
import { localZeroDay } from "utils";

const resolveUnavailableFrom = (step: number, unavailable?: [start: Date, end: Date][]) => {
  const unavailableFrom: Date[] = [];

  if (unavailable) {
    for (const [start, end] of unavailable) {
      unavailableFrom.push(...eachMinuteOfInterval({ start, end }, { step }).slice(0, -1));
    }
  }

  return unavailableFrom;
};

const resolveUnavailableTo = (step: number, zeroDay: Date, from?: Date, unavailable?: [start: Date, end: Date][]) => {
  const unavailableTo: Date[] = [];

  if (!from) {
    if (unavailable) {
      for (const [start, end] of unavailable) {
        unavailableTo.push(...eachMinuteOfInterval({ start, end }, { step }).slice(1));
      }
    }
  } else {
    if (unavailable) {
      const nextSchedule = unavailable.find(([start]) => isAfter(parse(format(start, "HH:mm"), "HH:mm", from), from));
      
      if (nextSchedule) {
        const [start] = nextSchedule;

        unavailableTo.push(...eachMinuteOfInterval({ start, end: endOfDay(start) }, { step }).slice(1));
      }
    }

    if (!isEqual(zeroDay, from)) {
      unavailableTo.push(...eachMinuteOfInterval({ start: zeroDay, end: from }, { step }));
    }
  }

  return unavailableTo;
};

export const FromToTimeInput: React.FC<FromToTimeInputProps> = (props) => {
  const { step = 15, value, isLoading, unavailable, min, disabled, onChange } = props;
  const [innerValue, setInnerValue] = useState<typeof value>();
  const [innerError, setInnerError] = useState<Record<keyof FromTo, boolean>>({ from: false, to: false });

  useEffect(() => {
    const { from } = value || {};

    if (from && (!innerValue?.from || !isSameHour(from, innerValue.from) || !isSameMinute(from, innerValue.from))) {
      setInnerValue((state) => ({
        ...state,
        from: set(from, { year: localZeroDay.getFullYear(), month: localZeroDay.getMonth(), date: localZeroDay.getDate() }),
      }));
    } else if (!from && innerValue?.from) {
      setInnerValue((state) => ({ ...state, from: undefined }));
    }
  }, [value?.from?.getTime(), localZeroDay.getTime()]);

  useEffect(() => {
    const { to } = value || {};

    if (to && (!innerValue?.to || !isSameHour(to, innerValue.to) || !isSameMinute(to, innerValue.to))) {
      setInnerValue((state) => ({
        ...state,
        to: set(to, { year: localZeroDay.getFullYear(), month: localZeroDay.getMonth(), date: localZeroDay.getDate() }),
      }));
    } else if (!to && innerValue?.to) {
      setInnerValue((state) => ({ ...state, to: undefined }));
    }
  }, [value?.to?.getTime(), localZeroDay.getTime()]);

  const unavailableFrom = useMemo(() => resolveUnavailableFrom(step, unavailable), [step, JSON.stringify(unavailable)]);

  const unavailableTo = useMemo(
    () => resolveUnavailableTo(step, localZeroDay, innerValue?.from, unavailable),
    [step, innerValue?.from, JSON.stringify(unavailable)],
  );

  const minOption = useMemo(() => {
    if (min) {
      return set(
        flooredCalendarViewDate(min, step),
        { year: localZeroDay.getFullYear(), month: localZeroDay.getMonth(), date: localZeroDay.getDate() },
      );
    }
  }, [min?.getTime(), localZeroDay.getTime()]);

  const handleFromChange = (value: Date) => {
    setInnerValue({ from: value, to: innerValue?.to });

    const isUnavailable = (minOption && isBefore(value, minOption))
      || unavailable?.some(([start, end]) => isEqual(value, start) || (isAfter(value, start) && isBefore(value, end)));

    if (isUnavailable || (value.getMinutes() % 15 !== 0 && !innerError.from)) {
      setInnerError({ from: true, to: innerError.to });
    } else if (value.getMinutes() % 15 === 0) {
      if (innerError.from) {
        setInnerError({ from: false, to: innerError.to });
      }

      if (innerValue?.to && differenceInMinutes(innerValue.to, value) >= step) {
        onChange?.({ from: value, to: innerValue.to });
      } else if (!innerValue?.to || (innerValue?.to && differenceInMinutes(innerValue.to, value) < step)) {
        const to = addMinutes(value, step);

        setInnerValue({ to, from: value });
        onChange?.({ to, from: value });
      }
    }
  };

  const handleToChange = (value: Date) => {
    setInnerValue({ from: innerValue?.from, to: value });

    const isUnavailable = (minOption && isBefore(value, minOption))
      || unavailable?.some(([start, end]) => isEqual(value, end) || (isAfter(value, start) && isBefore(value, end)));
      
    if (isUnavailable || (value.getMinutes() % 15 !== 0 && !innerError.to)) {
      setInnerError({ from: innerError.from, to: true });
    } else if (value.getMinutes() % 15 === 0) {
      if (innerError.to) {
        setInnerError({ from: innerError.from, to: false });
      }

      if (innerValue?.from && (isBefore(value, innerValue?.from) || isEqual(value, innerValue?.from))) {
        setInnerError({ from: innerError.from, to: true });
      } else if (innerValue?.from && differenceInMinutes(value, innerValue.from) >= step) {
        onChange?.({ from: innerValue.from, to: value });
      }
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box alignItems="center" display="flex" justifyContent="flex-start">
        <Box alignItems="center" display="flex" justifyContent="flex-start" mr={1}>
          <Typography component="span" fontSize={14} fontWeight="500" mr={1}>
            {t`From`}
          </Typography>
          <TimeInput
            disabled={disabled}
            error={innerError.from}
            isLoading={isLoading}
            min={min}
            onChange={handleFromChange}
            step={step}
            unavailable={unavailableFrom}
            value={innerValue?.from}
          />
        </Box>
        <Box alignItems="center" display="flex" justifyContent="flex-start">
          <Typography component="span" fontSize={14} fontWeight="500" mr={1}>
            {t`To`}
          </Typography>
          <TimeInput
            disabled={disabled}
            error={innerError.to}
            isLoading={isLoading}
            min={min}
            onChange={handleToChange}
            step={step}
            unavailable={unavailableTo}
            value={innerValue?.to}
          />
        </Box>
      </Box>
    </LocalizationProvider>
  );
};
