import Api from 'store/_legacy/Services/Api';
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import {
  BULK_CANCEL_RESERVATIONS_BOOKING,
  BULK_CANCEL_RESERVATIONS_BOOKING_FAIL,
  BULK_CANCEL_RESERVATIONS_BOOKING_SUCCESS,
  BulkCancelReservationsBooking,
  CANCEL_RESERVATIONS_BOOKING,
  CANCEL_RESERVATIONS_BOOKING_FAIL,
  CANCEL_RESERVATIONS_BOOKING_SUCCESS,
  CancelReservationsBooking,
  GET_RESERVATIONS,
  GET_RESERVATIONS_BY_FLOORS,
  GET_RESERVATIONS_BY_FLOORS_FAIL,
  GET_RESERVATIONS_BY_FLOORS_SUCCESS,
  GET_RESERVATIONS_FAIL,
  GET_RESERVATIONS_SUCCESS,
  GET_RESERVATION_BOOKINGS_BY_LOCATION,
  GET_RESERVATION_BOOKINGS_BY_LOCATION_FAIL,
  GET_RESERVATION_BOOKINGS_BY_LOCATION_SUCCESS,
  GetReservations,
  GetReservationsBookings,
  GetReservationsByFloors,
} from './index';
import {matchPath} from "react-router-dom";
import {CancelReservationsBookingResponse} from "./models";

function* getReservations(action: GetReservations): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: GET_RESERVATIONS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_RESERVATIONS_FAIL, payload: e });
  }
}

function* getReservationsBookings(action: GetReservationsBookings): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: GET_RESERVATION_BOOKINGS_BY_LOCATION_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_RESERVATION_BOOKINGS_BY_LOCATION_FAIL, payload: e });
  }
}

function* getReservationsByFloors(action: GetReservationsByFloors): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: GET_RESERVATIONS_BY_FLOORS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_RESERVATIONS_BY_FLOORS_FAIL, payload: e });
  }
}

function* cancelReservationSaga(action: CancelReservationsBooking): any {
  try {
    yield call(Api, action);

    const match = matchPath<{ bookingId: string }>(action.payload.request.url, {
      path: '/api/bookings/:bookingId',
    });

    const payload: CancelReservationsBookingResponse =  { bookingId: match?.params.bookingId ?? '' };

    yield put({ type: CANCEL_RESERVATIONS_BOOKING_SUCCESS, payload });
  } catch (e) {
    yield put({ type: CANCEL_RESERVATIONS_BOOKING_FAIL, payload: e });
  }
}

function* bulkCancelReservationSaga(action: BulkCancelReservationsBooking): any {
  try {
    yield call(Api, action);

    yield put({ type: BULK_CANCEL_RESERVATIONS_BOOKING_SUCCESS, payload: action.payload.request.data?.bookingIds });
  } catch (e) {
    yield put({ type: BULK_CANCEL_RESERVATIONS_BOOKING_FAIL, payload: e });
  }
}

export default [
  takeLatest(GET_RESERVATIONS, getReservations),
  takeLatest(GET_RESERVATION_BOOKINGS_BY_LOCATION, getReservationsBookings),
  takeLatest(GET_RESERVATIONS_BY_FLOORS, getReservationsByFloors),
  takeLatest(CANCEL_RESERVATIONS_BOOKING, cancelReservationSaga),
  takeLatest(BULK_CANCEL_RESERVATIONS_BOOKING, bulkCancelReservationSaga),
];
