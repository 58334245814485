import { SvgIcon, SvgIconProps } from "@mui/material";

export const TogetherCampaign: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path
        d="M12.632 6.434h2.526m-2.526 3.369 1.684 1.684m-1.684-8.421 1.684-1.685m-11.79 7.58h2.527v4.631a1.263 1.263 0 0 1-2.527 0zm2.527-5.053V8.96M2.496 3.908c1.085 0 2.15-.28 3.094-.815L8.85 1.25a.842.842 0 0 1 1.256.733v8.232a.842.842 0 0 1-1.17.775L5.672 9.606a8.134 8.134 0 0 0-3.177-.646c-.913 0-1.654-.74-1.654-1.654V5.562c0-.914.74-1.654 1.654-1.654z"
        fill="none"
        fillRule="evenodd"
        stroke={props.stroke}
        strokeLinecap="round"
        strokeOpacity="1"
        strokeWidth="1.68421"
      />
    </SvgIcon>
  );
};
