import { t } from "@lingui/macro";
import { AddReactionOutlined, SendOutlined } from "@mui/icons-material";
import { Box, Button, Divider, Skeleton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { EmojiPopover, EmojiPopoverProps, NewsFeedItemComments, NewsFeedItemContent, PageContainer, PageHeader, useToast } from "components";
import { AttachmentImage } from "components/attachments";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { TrackingEventType, useDeletePostReactionByIdMutation, useGetPostByIdQuery, useSetPostReactionMutation, useTrackMutation } from "store";
import { formatDistance, parseEmoji } from "utils";

export const PostRoute: React.FC = () => {
  const history = useHistory();
  const { postId } = useParams<{ postId?: string }>();
  const { palette, breakpoints } = useTheme();
  const toast = useToast();
  const xl = useMediaQuery(breakpoints.up("xl"));
  const [emojiPopoverAnchorEl, setEmojiPopoverAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [setPostReaction, { isLoading: isSettingReaction }] = useSetPostReactionMutation();
  const [deletePostReaction, { isLoading: isDeletingReaction }] = useDeletePostReactionByIdMutation();
  const [track] = useTrackMutation();
  const getPostById = useGetPostByIdQuery(
    {
      postId: postId || "",
      include: ["author", "thumbnail", "$extra.reactionsCount", "$extra.reactionsCodes", "$extra.commentsCount", "$extra.myReaction"],
    },
    { skip: !postId },
  );
  const { data: post, isLoading, isFetching, isError, error } = getPostById;

  useEffect(() => {
    if (!postId || (isError && "status" in error && error.status === 404)) {
      history.push("/feed");
    }
  }, [postId, isError, error]);

  useEffect(() => {
    if (!isLoading && !isFetching && postId && post) {
      track({ postId, type: TrackingEventType.VIEW });
    }
  }, [isLoading, isFetching, postId, JSON.stringify(post)]);

  const myReaction = post?.$extra?.myReaction;

  const handleEmojiPopoverChange: EmojiPopoverProps["onChange"] = (_, code) => {
    setEmojiPopoverAnchorEl(null);

    if (!post || isSettingReaction || isDeletingReaction) {
      return;
    }

    void (async () => {
      const response = myReaction?.code === code
        ? await deletePostReaction({ postId: post.id, postReactionId: myReaction.id })
        : await setPostReaction({ postId: post.id, code });

      if ("error" in response) {
        toast.showToast({ severity: "error", message: t`Failed to set reaction, please try again` });
      }
    })();
  };

  return (
    <>
      <PageContainer>
        {isLoading || !post ? (
          <>
            <PageHeader href="/feed" title={<Skeleton height={28} variant="rectangular" width={880} />} />
            <Box bgcolor={palette.grey[100]} borderRadius={2} pb={3} pt={2} px={3}>
              <Box display="flex" gap={1} mb={1}>
                <Skeleton height={18} sx={{ bgcolor: palette.grey[200] }} variant="rectangular" width={140} />
                <Skeleton height={18} sx={{ bgcolor: palette.grey[200] }} variant="rectangular" width={60} />
              </Box>
              <Skeleton height={640} sx={{ bgcolor: palette.grey[200], mb: xl ? 3 : 2 }} variant="rectangular" width="100%" />
              <Skeleton height={16} sx={{ bgcolor: palette.grey[200], mb: 1 }} variant="rectangular" width="100%" />
              <Skeleton height={16} sx={{ bgcolor: palette.grey[200], mb: 1 }} variant="rectangular" width="100%" />
              <Skeleton height={16} sx={{ bgcolor: palette.grey[200], mb: 1 }} variant="rectangular" width="100%" />
              <Skeleton height={16} sx={{ bgcolor: palette.grey[200], mb: 1 }} variant="rectangular" width="100%" />
              <Skeleton height={16} sx={{ bgcolor: palette.grey[200], mb: 1 }} variant="rectangular" width="80%" />
            </Box>
          </>
        ) : (
          <>
            <PageHeader href="/feed" title={post.title} />
            <Box bgcolor={palette.grey[100]} borderRadius={2} pb={3} pt={2} px={3}>
              <Box display="flex" gap={1} mb={1}>
                <Typography fontSize={14} fontWeight={600}>{post.author?.name}</Typography>
                <Typography color={palette.grey[700]} fontSize={14}>{formatDistance(new Date(post.createdAt))}</Typography>
              </Box>
              {post.thumbnail?.id ? (
                <AttachmentImage attachmentId={post.thumbnail.id} borderRadius={2} height="auto" mb={xl ? 3 : 2} width="100%" />
              ) : undefined}
              <Box mb={xl ? 3 : 2}>
                <NewsFeedItemContent content={post.body} disableTracking={!post?.tracks?.includes(TrackingEventType.CLICK)} postId={post.id} />
              </Box>
              <Box alignItems="center" display="flex" justifyContent="space-between">
                <Box alignItems="center" display="flex" gap={2}>
                  {post.$extra?.reactionsCount ? (
                    <Box alignItems="center" display="flex" gap={1}>
                      {post?.$extra?.reactionsCodes?.map((code) => <Typography component="span" fontSize={14} key={code}>{parseEmoji(code)}</Typography>)}
                      <Typography color={palette.grey[700]} fontSize={14}>{post.$extra.reactionsCount}</Typography>
                    </Box>
                  ) : undefined}
                  {post.$extra?.commentsCount ? (
                    <Typography color={palette.grey[700]} fontSize={14}>{t`Comments`}{` (${post.$extra.commentsCount})`}</Typography>
                  ) : undefined}
                </Box>
              </Box>
              <Divider sx={{ my: 1, borderColor: palette.grey[200] }} />
              <Box display="flex" gap={2}>
                <Button
                  onClick={(event) => setEmojiPopoverAnchorEl(event.currentTarget)}
                  size="small"
                  startIcon={myReaction?.code ? parseEmoji(myReaction.code) : <AddReactionOutlined />}
                >
                  {t`Like`}
                </Button>
                <Button size="small" startIcon={<SendOutlined />}>{t`Share`}</Button>
              </Box>
              <NewsFeedItemComments open postId={post.id} />
            </Box>
          </>
        )}
      </PageContainer>
      <EmojiPopover
        anchorEl={emojiPopoverAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        onChange={handleEmojiPopoverChange}
        onClose={() => setEmojiPopoverAnchorEl(null)}
        open={!!emojiPopoverAnchorEl}
        transformOrigin={{ vertical: "bottom", horizontal: "left" }}
        value={myReaction?.code}
      />
    </>
  );
};
