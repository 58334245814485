import { CSSProperties, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useTypedSelector } from 'store/_legacy/Redux/store';
import { Trans } from '@lingui/macro';
import styles from './styles.module.scss';

import Box from 'components/_legacy/Box';
import { Room as RoomModel, RoomServiceType } from 'App/Store/Meetings/meetingRooms/models';
import Capacity from "./Capacity";
import MeetingDetailsHeader from "./MeetingDetailsHeader";
import MeetingSwitcher from "./MeetingSwitcher";
import MeetingTitle from "./MeetingTitle";
import BookMeetingButton from "./BookMeetingButton";
import { setCreateNewMeetingData } from 'App/Store/Meetings/createNewMeetingDuck';

export default function MeetingDetails() {  
  const dispatch = useDispatch();
  const { createNewMeeting, room } = useTypedSelector(state => state);
  const { rooms } = room;
  const { selectedData } = createNewMeeting;

  const { roomId } = selectedData;

  const selectedRoom: RoomModel | undefined = rooms.find((room: RoomModel) => room.id === roomId);
  // prevent actions if room is not selected
  const disabledDetailsStyle: CSSProperties = selectedRoom ? {} : { pointerEvents: 'none', opacity: 0.5 };

  // clear data
  useEffect(() => {
    dispatch(setCreateNewMeetingData({
      selectedData: {        
        ...selectedData,
        roomId: null,
        attendees: [],
        requestedServices: [],
        isTeamsMeeting: true,
        meetingTitle: '',
      },      
    }));
  }, []);

  return (    
    <>
      <Box>
        <Box alignItems="center" display="flex" marginBottom={15}>
          <MeetingDetailsHeader room={selectedRoom} />
        </Box>

        <Box style={disabledDetailsStyle}>
          {selectedRoom &&
            <>
              <Capacity room={selectedRoom} />

              {selectedRoom.availableServices?.some(({ type }) => type === RoomServiceType.CATERING) && (
                <Box marginBottom={15}>
                  <MeetingSwitcher
                    room={selectedRoom}
                    type={'catering'}
                  />
                </Box>
              )}

              {selectedRoom.availableServices?.some(({ type }) => type === RoomServiceType.IT_SUPPORT) && (
                <Box marginBottom={15}>
                  <MeetingSwitcher
                    room={selectedRoom}
                    type={'itSupport'}
                  />
                </Box>
              )}

              <Box marginBottom={15}>
                <MeetingTitle />
              </Box>

              <Box direction='column' display='flex' marginBottom={20}>
                <MeetingSwitcher
                  room={selectedRoom}
                  type={'teamMeeting'}
                />
                <div className={styles.teamMeetingObservation}>
                  <Trans>
                    Option creates event in calendar
                  </Trans>
                </div>
              </Box>
            </>
          }
          <BookMeetingButton />
        </Box>          
      </Box>      
    </>
  );
}
