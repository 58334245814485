import Api from 'store/_legacy/Services/Api';
import { GetAvailableDesksResponse } from './models';
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import {
  GET_AVAILABLE_DESKS,
  GET_AVAILABLE_DESKS_FAIL,
  GET_AVAILABLE_DESKS_SUCCESS,
  GetAvailableDesks,
} from '.';

function* getAvailableDesksSaga(action: GetAvailableDesks): any {
  try {
    const payload: GetAvailableDesksResponse = yield call(Api, action);

    yield put({ type: GET_AVAILABLE_DESKS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_AVAILABLE_DESKS_FAIL, payload: e });
  }
}

export default [
  takeLatest(GET_AVAILABLE_DESKS, getAvailableDesksSaga),
];
