import { useCallback, useEffect, useState } from 'react';
import DeskObject from '../DeskObject';
import SectionObject from '../SectionObject';
import { FeatureGroup, useMapEvents } from 'react-leaflet';
import { useTypedSelector } from 'store/_legacy/Redux/store';
import 'leaflet-draw';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import { DeskStatus, DeskStatusEnum } from 'Admin/Store/floorMapDuck/desk/models';
import { ReduxStore } from 'store/_legacy/Redux';
import { Action } from 'store/_legacy/Models/ReduxModels';
import { GetAvailableDesksRequest } from 'App/Store/Bookings/availableDesksDuck/models';
import { format } from 'date-fns';
import { weeklySlotsToWeeklyBooking } from 'App/Store/Utils';
import Api from 'store/_legacy/Services/Api';
import { debounce } from 'underscore';
import { Neighbor } from 'App/Store/Bookings/sectionNeighbors/models';
import { useSelector } from 'react-redux';
import { selectCurrentBookingNeighbor } from 'store';
import { isDeskAvailableForOwner } from 'Functions/checkDeskOwners';
import { getUserIdForBooking } from 'App/Store/Users/executiveAssistant/helpers';

interface Props {
  map: L.Map;
  showOnlyBookedDesk?: boolean;
}

export default function MapDrawing({ showOnlyBookedDesk, map }: Props) {
  const { adminFloorMap, createNewBooking, profile, executiveAssistant } = useTypedSelector(state => state);
  const { desks, sections } = adminFloorMap;
  const [zoomLevel, setZoomLevel] = useState<number>(1); // variable to handle zoom event
  const { deskId } = createNewBooking;
  // show DELETE_IN_PROGRESS on booking details page
  const deskStatusToHide: DeskStatus[] = showOnlyBookedDesk ? [DeskStatusEnum.Unavailable] : [DeskStatusEnum.Unavailable, DeskStatusEnum.DeleteInProgress];
  // Transform the objects with desks and sections into arrays
  const desksArray = Object.keys(desks).map(key => desks[key]).filter(desk => !deskStatusToHide.includes(desk.status));
  const sectionsArray = Object.keys(sections).map(key => sections[parseInt(key)]);
  const [neighbors, setNeighbors] = useState<Neighbor[]>([]);
  const currentBookingNeighbor = useSelector(selectCurrentBookingNeighbor);
  const selectedUserId = getUserIdForBooking(profile, executiveAssistant);
  const userGroups = profile.groups;

  const handleNeighborsRequest = async (
    {
      bookingType,
      dateFrom,
      dateTo,
      locationId,
      floorId,
      timeFrom,
      timeTo,
      weeklySlots,
    }: Partial<ReduxStore["createNewBooking"]>,
  ) => {
    const action: Action<GetAvailableDesksRequest> = {
      type: "GET_DESK_NEIGHBORS",
      payload: {
        request: {
          method: "GET",
          url: `/api/locations/${locationId}/floors/${floorId}/neighbors`,
        },
      },
    };

    switch (bookingType) {
      case "custom": {
        if (locationId && dateFrom && dateTo && timeFrom && timeTo) {
          const timeFromFormatted = timeFrom ? format(new Date(timeFrom), 'HH:mm') : '';
          const timeToFormatted = timeTo ? format(new Date(timeTo), 'HH:mm') : '';

          action.payload.request.data = {
            custom: {
              dateFrom: dateFrom ? format(new Date(dateFrom), 'yyyy-MM-dd') : '',
              dateTo: dateTo ? format(new Date(dateTo), 'yyyy-MM-dd') : '',
              timeFrom: timeFromFormatted,
              timeTo: timeToFormatted,
            },
          };
        }
        break;
      }
      case "daily": {
        if (locationId && timeFrom && timeTo) {
          const timeFromFormatted = timeFrom ? format(new Date(timeFrom), 'HH:mm') : '';
          const timeToFormatted = timeTo ? format(new Date(timeTo), 'HH:mm') : '';

          action.payload.request.data = {
            daily: { timeFrom: timeFromFormatted, timeTo: timeToFormatted },
          };
        }
        break;
      }
      case "weekly": {
        if (locationId && dateFrom && dateTo && timeFrom && timeTo && weeklySlots) {
          action.payload.request.data = {
            weekly: weeklySlotsToWeeklyBooking(weeklySlots),
          };
        }
        break;
      }
      default:
        break;
    }

    const response = await Api(action);
    const items = response?.data?.result?.data?.items;

    if (Array.isArray(items)) {
      const neighbors: Neighbor[] = [];

      for (const item of items) {
        const { id, name, desk = {} } = item;

        neighbors.push({ id, name, deskId: desk.id, sectionId: desk.sectionId, surname: "" });
      }

      setNeighbors(neighbors);
    }
  };
  const debouncedHandleNeighborsRequest = useCallback(debounce(handleNeighborsRequest, 900), []);
  const { bookingType, dateFrom, dateTo, locationId, floorId, timeFrom, timeTo, weeklySlots } = createNewBooking;

  useEffect(() => {
    debouncedHandleNeighborsRequest({
      bookingType,
      dateFrom,
      dateTo,
      locationId,
      floorId,
      timeFrom,
      timeTo,
      weeklySlots,
    });
  }, [dateFrom, dateTo, timeFrom, timeTo, locationId, bookingType, weeklySlots]);

  useMapEvents({
    'zoom': (e) => {
      setZoomLevel(e.target._zoom);
    },
  });

  const bookedDesk = desksArray.find(desk => desk.id === deskId);

  return (
    <>
      {sectionsArray.map((section, index) => {
        const showSection = showOnlyBookedDesk ? bookedDesk?.parentId === section.id : true;

        return showSection && (
          <FeatureGroup key={`${section.id} ${index}`}>
            <SectionObject
              neighbors={neighbors.filter(({ sectionId }) => sectionId === section.id)}
              neighborsData='createNewBooking'
              section={section}
              showNeighbors
              zoomLevel={zoomLevel}
            />
          </FeatureGroup>
        );
      })}
      {desksArray.map((desk) => {
        const isOnlyOneBooked = deskId === desk.id;
        const isAvailableForOwner = isDeskAvailableForOwner({ selectedUserId, userGroups, desk, userId: profile.id });
        const showDesk = showOnlyBookedDesk ? isOnlyOneBooked && isAvailableForOwner : true;

        return showDesk && (
          <FeatureGroup key={`${desk.id} ${desk.parentId} ${sectionsArray.length}`}>
            <DeskObject desk={desk} map={map} neighbor={currentBookingNeighbor} />
          </FeatureGroup>
        );
      })}

    </>
  );
}
