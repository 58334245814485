import React from "react";
import { DailyCalendarViewScheduleProps } from "./types";
import { Box, ButtonBase, Tooltip, Typography } from "@mui/material";
import { resolveCalendarViewScheduleInterval, resolveCalendarViewScheduleSpan, resolveCalendarViewScheduleSummary } from "./utils";
import { isAfter, isBefore } from "date-fns";

export const DailyCalendarViewSchedule: React.FC<DailyCalendarViewScheduleProps> = (props) => {
  const {
    startDate,
    endDate,
    step,
    stepHeight,
    stepWidth,
    firstStepDate,
    lastStepDate,
    summary,
    background,
    color,
    reservationId,
    entryId,
    onScheduleClick,
  } = props;
  const scheduleInterval = resolveCalendarViewScheduleInterval(startDate, endDate, step, firstStepDate, lastStepDate);
  const left = resolveCalendarViewScheduleSpan(startDate, firstStepDate, step, firstStepDate) * stepWidth;
  const width =  scheduleInterval * stepWidth;
  const resolvedSummary = resolveCalendarViewScheduleSummary(summary, width);
  const isBeforeFirstStep = isBefore(startDate, firstStepDate);
  const isAfterLastStep = isAfter(endDate, lastStepDate);
  let borderRadius = "0px";

  if (!isBeforeFirstStep && !isAfterLastStep) {
    borderRadius = "8px";
  } else if (isBeforeFirstStep && !isAfterLastStep) {
    borderRadius = "0px 8px 8px 0px";
  } else if (isAfterLastStep && !isBeforeFirstStep) {
    borderRadius = "8px 0px 0px 8px";
  }

  const handleScheduleClick = () => {
    if (reservationId && entryId) {
      onScheduleClick?.(reservationId, entryId);
    }
  };

  return (
    <Tooltip followCursor title={summary}>
      {onScheduleClick ? (
        <ButtonBase
          onClick={handleScheduleClick}
          sx={{
            alignItems: "center",
            bgcolor: background || "#FFE4DB",
            borderRadius: borderRadius,
            display: "flex",
            height: stepHeight - 2,
            justifyContent: "center",
            left: left,
            padding: 2,
            position: "absolute",
            top: 1,
            width: width,
            zIndex: 10,
          }}
        >
          <Typography color={color} noWrap>{resolvedSummary}</Typography>
        </ButtonBase>
      ) : (
        <Box
          alignItems="center"
          bgcolor={background || "#FFE4DB"}
          borderRadius={borderRadius}
          display="flex"
          height={stepHeight - 2}
          justifyContent="center"
          left={left}
          padding={2}
          position="absolute"
          top={1}
          width={width}
          zIndex={10}
        >
          <Typography color={color} noWrap>{resolvedSummary}</Typography>
        </Box>
      )}
    </Tooltip>
  );
};
