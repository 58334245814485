import Box from 'components/_legacy/Box';
import LocationDetailsHeader from 'components/_legacy/Header/LocationDetailsHeader';
import MonthlyBooking from './AdminCalendar/MonthlyBooking';
import WeeklyBooking from './AdminCalendar/WeeklyBooking';
import { getCalendarDates } from '../../Store/calendar';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTypedSelector } from 'store/_legacy/Redux/store';
import Skeleton from '@material-ui/lab/Skeleton';

export default function CalendarPage() {
  const dispatch = useDispatch();
  const { locationId } = useParams<{ locationId: string }>();
  const { adminCalendar: { loading } } = useTypedSelector(state => state);

  const [firstLoadCompleted, setFirstLoadCompleted] = useState(false);

  useEffect(() => {
    dispatch(getCalendarDates({ locationId }));

    setFirstLoadCompleted(true);
  }, [locationId]);

  return (
    <>
      <LocationDetailsHeader />

      <Box dataTestId="admin-calendar" marginTop={20}>
        {(firstLoadCompleted && !loading) ? (
          <>
            <WeeklyBooking />
            <MonthlyBooking />
          </>
        ) : null}

        {loading ? (
          <Box dataTestId="admin-calendar-loading" height={500} marginTop={40}>
            <Skeleton height="100%" variant="rect" />
          </Box>
        ) : null}
      </Box>
    </>
  );
}
