import Box from 'components/_legacy/Box';
import CheckboxCustom from 'components/_legacy/CheckBoxCustom';
import CheckCircleIcon from 'components/_legacy/Icons/CheckCircle';
import ClockIcon from 'components/_legacy/Icons/Clock';
import clsx from 'clsx';
import DeniedCircleIcon from 'components/_legacy/Icons/DeniedCircle';
import NoneCircleIcon from 'components/_legacy/Icons/NoneCircle';
import styles from './styles.module.scss';
import Text from 'components/_legacy/Text';
import { FormControlLabel } from '@material-ui/core';
import {initialFilters, setUserManagementData} from 'Admin/Store/userManagement';
import { t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { UserDocumentStatus } from 'Admin/Store/userManagement/models';
import { useTypedSelector } from 'store/_legacy/Redux/store';
import FilterAccordion from "components/_legacy/Filters/FilterAccordion";

export default function StatusFilter() {
  const dispatch = useDispatch();
  const { adminUserManagement, config } = useTypedSelector(state => state);

  const { filters: reduxFilters } = adminUserManagement;

  const localCounter = Object.keys(reduxFilters.status).map(key => reduxFilters.status[key as UserDocumentStatus]).filter(status => status.value).length;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setUserManagementData({
      filters: {
        ...reduxFilters,
        status: {
          ...reduxFilters.status,
          [event.target.name]: {
            label: reduxFilters.status[event.target.name as UserDocumentStatus].label,
            value: event.target.checked,
          },
        },
      },
    }));
  };

  const onResetFilter = () => {
    dispatch(setUserManagementData({
      filters: {
        ...reduxFilters,
        status: initialFilters.status,
      },
    }));
  };

  const getIcon = (status: UserDocumentStatus) => {
    switch (status) {
      case 'approved': return <CheckCircleIcon color="#5BC535" />;
      case 'denied': return <DeniedCircleIcon color={config.theme.greyMedium} />;
      case 'expired': return <ClockIcon color={config.theme.error} />;
      case 'needsApproval': return <ClockIcon color="#FFBD0E" />;
      case 'notUploaded': return <NoneCircleIcon color={config.theme.greyMedium} />;
      default: return;
    }
  };

  return (
    <Box display="flex" justifyContent="between" marginTop={10}>
      <FilterAccordion headerName={t`Doc Status`} localCounter={localCounter} resetFilter={onResetFilter}>
        <Box className={styles.filtersFormControlWrapper} display="grid">
          {Object.keys(reduxFilters.status).map((key, index) => {
            const status = reduxFilters.status[key as UserDocumentStatus];

            return (
              <FormControlLabel
                className={clsx(status.value && styles.checkedLabel)}
                control={(
                  <CheckboxCustom
                    checked={status.value}
                    name={key}
                    onChange={handleChange}
                  />
                )}
                key={`${status.label} ${index}`}
                label={(
                  <Box alignItems='center' display='flex' gap={5} marginStart={3}>
                    {getIcon(key as UserDocumentStatus)}

                    <Text size="md" weight='regular'>
                      {status.label}
                    </Text>
                  </Box>
                )}
              />
            );
          })}
        </Box>
      </FilterAccordion>
    </Box>
  );
}
